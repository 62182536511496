import { CONTENTFUL } from '@/config'
import { GraphQLClient } from 'graphql-request'
import { APP_CONTENTFULS, DEFAULT_CONTENTFUL } from '@/appConfig'
import {
  FetchRootSettingsCollectionQuery,
  FetchRootSettingsCollectionForWriterPageQuery,
} from '@/generated/graphql'
import palette from '@/theme/palette'
import find from 'lodash/find'
// ----------------------------------------------------------------------

export const getContentfulClient = (currentAppId: string) => {
  const appContentful = APP_CONTENTFULS.find((contentful) => contentful.appId === currentAppId)
  if (!appContentful) {
    return new GraphQLClient(
      CONTENTFUL.contentfulGraphqlUrl.replace('{{SPACE_ID}}', DEFAULT_CONTENTFUL.spaceId),
      {
        headers: {
          Authorization: `Bearer ${DEFAULT_CONTENTFUL.accessToken}`,
        },
      }
    )
  }
  return new GraphQLClient(
    CONTENTFUL.contentfulGraphqlUrl.replace('{{SPACE_ID}}', appContentful.spaceId),
    {
      headers: {
        Authorization: `Bearer ${appContentful.accessToken}`,
      },
    }
  )
}

export const formatLocale = (locale?: string, target?: 'next' | 'contentful') => {
  if (target === 'next') {
    return locale === 'zh-Hant-TW' ? 'zh-TW' : locale
  } else {
    return locale === 'zh-TW' ? 'zh-Hant-TW' : locale
  }
}

export const globalSettingTransformFunc = (data?: FetchRootSettingsCollectionQuery) => {
  const [
    item = {
      scope: 'global',
      name: 'be-passive',
      companyColor: '#d6c37c',
      companyLogo: '',
      companyFavicon: '',
      callToActionLink: null,
      sectionColors: [],
      seoMetadataItemsCollection: {
        items: [],
      },
      socialMediaLinksCollection: {
        items: [],
      },
      theme: 'light',
      headerDisplayPagesCollection: {
        items: [],
      },
      mobileDrawerDisplayPagesCollection: {
        items: [],
      },
      promptEngineeringsCollection: {
        items: [],
      },
      renamePathDisplayTextBlog: null,
      renamePathDisplayTextHome: null,
      renamePathDisplayTextProduct: null,
      renameCallToActionLinksDisplayText: null,
    },
  ] =
    data?.rootSettingsCollection?.items.map((item) => ({
      ...item,
      companyLogo: item?.companyLogo?.url,
      companyFavicon: item?.companyFavicon?.url,
    })) ?? []

  const socialMediaLinksCollection = {
    ...item?.socialMediaLinksCollection,
    items: (item?.socialMediaLinksCollection?.items ?? []).map((item) => ({
      ...item,
      title: item?.title ?? '',
      link: item?.link ?? '',
      type: item?.type ?? '',
      isExternal: item?.isExternal ?? true,
    })),
  }

  const seoMetadataItemsCollection = {
    ...item?.seoMetadataItemsCollection,
    items: (item?.seoMetadataItemsCollection?.items ?? []).map((item) => ({
      ...item,
      name: item?.name ?? '',
      content: item?.content ?? '',
    })),
  }
  const headerDisplayPagesCollection = {
    ...item?.headerDisplayPagesCollection,
    items: (item?.headerDisplayPagesCollection?.items ?? []).map((item) => ({
      ...item,
      slug: item?.slug ?? '',
      content: item?.displayName ?? item?.slug ?? '',
    })),
  }
  const mobileDrawerDisplayPagesCollection = {
    ...item.mobileDrawerDisplayPagesCollection,
    items: (item?.mobileDrawerDisplayPagesCollection?.items ?? []).map((item) => ({
      ...item,
      slug: item?.slug ?? '',
      content: item?.displayName ?? item?.slug ?? '',
    })),
  }

  const promptEngineeringsCollection = {
    ...item?.promptEngineeringsCollection,
    items: (item?.promptEngineeringsCollection?.items ?? []).map((item) => ({
      ...item,
    })),
  }

  const seoMetadataItems = seoMetadataItemsCollection?.items ?? []
  return {
    scope: item?.scope ?? 'global',
    companyColor: item?.companyColor ?? palette.light.primary.main,
    companyLogo: item?.companyLogo ?? '',
    companyFavicon: item?.companyFavicon ?? '',
    sectionColors: item?.sectionColors ?? [],
    callToActionLink:
      item?.callToActionLink?.title && item?.callToActionLink?.link
        ? {
            title: item?.callToActionLink.title,
            link: item?.callToActionLink.link,
          }
        : null,
    seoMetadataItemsMap: {
      title: find(seoMetadataItems, { name: 'title' })?.content,
      description: find(seoMetadataItems, { name: 'description' })?.content,
    },
    seoMetadataItemsCollection,
    seoMetadataItems,
    socialMediaLinksCollection,
    headerDisplayPagesCollection,
    mobileDrawerDisplayPagesCollection,
    promptEngineeringsCollection: promptEngineeringsCollection ?? [],
    socialMediaLinks: socialMediaLinksCollection.items ?? [],
    theme: item?.theme ?? 'light',
    renamePathDisplayTextBlog: item.renamePathDisplayTextBlog,
    renamePathDisplayTextHome: item.renamePathDisplayTextHome,
    renamePathDisplayTextProduct: item.renamePathDisplayTextProduct,
    renameCallToActionLinksDisplayText: item.renameCallToActionLinksDisplayText,
  }
}
export const writerServiceSettingTransformFunc = (
  data?: FetchRootSettingsCollectionForWriterPageQuery
) => {
  const result = globalSettingTransformFunc(data)
  const writersoftModules = data?.rootSettingsCollection?.items?.[0]?.writersoftModules ?? []
  return {
    ...result,
    writersoftModules,
  }
}
