import {
  TypedTypePolicies,
  query_rootFieldPolicy,
  query_rootKeySpecifier,
} from '@/generated/graphql'
import { TypePolicy } from '@apollo/client'
import { offsetLimitPagination } from '@apollo/client/utilities'

type CustomTypedTypePolicies = TypedTypePolicies & {
  Query: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | query_rootKeySpecifier | (() => undefined | query_rootKeySpecifier)
    fields?: query_rootFieldPolicy
  }
}

export const typePolicies: CustomTypedTypePolicies = {
  Query: {
    fields: {
      midjourneyImages: offsetLimitPagination(),
    },
  },
}
