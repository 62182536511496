import { createContext, ReactNode, useEffect, useReducer } from 'react'
import Router from 'next/router'
import { useUser, UserProfile } from '@auth0/nextjs-auth0'
import { ActionMap, AuthState, Auth0ContextType } from '../@types/auth'

const initialState: AuthState & { user: UserProfile | null } = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
}

enum Types {
  init = 'INITIALIZE',
  login = 'LOGIN',
  logout = 'LOGOUT',
}

type Auth0AuthPayload = {
  [Types.init]: {
    isAuthenticated: boolean
    user: UserProfile | null
  }
  [Types.login]: {
    user: UserProfile | null
  }
  [Types.logout]: undefined
}

type Auth0Actions = ActionMap<Auth0AuthPayload>[keyof ActionMap<Auth0AuthPayload>]

const reducer = (state: AuthState & { user: UserProfile | null }, action: Auth0Actions) => {
  if (action.type === Types.init) {
    const { isAuthenticated, user } = action.payload
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    }
  }
  if (action.type === Types.login) {
    const { user } = action.payload
    return { ...state, isAuthenticated: true, user }
  }
  if (action.type === Types.logout) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    }
  }
  return state
}

const AuthContext = createContext<
  (Auth0ContextType & { isLoading: boolean; user: UserProfile | null; error?: Error }) | null
>(null)

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode
}

function AuthProvider({ children }: AuthProviderProps) {
  const { user, isLoading, error, checkSession } = useUser()
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const initialize = async () => {
      try {
        await checkSession()
      } catch (err) {
        console.error(err)
        dispatch({
          type: Types.init,
          payload: { isAuthenticated: false, user: null },
        })
      }
    }

    initialize()
  }, [])

  const login: Auth0ContextType['login'] = async (options) => {
    const returnTo = options?.returnTo || window.location.href
    const loginUrl = `/api/auth/login?returnTo=${returnTo}`
    process.env.NODE_ENV === 'development'
      ? window.location.assign(loginUrl)
      : Router.push(loginUrl)
  }

  const logout = () => {
    const logoutUrl = `/api/auth/logout?returnTo=${window.location.href}`
    process.env.NODE_ENV === 'development'
      ? window.location.assign(logoutUrl)
      : Router.push(logoutUrl)
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        isLoading,
        error,
        method: 'auth0',
        user: user ?? null,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
