import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
// @mui
import { enUS, Localization, zhTW, jaJP } from '@mui/material/locale'
import { SupportLocaleEnum } from '@/appConfig'

// ----------------------------------------------------------------------

const LANGS: {
  label: string
  value: SupportLocaleEnum
  systemValue: Localization
  icon: string
}[] = [
  {
    label: 'English',
    value: SupportLocaleEnum.en,
    systemValue: enUS,
    icon: '/assets/flags/ic_flag_us.svg',
  },
  {
    label: 'Chinese',
    value: SupportLocaleEnum.zhTw,
    systemValue: zhTW,
    icon: '/assets/flags/ic_flag_taiwan.svg',
  },
  {
    label: 'Japan',
    value: SupportLocaleEnum.ja,
    systemValue: jaJP,
    icon: '/assets/flags/ic_flag_ja.svg',
  },
  // {
  //   label: 'German',
  //   value: 'de',
  //   systemValue: deDE,
  //   icon: 'https://minimal-assets-api.vercel.app/assets/icons/ic_flag_de.svg',
  // },
  // {
  //   label: 'French',
  //   value: 'fr',
  //   systemValue: frFR,
  //   icon: 'https://minimal-assets-api.vercel.app/assets/icons/ic_flag_fr.svg',
  // },
]

export default function useLocales() {
  const { i18n, t: translate } = useTranslation()
  const router = useRouter()
  let langStorage: string | null = ''

  if (typeof window !== 'undefined') {
    langStorage = localStorage.getItem('i18nextLng')
  }
  const currentLang =
    LANGS.find((_lang) => _lang.value === router.locale) ||
    LANGS.find((_lang) => _lang.value === langStorage) ||
    LANGS[0]

  useEffect(() => {
    if (i18n.language !== router.locale) {
      i18n.changeLanguage(router.locale)
    }
  }, [])

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang)

    router.push({ pathname: router.pathname, query: router.query }, router.asPath, {
      locale: newlang,
    })
  }

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  }
}
