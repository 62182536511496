import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
//
import enLocales from './en.json'
import zhTWLocales from './zh-tw.json'
import jaLocales from './ja.json'

// ----------------------------------------------------------------------

let lng = 'zh-TW'

if (typeof localStorage !== 'undefined') {
  lng = localStorage.getItem('i18nextLng') || 'zh-TW'
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: enLocales },
      'zh-TW': { translations: zhTWLocales },
      ja: { translations: jaLocales },
    },
    lng,
    fallbackLng: 'zh-TW',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
