import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTime: any
  Dimension: any
  HexColor: any
  JSON: any
  Quality: any
  jsonb: any
  timestamp: any
  timestamptz: any
  uuid: any
}

/** Represents a binary file in a space. An asset can be any file type. */
export type Asset = {
  __typename?: 'Asset'
  contentType?: Maybe<Scalars['String']>
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  fileName?: Maybe<Scalars['String']>
  height?: Maybe<Scalars['Int']>
  linkedFrom?: Maybe<AssetLinkingCollections>
  size?: Maybe<Scalars['Int']>
  sys: Sys
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  width?: Maybe<Scalars['Int']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetContentTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetFileNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetHeightArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetSizeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
  transform?: InputMaybe<ImageTransformOptions>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetWidthArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type AssetCollection = {
  __typename?: 'AssetCollection'
  items: Array<Maybe<Asset>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type AssetFilter = {
  AND?: InputMaybe<Array<InputMaybe<AssetFilter>>>
  OR?: InputMaybe<Array<InputMaybe<AssetFilter>>>
  contentType?: InputMaybe<Scalars['String']>
  contentType_contains?: InputMaybe<Scalars['String']>
  contentType_exists?: InputMaybe<Scalars['Boolean']>
  contentType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentType_not?: InputMaybe<Scalars['String']>
  contentType_not_contains?: InputMaybe<Scalars['String']>
  contentType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fileName?: InputMaybe<Scalars['String']>
  fileName_contains?: InputMaybe<Scalars['String']>
  fileName_exists?: InputMaybe<Scalars['Boolean']>
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fileName_not?: InputMaybe<Scalars['String']>
  fileName_not_contains?: InputMaybe<Scalars['String']>
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  height?: InputMaybe<Scalars['Int']>
  height_exists?: InputMaybe<Scalars['Boolean']>
  height_gt?: InputMaybe<Scalars['Int']>
  height_gte?: InputMaybe<Scalars['Int']>
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  height_lt?: InputMaybe<Scalars['Int']>
  height_lte?: InputMaybe<Scalars['Int']>
  height_not?: InputMaybe<Scalars['Int']>
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  size?: InputMaybe<Scalars['Int']>
  size_exists?: InputMaybe<Scalars['Boolean']>
  size_gt?: InputMaybe<Scalars['Int']>
  size_gte?: InputMaybe<Scalars['Int']>
  size_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  size_lt?: InputMaybe<Scalars['Int']>
  size_lte?: InputMaybe<Scalars['Int']>
  size_not?: InputMaybe<Scalars['Int']>
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  url?: InputMaybe<Scalars['String']>
  url_contains?: InputMaybe<Scalars['String']>
  url_exists?: InputMaybe<Scalars['Boolean']>
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  url_not?: InputMaybe<Scalars['String']>
  url_not_contains?: InputMaybe<Scalars['String']>
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  width?: InputMaybe<Scalars['Int']>
  width_exists?: InputMaybe<Scalars['Boolean']>
  width_gt?: InputMaybe<Scalars['Int']>
  width_gte?: InputMaybe<Scalars['Int']>
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  width_lt?: InputMaybe<Scalars['Int']>
  width_lte?: InputMaybe<Scalars['Int']>
  width_not?: InputMaybe<Scalars['Int']>
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
}

export type AssetLinkingCollections = {
  __typename?: 'AssetLinkingCollections'
  authorsCollection?: Maybe<AuthorsCollection>
  blogsCollection?: Maybe<BlogsCollection>
  entryCollection?: Maybe<EntryCollection>
  erc721TokenMetadataCollection?: Maybe<Erc721TokenMetadataCollection>
  productsCollection?: Maybe<ProductsCollection>
  rootSettingsCollection?: Maybe<RootSettingsCollection>
  sectionsDetailPersonComponentCollection?: Maybe<SectionsDetailPersonComponentCollection>
  sectionsFeaturesListComponentCollection?: Maybe<SectionsFeaturesListComponentCollection>
  sectionsHeroCollection?: Maybe<SectionsHeroCollection>
  sectionsImageCollection?: Maybe<SectionsImageCollection>
  sectionsMinimalsHistoryComponentCollection?: Maybe<SectionsMinimalsHistoryComponentCollection>
  sectionsMinimalsHumanComponentCollection?: Maybe<SectionsMinimalsHumanComponentCollection>
  sectionsMinimalsImagesListCollection?: Maybe<SectionsMinimalsImagesListCollection>
  sectionsMinimalsVideoCollection?: Maybe<SectionsMinimalsVideoCollection>
  sectionsTimerCollection?: Maybe<SectionsTimerCollection>
  smartContractErc721Collection?: Maybe<SmartContractErc721Collection>
  smartContractErc721TokenCollection?: Maybe<SmartContractErc721TokenCollection>
}

export type AssetLinkingCollectionsAuthorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AssetLinkingCollectionsAuthorsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsBlogsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AssetLinkingCollectionsBlogsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsErc721TokenMetadataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AssetLinkingCollectionsErc721TokenMetadataCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsProductsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AssetLinkingCollectionsProductsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsRootSettingsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AssetLinkingCollectionsRootSettingsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsSectionsDetailPersonComponentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<AssetLinkingCollectionsSectionsDetailPersonComponentCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsSectionsFeaturesListComponentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<AssetLinkingCollectionsSectionsFeaturesListComponentCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsSectionsHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AssetLinkingCollectionsSectionsHeroCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsSectionsImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AssetLinkingCollectionsSectionsImageCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsSectionsMinimalsHistoryComponentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<AssetLinkingCollectionsSectionsMinimalsHistoryComponentCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsSectionsMinimalsHumanComponentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<AssetLinkingCollectionsSectionsMinimalsHumanComponentCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsSectionsMinimalsImagesListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<AssetLinkingCollectionsSectionsMinimalsImagesListCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsSectionsMinimalsVideoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AssetLinkingCollectionsSectionsMinimalsVideoCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsSectionsTimerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AssetLinkingCollectionsSectionsTimerCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsSmartContractErc721CollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AssetLinkingCollectionsSmartContractErc721CollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsSmartContractErc721TokenCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<AssetLinkingCollectionsSmartContractErc721TokenCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum AssetLinkingCollectionsAuthorsCollectionOrder {
  GeneratedBlogStateAsc = 'generatedBlogState_ASC',
  GeneratedBlogStateDesc = 'generatedBlogState_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UseBlogAutoGeneratorAsc = 'useBlogAutoGenerator_ASC',
  UseBlogAutoGeneratorDesc = 'useBlogAutoGenerator_DESC',
}

export enum AssetLinkingCollectionsBlogsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum AssetLinkingCollectionsErc721TokenMetadataCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ExternalUrlAsc = 'externalUrl_ASC',
  ExternalUrlDesc = 'externalUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TokenIdAsc = 'tokenId_ASC',
  TokenIdDesc = 'tokenId_DESC',
  YoutubeUrlAsc = 'youtubeUrl_ASC',
  YoutubeUrlDesc = 'youtubeUrl_DESC',
}

export enum AssetLinkingCollectionsProductsCollectionOrder {
  AbstractAsc = 'abstract_ASC',
  AbstractDesc = 'abstract_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum AssetLinkingCollectionsRootSettingsCollectionOrder {
  CompanyColorAsc = 'companyColor_ASC',
  CompanyColorDesc = 'companyColor_DESC',
  RenameCallToActionLinksDisplayTextAsc = 'renameCallToActionLinksDisplayText_ASC',
  RenameCallToActionLinksDisplayTextDesc = 'renameCallToActionLinksDisplayText_DESC',
  RenamePathDisplayTextBlogAsc = 'renamePathDisplayTextBlog_ASC',
  RenamePathDisplayTextBlogDesc = 'renamePathDisplayTextBlog_DESC',
  RenamePathDisplayTextHomeAsc = 'renamePathDisplayTextHome_ASC',
  RenamePathDisplayTextHomeDesc = 'renamePathDisplayTextHome_DESC',
  RenamePathDisplayTextProductAsc = 'renamePathDisplayTextProduct_ASC',
  RenamePathDisplayTextProductDesc = 'renamePathDisplayTextProduct_DESC',
  ScopeAsc = 'scope_ASC',
  ScopeDesc = 'scope_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
}

export enum AssetLinkingCollectionsSectionsDetailPersonComponentCollectionOrder {
  DescriptionHrefAsc = 'descriptionHref_ASC',
  DescriptionHrefDesc = 'descriptionHref_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleHrefAsc = 'titleHref_ASC',
  TitleHrefDesc = 'titleHref_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum AssetLinkingCollectionsSectionsFeaturesListComponentCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IconifyIconNameAsc = 'iconifyIconName_ASC',
  IconifyIconNameDesc = 'iconifyIconName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum AssetLinkingCollectionsSectionsHeroCollectionOrder {
  CallToActionHrefAsc = 'callToActionHref_ASC',
  CallToActionHrefDesc = 'callToActionHref_DESC',
  CallToActionTextAsc = 'callToActionText_ASC',
  CallToActionTextDesc = 'callToActionText_DESC',
  CompanyNameAsc = 'companyName_ASC',
  CompanyNameDesc = 'companyName_DESC',
  EnableAiRecommendationAsc = 'enableAiRecommendation_ASC',
  EnableAiRecommendationDesc = 'enableAiRecommendation_DESC',
  EnableAiTrailAsc = 'enableAiTrail_ASC',
  EnableAiTrailDesc = 'enableAiTrail_DESC',
  FeatureTextAsc = 'featureText_ASC',
  FeatureTextDesc = 'featureText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum AssetLinkingCollectionsSectionsImageCollectionOrder {
  CallToActionHrefAsc = 'callToActionHref_ASC',
  CallToActionHrefDesc = 'callToActionHref_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum AssetLinkingCollectionsSectionsMinimalsHistoryComponentCollectionOrder {
  DisplayTimeAsc = 'displayTime_ASC',
  DisplayTimeDesc = 'displayTime_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum AssetLinkingCollectionsSectionsMinimalsHumanComponentCollectionOrder {
  DescriptionHrefAsc = 'descriptionHref_ASC',
  DescriptionHrefDesc = 'descriptionHref_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum AssetLinkingCollectionsSectionsMinimalsImagesListCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DisplayModeAsc = 'displayMode_ASC',
  DisplayModeDesc = 'displayMode_DESC',
  GridModeColumnsCountDesktopAsc = 'gridModeColumnsCountDesktop_ASC',
  GridModeColumnsCountDesktopDesc = 'gridModeColumnsCountDesktop_DESC',
  GridModeColumnsCountMobileAsc = 'gridModeColumnsCountMobile_ASC',
  GridModeColumnsCountMobileDesc = 'gridModeColumnsCountMobile_DESC',
  GridModeGapDesktopAsc = 'gridModeGapDesktop_ASC',
  GridModeGapDesktopDesc = 'gridModeGapDesktop_DESC',
  GridModeGapMobileAsc = 'gridModeGapMobile_ASC',
  GridModeGapMobileDesc = 'gridModeGapMobile_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum AssetLinkingCollectionsSectionsMinimalsVideoCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ModeAsc = 'mode_ASC',
  ModeDesc = 'mode_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  VideoRatioAsc = 'videoRatio_ASC',
  VideoRatioDesc = 'videoRatio_DESC',
  YoutubeEmbedLinkAsc = 'youtubeEmbedLink_ASC',
  YoutubeEmbedLinkDesc = 'youtubeEmbedLink_DESC',
}

export enum AssetLinkingCollectionsSectionsTimerCollectionOrder {
  AppearanceAsc = 'appearance_ASC',
  AppearanceDesc = 'appearance_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  StartTimeAsc = 'startTime_ASC',
  StartTimeDesc = 'startTime_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum AssetLinkingCollectionsSmartContractErc721CollectionOrder {
  IsSingleTokenMetadataModeAsc = 'isSingleTokenMetadataMode_ASC',
  IsSingleTokenMetadataModeDesc = 'isSingleTokenMetadataMode_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  SmartContractAddressAsc = 'smartContractAddress_ASC',
  SmartContractAddressDesc = 'smartContractAddress_DESC',
  SmartContractGistAsc = 'smartContractGist_ASC',
  SmartContractGistDesc = 'smartContractGist_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum AssetLinkingCollectionsSmartContractErc721TokenCollectionOrder {
  AnimationUrlAsc = 'animationUrl_ASC',
  AnimationUrlDesc = 'animationUrl_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ExternalUrlAsc = 'externalUrl_ASC',
  ExternalUrlDesc = 'externalUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TokenIdAsc = 'tokenId_ASC',
  TokenIdDesc = 'tokenId_DESC',
  YoutubeUrlAsc = 'youtubeUrl_ASC',
  YoutubeUrlDesc = 'youtubeUrl_DESC',
}

export enum AssetOrder {
  ContentTypeAsc = 'contentType_ASC',
  ContentTypeDesc = 'contentType_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/authors) */
export type Authors = Entry & {
  __typename?: 'Authors'
  blogIdeas?: Maybe<Array<Maybe<Scalars['String']>>>
  blogIdeasRelatedCategoriesCollection?: Maybe<AuthorsBlogIdeasRelatedCategoriesCollection>
  blogIdeasRelatedImagesCollection?: Maybe<AssetCollection>
  blogIdeasRelatedTagsCollection?: Maybe<AuthorsBlogIdeasRelatedTagsCollection>
  contentfulMetadata: ContentfulMetadata
  generatedBlogState?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<AuthorsLinkingCollections>
  name?: Maybe<Scalars['String']>
  profileImage?: Maybe<Asset>
  sys: Sys
  useBlogAutoGenerator?: Maybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/authors) */
export type AuthorsBlogIdeasArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/authors) */
export type AuthorsBlogIdeasRelatedCategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AuthorsBlogIdeasRelatedCategoriesCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CategoriesFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/authors) */
export type AuthorsBlogIdeasRelatedImagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/authors) */
export type AuthorsBlogIdeasRelatedTagsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AuthorsBlogIdeasRelatedTagsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TagsFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/authors) */
export type AuthorsGeneratedBlogStateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/authors) */
export type AuthorsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/authors) */
export type AuthorsNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/authors) */
export type AuthorsProfileImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/authors) */
export type AuthorsUseBlogAutoGeneratorArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type AuthorsBlogIdeasRelatedCategoriesCollection = {
  __typename?: 'AuthorsBlogIdeasRelatedCategoriesCollection'
  items: Array<Maybe<Categories>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum AuthorsBlogIdeasRelatedCategoriesCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type AuthorsBlogIdeasRelatedTagsCollection = {
  __typename?: 'AuthorsBlogIdeasRelatedTagsCollection'
  items: Array<Maybe<Tags>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum AuthorsBlogIdeasRelatedTagsCollectionOrder {
  GroupNameAsc = 'groupName_ASC',
  GroupNameDesc = 'groupName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type AuthorsCollection = {
  __typename?: 'AuthorsCollection'
  items: Array<Maybe<Authors>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type AuthorsFilter = {
  AND?: InputMaybe<Array<InputMaybe<AuthorsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<AuthorsFilter>>>
  blogIdeasRelatedCategories?: InputMaybe<CfCategoriesNestedFilter>
  blogIdeasRelatedCategoriesCollection_exists?: InputMaybe<Scalars['Boolean']>
  blogIdeasRelatedImagesCollection_exists?: InputMaybe<Scalars['Boolean']>
  blogIdeasRelatedTags?: InputMaybe<CfTagsNestedFilter>
  blogIdeasRelatedTagsCollection_exists?: InputMaybe<Scalars['Boolean']>
  blogIdeas_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  blogIdeas_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  blogIdeas_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  blogIdeas_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  generatedBlogState?: InputMaybe<Scalars['String']>
  generatedBlogState_contains?: InputMaybe<Scalars['String']>
  generatedBlogState_exists?: InputMaybe<Scalars['Boolean']>
  generatedBlogState_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  generatedBlogState_not?: InputMaybe<Scalars['String']>
  generatedBlogState_not_contains?: InputMaybe<Scalars['String']>
  generatedBlogState_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  profileImage_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  useBlogAutoGenerator?: InputMaybe<Scalars['Boolean']>
  useBlogAutoGenerator_exists?: InputMaybe<Scalars['Boolean']>
  useBlogAutoGenerator_not?: InputMaybe<Scalars['Boolean']>
}

export type AuthorsLinkingCollections = {
  __typename?: 'AuthorsLinkingCollections'
  blogsCollection?: Maybe<BlogsCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type AuthorsLinkingCollectionsBlogsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AuthorsLinkingCollectionsBlogsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AuthorsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum AuthorsLinkingCollectionsBlogsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum AuthorsOrder {
  GeneratedBlogStateAsc = 'generatedBlogState_ASC',
  GeneratedBlogStateDesc = 'generatedBlogState_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UseBlogAutoGeneratorAsc = 'useBlogAutoGenerator_ASC',
  UseBlogAutoGeneratorDesc = 'useBlogAutoGenerator_DESC',
}

export type AutoContent = {
  __typename?: 'AutoContent'
  htmlTag?: Maybe<Scalars['String']>
  originalText?: Maybe<Scalars['String']>
  purpose?: Maybe<Scalars['String']>
  text?: Maybe<Scalars['String']>
  useCase?: Maybe<Scalars['String']>
  useCaseId?: Maybe<Scalars['String']>
  useCaseSlug?: Maybe<Scalars['String']>
}

export type AutoGenArticleResponse = {
  __typename?: 'AutoGenArticleResponse'
  contents?: Maybe<Array<Maybe<AutoContent>>>
  contentsWithoutHtml?: Maybe<Scalars['String']>
  keywords?: Maybe<Array<Maybe<Scalars['String']>>>
  seoInfo?: Maybe<SeoInfo>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/blogs) */
export type Blogs = Entry & {
  __typename?: 'Blogs'
  author?: Maybe<Authors>
  callToActionLink?: Maybe<CallToActionLink>
  content?: Maybe<BlogsContent>
  contentfulMetadata: ContentfulMetadata
  coverImage?: Maybe<Asset>
  linkedFrom?: Maybe<BlogsLinkingCollections>
  previewImage?: Maybe<Asset>
  seoMetadataItemsCollection?: Maybe<BlogsSeoMetadataItemsCollection>
  sys: Sys
  tagsCollection?: Maybe<BlogsTagsCollection>
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/blogs) */
export type BlogsAuthorArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<AuthorsFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/blogs) */
export type BlogsCallToActionLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CallToActionLinkFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/blogs) */
export type BlogsContentArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/blogs) */
export type BlogsCoverImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/blogs) */
export type BlogsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/blogs) */
export type BlogsPreviewImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/blogs) */
export type BlogsSeoMetadataItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<BlogsSeoMetadataItemsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SeoMetadataFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/blogs) */
export type BlogsTagsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<BlogsTagsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TagsFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/blogs) */
export type BlogsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type BlogsCollection = {
  __typename?: 'BlogsCollection'
  items: Array<Maybe<Blogs>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type BlogsContent = {
  __typename?: 'BlogsContent'
  json: Scalars['JSON']
  links: BlogsContentLinks
}

export type BlogsContentAssets = {
  __typename?: 'BlogsContentAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type BlogsContentEntries = {
  __typename?: 'BlogsContentEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type BlogsContentLinks = {
  __typename?: 'BlogsContentLinks'
  assets: BlogsContentAssets
  entries: BlogsContentEntries
  resources: BlogsContentResources
}

export type BlogsContentResources = {
  __typename?: 'BlogsContentResources'
  block: Array<ResourceLink>
}

export type BlogsFilter = {
  AND?: InputMaybe<Array<InputMaybe<BlogsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<BlogsFilter>>>
  author?: InputMaybe<CfAuthorsNestedFilter>
  author_exists?: InputMaybe<Scalars['Boolean']>
  callToActionLink?: InputMaybe<CfCallToActionLinkNestedFilter>
  callToActionLink_exists?: InputMaybe<Scalars['Boolean']>
  content_contains?: InputMaybe<Scalars['String']>
  content_exists?: InputMaybe<Scalars['Boolean']>
  content_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  coverImage_exists?: InputMaybe<Scalars['Boolean']>
  previewImage_exists?: InputMaybe<Scalars['Boolean']>
  seoMetadataItems?: InputMaybe<CfSeoMetadataNestedFilter>
  seoMetadataItemsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  tags?: InputMaybe<CfTagsNestedFilter>
  tagsCollection_exists?: InputMaybe<Scalars['Boolean']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type BlogsLinkingCollections = {
  __typename?: 'BlogsLinkingCollections'
  categoriesCollection?: Maybe<CategoriesCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type BlogsLinkingCollectionsCategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<BlogsLinkingCollectionsCategoriesCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type BlogsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum BlogsLinkingCollectionsCategoriesCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum BlogsOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type BlogsSeoMetadataItemsCollection = {
  __typename?: 'BlogsSeoMetadataItemsCollection'
  items: Array<Maybe<SeoMetadata>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum BlogsSeoMetadataItemsCollectionOrder {
  ContentAsc = 'content_ASC',
  ContentDesc = 'content_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type BlogsTagsCollection = {
  __typename?: 'BlogsTagsCollection'
  items: Array<Maybe<Tags>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum BlogsTagsCollectionOrder {
  GroupNameAsc = 'groupName_ASC',
  GroupNameDesc = 'groupName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>
  _gt?: InputMaybe<Scalars['Boolean']>
  _gte?: InputMaybe<Scalars['Boolean']>
  _in?: InputMaybe<Array<Scalars['Boolean']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['Boolean']>
  _lte?: InputMaybe<Scalars['Boolean']>
  _neq?: InputMaybe<Scalars['Boolean']>
  _nin?: InputMaybe<Array<Scalars['Boolean']>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/callToActionLink) */
export type CallToActionLink = Entry & {
  __typename?: 'CallToActionLink'
  contentfulMetadata: ContentfulMetadata
  link?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<CallToActionLinkLinkingCollections>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/callToActionLink) */
export type CallToActionLinkLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/callToActionLink) */
export type CallToActionLinkLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/callToActionLink) */
export type CallToActionLinkTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type CallToActionLinkCollection = {
  __typename?: 'CallToActionLinkCollection'
  items: Array<Maybe<CallToActionLink>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CallToActionLinkFilter = {
  AND?: InputMaybe<Array<InputMaybe<CallToActionLinkFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CallToActionLinkFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  link?: InputMaybe<Scalars['String']>
  link_contains?: InputMaybe<Scalars['String']>
  link_exists?: InputMaybe<Scalars['Boolean']>
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  link_not?: InputMaybe<Scalars['String']>
  link_not_contains?: InputMaybe<Scalars['String']>
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CallToActionLinkLinkingCollections = {
  __typename?: 'CallToActionLinkLinkingCollections'
  blogsCollection?: Maybe<BlogsCollection>
  entryCollection?: Maybe<EntryCollection>
  productsCollection?: Maybe<ProductsCollection>
  rootSettingsCollection?: Maybe<RootSettingsCollection>
  sectionsMinimalsVideoCollection?: Maybe<SectionsMinimalsVideoCollection>
}

export type CallToActionLinkLinkingCollectionsBlogsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CallToActionLinkLinkingCollectionsBlogsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CallToActionLinkLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CallToActionLinkLinkingCollectionsProductsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CallToActionLinkLinkingCollectionsProductsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CallToActionLinkLinkingCollectionsRootSettingsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CallToActionLinkLinkingCollectionsRootSettingsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CallToActionLinkLinkingCollectionsSectionsMinimalsVideoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CallToActionLinkLinkingCollectionsSectionsMinimalsVideoCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum CallToActionLinkLinkingCollectionsBlogsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CallToActionLinkLinkingCollectionsProductsCollectionOrder {
  AbstractAsc = 'abstract_ASC',
  AbstractDesc = 'abstract_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CallToActionLinkLinkingCollectionsRootSettingsCollectionOrder {
  CompanyColorAsc = 'companyColor_ASC',
  CompanyColorDesc = 'companyColor_DESC',
  RenameCallToActionLinksDisplayTextAsc = 'renameCallToActionLinksDisplayText_ASC',
  RenameCallToActionLinksDisplayTextDesc = 'renameCallToActionLinksDisplayText_DESC',
  RenamePathDisplayTextBlogAsc = 'renamePathDisplayTextBlog_ASC',
  RenamePathDisplayTextBlogDesc = 'renamePathDisplayTextBlog_DESC',
  RenamePathDisplayTextHomeAsc = 'renamePathDisplayTextHome_ASC',
  RenamePathDisplayTextHomeDesc = 'renamePathDisplayTextHome_DESC',
  RenamePathDisplayTextProductAsc = 'renamePathDisplayTextProduct_ASC',
  RenamePathDisplayTextProductDesc = 'renamePathDisplayTextProduct_DESC',
  ScopeAsc = 'scope_ASC',
  ScopeDesc = 'scope_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
}

export enum CallToActionLinkLinkingCollectionsSectionsMinimalsVideoCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ModeAsc = 'mode_ASC',
  ModeDesc = 'mode_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  VideoRatioAsc = 'videoRatio_ASC',
  VideoRatioDesc = 'videoRatio_DESC',
  YoutubeEmbedLinkAsc = 'youtubeEmbedLink_ASC',
  YoutubeEmbedLinkDesc = 'youtubeEmbedLink_DESC',
}

export enum CallToActionLinkOrder {
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/categories) */
export type Categories = Entry & {
  __typename?: 'Categories'
  blogsCollection?: Maybe<CategoriesBlogsCollection>
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<CategoriesLinkingCollections>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/categories) */
export type CategoriesBlogsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CategoriesBlogsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<BlogsFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/categories) */
export type CategoriesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/categories) */
export type CategoriesTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type CategoriesBlogsCollection = {
  __typename?: 'CategoriesBlogsCollection'
  items: Array<Maybe<Blogs>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum CategoriesBlogsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type CategoriesCollection = {
  __typename?: 'CategoriesCollection'
  items: Array<Maybe<Categories>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CategoriesFilter = {
  AND?: InputMaybe<Array<InputMaybe<CategoriesFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CategoriesFilter>>>
  blogs?: InputMaybe<CfBlogsNestedFilter>
  blogsCollection_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CategoriesLinkingCollections = {
  __typename?: 'CategoriesLinkingCollections'
  authorsCollection?: Maybe<AuthorsCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type CategoriesLinkingCollectionsAuthorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CategoriesLinkingCollectionsAuthorsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CategoriesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum CategoriesLinkingCollectionsAuthorsCollectionOrder {
  GeneratedBlogStateAsc = 'generatedBlogState_ASC',
  GeneratedBlogStateDesc = 'generatedBlogState_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UseBlogAutoGeneratorAsc = 'useBlogAutoGenerator_ASC',
  UseBlogAutoGeneratorDesc = 'useBlogAutoGenerator_DESC',
}

export enum CategoriesOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ChromeExtensionPromptResponse = {
  __typename?: 'ChromeExtensionPromptResponse'
  category: Scalars['String']
  createdAt: Scalars['String']
  id: Scalars['String']
  prompt: Scalars['String']
  promptHint: Scalars['String']
  promptTeaser: Scalars['String']
  statistics: PromptStatistics
  title: Scalars['String']
  updatedAt: Scalars['String']
}

export type ContentfulMetadata = {
  __typename?: 'ContentfulMetadata'
  tags: Array<Maybe<ContentfulTag>>
}

export type ContentfulMetadataFilter = {
  tags?: InputMaybe<ContentfulMetadataTagsFilter>
  tags_exists?: InputMaybe<Scalars['Boolean']>
}

export type ContentfulMetadataTagsFilter = {
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/**
 * Represents a tag entity for finding and organizing content easily.
 *     Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulTag = {
  __typename?: 'ContentfulTag'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type ContextInput = {
  __typename?: 'ContextInput'
  _id: Scalars['String']
  inputMaximumCharacters: Scalars['Int']
  inputType: Scalars['String']
  isRequired?: Maybe<Scalars['Boolean']>
  keyLabel: Scalars['String']
  label: Scalars['String']
  placeholder: Scalars['String']
}

export type ContextInputInput = {
  _id: Scalars['String']
  inputMaximumCharacters: Scalars['Int']
  inputType: Scalars['String']
  inputValue?: InputMaybe<Scalars['String']>
  isRequired?: InputMaybe<Scalars['Boolean']>
  keyLabel: Scalars['String']
  label: Scalars['String']
  placeholder: Scalars['String']
}

export type CreatePortalSession = {
  __typename?: 'CreatePortalSession'
  redirectUrl: Scalars['String']
}

export type CreateStripeCheckoutSession = {
  __typename?: 'CreateStripeCheckoutSession'
  redirectUrl: Scalars['String']
}

export type DeletePromptResponse = {
  __typename?: 'DeletePromptResponse'
  affected_rows: Scalars['Int']
}

export type DislikePromptResponse = {
  __typename?: 'DislikePromptResponse'
  affected_rows: Scalars['Int']
  returning: Array<Maybe<GptHeroDislikePrompt>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/embeddings) */
export type Embeddings = Entry & {
  __typename?: 'Embeddings'
  contentfulMetadata: ContentfulMetadata
  embeddingLink?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<EmbeddingsLinkingCollections>
  sys: Sys
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/embeddings) */
export type EmbeddingsEmbeddingLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/embeddings) */
export type EmbeddingsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type EmbeddingsCollection = {
  __typename?: 'EmbeddingsCollection'
  items: Array<Maybe<Embeddings>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type EmbeddingsFilter = {
  AND?: InputMaybe<Array<InputMaybe<EmbeddingsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<EmbeddingsFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  embeddingLink?: InputMaybe<Scalars['String']>
  embeddingLink_contains?: InputMaybe<Scalars['String']>
  embeddingLink_exists?: InputMaybe<Scalars['Boolean']>
  embeddingLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  embeddingLink_not?: InputMaybe<Scalars['String']>
  embeddingLink_not_contains?: InputMaybe<Scalars['String']>
  embeddingLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type EmbeddingsLinkingCollections = {
  __typename?: 'EmbeddingsLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type EmbeddingsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum EmbeddingsOrder {
  EmbeddingLinkAsc = 'embeddingLink_ASC',
  EmbeddingLinkDesc = 'embeddingLink_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type Entry = {
  contentfulMetadata: ContentfulMetadata
  sys: Sys
}

export type EntryCollection = {
  __typename?: 'EntryCollection'
  items: Array<Maybe<Entry>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type EntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<EntryFilter>>>
  OR?: InputMaybe<Array<InputMaybe<EntryFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
}

export enum EntryOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/erc721TokenMetadata) */
export type Erc721TokenMetadata = Entry & {
  __typename?: 'Erc721TokenMetadata'
  animationUrl?: Maybe<Asset>
  attributesCollection?: Maybe<Erc721TokenMetadataAttributesCollection>
  backgroundColor?: Maybe<Scalars['String']>
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  externalUrl?: Maybe<Scalars['String']>
  image?: Maybe<Asset>
  linkedFrom?: Maybe<Erc721TokenMetadataLinkingCollections>
  name?: Maybe<Scalars['String']>
  sys: Sys
  tokenId?: Maybe<Scalars['String']>
  youtubeUrl?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/erc721TokenMetadata) */
export type Erc721TokenMetadataAnimationUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/erc721TokenMetadata) */
export type Erc721TokenMetadataAttributesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<Erc721TokenMetadataAttributesCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<Erc721TokenMetadataAttributesTextFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/erc721TokenMetadata) */
export type Erc721TokenMetadataBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/erc721TokenMetadata) */
export type Erc721TokenMetadataDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/erc721TokenMetadata) */
export type Erc721TokenMetadataExternalUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/erc721TokenMetadata) */
export type Erc721TokenMetadataImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/erc721TokenMetadata) */
export type Erc721TokenMetadataLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/erc721TokenMetadata) */
export type Erc721TokenMetadataNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/erc721TokenMetadata) */
export type Erc721TokenMetadataTokenIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/erc721TokenMetadata) */
export type Erc721TokenMetadataYoutubeUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/erc721TokenMetadataAttributeTraitType) */
export type Erc721TokenMetadataAttributeTraitType = Entry & {
  __typename?: 'Erc721TokenMetadataAttributeTraitType'
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<Erc721TokenMetadataAttributeTraitTypeLinkingCollections>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/erc721TokenMetadataAttributeTraitType) */
export type Erc721TokenMetadataAttributeTraitTypeLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/erc721TokenMetadataAttributeTraitType) */
export type Erc721TokenMetadataAttributeTraitTypeTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type Erc721TokenMetadataAttributeTraitTypeCollection = {
  __typename?: 'Erc721TokenMetadataAttributeTraitTypeCollection'
  items: Array<Maybe<Erc721TokenMetadataAttributeTraitType>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type Erc721TokenMetadataAttributeTraitTypeFilter = {
  AND?: InputMaybe<Array<InputMaybe<Erc721TokenMetadataAttributeTraitTypeFilter>>>
  OR?: InputMaybe<Array<InputMaybe<Erc721TokenMetadataAttributeTraitTypeFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type Erc721TokenMetadataAttributeTraitTypeLinkingCollections = {
  __typename?: 'Erc721TokenMetadataAttributeTraitTypeLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  erc721TokenMetadataAttributesTextCollection?: Maybe<Erc721TokenMetadataAttributesTextCollection>
}

export type Erc721TokenMetadataAttributeTraitTypeLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type Erc721TokenMetadataAttributeTraitTypeLinkingCollectionsErc721TokenMetadataAttributesTextCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<Erc721TokenMetadataAttributeTraitTypeLinkingCollectionsErc721TokenMetadataAttributesTextCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum Erc721TokenMetadataAttributeTraitTypeLinkingCollectionsErc721TokenMetadataAttributesTextCollectionOrder {
  DisplayTypeAsc = 'displayType_ASC',
  DisplayTypeDesc = 'displayType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export enum Erc721TokenMetadataAttributeTraitTypeOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type Erc721TokenMetadataAttributesCollection = {
  __typename?: 'Erc721TokenMetadataAttributesCollection'
  items: Array<Maybe<Erc721TokenMetadataAttributesText>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum Erc721TokenMetadataAttributesCollectionOrder {
  DisplayTypeAsc = 'displayType_ASC',
  DisplayTypeDesc = 'displayType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

/** ref: https://docs.opensea.io/docs/metadata-standards [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/erc721TokenMetadataAttributesText) */
export type Erc721TokenMetadataAttributesText = Entry & {
  __typename?: 'Erc721TokenMetadataAttributesText'
  contentfulMetadata: ContentfulMetadata
  displayType?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<Erc721TokenMetadataAttributesTextLinkingCollections>
  sys: Sys
  traitType?: Maybe<Erc721TokenMetadataAttributeTraitType>
  value?: Maybe<Scalars['String']>
}

/** ref: https://docs.opensea.io/docs/metadata-standards [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/erc721TokenMetadataAttributesText) */
export type Erc721TokenMetadataAttributesTextDisplayTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** ref: https://docs.opensea.io/docs/metadata-standards [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/erc721TokenMetadataAttributesText) */
export type Erc721TokenMetadataAttributesTextLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** ref: https://docs.opensea.io/docs/metadata-standards [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/erc721TokenMetadataAttributesText) */
export type Erc721TokenMetadataAttributesTextTraitTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<Erc721TokenMetadataAttributeTraitTypeFilter>
}

/** ref: https://docs.opensea.io/docs/metadata-standards [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/erc721TokenMetadataAttributesText) */
export type Erc721TokenMetadataAttributesTextValueArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type Erc721TokenMetadataAttributesTextCollection = {
  __typename?: 'Erc721TokenMetadataAttributesTextCollection'
  items: Array<Maybe<Erc721TokenMetadataAttributesText>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type Erc721TokenMetadataAttributesTextFilter = {
  AND?: InputMaybe<Array<InputMaybe<Erc721TokenMetadataAttributesTextFilter>>>
  OR?: InputMaybe<Array<InputMaybe<Erc721TokenMetadataAttributesTextFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  displayType?: InputMaybe<Scalars['String']>
  displayType_contains?: InputMaybe<Scalars['String']>
  displayType_exists?: InputMaybe<Scalars['Boolean']>
  displayType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayType_not?: InputMaybe<Scalars['String']>
  displayType_not_contains?: InputMaybe<Scalars['String']>
  displayType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  traitType?: InputMaybe<CfErc721TokenMetadataAttributeTraitTypeNestedFilter>
  traitType_exists?: InputMaybe<Scalars['Boolean']>
  value?: InputMaybe<Scalars['String']>
  value_contains?: InputMaybe<Scalars['String']>
  value_exists?: InputMaybe<Scalars['Boolean']>
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  value_not?: InputMaybe<Scalars['String']>
  value_not_contains?: InputMaybe<Scalars['String']>
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type Erc721TokenMetadataAttributesTextLinkingCollections = {
  __typename?: 'Erc721TokenMetadataAttributesTextLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  erc721TokenMetadataCollection?: Maybe<Erc721TokenMetadataCollection>
}

export type Erc721TokenMetadataAttributesTextLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type Erc721TokenMetadataAttributesTextLinkingCollectionsErc721TokenMetadataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<Erc721TokenMetadataAttributesTextLinkingCollectionsErc721TokenMetadataCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum Erc721TokenMetadataAttributesTextLinkingCollectionsErc721TokenMetadataCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ExternalUrlAsc = 'externalUrl_ASC',
  ExternalUrlDesc = 'externalUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TokenIdAsc = 'tokenId_ASC',
  TokenIdDesc = 'tokenId_DESC',
  YoutubeUrlAsc = 'youtubeUrl_ASC',
  YoutubeUrlDesc = 'youtubeUrl_DESC',
}

export enum Erc721TokenMetadataAttributesTextOrder {
  DisplayTypeAsc = 'displayType_ASC',
  DisplayTypeDesc = 'displayType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export type Erc721TokenMetadataCollection = {
  __typename?: 'Erc721TokenMetadataCollection'
  items: Array<Maybe<Erc721TokenMetadata>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type Erc721TokenMetadataFilter = {
  AND?: InputMaybe<Array<InputMaybe<Erc721TokenMetadataFilter>>>
  OR?: InputMaybe<Array<InputMaybe<Erc721TokenMetadataFilter>>>
  animationUrl_exists?: InputMaybe<Scalars['Boolean']>
  attributes?: InputMaybe<CfErc721TokenMetadataAttributesTextNestedFilter>
  attributesCollection_exists?: InputMaybe<Scalars['Boolean']>
  backgroundColor?: InputMaybe<Scalars['String']>
  backgroundColor_contains?: InputMaybe<Scalars['String']>
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  backgroundColor_not?: InputMaybe<Scalars['String']>
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalUrl?: InputMaybe<Scalars['String']>
  externalUrl_contains?: InputMaybe<Scalars['String']>
  externalUrl_exists?: InputMaybe<Scalars['Boolean']>
  externalUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalUrl_not?: InputMaybe<Scalars['String']>
  externalUrl_not_contains?: InputMaybe<Scalars['String']>
  externalUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  image_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tokenId?: InputMaybe<Scalars['String']>
  tokenId_contains?: InputMaybe<Scalars['String']>
  tokenId_exists?: InputMaybe<Scalars['Boolean']>
  tokenId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  tokenId_not?: InputMaybe<Scalars['String']>
  tokenId_not_contains?: InputMaybe<Scalars['String']>
  tokenId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  youtubeUrl?: InputMaybe<Scalars['String']>
  youtubeUrl_contains?: InputMaybe<Scalars['String']>
  youtubeUrl_exists?: InputMaybe<Scalars['Boolean']>
  youtubeUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  youtubeUrl_not?: InputMaybe<Scalars['String']>
  youtubeUrl_not_contains?: InputMaybe<Scalars['String']>
  youtubeUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type Erc721TokenMetadataLinkingCollections = {
  __typename?: 'Erc721TokenMetadataLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  smartContractErc721Collection?: Maybe<SmartContractErc721Collection>
}

export type Erc721TokenMetadataLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type Erc721TokenMetadataLinkingCollectionsSmartContractErc721CollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<Erc721TokenMetadataLinkingCollectionsSmartContractErc721CollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum Erc721TokenMetadataLinkingCollectionsSmartContractErc721CollectionOrder {
  IsSingleTokenMetadataModeAsc = 'isSingleTokenMetadataMode_ASC',
  IsSingleTokenMetadataModeDesc = 'isSingleTokenMetadataMode_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  SmartContractAddressAsc = 'smartContractAddress_ASC',
  SmartContractAddressDesc = 'smartContractAddress_DESC',
  SmartContractGistAsc = 'smartContractGist_ASC',
  SmartContractGistDesc = 'smartContractGist_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum Erc721TokenMetadataOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ExternalUrlAsc = 'externalUrl_ASC',
  ExternalUrlDesc = 'externalUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TokenIdAsc = 'tokenId_ASC',
  TokenIdDesc = 'tokenId_DESC',
  YoutubeUrlAsc = 'youtubeUrl_ASC',
  YoutubeUrlDesc = 'youtubeUrl_DESC',
}

export enum Format {
  Html = 'html',
  Text = 'text',
}

export type GenerateImageResponse = {
  __typename?: 'GenerateImageResponse'
  formattedPrompt?: Maybe<Scalars['String']>
  imageCreateV1?: Maybe<ImageCreateResponse>
  imageCreateV2?: Maybe<ImageCreateResponse>
  prompt: Scalars['String']
}

export type GenerateImageResponseImageCreateV1Args = {
  format?: InputMaybe<ImageCreateResponseFormat>
}

export type GenerateImageResponseImageCreateV2Args = {
  format?: InputMaybe<ImageCreateResponseFormat>
}

export type GenericInput = {
  format: Format
  id?: InputMaybe<Scalars['String']>
  language: SupportingLanguageInput
  retryTimes: Scalars['Int']
  tone: SupportingToneInput
  useCase: SupportingUseCaseInput
  variations: Scalars['Int']
}

export type GenericResponse = {
  __typename?: 'GenericResponse'
  id?: Maybe<Scalars['String']>
  language: SupportingLanguage
  text?: Maybe<Scalars['String']>
  textVariations?: Maybe<Array<Maybe<TextVariation>>>
  tone: SupportingTone
  useCase: SupportingUseCase
}

export type GptHeroDislikePrompt = {
  __typename?: 'GptHeroDislikePrompt'
  likes: Scalars['Int']
}

export type GptHeroLikePrompt = {
  __typename?: 'GptHeroLikePrompt'
  likes: Scalars['Int']
}

export type GptHeroSeenPrompt = {
  __typename?: 'GptHeroSeenPrompt'
  seen: Scalars['Int']
}

export type ImageCreateResponse = {
  __typename?: 'ImageCreateResponse'
  created?: Maybe<Scalars['String']>
  data?: Maybe<Array<Maybe<ImageCreateResponseData>>>
  id: Scalars['String']
  promptInput?: Maybe<Scalars['String']>
}

export type ImageCreateResponseData = {
  __typename?: 'ImageCreateResponseData'
  b64_json?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export enum ImageCreateResponseFormat {
  B64Json = 'b64_json',
  Url = 'url',
}

export enum ImageFormat {
  Avif = 'AVIF',
  /** JPG image format. */
  Jpg = 'JPG',
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JpgProgressive = 'JPG_PROGRESSIVE',
  /** PNG image format */
  Png = 'PNG',
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  Png8 = 'PNG8',
  /** WebP image format. */
  Webp = 'WEBP',
}

export enum ImageResizeFocus {
  /** Focus the resizing on the bottom. */
  Bottom = 'BOTTOM',
  /** Focus the resizing on the bottom left. */
  BottomLeft = 'BOTTOM_LEFT',
  /** Focus the resizing on the bottom right. */
  BottomRight = 'BOTTOM_RIGHT',
  /** Focus the resizing on the center. */
  Center = 'CENTER',
  /** Focus the resizing on the largest face. */
  Face = 'FACE',
  /** Focus the resizing on the area containing all the faces. */
  Faces = 'FACES',
  /** Focus the resizing on the left. */
  Left = 'LEFT',
  /** Focus the resizing on the right. */
  Right = 'RIGHT',
  /** Focus the resizing on the top. */
  Top = 'TOP',
  /** Focus the resizing on the top left. */
  TopLeft = 'TOP_LEFT',
  /** Focus the resizing on the top right. */
  TopRight = 'TOP_RIGHT',
}

export enum ImageResizeStrategy {
  /** Crops a part of the original image to fit into the specified dimensions. */
  Crop = 'CROP',
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  Fill = 'FILL',
  /** Resizes the image to fit into the specified dimensions. */
  Fit = 'FIT',
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  Pad = 'PAD',
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  Scale = 'SCALE',
  /** Creates a thumbnail from the image. */
  Thumb = 'THUMB',
}

export type ImageTransformOptions = {
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor?: InputMaybe<Scalars['HexColor']>
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius?: InputMaybe<Scalars['Int']>
  /** Desired image format. Defaults to the original image format. */
  format?: InputMaybe<ImageFormat>
  /** Desired height in pixels. Defaults to the original image height. */
  height?: InputMaybe<Scalars['Dimension']>
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality?: InputMaybe<Scalars['Quality']>
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus?: InputMaybe<ImageResizeFocus>
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy?: InputMaybe<ImageResizeStrategy>
  /** Desired width in pixels. Defaults to the original image width. */
  width?: InputMaybe<Scalars['Dimension']>
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>
  _gt?: InputMaybe<Scalars['Int']>
  _gte?: InputMaybe<Scalars['Int']>
  _in?: InputMaybe<Array<Scalars['Int']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['Int']>
  _lte?: InputMaybe<Scalars['Int']>
  _neq?: InputMaybe<Scalars['Int']>
  _nin?: InputMaybe<Array<Scalars['Int']>>
}

export enum LanguageSlug {
  ChineseTraditional = 'chineseTraditional',
  English = 'english',
}

export type LikePromptResponse = {
  __typename?: 'LikePromptResponse'
  affected_rows: Scalars['Int']
  returning: Array<Maybe<GptHeroLikePrompt>>
}

export type MetaItem = {
  __typename?: 'MetaItem'
  content?: Maybe<Scalars['String']>
  property?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/pages) */
export type Pages = Entry & {
  __typename?: 'Pages'
  contentfulMetadata: ContentfulMetadata
  displayName?: Maybe<Scalars['String']>
  iconifyIconNameForMobileMenu?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<PagesLinkingCollections>
  sectionsCollection?: Maybe<PagesSectionsCollection>
  seoMetadataItemsCollection?: Maybe<PagesSeoMetadataItemsCollection>
  slug?: Maybe<Scalars['String']>
  sys: Sys
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/pages) */
export type PagesDisplayNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/pages) */
export type PagesIconifyIconNameForMobileMenuArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/pages) */
export type PagesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/pages) */
export type PagesSectionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PagesSectionsFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/pages) */
export type PagesSeoMetadataItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PagesSeoMetadataItemsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SeoMetadataFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/pages) */
export type PagesSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type PagesCollection = {
  __typename?: 'PagesCollection'
  items: Array<Maybe<Pages>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PagesFilter = {
  AND?: InputMaybe<Array<InputMaybe<PagesFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PagesFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  displayName?: InputMaybe<Scalars['String']>
  displayName_contains?: InputMaybe<Scalars['String']>
  displayName_exists?: InputMaybe<Scalars['Boolean']>
  displayName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayName_not?: InputMaybe<Scalars['String']>
  displayName_not_contains?: InputMaybe<Scalars['String']>
  displayName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  iconifyIconNameForMobileMenu?: InputMaybe<Scalars['String']>
  iconifyIconNameForMobileMenu_contains?: InputMaybe<Scalars['String']>
  iconifyIconNameForMobileMenu_exists?: InputMaybe<Scalars['Boolean']>
  iconifyIconNameForMobileMenu_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  iconifyIconNameForMobileMenu_not?: InputMaybe<Scalars['String']>
  iconifyIconNameForMobileMenu_not_contains?: InputMaybe<Scalars['String']>
  iconifyIconNameForMobileMenu_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sections?: InputMaybe<CfsectionsMultiTypeNestedFilter>
  sectionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  seoMetadataItems?: InputMaybe<CfSeoMetadataNestedFilter>
  seoMetadataItemsCollection_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type PagesLinkingCollections = {
  __typename?: 'PagesLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  rootSettingsCollection?: Maybe<RootSettingsCollection>
}

export type PagesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagesLinkingCollectionsRootSettingsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PagesLinkingCollectionsRootSettingsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum PagesLinkingCollectionsRootSettingsCollectionOrder {
  CompanyColorAsc = 'companyColor_ASC',
  CompanyColorDesc = 'companyColor_DESC',
  RenameCallToActionLinksDisplayTextAsc = 'renameCallToActionLinksDisplayText_ASC',
  RenameCallToActionLinksDisplayTextDesc = 'renameCallToActionLinksDisplayText_DESC',
  RenamePathDisplayTextBlogAsc = 'renamePathDisplayTextBlog_ASC',
  RenamePathDisplayTextBlogDesc = 'renamePathDisplayTextBlog_DESC',
  RenamePathDisplayTextHomeAsc = 'renamePathDisplayTextHome_ASC',
  RenamePathDisplayTextHomeDesc = 'renamePathDisplayTextHome_DESC',
  RenamePathDisplayTextProductAsc = 'renamePathDisplayTextProduct_ASC',
  RenamePathDisplayTextProductDesc = 'renamePathDisplayTextProduct_DESC',
  ScopeAsc = 'scope_ASC',
  ScopeDesc = 'scope_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
}

export enum PagesOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  IconifyIconNameForMobileMenuAsc = 'iconifyIconNameForMobileMenu_ASC',
  IconifyIconNameForMobileMenuDesc = 'iconifyIconNameForMobileMenu_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PagesSectionsCollection = {
  __typename?: 'PagesSectionsCollection'
  items: Array<Maybe<PagesSectionsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PagesSectionsFilter = {
  AND?: InputMaybe<Array<InputMaybe<PagesSectionsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PagesSectionsFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
}

export type PagesSectionsItem =
  | SectionsDetailPersonList
  | SectionsFaqList
  | SectionsFeaturesList
  | SectionsForm
  | SectionsHero
  | SectionsImage
  | SectionsMinimalsContactList
  | SectionsMinimalsGallery
  | SectionsMinimalsHistoryList
  | SectionsMinimalsHumanList
  | SectionsMinimalsImagesList
  | SectionsMinimalsServices
  | SectionsMinimalsVideo
  | SectionsMintList
  | SectionsTimer

export type PagesSeoMetadataItemsCollection = {
  __typename?: 'PagesSeoMetadataItemsCollection'
  items: Array<Maybe<SeoMetadata>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum PagesSeoMetadataItemsCollectionOrder {
  ContentAsc = 'content_ASC',
  ContentDesc = 'content_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/products) */
export type Products = Entry & {
  __typename?: 'Products'
  abstract?: Maybe<Scalars['String']>
  callToActionLink?: Maybe<CallToActionLink>
  content?: Maybe<ProductsContent>
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  imagesCollection?: Maybe<AssetCollection>
  linkedFrom?: Maybe<ProductsLinkingCollections>
  previewImage?: Maybe<Asset>
  price?: Maybe<Scalars['Float']>
  seoMetadataItemsCollection?: Maybe<ProductsSeoMetadataItemsCollection>
  slug?: Maybe<Scalars['String']>
  sys: Sys
  tagsCollection?: Maybe<ProductsTagsCollection>
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/products) */
export type ProductsAbstractArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/products) */
export type ProductsCallToActionLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CallToActionLinkFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/products) */
export type ProductsContentArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/products) */
export type ProductsDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/products) */
export type ProductsImagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/products) */
export type ProductsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/products) */
export type ProductsPreviewImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/products) */
export type ProductsPriceArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/products) */
export type ProductsSeoMetadataItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ProductsSeoMetadataItemsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SeoMetadataFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/products) */
export type ProductsSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/products) */
export type ProductsTagsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ProductsTagsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TagsFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/products) */
export type ProductsTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ProductsCollection = {
  __typename?: 'ProductsCollection'
  items: Array<Maybe<Products>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ProductsContent = {
  __typename?: 'ProductsContent'
  json: Scalars['JSON']
  links: ProductsContentLinks
}

export type ProductsContentAssets = {
  __typename?: 'ProductsContentAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ProductsContentEntries = {
  __typename?: 'ProductsContentEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ProductsContentLinks = {
  __typename?: 'ProductsContentLinks'
  assets: ProductsContentAssets
  entries: ProductsContentEntries
  resources: ProductsContentResources
}

export type ProductsContentResources = {
  __typename?: 'ProductsContentResources'
  block: Array<ResourceLink>
}

export type ProductsFilter = {
  AND?: InputMaybe<Array<InputMaybe<ProductsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ProductsFilter>>>
  abstract?: InputMaybe<Scalars['String']>
  abstract_contains?: InputMaybe<Scalars['String']>
  abstract_exists?: InputMaybe<Scalars['Boolean']>
  abstract_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  abstract_not?: InputMaybe<Scalars['String']>
  abstract_not_contains?: InputMaybe<Scalars['String']>
  abstract_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionLink?: InputMaybe<CfCallToActionLinkNestedFilter>
  callToActionLink_exists?: InputMaybe<Scalars['Boolean']>
  content_contains?: InputMaybe<Scalars['String']>
  content_exists?: InputMaybe<Scalars['Boolean']>
  content_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  imagesCollection_exists?: InputMaybe<Scalars['Boolean']>
  previewImage_exists?: InputMaybe<Scalars['Boolean']>
  price?: InputMaybe<Scalars['Float']>
  price_exists?: InputMaybe<Scalars['Boolean']>
  price_gt?: InputMaybe<Scalars['Float']>
  price_gte?: InputMaybe<Scalars['Float']>
  price_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  price_lt?: InputMaybe<Scalars['Float']>
  price_lte?: InputMaybe<Scalars['Float']>
  price_not?: InputMaybe<Scalars['Float']>
  price_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  seoMetadataItems?: InputMaybe<CfSeoMetadataNestedFilter>
  seoMetadataItemsCollection_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tags?: InputMaybe<CfTagsNestedFilter>
  tagsCollection_exists?: InputMaybe<Scalars['Boolean']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ProductsLinkingCollections = {
  __typename?: 'ProductsLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type ProductsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ProductsOrder {
  AbstractAsc = 'abstract_ASC',
  AbstractDesc = 'abstract_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ProductsSeoMetadataItemsCollection = {
  __typename?: 'ProductsSeoMetadataItemsCollection'
  items: Array<Maybe<SeoMetadata>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ProductsSeoMetadataItemsCollectionOrder {
  ContentAsc = 'content_ASC',
  ContentDesc = 'content_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ProductsTagsCollection = {
  __typename?: 'ProductsTagsCollection'
  items: Array<Maybe<Tags>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ProductsTagsCollectionOrder {
  GroupNameAsc = 'groupName_ASC',
  GroupNameDesc = 'groupName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PromptChoice = {
  __typename?: 'PromptChoice'
  finish_reason: Scalars['String']
  index: Scalars['Int']
  text: Scalars['String']
}

export type PromptCreateResponse = {
  __typename?: 'PromptCreateResponse'
  choices: Array<PromptChoice>
  created: Scalars['String']
  id: Scalars['String']
  model: Scalars['String']
  object: Scalars['String']
  usage: PromptUsage
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/promptEngineering) */
export type PromptEngineering = Entry & {
  __typename?: 'PromptEngineering'
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<PromptEngineeringLinkingCollections>
  prompt?: Maybe<Scalars['String']>
  promptHint?: Maybe<Scalars['String']>
  purpose?: Maybe<Scalars['String']>
  sys: Sys
  teaser?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/promptEngineering) */
export type PromptEngineeringLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/promptEngineering) */
export type PromptEngineeringPromptArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/promptEngineering) */
export type PromptEngineeringPromptHintArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/promptEngineering) */
export type PromptEngineeringPurposeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/promptEngineering) */
export type PromptEngineeringTeaserArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/promptEngineering) */
export type PromptEngineeringTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type PromptEngineeringCollection = {
  __typename?: 'PromptEngineeringCollection'
  items: Array<Maybe<PromptEngineering>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PromptEngineeringFilter = {
  AND?: InputMaybe<Array<InputMaybe<PromptEngineeringFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PromptEngineeringFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  prompt?: InputMaybe<Scalars['String']>
  promptHint?: InputMaybe<Scalars['String']>
  promptHint_contains?: InputMaybe<Scalars['String']>
  promptHint_exists?: InputMaybe<Scalars['Boolean']>
  promptHint_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  promptHint_not?: InputMaybe<Scalars['String']>
  promptHint_not_contains?: InputMaybe<Scalars['String']>
  promptHint_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  prompt_contains?: InputMaybe<Scalars['String']>
  prompt_exists?: InputMaybe<Scalars['Boolean']>
  prompt_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  prompt_not?: InputMaybe<Scalars['String']>
  prompt_not_contains?: InputMaybe<Scalars['String']>
  prompt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  purpose?: InputMaybe<Scalars['String']>
  purpose_contains?: InputMaybe<Scalars['String']>
  purpose_exists?: InputMaybe<Scalars['Boolean']>
  purpose_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  purpose_not?: InputMaybe<Scalars['String']>
  purpose_not_contains?: InputMaybe<Scalars['String']>
  purpose_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teaser?: InputMaybe<Scalars['String']>
  teaser_contains?: InputMaybe<Scalars['String']>
  teaser_exists?: InputMaybe<Scalars['Boolean']>
  teaser_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  teaser_not?: InputMaybe<Scalars['String']>
  teaser_not_contains?: InputMaybe<Scalars['String']>
  teaser_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PromptEngineeringLinkingCollections = {
  __typename?: 'PromptEngineeringLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  rootSettingsCollection?: Maybe<RootSettingsCollection>
}

export type PromptEngineeringLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PromptEngineeringLinkingCollectionsRootSettingsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<PromptEngineeringLinkingCollectionsRootSettingsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum PromptEngineeringLinkingCollectionsRootSettingsCollectionOrder {
  CompanyColorAsc = 'companyColor_ASC',
  CompanyColorDesc = 'companyColor_DESC',
  RenameCallToActionLinksDisplayTextAsc = 'renameCallToActionLinksDisplayText_ASC',
  RenameCallToActionLinksDisplayTextDesc = 'renameCallToActionLinksDisplayText_DESC',
  RenamePathDisplayTextBlogAsc = 'renamePathDisplayTextBlog_ASC',
  RenamePathDisplayTextBlogDesc = 'renamePathDisplayTextBlog_DESC',
  RenamePathDisplayTextHomeAsc = 'renamePathDisplayTextHome_ASC',
  RenamePathDisplayTextHomeDesc = 'renamePathDisplayTextHome_DESC',
  RenamePathDisplayTextProductAsc = 'renamePathDisplayTextProduct_ASC',
  RenamePathDisplayTextProductDesc = 'renamePathDisplayTextProduct_DESC',
  ScopeAsc = 'scope_ASC',
  ScopeDesc = 'scope_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
}

export enum PromptEngineeringOrder {
  PurposeAsc = 'purpose_ASC',
  PurposeDesc = 'purpose_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type PromptInterface = {
  __typename?: 'PromptInterface'
  createdAt: Scalars['String']
  id: Scalars['String']
  prompt: Scalars['String']
  promptHint: Scalars['String']
  promptTeaser: Scalars['String']
  statistics: PromptStatistics
  updatedAt: Scalars['String']
}

export type PromptResponse = {
  __typename?: 'PromptResponse'
  createResponse?: Maybe<PromptCreateResponse>
  id: Scalars['String']
  language: PromptSupportingLanguageMap
  prompt: Scalars['String']
  promptInterface?: Maybe<PromptInterface>
}

export type PromptStatistics = {
  __typename?: 'PromptStatistics'
  usages: Scalars['Float']
  views: Scalars['Float']
  votes: Scalars['Float']
}

export enum PromptSupportingLanguageMap {
  Arabic = 'Arabic',
  Bulgarian = 'Bulgarian',
  Catalan = 'Catalan',
  Chinese = 'Chinese',
  Czech = 'Czech',
  Danish = 'Danish',
  Dutch = 'Dutch',
  English = 'English',
  Finnish = 'Finnish',
  French = 'French',
  German = 'German',
  Greek = 'Greek',
  Hebrew = 'Hebrew',
  Hindi = 'Hindi',
  Hungarian = 'Hungarian',
  Indonesian = 'Indonesian',
  Italian = 'Italian',
  Japanese = 'Japanese',
  Korean = 'Korean',
  MandarinChinese = 'MandarinChinese',
  Norwegian = 'Norwegian',
  Polish = 'Polish',
  Portuguese = 'Portuguese',
  Romanian = 'Romanian',
  Russian = 'Russian',
  Serbian = 'Serbian',
  Spanish = 'Spanish',
  Swedish = 'Swedish',
  Thai = 'Thai',
  Turkish = 'Turkish',
  Ukrainian = 'Ukrainian',
  Vietnamese = 'Vietnamese',
}

export type PromptUsage = {
  __typename?: 'PromptUsage'
  completion_tokens: Scalars['Float']
  prompt_tokens: Scalars['Float']
  total_tokens: Scalars['Float']
}

export type ResourceLink = {
  __typename?: 'ResourceLink'
  sys: ResourceSys
}

export type ResourceSys = {
  __typename?: 'ResourceSys'
  linkType: Scalars['String']
  type: Scalars['String']
  urn: Scalars['String']
}

export enum ResponseTone {
  Authoritative = 'Authoritative',
  Clinical = 'Clinical',
  Cold = 'Cold',
  Confident = 'Confident',
  Cynical = 'Cynical',
  Emotional = 'Emotional',
  Empathetic = 'Empathetic',
  Formal = 'Formal',
  Friendly = 'Friendly',
  Humorous = 'Humorous',
  Informal = 'Informal',
  Ironic = 'Ironic',
  Label = 'Label',
  Optimistic = 'Optimistic',
  Pessimistic = 'Pessimistic',
  Playful = 'Playful',
  Sarcastic = 'Sarcastic',
  Serious = 'Serious',
  Sympathetic = 'Sympathetic',
  Tentative = 'Tentative',
  Warm = 'Warm',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/rootSettings) */
export type RootSettings = Entry & {
  __typename?: 'RootSettings'
  callToActionLink?: Maybe<CallToActionLink>
  companyColor?: Maybe<Scalars['String']>
  companyFavicon?: Maybe<Asset>
  companyLogo?: Maybe<Asset>
  contentfulMetadata: ContentfulMetadata
  headerDisplayPagesCollection?: Maybe<RootSettingsHeaderDisplayPagesCollection>
  linkedFrom?: Maybe<RootSettingsLinkingCollections>
  mobileDrawerDisplayPagesCollection?: Maybe<RootSettingsMobileDrawerDisplayPagesCollection>
  promptEngineeringsCollection?: Maybe<RootSettingsPromptEngineeringsCollection>
  renameCallToActionLinksDisplayText?: Maybe<Scalars['String']>
  renamePathDisplayTextBlog?: Maybe<Scalars['String']>
  renamePathDisplayTextHome?: Maybe<Scalars['String']>
  renamePathDisplayTextProduct?: Maybe<Scalars['String']>
  scope?: Maybe<Scalars['String']>
  sectionColors?: Maybe<Array<Maybe<Scalars['String']>>>
  seoMetadataItemsCollection?: Maybe<RootSettingsSeoMetadataItemsCollection>
  socialMediaLinksCollection?: Maybe<RootSettingsSocialMediaLinksCollection>
  sys: Sys
  theme?: Maybe<Scalars['String']>
  writersoftModules?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/rootSettings) */
export type RootSettingsCallToActionLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CallToActionLinkFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/rootSettings) */
export type RootSettingsCompanyColorArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/rootSettings) */
export type RootSettingsCompanyFaviconArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/rootSettings) */
export type RootSettingsCompanyLogoArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/rootSettings) */
export type RootSettingsHeaderDisplayPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<RootSettingsHeaderDisplayPagesCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PagesFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/rootSettings) */
export type RootSettingsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/rootSettings) */
export type RootSettingsMobileDrawerDisplayPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<RootSettingsMobileDrawerDisplayPagesCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PagesFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/rootSettings) */
export type RootSettingsPromptEngineeringsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<RootSettingsPromptEngineeringsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PromptEngineeringFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/rootSettings) */
export type RootSettingsRenameCallToActionLinksDisplayTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/rootSettings) */
export type RootSettingsRenamePathDisplayTextBlogArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/rootSettings) */
export type RootSettingsRenamePathDisplayTextHomeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/rootSettings) */
export type RootSettingsRenamePathDisplayTextProductArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/rootSettings) */
export type RootSettingsScopeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/rootSettings) */
export type RootSettingsSectionColorsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/rootSettings) */
export type RootSettingsSeoMetadataItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<RootSettingsSeoMetadataItemsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SeoMetadataFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/rootSettings) */
export type RootSettingsSocialMediaLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<RootSettingsSocialMediaLinksCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SocialMediaLinkFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/rootSettings) */
export type RootSettingsThemeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/rootSettings) */
export type RootSettingsWritersoftModulesArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type RootSettingsCollection = {
  __typename?: 'RootSettingsCollection'
  items: Array<Maybe<RootSettings>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type RootSettingsFilter = {
  AND?: InputMaybe<Array<InputMaybe<RootSettingsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<RootSettingsFilter>>>
  callToActionLink?: InputMaybe<CfCallToActionLinkNestedFilter>
  callToActionLink_exists?: InputMaybe<Scalars['Boolean']>
  companyColor?: InputMaybe<Scalars['String']>
  companyColor_contains?: InputMaybe<Scalars['String']>
  companyColor_exists?: InputMaybe<Scalars['Boolean']>
  companyColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  companyColor_not?: InputMaybe<Scalars['String']>
  companyColor_not_contains?: InputMaybe<Scalars['String']>
  companyColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  companyFavicon_exists?: InputMaybe<Scalars['Boolean']>
  companyLogo_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  headerDisplayPages?: InputMaybe<CfPagesNestedFilter>
  headerDisplayPagesCollection_exists?: InputMaybe<Scalars['Boolean']>
  mobileDrawerDisplayPages?: InputMaybe<CfPagesNestedFilter>
  mobileDrawerDisplayPagesCollection_exists?: InputMaybe<Scalars['Boolean']>
  promptEngineerings?: InputMaybe<CfPromptEngineeringNestedFilter>
  promptEngineeringsCollection_exists?: InputMaybe<Scalars['Boolean']>
  renameCallToActionLinksDisplayText?: InputMaybe<Scalars['String']>
  renameCallToActionLinksDisplayText_contains?: InputMaybe<Scalars['String']>
  renameCallToActionLinksDisplayText_exists?: InputMaybe<Scalars['Boolean']>
  renameCallToActionLinksDisplayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  renameCallToActionLinksDisplayText_not?: InputMaybe<Scalars['String']>
  renameCallToActionLinksDisplayText_not_contains?: InputMaybe<Scalars['String']>
  renameCallToActionLinksDisplayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  renamePathDisplayTextBlog?: InputMaybe<Scalars['String']>
  renamePathDisplayTextBlog_contains?: InputMaybe<Scalars['String']>
  renamePathDisplayTextBlog_exists?: InputMaybe<Scalars['Boolean']>
  renamePathDisplayTextBlog_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  renamePathDisplayTextBlog_not?: InputMaybe<Scalars['String']>
  renamePathDisplayTextBlog_not_contains?: InputMaybe<Scalars['String']>
  renamePathDisplayTextBlog_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  renamePathDisplayTextHome?: InputMaybe<Scalars['String']>
  renamePathDisplayTextHome_contains?: InputMaybe<Scalars['String']>
  renamePathDisplayTextHome_exists?: InputMaybe<Scalars['Boolean']>
  renamePathDisplayTextHome_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  renamePathDisplayTextHome_not?: InputMaybe<Scalars['String']>
  renamePathDisplayTextHome_not_contains?: InputMaybe<Scalars['String']>
  renamePathDisplayTextHome_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  renamePathDisplayTextProduct?: InputMaybe<Scalars['String']>
  renamePathDisplayTextProduct_contains?: InputMaybe<Scalars['String']>
  renamePathDisplayTextProduct_exists?: InputMaybe<Scalars['Boolean']>
  renamePathDisplayTextProduct_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  renamePathDisplayTextProduct_not?: InputMaybe<Scalars['String']>
  renamePathDisplayTextProduct_not_contains?: InputMaybe<Scalars['String']>
  renamePathDisplayTextProduct_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  scope?: InputMaybe<Scalars['String']>
  scope_contains?: InputMaybe<Scalars['String']>
  scope_exists?: InputMaybe<Scalars['Boolean']>
  scope_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  scope_not?: InputMaybe<Scalars['String']>
  scope_not_contains?: InputMaybe<Scalars['String']>
  scope_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionColors_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionColors_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionColors_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionColors_exists?: InputMaybe<Scalars['Boolean']>
  seoMetadataItems?: InputMaybe<CfSeoMetadataNestedFilter>
  seoMetadataItemsCollection_exists?: InputMaybe<Scalars['Boolean']>
  socialMediaLinks?: InputMaybe<CfSocialMediaLinkNestedFilter>
  socialMediaLinksCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  theme?: InputMaybe<Scalars['String']>
  theme_contains?: InputMaybe<Scalars['String']>
  theme_exists?: InputMaybe<Scalars['Boolean']>
  theme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  theme_not?: InputMaybe<Scalars['String']>
  theme_not_contains?: InputMaybe<Scalars['String']>
  theme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  writersoftModules_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  writersoftModules_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  writersoftModules_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  writersoftModules_exists?: InputMaybe<Scalars['Boolean']>
}

export type RootSettingsHeaderDisplayPagesCollection = {
  __typename?: 'RootSettingsHeaderDisplayPagesCollection'
  items: Array<Maybe<Pages>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum RootSettingsHeaderDisplayPagesCollectionOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  IconifyIconNameForMobileMenuAsc = 'iconifyIconNameForMobileMenu_ASC',
  IconifyIconNameForMobileMenuDesc = 'iconifyIconNameForMobileMenu_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type RootSettingsLinkingCollections = {
  __typename?: 'RootSettingsLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type RootSettingsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type RootSettingsMobileDrawerDisplayPagesCollection = {
  __typename?: 'RootSettingsMobileDrawerDisplayPagesCollection'
  items: Array<Maybe<Pages>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum RootSettingsMobileDrawerDisplayPagesCollectionOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  IconifyIconNameForMobileMenuAsc = 'iconifyIconNameForMobileMenu_ASC',
  IconifyIconNameForMobileMenuDesc = 'iconifyIconNameForMobileMenu_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum RootSettingsOrder {
  CompanyColorAsc = 'companyColor_ASC',
  CompanyColorDesc = 'companyColor_DESC',
  RenameCallToActionLinksDisplayTextAsc = 'renameCallToActionLinksDisplayText_ASC',
  RenameCallToActionLinksDisplayTextDesc = 'renameCallToActionLinksDisplayText_DESC',
  RenamePathDisplayTextBlogAsc = 'renamePathDisplayTextBlog_ASC',
  RenamePathDisplayTextBlogDesc = 'renamePathDisplayTextBlog_DESC',
  RenamePathDisplayTextHomeAsc = 'renamePathDisplayTextHome_ASC',
  RenamePathDisplayTextHomeDesc = 'renamePathDisplayTextHome_DESC',
  RenamePathDisplayTextProductAsc = 'renamePathDisplayTextProduct_ASC',
  RenamePathDisplayTextProductDesc = 'renamePathDisplayTextProduct_DESC',
  ScopeAsc = 'scope_ASC',
  ScopeDesc = 'scope_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
}

export type RootSettingsPromptEngineeringsCollection = {
  __typename?: 'RootSettingsPromptEngineeringsCollection'
  items: Array<Maybe<PromptEngineering>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum RootSettingsPromptEngineeringsCollectionOrder {
  PurposeAsc = 'purpose_ASC',
  PurposeDesc = 'purpose_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type RootSettingsSeoMetadataItemsCollection = {
  __typename?: 'RootSettingsSeoMetadataItemsCollection'
  items: Array<Maybe<SeoMetadata>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum RootSettingsSeoMetadataItemsCollectionOrder {
  ContentAsc = 'content_ASC',
  ContentDesc = 'content_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type RootSettingsSocialMediaLinksCollection = {
  __typename?: 'RootSettingsSocialMediaLinksCollection'
  items: Array<Maybe<SocialMediaLink>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum RootSettingsSocialMediaLinksCollectionOrder {
  IsExternalAsc = 'isExternal_ASC',
  IsExternalDesc = 'isExternal_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  OverwriteDisplayIconWithIconifyIconAsc = 'overwriteDisplayIconWithIconifyIcon_ASC',
  OverwriteDisplayIconWithIconifyIconDesc = 'overwriteDisplayIconWithIconifyIcon_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsDetailPersonComponent) */
export type SectionsDetailPersonComponent = Entry & {
  __typename?: 'SectionsDetailPersonComponent'
  avatar?: Maybe<Asset>
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  descriptionHref?: Maybe<Scalars['String']>
  detail?: Maybe<SectionsDetailPersonComponentDetail>
  linkedFrom?: Maybe<SectionsDetailPersonComponentLinkingCollections>
  sys: Sys
  title?: Maybe<Scalars['String']>
  titleHref?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsDetailPersonComponent) */
export type SectionsDetailPersonComponentAvatarArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsDetailPersonComponent) */
export type SectionsDetailPersonComponentDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsDetailPersonComponent) */
export type SectionsDetailPersonComponentDescriptionHrefArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsDetailPersonComponent) */
export type SectionsDetailPersonComponentDetailArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsDetailPersonComponent) */
export type SectionsDetailPersonComponentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsDetailPersonComponent) */
export type SectionsDetailPersonComponentTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsDetailPersonComponent) */
export type SectionsDetailPersonComponentTitleHrefArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionsDetailPersonComponentCollection = {
  __typename?: 'SectionsDetailPersonComponentCollection'
  items: Array<Maybe<SectionsDetailPersonComponent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SectionsDetailPersonComponentDetail = {
  __typename?: 'SectionsDetailPersonComponentDetail'
  json: Scalars['JSON']
  links: SectionsDetailPersonComponentDetailLinks
}

export type SectionsDetailPersonComponentDetailAssets = {
  __typename?: 'SectionsDetailPersonComponentDetailAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type SectionsDetailPersonComponentDetailEntries = {
  __typename?: 'SectionsDetailPersonComponentDetailEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type SectionsDetailPersonComponentDetailLinks = {
  __typename?: 'SectionsDetailPersonComponentDetailLinks'
  assets: SectionsDetailPersonComponentDetailAssets
  entries: SectionsDetailPersonComponentDetailEntries
  resources: SectionsDetailPersonComponentDetailResources
}

export type SectionsDetailPersonComponentDetailResources = {
  __typename?: 'SectionsDetailPersonComponentDetailResources'
  block: Array<ResourceLink>
}

export type SectionsDetailPersonComponentFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionsDetailPersonComponentFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionsDetailPersonComponentFilter>>>
  avatar_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  descriptionHref?: InputMaybe<Scalars['String']>
  descriptionHref_contains?: InputMaybe<Scalars['String']>
  descriptionHref_exists?: InputMaybe<Scalars['Boolean']>
  descriptionHref_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  descriptionHref_not?: InputMaybe<Scalars['String']>
  descriptionHref_not_contains?: InputMaybe<Scalars['String']>
  descriptionHref_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  detail_contains?: InputMaybe<Scalars['String']>
  detail_exists?: InputMaybe<Scalars['Boolean']>
  detail_not_contains?: InputMaybe<Scalars['String']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  titleHref?: InputMaybe<Scalars['String']>
  titleHref_contains?: InputMaybe<Scalars['String']>
  titleHref_exists?: InputMaybe<Scalars['Boolean']>
  titleHref_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleHref_not?: InputMaybe<Scalars['String']>
  titleHref_not_contains?: InputMaybe<Scalars['String']>
  titleHref_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionsDetailPersonComponentLinkingCollections = {
  __typename?: 'SectionsDetailPersonComponentLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  sectionsDetailPersonListCollection?: Maybe<SectionsDetailPersonListCollection>
}

export type SectionsDetailPersonComponentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsDetailPersonComponentLinkingCollectionsSectionsDetailPersonListCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SectionsDetailPersonComponentLinkingCollectionsSectionsDetailPersonListCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum SectionsDetailPersonComponentLinkingCollectionsSectionsDetailPersonListCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SectionsDetailPersonComponentOrder {
  DescriptionHrefAsc = 'descriptionHref_ASC',
  DescriptionHrefDesc = 'descriptionHref_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleHrefAsc = 'titleHref_ASC',
  TitleHrefDesc = 'titleHref_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsDetailPersonList) */
export type SectionsDetailPersonList = Entry & {
  __typename?: 'SectionsDetailPersonList'
  componentsCollection?: Maybe<SectionsDetailPersonListComponentsCollection>
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<SectionsDetailPersonListLinkingCollections>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsDetailPersonList) */
export type SectionsDetailPersonListComponentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsDetailPersonListComponentsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsDetailPersonComponentFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsDetailPersonList) */
export type SectionsDetailPersonListDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsDetailPersonList) */
export type SectionsDetailPersonListLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsDetailPersonList) */
export type SectionsDetailPersonListTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionsDetailPersonListCollection = {
  __typename?: 'SectionsDetailPersonListCollection'
  items: Array<Maybe<SectionsDetailPersonList>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SectionsDetailPersonListComponentsCollection = {
  __typename?: 'SectionsDetailPersonListComponentsCollection'
  items: Array<Maybe<SectionsDetailPersonComponent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum SectionsDetailPersonListComponentsCollectionOrder {
  DescriptionHrefAsc = 'descriptionHref_ASC',
  DescriptionHrefDesc = 'descriptionHref_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleHrefAsc = 'titleHref_ASC',
  TitleHrefDesc = 'titleHref_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionsDetailPersonListFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionsDetailPersonListFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionsDetailPersonListFilter>>>
  components?: InputMaybe<CfSectionsDetailPersonComponentNestedFilter>
  componentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionsDetailPersonListLinkingCollections = {
  __typename?: 'SectionsDetailPersonListLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pagesCollection?: Maybe<PagesCollection>
}

export type SectionsDetailPersonListLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsDetailPersonListLinkingCollectionsPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<SectionsDetailPersonListLinkingCollectionsPagesCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SectionsDetailPersonListLinkingCollectionsPagesCollectionOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  IconifyIconNameForMobileMenuAsc = 'iconifyIconNameForMobileMenu_ASC',
  IconifyIconNameForMobileMenuDesc = 'iconifyIconNameForMobileMenu_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SectionsDetailPersonListOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFaqComponent) */
export type SectionsFaqComponent = Entry & {
  __typename?: 'SectionsFaqComponent'
  content?: Maybe<SectionsFaqComponentContent>
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<SectionsFaqComponentLinkingCollections>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFaqComponent) */
export type SectionsFaqComponentContentArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFaqComponent) */
export type SectionsFaqComponentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFaqComponent) */
export type SectionsFaqComponentTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionsFaqComponentCollection = {
  __typename?: 'SectionsFaqComponentCollection'
  items: Array<Maybe<SectionsFaqComponent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SectionsFaqComponentContent = {
  __typename?: 'SectionsFaqComponentContent'
  json: Scalars['JSON']
  links: SectionsFaqComponentContentLinks
}

export type SectionsFaqComponentContentAssets = {
  __typename?: 'SectionsFaqComponentContentAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type SectionsFaqComponentContentEntries = {
  __typename?: 'SectionsFaqComponentContentEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type SectionsFaqComponentContentLinks = {
  __typename?: 'SectionsFaqComponentContentLinks'
  assets: SectionsFaqComponentContentAssets
  entries: SectionsFaqComponentContentEntries
  resources: SectionsFaqComponentContentResources
}

export type SectionsFaqComponentContentResources = {
  __typename?: 'SectionsFaqComponentContentResources'
  block: Array<ResourceLink>
}

export type SectionsFaqComponentFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionsFaqComponentFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionsFaqComponentFilter>>>
  content_contains?: InputMaybe<Scalars['String']>
  content_exists?: InputMaybe<Scalars['Boolean']>
  content_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionsFaqComponentLinkingCollections = {
  __typename?: 'SectionsFaqComponentLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  sectionsFaqListCollection?: Maybe<SectionsFaqListCollection>
}

export type SectionsFaqComponentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsFaqComponentLinkingCollectionsSectionsFaqListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<SectionsFaqComponentLinkingCollectionsSectionsFaqListCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SectionsFaqComponentLinkingCollectionsSectionsFaqListCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SectionsFaqComponentOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFaqList) */
export type SectionsFaqList = Entry & {
  __typename?: 'SectionsFaqList'
  componentsCollection?: Maybe<SectionsFaqListComponentsCollection>
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<SectionsFaqListLinkingCollections>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFaqList) */
export type SectionsFaqListComponentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsFaqListComponentsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsFaqComponentFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFaqList) */
export type SectionsFaqListDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFaqList) */
export type SectionsFaqListLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFaqList) */
export type SectionsFaqListTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionsFaqListCollection = {
  __typename?: 'SectionsFaqListCollection'
  items: Array<Maybe<SectionsFaqList>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SectionsFaqListComponentsCollection = {
  __typename?: 'SectionsFaqListComponentsCollection'
  items: Array<Maybe<SectionsFaqComponent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum SectionsFaqListComponentsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionsFaqListFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionsFaqListFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionsFaqListFilter>>>
  components?: InputMaybe<CfSectionsFaqComponentNestedFilter>
  componentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionsFaqListLinkingCollections = {
  __typename?: 'SectionsFaqListLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pagesCollection?: Maybe<PagesCollection>
}

export type SectionsFaqListLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsFaqListLinkingCollectionsPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsFaqListLinkingCollectionsPagesCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SectionsFaqListLinkingCollectionsPagesCollectionOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  IconifyIconNameForMobileMenuAsc = 'iconifyIconNameForMobileMenu_ASC',
  IconifyIconNameForMobileMenuDesc = 'iconifyIconNameForMobileMenu_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SectionsFaqListOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFeaturesList) */
export type SectionsFeaturesList = Entry & {
  __typename?: 'SectionsFeaturesList'
  componentsCollection?: Maybe<SectionsFeaturesListComponentsCollection>
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<SectionsFeaturesListLinkingCollections>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFeaturesList) */
export type SectionsFeaturesListComponentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsFeaturesListComponentsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsFeaturesListComponentFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFeaturesList) */
export type SectionsFeaturesListDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFeaturesList) */
export type SectionsFeaturesListLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFeaturesList) */
export type SectionsFeaturesListTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionsFeaturesListCollection = {
  __typename?: 'SectionsFeaturesListCollection'
  items: Array<Maybe<SectionsFeaturesList>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFeaturesListComponent) */
export type SectionsFeaturesListComponent = Entry & {
  __typename?: 'SectionsFeaturesListComponent'
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  icon?: Maybe<Asset>
  iconifyIconName?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<SectionsFeaturesListComponentLinkingCollections>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFeaturesListComponent) */
export type SectionsFeaturesListComponentDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFeaturesListComponent) */
export type SectionsFeaturesListComponentIconArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFeaturesListComponent) */
export type SectionsFeaturesListComponentIconifyIconNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFeaturesListComponent) */
export type SectionsFeaturesListComponentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFeaturesListComponent) */
export type SectionsFeaturesListComponentTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionsFeaturesListComponentCollection = {
  __typename?: 'SectionsFeaturesListComponentCollection'
  items: Array<Maybe<SectionsFeaturesListComponent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SectionsFeaturesListComponentFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionsFeaturesListComponentFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionsFeaturesListComponentFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  icon_exists?: InputMaybe<Scalars['Boolean']>
  iconifyIconName?: InputMaybe<Scalars['String']>
  iconifyIconName_contains?: InputMaybe<Scalars['String']>
  iconifyIconName_exists?: InputMaybe<Scalars['Boolean']>
  iconifyIconName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  iconifyIconName_not?: InputMaybe<Scalars['String']>
  iconifyIconName_not_contains?: InputMaybe<Scalars['String']>
  iconifyIconName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionsFeaturesListComponentLinkingCollections = {
  __typename?: 'SectionsFeaturesListComponentLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  sectionsFeaturesListCollection?: Maybe<SectionsFeaturesListCollection>
}

export type SectionsFeaturesListComponentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsFeaturesListComponentLinkingCollectionsSectionsFeaturesListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<SectionsFeaturesListComponentLinkingCollectionsSectionsFeaturesListCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SectionsFeaturesListComponentLinkingCollectionsSectionsFeaturesListCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SectionsFeaturesListComponentOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IconifyIconNameAsc = 'iconifyIconName_ASC',
  IconifyIconNameDesc = 'iconifyIconName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionsFeaturesListComponentsCollection = {
  __typename?: 'SectionsFeaturesListComponentsCollection'
  items: Array<Maybe<SectionsFeaturesListComponent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum SectionsFeaturesListComponentsCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IconifyIconNameAsc = 'iconifyIconName_ASC',
  IconifyIconNameDesc = 'iconifyIconName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionsFeaturesListFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionsFeaturesListFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionsFeaturesListFilter>>>
  components?: InputMaybe<CfSectionsFeaturesListComponentNestedFilter>
  componentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionsFeaturesListLinkingCollections = {
  __typename?: 'SectionsFeaturesListLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pagesCollection?: Maybe<PagesCollection>
}

export type SectionsFeaturesListLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsFeaturesListLinkingCollectionsPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsFeaturesListLinkingCollectionsPagesCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SectionsFeaturesListLinkingCollectionsPagesCollectionOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  IconifyIconNameForMobileMenuAsc = 'iconifyIconNameForMobileMenu_ASC',
  IconifyIconNameForMobileMenuDesc = 'iconifyIconNameForMobileMenu_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SectionsFeaturesListOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsForm) */
export type SectionsForm = Entry & {
  __typename?: 'SectionsForm'
  callToActionHref?: Maybe<Scalars['String']>
  callToActionText?: Maybe<Scalars['String']>
  componentsCollection?: Maybe<SectionsFormComponentsCollection>
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<SectionsFormLinkingCollections>
  name?: Maybe<Scalars['String']>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsForm) */
export type SectionsFormCallToActionHrefArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsForm) */
export type SectionsFormCallToActionTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsForm) */
export type SectionsFormComponentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsFormComponentsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsFormComponentFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsForm) */
export type SectionsFormDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsForm) */
export type SectionsFormLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsForm) */
export type SectionsFormNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsForm) */
export type SectionsFormTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionsFormCollection = {
  __typename?: 'SectionsFormCollection'
  items: Array<Maybe<SectionsForm>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFormComponent) */
export type SectionsFormComponent = Entry & {
  __typename?: 'SectionsFormComponent'
  contentfulMetadata: ContentfulMetadata
  defaultChecked?: Maybe<Scalars['Boolean']>
  defaultValue?: Maybe<Scalars['String']>
  disabled?: Maybe<Scalars['Boolean']>
  fullWidth?: Maybe<Scalars['Boolean']>
  helperText?: Maybe<Scalars['String']>
  label?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<SectionsFormComponentLinkingCollections>
  max?: Maybe<Scalars['String']>
  maxLength?: Maybe<Scalars['Int']>
  min?: Maybe<Scalars['String']>
  minLength?: Maybe<Scalars['Int']>
  multiple?: Maybe<Scalars['Boolean']>
  options?: Maybe<Array<Maybe<Scalars['String']>>>
  pattern?: Maybe<Scalars['String']>
  placeholder?: Maybe<Scalars['String']>
  readOnly?: Maybe<Scalars['Boolean']>
  required?: Maybe<Scalars['Boolean']>
  size?: Maybe<Scalars['String']>
  step?: Maybe<Scalars['Int']>
  sys: Sys
  type?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFormComponent) */
export type SectionsFormComponentDefaultCheckedArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFormComponent) */
export type SectionsFormComponentDefaultValueArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFormComponent) */
export type SectionsFormComponentDisabledArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFormComponent) */
export type SectionsFormComponentFullWidthArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFormComponent) */
export type SectionsFormComponentHelperTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFormComponent) */
export type SectionsFormComponentLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFormComponent) */
export type SectionsFormComponentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFormComponent) */
export type SectionsFormComponentMaxArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFormComponent) */
export type SectionsFormComponentMaxLengthArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFormComponent) */
export type SectionsFormComponentMinArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFormComponent) */
export type SectionsFormComponentMinLengthArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFormComponent) */
export type SectionsFormComponentMultipleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFormComponent) */
export type SectionsFormComponentOptionsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFormComponent) */
export type SectionsFormComponentPatternArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFormComponent) */
export type SectionsFormComponentPlaceholderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFormComponent) */
export type SectionsFormComponentReadOnlyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFormComponent) */
export type SectionsFormComponentRequiredArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFormComponent) */
export type SectionsFormComponentSizeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFormComponent) */
export type SectionsFormComponentStepArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsFormComponent) */
export type SectionsFormComponentTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionsFormComponentCollection = {
  __typename?: 'SectionsFormComponentCollection'
  items: Array<Maybe<SectionsFormComponent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SectionsFormComponentFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionsFormComponentFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionsFormComponentFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  defaultChecked?: InputMaybe<Scalars['Boolean']>
  defaultChecked_exists?: InputMaybe<Scalars['Boolean']>
  defaultChecked_not?: InputMaybe<Scalars['Boolean']>
  defaultValue?: InputMaybe<Scalars['String']>
  defaultValue_contains?: InputMaybe<Scalars['String']>
  defaultValue_exists?: InputMaybe<Scalars['Boolean']>
  defaultValue_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  defaultValue_not?: InputMaybe<Scalars['String']>
  defaultValue_not_contains?: InputMaybe<Scalars['String']>
  defaultValue_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  disabled?: InputMaybe<Scalars['Boolean']>
  disabled_exists?: InputMaybe<Scalars['Boolean']>
  disabled_not?: InputMaybe<Scalars['Boolean']>
  fullWidth?: InputMaybe<Scalars['Boolean']>
  fullWidth_exists?: InputMaybe<Scalars['Boolean']>
  fullWidth_not?: InputMaybe<Scalars['Boolean']>
  helperText?: InputMaybe<Scalars['String']>
  helperText_contains?: InputMaybe<Scalars['String']>
  helperText_exists?: InputMaybe<Scalars['Boolean']>
  helperText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  helperText_not?: InputMaybe<Scalars['String']>
  helperText_not_contains?: InputMaybe<Scalars['String']>
  helperText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  label?: InputMaybe<Scalars['String']>
  label_contains?: InputMaybe<Scalars['String']>
  label_exists?: InputMaybe<Scalars['Boolean']>
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  label_not?: InputMaybe<Scalars['String']>
  label_not_contains?: InputMaybe<Scalars['String']>
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  max?: InputMaybe<Scalars['String']>
  maxLength?: InputMaybe<Scalars['Int']>
  maxLength_exists?: InputMaybe<Scalars['Boolean']>
  maxLength_gt?: InputMaybe<Scalars['Int']>
  maxLength_gte?: InputMaybe<Scalars['Int']>
  maxLength_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  maxLength_lt?: InputMaybe<Scalars['Int']>
  maxLength_lte?: InputMaybe<Scalars['Int']>
  maxLength_not?: InputMaybe<Scalars['Int']>
  maxLength_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  max_contains?: InputMaybe<Scalars['String']>
  max_exists?: InputMaybe<Scalars['Boolean']>
  max_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  max_not?: InputMaybe<Scalars['String']>
  max_not_contains?: InputMaybe<Scalars['String']>
  max_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  min?: InputMaybe<Scalars['String']>
  minLength?: InputMaybe<Scalars['Int']>
  minLength_exists?: InputMaybe<Scalars['Boolean']>
  minLength_gt?: InputMaybe<Scalars['Int']>
  minLength_gte?: InputMaybe<Scalars['Int']>
  minLength_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  minLength_lt?: InputMaybe<Scalars['Int']>
  minLength_lte?: InputMaybe<Scalars['Int']>
  minLength_not?: InputMaybe<Scalars['Int']>
  minLength_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  min_contains?: InputMaybe<Scalars['String']>
  min_exists?: InputMaybe<Scalars['Boolean']>
  min_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  min_not?: InputMaybe<Scalars['String']>
  min_not_contains?: InputMaybe<Scalars['String']>
  min_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  multiple?: InputMaybe<Scalars['Boolean']>
  multiple_exists?: InputMaybe<Scalars['Boolean']>
  multiple_not?: InputMaybe<Scalars['Boolean']>
  options_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  options_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  options_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  options_exists?: InputMaybe<Scalars['Boolean']>
  pattern?: InputMaybe<Scalars['String']>
  pattern_contains?: InputMaybe<Scalars['String']>
  pattern_exists?: InputMaybe<Scalars['Boolean']>
  pattern_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  pattern_not?: InputMaybe<Scalars['String']>
  pattern_not_contains?: InputMaybe<Scalars['String']>
  pattern_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  placeholder?: InputMaybe<Scalars['String']>
  placeholder_contains?: InputMaybe<Scalars['String']>
  placeholder_exists?: InputMaybe<Scalars['Boolean']>
  placeholder_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  placeholder_not?: InputMaybe<Scalars['String']>
  placeholder_not_contains?: InputMaybe<Scalars['String']>
  placeholder_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  readOnly?: InputMaybe<Scalars['Boolean']>
  readOnly_exists?: InputMaybe<Scalars['Boolean']>
  readOnly_not?: InputMaybe<Scalars['Boolean']>
  required?: InputMaybe<Scalars['Boolean']>
  required_exists?: InputMaybe<Scalars['Boolean']>
  required_not?: InputMaybe<Scalars['Boolean']>
  size?: InputMaybe<Scalars['String']>
  size_contains?: InputMaybe<Scalars['String']>
  size_exists?: InputMaybe<Scalars['Boolean']>
  size_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  size_not?: InputMaybe<Scalars['String']>
  size_not_contains?: InputMaybe<Scalars['String']>
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  step?: InputMaybe<Scalars['Int']>
  step_exists?: InputMaybe<Scalars['Boolean']>
  step_gt?: InputMaybe<Scalars['Int']>
  step_gte?: InputMaybe<Scalars['Int']>
  step_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  step_lt?: InputMaybe<Scalars['Int']>
  step_lte?: InputMaybe<Scalars['Int']>
  step_not?: InputMaybe<Scalars['Int']>
  step_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  sys?: InputMaybe<SysFilter>
  type?: InputMaybe<Scalars['String']>
  type_contains?: InputMaybe<Scalars['String']>
  type_exists?: InputMaybe<Scalars['Boolean']>
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type_not?: InputMaybe<Scalars['String']>
  type_not_contains?: InputMaybe<Scalars['String']>
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionsFormComponentLinkingCollections = {
  __typename?: 'SectionsFormComponentLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  sectionsFormCollection?: Maybe<SectionsFormCollection>
}

export type SectionsFormComponentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsFormComponentLinkingCollectionsSectionsFormCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<SectionsFormComponentLinkingCollectionsSectionsFormCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SectionsFormComponentLinkingCollectionsSectionsFormCollectionOrder {
  CallToActionHrefAsc = 'callToActionHref_ASC',
  CallToActionHrefDesc = 'callToActionHref_DESC',
  CallToActionTextAsc = 'callToActionText_ASC',
  CallToActionTextDesc = 'callToActionText_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SectionsFormComponentOrder {
  DefaultCheckedAsc = 'defaultChecked_ASC',
  DefaultCheckedDesc = 'defaultChecked_DESC',
  DefaultValueAsc = 'defaultValue_ASC',
  DefaultValueDesc = 'defaultValue_DESC',
  DisabledAsc = 'disabled_ASC',
  DisabledDesc = 'disabled_DESC',
  FullWidthAsc = 'fullWidth_ASC',
  FullWidthDesc = 'fullWidth_DESC',
  HelperTextAsc = 'helperText_ASC',
  HelperTextDesc = 'helperText_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MaxLengthAsc = 'maxLength_ASC',
  MaxLengthDesc = 'maxLength_DESC',
  MaxAsc = 'max_ASC',
  MaxDesc = 'max_DESC',
  MinLengthAsc = 'minLength_ASC',
  MinLengthDesc = 'minLength_DESC',
  MinAsc = 'min_ASC',
  MinDesc = 'min_DESC',
  MultipleAsc = 'multiple_ASC',
  MultipleDesc = 'multiple_DESC',
  PatternAsc = 'pattern_ASC',
  PatternDesc = 'pattern_DESC',
  PlaceholderAsc = 'placeholder_ASC',
  PlaceholderDesc = 'placeholder_DESC',
  ReadOnlyAsc = 'readOnly_ASC',
  ReadOnlyDesc = 'readOnly_DESC',
  RequiredAsc = 'required_ASC',
  RequiredDesc = 'required_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  StepAsc = 'step_ASC',
  StepDesc = 'step_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type SectionsFormComponentsCollection = {
  __typename?: 'SectionsFormComponentsCollection'
  items: Array<Maybe<SectionsFormComponent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum SectionsFormComponentsCollectionOrder {
  DefaultCheckedAsc = 'defaultChecked_ASC',
  DefaultCheckedDesc = 'defaultChecked_DESC',
  DefaultValueAsc = 'defaultValue_ASC',
  DefaultValueDesc = 'defaultValue_DESC',
  DisabledAsc = 'disabled_ASC',
  DisabledDesc = 'disabled_DESC',
  FullWidthAsc = 'fullWidth_ASC',
  FullWidthDesc = 'fullWidth_DESC',
  HelperTextAsc = 'helperText_ASC',
  HelperTextDesc = 'helperText_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  MaxLengthAsc = 'maxLength_ASC',
  MaxLengthDesc = 'maxLength_DESC',
  MaxAsc = 'max_ASC',
  MaxDesc = 'max_DESC',
  MinLengthAsc = 'minLength_ASC',
  MinLengthDesc = 'minLength_DESC',
  MinAsc = 'min_ASC',
  MinDesc = 'min_DESC',
  MultipleAsc = 'multiple_ASC',
  MultipleDesc = 'multiple_DESC',
  PatternAsc = 'pattern_ASC',
  PatternDesc = 'pattern_DESC',
  PlaceholderAsc = 'placeholder_ASC',
  PlaceholderDesc = 'placeholder_DESC',
  ReadOnlyAsc = 'readOnly_ASC',
  ReadOnlyDesc = 'readOnly_DESC',
  RequiredAsc = 'required_ASC',
  RequiredDesc = 'required_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  StepAsc = 'step_ASC',
  StepDesc = 'step_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type SectionsFormFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionsFormFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionsFormFilter>>>
  callToActionHref?: InputMaybe<Scalars['String']>
  callToActionHref_contains?: InputMaybe<Scalars['String']>
  callToActionHref_exists?: InputMaybe<Scalars['Boolean']>
  callToActionHref_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionHref_not?: InputMaybe<Scalars['String']>
  callToActionHref_not_contains?: InputMaybe<Scalars['String']>
  callToActionHref_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionText?: InputMaybe<Scalars['String']>
  callToActionText_contains?: InputMaybe<Scalars['String']>
  callToActionText_exists?: InputMaybe<Scalars['Boolean']>
  callToActionText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionText_not?: InputMaybe<Scalars['String']>
  callToActionText_not_contains?: InputMaybe<Scalars['String']>
  callToActionText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  components?: InputMaybe<CfSectionsFormComponentNestedFilter>
  componentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionsFormLinkingCollections = {
  __typename?: 'SectionsFormLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pagesCollection?: Maybe<PagesCollection>
}

export type SectionsFormLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsFormLinkingCollectionsPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsFormLinkingCollectionsPagesCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SectionsFormLinkingCollectionsPagesCollectionOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  IconifyIconNameForMobileMenuAsc = 'iconifyIconNameForMobileMenu_ASC',
  IconifyIconNameForMobileMenuDesc = 'iconifyIconNameForMobileMenu_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SectionsFormOrder {
  CallToActionHrefAsc = 'callToActionHref_ASC',
  CallToActionHrefDesc = 'callToActionHref_DESC',
  CallToActionTextAsc = 'callToActionText_ASC',
  CallToActionTextDesc = 'callToActionText_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsHero) */
export type SectionsHero = Entry & {
  __typename?: 'SectionsHero'
  backgroundImage?: Maybe<Asset>
  callToActionHref?: Maybe<Scalars['String']>
  callToActionText?: Maybe<Scalars['String']>
  companyName?: Maybe<Scalars['String']>
  contentfulMetadata: ContentfulMetadata
  descriptionText?: Maybe<Scalars['String']>
  enableAiRecommendation?: Maybe<Scalars['Boolean']>
  enableAiRecommendationDisplayText?: Maybe<Scalars['String']>
  enableAiRecommendationPrompt?: Maybe<Scalars['String']>
  enableAiRecommendationTagsPrompt?: Maybe<Scalars['String']>
  enableAiTrail?: Maybe<Scalars['Boolean']>
  enableAiTrailDisplayText?: Maybe<Scalars['String']>
  featureText?: Maybe<Scalars['String']>
  fullWidthBackgroundImageDesktop?: Maybe<Asset>
  fullWidthBackgroundImageMobile?: Maybe<Asset>
  linkedFrom?: Maybe<SectionsHeroLinkingCollections>
  sys: Sys
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsHero) */
export type SectionsHeroBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsHero) */
export type SectionsHeroCallToActionHrefArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsHero) */
export type SectionsHeroCallToActionTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsHero) */
export type SectionsHeroCompanyNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsHero) */
export type SectionsHeroDescriptionTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsHero) */
export type SectionsHeroEnableAiRecommendationArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsHero) */
export type SectionsHeroEnableAiRecommendationDisplayTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsHero) */
export type SectionsHeroEnableAiRecommendationPromptArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsHero) */
export type SectionsHeroEnableAiRecommendationTagsPromptArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsHero) */
export type SectionsHeroEnableAiTrailArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsHero) */
export type SectionsHeroEnableAiTrailDisplayTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsHero) */
export type SectionsHeroFeatureTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsHero) */
export type SectionsHeroFullWidthBackgroundImageDesktopArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsHero) */
export type SectionsHeroFullWidthBackgroundImageMobileArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsHero) */
export type SectionsHeroLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionsHeroCollection = {
  __typename?: 'SectionsHeroCollection'
  items: Array<Maybe<SectionsHero>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SectionsHeroFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionsHeroFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionsHeroFilter>>>
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']>
  callToActionHref?: InputMaybe<Scalars['String']>
  callToActionHref_contains?: InputMaybe<Scalars['String']>
  callToActionHref_exists?: InputMaybe<Scalars['Boolean']>
  callToActionHref_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionHref_not?: InputMaybe<Scalars['String']>
  callToActionHref_not_contains?: InputMaybe<Scalars['String']>
  callToActionHref_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionText?: InputMaybe<Scalars['String']>
  callToActionText_contains?: InputMaybe<Scalars['String']>
  callToActionText_exists?: InputMaybe<Scalars['Boolean']>
  callToActionText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionText_not?: InputMaybe<Scalars['String']>
  callToActionText_not_contains?: InputMaybe<Scalars['String']>
  callToActionText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  companyName?: InputMaybe<Scalars['String']>
  companyName_contains?: InputMaybe<Scalars['String']>
  companyName_exists?: InputMaybe<Scalars['Boolean']>
  companyName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  companyName_not?: InputMaybe<Scalars['String']>
  companyName_not_contains?: InputMaybe<Scalars['String']>
  companyName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  descriptionText?: InputMaybe<Scalars['String']>
  descriptionText_contains?: InputMaybe<Scalars['String']>
  descriptionText_exists?: InputMaybe<Scalars['Boolean']>
  descriptionText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  descriptionText_not?: InputMaybe<Scalars['String']>
  descriptionText_not_contains?: InputMaybe<Scalars['String']>
  descriptionText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  enableAiRecommendation?: InputMaybe<Scalars['Boolean']>
  enableAiRecommendationDisplayText?: InputMaybe<Scalars['String']>
  enableAiRecommendationDisplayText_contains?: InputMaybe<Scalars['String']>
  enableAiRecommendationDisplayText_exists?: InputMaybe<Scalars['Boolean']>
  enableAiRecommendationDisplayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  enableAiRecommendationDisplayText_not?: InputMaybe<Scalars['String']>
  enableAiRecommendationDisplayText_not_contains?: InputMaybe<Scalars['String']>
  enableAiRecommendationDisplayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  enableAiRecommendationPrompt?: InputMaybe<Scalars['String']>
  enableAiRecommendationPrompt_contains?: InputMaybe<Scalars['String']>
  enableAiRecommendationPrompt_exists?: InputMaybe<Scalars['Boolean']>
  enableAiRecommendationPrompt_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  enableAiRecommendationPrompt_not?: InputMaybe<Scalars['String']>
  enableAiRecommendationPrompt_not_contains?: InputMaybe<Scalars['String']>
  enableAiRecommendationPrompt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  enableAiRecommendationTagsPrompt?: InputMaybe<Scalars['String']>
  enableAiRecommendationTagsPrompt_contains?: InputMaybe<Scalars['String']>
  enableAiRecommendationTagsPrompt_exists?: InputMaybe<Scalars['Boolean']>
  enableAiRecommendationTagsPrompt_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  enableAiRecommendationTagsPrompt_not?: InputMaybe<Scalars['String']>
  enableAiRecommendationTagsPrompt_not_contains?: InputMaybe<Scalars['String']>
  enableAiRecommendationTagsPrompt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  enableAiRecommendation_exists?: InputMaybe<Scalars['Boolean']>
  enableAiRecommendation_not?: InputMaybe<Scalars['Boolean']>
  enableAiTrail?: InputMaybe<Scalars['Boolean']>
  enableAiTrailDisplayText?: InputMaybe<Scalars['String']>
  enableAiTrailDisplayText_contains?: InputMaybe<Scalars['String']>
  enableAiTrailDisplayText_exists?: InputMaybe<Scalars['Boolean']>
  enableAiTrailDisplayText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  enableAiTrailDisplayText_not?: InputMaybe<Scalars['String']>
  enableAiTrailDisplayText_not_contains?: InputMaybe<Scalars['String']>
  enableAiTrailDisplayText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  enableAiTrail_exists?: InputMaybe<Scalars['Boolean']>
  enableAiTrail_not?: InputMaybe<Scalars['Boolean']>
  featureText?: InputMaybe<Scalars['String']>
  featureText_contains?: InputMaybe<Scalars['String']>
  featureText_exists?: InputMaybe<Scalars['Boolean']>
  featureText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  featureText_not?: InputMaybe<Scalars['String']>
  featureText_not_contains?: InputMaybe<Scalars['String']>
  featureText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fullWidthBackgroundImageDesktop_exists?: InputMaybe<Scalars['Boolean']>
  fullWidthBackgroundImageMobile_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
}

export type SectionsHeroLinkingCollections = {
  __typename?: 'SectionsHeroLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pagesCollection?: Maybe<PagesCollection>
}

export type SectionsHeroLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsHeroLinkingCollectionsPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsHeroLinkingCollectionsPagesCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SectionsHeroLinkingCollectionsPagesCollectionOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  IconifyIconNameForMobileMenuAsc = 'iconifyIconNameForMobileMenu_ASC',
  IconifyIconNameForMobileMenuDesc = 'iconifyIconNameForMobileMenu_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SectionsHeroOrder {
  CallToActionHrefAsc = 'callToActionHref_ASC',
  CallToActionHrefDesc = 'callToActionHref_DESC',
  CallToActionTextAsc = 'callToActionText_ASC',
  CallToActionTextDesc = 'callToActionText_DESC',
  CompanyNameAsc = 'companyName_ASC',
  CompanyNameDesc = 'companyName_DESC',
  EnableAiRecommendationAsc = 'enableAiRecommendation_ASC',
  EnableAiRecommendationDesc = 'enableAiRecommendation_DESC',
  EnableAiTrailAsc = 'enableAiTrail_ASC',
  EnableAiTrailDesc = 'enableAiTrail_DESC',
  FeatureTextAsc = 'featureText_ASC',
  FeatureTextDesc = 'featureText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsImage) */
export type SectionsImage = Entry & {
  __typename?: 'SectionsImage'
  callToActionHref?: Maybe<Scalars['String']>
  contentfulMetadata: ContentfulMetadata
  image?: Maybe<Asset>
  linkedFrom?: Maybe<SectionsImageLinkingCollections>
  mobileImage?: Maybe<Asset>
  sys: Sys
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsImage) */
export type SectionsImageCallToActionHrefArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsImage) */
export type SectionsImageImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsImage) */
export type SectionsImageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsImage) */
export type SectionsImageMobileImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type SectionsImageCollection = {
  __typename?: 'SectionsImageCollection'
  items: Array<Maybe<SectionsImage>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SectionsImageFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionsImageFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionsImageFilter>>>
  callToActionHref?: InputMaybe<Scalars['String']>
  callToActionHref_contains?: InputMaybe<Scalars['String']>
  callToActionHref_exists?: InputMaybe<Scalars['Boolean']>
  callToActionHref_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionHref_not?: InputMaybe<Scalars['String']>
  callToActionHref_not_contains?: InputMaybe<Scalars['String']>
  callToActionHref_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  image_exists?: InputMaybe<Scalars['Boolean']>
  mobileImage_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
}

export type SectionsImageLinkingCollections = {
  __typename?: 'SectionsImageLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pagesCollection?: Maybe<PagesCollection>
  sectionsMinimalsImagesListCollection?: Maybe<SectionsMinimalsImagesListCollection>
}

export type SectionsImageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsImageLinkingCollectionsPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsImageLinkingCollectionsPagesCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsImageLinkingCollectionsSectionsMinimalsImagesListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<SectionsImageLinkingCollectionsSectionsMinimalsImagesListCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SectionsImageLinkingCollectionsPagesCollectionOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  IconifyIconNameForMobileMenuAsc = 'iconifyIconNameForMobileMenu_ASC',
  IconifyIconNameForMobileMenuDesc = 'iconifyIconNameForMobileMenu_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SectionsImageLinkingCollectionsSectionsMinimalsImagesListCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DisplayModeAsc = 'displayMode_ASC',
  DisplayModeDesc = 'displayMode_DESC',
  GridModeColumnsCountDesktopAsc = 'gridModeColumnsCountDesktop_ASC',
  GridModeColumnsCountDesktopDesc = 'gridModeColumnsCountDesktop_DESC',
  GridModeColumnsCountMobileAsc = 'gridModeColumnsCountMobile_ASC',
  GridModeColumnsCountMobileDesc = 'gridModeColumnsCountMobile_DESC',
  GridModeGapDesktopAsc = 'gridModeGapDesktop_ASC',
  GridModeGapDesktopDesc = 'gridModeGapDesktop_DESC',
  GridModeGapMobileAsc = 'gridModeGapMobile_ASC',
  GridModeGapMobileDesc = 'gridModeGapMobile_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SectionsImageOrder {
  CallToActionHrefAsc = 'callToActionHref_ASC',
  CallToActionHrefDesc = 'callToActionHref_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsContactComponent) */
export type SectionsMinimalsContactComponent = Entry & {
  __typename?: 'SectionsMinimalsContactComponent'
  contentfulMetadata: ContentfulMetadata
  href?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<SectionsMinimalsContactComponentLinkingCollections>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsContactComponent) */
export type SectionsMinimalsContactComponentHrefArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsContactComponent) */
export type SectionsMinimalsContactComponentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsContactComponent) */
export type SectionsMinimalsContactComponentTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionsMinimalsContactComponentCollection = {
  __typename?: 'SectionsMinimalsContactComponentCollection'
  items: Array<Maybe<SectionsMinimalsContactComponent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SectionsMinimalsContactComponentFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionsMinimalsContactComponentFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionsMinimalsContactComponentFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  href?: InputMaybe<Scalars['String']>
  href_contains?: InputMaybe<Scalars['String']>
  href_exists?: InputMaybe<Scalars['Boolean']>
  href_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  href_not?: InputMaybe<Scalars['String']>
  href_not_contains?: InputMaybe<Scalars['String']>
  href_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionsMinimalsContactComponentLinkingCollections = {
  __typename?: 'SectionsMinimalsContactComponentLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  sectionsMinimalsContactListCollection?: Maybe<SectionsMinimalsContactListCollection>
}

export type SectionsMinimalsContactComponentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsMinimalsContactComponentLinkingCollectionsSectionsMinimalsContactListCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SectionsMinimalsContactComponentLinkingCollectionsSectionsMinimalsContactListCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum SectionsMinimalsContactComponentLinkingCollectionsSectionsMinimalsContactListCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SectionsMinimalsContactComponentOrder {
  HrefAsc = 'href_ASC',
  HrefDesc = 'href_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsContactList) */
export type SectionsMinimalsContactList = Entry & {
  __typename?: 'SectionsMinimalsContactList'
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<SectionsMinimalsContactListLinkingCollections>
  linksCollection?: Maybe<SectionsMinimalsContactListLinksCollection>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsContactList) */
export type SectionsMinimalsContactListDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsContactList) */
export type SectionsMinimalsContactListLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsContactList) */
export type SectionsMinimalsContactListLinksCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsMinimalsContactListLinksCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsMinimalsContactComponentFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsContactList) */
export type SectionsMinimalsContactListTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionsMinimalsContactListCollection = {
  __typename?: 'SectionsMinimalsContactListCollection'
  items: Array<Maybe<SectionsMinimalsContactList>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SectionsMinimalsContactListFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionsMinimalsContactListFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionsMinimalsContactListFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  links?: InputMaybe<CfSectionsMinimalsContactComponentNestedFilter>
  linksCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionsMinimalsContactListLinkingCollections = {
  __typename?: 'SectionsMinimalsContactListLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pagesCollection?: Maybe<PagesCollection>
}

export type SectionsMinimalsContactListLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsMinimalsContactListLinkingCollectionsPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<SectionsMinimalsContactListLinkingCollectionsPagesCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SectionsMinimalsContactListLinkingCollectionsPagesCollectionOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  IconifyIconNameForMobileMenuAsc = 'iconifyIconNameForMobileMenu_ASC',
  IconifyIconNameForMobileMenuDesc = 'iconifyIconNameForMobileMenu_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type SectionsMinimalsContactListLinksCollection = {
  __typename?: 'SectionsMinimalsContactListLinksCollection'
  items: Array<Maybe<SectionsMinimalsContactComponent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum SectionsMinimalsContactListLinksCollectionOrder {
  HrefAsc = 'href_ASC',
  HrefDesc = 'href_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SectionsMinimalsContactListOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsGallery) */
export type SectionsMinimalsGallery = Entry & {
  __typename?: 'SectionsMinimalsGallery'
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  displayItemsCollection?: Maybe<SectionsMinimalsGalleryDisplayItemsCollection>
  linkedFrom?: Maybe<SectionsMinimalsGalleryLinkingCollections>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsGallery) */
export type SectionsMinimalsGalleryDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsGallery) */
export type SectionsMinimalsGalleryDisplayItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsMinimalsGalleryDisplayItemsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsMinimalsImagesListFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsGallery) */
export type SectionsMinimalsGalleryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsGallery) */
export type SectionsMinimalsGalleryTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionsMinimalsGalleryCollection = {
  __typename?: 'SectionsMinimalsGalleryCollection'
  items: Array<Maybe<SectionsMinimalsGallery>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SectionsMinimalsGalleryDisplayItemsCollection = {
  __typename?: 'SectionsMinimalsGalleryDisplayItemsCollection'
  items: Array<Maybe<SectionsMinimalsImagesList>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum SectionsMinimalsGalleryDisplayItemsCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DisplayModeAsc = 'displayMode_ASC',
  DisplayModeDesc = 'displayMode_DESC',
  GridModeColumnsCountDesktopAsc = 'gridModeColumnsCountDesktop_ASC',
  GridModeColumnsCountDesktopDesc = 'gridModeColumnsCountDesktop_DESC',
  GridModeColumnsCountMobileAsc = 'gridModeColumnsCountMobile_ASC',
  GridModeColumnsCountMobileDesc = 'gridModeColumnsCountMobile_DESC',
  GridModeGapDesktopAsc = 'gridModeGapDesktop_ASC',
  GridModeGapDesktopDesc = 'gridModeGapDesktop_DESC',
  GridModeGapMobileAsc = 'gridModeGapMobile_ASC',
  GridModeGapMobileDesc = 'gridModeGapMobile_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionsMinimalsGalleryFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionsMinimalsGalleryFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionsMinimalsGalleryFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayItems?: InputMaybe<CfSectionsMinimalsImagesListNestedFilter>
  displayItemsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionsMinimalsGalleryLinkingCollections = {
  __typename?: 'SectionsMinimalsGalleryLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pagesCollection?: Maybe<PagesCollection>
}

export type SectionsMinimalsGalleryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsMinimalsGalleryLinkingCollectionsPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<SectionsMinimalsGalleryLinkingCollectionsPagesCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SectionsMinimalsGalleryLinkingCollectionsPagesCollectionOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  IconifyIconNameForMobileMenuAsc = 'iconifyIconNameForMobileMenu_ASC',
  IconifyIconNameForMobileMenuDesc = 'iconifyIconNameForMobileMenu_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SectionsMinimalsGalleryOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsHistoryComponent) */
export type SectionsMinimalsHistoryComponent = Entry & {
  __typename?: 'SectionsMinimalsHistoryComponent'
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  displayTime?: Maybe<Scalars['DateTime']>
  featureImage?: Maybe<Asset>
  linkedFrom?: Maybe<SectionsMinimalsHistoryComponentLinkingCollections>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsHistoryComponent) */
export type SectionsMinimalsHistoryComponentDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsHistoryComponent) */
export type SectionsMinimalsHistoryComponentDisplayTimeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsHistoryComponent) */
export type SectionsMinimalsHistoryComponentFeatureImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsHistoryComponent) */
export type SectionsMinimalsHistoryComponentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsHistoryComponent) */
export type SectionsMinimalsHistoryComponentTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionsMinimalsHistoryComponentCollection = {
  __typename?: 'SectionsMinimalsHistoryComponentCollection'
  items: Array<Maybe<SectionsMinimalsHistoryComponent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SectionsMinimalsHistoryComponentFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionsMinimalsHistoryComponentFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionsMinimalsHistoryComponentFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayTime?: InputMaybe<Scalars['DateTime']>
  displayTime_exists?: InputMaybe<Scalars['Boolean']>
  displayTime_gt?: InputMaybe<Scalars['DateTime']>
  displayTime_gte?: InputMaybe<Scalars['DateTime']>
  displayTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  displayTime_lt?: InputMaybe<Scalars['DateTime']>
  displayTime_lte?: InputMaybe<Scalars['DateTime']>
  displayTime_not?: InputMaybe<Scalars['DateTime']>
  displayTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  featureImage_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionsMinimalsHistoryComponentLinkingCollections = {
  __typename?: 'SectionsMinimalsHistoryComponentLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  sectionsMinimalsHistoryListCollection?: Maybe<SectionsMinimalsHistoryListCollection>
}

export type SectionsMinimalsHistoryComponentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsMinimalsHistoryComponentLinkingCollectionsSectionsMinimalsHistoryListCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SectionsMinimalsHistoryComponentLinkingCollectionsSectionsMinimalsHistoryListCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum SectionsMinimalsHistoryComponentLinkingCollectionsSectionsMinimalsHistoryListCollectionOrder {
  ComponentDateFormatAsc = 'componentDateFormat_ASC',
  ComponentDateFormatDesc = 'componentDateFormat_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SectionsMinimalsHistoryComponentOrder {
  DisplayTimeAsc = 'displayTime_ASC',
  DisplayTimeDesc = 'displayTime_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsHistoryList) */
export type SectionsMinimalsHistoryList = Entry & {
  __typename?: 'SectionsMinimalsHistoryList'
  componentDateFormat?: Maybe<Scalars['String']>
  componentsCollection?: Maybe<SectionsMinimalsHistoryListComponentsCollection>
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<SectionsMinimalsHistoryListLinkingCollections>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsHistoryList) */
export type SectionsMinimalsHistoryListComponentDateFormatArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsHistoryList) */
export type SectionsMinimalsHistoryListComponentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsMinimalsHistoryListComponentsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsMinimalsHistoryComponentFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsHistoryList) */
export type SectionsMinimalsHistoryListDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsHistoryList) */
export type SectionsMinimalsHistoryListLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsHistoryList) */
export type SectionsMinimalsHistoryListTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionsMinimalsHistoryListCollection = {
  __typename?: 'SectionsMinimalsHistoryListCollection'
  items: Array<Maybe<SectionsMinimalsHistoryList>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SectionsMinimalsHistoryListComponentsCollection = {
  __typename?: 'SectionsMinimalsHistoryListComponentsCollection'
  items: Array<Maybe<SectionsMinimalsHistoryComponent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum SectionsMinimalsHistoryListComponentsCollectionOrder {
  DisplayTimeAsc = 'displayTime_ASC',
  DisplayTimeDesc = 'displayTime_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionsMinimalsHistoryListFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionsMinimalsHistoryListFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionsMinimalsHistoryListFilter>>>
  componentDateFormat?: InputMaybe<Scalars['String']>
  componentDateFormat_contains?: InputMaybe<Scalars['String']>
  componentDateFormat_exists?: InputMaybe<Scalars['Boolean']>
  componentDateFormat_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  componentDateFormat_not?: InputMaybe<Scalars['String']>
  componentDateFormat_not_contains?: InputMaybe<Scalars['String']>
  componentDateFormat_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  components?: InputMaybe<CfSectionsMinimalsHistoryComponentNestedFilter>
  componentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionsMinimalsHistoryListLinkingCollections = {
  __typename?: 'SectionsMinimalsHistoryListLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pagesCollection?: Maybe<PagesCollection>
}

export type SectionsMinimalsHistoryListLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsMinimalsHistoryListLinkingCollectionsPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<SectionsMinimalsHistoryListLinkingCollectionsPagesCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SectionsMinimalsHistoryListLinkingCollectionsPagesCollectionOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  IconifyIconNameForMobileMenuAsc = 'iconifyIconNameForMobileMenu_ASC',
  IconifyIconNameForMobileMenuDesc = 'iconifyIconNameForMobileMenu_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SectionsMinimalsHistoryListOrder {
  ComponentDateFormatAsc = 'componentDateFormat_ASC',
  ComponentDateFormatDesc = 'componentDateFormat_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsHumanComponent) */
export type SectionsMinimalsHumanComponent = Entry & {
  __typename?: 'SectionsMinimalsHumanComponent'
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  descriptionHref?: Maybe<Scalars['String']>
  featureImage?: Maybe<Asset>
  linkedFrom?: Maybe<SectionsMinimalsHumanComponentLinkingCollections>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsHumanComponent) */
export type SectionsMinimalsHumanComponentDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsHumanComponent) */
export type SectionsMinimalsHumanComponentDescriptionHrefArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsHumanComponent) */
export type SectionsMinimalsHumanComponentFeatureImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsHumanComponent) */
export type SectionsMinimalsHumanComponentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsHumanComponent) */
export type SectionsMinimalsHumanComponentTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionsMinimalsHumanComponentCollection = {
  __typename?: 'SectionsMinimalsHumanComponentCollection'
  items: Array<Maybe<SectionsMinimalsHumanComponent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SectionsMinimalsHumanComponentFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionsMinimalsHumanComponentFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionsMinimalsHumanComponentFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  descriptionHref?: InputMaybe<Scalars['String']>
  descriptionHref_contains?: InputMaybe<Scalars['String']>
  descriptionHref_exists?: InputMaybe<Scalars['Boolean']>
  descriptionHref_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  descriptionHref_not?: InputMaybe<Scalars['String']>
  descriptionHref_not_contains?: InputMaybe<Scalars['String']>
  descriptionHref_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  featureImage_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionsMinimalsHumanComponentLinkingCollections = {
  __typename?: 'SectionsMinimalsHumanComponentLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  sectionsMinimalsHumanListCollection?: Maybe<SectionsMinimalsHumanListCollection>
}

export type SectionsMinimalsHumanComponentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsMinimalsHumanComponentLinkingCollectionsSectionsMinimalsHumanListCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SectionsMinimalsHumanComponentLinkingCollectionsSectionsMinimalsHumanListCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum SectionsMinimalsHumanComponentLinkingCollectionsSectionsMinimalsHumanListCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SectionsMinimalsHumanComponentOrder {
  DescriptionHrefAsc = 'descriptionHref_ASC',
  DescriptionHrefDesc = 'descriptionHref_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsHumanList) */
export type SectionsMinimalsHumanList = Entry & {
  __typename?: 'SectionsMinimalsHumanList'
  componentsCollection?: Maybe<SectionsMinimalsHumanListComponentsCollection>
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<SectionsMinimalsHumanListLinkingCollections>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsHumanList) */
export type SectionsMinimalsHumanListComponentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsMinimalsHumanListComponentsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsMinimalsHumanComponentFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsHumanList) */
export type SectionsMinimalsHumanListDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsHumanList) */
export type SectionsMinimalsHumanListLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsHumanList) */
export type SectionsMinimalsHumanListTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionsMinimalsHumanListCollection = {
  __typename?: 'SectionsMinimalsHumanListCollection'
  items: Array<Maybe<SectionsMinimalsHumanList>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SectionsMinimalsHumanListComponentsCollection = {
  __typename?: 'SectionsMinimalsHumanListComponentsCollection'
  items: Array<Maybe<SectionsMinimalsHumanComponent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum SectionsMinimalsHumanListComponentsCollectionOrder {
  DescriptionHrefAsc = 'descriptionHref_ASC',
  DescriptionHrefDesc = 'descriptionHref_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionsMinimalsHumanListFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionsMinimalsHumanListFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionsMinimalsHumanListFilter>>>
  components?: InputMaybe<CfSectionsMinimalsHumanComponentNestedFilter>
  componentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionsMinimalsHumanListLinkingCollections = {
  __typename?: 'SectionsMinimalsHumanListLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pagesCollection?: Maybe<PagesCollection>
}

export type SectionsMinimalsHumanListLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsMinimalsHumanListLinkingCollectionsPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<SectionsMinimalsHumanListLinkingCollectionsPagesCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SectionsMinimalsHumanListLinkingCollectionsPagesCollectionOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  IconifyIconNameForMobileMenuAsc = 'iconifyIconNameForMobileMenu_ASC',
  IconifyIconNameForMobileMenuDesc = 'iconifyIconNameForMobileMenu_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SectionsMinimalsHumanListOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsImagesList) */
export type SectionsMinimalsImagesList = Entry & {
  __typename?: 'SectionsMinimalsImagesList'
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  displayMode?: Maybe<Scalars['String']>
  gridModeColumnsCountDesktop?: Maybe<Scalars['Int']>
  gridModeColumnsCountMobile?: Maybe<Scalars['Int']>
  gridModeGapDesktop?: Maybe<Scalars['Int']>
  gridModeGapMobile?: Maybe<Scalars['Int']>
  imagesCollection?: Maybe<AssetCollection>
  imagesMobileCollection?: Maybe<AssetCollection>
  imagesWithHrefDesktopCollection?: Maybe<SectionsMinimalsImagesListImagesWithHrefDesktopCollection>
  imagesWithHrefMobileCollection?: Maybe<SectionsMinimalsImagesListImagesWithHrefMobileCollection>
  linkedFrom?: Maybe<SectionsMinimalsImagesListLinkingCollections>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsImagesList) */
export type SectionsMinimalsImagesListDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsImagesList) */
export type SectionsMinimalsImagesListDisplayModeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsImagesList) */
export type SectionsMinimalsImagesListGridModeColumnsCountDesktopArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsImagesList) */
export type SectionsMinimalsImagesListGridModeColumnsCountMobileArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsImagesList) */
export type SectionsMinimalsImagesListGridModeGapDesktopArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsImagesList) */
export type SectionsMinimalsImagesListGridModeGapMobileArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsImagesList) */
export type SectionsMinimalsImagesListImagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsImagesList) */
export type SectionsMinimalsImagesListImagesMobileCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsImagesList) */
export type SectionsMinimalsImagesListImagesWithHrefDesktopCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<SectionsMinimalsImagesListImagesWithHrefDesktopCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsImageFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsImagesList) */
export type SectionsMinimalsImagesListImagesWithHrefMobileCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<SectionsMinimalsImagesListImagesWithHrefMobileCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsImageFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsImagesList) */
export type SectionsMinimalsImagesListLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsImagesList) */
export type SectionsMinimalsImagesListTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionsMinimalsImagesListCollection = {
  __typename?: 'SectionsMinimalsImagesListCollection'
  items: Array<Maybe<SectionsMinimalsImagesList>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SectionsMinimalsImagesListFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionsMinimalsImagesListFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionsMinimalsImagesListFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayMode?: InputMaybe<Scalars['String']>
  displayMode_contains?: InputMaybe<Scalars['String']>
  displayMode_exists?: InputMaybe<Scalars['Boolean']>
  displayMode_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayMode_not?: InputMaybe<Scalars['String']>
  displayMode_not_contains?: InputMaybe<Scalars['String']>
  displayMode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  gridModeColumnsCountDesktop?: InputMaybe<Scalars['Int']>
  gridModeColumnsCountDesktop_exists?: InputMaybe<Scalars['Boolean']>
  gridModeColumnsCountDesktop_gt?: InputMaybe<Scalars['Int']>
  gridModeColumnsCountDesktop_gte?: InputMaybe<Scalars['Int']>
  gridModeColumnsCountDesktop_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  gridModeColumnsCountDesktop_lt?: InputMaybe<Scalars['Int']>
  gridModeColumnsCountDesktop_lte?: InputMaybe<Scalars['Int']>
  gridModeColumnsCountDesktop_not?: InputMaybe<Scalars['Int']>
  gridModeColumnsCountDesktop_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  gridModeColumnsCountMobile?: InputMaybe<Scalars['Int']>
  gridModeColumnsCountMobile_exists?: InputMaybe<Scalars['Boolean']>
  gridModeColumnsCountMobile_gt?: InputMaybe<Scalars['Int']>
  gridModeColumnsCountMobile_gte?: InputMaybe<Scalars['Int']>
  gridModeColumnsCountMobile_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  gridModeColumnsCountMobile_lt?: InputMaybe<Scalars['Int']>
  gridModeColumnsCountMobile_lte?: InputMaybe<Scalars['Int']>
  gridModeColumnsCountMobile_not?: InputMaybe<Scalars['Int']>
  gridModeColumnsCountMobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  gridModeGapDesktop?: InputMaybe<Scalars['Int']>
  gridModeGapDesktop_exists?: InputMaybe<Scalars['Boolean']>
  gridModeGapDesktop_gt?: InputMaybe<Scalars['Int']>
  gridModeGapDesktop_gte?: InputMaybe<Scalars['Int']>
  gridModeGapDesktop_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  gridModeGapDesktop_lt?: InputMaybe<Scalars['Int']>
  gridModeGapDesktop_lte?: InputMaybe<Scalars['Int']>
  gridModeGapDesktop_not?: InputMaybe<Scalars['Int']>
  gridModeGapDesktop_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  gridModeGapMobile?: InputMaybe<Scalars['Int']>
  gridModeGapMobile_exists?: InputMaybe<Scalars['Boolean']>
  gridModeGapMobile_gt?: InputMaybe<Scalars['Int']>
  gridModeGapMobile_gte?: InputMaybe<Scalars['Int']>
  gridModeGapMobile_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  gridModeGapMobile_lt?: InputMaybe<Scalars['Int']>
  gridModeGapMobile_lte?: InputMaybe<Scalars['Int']>
  gridModeGapMobile_not?: InputMaybe<Scalars['Int']>
  gridModeGapMobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  imagesCollection_exists?: InputMaybe<Scalars['Boolean']>
  imagesMobileCollection_exists?: InputMaybe<Scalars['Boolean']>
  imagesWithHrefDesktop?: InputMaybe<CfSectionsImageNestedFilter>
  imagesWithHrefDesktopCollection_exists?: InputMaybe<Scalars['Boolean']>
  imagesWithHrefMobile?: InputMaybe<CfSectionsImageNestedFilter>
  imagesWithHrefMobileCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionsMinimalsImagesListImagesWithHrefDesktopCollection = {
  __typename?: 'SectionsMinimalsImagesListImagesWithHrefDesktopCollection'
  items: Array<Maybe<SectionsImage>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum SectionsMinimalsImagesListImagesWithHrefDesktopCollectionOrder {
  CallToActionHrefAsc = 'callToActionHref_ASC',
  CallToActionHrefDesc = 'callToActionHref_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type SectionsMinimalsImagesListImagesWithHrefMobileCollection = {
  __typename?: 'SectionsMinimalsImagesListImagesWithHrefMobileCollection'
  items: Array<Maybe<SectionsImage>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum SectionsMinimalsImagesListImagesWithHrefMobileCollectionOrder {
  CallToActionHrefAsc = 'callToActionHref_ASC',
  CallToActionHrefDesc = 'callToActionHref_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type SectionsMinimalsImagesListLinkingCollections = {
  __typename?: 'SectionsMinimalsImagesListLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pagesCollection?: Maybe<PagesCollection>
  sectionsMinimalsGalleryCollection?: Maybe<SectionsMinimalsGalleryCollection>
}

export type SectionsMinimalsImagesListLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsMinimalsImagesListLinkingCollectionsPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<SectionsMinimalsImagesListLinkingCollectionsPagesCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsMinimalsImagesListLinkingCollectionsSectionsMinimalsGalleryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<SectionsMinimalsImagesListLinkingCollectionsSectionsMinimalsGalleryCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SectionsMinimalsImagesListLinkingCollectionsPagesCollectionOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  IconifyIconNameForMobileMenuAsc = 'iconifyIconNameForMobileMenu_ASC',
  IconifyIconNameForMobileMenuDesc = 'iconifyIconNameForMobileMenu_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SectionsMinimalsImagesListLinkingCollectionsSectionsMinimalsGalleryCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SectionsMinimalsImagesListOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DisplayModeAsc = 'displayMode_ASC',
  DisplayModeDesc = 'displayMode_DESC',
  GridModeColumnsCountDesktopAsc = 'gridModeColumnsCountDesktop_ASC',
  GridModeColumnsCountDesktopDesc = 'gridModeColumnsCountDesktop_DESC',
  GridModeColumnsCountMobileAsc = 'gridModeColumnsCountMobile_ASC',
  GridModeColumnsCountMobileDesc = 'gridModeColumnsCountMobile_DESC',
  GridModeGapDesktopAsc = 'gridModeGapDesktop_ASC',
  GridModeGapDesktopDesc = 'gridModeGapDesktop_DESC',
  GridModeGapMobileAsc = 'gridModeGapMobile_ASC',
  GridModeGapMobileDesc = 'gridModeGapMobile_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsService) */
export type SectionsMinimalsService = Entry & {
  __typename?: 'SectionsMinimalsService'
  callToActionHref?: Maybe<Scalars['String']>
  callToActionText?: Maybe<Scalars['String']>
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<SectionsMinimalsServiceLinkingCollections>
  services?: Maybe<Array<Maybe<Scalars['String']>>>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsService) */
export type SectionsMinimalsServiceCallToActionHrefArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsService) */
export type SectionsMinimalsServiceCallToActionTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsService) */
export type SectionsMinimalsServiceLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsService) */
export type SectionsMinimalsServiceServicesArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsService) */
export type SectionsMinimalsServiceTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionsMinimalsServiceCollection = {
  __typename?: 'SectionsMinimalsServiceCollection'
  items: Array<Maybe<SectionsMinimalsService>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SectionsMinimalsServiceFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionsMinimalsServiceFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionsMinimalsServiceFilter>>>
  callToActionHref?: InputMaybe<Scalars['String']>
  callToActionHref_contains?: InputMaybe<Scalars['String']>
  callToActionHref_exists?: InputMaybe<Scalars['Boolean']>
  callToActionHref_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionHref_not?: InputMaybe<Scalars['String']>
  callToActionHref_not_contains?: InputMaybe<Scalars['String']>
  callToActionHref_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionText?: InputMaybe<Scalars['String']>
  callToActionText_contains?: InputMaybe<Scalars['String']>
  callToActionText_exists?: InputMaybe<Scalars['Boolean']>
  callToActionText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionText_not?: InputMaybe<Scalars['String']>
  callToActionText_not_contains?: InputMaybe<Scalars['String']>
  callToActionText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  services_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  services_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  services_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  services_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionsMinimalsServiceLinkingCollections = {
  __typename?: 'SectionsMinimalsServiceLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  sectionsMinimalsServicesCollection?: Maybe<SectionsMinimalsServicesCollection>
}

export type SectionsMinimalsServiceLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsMinimalsServiceLinkingCollectionsSectionsMinimalsServicesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<SectionsMinimalsServiceLinkingCollectionsSectionsMinimalsServicesCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SectionsMinimalsServiceLinkingCollectionsSectionsMinimalsServicesCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SectionsMinimalsServiceOrder {
  CallToActionHrefAsc = 'callToActionHref_ASC',
  CallToActionHrefDesc = 'callToActionHref_DESC',
  CallToActionTextAsc = 'callToActionText_ASC',
  CallToActionTextDesc = 'callToActionText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsServices) */
export type SectionsMinimalsServices = Entry & {
  __typename?: 'SectionsMinimalsServices'
  componentsCollection?: Maybe<SectionsMinimalsServicesComponentsCollection>
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<SectionsMinimalsServicesLinkingCollections>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsServices) */
export type SectionsMinimalsServicesComponentsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsMinimalsServicesComponentsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsMinimalsServiceFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsServices) */
export type SectionsMinimalsServicesDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsServices) */
export type SectionsMinimalsServicesLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsServices) */
export type SectionsMinimalsServicesTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionsMinimalsServicesCollection = {
  __typename?: 'SectionsMinimalsServicesCollection'
  items: Array<Maybe<SectionsMinimalsServices>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SectionsMinimalsServicesComponentsCollection = {
  __typename?: 'SectionsMinimalsServicesComponentsCollection'
  items: Array<Maybe<SectionsMinimalsService>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum SectionsMinimalsServicesComponentsCollectionOrder {
  CallToActionHrefAsc = 'callToActionHref_ASC',
  CallToActionHrefDesc = 'callToActionHref_DESC',
  CallToActionTextAsc = 'callToActionText_ASC',
  CallToActionTextDesc = 'callToActionText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionsMinimalsServicesFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionsMinimalsServicesFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionsMinimalsServicesFilter>>>
  components?: InputMaybe<CfSectionsMinimalsServiceNestedFilter>
  componentsCollection_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionsMinimalsServicesLinkingCollections = {
  __typename?: 'SectionsMinimalsServicesLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pagesCollection?: Maybe<PagesCollection>
}

export type SectionsMinimalsServicesLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsMinimalsServicesLinkingCollectionsPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<SectionsMinimalsServicesLinkingCollectionsPagesCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SectionsMinimalsServicesLinkingCollectionsPagesCollectionOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  IconifyIconNameForMobileMenuAsc = 'iconifyIconNameForMobileMenu_ASC',
  IconifyIconNameForMobileMenuDesc = 'iconifyIconNameForMobileMenu_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SectionsMinimalsServicesOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsVideo) */
export type SectionsMinimalsVideo = Entry & {
  __typename?: 'SectionsMinimalsVideo'
  callToAction?: Maybe<CallToActionLink>
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  featureDescription?: Maybe<SectionsMinimalsVideoFeatureDescription>
  image?: Maybe<Asset>
  linkedFrom?: Maybe<SectionsMinimalsVideoLinkingCollections>
  mode?: Maybe<Scalars['String']>
  sys: Sys
  title?: Maybe<Scalars['String']>
  video?: Maybe<Asset>
  videoRatio?: Maybe<Scalars['String']>
  youtubeEmbedLink?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsVideo) */
export type SectionsMinimalsVideoCallToActionArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CallToActionLinkFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsVideo) */
export type SectionsMinimalsVideoDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsVideo) */
export type SectionsMinimalsVideoFeatureDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsVideo) */
export type SectionsMinimalsVideoImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsVideo) */
export type SectionsMinimalsVideoLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsVideo) */
export type SectionsMinimalsVideoModeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsVideo) */
export type SectionsMinimalsVideoTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsVideo) */
export type SectionsMinimalsVideoVideoArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsVideo) */
export type SectionsMinimalsVideoVideoRatioArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMinimalsVideo) */
export type SectionsMinimalsVideoYoutubeEmbedLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionsMinimalsVideoCollection = {
  __typename?: 'SectionsMinimalsVideoCollection'
  items: Array<Maybe<SectionsMinimalsVideo>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SectionsMinimalsVideoFeatureDescription = {
  __typename?: 'SectionsMinimalsVideoFeatureDescription'
  json: Scalars['JSON']
  links: SectionsMinimalsVideoFeatureDescriptionLinks
}

export type SectionsMinimalsVideoFeatureDescriptionAssets = {
  __typename?: 'SectionsMinimalsVideoFeatureDescriptionAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type SectionsMinimalsVideoFeatureDescriptionEntries = {
  __typename?: 'SectionsMinimalsVideoFeatureDescriptionEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type SectionsMinimalsVideoFeatureDescriptionLinks = {
  __typename?: 'SectionsMinimalsVideoFeatureDescriptionLinks'
  assets: SectionsMinimalsVideoFeatureDescriptionAssets
  entries: SectionsMinimalsVideoFeatureDescriptionEntries
  resources: SectionsMinimalsVideoFeatureDescriptionResources
}

export type SectionsMinimalsVideoFeatureDescriptionResources = {
  __typename?: 'SectionsMinimalsVideoFeatureDescriptionResources'
  block: Array<ResourceLink>
}

export type SectionsMinimalsVideoFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionsMinimalsVideoFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionsMinimalsVideoFilter>>>
  callToAction?: InputMaybe<CfCallToActionLinkNestedFilter>
  callToAction_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  featureDescription_contains?: InputMaybe<Scalars['String']>
  featureDescription_exists?: InputMaybe<Scalars['Boolean']>
  featureDescription_not_contains?: InputMaybe<Scalars['String']>
  image_exists?: InputMaybe<Scalars['Boolean']>
  mode?: InputMaybe<Scalars['String']>
  mode_contains?: InputMaybe<Scalars['String']>
  mode_exists?: InputMaybe<Scalars['Boolean']>
  mode_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mode_not?: InputMaybe<Scalars['String']>
  mode_not_contains?: InputMaybe<Scalars['String']>
  mode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  videoRatio?: InputMaybe<Scalars['String']>
  videoRatio_contains?: InputMaybe<Scalars['String']>
  videoRatio_exists?: InputMaybe<Scalars['Boolean']>
  videoRatio_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  videoRatio_not?: InputMaybe<Scalars['String']>
  videoRatio_not_contains?: InputMaybe<Scalars['String']>
  videoRatio_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  video_exists?: InputMaybe<Scalars['Boolean']>
  youtubeEmbedLink?: InputMaybe<Scalars['String']>
  youtubeEmbedLink_contains?: InputMaybe<Scalars['String']>
  youtubeEmbedLink_exists?: InputMaybe<Scalars['Boolean']>
  youtubeEmbedLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  youtubeEmbedLink_not?: InputMaybe<Scalars['String']>
  youtubeEmbedLink_not_contains?: InputMaybe<Scalars['String']>
  youtubeEmbedLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionsMinimalsVideoLinkingCollections = {
  __typename?: 'SectionsMinimalsVideoLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pagesCollection?: Maybe<PagesCollection>
}

export type SectionsMinimalsVideoLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsMinimalsVideoLinkingCollectionsPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsMinimalsVideoLinkingCollectionsPagesCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SectionsMinimalsVideoLinkingCollectionsPagesCollectionOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  IconifyIconNameForMobileMenuAsc = 'iconifyIconNameForMobileMenu_ASC',
  IconifyIconNameForMobileMenuDesc = 'iconifyIconNameForMobileMenu_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SectionsMinimalsVideoOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ModeAsc = 'mode_ASC',
  ModeDesc = 'mode_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  VideoRatioAsc = 'videoRatio_ASC',
  VideoRatioDesc = 'videoRatio_DESC',
  YoutubeEmbedLinkAsc = 'youtubeEmbedLink_ASC',
  YoutubeEmbedLinkDesc = 'youtubeEmbedLink_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMintList) */
export type SectionsMintList = Entry & {
  __typename?: 'SectionsMintList'
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<SectionsMintListLinkingCollections>
  mintCardsCollection?: Maybe<SectionsMintListMintCardsCollection>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMintList) */
export type SectionsMintListDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMintList) */
export type SectionsMintListLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMintList) */
export type SectionsMintListMintCardsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsMintListMintCardsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsMintListComponentFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMintList) */
export type SectionsMintListTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionsMintListCollection = {
  __typename?: 'SectionsMintListCollection'
  items: Array<Maybe<SectionsMintList>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMintListComponent) */
export type SectionsMintListComponent = Entry & {
  __typename?: 'SectionsMintListComponent'
  contentfulMetadata: ContentfulMetadata
  dependencySmartContractErc721?: Maybe<SmartContractErc721>
  description?: Maybe<Scalars['String']>
  isDisplay?: Maybe<Scalars['Boolean']>
  linkedFrom?: Maybe<SectionsMintListComponentLinkingCollections>
  price?: Maybe<Scalars['Float']>
  smartContractCallToActionName?: Maybe<Scalars['String']>
  smartContractErc721?: Maybe<SmartContractErc721>
  style?: Maybe<Scalars['String']>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMintListComponent) */
export type SectionsMintListComponentDependencySmartContractErc721Args = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SmartContractErc721Filter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMintListComponent) */
export type SectionsMintListComponentDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMintListComponent) */
export type SectionsMintListComponentIsDisplayArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMintListComponent) */
export type SectionsMintListComponentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMintListComponent) */
export type SectionsMintListComponentPriceArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMintListComponent) */
export type SectionsMintListComponentSmartContractCallToActionNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMintListComponent) */
export type SectionsMintListComponentSmartContractErc721Args = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SmartContractErc721Filter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMintListComponent) */
export type SectionsMintListComponentStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsMintListComponent) */
export type SectionsMintListComponentTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionsMintListComponentCollection = {
  __typename?: 'SectionsMintListComponentCollection'
  items: Array<Maybe<SectionsMintListComponent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SectionsMintListComponentFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionsMintListComponentFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionsMintListComponentFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  dependencySmartContractErc721?: InputMaybe<CfSmartContractErc721NestedFilter>
  dependencySmartContractErc721_exists?: InputMaybe<Scalars['Boolean']>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  isDisplay?: InputMaybe<Scalars['Boolean']>
  isDisplay_exists?: InputMaybe<Scalars['Boolean']>
  isDisplay_not?: InputMaybe<Scalars['Boolean']>
  price?: InputMaybe<Scalars['Float']>
  price_exists?: InputMaybe<Scalars['Boolean']>
  price_gt?: InputMaybe<Scalars['Float']>
  price_gte?: InputMaybe<Scalars['Float']>
  price_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  price_lt?: InputMaybe<Scalars['Float']>
  price_lte?: InputMaybe<Scalars['Float']>
  price_not?: InputMaybe<Scalars['Float']>
  price_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  smartContractCallToActionName?: InputMaybe<Scalars['String']>
  smartContractCallToActionName_contains?: InputMaybe<Scalars['String']>
  smartContractCallToActionName_exists?: InputMaybe<Scalars['Boolean']>
  smartContractCallToActionName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  smartContractCallToActionName_not?: InputMaybe<Scalars['String']>
  smartContractCallToActionName_not_contains?: InputMaybe<Scalars['String']>
  smartContractCallToActionName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  smartContractErc721?: InputMaybe<CfSmartContractErc721NestedFilter>
  smartContractErc721_exists?: InputMaybe<Scalars['Boolean']>
  style?: InputMaybe<Scalars['String']>
  style_contains?: InputMaybe<Scalars['String']>
  style_exists?: InputMaybe<Scalars['Boolean']>
  style_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  style_not?: InputMaybe<Scalars['String']>
  style_not_contains?: InputMaybe<Scalars['String']>
  style_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionsMintListComponentLinkingCollections = {
  __typename?: 'SectionsMintListComponentLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  sectionsMintListCollection?: Maybe<SectionsMintListCollection>
}

export type SectionsMintListComponentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsMintListComponentLinkingCollectionsSectionsMintListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<SectionsMintListComponentLinkingCollectionsSectionsMintListCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SectionsMintListComponentLinkingCollectionsSectionsMintListCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SectionsMintListComponentOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsDisplayAsc = 'isDisplay_ASC',
  IsDisplayDesc = 'isDisplay_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  SmartContractCallToActionNameAsc = 'smartContractCallToActionName_ASC',
  SmartContractCallToActionNameDesc = 'smartContractCallToActionName_DESC',
  StyleAsc = 'style_ASC',
  StyleDesc = 'style_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionsMintListFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionsMintListFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionsMintListFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mintCards?: InputMaybe<CfSectionsMintListComponentNestedFilter>
  mintCardsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionsMintListLinkingCollections = {
  __typename?: 'SectionsMintListLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pagesCollection?: Maybe<PagesCollection>
}

export type SectionsMintListLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsMintListLinkingCollectionsPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsMintListLinkingCollectionsPagesCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SectionsMintListLinkingCollectionsPagesCollectionOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  IconifyIconNameForMobileMenuAsc = 'iconifyIconNameForMobileMenu_ASC',
  IconifyIconNameForMobileMenuDesc = 'iconifyIconNameForMobileMenu_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type SectionsMintListMintCardsCollection = {
  __typename?: 'SectionsMintListMintCardsCollection'
  items: Array<Maybe<SectionsMintListComponent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum SectionsMintListMintCardsCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsDisplayAsc = 'isDisplay_ASC',
  IsDisplayDesc = 'isDisplay_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  SmartContractCallToActionNameAsc = 'smartContractCallToActionName_ASC',
  SmartContractCallToActionNameDesc = 'smartContractCallToActionName_DESC',
  StyleAsc = 'style_ASC',
  StyleDesc = 'style_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SectionsMintListOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsTimer) */
export type SectionsTimer = Entry & {
  __typename?: 'SectionsTimer'
  appearance?: Maybe<Scalars['String']>
  backgroundImage?: Maybe<Asset>
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<SectionsTimerLinkingCollections>
  startTime?: Maybe<Scalars['DateTime']>
  sys: Sys
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsTimer) */
export type SectionsTimerAppearanceArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsTimer) */
export type SectionsTimerBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsTimer) */
export type SectionsTimerDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsTimer) */
export type SectionsTimerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsTimer) */
export type SectionsTimerStartTimeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/sectionsTimer) */
export type SectionsTimerTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SectionsTimerCollection = {
  __typename?: 'SectionsTimerCollection'
  items: Array<Maybe<SectionsTimer>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SectionsTimerFilter = {
  AND?: InputMaybe<Array<InputMaybe<SectionsTimerFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SectionsTimerFilter>>>
  appearance?: InputMaybe<Scalars['String']>
  appearance_contains?: InputMaybe<Scalars['String']>
  appearance_exists?: InputMaybe<Scalars['Boolean']>
  appearance_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  appearance_not?: InputMaybe<Scalars['String']>
  appearance_not_contains?: InputMaybe<Scalars['String']>
  appearance_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  startTime?: InputMaybe<Scalars['DateTime']>
  startTime_exists?: InputMaybe<Scalars['Boolean']>
  startTime_gt?: InputMaybe<Scalars['DateTime']>
  startTime_gte?: InputMaybe<Scalars['DateTime']>
  startTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  startTime_lt?: InputMaybe<Scalars['DateTime']>
  startTime_lte?: InputMaybe<Scalars['DateTime']>
  startTime_not?: InputMaybe<Scalars['DateTime']>
  startTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SectionsTimerLinkingCollections = {
  __typename?: 'SectionsTimerLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pagesCollection?: Maybe<PagesCollection>
}

export type SectionsTimerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SectionsTimerLinkingCollectionsPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsTimerLinkingCollectionsPagesCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SectionsTimerLinkingCollectionsPagesCollectionOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  IconifyIconNameForMobileMenuAsc = 'iconifyIconNameForMobileMenu_ASC',
  IconifyIconNameForMobileMenuDesc = 'iconifyIconNameForMobileMenu_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SectionsTimerOrder {
  AppearanceAsc = 'appearance_ASC',
  AppearanceDesc = 'appearance_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  StartTimeAsc = 'startTime_ASC',
  StartTimeDesc = 'startTime_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SeenPromptResponse = {
  __typename?: 'SeenPromptResponse'
  affected_rows: Scalars['Int']
  returning: Array<Maybe<GptHeroSeenPrompt>>
}

export type SeoInfo = {
  __typename?: 'SeoInfo'
  description?: Maybe<Scalars['String']>
  metaItems?: Maybe<Array<Maybe<MetaItem>>>
  title?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/seoMetadata) */
export type SeoMetadata = Entry & {
  __typename?: 'SeoMetadata'
  content?: Maybe<Scalars['String']>
  contentfulMetadata: ContentfulMetadata
  linkedFrom?: Maybe<SeoMetadataLinkingCollections>
  name?: Maybe<Scalars['String']>
  sys: Sys
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/seoMetadata) */
export type SeoMetadataContentArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/seoMetadata) */
export type SeoMetadataLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/seoMetadata) */
export type SeoMetadataNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SeoMetadataCollection = {
  __typename?: 'SeoMetadataCollection'
  items: Array<Maybe<SeoMetadata>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SeoMetadataFilter = {
  AND?: InputMaybe<Array<InputMaybe<SeoMetadataFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SeoMetadataFilter>>>
  content?: InputMaybe<Scalars['String']>
  content_contains?: InputMaybe<Scalars['String']>
  content_exists?: InputMaybe<Scalars['Boolean']>
  content_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  content_not?: InputMaybe<Scalars['String']>
  content_not_contains?: InputMaybe<Scalars['String']>
  content_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type SeoMetadataLinkingCollections = {
  __typename?: 'SeoMetadataLinkingCollections'
  blogsCollection?: Maybe<BlogsCollection>
  entryCollection?: Maybe<EntryCollection>
  pagesCollection?: Maybe<PagesCollection>
  productsCollection?: Maybe<ProductsCollection>
  rootSettingsCollection?: Maybe<RootSettingsCollection>
}

export type SeoMetadataLinkingCollectionsBlogsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SeoMetadataLinkingCollectionsBlogsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SeoMetadataLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SeoMetadataLinkingCollectionsPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SeoMetadataLinkingCollectionsPagesCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SeoMetadataLinkingCollectionsProductsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SeoMetadataLinkingCollectionsProductsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SeoMetadataLinkingCollectionsRootSettingsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SeoMetadataLinkingCollectionsRootSettingsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SeoMetadataLinkingCollectionsBlogsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SeoMetadataLinkingCollectionsPagesCollectionOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  IconifyIconNameForMobileMenuAsc = 'iconifyIconNameForMobileMenu_ASC',
  IconifyIconNameForMobileMenuDesc = 'iconifyIconNameForMobileMenu_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SeoMetadataLinkingCollectionsProductsCollectionOrder {
  AbstractAsc = 'abstract_ASC',
  AbstractDesc = 'abstract_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SeoMetadataLinkingCollectionsRootSettingsCollectionOrder {
  CompanyColorAsc = 'companyColor_ASC',
  CompanyColorDesc = 'companyColor_DESC',
  RenameCallToActionLinksDisplayTextAsc = 'renameCallToActionLinksDisplayText_ASC',
  RenameCallToActionLinksDisplayTextDesc = 'renameCallToActionLinksDisplayText_DESC',
  RenamePathDisplayTextBlogAsc = 'renamePathDisplayTextBlog_ASC',
  RenamePathDisplayTextBlogDesc = 'renamePathDisplayTextBlog_DESC',
  RenamePathDisplayTextHomeAsc = 'renamePathDisplayTextHome_ASC',
  RenamePathDisplayTextHomeDesc = 'renamePathDisplayTextHome_DESC',
  RenamePathDisplayTextProductAsc = 'renamePathDisplayTextProduct_ASC',
  RenamePathDisplayTextProductDesc = 'renamePathDisplayTextProduct_DESC',
  ScopeAsc = 'scope_ASC',
  ScopeDesc = 'scope_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
}

export enum SeoMetadataOrder {
  ContentAsc = 'content_ASC',
  ContentDesc = 'content_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721) */
export type SmartContractErc721 = Entry & {
  __typename?: 'SmartContractErc721'
  abi?: Maybe<Scalars['JSON']>
  bannerImage?: Maybe<Asset>
  contentfulMetadata: ContentfulMetadata
  displayImage?: Maybe<Asset>
  featureImage?: Maybe<Asset>
  isSingleTokenMetadataMode?: Maybe<Scalars['Boolean']>
  linkedFrom?: Maybe<SmartContractErc721LinkingCollections>
  logo?: Maybe<Asset>
  network?: Maybe<Scalars['String']>
  singleToken?: Maybe<Erc721TokenMetadata>
  smartContractAddress?: Maybe<Scalars['String']>
  smartContractGist?: Maybe<Scalars['String']>
  sys: Sys
  tokensCollection?: Maybe<SmartContractErc721TokensCollection>
  whitelistAddressesCollection?: Maybe<SmartContractErc721WhitelistAddressesCollection>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721) */
export type SmartContractErc721AbiArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721) */
export type SmartContractErc721BannerImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721) */
export type SmartContractErc721DisplayImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721) */
export type SmartContractErc721FeatureImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721) */
export type SmartContractErc721IsSingleTokenMetadataModeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721) */
export type SmartContractErc721LinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721) */
export type SmartContractErc721LogoArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721) */
export type SmartContractErc721NetworkArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721) */
export type SmartContractErc721SingleTokenArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<Erc721TokenMetadataFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721) */
export type SmartContractErc721SmartContractAddressArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721) */
export type SmartContractErc721SmartContractGistArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721) */
export type SmartContractErc721TokensCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SmartContractErc721TokensCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<Erc721TokenMetadataFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721) */
export type SmartContractErc721WhitelistAddressesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SmartContractErc721WhitelistAddressesCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SmartContractErc721WhitelistAddressFilter>
}

export type SmartContractErc721Collection = {
  __typename?: 'SmartContractErc721Collection'
  items: Array<Maybe<SmartContractErc721>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SmartContractErc721Filter = {
  AND?: InputMaybe<Array<InputMaybe<SmartContractErc721Filter>>>
  OR?: InputMaybe<Array<InputMaybe<SmartContractErc721Filter>>>
  abi_exists?: InputMaybe<Scalars['Boolean']>
  bannerImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  displayImage_exists?: InputMaybe<Scalars['Boolean']>
  featureImage_exists?: InputMaybe<Scalars['Boolean']>
  isSingleTokenMetadataMode?: InputMaybe<Scalars['Boolean']>
  isSingleTokenMetadataMode_exists?: InputMaybe<Scalars['Boolean']>
  isSingleTokenMetadataMode_not?: InputMaybe<Scalars['Boolean']>
  logo_exists?: InputMaybe<Scalars['Boolean']>
  network?: InputMaybe<Scalars['String']>
  network_contains?: InputMaybe<Scalars['String']>
  network_exists?: InputMaybe<Scalars['Boolean']>
  network_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  network_not?: InputMaybe<Scalars['String']>
  network_not_contains?: InputMaybe<Scalars['String']>
  network_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  singleToken?: InputMaybe<CfErc721TokenMetadataNestedFilter>
  singleToken_exists?: InputMaybe<Scalars['Boolean']>
  smartContractAddress?: InputMaybe<Scalars['String']>
  smartContractAddress_contains?: InputMaybe<Scalars['String']>
  smartContractAddress_exists?: InputMaybe<Scalars['Boolean']>
  smartContractAddress_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  smartContractAddress_not?: InputMaybe<Scalars['String']>
  smartContractAddress_not_contains?: InputMaybe<Scalars['String']>
  smartContractAddress_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  smartContractGist?: InputMaybe<Scalars['String']>
  smartContractGist_contains?: InputMaybe<Scalars['String']>
  smartContractGist_exists?: InputMaybe<Scalars['Boolean']>
  smartContractGist_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  smartContractGist_not?: InputMaybe<Scalars['String']>
  smartContractGist_not_contains?: InputMaybe<Scalars['String']>
  smartContractGist_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tokens?: InputMaybe<CfErc721TokenMetadataNestedFilter>
  tokensCollection_exists?: InputMaybe<Scalars['Boolean']>
  whitelistAddresses?: InputMaybe<CfSmartContractErc721WhitelistAddressNestedFilter>
  whitelistAddressesCollection_exists?: InputMaybe<Scalars['Boolean']>
}

export type SmartContractErc721LinkingCollections = {
  __typename?: 'SmartContractErc721LinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  sectionsMintListComponentCollection?: Maybe<SectionsMintListComponentCollection>
}

export type SmartContractErc721LinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SmartContractErc721LinkingCollectionsSectionsMintListComponentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<SmartContractErc721LinkingCollectionsSectionsMintListComponentCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SmartContractErc721LinkingCollectionsSectionsMintListComponentCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsDisplayAsc = 'isDisplay_ASC',
  IsDisplayDesc = 'isDisplay_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  SmartContractCallToActionNameAsc = 'smartContractCallToActionName_ASC',
  SmartContractCallToActionNameDesc = 'smartContractCallToActionName_DESC',
  StyleAsc = 'style_ASC',
  StyleDesc = 'style_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SmartContractErc721Order {
  IsSingleTokenMetadataModeAsc = 'isSingleTokenMetadataMode_ASC',
  IsSingleTokenMetadataModeDesc = 'isSingleTokenMetadataMode_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  SmartContractAddressAsc = 'smartContractAddress_ASC',
  SmartContractAddressDesc = 'smartContractAddress_DESC',
  SmartContractGistAsc = 'smartContractGist_ASC',
  SmartContractGistDesc = 'smartContractGist_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721Token) */
export type SmartContractErc721Token = Entry & {
  __typename?: 'SmartContractErc721Token'
  animationUrl?: Maybe<Scalars['String']>
  attributesCollection?: Maybe<SmartContractErc721TokenAttributesCollection>
  backgroundColor?: Maybe<Scalars['String']>
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  externalUrl?: Maybe<Scalars['String']>
  image?: Maybe<Asset>
  linkedFrom?: Maybe<SmartContractErc721TokenLinkingCollections>
  name?: Maybe<Scalars['String']>
  sys: Sys
  tokenId?: Maybe<Scalars['Int']>
  youtubeUrl?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721Token) */
export type SmartContractErc721TokenAnimationUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721Token) */
export type SmartContractErc721TokenAttributesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SmartContractErc721TokenAttributesCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SmartContractErc721TokenAttributeFilter>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721Token) */
export type SmartContractErc721TokenBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721Token) */
export type SmartContractErc721TokenDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721Token) */
export type SmartContractErc721TokenExternalUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721Token) */
export type SmartContractErc721TokenImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721Token) */
export type SmartContractErc721TokenLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721Token) */
export type SmartContractErc721TokenNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721Token) */
export type SmartContractErc721TokenTokenIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721Token) */
export type SmartContractErc721TokenYoutubeUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721TokenAttribute) */
export type SmartContractErc721TokenAttribute = Entry & {
  __typename?: 'SmartContractErc721TokenAttribute'
  contentfulMetadata: ContentfulMetadata
  displayType?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<SmartContractErc721TokenAttributeLinkingCollections>
  sys: Sys
  traitType?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721TokenAttribute) */
export type SmartContractErc721TokenAttributeDisplayTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721TokenAttribute) */
export type SmartContractErc721TokenAttributeLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721TokenAttribute) */
export type SmartContractErc721TokenAttributeTraitTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721TokenAttribute) */
export type SmartContractErc721TokenAttributeValueArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SmartContractErc721TokenAttributeCollection = {
  __typename?: 'SmartContractErc721TokenAttributeCollection'
  items: Array<Maybe<SmartContractErc721TokenAttribute>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SmartContractErc721TokenAttributeFilter = {
  AND?: InputMaybe<Array<InputMaybe<SmartContractErc721TokenAttributeFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SmartContractErc721TokenAttributeFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  displayType?: InputMaybe<Scalars['String']>
  displayType_contains?: InputMaybe<Scalars['String']>
  displayType_exists?: InputMaybe<Scalars['Boolean']>
  displayType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayType_not?: InputMaybe<Scalars['String']>
  displayType_not_contains?: InputMaybe<Scalars['String']>
  displayType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  traitType?: InputMaybe<Scalars['String']>
  traitType_contains?: InputMaybe<Scalars['String']>
  traitType_exists?: InputMaybe<Scalars['Boolean']>
  traitType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  traitType_not?: InputMaybe<Scalars['String']>
  traitType_not_contains?: InputMaybe<Scalars['String']>
  traitType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  value?: InputMaybe<Scalars['String']>
  value_contains?: InputMaybe<Scalars['String']>
  value_exists?: InputMaybe<Scalars['Boolean']>
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  value_not?: InputMaybe<Scalars['String']>
  value_not_contains?: InputMaybe<Scalars['String']>
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SmartContractErc721TokenAttributeLinkingCollections = {
  __typename?: 'SmartContractErc721TokenAttributeLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  smartContractErc721TokenCollection?: Maybe<SmartContractErc721TokenCollection>
}

export type SmartContractErc721TokenAttributeLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SmartContractErc721TokenAttributeLinkingCollectionsSmartContractErc721TokenCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SmartContractErc721TokenAttributeLinkingCollectionsSmartContractErc721TokenCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum SmartContractErc721TokenAttributeLinkingCollectionsSmartContractErc721TokenCollectionOrder {
  AnimationUrlAsc = 'animationUrl_ASC',
  AnimationUrlDesc = 'animationUrl_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ExternalUrlAsc = 'externalUrl_ASC',
  ExternalUrlDesc = 'externalUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TokenIdAsc = 'tokenId_ASC',
  TokenIdDesc = 'tokenId_DESC',
  YoutubeUrlAsc = 'youtubeUrl_ASC',
  YoutubeUrlDesc = 'youtubeUrl_DESC',
}

export enum SmartContractErc721TokenAttributeOrder {
  DisplayTypeAsc = 'displayType_ASC',
  DisplayTypeDesc = 'displayType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TraitTypeAsc = 'traitType_ASC',
  TraitTypeDesc = 'traitType_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export type SmartContractErc721TokenAttributesCollection = {
  __typename?: 'SmartContractErc721TokenAttributesCollection'
  items: Array<Maybe<SmartContractErc721TokenAttribute>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum SmartContractErc721TokenAttributesCollectionOrder {
  DisplayTypeAsc = 'displayType_ASC',
  DisplayTypeDesc = 'displayType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TraitTypeAsc = 'traitType_ASC',
  TraitTypeDesc = 'traitType_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export type SmartContractErc721TokenCollection = {
  __typename?: 'SmartContractErc721TokenCollection'
  items: Array<Maybe<SmartContractErc721Token>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SmartContractErc721TokenFilter = {
  AND?: InputMaybe<Array<InputMaybe<SmartContractErc721TokenFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SmartContractErc721TokenFilter>>>
  animationUrl?: InputMaybe<Scalars['String']>
  animationUrl_contains?: InputMaybe<Scalars['String']>
  animationUrl_exists?: InputMaybe<Scalars['Boolean']>
  animationUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  animationUrl_not?: InputMaybe<Scalars['String']>
  animationUrl_not_contains?: InputMaybe<Scalars['String']>
  animationUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  attributes?: InputMaybe<CfSmartContractErc721TokenAttributeNestedFilter>
  attributesCollection_exists?: InputMaybe<Scalars['Boolean']>
  backgroundColor?: InputMaybe<Scalars['String']>
  backgroundColor_contains?: InputMaybe<Scalars['String']>
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  backgroundColor_not?: InputMaybe<Scalars['String']>
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalUrl?: InputMaybe<Scalars['String']>
  externalUrl_contains?: InputMaybe<Scalars['String']>
  externalUrl_exists?: InputMaybe<Scalars['Boolean']>
  externalUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalUrl_not?: InputMaybe<Scalars['String']>
  externalUrl_not_contains?: InputMaybe<Scalars['String']>
  externalUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  image_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tokenId?: InputMaybe<Scalars['Int']>
  tokenId_exists?: InputMaybe<Scalars['Boolean']>
  tokenId_gt?: InputMaybe<Scalars['Int']>
  tokenId_gte?: InputMaybe<Scalars['Int']>
  tokenId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  tokenId_lt?: InputMaybe<Scalars['Int']>
  tokenId_lte?: InputMaybe<Scalars['Int']>
  tokenId_not?: InputMaybe<Scalars['Int']>
  tokenId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  youtubeUrl?: InputMaybe<Scalars['String']>
  youtubeUrl_contains?: InputMaybe<Scalars['String']>
  youtubeUrl_exists?: InputMaybe<Scalars['Boolean']>
  youtubeUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  youtubeUrl_not?: InputMaybe<Scalars['String']>
  youtubeUrl_not_contains?: InputMaybe<Scalars['String']>
  youtubeUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SmartContractErc721TokenLinkingCollections = {
  __typename?: 'SmartContractErc721TokenLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type SmartContractErc721TokenLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SmartContractErc721TokenOrder {
  AnimationUrlAsc = 'animationUrl_ASC',
  AnimationUrlDesc = 'animationUrl_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ExternalUrlAsc = 'externalUrl_ASC',
  ExternalUrlDesc = 'externalUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TokenIdAsc = 'tokenId_ASC',
  TokenIdDesc = 'tokenId_DESC',
  YoutubeUrlAsc = 'youtubeUrl_ASC',
  YoutubeUrlDesc = 'youtubeUrl_DESC',
}

export type SmartContractErc721TokensCollection = {
  __typename?: 'SmartContractErc721TokensCollection'
  items: Array<Maybe<Erc721TokenMetadata>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum SmartContractErc721TokensCollectionOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ExternalUrlAsc = 'externalUrl_ASC',
  ExternalUrlDesc = 'externalUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TokenIdAsc = 'tokenId_ASC',
  TokenIdDesc = 'tokenId_DESC',
  YoutubeUrlAsc = 'youtubeUrl_ASC',
  YoutubeUrlDesc = 'youtubeUrl_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721WhitelistAddress) */
export type SmartContractErc721WhitelistAddress = Entry & {
  __typename?: 'SmartContractErc721WhitelistAddress'
  alreadyMinted?: Maybe<Scalars['Boolean']>
  contentfulMetadata: ContentfulMetadata
  ethAddress?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<SmartContractErc721WhitelistAddressLinkingCollections>
  name?: Maybe<Scalars['String']>
  sys: Sys
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721WhitelistAddress) */
export type SmartContractErc721WhitelistAddressAlreadyMintedArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721WhitelistAddress) */
export type SmartContractErc721WhitelistAddressEthAddressArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721WhitelistAddress) */
export type SmartContractErc721WhitelistAddressLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/smartContractErc721WhitelistAddress) */
export type SmartContractErc721WhitelistAddressNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SmartContractErc721WhitelistAddressCollection = {
  __typename?: 'SmartContractErc721WhitelistAddressCollection'
  items: Array<Maybe<SmartContractErc721WhitelistAddress>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SmartContractErc721WhitelistAddressFilter = {
  AND?: InputMaybe<Array<InputMaybe<SmartContractErc721WhitelistAddressFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SmartContractErc721WhitelistAddressFilter>>>
  alreadyMinted?: InputMaybe<Scalars['Boolean']>
  alreadyMinted_exists?: InputMaybe<Scalars['Boolean']>
  alreadyMinted_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ethAddress?: InputMaybe<Scalars['String']>
  ethAddress_contains?: InputMaybe<Scalars['String']>
  ethAddress_exists?: InputMaybe<Scalars['Boolean']>
  ethAddress_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ethAddress_not?: InputMaybe<Scalars['String']>
  ethAddress_not_contains?: InputMaybe<Scalars['String']>
  ethAddress_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type SmartContractErc721WhitelistAddressLinkingCollections = {
  __typename?: 'SmartContractErc721WhitelistAddressLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  smartContractErc721Collection?: Maybe<SmartContractErc721Collection>
}

export type SmartContractErc721WhitelistAddressLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SmartContractErc721WhitelistAddressLinkingCollectionsSmartContractErc721CollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<SmartContractErc721WhitelistAddressLinkingCollectionsSmartContractErc721CollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum SmartContractErc721WhitelistAddressLinkingCollectionsSmartContractErc721CollectionOrder {
  IsSingleTokenMetadataModeAsc = 'isSingleTokenMetadataMode_ASC',
  IsSingleTokenMetadataModeDesc = 'isSingleTokenMetadataMode_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  SmartContractAddressAsc = 'smartContractAddress_ASC',
  SmartContractAddressDesc = 'smartContractAddress_DESC',
  SmartContractGistAsc = 'smartContractGist_ASC',
  SmartContractGistDesc = 'smartContractGist_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SmartContractErc721WhitelistAddressOrder {
  AlreadyMintedAsc = 'alreadyMinted_ASC',
  AlreadyMintedDesc = 'alreadyMinted_DESC',
  EthAddressAsc = 'ethAddress_ASC',
  EthAddressDesc = 'ethAddress_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type SmartContractErc721WhitelistAddressesCollection = {
  __typename?: 'SmartContractErc721WhitelistAddressesCollection'
  items: Array<Maybe<SmartContractErc721WhitelistAddress>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum SmartContractErc721WhitelistAddressesCollectionOrder {
  AlreadyMintedAsc = 'alreadyMinted_ASC',
  AlreadyMintedDesc = 'alreadyMinted_DESC',
  EthAddressAsc = 'ethAddress_ASC',
  EthAddressDesc = 'ethAddress_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/socialMediaLink) */
export type SocialMediaLink = Entry & {
  __typename?: 'SocialMediaLink'
  contentfulMetadata: ContentfulMetadata
  isExternal?: Maybe<Scalars['Boolean']>
  link?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<SocialMediaLinkLinkingCollections>
  overwriteDisplayIconWithIconifyIcon?: Maybe<Scalars['String']>
  sys: Sys
  title?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/socialMediaLink) */
export type SocialMediaLinkIsExternalArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/socialMediaLink) */
export type SocialMediaLinkLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/socialMediaLink) */
export type SocialMediaLinkLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/socialMediaLink) */
export type SocialMediaLinkOverwriteDisplayIconWithIconifyIconArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/socialMediaLink) */
export type SocialMediaLinkTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/socialMediaLink) */
export type SocialMediaLinkTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SocialMediaLinkCollection = {
  __typename?: 'SocialMediaLinkCollection'
  items: Array<Maybe<SocialMediaLink>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SocialMediaLinkFilter = {
  AND?: InputMaybe<Array<InputMaybe<SocialMediaLinkFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SocialMediaLinkFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  isExternal?: InputMaybe<Scalars['Boolean']>
  isExternal_exists?: InputMaybe<Scalars['Boolean']>
  isExternal_not?: InputMaybe<Scalars['Boolean']>
  link?: InputMaybe<Scalars['String']>
  link_contains?: InputMaybe<Scalars['String']>
  link_exists?: InputMaybe<Scalars['Boolean']>
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  link_not?: InputMaybe<Scalars['String']>
  link_not_contains?: InputMaybe<Scalars['String']>
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  overwriteDisplayIconWithIconifyIcon?: InputMaybe<Scalars['String']>
  overwriteDisplayIconWithIconifyIcon_contains?: InputMaybe<Scalars['String']>
  overwriteDisplayIconWithIconifyIcon_exists?: InputMaybe<Scalars['Boolean']>
  overwriteDisplayIconWithIconifyIcon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  overwriteDisplayIconWithIconifyIcon_not?: InputMaybe<Scalars['String']>
  overwriteDisplayIconWithIconifyIcon_not_contains?: InputMaybe<Scalars['String']>
  overwriteDisplayIconWithIconifyIcon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type?: InputMaybe<Scalars['String']>
  type_contains?: InputMaybe<Scalars['String']>
  type_exists?: InputMaybe<Scalars['Boolean']>
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type_not?: InputMaybe<Scalars['String']>
  type_not_contains?: InputMaybe<Scalars['String']>
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SocialMediaLinkLinkingCollections = {
  __typename?: 'SocialMediaLinkLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  rootSettingsCollection?: Maybe<RootSettingsCollection>
}

export type SocialMediaLinkLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SocialMediaLinkLinkingCollectionsRootSettingsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<SocialMediaLinkLinkingCollectionsRootSettingsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SocialMediaLinkLinkingCollectionsRootSettingsCollectionOrder {
  CompanyColorAsc = 'companyColor_ASC',
  CompanyColorDesc = 'companyColor_DESC',
  RenameCallToActionLinksDisplayTextAsc = 'renameCallToActionLinksDisplayText_ASC',
  RenameCallToActionLinksDisplayTextDesc = 'renameCallToActionLinksDisplayText_DESC',
  RenamePathDisplayTextBlogAsc = 'renamePathDisplayTextBlog_ASC',
  RenamePathDisplayTextBlogDesc = 'renamePathDisplayTextBlog_DESC',
  RenamePathDisplayTextHomeAsc = 'renamePathDisplayTextHome_ASC',
  RenamePathDisplayTextHomeDesc = 'renamePathDisplayTextHome_DESC',
  RenamePathDisplayTextProductAsc = 'renamePathDisplayTextProduct_ASC',
  RenamePathDisplayTextProductDesc = 'renamePathDisplayTextProduct_DESC',
  ScopeAsc = 'scope_ASC',
  ScopeDesc = 'scope_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
}

export enum SocialMediaLinkOrder {
  IsExternalAsc = 'isExternal_ASC',
  IsExternalDesc = 'isExternal_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  OverwriteDisplayIconWithIconifyIconAsc = 'overwriteDisplayIconWithIconifyIcon_ASC',
  OverwriteDisplayIconWithIconifyIconDesc = 'overwriteDisplayIconWithIconifyIcon_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>
  _gt?: InputMaybe<Scalars['String']>
  _gte?: InputMaybe<Scalars['String']>
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>
  _in?: InputMaybe<Array<Scalars['String']>>
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>
  _is_null?: InputMaybe<Scalars['Boolean']>
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>
  _lt?: InputMaybe<Scalars['String']>
  _lte?: InputMaybe<Scalars['String']>
  _neq?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>
  _nin?: InputMaybe<Array<Scalars['String']>>
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>
}

export type SupportingLanguage = {
  __typename?: 'SupportingLanguage'
  _id: Scalars['String']
  createdAt: Scalars['String']
  isDefault: Scalars['Boolean']
  isNew: Scalars['Boolean']
  name: Scalars['String']
  slug: Scalars['String']
}

export type SupportingLanguageInput = {
  _id: Scalars['String']
  slug: Scalars['String']
}

export type SupportingTone = {
  __typename?: 'SupportingTone'
  _id: Scalars['String']
  createdAt: Scalars['String']
  isNew: Scalars['Boolean']
  name: Scalars['String']
  slug: Scalars['String']
}

export type SupportingToneInput = {
  _id: Scalars['String']
  slug: Scalars['String']
}

export type SupportingUseCase = {
  __typename?: 'SupportingUseCase'
  _id: Scalars['String']
  caption: Scalars['String']
  contextInputs: Array<ContextInput>
  createdAt?: Maybe<Scalars['String']>
  description: Scalars['String']
  image: Scalars['String']
  info: Scalars['String']
  isCustom: Scalars['Boolean']
  isNew: Scalars['Boolean']
  key: Scalars['String']
  name: Scalars['String']
  slug: Scalars['String']
  variants: Scalars['Int']
}

export type SupportingUseCaseInput = {
  _id: Scalars['String']
  contextInputs: Array<ContextInputInput>
  slug: Scalars['String']
}

export type Sys = {
  __typename?: 'Sys'
  environmentId: Scalars['String']
  firstPublishedAt?: Maybe<Scalars['DateTime']>
  id: Scalars['String']
  publishedAt?: Maybe<Scalars['DateTime']>
  publishedVersion?: Maybe<Scalars['Int']>
  spaceId: Scalars['String']
}

export type SysFilter = {
  firstPublishedAt?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_exists?: InputMaybe<Scalars['Boolean']>
  firstPublishedAt_gt?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_gte?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  firstPublishedAt_lt?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_lte?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_not?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  id?: InputMaybe<Scalars['String']>
  id_contains?: InputMaybe<Scalars['String']>
  id_exists?: InputMaybe<Scalars['Boolean']>
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_not?: InputMaybe<Scalars['String']>
  id_not_contains?: InputMaybe<Scalars['String']>
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  publishedAt?: InputMaybe<Scalars['DateTime']>
  publishedAt_exists?: InputMaybe<Scalars['Boolean']>
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>
  publishedAt_not?: InputMaybe<Scalars['DateTime']>
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  publishedVersion?: InputMaybe<Scalars['Float']>
  publishedVersion_exists?: InputMaybe<Scalars['Boolean']>
  publishedVersion_gt?: InputMaybe<Scalars['Float']>
  publishedVersion_gte?: InputMaybe<Scalars['Float']>
  publishedVersion_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  publishedVersion_lt?: InputMaybe<Scalars['Float']>
  publishedVersion_lte?: InputMaybe<Scalars['Float']>
  publishedVersion_not?: InputMaybe<Scalars['Float']>
  publishedVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/tags) */
export type Tags = Entry & {
  __typename?: 'Tags'
  content?: Maybe<TagsContent>
  contentfulMetadata: ContentfulMetadata
  groupName?: Maybe<Scalars['String']>
  linkedFrom?: Maybe<TagsLinkingCollections>
  name?: Maybe<Scalars['String']>
  seoMetadataItemsCollection?: Maybe<TagsSeoMetadataItemsCollection>
  slug?: Maybe<Scalars['String']>
  sys: Sys
  typeForSearch?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/tags) */
export type TagsContentArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/tags) */
export type TagsGroupNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/tags) */
export type TagsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/tags) */
export type TagsNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/tags) */
export type TagsSeoMetadataItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/tags) */
export type TagsSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/035nyqgapb7h/content_types/tags) */
export type TagsTypeForSearchArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TagsCollection = {
  __typename?: 'TagsCollection'
  items: Array<Maybe<Tags>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TagsContent = {
  __typename?: 'TagsContent'
  json: Scalars['JSON']
  links: TagsContentLinks
}

export type TagsContentAssets = {
  __typename?: 'TagsContentAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type TagsContentEntries = {
  __typename?: 'TagsContentEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type TagsContentLinks = {
  __typename?: 'TagsContentLinks'
  assets: TagsContentAssets
  entries: TagsContentEntries
  resources: TagsContentResources
}

export type TagsContentResources = {
  __typename?: 'TagsContentResources'
  block: Array<ResourceLink>
}

export type TagsFilter = {
  AND?: InputMaybe<Array<InputMaybe<TagsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TagsFilter>>>
  content_contains?: InputMaybe<Scalars['String']>
  content_exists?: InputMaybe<Scalars['Boolean']>
  content_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  groupName?: InputMaybe<Scalars['String']>
  groupName_contains?: InputMaybe<Scalars['String']>
  groupName_exists?: InputMaybe<Scalars['Boolean']>
  groupName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  groupName_not?: InputMaybe<Scalars['String']>
  groupName_not_contains?: InputMaybe<Scalars['String']>
  groupName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  seoMetadataItemsCollection_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  typeForSearch_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  typeForSearch_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  typeForSearch_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  typeForSearch_exists?: InputMaybe<Scalars['Boolean']>
}

export type TagsLinkingCollections = {
  __typename?: 'TagsLinkingCollections'
  authorsCollection?: Maybe<AuthorsCollection>
  blogsCollection?: Maybe<BlogsCollection>
  entryCollection?: Maybe<EntryCollection>
  productsCollection?: Maybe<ProductsCollection>
}

export type TagsLinkingCollectionsAuthorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TagsLinkingCollectionsAuthorsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TagsLinkingCollectionsBlogsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TagsLinkingCollectionsBlogsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TagsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TagsLinkingCollectionsProductsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TagsLinkingCollectionsProductsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TagsLinkingCollectionsAuthorsCollectionOrder {
  GeneratedBlogStateAsc = 'generatedBlogState_ASC',
  GeneratedBlogStateDesc = 'generatedBlogState_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UseBlogAutoGeneratorAsc = 'useBlogAutoGenerator_ASC',
  UseBlogAutoGeneratorDesc = 'useBlogAutoGenerator_DESC',
}

export enum TagsLinkingCollectionsBlogsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum TagsLinkingCollectionsProductsCollectionOrder {
  AbstractAsc = 'abstract_ASC',
  AbstractDesc = 'abstract_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum TagsOrder {
  GroupNameAsc = 'groupName_ASC',
  GroupNameDesc = 'groupName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type TagsSeoMetadataItemsCollection = {
  __typename?: 'TagsSeoMetadataItemsCollection'
  items: Array<Maybe<Entry>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TextVariation = {
  __typename?: 'TextVariation'
  text?: Maybe<Scalars['String']>
}

export enum ToneSlug {
  Candid = 'candid',
  Convincing = 'convincing',
  Earnest = 'earnest',
  Formal = 'formal',
}

export enum WritingStyle {
  Academic = 'Academic',
  Analytical = 'Analytical',
  Argumentative = 'Argumentative',
  Conversational = 'Conversational',
  Creative = 'Creative',
  Critical = 'Critical',
  Descriptive = 'Descriptive',
  Epigrammatic = 'Epigrammatic',
  Epistolary = 'Epistolary',
  Expository = 'Expository',
  Informative = 'Informative',
  Instructive = 'Instructive',
  Journalistic = 'Journalistic',
  Label = 'Label',
  Metaphorical = 'Metaphorical',
  Narrative = 'Narrative',
  Persuasive = 'Persuasive',
  Poetic = 'Poetic',
  Satirical = 'Satirical',
  Technical = 'Technical',
}

/** columns and relationships of "aICompletionRecords" */
export type AICompletionRecords = {
  __typename?: 'aICompletionRecords'
  actorId?: Maybe<Scalars['String']>
  clientName?: Maybe<Scalars['String']>
  completedAt?: Maybe<Scalars['timestamptz']>
  content?: Maybe<Scalars['String']>
  createdAt: Scalars['timestamptz']
  id: Scalars['uuid']
  meta?: Maybe<Scalars['jsonb']>
  updatedAt: Scalars['timestamptz']
}

/** columns and relationships of "aICompletionRecords" */
export type AICompletionRecordsMetaArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "aICompletionRecords" */
export type AICompletionRecords_Aggregate = {
  __typename?: 'aICompletionRecords_aggregate'
  aggregate?: Maybe<AICompletionRecords_Aggregate_Fields>
  nodes: Array<AICompletionRecords>
}

/** aggregate fields of "aICompletionRecords" */
export type AICompletionRecords_Aggregate_Fields = {
  __typename?: 'aICompletionRecords_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<AICompletionRecords_Max_Fields>
  min?: Maybe<AICompletionRecords_Min_Fields>
}

/** aggregate fields of "aICompletionRecords" */
export type AICompletionRecords_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AICompletionRecords_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type AICompletionRecords_Append_Input = {
  meta?: InputMaybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "aICompletionRecords". All fields are combined with a logical 'AND'. */
export type AICompletionRecords_Bool_Exp = {
  _and?: InputMaybe<Array<AICompletionRecords_Bool_Exp>>
  _not?: InputMaybe<AICompletionRecords_Bool_Exp>
  _or?: InputMaybe<Array<AICompletionRecords_Bool_Exp>>
  actorId?: InputMaybe<String_Comparison_Exp>
  clientName?: InputMaybe<String_Comparison_Exp>
  completedAt?: InputMaybe<Timestamptz_Comparison_Exp>
  content?: InputMaybe<String_Comparison_Exp>
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  meta?: InputMaybe<Jsonb_Comparison_Exp>
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "aICompletionRecords" */
export enum AICompletionRecords_Constraint {
  /** unique or primary key constraint on columns "id" */
  AICompletionRecordsPkey = 'aICompletionRecords_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AICompletionRecords_Delete_At_Path_Input = {
  meta?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AICompletionRecords_Delete_Elem_Input = {
  meta?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AICompletionRecords_Delete_Key_Input = {
  meta?: InputMaybe<Scalars['String']>
}

/** input type for inserting data into table "aICompletionRecords" */
export type AICompletionRecords_Insert_Input = {
  actorId?: InputMaybe<Scalars['String']>
  clientName?: InputMaybe<Scalars['String']>
  completedAt?: InputMaybe<Scalars['timestamptz']>
  content?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  meta?: InputMaybe<Scalars['jsonb']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type AICompletionRecords_Max_Fields = {
  __typename?: 'aICompletionRecords_max_fields'
  actorId?: Maybe<Scalars['String']>
  clientName?: Maybe<Scalars['String']>
  completedAt?: Maybe<Scalars['timestamptz']>
  content?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type AICompletionRecords_Min_Fields = {
  __typename?: 'aICompletionRecords_min_fields'
  actorId?: Maybe<Scalars['String']>
  clientName?: Maybe<Scalars['String']>
  completedAt?: Maybe<Scalars['timestamptz']>
  content?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "aICompletionRecords" */
export type AICompletionRecords_Mutation_Response = {
  __typename?: 'aICompletionRecords_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<AICompletionRecords>
}

/** on_conflict condition type for table "aICompletionRecords" */
export type AICompletionRecords_On_Conflict = {
  constraint: AICompletionRecords_Constraint
  update_columns?: Array<AICompletionRecords_Update_Column>
  where?: InputMaybe<AICompletionRecords_Bool_Exp>
}

/** Ordering options when selecting data from "aICompletionRecords". */
export type AICompletionRecords_Order_By = {
  actorId?: InputMaybe<Order_By>
  clientName?: InputMaybe<Order_By>
  completedAt?: InputMaybe<Order_By>
  content?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  meta?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** primary key columns input for table: aICompletionRecords */
export type AICompletionRecords_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AICompletionRecords_Prepend_Input = {
  meta?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "aICompletionRecords" */
export enum AICompletionRecords_Select_Column {
  /** column name */
  ActorId = 'actorId',
  /** column name */
  ClientName = 'clientName',
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "aICompletionRecords" */
export type AICompletionRecords_Set_Input = {
  actorId?: InputMaybe<Scalars['String']>
  clientName?: InputMaybe<Scalars['String']>
  completedAt?: InputMaybe<Scalars['timestamptz']>
  content?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  meta?: InputMaybe<Scalars['jsonb']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "aICompletionRecords" */
export type AICompletionRecords_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AICompletionRecords_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type AICompletionRecords_Stream_Cursor_Value_Input = {
  actorId?: InputMaybe<Scalars['String']>
  clientName?: InputMaybe<Scalars['String']>
  completedAt?: InputMaybe<Scalars['timestamptz']>
  content?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  meta?: InputMaybe<Scalars['jsonb']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "aICompletionRecords" */
export enum AICompletionRecords_Update_Column {
  /** column name */
  ActorId = 'actorId',
  /** column name */
  ClientName = 'clientName',
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type AICompletionRecords_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AICompletionRecords_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AICompletionRecords_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AICompletionRecords_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AICompletionRecords_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AICompletionRecords_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AICompletionRecords_Set_Input>
  /** filter the rows which have to be updated */
  where: AICompletionRecords_Bool_Exp
}

/** columns and relationships of "apps" */
export type Apps = {
  __typename?: 'apps'
  createdAt: Scalars['timestamptz']
  /** An array relationship */
  formAnswers: Array<FormAnswers>
  /** An aggregate relationship */
  formAnswers_aggregate: FormAnswers_Aggregate
  /** An array relationship */
  forms: Array<Forms>
  /** An aggregate relationship */
  forms_aggregate: Forms_Aggregate
  host: Scalars['String']
  id: Scalars['String']
  stripeWritersoftConfigurationId?: Maybe<Scalars['String']>
  stripeWritersoftLookupKey?: Maybe<Scalars['String']>
  /** An array relationship */
  subscriptionItems: Array<SubscriptionItems>
  /** An aggregate relationship */
  subscriptionItems_aggregate: SubscriptionItems_Aggregate
  /** An array relationship */
  subscriptions: Array<Subscriptions>
  /** An aggregate relationship */
  subscriptions_aggregate: Subscriptions_Aggregate
  updatedAt: Scalars['timestamptz']
  /** An array relationship */
  users: Array<Users>
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate
  /** An array relationship */
  writersoftUsageReports: Array<WritersoftUsageReports>
  /** An aggregate relationship */
  writersoftUsageReports_aggregate: WritersoftUsageReports_Aggregate
}

/** columns and relationships of "apps" */
export type AppsFormAnswersArgs = {
  distinct_on?: InputMaybe<Array<FormAnswers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<FormAnswers_Order_By>>
  where?: InputMaybe<FormAnswers_Bool_Exp>
}

/** columns and relationships of "apps" */
export type AppsFormAnswers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FormAnswers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<FormAnswers_Order_By>>
  where?: InputMaybe<FormAnswers_Bool_Exp>
}

/** columns and relationships of "apps" */
export type AppsFormsArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Forms_Order_By>>
  where?: InputMaybe<Forms_Bool_Exp>
}

/** columns and relationships of "apps" */
export type AppsForms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Forms_Order_By>>
  where?: InputMaybe<Forms_Bool_Exp>
}

/** columns and relationships of "apps" */
export type AppsSubscriptionItemsArgs = {
  distinct_on?: InputMaybe<Array<SubscriptionItems_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<SubscriptionItems_Order_By>>
  where?: InputMaybe<SubscriptionItems_Bool_Exp>
}

/** columns and relationships of "apps" */
export type AppsSubscriptionItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SubscriptionItems_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<SubscriptionItems_Order_By>>
  where?: InputMaybe<SubscriptionItems_Bool_Exp>
}

/** columns and relationships of "apps" */
export type AppsSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>
  where?: InputMaybe<Subscriptions_Bool_Exp>
}

/** columns and relationships of "apps" */
export type AppsSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>
  where?: InputMaybe<Subscriptions_Bool_Exp>
}

/** columns and relationships of "apps" */
export type AppsUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Users_Order_By>>
  where?: InputMaybe<Users_Bool_Exp>
}

/** columns and relationships of "apps" */
export type AppsUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Users_Order_By>>
  where?: InputMaybe<Users_Bool_Exp>
}

/** columns and relationships of "apps" */
export type AppsWritersoftUsageReportsArgs = {
  distinct_on?: InputMaybe<Array<WritersoftUsageReports_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<WritersoftUsageReports_Order_By>>
  where?: InputMaybe<WritersoftUsageReports_Bool_Exp>
}

/** columns and relationships of "apps" */
export type AppsWritersoftUsageReports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WritersoftUsageReports_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<WritersoftUsageReports_Order_By>>
  where?: InputMaybe<WritersoftUsageReports_Bool_Exp>
}

/** aggregated selection of "apps" */
export type Apps_Aggregate = {
  __typename?: 'apps_aggregate'
  aggregate?: Maybe<Apps_Aggregate_Fields>
  nodes: Array<Apps>
}

/** aggregate fields of "apps" */
export type Apps_Aggregate_Fields = {
  __typename?: 'apps_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Apps_Max_Fields>
  min?: Maybe<Apps_Min_Fields>
}

/** aggregate fields of "apps" */
export type Apps_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Apps_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "apps". All fields are combined with a logical 'AND'. */
export type Apps_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_Bool_Exp>>
  _not?: InputMaybe<Apps_Bool_Exp>
  _or?: InputMaybe<Array<Apps_Bool_Exp>>
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>
  formAnswers?: InputMaybe<FormAnswers_Bool_Exp>
  formAnswers_aggregate?: InputMaybe<FormAnswers_Aggregate_Bool_Exp>
  forms?: InputMaybe<Forms_Bool_Exp>
  forms_aggregate?: InputMaybe<Forms_Aggregate_Bool_Exp>
  host?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<String_Comparison_Exp>
  stripeWritersoftConfigurationId?: InputMaybe<String_Comparison_Exp>
  stripeWritersoftLookupKey?: InputMaybe<String_Comparison_Exp>
  subscriptionItems?: InputMaybe<SubscriptionItems_Bool_Exp>
  subscriptionItems_aggregate?: InputMaybe<SubscriptionItems_Aggregate_Bool_Exp>
  subscriptions?: InputMaybe<Subscriptions_Bool_Exp>
  subscriptions_aggregate?: InputMaybe<Subscriptions_Aggregate_Bool_Exp>
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>
  users?: InputMaybe<Users_Bool_Exp>
  users_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>
  writersoftUsageReports?: InputMaybe<WritersoftUsageReports_Bool_Exp>
  writersoftUsageReports_aggregate?: InputMaybe<WritersoftUsageReports_Aggregate_Bool_Exp>
}

/** unique or primary key constraints on table "apps" */
export enum Apps_Constraint {
  /** unique or primary key constraint on columns "id" */
  AppsPkey = 'apps_pkey',
}

/** input type for inserting data into table "apps" */
export type Apps_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>
  formAnswers?: InputMaybe<FormAnswers_Arr_Rel_Insert_Input>
  forms?: InputMaybe<Forms_Arr_Rel_Insert_Input>
  host?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  stripeWritersoftConfigurationId?: InputMaybe<Scalars['String']>
  stripeWritersoftLookupKey?: InputMaybe<Scalars['String']>
  subscriptionItems?: InputMaybe<SubscriptionItems_Arr_Rel_Insert_Input>
  subscriptions?: InputMaybe<Subscriptions_Arr_Rel_Insert_Input>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  users?: InputMaybe<Users_Arr_Rel_Insert_Input>
  writersoftUsageReports?: InputMaybe<WritersoftUsageReports_Arr_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Apps_Max_Fields = {
  __typename?: 'apps_max_fields'
  createdAt?: Maybe<Scalars['timestamptz']>
  host?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  stripeWritersoftConfigurationId?: Maybe<Scalars['String']>
  stripeWritersoftLookupKey?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Apps_Min_Fields = {
  __typename?: 'apps_min_fields'
  createdAt?: Maybe<Scalars['timestamptz']>
  host?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  stripeWritersoftConfigurationId?: Maybe<Scalars['String']>
  stripeWritersoftLookupKey?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "apps" */
export type Apps_Mutation_Response = {
  __typename?: 'apps_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Apps>
}

/** input type for inserting object relation for remote table "apps" */
export type Apps_Obj_Rel_Insert_Input = {
  data: Apps_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Apps_On_Conflict>
}

/** on_conflict condition type for table "apps" */
export type Apps_On_Conflict = {
  constraint: Apps_Constraint
  update_columns?: Array<Apps_Update_Column>
  where?: InputMaybe<Apps_Bool_Exp>
}

/** Ordering options when selecting data from "apps". */
export type Apps_Order_By = {
  createdAt?: InputMaybe<Order_By>
  formAnswers_aggregate?: InputMaybe<FormAnswers_Aggregate_Order_By>
  forms_aggregate?: InputMaybe<Forms_Aggregate_Order_By>
  host?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  stripeWritersoftConfigurationId?: InputMaybe<Order_By>
  stripeWritersoftLookupKey?: InputMaybe<Order_By>
  subscriptionItems_aggregate?: InputMaybe<SubscriptionItems_Aggregate_Order_By>
  subscriptions_aggregate?: InputMaybe<Subscriptions_Aggregate_Order_By>
  updatedAt?: InputMaybe<Order_By>
  users_aggregate?: InputMaybe<Users_Aggregate_Order_By>
  writersoftUsageReports_aggregate?: InputMaybe<WritersoftUsageReports_Aggregate_Order_By>
}

/** primary key columns input for table: apps */
export type Apps_Pk_Columns_Input = {
  id: Scalars['String']
}

/** select columns of table "apps" */
export enum Apps_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Host = 'host',
  /** column name */
  Id = 'id',
  /** column name */
  StripeWritersoftConfigurationId = 'stripeWritersoftConfigurationId',
  /** column name */
  StripeWritersoftLookupKey = 'stripeWritersoftLookupKey',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "apps" */
export type Apps_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>
  host?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  stripeWritersoftConfigurationId?: InputMaybe<Scalars['String']>
  stripeWritersoftLookupKey?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "apps" */
export type Apps_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Apps_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>
  host?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  stripeWritersoftConfigurationId?: InputMaybe<Scalars['String']>
  stripeWritersoftLookupKey?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "apps" */
export enum Apps_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Host = 'host',
  /** column name */
  Id = 'id',
  /** column name */
  StripeWritersoftConfigurationId = 'stripeWritersoftConfigurationId',
  /** column name */
  StripeWritersoftLookupKey = 'stripeWritersoftLookupKey',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type Apps_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Apps_Set_Input>
  /** filter the rows which have to be updated */
  where: Apps_Bool_Exp
}

export type AutoInput = {
  language: LanguageSlug
  text: Scalars['String']
  tone: ToneSlug
}

export type AutoKeywordsInput = {
  keywords: Array<Scalars['String']>
  language: LanguageSlug
  tone: ToneSlug
}

export type BlogSectionWritingInput = {
  format: Format
  keywords?: InputMaybe<Scalars['String']>
  language: LanguageSlug
  tone: ToneSlug
  topic: Scalars['String']
}

export type CfAuthorsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfAuthorsNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfAuthorsNestedFilter>>>
  blogIdeasRelatedCategoriesCollection_exists?: InputMaybe<Scalars['Boolean']>
  blogIdeasRelatedImagesCollection_exists?: InputMaybe<Scalars['Boolean']>
  blogIdeasRelatedTagsCollection_exists?: InputMaybe<Scalars['Boolean']>
  blogIdeas_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  blogIdeas_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  blogIdeas_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  blogIdeas_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  generatedBlogState?: InputMaybe<Scalars['String']>
  generatedBlogState_contains?: InputMaybe<Scalars['String']>
  generatedBlogState_exists?: InputMaybe<Scalars['Boolean']>
  generatedBlogState_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  generatedBlogState_not?: InputMaybe<Scalars['String']>
  generatedBlogState_not_contains?: InputMaybe<Scalars['String']>
  generatedBlogState_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  profileImage_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  useBlogAutoGenerator?: InputMaybe<Scalars['Boolean']>
  useBlogAutoGenerator_exists?: InputMaybe<Scalars['Boolean']>
  useBlogAutoGenerator_not?: InputMaybe<Scalars['Boolean']>
}

export type CfBlogsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfBlogsNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfBlogsNestedFilter>>>
  author_exists?: InputMaybe<Scalars['Boolean']>
  callToActionLink_exists?: InputMaybe<Scalars['Boolean']>
  content_contains?: InputMaybe<Scalars['String']>
  content_exists?: InputMaybe<Scalars['Boolean']>
  content_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  coverImage_exists?: InputMaybe<Scalars['Boolean']>
  previewImage_exists?: InputMaybe<Scalars['Boolean']>
  seoMetadataItemsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  tagsCollection_exists?: InputMaybe<Scalars['Boolean']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfCallToActionLinkNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCallToActionLinkNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfCallToActionLinkNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  link?: InputMaybe<Scalars['String']>
  link_contains?: InputMaybe<Scalars['String']>
  link_exists?: InputMaybe<Scalars['Boolean']>
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  link_not?: InputMaybe<Scalars['String']>
  link_not_contains?: InputMaybe<Scalars['String']>
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfCategoriesNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCategoriesNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfCategoriesNestedFilter>>>
  blogsCollection_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfErc721TokenMetadataAttributeTraitTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfErc721TokenMetadataAttributeTraitTypeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfErc721TokenMetadataAttributeTraitTypeNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfErc721TokenMetadataAttributesTextNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfErc721TokenMetadataAttributesTextNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfErc721TokenMetadataAttributesTextNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  displayType?: InputMaybe<Scalars['String']>
  displayType_contains?: InputMaybe<Scalars['String']>
  displayType_exists?: InputMaybe<Scalars['Boolean']>
  displayType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayType_not?: InputMaybe<Scalars['String']>
  displayType_not_contains?: InputMaybe<Scalars['String']>
  displayType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  traitType_exists?: InputMaybe<Scalars['Boolean']>
  value?: InputMaybe<Scalars['String']>
  value_contains?: InputMaybe<Scalars['String']>
  value_exists?: InputMaybe<Scalars['Boolean']>
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  value_not?: InputMaybe<Scalars['String']>
  value_not_contains?: InputMaybe<Scalars['String']>
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfErc721TokenMetadataNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfErc721TokenMetadataNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfErc721TokenMetadataNestedFilter>>>
  animationUrl_exists?: InputMaybe<Scalars['Boolean']>
  attributesCollection_exists?: InputMaybe<Scalars['Boolean']>
  backgroundColor?: InputMaybe<Scalars['String']>
  backgroundColor_contains?: InputMaybe<Scalars['String']>
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  backgroundColor_not?: InputMaybe<Scalars['String']>
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalUrl?: InputMaybe<Scalars['String']>
  externalUrl_contains?: InputMaybe<Scalars['String']>
  externalUrl_exists?: InputMaybe<Scalars['Boolean']>
  externalUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalUrl_not?: InputMaybe<Scalars['String']>
  externalUrl_not_contains?: InputMaybe<Scalars['String']>
  externalUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  image_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tokenId?: InputMaybe<Scalars['String']>
  tokenId_contains?: InputMaybe<Scalars['String']>
  tokenId_exists?: InputMaybe<Scalars['Boolean']>
  tokenId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  tokenId_not?: InputMaybe<Scalars['String']>
  tokenId_not_contains?: InputMaybe<Scalars['String']>
  tokenId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  youtubeUrl?: InputMaybe<Scalars['String']>
  youtubeUrl_contains?: InputMaybe<Scalars['String']>
  youtubeUrl_exists?: InputMaybe<Scalars['Boolean']>
  youtubeUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  youtubeUrl_not?: InputMaybe<Scalars['String']>
  youtubeUrl_not_contains?: InputMaybe<Scalars['String']>
  youtubeUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfPagesNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPagesNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfPagesNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  displayName?: InputMaybe<Scalars['String']>
  displayName_contains?: InputMaybe<Scalars['String']>
  displayName_exists?: InputMaybe<Scalars['Boolean']>
  displayName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayName_not?: InputMaybe<Scalars['String']>
  displayName_not_contains?: InputMaybe<Scalars['String']>
  displayName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  iconifyIconNameForMobileMenu?: InputMaybe<Scalars['String']>
  iconifyIconNameForMobileMenu_contains?: InputMaybe<Scalars['String']>
  iconifyIconNameForMobileMenu_exists?: InputMaybe<Scalars['Boolean']>
  iconifyIconNameForMobileMenu_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  iconifyIconNameForMobileMenu_not?: InputMaybe<Scalars['String']>
  iconifyIconNameForMobileMenu_not_contains?: InputMaybe<Scalars['String']>
  iconifyIconNameForMobileMenu_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sectionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  seoMetadataItemsCollection_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CfPromptEngineeringNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPromptEngineeringNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfPromptEngineeringNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  prompt?: InputMaybe<Scalars['String']>
  promptHint?: InputMaybe<Scalars['String']>
  promptHint_contains?: InputMaybe<Scalars['String']>
  promptHint_exists?: InputMaybe<Scalars['Boolean']>
  promptHint_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  promptHint_not?: InputMaybe<Scalars['String']>
  promptHint_not_contains?: InputMaybe<Scalars['String']>
  promptHint_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  prompt_contains?: InputMaybe<Scalars['String']>
  prompt_exists?: InputMaybe<Scalars['Boolean']>
  prompt_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  prompt_not?: InputMaybe<Scalars['String']>
  prompt_not_contains?: InputMaybe<Scalars['String']>
  prompt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  purpose?: InputMaybe<Scalars['String']>
  purpose_contains?: InputMaybe<Scalars['String']>
  purpose_exists?: InputMaybe<Scalars['Boolean']>
  purpose_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  purpose_not?: InputMaybe<Scalars['String']>
  purpose_not_contains?: InputMaybe<Scalars['String']>
  purpose_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teaser?: InputMaybe<Scalars['String']>
  teaser_contains?: InputMaybe<Scalars['String']>
  teaser_exists?: InputMaybe<Scalars['Boolean']>
  teaser_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  teaser_not?: InputMaybe<Scalars['String']>
  teaser_not_contains?: InputMaybe<Scalars['String']>
  teaser_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfSectionsDetailPersonComponentNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSectionsDetailPersonComponentNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfSectionsDetailPersonComponentNestedFilter>>>
  avatar_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  descriptionHref?: InputMaybe<Scalars['String']>
  descriptionHref_contains?: InputMaybe<Scalars['String']>
  descriptionHref_exists?: InputMaybe<Scalars['Boolean']>
  descriptionHref_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  descriptionHref_not?: InputMaybe<Scalars['String']>
  descriptionHref_not_contains?: InputMaybe<Scalars['String']>
  descriptionHref_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  detail_contains?: InputMaybe<Scalars['String']>
  detail_exists?: InputMaybe<Scalars['Boolean']>
  detail_not_contains?: InputMaybe<Scalars['String']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  titleHref?: InputMaybe<Scalars['String']>
  titleHref_contains?: InputMaybe<Scalars['String']>
  titleHref_exists?: InputMaybe<Scalars['Boolean']>
  titleHref_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  titleHref_not?: InputMaybe<Scalars['String']>
  titleHref_not_contains?: InputMaybe<Scalars['String']>
  titleHref_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfSectionsFaqComponentNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSectionsFaqComponentNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfSectionsFaqComponentNestedFilter>>>
  content_contains?: InputMaybe<Scalars['String']>
  content_exists?: InputMaybe<Scalars['Boolean']>
  content_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfSectionsFeaturesListComponentNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSectionsFeaturesListComponentNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfSectionsFeaturesListComponentNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  icon_exists?: InputMaybe<Scalars['Boolean']>
  iconifyIconName?: InputMaybe<Scalars['String']>
  iconifyIconName_contains?: InputMaybe<Scalars['String']>
  iconifyIconName_exists?: InputMaybe<Scalars['Boolean']>
  iconifyIconName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  iconifyIconName_not?: InputMaybe<Scalars['String']>
  iconifyIconName_not_contains?: InputMaybe<Scalars['String']>
  iconifyIconName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfSectionsFormComponentNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSectionsFormComponentNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfSectionsFormComponentNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  defaultChecked?: InputMaybe<Scalars['Boolean']>
  defaultChecked_exists?: InputMaybe<Scalars['Boolean']>
  defaultChecked_not?: InputMaybe<Scalars['Boolean']>
  defaultValue?: InputMaybe<Scalars['String']>
  defaultValue_contains?: InputMaybe<Scalars['String']>
  defaultValue_exists?: InputMaybe<Scalars['Boolean']>
  defaultValue_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  defaultValue_not?: InputMaybe<Scalars['String']>
  defaultValue_not_contains?: InputMaybe<Scalars['String']>
  defaultValue_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  disabled?: InputMaybe<Scalars['Boolean']>
  disabled_exists?: InputMaybe<Scalars['Boolean']>
  disabled_not?: InputMaybe<Scalars['Boolean']>
  fullWidth?: InputMaybe<Scalars['Boolean']>
  fullWidth_exists?: InputMaybe<Scalars['Boolean']>
  fullWidth_not?: InputMaybe<Scalars['Boolean']>
  helperText?: InputMaybe<Scalars['String']>
  helperText_contains?: InputMaybe<Scalars['String']>
  helperText_exists?: InputMaybe<Scalars['Boolean']>
  helperText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  helperText_not?: InputMaybe<Scalars['String']>
  helperText_not_contains?: InputMaybe<Scalars['String']>
  helperText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  label?: InputMaybe<Scalars['String']>
  label_contains?: InputMaybe<Scalars['String']>
  label_exists?: InputMaybe<Scalars['Boolean']>
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  label_not?: InputMaybe<Scalars['String']>
  label_not_contains?: InputMaybe<Scalars['String']>
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  max?: InputMaybe<Scalars['String']>
  maxLength?: InputMaybe<Scalars['Int']>
  maxLength_exists?: InputMaybe<Scalars['Boolean']>
  maxLength_gt?: InputMaybe<Scalars['Int']>
  maxLength_gte?: InputMaybe<Scalars['Int']>
  maxLength_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  maxLength_lt?: InputMaybe<Scalars['Int']>
  maxLength_lte?: InputMaybe<Scalars['Int']>
  maxLength_not?: InputMaybe<Scalars['Int']>
  maxLength_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  max_contains?: InputMaybe<Scalars['String']>
  max_exists?: InputMaybe<Scalars['Boolean']>
  max_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  max_not?: InputMaybe<Scalars['String']>
  max_not_contains?: InputMaybe<Scalars['String']>
  max_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  min?: InputMaybe<Scalars['String']>
  minLength?: InputMaybe<Scalars['Int']>
  minLength_exists?: InputMaybe<Scalars['Boolean']>
  minLength_gt?: InputMaybe<Scalars['Int']>
  minLength_gte?: InputMaybe<Scalars['Int']>
  minLength_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  minLength_lt?: InputMaybe<Scalars['Int']>
  minLength_lte?: InputMaybe<Scalars['Int']>
  minLength_not?: InputMaybe<Scalars['Int']>
  minLength_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  min_contains?: InputMaybe<Scalars['String']>
  min_exists?: InputMaybe<Scalars['Boolean']>
  min_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  min_not?: InputMaybe<Scalars['String']>
  min_not_contains?: InputMaybe<Scalars['String']>
  min_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  multiple?: InputMaybe<Scalars['Boolean']>
  multiple_exists?: InputMaybe<Scalars['Boolean']>
  multiple_not?: InputMaybe<Scalars['Boolean']>
  options_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  options_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  options_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  options_exists?: InputMaybe<Scalars['Boolean']>
  pattern?: InputMaybe<Scalars['String']>
  pattern_contains?: InputMaybe<Scalars['String']>
  pattern_exists?: InputMaybe<Scalars['Boolean']>
  pattern_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  pattern_not?: InputMaybe<Scalars['String']>
  pattern_not_contains?: InputMaybe<Scalars['String']>
  pattern_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  placeholder?: InputMaybe<Scalars['String']>
  placeholder_contains?: InputMaybe<Scalars['String']>
  placeholder_exists?: InputMaybe<Scalars['Boolean']>
  placeholder_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  placeholder_not?: InputMaybe<Scalars['String']>
  placeholder_not_contains?: InputMaybe<Scalars['String']>
  placeholder_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  readOnly?: InputMaybe<Scalars['Boolean']>
  readOnly_exists?: InputMaybe<Scalars['Boolean']>
  readOnly_not?: InputMaybe<Scalars['Boolean']>
  required?: InputMaybe<Scalars['Boolean']>
  required_exists?: InputMaybe<Scalars['Boolean']>
  required_not?: InputMaybe<Scalars['Boolean']>
  size?: InputMaybe<Scalars['String']>
  size_contains?: InputMaybe<Scalars['String']>
  size_exists?: InputMaybe<Scalars['Boolean']>
  size_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  size_not?: InputMaybe<Scalars['String']>
  size_not_contains?: InputMaybe<Scalars['String']>
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  step?: InputMaybe<Scalars['Int']>
  step_exists?: InputMaybe<Scalars['Boolean']>
  step_gt?: InputMaybe<Scalars['Int']>
  step_gte?: InputMaybe<Scalars['Int']>
  step_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  step_lt?: InputMaybe<Scalars['Int']>
  step_lte?: InputMaybe<Scalars['Int']>
  step_not?: InputMaybe<Scalars['Int']>
  step_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  sys?: InputMaybe<SysFilter>
  type?: InputMaybe<Scalars['String']>
  type_contains?: InputMaybe<Scalars['String']>
  type_exists?: InputMaybe<Scalars['Boolean']>
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type_not?: InputMaybe<Scalars['String']>
  type_not_contains?: InputMaybe<Scalars['String']>
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfSectionsImageNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSectionsImageNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfSectionsImageNestedFilter>>>
  callToActionHref?: InputMaybe<Scalars['String']>
  callToActionHref_contains?: InputMaybe<Scalars['String']>
  callToActionHref_exists?: InputMaybe<Scalars['Boolean']>
  callToActionHref_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionHref_not?: InputMaybe<Scalars['String']>
  callToActionHref_not_contains?: InputMaybe<Scalars['String']>
  callToActionHref_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  image_exists?: InputMaybe<Scalars['Boolean']>
  mobileImage_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
}

export type CfSectionsMinimalsContactComponentNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSectionsMinimalsContactComponentNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfSectionsMinimalsContactComponentNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  href?: InputMaybe<Scalars['String']>
  href_contains?: InputMaybe<Scalars['String']>
  href_exists?: InputMaybe<Scalars['Boolean']>
  href_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  href_not?: InputMaybe<Scalars['String']>
  href_not_contains?: InputMaybe<Scalars['String']>
  href_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfSectionsMinimalsHistoryComponentNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSectionsMinimalsHistoryComponentNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfSectionsMinimalsHistoryComponentNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayTime?: InputMaybe<Scalars['DateTime']>
  displayTime_exists?: InputMaybe<Scalars['Boolean']>
  displayTime_gt?: InputMaybe<Scalars['DateTime']>
  displayTime_gte?: InputMaybe<Scalars['DateTime']>
  displayTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  displayTime_lt?: InputMaybe<Scalars['DateTime']>
  displayTime_lte?: InputMaybe<Scalars['DateTime']>
  displayTime_not?: InputMaybe<Scalars['DateTime']>
  displayTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  featureImage_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfSectionsMinimalsHumanComponentNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSectionsMinimalsHumanComponentNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfSectionsMinimalsHumanComponentNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  descriptionHref?: InputMaybe<Scalars['String']>
  descriptionHref_contains?: InputMaybe<Scalars['String']>
  descriptionHref_exists?: InputMaybe<Scalars['Boolean']>
  descriptionHref_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  descriptionHref_not?: InputMaybe<Scalars['String']>
  descriptionHref_not_contains?: InputMaybe<Scalars['String']>
  descriptionHref_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  featureImage_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfSectionsMinimalsImagesListNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSectionsMinimalsImagesListNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfSectionsMinimalsImagesListNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayMode?: InputMaybe<Scalars['String']>
  displayMode_contains?: InputMaybe<Scalars['String']>
  displayMode_exists?: InputMaybe<Scalars['Boolean']>
  displayMode_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayMode_not?: InputMaybe<Scalars['String']>
  displayMode_not_contains?: InputMaybe<Scalars['String']>
  displayMode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  gridModeColumnsCountDesktop?: InputMaybe<Scalars['Int']>
  gridModeColumnsCountDesktop_exists?: InputMaybe<Scalars['Boolean']>
  gridModeColumnsCountDesktop_gt?: InputMaybe<Scalars['Int']>
  gridModeColumnsCountDesktop_gte?: InputMaybe<Scalars['Int']>
  gridModeColumnsCountDesktop_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  gridModeColumnsCountDesktop_lt?: InputMaybe<Scalars['Int']>
  gridModeColumnsCountDesktop_lte?: InputMaybe<Scalars['Int']>
  gridModeColumnsCountDesktop_not?: InputMaybe<Scalars['Int']>
  gridModeColumnsCountDesktop_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  gridModeColumnsCountMobile?: InputMaybe<Scalars['Int']>
  gridModeColumnsCountMobile_exists?: InputMaybe<Scalars['Boolean']>
  gridModeColumnsCountMobile_gt?: InputMaybe<Scalars['Int']>
  gridModeColumnsCountMobile_gte?: InputMaybe<Scalars['Int']>
  gridModeColumnsCountMobile_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  gridModeColumnsCountMobile_lt?: InputMaybe<Scalars['Int']>
  gridModeColumnsCountMobile_lte?: InputMaybe<Scalars['Int']>
  gridModeColumnsCountMobile_not?: InputMaybe<Scalars['Int']>
  gridModeColumnsCountMobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  gridModeGapDesktop?: InputMaybe<Scalars['Int']>
  gridModeGapDesktop_exists?: InputMaybe<Scalars['Boolean']>
  gridModeGapDesktop_gt?: InputMaybe<Scalars['Int']>
  gridModeGapDesktop_gte?: InputMaybe<Scalars['Int']>
  gridModeGapDesktop_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  gridModeGapDesktop_lt?: InputMaybe<Scalars['Int']>
  gridModeGapDesktop_lte?: InputMaybe<Scalars['Int']>
  gridModeGapDesktop_not?: InputMaybe<Scalars['Int']>
  gridModeGapDesktop_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  gridModeGapMobile?: InputMaybe<Scalars['Int']>
  gridModeGapMobile_exists?: InputMaybe<Scalars['Boolean']>
  gridModeGapMobile_gt?: InputMaybe<Scalars['Int']>
  gridModeGapMobile_gte?: InputMaybe<Scalars['Int']>
  gridModeGapMobile_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  gridModeGapMobile_lt?: InputMaybe<Scalars['Int']>
  gridModeGapMobile_lte?: InputMaybe<Scalars['Int']>
  gridModeGapMobile_not?: InputMaybe<Scalars['Int']>
  gridModeGapMobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  imagesCollection_exists?: InputMaybe<Scalars['Boolean']>
  imagesMobileCollection_exists?: InputMaybe<Scalars['Boolean']>
  imagesWithHrefDesktopCollection_exists?: InputMaybe<Scalars['Boolean']>
  imagesWithHrefMobileCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfSectionsMinimalsServiceNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSectionsMinimalsServiceNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfSectionsMinimalsServiceNestedFilter>>>
  callToActionHref?: InputMaybe<Scalars['String']>
  callToActionHref_contains?: InputMaybe<Scalars['String']>
  callToActionHref_exists?: InputMaybe<Scalars['Boolean']>
  callToActionHref_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionHref_not?: InputMaybe<Scalars['String']>
  callToActionHref_not_contains?: InputMaybe<Scalars['String']>
  callToActionHref_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionText?: InputMaybe<Scalars['String']>
  callToActionText_contains?: InputMaybe<Scalars['String']>
  callToActionText_exists?: InputMaybe<Scalars['Boolean']>
  callToActionText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  callToActionText_not?: InputMaybe<Scalars['String']>
  callToActionText_not_contains?: InputMaybe<Scalars['String']>
  callToActionText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  services_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  services_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  services_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  services_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfSectionsMintListComponentNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSectionsMintListComponentNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfSectionsMintListComponentNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  dependencySmartContractErc721_exists?: InputMaybe<Scalars['Boolean']>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  isDisplay?: InputMaybe<Scalars['Boolean']>
  isDisplay_exists?: InputMaybe<Scalars['Boolean']>
  isDisplay_not?: InputMaybe<Scalars['Boolean']>
  price?: InputMaybe<Scalars['Float']>
  price_exists?: InputMaybe<Scalars['Boolean']>
  price_gt?: InputMaybe<Scalars['Float']>
  price_gte?: InputMaybe<Scalars['Float']>
  price_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  price_lt?: InputMaybe<Scalars['Float']>
  price_lte?: InputMaybe<Scalars['Float']>
  price_not?: InputMaybe<Scalars['Float']>
  price_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  smartContractCallToActionName?: InputMaybe<Scalars['String']>
  smartContractCallToActionName_contains?: InputMaybe<Scalars['String']>
  smartContractCallToActionName_exists?: InputMaybe<Scalars['Boolean']>
  smartContractCallToActionName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  smartContractCallToActionName_not?: InputMaybe<Scalars['String']>
  smartContractCallToActionName_not_contains?: InputMaybe<Scalars['String']>
  smartContractCallToActionName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  smartContractErc721_exists?: InputMaybe<Scalars['Boolean']>
  style?: InputMaybe<Scalars['String']>
  style_contains?: InputMaybe<Scalars['String']>
  style_exists?: InputMaybe<Scalars['Boolean']>
  style_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  style_not?: InputMaybe<Scalars['String']>
  style_not_contains?: InputMaybe<Scalars['String']>
  style_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfSeoMetadataNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSeoMetadataNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfSeoMetadataNestedFilter>>>
  content?: InputMaybe<Scalars['String']>
  content_contains?: InputMaybe<Scalars['String']>
  content_exists?: InputMaybe<Scalars['Boolean']>
  content_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  content_not?: InputMaybe<Scalars['String']>
  content_not_contains?: InputMaybe<Scalars['String']>
  content_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CfSmartContractErc721NestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSmartContractErc721NestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfSmartContractErc721NestedFilter>>>
  abi_exists?: InputMaybe<Scalars['Boolean']>
  bannerImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  displayImage_exists?: InputMaybe<Scalars['Boolean']>
  featureImage_exists?: InputMaybe<Scalars['Boolean']>
  isSingleTokenMetadataMode?: InputMaybe<Scalars['Boolean']>
  isSingleTokenMetadataMode_exists?: InputMaybe<Scalars['Boolean']>
  isSingleTokenMetadataMode_not?: InputMaybe<Scalars['Boolean']>
  logo_exists?: InputMaybe<Scalars['Boolean']>
  network?: InputMaybe<Scalars['String']>
  network_contains?: InputMaybe<Scalars['String']>
  network_exists?: InputMaybe<Scalars['Boolean']>
  network_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  network_not?: InputMaybe<Scalars['String']>
  network_not_contains?: InputMaybe<Scalars['String']>
  network_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  singleToken_exists?: InputMaybe<Scalars['Boolean']>
  smartContractAddress?: InputMaybe<Scalars['String']>
  smartContractAddress_contains?: InputMaybe<Scalars['String']>
  smartContractAddress_exists?: InputMaybe<Scalars['Boolean']>
  smartContractAddress_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  smartContractAddress_not?: InputMaybe<Scalars['String']>
  smartContractAddress_not_contains?: InputMaybe<Scalars['String']>
  smartContractAddress_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  smartContractGist?: InputMaybe<Scalars['String']>
  smartContractGist_contains?: InputMaybe<Scalars['String']>
  smartContractGist_exists?: InputMaybe<Scalars['Boolean']>
  smartContractGist_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  smartContractGist_not?: InputMaybe<Scalars['String']>
  smartContractGist_not_contains?: InputMaybe<Scalars['String']>
  smartContractGist_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tokensCollection_exists?: InputMaybe<Scalars['Boolean']>
  whitelistAddressesCollection_exists?: InputMaybe<Scalars['Boolean']>
}

export type CfSmartContractErc721TokenAttributeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSmartContractErc721TokenAttributeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfSmartContractErc721TokenAttributeNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  displayType?: InputMaybe<Scalars['String']>
  displayType_contains?: InputMaybe<Scalars['String']>
  displayType_exists?: InputMaybe<Scalars['Boolean']>
  displayType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayType_not?: InputMaybe<Scalars['String']>
  displayType_not_contains?: InputMaybe<Scalars['String']>
  displayType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  traitType?: InputMaybe<Scalars['String']>
  traitType_contains?: InputMaybe<Scalars['String']>
  traitType_exists?: InputMaybe<Scalars['Boolean']>
  traitType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  traitType_not?: InputMaybe<Scalars['String']>
  traitType_not_contains?: InputMaybe<Scalars['String']>
  traitType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  value?: InputMaybe<Scalars['String']>
  value_contains?: InputMaybe<Scalars['String']>
  value_exists?: InputMaybe<Scalars['Boolean']>
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  value_not?: InputMaybe<Scalars['String']>
  value_not_contains?: InputMaybe<Scalars['String']>
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfSmartContractErc721WhitelistAddressNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSmartContractErc721WhitelistAddressNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfSmartContractErc721WhitelistAddressNestedFilter>>>
  alreadyMinted?: InputMaybe<Scalars['Boolean']>
  alreadyMinted_exists?: InputMaybe<Scalars['Boolean']>
  alreadyMinted_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ethAddress?: InputMaybe<Scalars['String']>
  ethAddress_contains?: InputMaybe<Scalars['String']>
  ethAddress_exists?: InputMaybe<Scalars['Boolean']>
  ethAddress_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ethAddress_not?: InputMaybe<Scalars['String']>
  ethAddress_not_contains?: InputMaybe<Scalars['String']>
  ethAddress_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CfSocialMediaLinkNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSocialMediaLinkNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfSocialMediaLinkNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  isExternal?: InputMaybe<Scalars['Boolean']>
  isExternal_exists?: InputMaybe<Scalars['Boolean']>
  isExternal_not?: InputMaybe<Scalars['Boolean']>
  link?: InputMaybe<Scalars['String']>
  link_contains?: InputMaybe<Scalars['String']>
  link_exists?: InputMaybe<Scalars['Boolean']>
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  link_not?: InputMaybe<Scalars['String']>
  link_not_contains?: InputMaybe<Scalars['String']>
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  overwriteDisplayIconWithIconifyIcon?: InputMaybe<Scalars['String']>
  overwriteDisplayIconWithIconifyIcon_contains?: InputMaybe<Scalars['String']>
  overwriteDisplayIconWithIconifyIcon_exists?: InputMaybe<Scalars['Boolean']>
  overwriteDisplayIconWithIconifyIcon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  overwriteDisplayIconWithIconifyIcon_not?: InputMaybe<Scalars['String']>
  overwriteDisplayIconWithIconifyIcon_not_contains?: InputMaybe<Scalars['String']>
  overwriteDisplayIconWithIconifyIcon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type?: InputMaybe<Scalars['String']>
  type_contains?: InputMaybe<Scalars['String']>
  type_exists?: InputMaybe<Scalars['Boolean']>
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type_not?: InputMaybe<Scalars['String']>
  type_not_contains?: InputMaybe<Scalars['String']>
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfTagsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfTagsNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfTagsNestedFilter>>>
  content_contains?: InputMaybe<Scalars['String']>
  content_exists?: InputMaybe<Scalars['Boolean']>
  content_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  groupName?: InputMaybe<Scalars['String']>
  groupName_contains?: InputMaybe<Scalars['String']>
  groupName_exists?: InputMaybe<Scalars['Boolean']>
  groupName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  groupName_not?: InputMaybe<Scalars['String']>
  groupName_not_contains?: InputMaybe<Scalars['String']>
  groupName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  seoMetadataItemsCollection_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  typeForSearch_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  typeForSearch_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  typeForSearch_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  typeForSearch_exists?: InputMaybe<Scalars['Boolean']>
}

export type CfsectionsMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfsectionsMultiTypeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfsectionsMultiTypeNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
}

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** columns and relationships of "discordMessages" */
export type DiscordMessages = {
  __typename?: 'discordMessages'
  attachments?: Maybe<Scalars['jsonb']>
  createdAt: Scalars['timestamptz']
  event?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  message?: Maybe<Scalars['jsonb']>
  updatedAt: Scalars['timestamptz']
}

/** columns and relationships of "discordMessages" */
export type DiscordMessagesAttachmentsArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "discordMessages" */
export type DiscordMessagesMessageArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "discordMessages" */
export type DiscordMessages_Aggregate = {
  __typename?: 'discordMessages_aggregate'
  aggregate?: Maybe<DiscordMessages_Aggregate_Fields>
  nodes: Array<DiscordMessages>
}

/** aggregate fields of "discordMessages" */
export type DiscordMessages_Aggregate_Fields = {
  __typename?: 'discordMessages_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<DiscordMessages_Max_Fields>
  min?: Maybe<DiscordMessages_Min_Fields>
}

/** aggregate fields of "discordMessages" */
export type DiscordMessages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<DiscordMessages_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type DiscordMessages_Append_Input = {
  attachments?: InputMaybe<Scalars['jsonb']>
  message?: InputMaybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "discordMessages". All fields are combined with a logical 'AND'. */
export type DiscordMessages_Bool_Exp = {
  _and?: InputMaybe<Array<DiscordMessages_Bool_Exp>>
  _not?: InputMaybe<DiscordMessages_Bool_Exp>
  _or?: InputMaybe<Array<DiscordMessages_Bool_Exp>>
  attachments?: InputMaybe<Jsonb_Comparison_Exp>
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>
  event?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  message?: InputMaybe<Jsonb_Comparison_Exp>
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "discordMessages" */
export enum DiscordMessages_Constraint {
  /** unique or primary key constraint on columns "id" */
  DiscordMessagesPkey = 'discordMessages_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type DiscordMessages_Delete_At_Path_Input = {
  attachments?: InputMaybe<Array<Scalars['String']>>
  message?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type DiscordMessages_Delete_Elem_Input = {
  attachments?: InputMaybe<Scalars['Int']>
  message?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type DiscordMessages_Delete_Key_Input = {
  attachments?: InputMaybe<Scalars['String']>
  message?: InputMaybe<Scalars['String']>
}

/** input type for inserting data into table "discordMessages" */
export type DiscordMessages_Insert_Input = {
  attachments?: InputMaybe<Scalars['jsonb']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  event?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  message?: InputMaybe<Scalars['jsonb']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type DiscordMessages_Max_Fields = {
  __typename?: 'discordMessages_max_fields'
  createdAt?: Maybe<Scalars['timestamptz']>
  event?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type DiscordMessages_Min_Fields = {
  __typename?: 'discordMessages_min_fields'
  createdAt?: Maybe<Scalars['timestamptz']>
  event?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "discordMessages" */
export type DiscordMessages_Mutation_Response = {
  __typename?: 'discordMessages_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<DiscordMessages>
}

/** on_conflict condition type for table "discordMessages" */
export type DiscordMessages_On_Conflict = {
  constraint: DiscordMessages_Constraint
  update_columns?: Array<DiscordMessages_Update_Column>
  where?: InputMaybe<DiscordMessages_Bool_Exp>
}

/** Ordering options when selecting data from "discordMessages". */
export type DiscordMessages_Order_By = {
  attachments?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  event?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  message?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** primary key columns input for table: discordMessages */
export type DiscordMessages_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type DiscordMessages_Prepend_Input = {
  attachments?: InputMaybe<Scalars['jsonb']>
  message?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "discordMessages" */
export enum DiscordMessages_Select_Column {
  /** column name */
  Attachments = 'attachments',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Event = 'event',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "discordMessages" */
export type DiscordMessages_Set_Input = {
  attachments?: InputMaybe<Scalars['jsonb']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  event?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  message?: InputMaybe<Scalars['jsonb']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "discordMessages" */
export type DiscordMessages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: DiscordMessages_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type DiscordMessages_Stream_Cursor_Value_Input = {
  attachments?: InputMaybe<Scalars['jsonb']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  event?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  message?: InputMaybe<Scalars['jsonb']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "discordMessages" */
export enum DiscordMessages_Update_Column {
  /** column name */
  Attachments = 'attachments',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Event = 'event',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type DiscordMessages_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<DiscordMessages_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<DiscordMessages_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<DiscordMessages_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<DiscordMessages_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<DiscordMessages_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DiscordMessages_Set_Input>
  /** filter the rows which have to be updated */
  where: DiscordMessages_Bool_Exp
}

/** columns and relationships of "formAnswers" */
export type FormAnswers = {
  __typename?: 'formAnswers'
  /** An object relationship */
  app: Apps
  appId: Scalars['String']
  contentfulFormComponentId: Scalars['String']
  createdAt: Scalars['timestamptz']
  /** An object relationship */
  form: Forms
  formId: Scalars['uuid']
  id: Scalars['uuid']
  type: Scalars['String']
  updatedAt: Scalars['timestamptz']
  value: Scalars['String']
}

/** aggregated selection of "formAnswers" */
export type FormAnswers_Aggregate = {
  __typename?: 'formAnswers_aggregate'
  aggregate?: Maybe<FormAnswers_Aggregate_Fields>
  nodes: Array<FormAnswers>
}

export type FormAnswers_Aggregate_Bool_Exp = {
  count?: InputMaybe<FormAnswers_Aggregate_Bool_Exp_Count>
}

export type FormAnswers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<FormAnswers_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<FormAnswers_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "formAnswers" */
export type FormAnswers_Aggregate_Fields = {
  __typename?: 'formAnswers_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<FormAnswers_Max_Fields>
  min?: Maybe<FormAnswers_Min_Fields>
}

/** aggregate fields of "formAnswers" */
export type FormAnswers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<FormAnswers_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "formAnswers" */
export type FormAnswers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<FormAnswers_Max_Order_By>
  min?: InputMaybe<FormAnswers_Min_Order_By>
}

/** input type for inserting array relation for remote table "formAnswers" */
export type FormAnswers_Arr_Rel_Insert_Input = {
  data: Array<FormAnswers_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<FormAnswers_On_Conflict>
}

/** Boolean expression to filter rows from the table "formAnswers". All fields are combined with a logical 'AND'. */
export type FormAnswers_Bool_Exp = {
  _and?: InputMaybe<Array<FormAnswers_Bool_Exp>>
  _not?: InputMaybe<FormAnswers_Bool_Exp>
  _or?: InputMaybe<Array<FormAnswers_Bool_Exp>>
  app?: InputMaybe<Apps_Bool_Exp>
  appId?: InputMaybe<String_Comparison_Exp>
  contentfulFormComponentId?: InputMaybe<String_Comparison_Exp>
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>
  form?: InputMaybe<Forms_Bool_Exp>
  formId?: InputMaybe<Uuid_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  type?: InputMaybe<String_Comparison_Exp>
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "formAnswers" */
export enum FormAnswers_Constraint {
  /** unique or primary key constraint on columns "id" */
  FormAnswersPkey = 'formAnswers_pkey',
}

/** input type for inserting data into table "formAnswers" */
export type FormAnswers_Insert_Input = {
  app?: InputMaybe<Apps_Obj_Rel_Insert_Input>
  appId?: InputMaybe<Scalars['String']>
  contentfulFormComponentId?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  form?: InputMaybe<Forms_Obj_Rel_Insert_Input>
  formId?: InputMaybe<Scalars['uuid']>
  id?: InputMaybe<Scalars['uuid']>
  type?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  value?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type FormAnswers_Max_Fields = {
  __typename?: 'formAnswers_max_fields'
  appId?: Maybe<Scalars['String']>
  contentfulFormComponentId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  formId?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  type?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
  value?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "formAnswers" */
export type FormAnswers_Max_Order_By = {
  appId?: InputMaybe<Order_By>
  contentfulFormComponentId?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  formId?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type FormAnswers_Min_Fields = {
  __typename?: 'formAnswers_min_fields'
  appId?: Maybe<Scalars['String']>
  contentfulFormComponentId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  formId?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  type?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
  value?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "formAnswers" */
export type FormAnswers_Min_Order_By = {
  appId?: InputMaybe<Order_By>
  contentfulFormComponentId?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  formId?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** response of any mutation on the table "formAnswers" */
export type FormAnswers_Mutation_Response = {
  __typename?: 'formAnswers_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<FormAnswers>
}

/** on_conflict condition type for table "formAnswers" */
export type FormAnswers_On_Conflict = {
  constraint: FormAnswers_Constraint
  update_columns?: Array<FormAnswers_Update_Column>
  where?: InputMaybe<FormAnswers_Bool_Exp>
}

/** Ordering options when selecting data from "formAnswers". */
export type FormAnswers_Order_By = {
  app?: InputMaybe<Apps_Order_By>
  appId?: InputMaybe<Order_By>
  contentfulFormComponentId?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  form?: InputMaybe<Forms_Order_By>
  formId?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: formAnswers */
export type FormAnswers_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "formAnswers" */
export enum FormAnswers_Select_Column {
  /** column name */
  AppId = 'appId',
  /** column name */
  ContentfulFormComponentId = 'contentfulFormComponentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FormId = 'formId',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "formAnswers" */
export type FormAnswers_Set_Input = {
  appId?: InputMaybe<Scalars['String']>
  contentfulFormComponentId?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  formId?: InputMaybe<Scalars['uuid']>
  id?: InputMaybe<Scalars['uuid']>
  type?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  value?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "formAnswers" */
export type FormAnswers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: FormAnswers_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type FormAnswers_Stream_Cursor_Value_Input = {
  appId?: InputMaybe<Scalars['String']>
  contentfulFormComponentId?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  formId?: InputMaybe<Scalars['uuid']>
  id?: InputMaybe<Scalars['uuid']>
  type?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  value?: InputMaybe<Scalars['String']>
}

/** update columns of table "formAnswers" */
export enum FormAnswers_Update_Column {
  /** column name */
  AppId = 'appId',
  /** column name */
  ContentfulFormComponentId = 'contentfulFormComponentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FormId = 'formId',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value',
}

export type FormAnswers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FormAnswers_Set_Input>
  /** filter the rows which have to be updated */
  where: FormAnswers_Bool_Exp
}

/** columns and relationships of "forms" */
export type Forms = {
  __typename?: 'forms'
  /** An object relationship */
  app: Apps
  appId: Scalars['String']
  contentfulFormId: Scalars['String']
  createdAt: Scalars['timestamptz']
  /** An array relationship */
  formAnswers: Array<FormAnswers>
  /** An aggregate relationship */
  formAnswers_aggregate: FormAnswers_Aggregate
  id: Scalars['uuid']
  updatedAt: Scalars['timestamptz']
  /** An object relationship */
  user?: Maybe<Users>
  userId?: Maybe<Scalars['uuid']>
}

/** columns and relationships of "forms" */
export type FormsFormAnswersArgs = {
  distinct_on?: InputMaybe<Array<FormAnswers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<FormAnswers_Order_By>>
  where?: InputMaybe<FormAnswers_Bool_Exp>
}

/** columns and relationships of "forms" */
export type FormsFormAnswers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FormAnswers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<FormAnswers_Order_By>>
  where?: InputMaybe<FormAnswers_Bool_Exp>
}

/** aggregated selection of "forms" */
export type Forms_Aggregate = {
  __typename?: 'forms_aggregate'
  aggregate?: Maybe<Forms_Aggregate_Fields>
  nodes: Array<Forms>
}

export type Forms_Aggregate_Bool_Exp = {
  count?: InputMaybe<Forms_Aggregate_Bool_Exp_Count>
}

export type Forms_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Forms_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Forms_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "forms" */
export type Forms_Aggregate_Fields = {
  __typename?: 'forms_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Forms_Max_Fields>
  min?: Maybe<Forms_Min_Fields>
}

/** aggregate fields of "forms" */
export type Forms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Forms_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "forms" */
export type Forms_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Forms_Max_Order_By>
  min?: InputMaybe<Forms_Min_Order_By>
}

/** input type for inserting array relation for remote table "forms" */
export type Forms_Arr_Rel_Insert_Input = {
  data: Array<Forms_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Forms_On_Conflict>
}

/** Boolean expression to filter rows from the table "forms". All fields are combined with a logical 'AND'. */
export type Forms_Bool_Exp = {
  _and?: InputMaybe<Array<Forms_Bool_Exp>>
  _not?: InputMaybe<Forms_Bool_Exp>
  _or?: InputMaybe<Array<Forms_Bool_Exp>>
  app?: InputMaybe<Apps_Bool_Exp>
  appId?: InputMaybe<String_Comparison_Exp>
  contentfulFormId?: InputMaybe<String_Comparison_Exp>
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>
  formAnswers?: InputMaybe<FormAnswers_Bool_Exp>
  formAnswers_aggregate?: InputMaybe<FormAnswers_Aggregate_Bool_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>
  user?: InputMaybe<Users_Bool_Exp>
  userId?: InputMaybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "forms" */
export enum Forms_Constraint {
  /** unique or primary key constraint on columns "id" */
  FormsPkey = 'forms_pkey',
}

/** input type for inserting data into table "forms" */
export type Forms_Insert_Input = {
  app?: InputMaybe<Apps_Obj_Rel_Insert_Input>
  appId?: InputMaybe<Scalars['String']>
  contentfulFormId?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  formAnswers?: InputMaybe<FormAnswers_Arr_Rel_Insert_Input>
  id?: InputMaybe<Scalars['uuid']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>
  userId?: InputMaybe<Scalars['uuid']>
}

/** aggregate max on columns */
export type Forms_Max_Fields = {
  __typename?: 'forms_max_fields'
  appId?: Maybe<Scalars['String']>
  contentfulFormId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updatedAt?: Maybe<Scalars['timestamptz']>
  userId?: Maybe<Scalars['uuid']>
}

/** order by max() on columns of table "forms" */
export type Forms_Max_Order_By = {
  appId?: InputMaybe<Order_By>
  contentfulFormId?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
  userId?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Forms_Min_Fields = {
  __typename?: 'forms_min_fields'
  appId?: Maybe<Scalars['String']>
  contentfulFormId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updatedAt?: Maybe<Scalars['timestamptz']>
  userId?: Maybe<Scalars['uuid']>
}

/** order by min() on columns of table "forms" */
export type Forms_Min_Order_By = {
  appId?: InputMaybe<Order_By>
  contentfulFormId?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
  userId?: InputMaybe<Order_By>
}

/** response of any mutation on the table "forms" */
export type Forms_Mutation_Response = {
  __typename?: 'forms_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Forms>
}

/** input type for inserting object relation for remote table "forms" */
export type Forms_Obj_Rel_Insert_Input = {
  data: Forms_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Forms_On_Conflict>
}

/** on_conflict condition type for table "forms" */
export type Forms_On_Conflict = {
  constraint: Forms_Constraint
  update_columns?: Array<Forms_Update_Column>
  where?: InputMaybe<Forms_Bool_Exp>
}

/** Ordering options when selecting data from "forms". */
export type Forms_Order_By = {
  app?: InputMaybe<Apps_Order_By>
  appId?: InputMaybe<Order_By>
  contentfulFormId?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  formAnswers_aggregate?: InputMaybe<FormAnswers_Aggregate_Order_By>
  id?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
  user?: InputMaybe<Users_Order_By>
  userId?: InputMaybe<Order_By>
}

/** primary key columns input for table: forms */
export type Forms_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "forms" */
export enum Forms_Select_Column {
  /** column name */
  AppId = 'appId',
  /** column name */
  ContentfulFormId = 'contentfulFormId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "forms" */
export type Forms_Set_Input = {
  appId?: InputMaybe<Scalars['String']>
  contentfulFormId?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  userId?: InputMaybe<Scalars['uuid']>
}

/** Streaming cursor of the table "forms" */
export type Forms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Forms_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Forms_Stream_Cursor_Value_Input = {
  appId?: InputMaybe<Scalars['String']>
  contentfulFormId?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  userId?: InputMaybe<Scalars['uuid']>
}

/** update columns of table "forms" */
export enum Forms_Update_Column {
  /** column name */
  AppId = 'appId',
  /** column name */
  ContentfulFormId = 'contentfulFormId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

export type Forms_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Forms_Set_Input>
  /** filter the rows which have to be updated */
  where: Forms_Bool_Exp
}

/** columns and relationships of "gptHeroAccounts" */
export type GptHeroAccounts = {
  __typename?: 'gptHeroAccounts'
  createdAt: Scalars['timestamptz']
  email?: Maybe<Scalars['String']>
  /** An array relationship */
  gptHeroPromptUsages: Array<GptHeroPromptUsages>
  /** An aggregate relationship */
  gptHeroPromptUsages_aggregate: GptHeroPromptUsages_Aggregate
  /** An array relationship */
  gptHeroPrompts: Array<GptHeroPrompts>
  /** An aggregate relationship */
  gptHeroPrompts_aggregate: GptHeroPrompts_Aggregate
  id: Scalars['uuid']
  identity: Scalars['String']
  name?: Maybe<Scalars['String']>
  updatedAt: Scalars['timestamptz']
  website?: Maybe<Scalars['String']>
}

/** columns and relationships of "gptHeroAccounts" */
export type GptHeroAccountsGptHeroPromptUsagesArgs = {
  distinct_on?: InputMaybe<Array<GptHeroPromptUsages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroPromptUsages_Order_By>>
  where?: InputMaybe<GptHeroPromptUsages_Bool_Exp>
}

/** columns and relationships of "gptHeroAccounts" */
export type GptHeroAccountsGptHeroPromptUsages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GptHeroPromptUsages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroPromptUsages_Order_By>>
  where?: InputMaybe<GptHeroPromptUsages_Bool_Exp>
}

/** columns and relationships of "gptHeroAccounts" */
export type GptHeroAccountsGptHeroPromptsArgs = {
  distinct_on?: InputMaybe<Array<GptHeroPrompts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroPrompts_Order_By>>
  where?: InputMaybe<GptHeroPrompts_Bool_Exp>
}

/** columns and relationships of "gptHeroAccounts" */
export type GptHeroAccountsGptHeroPrompts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GptHeroPrompts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroPrompts_Order_By>>
  where?: InputMaybe<GptHeroPrompts_Bool_Exp>
}

/** aggregated selection of "gptHeroAccounts" */
export type GptHeroAccounts_Aggregate = {
  __typename?: 'gptHeroAccounts_aggregate'
  aggregate?: Maybe<GptHeroAccounts_Aggregate_Fields>
  nodes: Array<GptHeroAccounts>
}

/** aggregate fields of "gptHeroAccounts" */
export type GptHeroAccounts_Aggregate_Fields = {
  __typename?: 'gptHeroAccounts_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<GptHeroAccounts_Max_Fields>
  min?: Maybe<GptHeroAccounts_Min_Fields>
}

/** aggregate fields of "gptHeroAccounts" */
export type GptHeroAccounts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GptHeroAccounts_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "gptHeroAccounts". All fields are combined with a logical 'AND'. */
export type GptHeroAccounts_Bool_Exp = {
  _and?: InputMaybe<Array<GptHeroAccounts_Bool_Exp>>
  _not?: InputMaybe<GptHeroAccounts_Bool_Exp>
  _or?: InputMaybe<Array<GptHeroAccounts_Bool_Exp>>
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>
  email?: InputMaybe<String_Comparison_Exp>
  gptHeroPromptUsages?: InputMaybe<GptHeroPromptUsages_Bool_Exp>
  gptHeroPromptUsages_aggregate?: InputMaybe<GptHeroPromptUsages_Aggregate_Bool_Exp>
  gptHeroPrompts?: InputMaybe<GptHeroPrompts_Bool_Exp>
  gptHeroPrompts_aggregate?: InputMaybe<GptHeroPrompts_Aggregate_Bool_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  identity?: InputMaybe<String_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>
  website?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "gptHeroAccounts" */
export enum GptHeroAccounts_Constraint {
  /** unique or primary key constraint on columns "identity" */
  GptHeroAccountsIdentityKey = 'gptHeroAccounts_identity_key',
  /** unique or primary key constraint on columns "id" */
  GptHeroAccountsPkey = 'gptHeroAccounts_pkey',
}

/** input type for inserting data into table "gptHeroAccounts" */
export type GptHeroAccounts_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  gptHeroPromptUsages?: InputMaybe<GptHeroPromptUsages_Arr_Rel_Insert_Input>
  gptHeroPrompts?: InputMaybe<GptHeroPrompts_Arr_Rel_Insert_Input>
  id?: InputMaybe<Scalars['uuid']>
  identity?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  website?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type GptHeroAccounts_Max_Fields = {
  __typename?: 'gptHeroAccounts_max_fields'
  createdAt?: Maybe<Scalars['timestamptz']>
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  identity?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
  website?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type GptHeroAccounts_Min_Fields = {
  __typename?: 'gptHeroAccounts_min_fields'
  createdAt?: Maybe<Scalars['timestamptz']>
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  identity?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
  website?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "gptHeroAccounts" */
export type GptHeroAccounts_Mutation_Response = {
  __typename?: 'gptHeroAccounts_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<GptHeroAccounts>
}

/** input type for inserting object relation for remote table "gptHeroAccounts" */
export type GptHeroAccounts_Obj_Rel_Insert_Input = {
  data: GptHeroAccounts_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<GptHeroAccounts_On_Conflict>
}

/** on_conflict condition type for table "gptHeroAccounts" */
export type GptHeroAccounts_On_Conflict = {
  constraint: GptHeroAccounts_Constraint
  update_columns?: Array<GptHeroAccounts_Update_Column>
  where?: InputMaybe<GptHeroAccounts_Bool_Exp>
}

/** Ordering options when selecting data from "gptHeroAccounts". */
export type GptHeroAccounts_Order_By = {
  createdAt?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  gptHeroPromptUsages_aggregate?: InputMaybe<GptHeroPromptUsages_Aggregate_Order_By>
  gptHeroPrompts_aggregate?: InputMaybe<GptHeroPrompts_Aggregate_Order_By>
  id?: InputMaybe<Order_By>
  identity?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
  website?: InputMaybe<Order_By>
}

/** primary key columns input for table: gptHeroAccounts */
export type GptHeroAccounts_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "gptHeroAccounts" */
export enum GptHeroAccounts_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Identity = 'identity',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Website = 'website',
}

/** input type for updating data in table "gptHeroAccounts" */
export type GptHeroAccounts_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  identity?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  website?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "gptHeroAccounts" */
export type GptHeroAccounts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GptHeroAccounts_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type GptHeroAccounts_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  identity?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  website?: InputMaybe<Scalars['String']>
}

/** update columns of table "gptHeroAccounts" */
export enum GptHeroAccounts_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Identity = 'identity',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Website = 'website',
}

export type GptHeroAccounts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GptHeroAccounts_Set_Input>
  /** filter the rows which have to be updated */
  where: GptHeroAccounts_Bool_Exp
}

/** columns and relationships of "gptHeroPromptUsages" */
export type GptHeroPromptUsages = {
  __typename?: 'gptHeroPromptUsages'
  content: Scalars['String']
  createdAt: Scalars['timestamptz']
  /** An object relationship */
  gptHeroAccount: GptHeroAccounts
  /** An object relationship */
  gptHeroPrompt: GptHeroPrompts
  id: Scalars['uuid']
  identity: Scalars['String']
  lang: Scalars['String']
  promptId: Scalars['uuid']
}

/** aggregated selection of "gptHeroPromptUsages" */
export type GptHeroPromptUsages_Aggregate = {
  __typename?: 'gptHeroPromptUsages_aggregate'
  aggregate?: Maybe<GptHeroPromptUsages_Aggregate_Fields>
  nodes: Array<GptHeroPromptUsages>
}

export type GptHeroPromptUsages_Aggregate_Bool_Exp = {
  count?: InputMaybe<GptHeroPromptUsages_Aggregate_Bool_Exp_Count>
}

export type GptHeroPromptUsages_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<GptHeroPromptUsages_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<GptHeroPromptUsages_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "gptHeroPromptUsages" */
export type GptHeroPromptUsages_Aggregate_Fields = {
  __typename?: 'gptHeroPromptUsages_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<GptHeroPromptUsages_Max_Fields>
  min?: Maybe<GptHeroPromptUsages_Min_Fields>
}

/** aggregate fields of "gptHeroPromptUsages" */
export type GptHeroPromptUsages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GptHeroPromptUsages_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "gptHeroPromptUsages" */
export type GptHeroPromptUsages_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<GptHeroPromptUsages_Max_Order_By>
  min?: InputMaybe<GptHeroPromptUsages_Min_Order_By>
}

/** input type for inserting array relation for remote table "gptHeroPromptUsages" */
export type GptHeroPromptUsages_Arr_Rel_Insert_Input = {
  data: Array<GptHeroPromptUsages_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<GptHeroPromptUsages_On_Conflict>
}

/** Boolean expression to filter rows from the table "gptHeroPromptUsages". All fields are combined with a logical 'AND'. */
export type GptHeroPromptUsages_Bool_Exp = {
  _and?: InputMaybe<Array<GptHeroPromptUsages_Bool_Exp>>
  _not?: InputMaybe<GptHeroPromptUsages_Bool_Exp>
  _or?: InputMaybe<Array<GptHeroPromptUsages_Bool_Exp>>
  content?: InputMaybe<String_Comparison_Exp>
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>
  gptHeroAccount?: InputMaybe<GptHeroAccounts_Bool_Exp>
  gptHeroPrompt?: InputMaybe<GptHeroPrompts_Bool_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  identity?: InputMaybe<String_Comparison_Exp>
  lang?: InputMaybe<String_Comparison_Exp>
  promptId?: InputMaybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "gptHeroPromptUsages" */
export enum GptHeroPromptUsages_Constraint {
  /** unique or primary key constraint on columns "id" */
  GptHeroPromptUsagesPkey = 'gptHeroPromptUsages_pkey',
}

/** input type for inserting data into table "gptHeroPromptUsages" */
export type GptHeroPromptUsages_Insert_Input = {
  content?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  gptHeroAccount?: InputMaybe<GptHeroAccounts_Obj_Rel_Insert_Input>
  gptHeroPrompt?: InputMaybe<GptHeroPrompts_Obj_Rel_Insert_Input>
  id?: InputMaybe<Scalars['uuid']>
  identity?: InputMaybe<Scalars['String']>
  lang?: InputMaybe<Scalars['String']>
  promptId?: InputMaybe<Scalars['uuid']>
}

/** aggregate max on columns */
export type GptHeroPromptUsages_Max_Fields = {
  __typename?: 'gptHeroPromptUsages_max_fields'
  content?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  identity?: Maybe<Scalars['String']>
  lang?: Maybe<Scalars['String']>
  promptId?: Maybe<Scalars['uuid']>
}

/** order by max() on columns of table "gptHeroPromptUsages" */
export type GptHeroPromptUsages_Max_Order_By = {
  content?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  identity?: InputMaybe<Order_By>
  lang?: InputMaybe<Order_By>
  promptId?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type GptHeroPromptUsages_Min_Fields = {
  __typename?: 'gptHeroPromptUsages_min_fields'
  content?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  identity?: Maybe<Scalars['String']>
  lang?: Maybe<Scalars['String']>
  promptId?: Maybe<Scalars['uuid']>
}

/** order by min() on columns of table "gptHeroPromptUsages" */
export type GptHeroPromptUsages_Min_Order_By = {
  content?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  identity?: InputMaybe<Order_By>
  lang?: InputMaybe<Order_By>
  promptId?: InputMaybe<Order_By>
}

/** response of any mutation on the table "gptHeroPromptUsages" */
export type GptHeroPromptUsages_Mutation_Response = {
  __typename?: 'gptHeroPromptUsages_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<GptHeroPromptUsages>
}

/** on_conflict condition type for table "gptHeroPromptUsages" */
export type GptHeroPromptUsages_On_Conflict = {
  constraint: GptHeroPromptUsages_Constraint
  update_columns?: Array<GptHeroPromptUsages_Update_Column>
  where?: InputMaybe<GptHeroPromptUsages_Bool_Exp>
}

/** Ordering options when selecting data from "gptHeroPromptUsages". */
export type GptHeroPromptUsages_Order_By = {
  content?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  gptHeroAccount?: InputMaybe<GptHeroAccounts_Order_By>
  gptHeroPrompt?: InputMaybe<GptHeroPrompts_Order_By>
  id?: InputMaybe<Order_By>
  identity?: InputMaybe<Order_By>
  lang?: InputMaybe<Order_By>
  promptId?: InputMaybe<Order_By>
}

/** primary key columns input for table: gptHeroPromptUsages */
export type GptHeroPromptUsages_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "gptHeroPromptUsages" */
export enum GptHeroPromptUsages_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Identity = 'identity',
  /** column name */
  Lang = 'lang',
  /** column name */
  PromptId = 'promptId',
}

/** input type for updating data in table "gptHeroPromptUsages" */
export type GptHeroPromptUsages_Set_Input = {
  content?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  identity?: InputMaybe<Scalars['String']>
  lang?: InputMaybe<Scalars['String']>
  promptId?: InputMaybe<Scalars['uuid']>
}

/** Streaming cursor of the table "gptHeroPromptUsages" */
export type GptHeroPromptUsages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GptHeroPromptUsages_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type GptHeroPromptUsages_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  identity?: InputMaybe<Scalars['String']>
  lang?: InputMaybe<Scalars['String']>
  promptId?: InputMaybe<Scalars['uuid']>
}

/** update columns of table "gptHeroPromptUsages" */
export enum GptHeroPromptUsages_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Identity = 'identity',
  /** column name */
  Lang = 'lang',
  /** column name */
  PromptId = 'promptId',
}

export type GptHeroPromptUsages_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GptHeroPromptUsages_Set_Input>
  /** filter the rows which have to be updated */
  where: GptHeroPromptUsages_Bool_Exp
}

/** columns and relationships of "gptHeroPrompts" */
export type GptHeroPrompts = {
  __typename?: 'gptHeroPrompts'
  accountId: Scalars['uuid']
  createdAt: Scalars['timestamptz']
  deletedAt?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  gptHeroAccount: GptHeroAccounts
  /** An array relationship */
  gptHeroPromptUsages: Array<GptHeroPromptUsages>
  /** An aggregate relationship */
  gptHeroPromptUsages_aggregate: GptHeroPromptUsages_Aggregate
  /** An array relationship */
  gptHeroReportIssues: Array<GptHeroReportIssues>
  /** An aggregate relationship */
  gptHeroReportIssues_aggregate: GptHeroReportIssues_Aggregate
  /** An object relationship */
  gptHeroTopic: GptHeroTopics
  hint: Scalars['String']
  id: Scalars['uuid']
  isPublic: Scalars['Boolean']
  likes: Scalars['Int']
  originId?: Maybe<Scalars['String']>
  seen: Scalars['Int']
  teaser: Scalars['String']
  template: Scalars['String']
  title: Scalars['String']
  topicId: Scalars['uuid']
  updatedAt: Scalars['timestamptz']
  usage: Scalars['Int']
}

/** columns and relationships of "gptHeroPrompts" */
export type GptHeroPromptsGptHeroPromptUsagesArgs = {
  distinct_on?: InputMaybe<Array<GptHeroPromptUsages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroPromptUsages_Order_By>>
  where?: InputMaybe<GptHeroPromptUsages_Bool_Exp>
}

/** columns and relationships of "gptHeroPrompts" */
export type GptHeroPromptsGptHeroPromptUsages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GptHeroPromptUsages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroPromptUsages_Order_By>>
  where?: InputMaybe<GptHeroPromptUsages_Bool_Exp>
}

/** columns and relationships of "gptHeroPrompts" */
export type GptHeroPromptsGptHeroReportIssuesArgs = {
  distinct_on?: InputMaybe<Array<GptHeroReportIssues_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroReportIssues_Order_By>>
  where?: InputMaybe<GptHeroReportIssues_Bool_Exp>
}

/** columns and relationships of "gptHeroPrompts" */
export type GptHeroPromptsGptHeroReportIssues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GptHeroReportIssues_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroReportIssues_Order_By>>
  where?: InputMaybe<GptHeroReportIssues_Bool_Exp>
}

/** aggregated selection of "gptHeroPrompts" */
export type GptHeroPrompts_Aggregate = {
  __typename?: 'gptHeroPrompts_aggregate'
  aggregate?: Maybe<GptHeroPrompts_Aggregate_Fields>
  nodes: Array<GptHeroPrompts>
}

export type GptHeroPrompts_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<GptHeroPrompts_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<GptHeroPrompts_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<GptHeroPrompts_Aggregate_Bool_Exp_Count>
}

export type GptHeroPrompts_Aggregate_Bool_Exp_Bool_And = {
  arguments: GptHeroPrompts_Select_Column_GptHeroPrompts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<GptHeroPrompts_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type GptHeroPrompts_Aggregate_Bool_Exp_Bool_Or = {
  arguments: GptHeroPrompts_Select_Column_GptHeroPrompts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<GptHeroPrompts_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type GptHeroPrompts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<GptHeroPrompts_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<GptHeroPrompts_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "gptHeroPrompts" */
export type GptHeroPrompts_Aggregate_Fields = {
  __typename?: 'gptHeroPrompts_aggregate_fields'
  avg?: Maybe<GptHeroPrompts_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<GptHeroPrompts_Max_Fields>
  min?: Maybe<GptHeroPrompts_Min_Fields>
  stddev?: Maybe<GptHeroPrompts_Stddev_Fields>
  stddev_pop?: Maybe<GptHeroPrompts_Stddev_Pop_Fields>
  stddev_samp?: Maybe<GptHeroPrompts_Stddev_Samp_Fields>
  sum?: Maybe<GptHeroPrompts_Sum_Fields>
  var_pop?: Maybe<GptHeroPrompts_Var_Pop_Fields>
  var_samp?: Maybe<GptHeroPrompts_Var_Samp_Fields>
  variance?: Maybe<GptHeroPrompts_Variance_Fields>
}

/** aggregate fields of "gptHeroPrompts" */
export type GptHeroPrompts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GptHeroPrompts_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "gptHeroPrompts" */
export type GptHeroPrompts_Aggregate_Order_By = {
  avg?: InputMaybe<GptHeroPrompts_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<GptHeroPrompts_Max_Order_By>
  min?: InputMaybe<GptHeroPrompts_Min_Order_By>
  stddev?: InputMaybe<GptHeroPrompts_Stddev_Order_By>
  stddev_pop?: InputMaybe<GptHeroPrompts_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<GptHeroPrompts_Stddev_Samp_Order_By>
  sum?: InputMaybe<GptHeroPrompts_Sum_Order_By>
  var_pop?: InputMaybe<GptHeroPrompts_Var_Pop_Order_By>
  var_samp?: InputMaybe<GptHeroPrompts_Var_Samp_Order_By>
  variance?: InputMaybe<GptHeroPrompts_Variance_Order_By>
}

/** input type for inserting array relation for remote table "gptHeroPrompts" */
export type GptHeroPrompts_Arr_Rel_Insert_Input = {
  data: Array<GptHeroPrompts_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<GptHeroPrompts_On_Conflict>
}

/** aggregate avg on columns */
export type GptHeroPrompts_Avg_Fields = {
  __typename?: 'gptHeroPrompts_avg_fields'
  likes?: Maybe<Scalars['Float']>
  seen?: Maybe<Scalars['Float']>
  usage?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "gptHeroPrompts" */
export type GptHeroPrompts_Avg_Order_By = {
  likes?: InputMaybe<Order_By>
  seen?: InputMaybe<Order_By>
  usage?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "gptHeroPrompts". All fields are combined with a logical 'AND'. */
export type GptHeroPrompts_Bool_Exp = {
  _and?: InputMaybe<Array<GptHeroPrompts_Bool_Exp>>
  _not?: InputMaybe<GptHeroPrompts_Bool_Exp>
  _or?: InputMaybe<Array<GptHeroPrompts_Bool_Exp>>
  accountId?: InputMaybe<Uuid_Comparison_Exp>
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>
  gptHeroAccount?: InputMaybe<GptHeroAccounts_Bool_Exp>
  gptHeroPromptUsages?: InputMaybe<GptHeroPromptUsages_Bool_Exp>
  gptHeroPromptUsages_aggregate?: InputMaybe<GptHeroPromptUsages_Aggregate_Bool_Exp>
  gptHeroReportIssues?: InputMaybe<GptHeroReportIssues_Bool_Exp>
  gptHeroReportIssues_aggregate?: InputMaybe<GptHeroReportIssues_Aggregate_Bool_Exp>
  gptHeroTopic?: InputMaybe<GptHeroTopics_Bool_Exp>
  hint?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  isPublic?: InputMaybe<Boolean_Comparison_Exp>
  likes?: InputMaybe<Int_Comparison_Exp>
  originId?: InputMaybe<String_Comparison_Exp>
  seen?: InputMaybe<Int_Comparison_Exp>
  teaser?: InputMaybe<String_Comparison_Exp>
  template?: InputMaybe<String_Comparison_Exp>
  title?: InputMaybe<String_Comparison_Exp>
  topicId?: InputMaybe<Uuid_Comparison_Exp>
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>
  usage?: InputMaybe<Int_Comparison_Exp>
}

/** unique or primary key constraints on table "gptHeroPrompts" */
export enum GptHeroPrompts_Constraint {
  /** unique or primary key constraint on columns "originId" */
  GptHeroPromptsOriginIdKey = 'gptHeroPrompts_originId_key',
  /** unique or primary key constraint on columns "id" */
  GptHeroPromptsPkey = 'gptHeroPrompts_pkey',
}

/** input type for incrementing numeric columns in table "gptHeroPrompts" */
export type GptHeroPrompts_Inc_Input = {
  likes?: InputMaybe<Scalars['Int']>
  seen?: InputMaybe<Scalars['Int']>
  usage?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "gptHeroPrompts" */
export type GptHeroPrompts_Insert_Input = {
  accountId?: InputMaybe<Scalars['uuid']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  deletedAt?: InputMaybe<Scalars['timestamptz']>
  gptHeroAccount?: InputMaybe<GptHeroAccounts_Obj_Rel_Insert_Input>
  gptHeroPromptUsages?: InputMaybe<GptHeroPromptUsages_Arr_Rel_Insert_Input>
  gptHeroReportIssues?: InputMaybe<GptHeroReportIssues_Arr_Rel_Insert_Input>
  gptHeroTopic?: InputMaybe<GptHeroTopics_Obj_Rel_Insert_Input>
  hint?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  isPublic?: InputMaybe<Scalars['Boolean']>
  likes?: InputMaybe<Scalars['Int']>
  originId?: InputMaybe<Scalars['String']>
  seen?: InputMaybe<Scalars['Int']>
  teaser?: InputMaybe<Scalars['String']>
  template?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  topicId?: InputMaybe<Scalars['uuid']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  usage?: InputMaybe<Scalars['Int']>
}

/** aggregate max on columns */
export type GptHeroPrompts_Max_Fields = {
  __typename?: 'gptHeroPrompts_max_fields'
  accountId?: Maybe<Scalars['uuid']>
  createdAt?: Maybe<Scalars['timestamptz']>
  deletedAt?: Maybe<Scalars['timestamptz']>
  hint?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  likes?: Maybe<Scalars['Int']>
  originId?: Maybe<Scalars['String']>
  seen?: Maybe<Scalars['Int']>
  teaser?: Maybe<Scalars['String']>
  template?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  topicId?: Maybe<Scalars['uuid']>
  updatedAt?: Maybe<Scalars['timestamptz']>
  usage?: Maybe<Scalars['Int']>
}

/** order by max() on columns of table "gptHeroPrompts" */
export type GptHeroPrompts_Max_Order_By = {
  accountId?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  deletedAt?: InputMaybe<Order_By>
  hint?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  likes?: InputMaybe<Order_By>
  originId?: InputMaybe<Order_By>
  seen?: InputMaybe<Order_By>
  teaser?: InputMaybe<Order_By>
  template?: InputMaybe<Order_By>
  title?: InputMaybe<Order_By>
  topicId?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
  usage?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type GptHeroPrompts_Min_Fields = {
  __typename?: 'gptHeroPrompts_min_fields'
  accountId?: Maybe<Scalars['uuid']>
  createdAt?: Maybe<Scalars['timestamptz']>
  deletedAt?: Maybe<Scalars['timestamptz']>
  hint?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  likes?: Maybe<Scalars['Int']>
  originId?: Maybe<Scalars['String']>
  seen?: Maybe<Scalars['Int']>
  teaser?: Maybe<Scalars['String']>
  template?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  topicId?: Maybe<Scalars['uuid']>
  updatedAt?: Maybe<Scalars['timestamptz']>
  usage?: Maybe<Scalars['Int']>
}

/** order by min() on columns of table "gptHeroPrompts" */
export type GptHeroPrompts_Min_Order_By = {
  accountId?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  deletedAt?: InputMaybe<Order_By>
  hint?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  likes?: InputMaybe<Order_By>
  originId?: InputMaybe<Order_By>
  seen?: InputMaybe<Order_By>
  teaser?: InputMaybe<Order_By>
  template?: InputMaybe<Order_By>
  title?: InputMaybe<Order_By>
  topicId?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
  usage?: InputMaybe<Order_By>
}

/** response of any mutation on the table "gptHeroPrompts" */
export type GptHeroPrompts_Mutation_Response = {
  __typename?: 'gptHeroPrompts_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<GptHeroPrompts>
}

/** input type for inserting object relation for remote table "gptHeroPrompts" */
export type GptHeroPrompts_Obj_Rel_Insert_Input = {
  data: GptHeroPrompts_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<GptHeroPrompts_On_Conflict>
}

/** on_conflict condition type for table "gptHeroPrompts" */
export type GptHeroPrompts_On_Conflict = {
  constraint: GptHeroPrompts_Constraint
  update_columns?: Array<GptHeroPrompts_Update_Column>
  where?: InputMaybe<GptHeroPrompts_Bool_Exp>
}

/** Ordering options when selecting data from "gptHeroPrompts". */
export type GptHeroPrompts_Order_By = {
  accountId?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  deletedAt?: InputMaybe<Order_By>
  gptHeroAccount?: InputMaybe<GptHeroAccounts_Order_By>
  gptHeroPromptUsages_aggregate?: InputMaybe<GptHeroPromptUsages_Aggregate_Order_By>
  gptHeroReportIssues_aggregate?: InputMaybe<GptHeroReportIssues_Aggregate_Order_By>
  gptHeroTopic?: InputMaybe<GptHeroTopics_Order_By>
  hint?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  isPublic?: InputMaybe<Order_By>
  likes?: InputMaybe<Order_By>
  originId?: InputMaybe<Order_By>
  seen?: InputMaybe<Order_By>
  teaser?: InputMaybe<Order_By>
  template?: InputMaybe<Order_By>
  title?: InputMaybe<Order_By>
  topicId?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
  usage?: InputMaybe<Order_By>
}

/** primary key columns input for table: gptHeroPrompts */
export type GptHeroPrompts_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "gptHeroPrompts" */
export enum GptHeroPrompts_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Hint = 'hint',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'isPublic',
  /** column name */
  Likes = 'likes',
  /** column name */
  OriginId = 'originId',
  /** column name */
  Seen = 'seen',
  /** column name */
  Teaser = 'teaser',
  /** column name */
  Template = 'template',
  /** column name */
  Title = 'title',
  /** column name */
  TopicId = 'topicId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Usage = 'usage',
}

/** select "gptHeroPrompts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "gptHeroPrompts" */
export enum GptHeroPrompts_Select_Column_GptHeroPrompts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPublic = 'isPublic',
}

/** select "gptHeroPrompts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "gptHeroPrompts" */
export enum GptHeroPrompts_Select_Column_GptHeroPrompts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPublic = 'isPublic',
}

/** input type for updating data in table "gptHeroPrompts" */
export type GptHeroPrompts_Set_Input = {
  accountId?: InputMaybe<Scalars['uuid']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  deletedAt?: InputMaybe<Scalars['timestamptz']>
  hint?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  isPublic?: InputMaybe<Scalars['Boolean']>
  likes?: InputMaybe<Scalars['Int']>
  originId?: InputMaybe<Scalars['String']>
  seen?: InputMaybe<Scalars['Int']>
  teaser?: InputMaybe<Scalars['String']>
  template?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  topicId?: InputMaybe<Scalars['uuid']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  usage?: InputMaybe<Scalars['Int']>
}

/** aggregate stddev on columns */
export type GptHeroPrompts_Stddev_Fields = {
  __typename?: 'gptHeroPrompts_stddev_fields'
  likes?: Maybe<Scalars['Float']>
  seen?: Maybe<Scalars['Float']>
  usage?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "gptHeroPrompts" */
export type GptHeroPrompts_Stddev_Order_By = {
  likes?: InputMaybe<Order_By>
  seen?: InputMaybe<Order_By>
  usage?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type GptHeroPrompts_Stddev_Pop_Fields = {
  __typename?: 'gptHeroPrompts_stddev_pop_fields'
  likes?: Maybe<Scalars['Float']>
  seen?: Maybe<Scalars['Float']>
  usage?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "gptHeroPrompts" */
export type GptHeroPrompts_Stddev_Pop_Order_By = {
  likes?: InputMaybe<Order_By>
  seen?: InputMaybe<Order_By>
  usage?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type GptHeroPrompts_Stddev_Samp_Fields = {
  __typename?: 'gptHeroPrompts_stddev_samp_fields'
  likes?: Maybe<Scalars['Float']>
  seen?: Maybe<Scalars['Float']>
  usage?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "gptHeroPrompts" */
export type GptHeroPrompts_Stddev_Samp_Order_By = {
  likes?: InputMaybe<Order_By>
  seen?: InputMaybe<Order_By>
  usage?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "gptHeroPrompts" */
export type GptHeroPrompts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GptHeroPrompts_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type GptHeroPrompts_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['uuid']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  deletedAt?: InputMaybe<Scalars['timestamptz']>
  hint?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  isPublic?: InputMaybe<Scalars['Boolean']>
  likes?: InputMaybe<Scalars['Int']>
  originId?: InputMaybe<Scalars['String']>
  seen?: InputMaybe<Scalars['Int']>
  teaser?: InputMaybe<Scalars['String']>
  template?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  topicId?: InputMaybe<Scalars['uuid']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  usage?: InputMaybe<Scalars['Int']>
}

/** aggregate sum on columns */
export type GptHeroPrompts_Sum_Fields = {
  __typename?: 'gptHeroPrompts_sum_fields'
  likes?: Maybe<Scalars['Int']>
  seen?: Maybe<Scalars['Int']>
  usage?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "gptHeroPrompts" */
export type GptHeroPrompts_Sum_Order_By = {
  likes?: InputMaybe<Order_By>
  seen?: InputMaybe<Order_By>
  usage?: InputMaybe<Order_By>
}

/** update columns of table "gptHeroPrompts" */
export enum GptHeroPrompts_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Hint = 'hint',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'isPublic',
  /** column name */
  Likes = 'likes',
  /** column name */
  OriginId = 'originId',
  /** column name */
  Seen = 'seen',
  /** column name */
  Teaser = 'teaser',
  /** column name */
  Template = 'template',
  /** column name */
  Title = 'title',
  /** column name */
  TopicId = 'topicId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Usage = 'usage',
}

export type GptHeroPrompts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GptHeroPrompts_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GptHeroPrompts_Set_Input>
  /** filter the rows which have to be updated */
  where: GptHeroPrompts_Bool_Exp
}

/** aggregate var_pop on columns */
export type GptHeroPrompts_Var_Pop_Fields = {
  __typename?: 'gptHeroPrompts_var_pop_fields'
  likes?: Maybe<Scalars['Float']>
  seen?: Maybe<Scalars['Float']>
  usage?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "gptHeroPrompts" */
export type GptHeroPrompts_Var_Pop_Order_By = {
  likes?: InputMaybe<Order_By>
  seen?: InputMaybe<Order_By>
  usage?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type GptHeroPrompts_Var_Samp_Fields = {
  __typename?: 'gptHeroPrompts_var_samp_fields'
  likes?: Maybe<Scalars['Float']>
  seen?: Maybe<Scalars['Float']>
  usage?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "gptHeroPrompts" */
export type GptHeroPrompts_Var_Samp_Order_By = {
  likes?: InputMaybe<Order_By>
  seen?: InputMaybe<Order_By>
  usage?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type GptHeroPrompts_Variance_Fields = {
  __typename?: 'gptHeroPrompts_variance_fields'
  likes?: Maybe<Scalars['Float']>
  seen?: Maybe<Scalars['Float']>
  usage?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "gptHeroPrompts" */
export type GptHeroPrompts_Variance_Order_By = {
  likes?: InputMaybe<Order_By>
  seen?: InputMaybe<Order_By>
  usage?: InputMaybe<Order_By>
}

/** columns and relationships of "gptHeroReportIssues" */
export type GptHeroReportIssues = {
  __typename?: 'gptHeroReportIssues'
  comment?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  createdAt: Scalars['timestamptz']
  email: Scalars['String']
  /** An object relationship */
  gptHeroPrompt: GptHeroPrompts
  id: Scalars['uuid']
  isChecked: Scalars['Boolean']
  promptId: Scalars['uuid']
  reason?: Maybe<Scalars['String']>
  updatedAt: Scalars['timestamptz']
}

/** aggregated selection of "gptHeroReportIssues" */
export type GptHeroReportIssues_Aggregate = {
  __typename?: 'gptHeroReportIssues_aggregate'
  aggregate?: Maybe<GptHeroReportIssues_Aggregate_Fields>
  nodes: Array<GptHeroReportIssues>
}

export type GptHeroReportIssues_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<GptHeroReportIssues_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<GptHeroReportIssues_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<GptHeroReportIssues_Aggregate_Bool_Exp_Count>
}

export type GptHeroReportIssues_Aggregate_Bool_Exp_Bool_And = {
  arguments: GptHeroReportIssues_Select_Column_GptHeroReportIssues_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<GptHeroReportIssues_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type GptHeroReportIssues_Aggregate_Bool_Exp_Bool_Or = {
  arguments: GptHeroReportIssues_Select_Column_GptHeroReportIssues_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<GptHeroReportIssues_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type GptHeroReportIssues_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<GptHeroReportIssues_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<GptHeroReportIssues_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "gptHeroReportIssues" */
export type GptHeroReportIssues_Aggregate_Fields = {
  __typename?: 'gptHeroReportIssues_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<GptHeroReportIssues_Max_Fields>
  min?: Maybe<GptHeroReportIssues_Min_Fields>
}

/** aggregate fields of "gptHeroReportIssues" */
export type GptHeroReportIssues_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GptHeroReportIssues_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "gptHeroReportIssues" */
export type GptHeroReportIssues_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<GptHeroReportIssues_Max_Order_By>
  min?: InputMaybe<GptHeroReportIssues_Min_Order_By>
}

/** input type for inserting array relation for remote table "gptHeroReportIssues" */
export type GptHeroReportIssues_Arr_Rel_Insert_Input = {
  data: Array<GptHeroReportIssues_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<GptHeroReportIssues_On_Conflict>
}

/** Boolean expression to filter rows from the table "gptHeroReportIssues". All fields are combined with a logical 'AND'. */
export type GptHeroReportIssues_Bool_Exp = {
  _and?: InputMaybe<Array<GptHeroReportIssues_Bool_Exp>>
  _not?: InputMaybe<GptHeroReportIssues_Bool_Exp>
  _or?: InputMaybe<Array<GptHeroReportIssues_Bool_Exp>>
  comment?: InputMaybe<String_Comparison_Exp>
  content?: InputMaybe<String_Comparison_Exp>
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>
  email?: InputMaybe<String_Comparison_Exp>
  gptHeroPrompt?: InputMaybe<GptHeroPrompts_Bool_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  isChecked?: InputMaybe<Boolean_Comparison_Exp>
  promptId?: InputMaybe<Uuid_Comparison_Exp>
  reason?: InputMaybe<String_Comparison_Exp>
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "gptHeroReportIssues" */
export enum GptHeroReportIssues_Constraint {
  /** unique or primary key constraint on columns "id" */
  GptHeroReportIssuesPkey = 'gptHeroReportIssues_pkey',
}

/** input type for inserting data into table "gptHeroReportIssues" */
export type GptHeroReportIssues_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>
  content?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  gptHeroPrompt?: InputMaybe<GptHeroPrompts_Obj_Rel_Insert_Input>
  id?: InputMaybe<Scalars['uuid']>
  isChecked?: InputMaybe<Scalars['Boolean']>
  promptId?: InputMaybe<Scalars['uuid']>
  reason?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type GptHeroReportIssues_Max_Fields = {
  __typename?: 'gptHeroReportIssues_max_fields'
  comment?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  promptId?: Maybe<Scalars['uuid']>
  reason?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "gptHeroReportIssues" */
export type GptHeroReportIssues_Max_Order_By = {
  comment?: InputMaybe<Order_By>
  content?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  promptId?: InputMaybe<Order_By>
  reason?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type GptHeroReportIssues_Min_Fields = {
  __typename?: 'gptHeroReportIssues_min_fields'
  comment?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  promptId?: Maybe<Scalars['uuid']>
  reason?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "gptHeroReportIssues" */
export type GptHeroReportIssues_Min_Order_By = {
  comment?: InputMaybe<Order_By>
  content?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  promptId?: InputMaybe<Order_By>
  reason?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** response of any mutation on the table "gptHeroReportIssues" */
export type GptHeroReportIssues_Mutation_Response = {
  __typename?: 'gptHeroReportIssues_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<GptHeroReportIssues>
}

/** on_conflict condition type for table "gptHeroReportIssues" */
export type GptHeroReportIssues_On_Conflict = {
  constraint: GptHeroReportIssues_Constraint
  update_columns?: Array<GptHeroReportIssues_Update_Column>
  where?: InputMaybe<GptHeroReportIssues_Bool_Exp>
}

/** Ordering options when selecting data from "gptHeroReportIssues". */
export type GptHeroReportIssues_Order_By = {
  comment?: InputMaybe<Order_By>
  content?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  gptHeroPrompt?: InputMaybe<GptHeroPrompts_Order_By>
  id?: InputMaybe<Order_By>
  isChecked?: InputMaybe<Order_By>
  promptId?: InputMaybe<Order_By>
  reason?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** primary key columns input for table: gptHeroReportIssues */
export type GptHeroReportIssues_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "gptHeroReportIssues" */
export enum GptHeroReportIssues_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsChecked = 'isChecked',
  /** column name */
  PromptId = 'promptId',
  /** column name */
  Reason = 'reason',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "gptHeroReportIssues_aggregate_bool_exp_bool_and_arguments_columns" columns of table "gptHeroReportIssues" */
export enum GptHeroReportIssues_Select_Column_GptHeroReportIssues_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsChecked = 'isChecked',
}

/** select "gptHeroReportIssues_aggregate_bool_exp_bool_or_arguments_columns" columns of table "gptHeroReportIssues" */
export enum GptHeroReportIssues_Select_Column_GptHeroReportIssues_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsChecked = 'isChecked',
}

/** input type for updating data in table "gptHeroReportIssues" */
export type GptHeroReportIssues_Set_Input = {
  comment?: InputMaybe<Scalars['String']>
  content?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  isChecked?: InputMaybe<Scalars['Boolean']>
  promptId?: InputMaybe<Scalars['uuid']>
  reason?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "gptHeroReportIssues" */
export type GptHeroReportIssues_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GptHeroReportIssues_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type GptHeroReportIssues_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>
  content?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  isChecked?: InputMaybe<Scalars['Boolean']>
  promptId?: InputMaybe<Scalars['uuid']>
  reason?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "gptHeroReportIssues" */
export enum GptHeroReportIssues_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsChecked = 'isChecked',
  /** column name */
  PromptId = 'promptId',
  /** column name */
  Reason = 'reason',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type GptHeroReportIssues_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GptHeroReportIssues_Set_Input>
  /** filter the rows which have to be updated */
  where: GptHeroReportIssues_Bool_Exp
}

/** columns and relationships of "gptHeroTopics" */
export type GptHeroTopics = {
  __typename?: 'gptHeroTopics'
  createdAt: Scalars['timestamptz']
  /** An array relationship */
  gptHeroPrompts: Array<GptHeroPrompts>
  /** An aggregate relationship */
  gptHeroPrompts_aggregate: GptHeroPrompts_Aggregate
  id: Scalars['uuid']
  name: Scalars['String']
  updatedAt: Scalars['timestamptz']
}

/** columns and relationships of "gptHeroTopics" */
export type GptHeroTopicsGptHeroPromptsArgs = {
  distinct_on?: InputMaybe<Array<GptHeroPrompts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroPrompts_Order_By>>
  where?: InputMaybe<GptHeroPrompts_Bool_Exp>
}

/** columns and relationships of "gptHeroTopics" */
export type GptHeroTopicsGptHeroPrompts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GptHeroPrompts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroPrompts_Order_By>>
  where?: InputMaybe<GptHeroPrompts_Bool_Exp>
}

/** aggregated selection of "gptHeroTopics" */
export type GptHeroTopics_Aggregate = {
  __typename?: 'gptHeroTopics_aggregate'
  aggregate?: Maybe<GptHeroTopics_Aggregate_Fields>
  nodes: Array<GptHeroTopics>
}

/** aggregate fields of "gptHeroTopics" */
export type GptHeroTopics_Aggregate_Fields = {
  __typename?: 'gptHeroTopics_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<GptHeroTopics_Max_Fields>
  min?: Maybe<GptHeroTopics_Min_Fields>
}

/** aggregate fields of "gptHeroTopics" */
export type GptHeroTopics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<GptHeroTopics_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "gptHeroTopics". All fields are combined with a logical 'AND'. */
export type GptHeroTopics_Bool_Exp = {
  _and?: InputMaybe<Array<GptHeroTopics_Bool_Exp>>
  _not?: InputMaybe<GptHeroTopics_Bool_Exp>
  _or?: InputMaybe<Array<GptHeroTopics_Bool_Exp>>
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>
  gptHeroPrompts?: InputMaybe<GptHeroPrompts_Bool_Exp>
  gptHeroPrompts_aggregate?: InputMaybe<GptHeroPrompts_Aggregate_Bool_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "gptHeroTopics" */
export enum GptHeroTopics_Constraint {
  /** unique or primary key constraint on columns "id" */
  GptHeroTopicsPkey = 'gptHeroTopics_pkey',
}

/** input type for inserting data into table "gptHeroTopics" */
export type GptHeroTopics_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>
  gptHeroPrompts?: InputMaybe<GptHeroPrompts_Arr_Rel_Insert_Input>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type GptHeroTopics_Max_Fields = {
  __typename?: 'gptHeroTopics_max_fields'
  createdAt?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type GptHeroTopics_Min_Fields = {
  __typename?: 'gptHeroTopics_min_fields'
  createdAt?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "gptHeroTopics" */
export type GptHeroTopics_Mutation_Response = {
  __typename?: 'gptHeroTopics_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<GptHeroTopics>
}

/** input type for inserting object relation for remote table "gptHeroTopics" */
export type GptHeroTopics_Obj_Rel_Insert_Input = {
  data: GptHeroTopics_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<GptHeroTopics_On_Conflict>
}

/** on_conflict condition type for table "gptHeroTopics" */
export type GptHeroTopics_On_Conflict = {
  constraint: GptHeroTopics_Constraint
  update_columns?: Array<GptHeroTopics_Update_Column>
  where?: InputMaybe<GptHeroTopics_Bool_Exp>
}

/** Ordering options when selecting data from "gptHeroTopics". */
export type GptHeroTopics_Order_By = {
  createdAt?: InputMaybe<Order_By>
  gptHeroPrompts_aggregate?: InputMaybe<GptHeroPrompts_Aggregate_Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** primary key columns input for table: gptHeroTopics */
export type GptHeroTopics_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "gptHeroTopics" */
export enum GptHeroTopics_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "gptHeroTopics" */
export type GptHeroTopics_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "gptHeroTopics" */
export type GptHeroTopics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: GptHeroTopics_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type GptHeroTopics_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "gptHeroTopics" */
export enum GptHeroTopics_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type GptHeroTopics_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GptHeroTopics_Set_Input>
  /** filter the rows which have to be updated */
  where: GptHeroTopics_Bool_Exp
}

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>
  _eq?: InputMaybe<Scalars['jsonb']>
  _gt?: InputMaybe<Scalars['jsonb']>
  _gte?: InputMaybe<Scalars['jsonb']>
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>
  _in?: InputMaybe<Array<Scalars['jsonb']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['jsonb']>
  _lte?: InputMaybe<Scalars['jsonb']>
  _neq?: InputMaybe<Scalars['jsonb']>
  _nin?: InputMaybe<Array<Scalars['jsonb']>>
}

/** columns and relationships of "midjourneyImages" */
export type MidjourneyImages = {
  __typename?: 'midjourneyImages'
  attachment: Scalars['String']
  cloudinaryPublicId?: Maybe<Scalars['String']>
  contentType: Scalars['String']
  createdAt: Scalars['timestamptz']
  deletedAt?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  discordAttachmentId: Scalars['String']
  discordMessageId: Scalars['String']
  discordUserId: Scalars['String']
  ephemeral: Scalars['Boolean']
  height: Scalars['Int']
  id: Scalars['uuid']
  name: Scalars['String']
  prompt: Scalars['String']
  proxyURL: Scalars['String']
  recordedAt: Scalars['timestamptz']
  size: Scalars['Int']
  updatedAt: Scalars['timestamptz']
  url: Scalars['String']
  width: Scalars['Int']
}

/** aggregated selection of "midjourneyImages" */
export type MidjourneyImages_Aggregate = {
  __typename?: 'midjourneyImages_aggregate'
  aggregate?: Maybe<MidjourneyImages_Aggregate_Fields>
  nodes: Array<MidjourneyImages>
}

/** aggregate fields of "midjourneyImages" */
export type MidjourneyImages_Aggregate_Fields = {
  __typename?: 'midjourneyImages_aggregate_fields'
  avg?: Maybe<MidjourneyImages_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<MidjourneyImages_Max_Fields>
  min?: Maybe<MidjourneyImages_Min_Fields>
  stddev?: Maybe<MidjourneyImages_Stddev_Fields>
  stddev_pop?: Maybe<MidjourneyImages_Stddev_Pop_Fields>
  stddev_samp?: Maybe<MidjourneyImages_Stddev_Samp_Fields>
  sum?: Maybe<MidjourneyImages_Sum_Fields>
  var_pop?: Maybe<MidjourneyImages_Var_Pop_Fields>
  var_samp?: Maybe<MidjourneyImages_Var_Samp_Fields>
  variance?: Maybe<MidjourneyImages_Variance_Fields>
}

/** aggregate fields of "midjourneyImages" */
export type MidjourneyImages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<MidjourneyImages_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type MidjourneyImages_Avg_Fields = {
  __typename?: 'midjourneyImages_avg_fields'
  height?: Maybe<Scalars['Float']>
  size?: Maybe<Scalars['Float']>
  width?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "midjourneyImages". All fields are combined with a logical 'AND'. */
export type MidjourneyImages_Bool_Exp = {
  _and?: InputMaybe<Array<MidjourneyImages_Bool_Exp>>
  _not?: InputMaybe<MidjourneyImages_Bool_Exp>
  _or?: InputMaybe<Array<MidjourneyImages_Bool_Exp>>
  attachment?: InputMaybe<String_Comparison_Exp>
  cloudinaryPublicId?: InputMaybe<String_Comparison_Exp>
  contentType?: InputMaybe<String_Comparison_Exp>
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>
  deletedAt?: InputMaybe<Timestamptz_Comparison_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  discordAttachmentId?: InputMaybe<String_Comparison_Exp>
  discordMessageId?: InputMaybe<String_Comparison_Exp>
  discordUserId?: InputMaybe<String_Comparison_Exp>
  ephemeral?: InputMaybe<Boolean_Comparison_Exp>
  height?: InputMaybe<Int_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  prompt?: InputMaybe<String_Comparison_Exp>
  proxyURL?: InputMaybe<String_Comparison_Exp>
  recordedAt?: InputMaybe<Timestamptz_Comparison_Exp>
  size?: InputMaybe<Int_Comparison_Exp>
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>
  url?: InputMaybe<String_Comparison_Exp>
  width?: InputMaybe<Int_Comparison_Exp>
}

/** unique or primary key constraints on table "midjourneyImages" */
export enum MidjourneyImages_Constraint {
  /** unique or primary key constraint on columns "cloudinaryPublicId" */
  MidjourneyImagesCloudinaryPublicIdKey = 'midjourneyImages_cloudinaryPublicId_key',
  /** unique or primary key constraint on columns "discordAttachmentId" */
  MidjourneyImagesDiscordAttachmentIdKey = 'midjourneyImages_discordAttachmentId_key',
  /** unique or primary key constraint on columns "id" */
  MidjourneyImagesPkey = 'midjourneyImages_pkey',
}

/** input type for incrementing numeric columns in table "midjourneyImages" */
export type MidjourneyImages_Inc_Input = {
  height?: InputMaybe<Scalars['Int']>
  size?: InputMaybe<Scalars['Int']>
  width?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "midjourneyImages" */
export type MidjourneyImages_Insert_Input = {
  attachment?: InputMaybe<Scalars['String']>
  cloudinaryPublicId?: InputMaybe<Scalars['String']>
  contentType?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  deletedAt?: InputMaybe<Scalars['timestamptz']>
  description?: InputMaybe<Scalars['String']>
  discordAttachmentId?: InputMaybe<Scalars['String']>
  discordMessageId?: InputMaybe<Scalars['String']>
  discordUserId?: InputMaybe<Scalars['String']>
  ephemeral?: InputMaybe<Scalars['Boolean']>
  height?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  prompt?: InputMaybe<Scalars['String']>
  proxyURL?: InputMaybe<Scalars['String']>
  recordedAt?: InputMaybe<Scalars['timestamptz']>
  size?: InputMaybe<Scalars['Int']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  url?: InputMaybe<Scalars['String']>
  width?: InputMaybe<Scalars['Int']>
}

/** aggregate max on columns */
export type MidjourneyImages_Max_Fields = {
  __typename?: 'midjourneyImages_max_fields'
  attachment?: Maybe<Scalars['String']>
  cloudinaryPublicId?: Maybe<Scalars['String']>
  contentType?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  deletedAt?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  discordAttachmentId?: Maybe<Scalars['String']>
  discordMessageId?: Maybe<Scalars['String']>
  discordUserId?: Maybe<Scalars['String']>
  height?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  prompt?: Maybe<Scalars['String']>
  proxyURL?: Maybe<Scalars['String']>
  recordedAt?: Maybe<Scalars['timestamptz']>
  size?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
  width?: Maybe<Scalars['Int']>
}

/** aggregate min on columns */
export type MidjourneyImages_Min_Fields = {
  __typename?: 'midjourneyImages_min_fields'
  attachment?: Maybe<Scalars['String']>
  cloudinaryPublicId?: Maybe<Scalars['String']>
  contentType?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  deletedAt?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  discordAttachmentId?: Maybe<Scalars['String']>
  discordMessageId?: Maybe<Scalars['String']>
  discordUserId?: Maybe<Scalars['String']>
  height?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  prompt?: Maybe<Scalars['String']>
  proxyURL?: Maybe<Scalars['String']>
  recordedAt?: Maybe<Scalars['timestamptz']>
  size?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
  width?: Maybe<Scalars['Int']>
}

/** response of any mutation on the table "midjourneyImages" */
export type MidjourneyImages_Mutation_Response = {
  __typename?: 'midjourneyImages_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<MidjourneyImages>
}

/** on_conflict condition type for table "midjourneyImages" */
export type MidjourneyImages_On_Conflict = {
  constraint: MidjourneyImages_Constraint
  update_columns?: Array<MidjourneyImages_Update_Column>
  where?: InputMaybe<MidjourneyImages_Bool_Exp>
}

/** Ordering options when selecting data from "midjourneyImages". */
export type MidjourneyImages_Order_By = {
  attachment?: InputMaybe<Order_By>
  cloudinaryPublicId?: InputMaybe<Order_By>
  contentType?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  deletedAt?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  discordAttachmentId?: InputMaybe<Order_By>
  discordMessageId?: InputMaybe<Order_By>
  discordUserId?: InputMaybe<Order_By>
  ephemeral?: InputMaybe<Order_By>
  height?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  prompt?: InputMaybe<Order_By>
  proxyURL?: InputMaybe<Order_By>
  recordedAt?: InputMaybe<Order_By>
  size?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
  url?: InputMaybe<Order_By>
  width?: InputMaybe<Order_By>
}

/** primary key columns input for table: midjourneyImages */
export type MidjourneyImages_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "midjourneyImages" */
export enum MidjourneyImages_Select_Column {
  /** column name */
  Attachment = 'attachment',
  /** column name */
  CloudinaryPublicId = 'cloudinaryPublicId',
  /** column name */
  ContentType = 'contentType',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  DiscordAttachmentId = 'discordAttachmentId',
  /** column name */
  DiscordMessageId = 'discordMessageId',
  /** column name */
  DiscordUserId = 'discordUserId',
  /** column name */
  Ephemeral = 'ephemeral',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Prompt = 'prompt',
  /** column name */
  ProxyUrl = 'proxyURL',
  /** column name */
  RecordedAt = 'recordedAt',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url',
  /** column name */
  Width = 'width',
}

/** input type for updating data in table "midjourneyImages" */
export type MidjourneyImages_Set_Input = {
  attachment?: InputMaybe<Scalars['String']>
  cloudinaryPublicId?: InputMaybe<Scalars['String']>
  contentType?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  deletedAt?: InputMaybe<Scalars['timestamptz']>
  description?: InputMaybe<Scalars['String']>
  discordAttachmentId?: InputMaybe<Scalars['String']>
  discordMessageId?: InputMaybe<Scalars['String']>
  discordUserId?: InputMaybe<Scalars['String']>
  ephemeral?: InputMaybe<Scalars['Boolean']>
  height?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  prompt?: InputMaybe<Scalars['String']>
  proxyURL?: InputMaybe<Scalars['String']>
  recordedAt?: InputMaybe<Scalars['timestamptz']>
  size?: InputMaybe<Scalars['Int']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  url?: InputMaybe<Scalars['String']>
  width?: InputMaybe<Scalars['Int']>
}

/** aggregate stddev on columns */
export type MidjourneyImages_Stddev_Fields = {
  __typename?: 'midjourneyImages_stddev_fields'
  height?: Maybe<Scalars['Float']>
  size?: Maybe<Scalars['Float']>
  width?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type MidjourneyImages_Stddev_Pop_Fields = {
  __typename?: 'midjourneyImages_stddev_pop_fields'
  height?: Maybe<Scalars['Float']>
  size?: Maybe<Scalars['Float']>
  width?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type MidjourneyImages_Stddev_Samp_Fields = {
  __typename?: 'midjourneyImages_stddev_samp_fields'
  height?: Maybe<Scalars['Float']>
  size?: Maybe<Scalars['Float']>
  width?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "midjourneyImages" */
export type MidjourneyImages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: MidjourneyImages_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type MidjourneyImages_Stream_Cursor_Value_Input = {
  attachment?: InputMaybe<Scalars['String']>
  cloudinaryPublicId?: InputMaybe<Scalars['String']>
  contentType?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  deletedAt?: InputMaybe<Scalars['timestamptz']>
  description?: InputMaybe<Scalars['String']>
  discordAttachmentId?: InputMaybe<Scalars['String']>
  discordMessageId?: InputMaybe<Scalars['String']>
  discordUserId?: InputMaybe<Scalars['String']>
  ephemeral?: InputMaybe<Scalars['Boolean']>
  height?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  prompt?: InputMaybe<Scalars['String']>
  proxyURL?: InputMaybe<Scalars['String']>
  recordedAt?: InputMaybe<Scalars['timestamptz']>
  size?: InputMaybe<Scalars['Int']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  url?: InputMaybe<Scalars['String']>
  width?: InputMaybe<Scalars['Int']>
}

/** aggregate sum on columns */
export type MidjourneyImages_Sum_Fields = {
  __typename?: 'midjourneyImages_sum_fields'
  height?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  width?: Maybe<Scalars['Int']>
}

/** update columns of table "midjourneyImages" */
export enum MidjourneyImages_Update_Column {
  /** column name */
  Attachment = 'attachment',
  /** column name */
  CloudinaryPublicId = 'cloudinaryPublicId',
  /** column name */
  ContentType = 'contentType',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  DiscordAttachmentId = 'discordAttachmentId',
  /** column name */
  DiscordMessageId = 'discordMessageId',
  /** column name */
  DiscordUserId = 'discordUserId',
  /** column name */
  Ephemeral = 'ephemeral',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Prompt = 'prompt',
  /** column name */
  ProxyUrl = 'proxyURL',
  /** column name */
  RecordedAt = 'recordedAt',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url',
  /** column name */
  Width = 'width',
}

export type MidjourneyImages_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MidjourneyImages_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MidjourneyImages_Set_Input>
  /** filter the rows which have to be updated */
  where: MidjourneyImages_Bool_Exp
}

/** aggregate var_pop on columns */
export type MidjourneyImages_Var_Pop_Fields = {
  __typename?: 'midjourneyImages_var_pop_fields'
  height?: Maybe<Scalars['Float']>
  size?: Maybe<Scalars['Float']>
  width?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type MidjourneyImages_Var_Samp_Fields = {
  __typename?: 'midjourneyImages_var_samp_fields'
  height?: Maybe<Scalars['Float']>
  size?: Maybe<Scalars['Float']>
  width?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type MidjourneyImages_Variance_Fields = {
  __typename?: 'midjourneyImages_variance_fields'
  height?: Maybe<Scalars['Float']>
  size?: Maybe<Scalars['Float']>
  width?: Maybe<Scalars['Float']>
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root'
  /** create portal sessions */
  createPortalSessions?: Maybe<CreatePortalSession>
  /** create stripe checkout sessions */
  createStripeCheckoutSessions?: Maybe<CreateStripeCheckoutSession>
  /** delete data from the table: "aICompletionRecords" */
  delete_aICompletionRecords?: Maybe<AICompletionRecords_Mutation_Response>
  /** delete single row from the table: "aICompletionRecords" */
  delete_aICompletionRecords_by_pk?: Maybe<AICompletionRecords>
  /** delete data from the table: "apps" */
  delete_apps?: Maybe<Apps_Mutation_Response>
  /** delete single row from the table: "apps" */
  delete_apps_by_pk?: Maybe<Apps>
  /** delete data from the table: "discordMessages" */
  delete_discordMessages?: Maybe<DiscordMessages_Mutation_Response>
  /** delete single row from the table: "discordMessages" */
  delete_discordMessages_by_pk?: Maybe<DiscordMessages>
  /** delete data from the table: "formAnswers" */
  delete_formAnswers?: Maybe<FormAnswers_Mutation_Response>
  /** delete single row from the table: "formAnswers" */
  delete_formAnswers_by_pk?: Maybe<FormAnswers>
  /** delete data from the table: "forms" */
  delete_forms?: Maybe<Forms_Mutation_Response>
  /** delete single row from the table: "forms" */
  delete_forms_by_pk?: Maybe<Forms>
  /** delete data from the table: "gptHeroAccounts" */
  delete_gptHeroAccounts?: Maybe<GptHeroAccounts_Mutation_Response>
  /** delete single row from the table: "gptHeroAccounts" */
  delete_gptHeroAccounts_by_pk?: Maybe<GptHeroAccounts>
  /** delete data from the table: "gptHeroPromptUsages" */
  delete_gptHeroPromptUsages?: Maybe<GptHeroPromptUsages_Mutation_Response>
  /** delete single row from the table: "gptHeroPromptUsages" */
  delete_gptHeroPromptUsages_by_pk?: Maybe<GptHeroPromptUsages>
  /** delete data from the table: "gptHeroPrompts" */
  delete_gptHeroPrompts?: Maybe<GptHeroPrompts_Mutation_Response>
  /** delete single row from the table: "gptHeroPrompts" */
  delete_gptHeroPrompts_by_pk?: Maybe<GptHeroPrompts>
  /** delete data from the table: "gptHeroReportIssues" */
  delete_gptHeroReportIssues?: Maybe<GptHeroReportIssues_Mutation_Response>
  /** delete single row from the table: "gptHeroReportIssues" */
  delete_gptHeroReportIssues_by_pk?: Maybe<GptHeroReportIssues>
  /** delete data from the table: "gptHeroTopics" */
  delete_gptHeroTopics?: Maybe<GptHeroTopics_Mutation_Response>
  /** delete single row from the table: "gptHeroTopics" */
  delete_gptHeroTopics_by_pk?: Maybe<GptHeroTopics>
  /** delete data from the table: "midjourneyImages" */
  delete_midjourneyImages?: Maybe<MidjourneyImages_Mutation_Response>
  /** delete single row from the table: "midjourneyImages" */
  delete_midjourneyImages_by_pk?: Maybe<MidjourneyImages>
  /** delete data from the table: "newebpayEpgNotifications" */
  delete_newebpayEpgNotifications?: Maybe<NewebpayEpgNotifications_Mutation_Response>
  /** delete single row from the table: "newebpayEpgNotifications" */
  delete_newebpayEpgNotifications_by_pk?: Maybe<NewebpayEpgNotifications>
  /** delete data from the table: "promptUsages" */
  delete_promptUsages?: Maybe<PromptUsages_Mutation_Response>
  /** delete single row from the table: "promptUsages" */
  delete_promptUsages_by_pk?: Maybe<PromptUsages>
  /** delete data from the table: "stripeWebhookLogs" */
  delete_stripeWebhookLogs?: Maybe<StripeWebhookLogs_Mutation_Response>
  /** delete single row from the table: "stripeWebhookLogs" */
  delete_stripeWebhookLogs_by_pk?: Maybe<StripeWebhookLogs>
  /** delete data from the table: "subscriptionItems" */
  delete_subscriptionItems?: Maybe<SubscriptionItems_Mutation_Response>
  /** delete single row from the table: "subscriptionItems" */
  delete_subscriptionItems_by_pk?: Maybe<SubscriptionItems>
  /** delete data from the table: "subscriptions" */
  delete_subscriptions?: Maybe<Subscriptions_Mutation_Response>
  /** delete single row from the table: "subscriptions" */
  delete_subscriptions_by_pk?: Maybe<Subscriptions>
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>
  /** delete data from the table: "writersoftUsageReports" */
  delete_writersoftUsageReports?: Maybe<WritersoftUsageReports_Mutation_Response>
  /** delete single row from the table: "writersoftUsageReports" */
  delete_writersoftUsageReports_by_pk?: Maybe<WritersoftUsageReports>
  /** gptHeroDeletePrompt */
  gptHeroDeletePrompt?: Maybe<DeletePromptResponse>
  /** gptHeroDislikePrompt */
  gptHeroDislikePrompt?: Maybe<DislikePromptResponse>
  /** gptHeroLikePrompt */
  gptHeroLikePrompt?: Maybe<LikePromptResponse>
  /** gptHeroSeenPrompt */
  gptHeroSeenPrompt?: Maybe<SeenPromptResponse>
  /** insert data into the table: "aICompletionRecords" */
  insert_aICompletionRecords?: Maybe<AICompletionRecords_Mutation_Response>
  /** insert a single row into the table: "aICompletionRecords" */
  insert_aICompletionRecords_one?: Maybe<AICompletionRecords>
  /** insert data into the table: "apps" */
  insert_apps?: Maybe<Apps_Mutation_Response>
  /** insert a single row into the table: "apps" */
  insert_apps_one?: Maybe<Apps>
  /** insert data into the table: "discordMessages" */
  insert_discordMessages?: Maybe<DiscordMessages_Mutation_Response>
  /** insert a single row into the table: "discordMessages" */
  insert_discordMessages_one?: Maybe<DiscordMessages>
  /** insert data into the table: "formAnswers" */
  insert_formAnswers?: Maybe<FormAnswers_Mutation_Response>
  /** insert a single row into the table: "formAnswers" */
  insert_formAnswers_one?: Maybe<FormAnswers>
  /** insert data into the table: "forms" */
  insert_forms?: Maybe<Forms_Mutation_Response>
  /** insert a single row into the table: "forms" */
  insert_forms_one?: Maybe<Forms>
  /** insert data into the table: "gptHeroAccounts" */
  insert_gptHeroAccounts?: Maybe<GptHeroAccounts_Mutation_Response>
  /** insert a single row into the table: "gptHeroAccounts" */
  insert_gptHeroAccounts_one?: Maybe<GptHeroAccounts>
  /** insert data into the table: "gptHeroPromptUsages" */
  insert_gptHeroPromptUsages?: Maybe<GptHeroPromptUsages_Mutation_Response>
  /** insert a single row into the table: "gptHeroPromptUsages" */
  insert_gptHeroPromptUsages_one?: Maybe<GptHeroPromptUsages>
  /** insert data into the table: "gptHeroPrompts" */
  insert_gptHeroPrompts?: Maybe<GptHeroPrompts_Mutation_Response>
  /** insert a single row into the table: "gptHeroPrompts" */
  insert_gptHeroPrompts_one?: Maybe<GptHeroPrompts>
  /** insert data into the table: "gptHeroReportIssues" */
  insert_gptHeroReportIssues?: Maybe<GptHeroReportIssues_Mutation_Response>
  /** insert a single row into the table: "gptHeroReportIssues" */
  insert_gptHeroReportIssues_one?: Maybe<GptHeroReportIssues>
  /** insert data into the table: "gptHeroTopics" */
  insert_gptHeroTopics?: Maybe<GptHeroTopics_Mutation_Response>
  /** insert a single row into the table: "gptHeroTopics" */
  insert_gptHeroTopics_one?: Maybe<GptHeroTopics>
  /** insert data into the table: "midjourneyImages" */
  insert_midjourneyImages?: Maybe<MidjourneyImages_Mutation_Response>
  /** insert a single row into the table: "midjourneyImages" */
  insert_midjourneyImages_one?: Maybe<MidjourneyImages>
  /** insert data into the table: "newebpayEpgNotifications" */
  insert_newebpayEpgNotifications?: Maybe<NewebpayEpgNotifications_Mutation_Response>
  /** insert a single row into the table: "newebpayEpgNotifications" */
  insert_newebpayEpgNotifications_one?: Maybe<NewebpayEpgNotifications>
  /** insert data into the table: "promptUsages" */
  insert_promptUsages?: Maybe<PromptUsages_Mutation_Response>
  /** insert a single row into the table: "promptUsages" */
  insert_promptUsages_one?: Maybe<PromptUsages>
  /** insert data into the table: "stripeWebhookLogs" */
  insert_stripeWebhookLogs?: Maybe<StripeWebhookLogs_Mutation_Response>
  /** insert a single row into the table: "stripeWebhookLogs" */
  insert_stripeWebhookLogs_one?: Maybe<StripeWebhookLogs>
  /** insert data into the table: "subscriptionItems" */
  insert_subscriptionItems?: Maybe<SubscriptionItems_Mutation_Response>
  /** insert a single row into the table: "subscriptionItems" */
  insert_subscriptionItems_one?: Maybe<SubscriptionItems>
  /** insert data into the table: "subscriptions" */
  insert_subscriptions?: Maybe<Subscriptions_Mutation_Response>
  /** insert a single row into the table: "subscriptions" */
  insert_subscriptions_one?: Maybe<Subscriptions>
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>
  /** insert data into the table: "writersoftUsageReports" */
  insert_writersoftUsageReports?: Maybe<WritersoftUsageReports_Mutation_Response>
  /** insert a single row into the table: "writersoftUsageReports" */
  insert_writersoftUsageReports_one?: Maybe<WritersoftUsageReports>
  updateList: Scalars['Boolean']
  updatePromptForChromeExtensionPrompt: Scalars['Boolean']
  /** update data of the table: "aICompletionRecords" */
  update_aICompletionRecords?: Maybe<AICompletionRecords_Mutation_Response>
  /** update single row of the table: "aICompletionRecords" */
  update_aICompletionRecords_by_pk?: Maybe<AICompletionRecords>
  /** update multiples rows of table: "aICompletionRecords" */
  update_aICompletionRecords_many?: Maybe<Array<Maybe<AICompletionRecords_Mutation_Response>>>
  /** update data of the table: "apps" */
  update_apps?: Maybe<Apps_Mutation_Response>
  /** update single row of the table: "apps" */
  update_apps_by_pk?: Maybe<Apps>
  /** update multiples rows of table: "apps" */
  update_apps_many?: Maybe<Array<Maybe<Apps_Mutation_Response>>>
  /** update data of the table: "discordMessages" */
  update_discordMessages?: Maybe<DiscordMessages_Mutation_Response>
  /** update single row of the table: "discordMessages" */
  update_discordMessages_by_pk?: Maybe<DiscordMessages>
  /** update multiples rows of table: "discordMessages" */
  update_discordMessages_many?: Maybe<Array<Maybe<DiscordMessages_Mutation_Response>>>
  /** update data of the table: "formAnswers" */
  update_formAnswers?: Maybe<FormAnswers_Mutation_Response>
  /** update single row of the table: "formAnswers" */
  update_formAnswers_by_pk?: Maybe<FormAnswers>
  /** update multiples rows of table: "formAnswers" */
  update_formAnswers_many?: Maybe<Array<Maybe<FormAnswers_Mutation_Response>>>
  /** update data of the table: "forms" */
  update_forms?: Maybe<Forms_Mutation_Response>
  /** update single row of the table: "forms" */
  update_forms_by_pk?: Maybe<Forms>
  /** update multiples rows of table: "forms" */
  update_forms_many?: Maybe<Array<Maybe<Forms_Mutation_Response>>>
  /** update data of the table: "gptHeroAccounts" */
  update_gptHeroAccounts?: Maybe<GptHeroAccounts_Mutation_Response>
  /** update single row of the table: "gptHeroAccounts" */
  update_gptHeroAccounts_by_pk?: Maybe<GptHeroAccounts>
  /** update multiples rows of table: "gptHeroAccounts" */
  update_gptHeroAccounts_many?: Maybe<Array<Maybe<GptHeroAccounts_Mutation_Response>>>
  /** update data of the table: "gptHeroPromptUsages" */
  update_gptHeroPromptUsages?: Maybe<GptHeroPromptUsages_Mutation_Response>
  /** update single row of the table: "gptHeroPromptUsages" */
  update_gptHeroPromptUsages_by_pk?: Maybe<GptHeroPromptUsages>
  /** update multiples rows of table: "gptHeroPromptUsages" */
  update_gptHeroPromptUsages_many?: Maybe<Array<Maybe<GptHeroPromptUsages_Mutation_Response>>>
  /** update data of the table: "gptHeroPrompts" */
  update_gptHeroPrompts?: Maybe<GptHeroPrompts_Mutation_Response>
  /** update single row of the table: "gptHeroPrompts" */
  update_gptHeroPrompts_by_pk?: Maybe<GptHeroPrompts>
  /** update multiples rows of table: "gptHeroPrompts" */
  update_gptHeroPrompts_many?: Maybe<Array<Maybe<GptHeroPrompts_Mutation_Response>>>
  /** update data of the table: "gptHeroReportIssues" */
  update_gptHeroReportIssues?: Maybe<GptHeroReportIssues_Mutation_Response>
  /** update single row of the table: "gptHeroReportIssues" */
  update_gptHeroReportIssues_by_pk?: Maybe<GptHeroReportIssues>
  /** update multiples rows of table: "gptHeroReportIssues" */
  update_gptHeroReportIssues_many?: Maybe<Array<Maybe<GptHeroReportIssues_Mutation_Response>>>
  /** update data of the table: "gptHeroTopics" */
  update_gptHeroTopics?: Maybe<GptHeroTopics_Mutation_Response>
  /** update single row of the table: "gptHeroTopics" */
  update_gptHeroTopics_by_pk?: Maybe<GptHeroTopics>
  /** update multiples rows of table: "gptHeroTopics" */
  update_gptHeroTopics_many?: Maybe<Array<Maybe<GptHeroTopics_Mutation_Response>>>
  /** update data of the table: "midjourneyImages" */
  update_midjourneyImages?: Maybe<MidjourneyImages_Mutation_Response>
  /** update single row of the table: "midjourneyImages" */
  update_midjourneyImages_by_pk?: Maybe<MidjourneyImages>
  /** update multiples rows of table: "midjourneyImages" */
  update_midjourneyImages_many?: Maybe<Array<Maybe<MidjourneyImages_Mutation_Response>>>
  /** update data of the table: "newebpayEpgNotifications" */
  update_newebpayEpgNotifications?: Maybe<NewebpayEpgNotifications_Mutation_Response>
  /** update single row of the table: "newebpayEpgNotifications" */
  update_newebpayEpgNotifications_by_pk?: Maybe<NewebpayEpgNotifications>
  /** update multiples rows of table: "newebpayEpgNotifications" */
  update_newebpayEpgNotifications_many?: Maybe<
    Array<Maybe<NewebpayEpgNotifications_Mutation_Response>>
  >
  /** update data of the table: "promptUsages" */
  update_promptUsages?: Maybe<PromptUsages_Mutation_Response>
  /** update single row of the table: "promptUsages" */
  update_promptUsages_by_pk?: Maybe<PromptUsages>
  /** update multiples rows of table: "promptUsages" */
  update_promptUsages_many?: Maybe<Array<Maybe<PromptUsages_Mutation_Response>>>
  /** update data of the table: "stripeWebhookLogs" */
  update_stripeWebhookLogs?: Maybe<StripeWebhookLogs_Mutation_Response>
  /** update single row of the table: "stripeWebhookLogs" */
  update_stripeWebhookLogs_by_pk?: Maybe<StripeWebhookLogs>
  /** update multiples rows of table: "stripeWebhookLogs" */
  update_stripeWebhookLogs_many?: Maybe<Array<Maybe<StripeWebhookLogs_Mutation_Response>>>
  /** update data of the table: "subscriptionItems" */
  update_subscriptionItems?: Maybe<SubscriptionItems_Mutation_Response>
  /** update single row of the table: "subscriptionItems" */
  update_subscriptionItems_by_pk?: Maybe<SubscriptionItems>
  /** update multiples rows of table: "subscriptionItems" */
  update_subscriptionItems_many?: Maybe<Array<Maybe<SubscriptionItems_Mutation_Response>>>
  /** update data of the table: "subscriptions" */
  update_subscriptions?: Maybe<Subscriptions_Mutation_Response>
  /** update single row of the table: "subscriptions" */
  update_subscriptions_by_pk?: Maybe<Subscriptions>
  /** update multiples rows of table: "subscriptions" */
  update_subscriptions_many?: Maybe<Array<Maybe<Subscriptions_Mutation_Response>>>
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>
  /** update data of the table: "writersoftUsageReports" */
  update_writersoftUsageReports?: Maybe<WritersoftUsageReports_Mutation_Response>
  /** update single row of the table: "writersoftUsageReports" */
  update_writersoftUsageReports_by_pk?: Maybe<WritersoftUsageReports>
  /** update multiples rows of table: "writersoftUsageReports" */
  update_writersoftUsageReports_many?: Maybe<Array<Maybe<WritersoftUsageReports_Mutation_Response>>>
}

/** mutation root */
export type Mutation_RootDelete_AiCompletionRecordsArgs = {
  where: AICompletionRecords_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_AiCompletionRecords_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_AppsArgs = {
  where: Apps_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Apps_By_PkArgs = {
  id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_DiscordMessagesArgs = {
  where: DiscordMessages_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_DiscordMessages_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_FormAnswersArgs = {
  where: FormAnswers_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_FormAnswers_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_FormsArgs = {
  where: Forms_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Forms_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_GptHeroAccountsArgs = {
  where: GptHeroAccounts_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_GptHeroAccounts_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_GptHeroPromptUsagesArgs = {
  where: GptHeroPromptUsages_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_GptHeroPromptUsages_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_GptHeroPromptsArgs = {
  where: GptHeroPrompts_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_GptHeroPrompts_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_GptHeroReportIssuesArgs = {
  where: GptHeroReportIssues_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_GptHeroReportIssues_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_GptHeroTopicsArgs = {
  where: GptHeroTopics_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_GptHeroTopics_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_MidjourneyImagesArgs = {
  where: MidjourneyImages_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_MidjourneyImages_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_NewebpayEpgNotificationsArgs = {
  where: NewebpayEpgNotifications_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_NewebpayEpgNotifications_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_PromptUsagesArgs = {
  where: PromptUsages_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_PromptUsages_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_StripeWebhookLogsArgs = {
  where: StripeWebhookLogs_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_StripeWebhookLogs_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_SubscriptionItemsArgs = {
  where: SubscriptionItems_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_SubscriptionItems_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_SubscriptionsArgs = {
  where: Subscriptions_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Subscriptions_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_WritersoftUsageReportsArgs = {
  where: WritersoftUsageReports_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_WritersoftUsageReports_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootGptHeroDeletePromptArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootGptHeroDislikePromptArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootGptHeroLikePromptArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootGptHeroSeenPromptArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootInsert_AiCompletionRecordsArgs = {
  objects: Array<AICompletionRecords_Insert_Input>
  on_conflict?: InputMaybe<AICompletionRecords_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_AiCompletionRecords_OneArgs = {
  object: AICompletionRecords_Insert_Input
  on_conflict?: InputMaybe<AICompletionRecords_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_AppsArgs = {
  objects: Array<Apps_Insert_Input>
  on_conflict?: InputMaybe<Apps_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Apps_OneArgs = {
  object: Apps_Insert_Input
  on_conflict?: InputMaybe<Apps_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_DiscordMessagesArgs = {
  objects: Array<DiscordMessages_Insert_Input>
  on_conflict?: InputMaybe<DiscordMessages_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_DiscordMessages_OneArgs = {
  object: DiscordMessages_Insert_Input
  on_conflict?: InputMaybe<DiscordMessages_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_FormAnswersArgs = {
  objects: Array<FormAnswers_Insert_Input>
  on_conflict?: InputMaybe<FormAnswers_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_FormAnswers_OneArgs = {
  object: FormAnswers_Insert_Input
  on_conflict?: InputMaybe<FormAnswers_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_FormsArgs = {
  objects: Array<Forms_Insert_Input>
  on_conflict?: InputMaybe<Forms_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Forms_OneArgs = {
  object: Forms_Insert_Input
  on_conflict?: InputMaybe<Forms_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_GptHeroAccountsArgs = {
  objects: Array<GptHeroAccounts_Insert_Input>
  on_conflict?: InputMaybe<GptHeroAccounts_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_GptHeroAccounts_OneArgs = {
  object: GptHeroAccounts_Insert_Input
  on_conflict?: InputMaybe<GptHeroAccounts_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_GptHeroPromptUsagesArgs = {
  objects: Array<GptHeroPromptUsages_Insert_Input>
  on_conflict?: InputMaybe<GptHeroPromptUsages_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_GptHeroPromptUsages_OneArgs = {
  object: GptHeroPromptUsages_Insert_Input
  on_conflict?: InputMaybe<GptHeroPromptUsages_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_GptHeroPromptsArgs = {
  objects: Array<GptHeroPrompts_Insert_Input>
  on_conflict?: InputMaybe<GptHeroPrompts_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_GptHeroPrompts_OneArgs = {
  object: GptHeroPrompts_Insert_Input
  on_conflict?: InputMaybe<GptHeroPrompts_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_GptHeroReportIssuesArgs = {
  objects: Array<GptHeroReportIssues_Insert_Input>
  on_conflict?: InputMaybe<GptHeroReportIssues_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_GptHeroReportIssues_OneArgs = {
  object: GptHeroReportIssues_Insert_Input
  on_conflict?: InputMaybe<GptHeroReportIssues_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_GptHeroTopicsArgs = {
  objects: Array<GptHeroTopics_Insert_Input>
  on_conflict?: InputMaybe<GptHeroTopics_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_GptHeroTopics_OneArgs = {
  object: GptHeroTopics_Insert_Input
  on_conflict?: InputMaybe<GptHeroTopics_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_MidjourneyImagesArgs = {
  objects: Array<MidjourneyImages_Insert_Input>
  on_conflict?: InputMaybe<MidjourneyImages_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_MidjourneyImages_OneArgs = {
  object: MidjourneyImages_Insert_Input
  on_conflict?: InputMaybe<MidjourneyImages_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_NewebpayEpgNotificationsArgs = {
  objects: Array<NewebpayEpgNotifications_Insert_Input>
  on_conflict?: InputMaybe<NewebpayEpgNotifications_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_NewebpayEpgNotifications_OneArgs = {
  object: NewebpayEpgNotifications_Insert_Input
  on_conflict?: InputMaybe<NewebpayEpgNotifications_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_PromptUsagesArgs = {
  objects: Array<PromptUsages_Insert_Input>
  on_conflict?: InputMaybe<PromptUsages_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_PromptUsages_OneArgs = {
  object: PromptUsages_Insert_Input
  on_conflict?: InputMaybe<PromptUsages_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_StripeWebhookLogsArgs = {
  objects: Array<StripeWebhookLogs_Insert_Input>
  on_conflict?: InputMaybe<StripeWebhookLogs_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_StripeWebhookLogs_OneArgs = {
  object: StripeWebhookLogs_Insert_Input
  on_conflict?: InputMaybe<StripeWebhookLogs_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_SubscriptionItemsArgs = {
  objects: Array<SubscriptionItems_Insert_Input>
  on_conflict?: InputMaybe<SubscriptionItems_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_SubscriptionItems_OneArgs = {
  object: SubscriptionItems_Insert_Input
  on_conflict?: InputMaybe<SubscriptionItems_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_SubscriptionsArgs = {
  objects: Array<Subscriptions_Insert_Input>
  on_conflict?: InputMaybe<Subscriptions_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Subscriptions_OneArgs = {
  object: Subscriptions_Insert_Input
  on_conflict?: InputMaybe<Subscriptions_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>
  on_conflict?: InputMaybe<Users_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input
  on_conflict?: InputMaybe<Users_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_WritersoftUsageReportsArgs = {
  objects: Array<WritersoftUsageReports_Insert_Input>
  on_conflict?: InputMaybe<WritersoftUsageReports_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_WritersoftUsageReports_OneArgs = {
  object: WritersoftUsageReports_Insert_Input
  on_conflict?: InputMaybe<WritersoftUsageReports_On_Conflict>
}

/** mutation root */
export type Mutation_RootUpdate_AiCompletionRecordsArgs = {
  _append?: InputMaybe<AICompletionRecords_Append_Input>
  _delete_at_path?: InputMaybe<AICompletionRecords_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<AICompletionRecords_Delete_Elem_Input>
  _delete_key?: InputMaybe<AICompletionRecords_Delete_Key_Input>
  _prepend?: InputMaybe<AICompletionRecords_Prepend_Input>
  _set?: InputMaybe<AICompletionRecords_Set_Input>
  where: AICompletionRecords_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_AiCompletionRecords_By_PkArgs = {
  _append?: InputMaybe<AICompletionRecords_Append_Input>
  _delete_at_path?: InputMaybe<AICompletionRecords_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<AICompletionRecords_Delete_Elem_Input>
  _delete_key?: InputMaybe<AICompletionRecords_Delete_Key_Input>
  _prepend?: InputMaybe<AICompletionRecords_Prepend_Input>
  _set?: InputMaybe<AICompletionRecords_Set_Input>
  pk_columns: AICompletionRecords_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_AiCompletionRecords_ManyArgs = {
  updates: Array<AICompletionRecords_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_AppsArgs = {
  _set?: InputMaybe<Apps_Set_Input>
  where: Apps_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Apps_By_PkArgs = {
  _set?: InputMaybe<Apps_Set_Input>
  pk_columns: Apps_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Apps_ManyArgs = {
  updates: Array<Apps_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_DiscordMessagesArgs = {
  _append?: InputMaybe<DiscordMessages_Append_Input>
  _delete_at_path?: InputMaybe<DiscordMessages_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<DiscordMessages_Delete_Elem_Input>
  _delete_key?: InputMaybe<DiscordMessages_Delete_Key_Input>
  _prepend?: InputMaybe<DiscordMessages_Prepend_Input>
  _set?: InputMaybe<DiscordMessages_Set_Input>
  where: DiscordMessages_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_DiscordMessages_By_PkArgs = {
  _append?: InputMaybe<DiscordMessages_Append_Input>
  _delete_at_path?: InputMaybe<DiscordMessages_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<DiscordMessages_Delete_Elem_Input>
  _delete_key?: InputMaybe<DiscordMessages_Delete_Key_Input>
  _prepend?: InputMaybe<DiscordMessages_Prepend_Input>
  _set?: InputMaybe<DiscordMessages_Set_Input>
  pk_columns: DiscordMessages_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_DiscordMessages_ManyArgs = {
  updates: Array<DiscordMessages_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_FormAnswersArgs = {
  _set?: InputMaybe<FormAnswers_Set_Input>
  where: FormAnswers_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_FormAnswers_By_PkArgs = {
  _set?: InputMaybe<FormAnswers_Set_Input>
  pk_columns: FormAnswers_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_FormAnswers_ManyArgs = {
  updates: Array<FormAnswers_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_FormsArgs = {
  _set?: InputMaybe<Forms_Set_Input>
  where: Forms_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Forms_By_PkArgs = {
  _set?: InputMaybe<Forms_Set_Input>
  pk_columns: Forms_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Forms_ManyArgs = {
  updates: Array<Forms_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_GptHeroAccountsArgs = {
  _set?: InputMaybe<GptHeroAccounts_Set_Input>
  where: GptHeroAccounts_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_GptHeroAccounts_By_PkArgs = {
  _set?: InputMaybe<GptHeroAccounts_Set_Input>
  pk_columns: GptHeroAccounts_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_GptHeroAccounts_ManyArgs = {
  updates: Array<GptHeroAccounts_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_GptHeroPromptUsagesArgs = {
  _set?: InputMaybe<GptHeroPromptUsages_Set_Input>
  where: GptHeroPromptUsages_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_GptHeroPromptUsages_By_PkArgs = {
  _set?: InputMaybe<GptHeroPromptUsages_Set_Input>
  pk_columns: GptHeroPromptUsages_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_GptHeroPromptUsages_ManyArgs = {
  updates: Array<GptHeroPromptUsages_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_GptHeroPromptsArgs = {
  _inc?: InputMaybe<GptHeroPrompts_Inc_Input>
  _set?: InputMaybe<GptHeroPrompts_Set_Input>
  where: GptHeroPrompts_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_GptHeroPrompts_By_PkArgs = {
  _inc?: InputMaybe<GptHeroPrompts_Inc_Input>
  _set?: InputMaybe<GptHeroPrompts_Set_Input>
  pk_columns: GptHeroPrompts_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_GptHeroPrompts_ManyArgs = {
  updates: Array<GptHeroPrompts_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_GptHeroReportIssuesArgs = {
  _set?: InputMaybe<GptHeroReportIssues_Set_Input>
  where: GptHeroReportIssues_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_GptHeroReportIssues_By_PkArgs = {
  _set?: InputMaybe<GptHeroReportIssues_Set_Input>
  pk_columns: GptHeroReportIssues_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_GptHeroReportIssues_ManyArgs = {
  updates: Array<GptHeroReportIssues_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_GptHeroTopicsArgs = {
  _set?: InputMaybe<GptHeroTopics_Set_Input>
  where: GptHeroTopics_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_GptHeroTopics_By_PkArgs = {
  _set?: InputMaybe<GptHeroTopics_Set_Input>
  pk_columns: GptHeroTopics_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_GptHeroTopics_ManyArgs = {
  updates: Array<GptHeroTopics_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_MidjourneyImagesArgs = {
  _inc?: InputMaybe<MidjourneyImages_Inc_Input>
  _set?: InputMaybe<MidjourneyImages_Set_Input>
  where: MidjourneyImages_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_MidjourneyImages_By_PkArgs = {
  _inc?: InputMaybe<MidjourneyImages_Inc_Input>
  _set?: InputMaybe<MidjourneyImages_Set_Input>
  pk_columns: MidjourneyImages_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_MidjourneyImages_ManyArgs = {
  updates: Array<MidjourneyImages_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_NewebpayEpgNotificationsArgs = {
  _append?: InputMaybe<NewebpayEpgNotifications_Append_Input>
  _delete_at_path?: InputMaybe<NewebpayEpgNotifications_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<NewebpayEpgNotifications_Delete_Elem_Input>
  _delete_key?: InputMaybe<NewebpayEpgNotifications_Delete_Key_Input>
  _inc?: InputMaybe<NewebpayEpgNotifications_Inc_Input>
  _prepend?: InputMaybe<NewebpayEpgNotifications_Prepend_Input>
  _set?: InputMaybe<NewebpayEpgNotifications_Set_Input>
  where: NewebpayEpgNotifications_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_NewebpayEpgNotifications_By_PkArgs = {
  _append?: InputMaybe<NewebpayEpgNotifications_Append_Input>
  _delete_at_path?: InputMaybe<NewebpayEpgNotifications_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<NewebpayEpgNotifications_Delete_Elem_Input>
  _delete_key?: InputMaybe<NewebpayEpgNotifications_Delete_Key_Input>
  _inc?: InputMaybe<NewebpayEpgNotifications_Inc_Input>
  _prepend?: InputMaybe<NewebpayEpgNotifications_Prepend_Input>
  _set?: InputMaybe<NewebpayEpgNotifications_Set_Input>
  pk_columns: NewebpayEpgNotifications_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_NewebpayEpgNotifications_ManyArgs = {
  updates: Array<NewebpayEpgNotifications_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_PromptUsagesArgs = {
  _append?: InputMaybe<PromptUsages_Append_Input>
  _delete_at_path?: InputMaybe<PromptUsages_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<PromptUsages_Delete_Elem_Input>
  _delete_key?: InputMaybe<PromptUsages_Delete_Key_Input>
  _inc?: InputMaybe<PromptUsages_Inc_Input>
  _prepend?: InputMaybe<PromptUsages_Prepend_Input>
  _set?: InputMaybe<PromptUsages_Set_Input>
  where: PromptUsages_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_PromptUsages_By_PkArgs = {
  _append?: InputMaybe<PromptUsages_Append_Input>
  _delete_at_path?: InputMaybe<PromptUsages_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<PromptUsages_Delete_Elem_Input>
  _delete_key?: InputMaybe<PromptUsages_Delete_Key_Input>
  _inc?: InputMaybe<PromptUsages_Inc_Input>
  _prepend?: InputMaybe<PromptUsages_Prepend_Input>
  _set?: InputMaybe<PromptUsages_Set_Input>
  pk_columns: PromptUsages_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_PromptUsages_ManyArgs = {
  updates: Array<PromptUsages_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_StripeWebhookLogsArgs = {
  _append?: InputMaybe<StripeWebhookLogs_Append_Input>
  _delete_at_path?: InputMaybe<StripeWebhookLogs_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<StripeWebhookLogs_Delete_Elem_Input>
  _delete_key?: InputMaybe<StripeWebhookLogs_Delete_Key_Input>
  _prepend?: InputMaybe<StripeWebhookLogs_Prepend_Input>
  _set?: InputMaybe<StripeWebhookLogs_Set_Input>
  where: StripeWebhookLogs_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_StripeWebhookLogs_By_PkArgs = {
  _append?: InputMaybe<StripeWebhookLogs_Append_Input>
  _delete_at_path?: InputMaybe<StripeWebhookLogs_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<StripeWebhookLogs_Delete_Elem_Input>
  _delete_key?: InputMaybe<StripeWebhookLogs_Delete_Key_Input>
  _prepend?: InputMaybe<StripeWebhookLogs_Prepend_Input>
  _set?: InputMaybe<StripeWebhookLogs_Set_Input>
  pk_columns: StripeWebhookLogs_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_StripeWebhookLogs_ManyArgs = {
  updates: Array<StripeWebhookLogs_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_SubscriptionItemsArgs = {
  _set?: InputMaybe<SubscriptionItems_Set_Input>
  where: SubscriptionItems_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_SubscriptionItems_By_PkArgs = {
  _set?: InputMaybe<SubscriptionItems_Set_Input>
  pk_columns: SubscriptionItems_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_SubscriptionItems_ManyArgs = {
  updates: Array<SubscriptionItems_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_SubscriptionsArgs = {
  _set?: InputMaybe<Subscriptions_Set_Input>
  where: Subscriptions_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Subscriptions_By_PkArgs = {
  _set?: InputMaybe<Subscriptions_Set_Input>
  pk_columns: Subscriptions_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Subscriptions_ManyArgs = {
  updates: Array<Subscriptions_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: InputMaybe<Users_Set_Input>
  where: Users_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: InputMaybe<Users_Set_Input>
  pk_columns: Users_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_WritersoftUsageReportsArgs = {
  _append?: InputMaybe<WritersoftUsageReports_Append_Input>
  _delete_at_path?: InputMaybe<WritersoftUsageReports_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<WritersoftUsageReports_Delete_Elem_Input>
  _delete_key?: InputMaybe<WritersoftUsageReports_Delete_Key_Input>
  _inc?: InputMaybe<WritersoftUsageReports_Inc_Input>
  _prepend?: InputMaybe<WritersoftUsageReports_Prepend_Input>
  _set?: InputMaybe<WritersoftUsageReports_Set_Input>
  where: WritersoftUsageReports_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_WritersoftUsageReports_By_PkArgs = {
  _append?: InputMaybe<WritersoftUsageReports_Append_Input>
  _delete_at_path?: InputMaybe<WritersoftUsageReports_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<WritersoftUsageReports_Delete_Elem_Input>
  _delete_key?: InputMaybe<WritersoftUsageReports_Delete_Key_Input>
  _inc?: InputMaybe<WritersoftUsageReports_Inc_Input>
  _prepend?: InputMaybe<WritersoftUsageReports_Prepend_Input>
  _set?: InputMaybe<WritersoftUsageReports_Set_Input>
  pk_columns: WritersoftUsageReports_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_WritersoftUsageReports_ManyArgs = {
  updates: Array<WritersoftUsageReports_Updates>
}

/** columns and relationships of "newebpayEpgNotifications" */
export type NewebpayEpgNotifications = {
  __typename?: 'newebpayEpgNotifications'
  amt: Scalars['Int']
  checkCode: Scalars['String']
  consigneeName?: Maybe<Scalars['String']>
  createdAt: Scalars['timestamptz']
  data: Scalars['jsonb']
  id: Scalars['uuid']
  isValid?: Maybe<Scalars['Boolean']>
  merchantId: Scalars['String']
  merchantOrderNo: Scalars['String']
  payerAddr?: Maybe<Scalars['String']>
  payerEmail?: Maybe<Scalars['String']>
  payerName?: Maybe<Scalars['String']>
  payerTel?: Maybe<Scalars['String']>
  paymentType: Scalars['String']
  quantity: Scalars['Int']
  status: Scalars['String']
  tradeNo: Scalars['String']
  unitPrice: Scalars['Int']
  updatedAt: Scalars['timestamptz']
}

/** columns and relationships of "newebpayEpgNotifications" */
export type NewebpayEpgNotificationsDataArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "newebpayEpgNotifications" */
export type NewebpayEpgNotifications_Aggregate = {
  __typename?: 'newebpayEpgNotifications_aggregate'
  aggregate?: Maybe<NewebpayEpgNotifications_Aggregate_Fields>
  nodes: Array<NewebpayEpgNotifications>
}

/** aggregate fields of "newebpayEpgNotifications" */
export type NewebpayEpgNotifications_Aggregate_Fields = {
  __typename?: 'newebpayEpgNotifications_aggregate_fields'
  avg?: Maybe<NewebpayEpgNotifications_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<NewebpayEpgNotifications_Max_Fields>
  min?: Maybe<NewebpayEpgNotifications_Min_Fields>
  stddev?: Maybe<NewebpayEpgNotifications_Stddev_Fields>
  stddev_pop?: Maybe<NewebpayEpgNotifications_Stddev_Pop_Fields>
  stddev_samp?: Maybe<NewebpayEpgNotifications_Stddev_Samp_Fields>
  sum?: Maybe<NewebpayEpgNotifications_Sum_Fields>
  var_pop?: Maybe<NewebpayEpgNotifications_Var_Pop_Fields>
  var_samp?: Maybe<NewebpayEpgNotifications_Var_Samp_Fields>
  variance?: Maybe<NewebpayEpgNotifications_Variance_Fields>
}

/** aggregate fields of "newebpayEpgNotifications" */
export type NewebpayEpgNotifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<NewebpayEpgNotifications_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type NewebpayEpgNotifications_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>
}

/** aggregate avg on columns */
export type NewebpayEpgNotifications_Avg_Fields = {
  __typename?: 'newebpayEpgNotifications_avg_fields'
  amt?: Maybe<Scalars['Float']>
  quantity?: Maybe<Scalars['Float']>
  unitPrice?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "newebpayEpgNotifications". All fields are combined with a logical 'AND'. */
export type NewebpayEpgNotifications_Bool_Exp = {
  _and?: InputMaybe<Array<NewebpayEpgNotifications_Bool_Exp>>
  _not?: InputMaybe<NewebpayEpgNotifications_Bool_Exp>
  _or?: InputMaybe<Array<NewebpayEpgNotifications_Bool_Exp>>
  amt?: InputMaybe<Int_Comparison_Exp>
  checkCode?: InputMaybe<String_Comparison_Exp>
  consigneeName?: InputMaybe<String_Comparison_Exp>
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>
  data?: InputMaybe<Jsonb_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  isValid?: InputMaybe<Boolean_Comparison_Exp>
  merchantId?: InputMaybe<String_Comparison_Exp>
  merchantOrderNo?: InputMaybe<String_Comparison_Exp>
  payerAddr?: InputMaybe<String_Comparison_Exp>
  payerEmail?: InputMaybe<String_Comparison_Exp>
  payerName?: InputMaybe<String_Comparison_Exp>
  payerTel?: InputMaybe<String_Comparison_Exp>
  paymentType?: InputMaybe<String_Comparison_Exp>
  quantity?: InputMaybe<Int_Comparison_Exp>
  status?: InputMaybe<String_Comparison_Exp>
  tradeNo?: InputMaybe<String_Comparison_Exp>
  unitPrice?: InputMaybe<Int_Comparison_Exp>
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "newebpayEpgNotifications" */
export enum NewebpayEpgNotifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  NewebpayEpgNotificationsPkey = 'newebpayEpgNotifications_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type NewebpayEpgNotifications_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type NewebpayEpgNotifications_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type NewebpayEpgNotifications_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "newebpayEpgNotifications" */
export type NewebpayEpgNotifications_Inc_Input = {
  amt?: InputMaybe<Scalars['Int']>
  quantity?: InputMaybe<Scalars['Int']>
  unitPrice?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "newebpayEpgNotifications" */
export type NewebpayEpgNotifications_Insert_Input = {
  amt?: InputMaybe<Scalars['Int']>
  checkCode?: InputMaybe<Scalars['String']>
  consigneeName?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  data?: InputMaybe<Scalars['jsonb']>
  id?: InputMaybe<Scalars['uuid']>
  isValid?: InputMaybe<Scalars['Boolean']>
  merchantId?: InputMaybe<Scalars['String']>
  merchantOrderNo?: InputMaybe<Scalars['String']>
  payerAddr?: InputMaybe<Scalars['String']>
  payerEmail?: InputMaybe<Scalars['String']>
  payerName?: InputMaybe<Scalars['String']>
  payerTel?: InputMaybe<Scalars['String']>
  paymentType?: InputMaybe<Scalars['String']>
  quantity?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<Scalars['String']>
  tradeNo?: InputMaybe<Scalars['String']>
  unitPrice?: InputMaybe<Scalars['Int']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type NewebpayEpgNotifications_Max_Fields = {
  __typename?: 'newebpayEpgNotifications_max_fields'
  amt?: Maybe<Scalars['Int']>
  checkCode?: Maybe<Scalars['String']>
  consigneeName?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  merchantId?: Maybe<Scalars['String']>
  merchantOrderNo?: Maybe<Scalars['String']>
  payerAddr?: Maybe<Scalars['String']>
  payerEmail?: Maybe<Scalars['String']>
  payerName?: Maybe<Scalars['String']>
  payerTel?: Maybe<Scalars['String']>
  paymentType?: Maybe<Scalars['String']>
  quantity?: Maybe<Scalars['Int']>
  status?: Maybe<Scalars['String']>
  tradeNo?: Maybe<Scalars['String']>
  unitPrice?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type NewebpayEpgNotifications_Min_Fields = {
  __typename?: 'newebpayEpgNotifications_min_fields'
  amt?: Maybe<Scalars['Int']>
  checkCode?: Maybe<Scalars['String']>
  consigneeName?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  merchantId?: Maybe<Scalars['String']>
  merchantOrderNo?: Maybe<Scalars['String']>
  payerAddr?: Maybe<Scalars['String']>
  payerEmail?: Maybe<Scalars['String']>
  payerName?: Maybe<Scalars['String']>
  payerTel?: Maybe<Scalars['String']>
  paymentType?: Maybe<Scalars['String']>
  quantity?: Maybe<Scalars['Int']>
  status?: Maybe<Scalars['String']>
  tradeNo?: Maybe<Scalars['String']>
  unitPrice?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "newebpayEpgNotifications" */
export type NewebpayEpgNotifications_Mutation_Response = {
  __typename?: 'newebpayEpgNotifications_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<NewebpayEpgNotifications>
}

/** on_conflict condition type for table "newebpayEpgNotifications" */
export type NewebpayEpgNotifications_On_Conflict = {
  constraint: NewebpayEpgNotifications_Constraint
  update_columns?: Array<NewebpayEpgNotifications_Update_Column>
  where?: InputMaybe<NewebpayEpgNotifications_Bool_Exp>
}

/** Ordering options when selecting data from "newebpayEpgNotifications". */
export type NewebpayEpgNotifications_Order_By = {
  amt?: InputMaybe<Order_By>
  checkCode?: InputMaybe<Order_By>
  consigneeName?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  data?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  isValid?: InputMaybe<Order_By>
  merchantId?: InputMaybe<Order_By>
  merchantOrderNo?: InputMaybe<Order_By>
  payerAddr?: InputMaybe<Order_By>
  payerEmail?: InputMaybe<Order_By>
  payerName?: InputMaybe<Order_By>
  payerTel?: InputMaybe<Order_By>
  paymentType?: InputMaybe<Order_By>
  quantity?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  tradeNo?: InputMaybe<Order_By>
  unitPrice?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** primary key columns input for table: newebpayEpgNotifications */
export type NewebpayEpgNotifications_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type NewebpayEpgNotifications_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "newebpayEpgNotifications" */
export enum NewebpayEpgNotifications_Select_Column {
  /** column name */
  Amt = 'amt',
  /** column name */
  CheckCode = 'checkCode',
  /** column name */
  ConsigneeName = 'consigneeName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  IsValid = 'isValid',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  MerchantOrderNo = 'merchantOrderNo',
  /** column name */
  PayerAddr = 'payerAddr',
  /** column name */
  PayerEmail = 'payerEmail',
  /** column name */
  PayerName = 'payerName',
  /** column name */
  PayerTel = 'payerTel',
  /** column name */
  PaymentType = 'paymentType',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Status = 'status',
  /** column name */
  TradeNo = 'tradeNo',
  /** column name */
  UnitPrice = 'unitPrice',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "newebpayEpgNotifications" */
export type NewebpayEpgNotifications_Set_Input = {
  amt?: InputMaybe<Scalars['Int']>
  checkCode?: InputMaybe<Scalars['String']>
  consigneeName?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  data?: InputMaybe<Scalars['jsonb']>
  id?: InputMaybe<Scalars['uuid']>
  isValid?: InputMaybe<Scalars['Boolean']>
  merchantId?: InputMaybe<Scalars['String']>
  merchantOrderNo?: InputMaybe<Scalars['String']>
  payerAddr?: InputMaybe<Scalars['String']>
  payerEmail?: InputMaybe<Scalars['String']>
  payerName?: InputMaybe<Scalars['String']>
  payerTel?: InputMaybe<Scalars['String']>
  paymentType?: InputMaybe<Scalars['String']>
  quantity?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<Scalars['String']>
  tradeNo?: InputMaybe<Scalars['String']>
  unitPrice?: InputMaybe<Scalars['Int']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type NewebpayEpgNotifications_Stddev_Fields = {
  __typename?: 'newebpayEpgNotifications_stddev_fields'
  amt?: Maybe<Scalars['Float']>
  quantity?: Maybe<Scalars['Float']>
  unitPrice?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type NewebpayEpgNotifications_Stddev_Pop_Fields = {
  __typename?: 'newebpayEpgNotifications_stddev_pop_fields'
  amt?: Maybe<Scalars['Float']>
  quantity?: Maybe<Scalars['Float']>
  unitPrice?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type NewebpayEpgNotifications_Stddev_Samp_Fields = {
  __typename?: 'newebpayEpgNotifications_stddev_samp_fields'
  amt?: Maybe<Scalars['Float']>
  quantity?: Maybe<Scalars['Float']>
  unitPrice?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "newebpayEpgNotifications" */
export type NewebpayEpgNotifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: NewebpayEpgNotifications_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type NewebpayEpgNotifications_Stream_Cursor_Value_Input = {
  amt?: InputMaybe<Scalars['Int']>
  checkCode?: InputMaybe<Scalars['String']>
  consigneeName?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  data?: InputMaybe<Scalars['jsonb']>
  id?: InputMaybe<Scalars['uuid']>
  isValid?: InputMaybe<Scalars['Boolean']>
  merchantId?: InputMaybe<Scalars['String']>
  merchantOrderNo?: InputMaybe<Scalars['String']>
  payerAddr?: InputMaybe<Scalars['String']>
  payerEmail?: InputMaybe<Scalars['String']>
  payerName?: InputMaybe<Scalars['String']>
  payerTel?: InputMaybe<Scalars['String']>
  paymentType?: InputMaybe<Scalars['String']>
  quantity?: InputMaybe<Scalars['Int']>
  status?: InputMaybe<Scalars['String']>
  tradeNo?: InputMaybe<Scalars['String']>
  unitPrice?: InputMaybe<Scalars['Int']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type NewebpayEpgNotifications_Sum_Fields = {
  __typename?: 'newebpayEpgNotifications_sum_fields'
  amt?: Maybe<Scalars['Int']>
  quantity?: Maybe<Scalars['Int']>
  unitPrice?: Maybe<Scalars['Int']>
}

/** update columns of table "newebpayEpgNotifications" */
export enum NewebpayEpgNotifications_Update_Column {
  /** column name */
  Amt = 'amt',
  /** column name */
  CheckCode = 'checkCode',
  /** column name */
  ConsigneeName = 'consigneeName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  IsValid = 'isValid',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  MerchantOrderNo = 'merchantOrderNo',
  /** column name */
  PayerAddr = 'payerAddr',
  /** column name */
  PayerEmail = 'payerEmail',
  /** column name */
  PayerName = 'payerName',
  /** column name */
  PayerTel = 'payerTel',
  /** column name */
  PaymentType = 'paymentType',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Status = 'status',
  /** column name */
  TradeNo = 'tradeNo',
  /** column name */
  UnitPrice = 'unitPrice',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type NewebpayEpgNotifications_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<NewebpayEpgNotifications_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<NewebpayEpgNotifications_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<NewebpayEpgNotifications_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<NewebpayEpgNotifications_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<NewebpayEpgNotifications_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<NewebpayEpgNotifications_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<NewebpayEpgNotifications_Set_Input>
  /** filter the rows which have to be updated */
  where: NewebpayEpgNotifications_Bool_Exp
}

/** aggregate var_pop on columns */
export type NewebpayEpgNotifications_Var_Pop_Fields = {
  __typename?: 'newebpayEpgNotifications_var_pop_fields'
  amt?: Maybe<Scalars['Float']>
  quantity?: Maybe<Scalars['Float']>
  unitPrice?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type NewebpayEpgNotifications_Var_Samp_Fields = {
  __typename?: 'newebpayEpgNotifications_var_samp_fields'
  amt?: Maybe<Scalars['Float']>
  quantity?: Maybe<Scalars['Float']>
  unitPrice?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type NewebpayEpgNotifications_Variance_Fields = {
  __typename?: 'newebpayEpgNotifications_variance_fields'
  amt?: Maybe<Scalars['Float']>
  quantity?: Maybe<Scalars['Float']>
  unitPrice?: Maybe<Scalars['Float']>
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "promptUsages" */
export type PromptUsages = {
  __typename?: 'promptUsages'
  appId?: Maybe<Scalars['String']>
  createdAt: Scalars['timestamptz']
  discordUserId?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  language: Scalars['String']
  model: Scalars['String']
  object: Scalars['String']
  promptId: Scalars['String']
  response: Scalars['jsonb']
  updatedAt: Scalars['timestamptz']
  usageCompletionTokens: Scalars['Int']
  usagePromptTokens: Scalars['Int']
  usageTotalTokens: Scalars['Int']
  userPrompt: Scalars['String']
}

/** columns and relationships of "promptUsages" */
export type PromptUsagesResponseArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "promptUsages" */
export type PromptUsages_Aggregate = {
  __typename?: 'promptUsages_aggregate'
  aggregate?: Maybe<PromptUsages_Aggregate_Fields>
  nodes: Array<PromptUsages>
}

/** aggregate fields of "promptUsages" */
export type PromptUsages_Aggregate_Fields = {
  __typename?: 'promptUsages_aggregate_fields'
  avg?: Maybe<PromptUsages_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<PromptUsages_Max_Fields>
  min?: Maybe<PromptUsages_Min_Fields>
  stddev?: Maybe<PromptUsages_Stddev_Fields>
  stddev_pop?: Maybe<PromptUsages_Stddev_Pop_Fields>
  stddev_samp?: Maybe<PromptUsages_Stddev_Samp_Fields>
  sum?: Maybe<PromptUsages_Sum_Fields>
  var_pop?: Maybe<PromptUsages_Var_Pop_Fields>
  var_samp?: Maybe<PromptUsages_Var_Samp_Fields>
  variance?: Maybe<PromptUsages_Variance_Fields>
}

/** aggregate fields of "promptUsages" */
export type PromptUsages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PromptUsages_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type PromptUsages_Append_Input = {
  response?: InputMaybe<Scalars['jsonb']>
}

/** aggregate avg on columns */
export type PromptUsages_Avg_Fields = {
  __typename?: 'promptUsages_avg_fields'
  usageCompletionTokens?: Maybe<Scalars['Float']>
  usagePromptTokens?: Maybe<Scalars['Float']>
  usageTotalTokens?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "promptUsages". All fields are combined with a logical 'AND'. */
export type PromptUsages_Bool_Exp = {
  _and?: InputMaybe<Array<PromptUsages_Bool_Exp>>
  _not?: InputMaybe<PromptUsages_Bool_Exp>
  _or?: InputMaybe<Array<PromptUsages_Bool_Exp>>
  appId?: InputMaybe<String_Comparison_Exp>
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>
  discordUserId?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  language?: InputMaybe<String_Comparison_Exp>
  model?: InputMaybe<String_Comparison_Exp>
  object?: InputMaybe<String_Comparison_Exp>
  promptId?: InputMaybe<String_Comparison_Exp>
  response?: InputMaybe<Jsonb_Comparison_Exp>
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>
  usageCompletionTokens?: InputMaybe<Int_Comparison_Exp>
  usagePromptTokens?: InputMaybe<Int_Comparison_Exp>
  usageTotalTokens?: InputMaybe<Int_Comparison_Exp>
  userPrompt?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "promptUsages" */
export enum PromptUsages_Constraint {
  /** unique or primary key constraint on columns "id" */
  PromptUsagesPkey = 'promptUsages_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type PromptUsages_Delete_At_Path_Input = {
  response?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type PromptUsages_Delete_Elem_Input = {
  response?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type PromptUsages_Delete_Key_Input = {
  response?: InputMaybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "promptUsages" */
export type PromptUsages_Inc_Input = {
  usageCompletionTokens?: InputMaybe<Scalars['Int']>
  usagePromptTokens?: InputMaybe<Scalars['Int']>
  usageTotalTokens?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "promptUsages" */
export type PromptUsages_Insert_Input = {
  appId?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  discordUserId?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  language?: InputMaybe<Scalars['String']>
  model?: InputMaybe<Scalars['String']>
  object?: InputMaybe<Scalars['String']>
  promptId?: InputMaybe<Scalars['String']>
  response?: InputMaybe<Scalars['jsonb']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  usageCompletionTokens?: InputMaybe<Scalars['Int']>
  usagePromptTokens?: InputMaybe<Scalars['Int']>
  usageTotalTokens?: InputMaybe<Scalars['Int']>
  userPrompt?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type PromptUsages_Max_Fields = {
  __typename?: 'promptUsages_max_fields'
  appId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  discordUserId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  language?: Maybe<Scalars['String']>
  model?: Maybe<Scalars['String']>
  object?: Maybe<Scalars['String']>
  promptId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
  usageCompletionTokens?: Maybe<Scalars['Int']>
  usagePromptTokens?: Maybe<Scalars['Int']>
  usageTotalTokens?: Maybe<Scalars['Int']>
  userPrompt?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type PromptUsages_Min_Fields = {
  __typename?: 'promptUsages_min_fields'
  appId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  discordUserId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  language?: Maybe<Scalars['String']>
  model?: Maybe<Scalars['String']>
  object?: Maybe<Scalars['String']>
  promptId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
  usageCompletionTokens?: Maybe<Scalars['Int']>
  usagePromptTokens?: Maybe<Scalars['Int']>
  usageTotalTokens?: Maybe<Scalars['Int']>
  userPrompt?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "promptUsages" */
export type PromptUsages_Mutation_Response = {
  __typename?: 'promptUsages_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<PromptUsages>
}

/** on_conflict condition type for table "promptUsages" */
export type PromptUsages_On_Conflict = {
  constraint: PromptUsages_Constraint
  update_columns?: Array<PromptUsages_Update_Column>
  where?: InputMaybe<PromptUsages_Bool_Exp>
}

/** Ordering options when selecting data from "promptUsages". */
export type PromptUsages_Order_By = {
  appId?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  discordUserId?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  language?: InputMaybe<Order_By>
  model?: InputMaybe<Order_By>
  object?: InputMaybe<Order_By>
  promptId?: InputMaybe<Order_By>
  response?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
  usageCompletionTokens?: InputMaybe<Order_By>
  usagePromptTokens?: InputMaybe<Order_By>
  usageTotalTokens?: InputMaybe<Order_By>
  userPrompt?: InputMaybe<Order_By>
}

/** primary key columns input for table: promptUsages */
export type PromptUsages_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type PromptUsages_Prepend_Input = {
  response?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "promptUsages" */
export enum PromptUsages_Select_Column {
  /** column name */
  AppId = 'appId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DiscordUserId = 'discordUserId',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Model = 'model',
  /** column name */
  Object = 'object',
  /** column name */
  PromptId = 'promptId',
  /** column name */
  Response = 'response',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UsageCompletionTokens = 'usageCompletionTokens',
  /** column name */
  UsagePromptTokens = 'usagePromptTokens',
  /** column name */
  UsageTotalTokens = 'usageTotalTokens',
  /** column name */
  UserPrompt = 'userPrompt',
}

/** input type for updating data in table "promptUsages" */
export type PromptUsages_Set_Input = {
  appId?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  discordUserId?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  language?: InputMaybe<Scalars['String']>
  model?: InputMaybe<Scalars['String']>
  object?: InputMaybe<Scalars['String']>
  promptId?: InputMaybe<Scalars['String']>
  response?: InputMaybe<Scalars['jsonb']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  usageCompletionTokens?: InputMaybe<Scalars['Int']>
  usagePromptTokens?: InputMaybe<Scalars['Int']>
  usageTotalTokens?: InputMaybe<Scalars['Int']>
  userPrompt?: InputMaybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type PromptUsages_Stddev_Fields = {
  __typename?: 'promptUsages_stddev_fields'
  usageCompletionTokens?: Maybe<Scalars['Float']>
  usagePromptTokens?: Maybe<Scalars['Float']>
  usageTotalTokens?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type PromptUsages_Stddev_Pop_Fields = {
  __typename?: 'promptUsages_stddev_pop_fields'
  usageCompletionTokens?: Maybe<Scalars['Float']>
  usagePromptTokens?: Maybe<Scalars['Float']>
  usageTotalTokens?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type PromptUsages_Stddev_Samp_Fields = {
  __typename?: 'promptUsages_stddev_samp_fields'
  usageCompletionTokens?: Maybe<Scalars['Float']>
  usagePromptTokens?: Maybe<Scalars['Float']>
  usageTotalTokens?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "promptUsages" */
export type PromptUsages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: PromptUsages_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type PromptUsages_Stream_Cursor_Value_Input = {
  appId?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  discordUserId?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  language?: InputMaybe<Scalars['String']>
  model?: InputMaybe<Scalars['String']>
  object?: InputMaybe<Scalars['String']>
  promptId?: InputMaybe<Scalars['String']>
  response?: InputMaybe<Scalars['jsonb']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  usageCompletionTokens?: InputMaybe<Scalars['Int']>
  usagePromptTokens?: InputMaybe<Scalars['Int']>
  usageTotalTokens?: InputMaybe<Scalars['Int']>
  userPrompt?: InputMaybe<Scalars['String']>
}

/** aggregate sum on columns */
export type PromptUsages_Sum_Fields = {
  __typename?: 'promptUsages_sum_fields'
  usageCompletionTokens?: Maybe<Scalars['Int']>
  usagePromptTokens?: Maybe<Scalars['Int']>
  usageTotalTokens?: Maybe<Scalars['Int']>
}

/** update columns of table "promptUsages" */
export enum PromptUsages_Update_Column {
  /** column name */
  AppId = 'appId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DiscordUserId = 'discordUserId',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Model = 'model',
  /** column name */
  Object = 'object',
  /** column name */
  PromptId = 'promptId',
  /** column name */
  Response = 'response',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UsageCompletionTokens = 'usageCompletionTokens',
  /** column name */
  UsagePromptTokens = 'usagePromptTokens',
  /** column name */
  UsageTotalTokens = 'usageTotalTokens',
  /** column name */
  UserPrompt = 'userPrompt',
}

export type PromptUsages_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<PromptUsages_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<PromptUsages_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<PromptUsages_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<PromptUsages_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PromptUsages_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<PromptUsages_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PromptUsages_Set_Input>
  /** filter the rows which have to be updated */
  where: PromptUsages_Bool_Exp
}

/** aggregate var_pop on columns */
export type PromptUsages_Var_Pop_Fields = {
  __typename?: 'promptUsages_var_pop_fields'
  usageCompletionTokens?: Maybe<Scalars['Float']>
  usagePromptTokens?: Maybe<Scalars['Float']>
  usageTotalTokens?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type PromptUsages_Var_Samp_Fields = {
  __typename?: 'promptUsages_var_samp_fields'
  usageCompletionTokens?: Maybe<Scalars['Float']>
  usagePromptTokens?: Maybe<Scalars['Float']>
  usageTotalTokens?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type PromptUsages_Variance_Fields = {
  __typename?: 'promptUsages_variance_fields'
  usageCompletionTokens?: Maybe<Scalars['Float']>
  usagePromptTokens?: Maybe<Scalars['Float']>
  usageTotalTokens?: Maybe<Scalars['Float']>
}

export type Query_Root = {
  __typename?: 'query_root'
  /** fetch data from the table: "aICompletionRecords" */
  aICompletionRecords: Array<AICompletionRecords>
  /** fetch aggregated fields from the table: "aICompletionRecords" */
  aICompletionRecords_aggregate: AICompletionRecords_Aggregate
  /** fetch data from the table: "aICompletionRecords" using primary key columns */
  aICompletionRecords_by_pk?: Maybe<AICompletionRecords>
  /** fetch data from the table: "apps" */
  apps: Array<Apps>
  /** fetch aggregated fields from the table: "apps" */
  apps_aggregate: Apps_Aggregate
  /** fetch data from the table: "apps" using primary key columns */
  apps_by_pk?: Maybe<Apps>
  asset?: Maybe<Asset>
  assetCollection?: Maybe<AssetCollection>
  authors?: Maybe<Authors>
  authorsCollection?: Maybe<AuthorsCollection>
  autoGenArticle?: Maybe<AutoGenArticleResponse>
  autoGenArticleV2?: Maybe<AutoGenArticleResponse>
  autoGenArticleV3ByKeywords?: Maybe<AutoGenArticleResponse>
  autoGenArticleV4ByKeywords?: Maybe<AutoGenArticleResponse>
  blogs?: Maybe<Blogs>
  blogsCollection?: Maybe<BlogsCollection>
  callToActionLink?: Maybe<CallToActionLink>
  callToActionLinkCollection?: Maybe<CallToActionLinkCollection>
  categories?: Maybe<Categories>
  categoriesCollection?: Maybe<CategoriesCollection>
  /** fetch data from the table: "discordMessages" */
  discordMessages: Array<DiscordMessages>
  /** fetch aggregated fields from the table: "discordMessages" */
  discordMessages_aggregate: DiscordMessages_Aggregate
  /** fetch data from the table: "discordMessages" using primary key columns */
  discordMessages_by_pk?: Maybe<DiscordMessages>
  embeddings?: Maybe<Embeddings>
  embeddingsCollection?: Maybe<EmbeddingsCollection>
  entryCollection?: Maybe<EntryCollection>
  erc721TokenMetadata?: Maybe<Erc721TokenMetadata>
  erc721TokenMetadataAttributeTraitType?: Maybe<Erc721TokenMetadataAttributeTraitType>
  erc721TokenMetadataAttributeTraitTypeCollection?: Maybe<Erc721TokenMetadataAttributeTraitTypeCollection>
  erc721TokenMetadataAttributesText?: Maybe<Erc721TokenMetadataAttributesText>
  erc721TokenMetadataAttributesTextCollection?: Maybe<Erc721TokenMetadataAttributesTextCollection>
  erc721TokenMetadataCollection?: Maybe<Erc721TokenMetadataCollection>
  /** An array relationship */
  formAnswers: Array<FormAnswers>
  /** An aggregate relationship */
  formAnswers_aggregate: FormAnswers_Aggregate
  /** fetch data from the table: "formAnswers" using primary key columns */
  formAnswers_by_pk?: Maybe<FormAnswers>
  /** An array relationship */
  forms: Array<Forms>
  /** An aggregate relationship */
  forms_aggregate: Forms_Aggregate
  /** fetch data from the table: "forms" using primary key columns */
  forms_by_pk?: Maybe<Forms>
  generateImage?: Maybe<GenerateImageResponse>
  generatePrompt: PromptResponse
  generatePromptForChromeExtensionPrompt: PromptResponse
  generatePromptWithTags: PromptResponse
  generics: Array<Maybe<GenericResponse>>
  getBlogSectionWriting?: Maybe<Scalars['String']>
  getTextEditingExpand?: Maybe<Scalars['String']>
  getTextEditingImprove?: Maybe<Scalars['String']>
  getTextEditingReword?: Maybe<Scalars['String']>
  getTextEditingShorten?: Maybe<Scalars['String']>
  /** fetch data from the table: "gptHeroAccounts" */
  gptHeroAccounts: Array<GptHeroAccounts>
  /** fetch aggregated fields from the table: "gptHeroAccounts" */
  gptHeroAccounts_aggregate: GptHeroAccounts_Aggregate
  /** fetch data from the table: "gptHeroAccounts" using primary key columns */
  gptHeroAccounts_by_pk?: Maybe<GptHeroAccounts>
  /** An array relationship */
  gptHeroPromptUsages: Array<GptHeroPromptUsages>
  /** An aggregate relationship */
  gptHeroPromptUsages_aggregate: GptHeroPromptUsages_Aggregate
  /** fetch data from the table: "gptHeroPromptUsages" using primary key columns */
  gptHeroPromptUsages_by_pk?: Maybe<GptHeroPromptUsages>
  /** An array relationship */
  gptHeroPrompts: Array<GptHeroPrompts>
  /** An aggregate relationship */
  gptHeroPrompts_aggregate: GptHeroPrompts_Aggregate
  /** fetch data from the table: "gptHeroPrompts" using primary key columns */
  gptHeroPrompts_by_pk?: Maybe<GptHeroPrompts>
  /** An array relationship */
  gptHeroReportIssues: Array<GptHeroReportIssues>
  /** An aggregate relationship */
  gptHeroReportIssues_aggregate: GptHeroReportIssues_Aggregate
  /** fetch data from the table: "gptHeroReportIssues" using primary key columns */
  gptHeroReportIssues_by_pk?: Maybe<GptHeroReportIssues>
  /** fetch data from the table: "gptHeroTopics" */
  gptHeroTopics: Array<GptHeroTopics>
  /** fetch aggregated fields from the table: "gptHeroTopics" */
  gptHeroTopics_aggregate: GptHeroTopics_Aggregate
  /** fetch data from the table: "gptHeroTopics" using primary key columns */
  gptHeroTopics_by_pk?: Maybe<GptHeroTopics>
  /** fetch data from the table: "midjourneyImages" */
  midjourneyImages: Array<MidjourneyImages>
  /** fetch aggregated fields from the table: "midjourneyImages" */
  midjourneyImages_aggregate: MidjourneyImages_Aggregate
  /** fetch data from the table: "midjourneyImages" using primary key columns */
  midjourneyImages_by_pk?: Maybe<MidjourneyImages>
  /** fetch data from the table: "newebpayEpgNotifications" */
  newebpayEpgNotifications: Array<NewebpayEpgNotifications>
  /** fetch aggregated fields from the table: "newebpayEpgNotifications" */
  newebpayEpgNotifications_aggregate: NewebpayEpgNotifications_Aggregate
  /** fetch data from the table: "newebpayEpgNotifications" using primary key columns */
  newebpayEpgNotifications_by_pk?: Maybe<NewebpayEpgNotifications>
  pages?: Maybe<Pages>
  pagesCollection?: Maybe<PagesCollection>
  products?: Maybe<Products>
  productsCollection?: Maybe<ProductsCollection>
  promptEngineering?: Maybe<PromptEngineering>
  promptEngineeringCollection?: Maybe<PromptEngineeringCollection>
  /** fetch data from the table: "promptUsages" */
  promptUsages: Array<PromptUsages>
  /** fetch aggregated fields from the table: "promptUsages" */
  promptUsages_aggregate: PromptUsages_Aggregate
  /** fetch data from the table: "promptUsages" using primary key columns */
  promptUsages_by_pk?: Maybe<PromptUsages>
  prompts: Array<PromptInterface>
  promptsForChromeExtension: Array<ChromeExtensionPromptResponse>
  rootSettings?: Maybe<RootSettings>
  rootSettingsCollection?: Maybe<RootSettingsCollection>
  sectionsDetailPersonComponent?: Maybe<SectionsDetailPersonComponent>
  sectionsDetailPersonComponentCollection?: Maybe<SectionsDetailPersonComponentCollection>
  sectionsDetailPersonList?: Maybe<SectionsDetailPersonList>
  sectionsDetailPersonListCollection?: Maybe<SectionsDetailPersonListCollection>
  sectionsFaqComponent?: Maybe<SectionsFaqComponent>
  sectionsFaqComponentCollection?: Maybe<SectionsFaqComponentCollection>
  sectionsFaqList?: Maybe<SectionsFaqList>
  sectionsFaqListCollection?: Maybe<SectionsFaqListCollection>
  sectionsFeaturesList?: Maybe<SectionsFeaturesList>
  sectionsFeaturesListCollection?: Maybe<SectionsFeaturesListCollection>
  sectionsFeaturesListComponent?: Maybe<SectionsFeaturesListComponent>
  sectionsFeaturesListComponentCollection?: Maybe<SectionsFeaturesListComponentCollection>
  sectionsForm?: Maybe<SectionsForm>
  sectionsFormCollection?: Maybe<SectionsFormCollection>
  sectionsFormComponent?: Maybe<SectionsFormComponent>
  sectionsFormComponentCollection?: Maybe<SectionsFormComponentCollection>
  sectionsHero?: Maybe<SectionsHero>
  sectionsHeroCollection?: Maybe<SectionsHeroCollection>
  sectionsImage?: Maybe<SectionsImage>
  sectionsImageCollection?: Maybe<SectionsImageCollection>
  sectionsMinimalsContactComponent?: Maybe<SectionsMinimalsContactComponent>
  sectionsMinimalsContactComponentCollection?: Maybe<SectionsMinimalsContactComponentCollection>
  sectionsMinimalsContactList?: Maybe<SectionsMinimalsContactList>
  sectionsMinimalsContactListCollection?: Maybe<SectionsMinimalsContactListCollection>
  sectionsMinimalsGallery?: Maybe<SectionsMinimalsGallery>
  sectionsMinimalsGalleryCollection?: Maybe<SectionsMinimalsGalleryCollection>
  sectionsMinimalsHistoryComponent?: Maybe<SectionsMinimalsHistoryComponent>
  sectionsMinimalsHistoryComponentCollection?: Maybe<SectionsMinimalsHistoryComponentCollection>
  sectionsMinimalsHistoryList?: Maybe<SectionsMinimalsHistoryList>
  sectionsMinimalsHistoryListCollection?: Maybe<SectionsMinimalsHistoryListCollection>
  sectionsMinimalsHumanComponent?: Maybe<SectionsMinimalsHumanComponent>
  sectionsMinimalsHumanComponentCollection?: Maybe<SectionsMinimalsHumanComponentCollection>
  sectionsMinimalsHumanList?: Maybe<SectionsMinimalsHumanList>
  sectionsMinimalsHumanListCollection?: Maybe<SectionsMinimalsHumanListCollection>
  sectionsMinimalsImagesList?: Maybe<SectionsMinimalsImagesList>
  sectionsMinimalsImagesListCollection?: Maybe<SectionsMinimalsImagesListCollection>
  sectionsMinimalsService?: Maybe<SectionsMinimalsService>
  sectionsMinimalsServiceCollection?: Maybe<SectionsMinimalsServiceCollection>
  sectionsMinimalsServices?: Maybe<SectionsMinimalsServices>
  sectionsMinimalsServicesCollection?: Maybe<SectionsMinimalsServicesCollection>
  sectionsMinimalsVideo?: Maybe<SectionsMinimalsVideo>
  sectionsMinimalsVideoCollection?: Maybe<SectionsMinimalsVideoCollection>
  sectionsMintList?: Maybe<SectionsMintList>
  sectionsMintListCollection?: Maybe<SectionsMintListCollection>
  sectionsMintListComponent?: Maybe<SectionsMintListComponent>
  sectionsMintListComponentCollection?: Maybe<SectionsMintListComponentCollection>
  sectionsTimer?: Maybe<SectionsTimer>
  sectionsTimerCollection?: Maybe<SectionsTimerCollection>
  seoMetadata?: Maybe<SeoMetadata>
  seoMetadataCollection?: Maybe<SeoMetadataCollection>
  smartContractErc721?: Maybe<SmartContractErc721>
  smartContractErc721Collection?: Maybe<SmartContractErc721Collection>
  smartContractErc721Token?: Maybe<SmartContractErc721Token>
  smartContractErc721TokenAttribute?: Maybe<SmartContractErc721TokenAttribute>
  smartContractErc721TokenAttributeCollection?: Maybe<SmartContractErc721TokenAttributeCollection>
  smartContractErc721TokenCollection?: Maybe<SmartContractErc721TokenCollection>
  smartContractErc721WhitelistAddress?: Maybe<SmartContractErc721WhitelistAddress>
  smartContractErc721WhitelistAddressCollection?: Maybe<SmartContractErc721WhitelistAddressCollection>
  socialMediaLink?: Maybe<SocialMediaLink>
  socialMediaLinkCollection?: Maybe<SocialMediaLinkCollection>
  /** fetch data from the table: "stripeWebhookLogs" */
  stripeWebhookLogs: Array<StripeWebhookLogs>
  /** fetch aggregated fields from the table: "stripeWebhookLogs" */
  stripeWebhookLogs_aggregate: StripeWebhookLogs_Aggregate
  /** fetch data from the table: "stripeWebhookLogs" using primary key columns */
  stripeWebhookLogs_by_pk?: Maybe<StripeWebhookLogs>
  /** An array relationship */
  subscriptionItems: Array<SubscriptionItems>
  /** An aggregate relationship */
  subscriptionItems_aggregate: SubscriptionItems_Aggregate
  /** fetch data from the table: "subscriptionItems" using primary key columns */
  subscriptionItems_by_pk?: Maybe<SubscriptionItems>
  /** An array relationship */
  subscriptions: Array<Subscriptions>
  /** An aggregate relationship */
  subscriptions_aggregate: Subscriptions_Aggregate
  /** fetch data from the table: "subscriptions" using primary key columns */
  subscriptions_by_pk?: Maybe<Subscriptions>
  supportingLanguages: Array<SupportingLanguage>
  supportingTones: Array<SupportingTone>
  supportingUseCases: Array<SupportingUseCase>
  tags?: Maybe<Tags>
  tagsCollection?: Maybe<TagsCollection>
  /** An array relationship */
  users: Array<Users>
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>
  /** An array relationship */
  writersoftUsageReports: Array<WritersoftUsageReports>
  /** An aggregate relationship */
  writersoftUsageReports_aggregate: WritersoftUsageReports_Aggregate
  /** fetch data from the table: "writersoftUsageReports" using primary key columns */
  writersoftUsageReports_by_pk?: Maybe<WritersoftUsageReports>
}

export type Query_RootAiCompletionRecordsArgs = {
  distinct_on?: InputMaybe<Array<AICompletionRecords_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<AICompletionRecords_Order_By>>
  where?: InputMaybe<AICompletionRecords_Bool_Exp>
}

export type Query_RootAiCompletionRecords_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AICompletionRecords_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<AICompletionRecords_Order_By>>
  where?: InputMaybe<AICompletionRecords_Bool_Exp>
}

export type Query_RootAiCompletionRecords_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootAppsArgs = {
  distinct_on?: InputMaybe<Array<Apps_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Apps_Order_By>>
  where?: InputMaybe<Apps_Bool_Exp>
}

export type Query_RootApps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Apps_Order_By>>
  where?: InputMaybe<Apps_Bool_Exp>
}

export type Query_RootApps_By_PkArgs = {
  id: Scalars['String']
}

export type Query_RootAssetArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootAssetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AssetOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<AssetFilter>
}

export type Query_RootAuthorsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootAuthorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AuthorsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<AuthorsFilter>
}

export type Query_RootAutoGenArticleArgs = {
  input: AutoInput
}

export type Query_RootAutoGenArticleV2Args = {
  input: AutoInput
  productName: Scalars['String']
}

export type Query_RootAutoGenArticleV3ByKeywordsArgs = {
  input: AutoKeywordsInput
  productName: Scalars['String']
}

export type Query_RootAutoGenArticleV4ByKeywordsArgs = {
  input: AutoKeywordsInput
  productName: Scalars['String']
}

export type Query_RootBlogsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootBlogsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<BlogsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<BlogsFilter>
}

export type Query_RootCallToActionLinkArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootCallToActionLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CallToActionLinkOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CallToActionLinkFilter>
}

export type Query_RootCategoriesArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootCategoriesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CategoriesOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CategoriesFilter>
}

export type Query_RootDiscordMessagesArgs = {
  distinct_on?: InputMaybe<Array<DiscordMessages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<DiscordMessages_Order_By>>
  where?: InputMaybe<DiscordMessages_Bool_Exp>
}

export type Query_RootDiscordMessages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DiscordMessages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<DiscordMessages_Order_By>>
  where?: InputMaybe<DiscordMessages_Bool_Exp>
}

export type Query_RootDiscordMessages_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootEmbeddingsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootEmbeddingsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<EmbeddingsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<EmbeddingsFilter>
}

export type Query_RootEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<EntryOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<EntryFilter>
}

export type Query_RootErc721TokenMetadataArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootErc721TokenMetadataAttributeTraitTypeArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootErc721TokenMetadataAttributeTraitTypeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<Erc721TokenMetadataAttributeTraitTypeOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<Erc721TokenMetadataAttributeTraitTypeFilter>
}

export type Query_RootErc721TokenMetadataAttributesTextArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootErc721TokenMetadataAttributesTextCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<Erc721TokenMetadataAttributesTextOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<Erc721TokenMetadataAttributesTextFilter>
}

export type Query_RootErc721TokenMetadataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<Erc721TokenMetadataOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<Erc721TokenMetadataFilter>
}

export type Query_RootFormAnswersArgs = {
  distinct_on?: InputMaybe<Array<FormAnswers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<FormAnswers_Order_By>>
  where?: InputMaybe<FormAnswers_Bool_Exp>
}

export type Query_RootFormAnswers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FormAnswers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<FormAnswers_Order_By>>
  where?: InputMaybe<FormAnswers_Bool_Exp>
}

export type Query_RootFormAnswers_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootFormsArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Forms_Order_By>>
  where?: InputMaybe<Forms_Bool_Exp>
}

export type Query_RootForms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Forms_Order_By>>
  where?: InputMaybe<Forms_Bool_Exp>
}

export type Query_RootForms_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootGenerateImageArgs = {
  empower?: InputMaybe<Scalars['Boolean']>
  prompt: Scalars['String']
}

export type Query_RootGeneratePromptArgs = {
  language?: InputMaybe<PromptSupportingLanguageMap>
  prompt: Scalars['String']
  promptId: Scalars['String']
}

export type Query_RootGeneratePromptForChromeExtensionPromptArgs = {
  language?: InputMaybe<PromptSupportingLanguageMap>
  prompt: Scalars['String']
  promptId: Scalars['String']
  responseTone?: InputMaybe<ResponseTone>
  writeStyle?: InputMaybe<WritingStyle>
}

export type Query_RootGeneratePromptWithTagsArgs = {
  language?: InputMaybe<PromptSupportingLanguageMap>
  prompt: Scalars['String']
  promptId: Scalars['String']
  tags: Array<Scalars['String']>
}

export type Query_RootGenericsArgs = {
  inputs: Array<GenericInput>
}

export type Query_RootGetBlogSectionWritingArgs = {
  input: BlogSectionWritingInput
}

export type Query_RootGetTextEditingExpandArgs = {
  input: TextEditingExpandInput
}

export type Query_RootGetTextEditingImproveArgs = {
  input: TextEditingImproveInput
}

export type Query_RootGetTextEditingRewordArgs = {
  input: TextEditingRewordInput
}

export type Query_RootGetTextEditingShortenArgs = {
  input: TextEditingShortenInput
}

export type Query_RootGptHeroAccountsArgs = {
  distinct_on?: InputMaybe<Array<GptHeroAccounts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroAccounts_Order_By>>
  where?: InputMaybe<GptHeroAccounts_Bool_Exp>
}

export type Query_RootGptHeroAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GptHeroAccounts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroAccounts_Order_By>>
  where?: InputMaybe<GptHeroAccounts_Bool_Exp>
}

export type Query_RootGptHeroAccounts_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootGptHeroPromptUsagesArgs = {
  distinct_on?: InputMaybe<Array<GptHeroPromptUsages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroPromptUsages_Order_By>>
  where?: InputMaybe<GptHeroPromptUsages_Bool_Exp>
}

export type Query_RootGptHeroPromptUsages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GptHeroPromptUsages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroPromptUsages_Order_By>>
  where?: InputMaybe<GptHeroPromptUsages_Bool_Exp>
}

export type Query_RootGptHeroPromptUsages_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootGptHeroPromptsArgs = {
  distinct_on?: InputMaybe<Array<GptHeroPrompts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroPrompts_Order_By>>
  where?: InputMaybe<GptHeroPrompts_Bool_Exp>
}

export type Query_RootGptHeroPrompts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GptHeroPrompts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroPrompts_Order_By>>
  where?: InputMaybe<GptHeroPrompts_Bool_Exp>
}

export type Query_RootGptHeroPrompts_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootGptHeroReportIssuesArgs = {
  distinct_on?: InputMaybe<Array<GptHeroReportIssues_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroReportIssues_Order_By>>
  where?: InputMaybe<GptHeroReportIssues_Bool_Exp>
}

export type Query_RootGptHeroReportIssues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GptHeroReportIssues_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroReportIssues_Order_By>>
  where?: InputMaybe<GptHeroReportIssues_Bool_Exp>
}

export type Query_RootGptHeroReportIssues_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootGptHeroTopicsArgs = {
  distinct_on?: InputMaybe<Array<GptHeroTopics_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroTopics_Order_By>>
  where?: InputMaybe<GptHeroTopics_Bool_Exp>
}

export type Query_RootGptHeroTopics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GptHeroTopics_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroTopics_Order_By>>
  where?: InputMaybe<GptHeroTopics_Bool_Exp>
}

export type Query_RootGptHeroTopics_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootMidjourneyImagesArgs = {
  distinct_on?: InputMaybe<Array<MidjourneyImages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<MidjourneyImages_Order_By>>
  where?: InputMaybe<MidjourneyImages_Bool_Exp>
}

export type Query_RootMidjourneyImages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MidjourneyImages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<MidjourneyImages_Order_By>>
  where?: InputMaybe<MidjourneyImages_Bool_Exp>
}

export type Query_RootMidjourneyImages_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootNewebpayEpgNotificationsArgs = {
  distinct_on?: InputMaybe<Array<NewebpayEpgNotifications_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<NewebpayEpgNotifications_Order_By>>
  where?: InputMaybe<NewebpayEpgNotifications_Bool_Exp>
}

export type Query_RootNewebpayEpgNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<NewebpayEpgNotifications_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<NewebpayEpgNotifications_Order_By>>
  where?: InputMaybe<NewebpayEpgNotifications_Bool_Exp>
}

export type Query_RootNewebpayEpgNotifications_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootPagesArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PagesOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PagesFilter>
}

export type Query_RootProductsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootProductsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ProductsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ProductsFilter>
}

export type Query_RootPromptEngineeringArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootPromptEngineeringCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PromptEngineeringOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PromptEngineeringFilter>
}

export type Query_RootPromptUsagesArgs = {
  distinct_on?: InputMaybe<Array<PromptUsages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<PromptUsages_Order_By>>
  where?: InputMaybe<PromptUsages_Bool_Exp>
}

export type Query_RootPromptUsages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PromptUsages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<PromptUsages_Order_By>>
  where?: InputMaybe<PromptUsages_Bool_Exp>
}

export type Query_RootPromptUsages_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootRootSettingsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootRootSettingsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<RootSettingsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<RootSettingsFilter>
}

export type Query_RootSectionsDetailPersonComponentArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSectionsDetailPersonComponentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsDetailPersonComponentOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsDetailPersonComponentFilter>
}

export type Query_RootSectionsDetailPersonListArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSectionsDetailPersonListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsDetailPersonListOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsDetailPersonListFilter>
}

export type Query_RootSectionsFaqComponentArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSectionsFaqComponentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsFaqComponentOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsFaqComponentFilter>
}

export type Query_RootSectionsFaqListArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSectionsFaqListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsFaqListOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsFaqListFilter>
}

export type Query_RootSectionsFeaturesListArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSectionsFeaturesListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsFeaturesListOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsFeaturesListFilter>
}

export type Query_RootSectionsFeaturesListComponentArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSectionsFeaturesListComponentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsFeaturesListComponentOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsFeaturesListComponentFilter>
}

export type Query_RootSectionsFormArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSectionsFormCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsFormOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsFormFilter>
}

export type Query_RootSectionsFormComponentArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSectionsFormComponentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsFormComponentOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsFormComponentFilter>
}

export type Query_RootSectionsHeroArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSectionsHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsHeroOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsHeroFilter>
}

export type Query_RootSectionsImageArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSectionsImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsImageOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsImageFilter>
}

export type Query_RootSectionsMinimalsContactComponentArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSectionsMinimalsContactComponentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsMinimalsContactComponentOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsMinimalsContactComponentFilter>
}

export type Query_RootSectionsMinimalsContactListArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSectionsMinimalsContactListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsMinimalsContactListOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsMinimalsContactListFilter>
}

export type Query_RootSectionsMinimalsGalleryArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSectionsMinimalsGalleryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsMinimalsGalleryOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsMinimalsGalleryFilter>
}

export type Query_RootSectionsMinimalsHistoryComponentArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSectionsMinimalsHistoryComponentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsMinimalsHistoryComponentOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsMinimalsHistoryComponentFilter>
}

export type Query_RootSectionsMinimalsHistoryListArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSectionsMinimalsHistoryListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsMinimalsHistoryListOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsMinimalsHistoryListFilter>
}

export type Query_RootSectionsMinimalsHumanComponentArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSectionsMinimalsHumanComponentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsMinimalsHumanComponentOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsMinimalsHumanComponentFilter>
}

export type Query_RootSectionsMinimalsHumanListArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSectionsMinimalsHumanListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsMinimalsHumanListOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsMinimalsHumanListFilter>
}

export type Query_RootSectionsMinimalsImagesListArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSectionsMinimalsImagesListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsMinimalsImagesListOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsMinimalsImagesListFilter>
}

export type Query_RootSectionsMinimalsServiceArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSectionsMinimalsServiceCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsMinimalsServiceOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsMinimalsServiceFilter>
}

export type Query_RootSectionsMinimalsServicesArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSectionsMinimalsServicesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsMinimalsServicesOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsMinimalsServicesFilter>
}

export type Query_RootSectionsMinimalsVideoArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSectionsMinimalsVideoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsMinimalsVideoOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsMinimalsVideoFilter>
}

export type Query_RootSectionsMintListArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSectionsMintListCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsMintListOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsMintListFilter>
}

export type Query_RootSectionsMintListComponentArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSectionsMintListComponentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsMintListComponentOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsMintListComponentFilter>
}

export type Query_RootSectionsTimerArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSectionsTimerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SectionsTimerOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SectionsTimerFilter>
}

export type Query_RootSeoMetadataArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSeoMetadataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SeoMetadataOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SeoMetadataFilter>
}

export type Query_RootSmartContractErc721Args = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSmartContractErc721CollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SmartContractErc721Order>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SmartContractErc721Filter>
}

export type Query_RootSmartContractErc721TokenArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSmartContractErc721TokenAttributeArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSmartContractErc721TokenAttributeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SmartContractErc721TokenAttributeOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SmartContractErc721TokenAttributeFilter>
}

export type Query_RootSmartContractErc721TokenCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SmartContractErc721TokenOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SmartContractErc721TokenFilter>
}

export type Query_RootSmartContractErc721WhitelistAddressArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSmartContractErc721WhitelistAddressCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SmartContractErc721WhitelistAddressOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SmartContractErc721WhitelistAddressFilter>
}

export type Query_RootSocialMediaLinkArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootSocialMediaLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SocialMediaLinkOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SocialMediaLinkFilter>
}

export type Query_RootStripeWebhookLogsArgs = {
  distinct_on?: InputMaybe<Array<StripeWebhookLogs_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<StripeWebhookLogs_Order_By>>
  where?: InputMaybe<StripeWebhookLogs_Bool_Exp>
}

export type Query_RootStripeWebhookLogs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StripeWebhookLogs_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<StripeWebhookLogs_Order_By>>
  where?: InputMaybe<StripeWebhookLogs_Bool_Exp>
}

export type Query_RootStripeWebhookLogs_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootSubscriptionItemsArgs = {
  distinct_on?: InputMaybe<Array<SubscriptionItems_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<SubscriptionItems_Order_By>>
  where?: InputMaybe<SubscriptionItems_Bool_Exp>
}

export type Query_RootSubscriptionItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SubscriptionItems_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<SubscriptionItems_Order_By>>
  where?: InputMaybe<SubscriptionItems_Bool_Exp>
}

export type Query_RootSubscriptionItems_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>
  where?: InputMaybe<Subscriptions_Bool_Exp>
}

export type Query_RootSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>
  where?: InputMaybe<Subscriptions_Bool_Exp>
}

export type Query_RootSubscriptions_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootTagsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type Query_RootTagsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TagsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TagsFilter>
}

export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Users_Order_By>>
  where?: InputMaybe<Users_Bool_Exp>
}

export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Users_Order_By>>
  where?: InputMaybe<Users_Bool_Exp>
}

export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootWritersoftUsageReportsArgs = {
  distinct_on?: InputMaybe<Array<WritersoftUsageReports_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<WritersoftUsageReports_Order_By>>
  where?: InputMaybe<WritersoftUsageReports_Bool_Exp>
}

export type Query_RootWritersoftUsageReports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WritersoftUsageReports_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<WritersoftUsageReports_Order_By>>
  where?: InputMaybe<WritersoftUsageReports_Bool_Exp>
}

export type Query_RootWritersoftUsageReports_By_PkArgs = {
  id: Scalars['uuid']
}

/** columns and relationships of "stripeWebhookLogs" */
export type StripeWebhookLogs = {
  __typename?: 'stripeWebhookLogs'
  createdAt: Scalars['timestamptz']
  data?: Maybe<Scalars['jsonb']>
  eventType?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  updatedAt: Scalars['timestamptz']
}

/** columns and relationships of "stripeWebhookLogs" */
export type StripeWebhookLogsDataArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "stripeWebhookLogs" */
export type StripeWebhookLogs_Aggregate = {
  __typename?: 'stripeWebhookLogs_aggregate'
  aggregate?: Maybe<StripeWebhookLogs_Aggregate_Fields>
  nodes: Array<StripeWebhookLogs>
}

/** aggregate fields of "stripeWebhookLogs" */
export type StripeWebhookLogs_Aggregate_Fields = {
  __typename?: 'stripeWebhookLogs_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<StripeWebhookLogs_Max_Fields>
  min?: Maybe<StripeWebhookLogs_Min_Fields>
}

/** aggregate fields of "stripeWebhookLogs" */
export type StripeWebhookLogs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<StripeWebhookLogs_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type StripeWebhookLogs_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "stripeWebhookLogs". All fields are combined with a logical 'AND'. */
export type StripeWebhookLogs_Bool_Exp = {
  _and?: InputMaybe<Array<StripeWebhookLogs_Bool_Exp>>
  _not?: InputMaybe<StripeWebhookLogs_Bool_Exp>
  _or?: InputMaybe<Array<StripeWebhookLogs_Bool_Exp>>
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>
  data?: InputMaybe<Jsonb_Comparison_Exp>
  eventType?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "stripeWebhookLogs" */
export enum StripeWebhookLogs_Constraint {
  /** unique or primary key constraint on columns "id" */
  StripeWebhookLogsPkey = 'stripeWebhookLogs_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type StripeWebhookLogs_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type StripeWebhookLogs_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type StripeWebhookLogs_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>
}

/** input type for inserting data into table "stripeWebhookLogs" */
export type StripeWebhookLogs_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>
  data?: InputMaybe<Scalars['jsonb']>
  eventType?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type StripeWebhookLogs_Max_Fields = {
  __typename?: 'stripeWebhookLogs_max_fields'
  createdAt?: Maybe<Scalars['timestamptz']>
  eventType?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type StripeWebhookLogs_Min_Fields = {
  __typename?: 'stripeWebhookLogs_min_fields'
  createdAt?: Maybe<Scalars['timestamptz']>
  eventType?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "stripeWebhookLogs" */
export type StripeWebhookLogs_Mutation_Response = {
  __typename?: 'stripeWebhookLogs_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<StripeWebhookLogs>
}

/** on_conflict condition type for table "stripeWebhookLogs" */
export type StripeWebhookLogs_On_Conflict = {
  constraint: StripeWebhookLogs_Constraint
  update_columns?: Array<StripeWebhookLogs_Update_Column>
  where?: InputMaybe<StripeWebhookLogs_Bool_Exp>
}

/** Ordering options when selecting data from "stripeWebhookLogs". */
export type StripeWebhookLogs_Order_By = {
  createdAt?: InputMaybe<Order_By>
  data?: InputMaybe<Order_By>
  eventType?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** primary key columns input for table: stripeWebhookLogs */
export type StripeWebhookLogs_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type StripeWebhookLogs_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "stripeWebhookLogs" */
export enum StripeWebhookLogs_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  EventType = 'eventType',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "stripeWebhookLogs" */
export type StripeWebhookLogs_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>
  data?: InputMaybe<Scalars['jsonb']>
  eventType?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "stripeWebhookLogs" */
export type StripeWebhookLogs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: StripeWebhookLogs_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type StripeWebhookLogs_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>
  data?: InputMaybe<Scalars['jsonb']>
  eventType?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "stripeWebhookLogs" */
export enum StripeWebhookLogs_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  EventType = 'eventType',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type StripeWebhookLogs_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<StripeWebhookLogs_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<StripeWebhookLogs_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<StripeWebhookLogs_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<StripeWebhookLogs_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<StripeWebhookLogs_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StripeWebhookLogs_Set_Input>
  /** filter the rows which have to be updated */
  where: StripeWebhookLogs_Bool_Exp
}

/** columns and relationships of "subscriptionItems" */
export type SubscriptionItems = {
  __typename?: 'subscriptionItems'
  /** An object relationship */
  app: Apps
  appId: Scalars['String']
  createdAt: Scalars['timestamptz']
  id: Scalars['uuid']
  stripeSubscriptionItemId: Scalars['String']
  /** An object relationship */
  subscription: Subscriptions
  subscriptionId: Scalars['uuid']
  updatedAt: Scalars['timestamptz']
}

/** aggregated selection of "subscriptionItems" */
export type SubscriptionItems_Aggregate = {
  __typename?: 'subscriptionItems_aggregate'
  aggregate?: Maybe<SubscriptionItems_Aggregate_Fields>
  nodes: Array<SubscriptionItems>
}

export type SubscriptionItems_Aggregate_Bool_Exp = {
  count?: InputMaybe<SubscriptionItems_Aggregate_Bool_Exp_Count>
}

export type SubscriptionItems_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<SubscriptionItems_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<SubscriptionItems_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "subscriptionItems" */
export type SubscriptionItems_Aggregate_Fields = {
  __typename?: 'subscriptionItems_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<SubscriptionItems_Max_Fields>
  min?: Maybe<SubscriptionItems_Min_Fields>
}

/** aggregate fields of "subscriptionItems" */
export type SubscriptionItems_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<SubscriptionItems_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "subscriptionItems" */
export type SubscriptionItems_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<SubscriptionItems_Max_Order_By>
  min?: InputMaybe<SubscriptionItems_Min_Order_By>
}

/** input type for inserting array relation for remote table "subscriptionItems" */
export type SubscriptionItems_Arr_Rel_Insert_Input = {
  data: Array<SubscriptionItems_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<SubscriptionItems_On_Conflict>
}

/** Boolean expression to filter rows from the table "subscriptionItems". All fields are combined with a logical 'AND'. */
export type SubscriptionItems_Bool_Exp = {
  _and?: InputMaybe<Array<SubscriptionItems_Bool_Exp>>
  _not?: InputMaybe<SubscriptionItems_Bool_Exp>
  _or?: InputMaybe<Array<SubscriptionItems_Bool_Exp>>
  app?: InputMaybe<Apps_Bool_Exp>
  appId?: InputMaybe<String_Comparison_Exp>
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  stripeSubscriptionItemId?: InputMaybe<String_Comparison_Exp>
  subscription?: InputMaybe<Subscriptions_Bool_Exp>
  subscriptionId?: InputMaybe<Uuid_Comparison_Exp>
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "subscriptionItems" */
export enum SubscriptionItems_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubscriptionItemsPkey = 'subscriptionItems_pkey',
  /** unique or primary key constraint on columns "stripeSubscriptionItemId" */
  SubscriptionItemsStripeSubscriptionItemIdKey = 'subscriptionItems_stripeSubscriptionItemId_key',
}

/** input type for inserting data into table "subscriptionItems" */
export type SubscriptionItems_Insert_Input = {
  app?: InputMaybe<Apps_Obj_Rel_Insert_Input>
  appId?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  stripeSubscriptionItemId?: InputMaybe<Scalars['String']>
  subscription?: InputMaybe<Subscriptions_Obj_Rel_Insert_Input>
  subscriptionId?: InputMaybe<Scalars['uuid']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type SubscriptionItems_Max_Fields = {
  __typename?: 'subscriptionItems_max_fields'
  appId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  stripeSubscriptionItemId?: Maybe<Scalars['String']>
  subscriptionId?: Maybe<Scalars['uuid']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "subscriptionItems" */
export type SubscriptionItems_Max_Order_By = {
  appId?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  stripeSubscriptionItemId?: InputMaybe<Order_By>
  subscriptionId?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type SubscriptionItems_Min_Fields = {
  __typename?: 'subscriptionItems_min_fields'
  appId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  stripeSubscriptionItemId?: Maybe<Scalars['String']>
  subscriptionId?: Maybe<Scalars['uuid']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "subscriptionItems" */
export type SubscriptionItems_Min_Order_By = {
  appId?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  stripeSubscriptionItemId?: InputMaybe<Order_By>
  subscriptionId?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** response of any mutation on the table "subscriptionItems" */
export type SubscriptionItems_Mutation_Response = {
  __typename?: 'subscriptionItems_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<SubscriptionItems>
}

/** on_conflict condition type for table "subscriptionItems" */
export type SubscriptionItems_On_Conflict = {
  constraint: SubscriptionItems_Constraint
  update_columns?: Array<SubscriptionItems_Update_Column>
  where?: InputMaybe<SubscriptionItems_Bool_Exp>
}

/** Ordering options when selecting data from "subscriptionItems". */
export type SubscriptionItems_Order_By = {
  app?: InputMaybe<Apps_Order_By>
  appId?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  stripeSubscriptionItemId?: InputMaybe<Order_By>
  subscription?: InputMaybe<Subscriptions_Order_By>
  subscriptionId?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** primary key columns input for table: subscriptionItems */
export type SubscriptionItems_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "subscriptionItems" */
export enum SubscriptionItems_Select_Column {
  /** column name */
  AppId = 'appId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  StripeSubscriptionItemId = 'stripeSubscriptionItemId',
  /** column name */
  SubscriptionId = 'subscriptionId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "subscriptionItems" */
export type SubscriptionItems_Set_Input = {
  appId?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  stripeSubscriptionItemId?: InputMaybe<Scalars['String']>
  subscriptionId?: InputMaybe<Scalars['uuid']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "subscriptionItems" */
export type SubscriptionItems_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: SubscriptionItems_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type SubscriptionItems_Stream_Cursor_Value_Input = {
  appId?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  stripeSubscriptionItemId?: InputMaybe<Scalars['String']>
  subscriptionId?: InputMaybe<Scalars['uuid']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "subscriptionItems" */
export enum SubscriptionItems_Update_Column {
  /** column name */
  AppId = 'appId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  StripeSubscriptionItemId = 'stripeSubscriptionItemId',
  /** column name */
  SubscriptionId = 'subscriptionId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type SubscriptionItems_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SubscriptionItems_Set_Input>
  /** filter the rows which have to be updated */
  where: SubscriptionItems_Bool_Exp
}

export type Subscription_Root = {
  __typename?: 'subscription_root'
  /** fetch data from the table: "aICompletionRecords" */
  aICompletionRecords: Array<AICompletionRecords>
  /** fetch aggregated fields from the table: "aICompletionRecords" */
  aICompletionRecords_aggregate: AICompletionRecords_Aggregate
  /** fetch data from the table: "aICompletionRecords" using primary key columns */
  aICompletionRecords_by_pk?: Maybe<AICompletionRecords>
  /** fetch data from the table in a streaming manner: "aICompletionRecords" */
  aICompletionRecords_stream: Array<AICompletionRecords>
  /** fetch data from the table: "apps" */
  apps: Array<Apps>
  /** fetch aggregated fields from the table: "apps" */
  apps_aggregate: Apps_Aggregate
  /** fetch data from the table: "apps" using primary key columns */
  apps_by_pk?: Maybe<Apps>
  /** fetch data from the table in a streaming manner: "apps" */
  apps_stream: Array<Apps>
  /** fetch data from the table: "discordMessages" */
  discordMessages: Array<DiscordMessages>
  /** fetch aggregated fields from the table: "discordMessages" */
  discordMessages_aggregate: DiscordMessages_Aggregate
  /** fetch data from the table: "discordMessages" using primary key columns */
  discordMessages_by_pk?: Maybe<DiscordMessages>
  /** fetch data from the table in a streaming manner: "discordMessages" */
  discordMessages_stream: Array<DiscordMessages>
  /** An array relationship */
  formAnswers: Array<FormAnswers>
  /** An aggregate relationship */
  formAnswers_aggregate: FormAnswers_Aggregate
  /** fetch data from the table: "formAnswers" using primary key columns */
  formAnswers_by_pk?: Maybe<FormAnswers>
  /** fetch data from the table in a streaming manner: "formAnswers" */
  formAnswers_stream: Array<FormAnswers>
  /** An array relationship */
  forms: Array<Forms>
  /** An aggregate relationship */
  forms_aggregate: Forms_Aggregate
  /** fetch data from the table: "forms" using primary key columns */
  forms_by_pk?: Maybe<Forms>
  /** fetch data from the table in a streaming manner: "forms" */
  forms_stream: Array<Forms>
  /** fetch data from the table: "gptHeroAccounts" */
  gptHeroAccounts: Array<GptHeroAccounts>
  /** fetch aggregated fields from the table: "gptHeroAccounts" */
  gptHeroAccounts_aggregate: GptHeroAccounts_Aggregate
  /** fetch data from the table: "gptHeroAccounts" using primary key columns */
  gptHeroAccounts_by_pk?: Maybe<GptHeroAccounts>
  /** fetch data from the table in a streaming manner: "gptHeroAccounts" */
  gptHeroAccounts_stream: Array<GptHeroAccounts>
  /** An array relationship */
  gptHeroPromptUsages: Array<GptHeroPromptUsages>
  /** An aggregate relationship */
  gptHeroPromptUsages_aggregate: GptHeroPromptUsages_Aggregate
  /** fetch data from the table: "gptHeroPromptUsages" using primary key columns */
  gptHeroPromptUsages_by_pk?: Maybe<GptHeroPromptUsages>
  /** fetch data from the table in a streaming manner: "gptHeroPromptUsages" */
  gptHeroPromptUsages_stream: Array<GptHeroPromptUsages>
  /** An array relationship */
  gptHeroPrompts: Array<GptHeroPrompts>
  /** An aggregate relationship */
  gptHeroPrompts_aggregate: GptHeroPrompts_Aggregate
  /** fetch data from the table: "gptHeroPrompts" using primary key columns */
  gptHeroPrompts_by_pk?: Maybe<GptHeroPrompts>
  /** fetch data from the table in a streaming manner: "gptHeroPrompts" */
  gptHeroPrompts_stream: Array<GptHeroPrompts>
  /** An array relationship */
  gptHeroReportIssues: Array<GptHeroReportIssues>
  /** An aggregate relationship */
  gptHeroReportIssues_aggregate: GptHeroReportIssues_Aggregate
  /** fetch data from the table: "gptHeroReportIssues" using primary key columns */
  gptHeroReportIssues_by_pk?: Maybe<GptHeroReportIssues>
  /** fetch data from the table in a streaming manner: "gptHeroReportIssues" */
  gptHeroReportIssues_stream: Array<GptHeroReportIssues>
  /** fetch data from the table: "gptHeroTopics" */
  gptHeroTopics: Array<GptHeroTopics>
  /** fetch aggregated fields from the table: "gptHeroTopics" */
  gptHeroTopics_aggregate: GptHeroTopics_Aggregate
  /** fetch data from the table: "gptHeroTopics" using primary key columns */
  gptHeroTopics_by_pk?: Maybe<GptHeroTopics>
  /** fetch data from the table in a streaming manner: "gptHeroTopics" */
  gptHeroTopics_stream: Array<GptHeroTopics>
  /** fetch data from the table: "midjourneyImages" */
  midjourneyImages: Array<MidjourneyImages>
  /** fetch aggregated fields from the table: "midjourneyImages" */
  midjourneyImages_aggregate: MidjourneyImages_Aggregate
  /** fetch data from the table: "midjourneyImages" using primary key columns */
  midjourneyImages_by_pk?: Maybe<MidjourneyImages>
  /** fetch data from the table in a streaming manner: "midjourneyImages" */
  midjourneyImages_stream: Array<MidjourneyImages>
  /** fetch data from the table: "newebpayEpgNotifications" */
  newebpayEpgNotifications: Array<NewebpayEpgNotifications>
  /** fetch aggregated fields from the table: "newebpayEpgNotifications" */
  newebpayEpgNotifications_aggregate: NewebpayEpgNotifications_Aggregate
  /** fetch data from the table: "newebpayEpgNotifications" using primary key columns */
  newebpayEpgNotifications_by_pk?: Maybe<NewebpayEpgNotifications>
  /** fetch data from the table in a streaming manner: "newebpayEpgNotifications" */
  newebpayEpgNotifications_stream: Array<NewebpayEpgNotifications>
  /** fetch data from the table: "promptUsages" */
  promptUsages: Array<PromptUsages>
  /** fetch aggregated fields from the table: "promptUsages" */
  promptUsages_aggregate: PromptUsages_Aggregate
  /** fetch data from the table: "promptUsages" using primary key columns */
  promptUsages_by_pk?: Maybe<PromptUsages>
  /** fetch data from the table in a streaming manner: "promptUsages" */
  promptUsages_stream: Array<PromptUsages>
  /** fetch data from the table: "stripeWebhookLogs" */
  stripeWebhookLogs: Array<StripeWebhookLogs>
  /** fetch aggregated fields from the table: "stripeWebhookLogs" */
  stripeWebhookLogs_aggregate: StripeWebhookLogs_Aggregate
  /** fetch data from the table: "stripeWebhookLogs" using primary key columns */
  stripeWebhookLogs_by_pk?: Maybe<StripeWebhookLogs>
  /** fetch data from the table in a streaming manner: "stripeWebhookLogs" */
  stripeWebhookLogs_stream: Array<StripeWebhookLogs>
  /** An array relationship */
  subscriptionItems: Array<SubscriptionItems>
  /** An aggregate relationship */
  subscriptionItems_aggregate: SubscriptionItems_Aggregate
  /** fetch data from the table: "subscriptionItems" using primary key columns */
  subscriptionItems_by_pk?: Maybe<SubscriptionItems>
  /** fetch data from the table in a streaming manner: "subscriptionItems" */
  subscriptionItems_stream: Array<SubscriptionItems>
  /** An array relationship */
  subscriptions: Array<Subscriptions>
  /** An aggregate relationship */
  subscriptions_aggregate: Subscriptions_Aggregate
  /** fetch data from the table: "subscriptions" using primary key columns */
  subscriptions_by_pk?: Maybe<Subscriptions>
  /** fetch data from the table in a streaming manner: "subscriptions" */
  subscriptions_stream: Array<Subscriptions>
  /** An array relationship */
  users: Array<Users>
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>
  /** An array relationship */
  writersoftUsageReports: Array<WritersoftUsageReports>
  /** An aggregate relationship */
  writersoftUsageReports_aggregate: WritersoftUsageReports_Aggregate
  /** fetch data from the table: "writersoftUsageReports" using primary key columns */
  writersoftUsageReports_by_pk?: Maybe<WritersoftUsageReports>
  /** fetch data from the table in a streaming manner: "writersoftUsageReports" */
  writersoftUsageReports_stream: Array<WritersoftUsageReports>
}

export type Subscription_RootAiCompletionRecordsArgs = {
  distinct_on?: InputMaybe<Array<AICompletionRecords_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<AICompletionRecords_Order_By>>
  where?: InputMaybe<AICompletionRecords_Bool_Exp>
}

export type Subscription_RootAiCompletionRecords_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AICompletionRecords_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<AICompletionRecords_Order_By>>
  where?: InputMaybe<AICompletionRecords_Bool_Exp>
}

export type Subscription_RootAiCompletionRecords_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootAiCompletionRecords_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<AICompletionRecords_Stream_Cursor_Input>>
  where?: InputMaybe<AICompletionRecords_Bool_Exp>
}

export type Subscription_RootAppsArgs = {
  distinct_on?: InputMaybe<Array<Apps_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Apps_Order_By>>
  where?: InputMaybe<Apps_Bool_Exp>
}

export type Subscription_RootApps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Apps_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Apps_Order_By>>
  where?: InputMaybe<Apps_Bool_Exp>
}

export type Subscription_RootApps_By_PkArgs = {
  id: Scalars['String']
}

export type Subscription_RootApps_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Apps_Stream_Cursor_Input>>
  where?: InputMaybe<Apps_Bool_Exp>
}

export type Subscription_RootDiscordMessagesArgs = {
  distinct_on?: InputMaybe<Array<DiscordMessages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<DiscordMessages_Order_By>>
  where?: InputMaybe<DiscordMessages_Bool_Exp>
}

export type Subscription_RootDiscordMessages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<DiscordMessages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<DiscordMessages_Order_By>>
  where?: InputMaybe<DiscordMessages_Bool_Exp>
}

export type Subscription_RootDiscordMessages_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootDiscordMessages_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<DiscordMessages_Stream_Cursor_Input>>
  where?: InputMaybe<DiscordMessages_Bool_Exp>
}

export type Subscription_RootFormAnswersArgs = {
  distinct_on?: InputMaybe<Array<FormAnswers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<FormAnswers_Order_By>>
  where?: InputMaybe<FormAnswers_Bool_Exp>
}

export type Subscription_RootFormAnswers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<FormAnswers_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<FormAnswers_Order_By>>
  where?: InputMaybe<FormAnswers_Bool_Exp>
}

export type Subscription_RootFormAnswers_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootFormAnswers_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<FormAnswers_Stream_Cursor_Input>>
  where?: InputMaybe<FormAnswers_Bool_Exp>
}

export type Subscription_RootFormsArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Forms_Order_By>>
  where?: InputMaybe<Forms_Bool_Exp>
}

export type Subscription_RootForms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Forms_Order_By>>
  where?: InputMaybe<Forms_Bool_Exp>
}

export type Subscription_RootForms_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootForms_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Forms_Stream_Cursor_Input>>
  where?: InputMaybe<Forms_Bool_Exp>
}

export type Subscription_RootGptHeroAccountsArgs = {
  distinct_on?: InputMaybe<Array<GptHeroAccounts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroAccounts_Order_By>>
  where?: InputMaybe<GptHeroAccounts_Bool_Exp>
}

export type Subscription_RootGptHeroAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GptHeroAccounts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroAccounts_Order_By>>
  where?: InputMaybe<GptHeroAccounts_Bool_Exp>
}

export type Subscription_RootGptHeroAccounts_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootGptHeroAccounts_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<GptHeroAccounts_Stream_Cursor_Input>>
  where?: InputMaybe<GptHeroAccounts_Bool_Exp>
}

export type Subscription_RootGptHeroPromptUsagesArgs = {
  distinct_on?: InputMaybe<Array<GptHeroPromptUsages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroPromptUsages_Order_By>>
  where?: InputMaybe<GptHeroPromptUsages_Bool_Exp>
}

export type Subscription_RootGptHeroPromptUsages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GptHeroPromptUsages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroPromptUsages_Order_By>>
  where?: InputMaybe<GptHeroPromptUsages_Bool_Exp>
}

export type Subscription_RootGptHeroPromptUsages_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootGptHeroPromptUsages_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<GptHeroPromptUsages_Stream_Cursor_Input>>
  where?: InputMaybe<GptHeroPromptUsages_Bool_Exp>
}

export type Subscription_RootGptHeroPromptsArgs = {
  distinct_on?: InputMaybe<Array<GptHeroPrompts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroPrompts_Order_By>>
  where?: InputMaybe<GptHeroPrompts_Bool_Exp>
}

export type Subscription_RootGptHeroPrompts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GptHeroPrompts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroPrompts_Order_By>>
  where?: InputMaybe<GptHeroPrompts_Bool_Exp>
}

export type Subscription_RootGptHeroPrompts_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootGptHeroPrompts_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<GptHeroPrompts_Stream_Cursor_Input>>
  where?: InputMaybe<GptHeroPrompts_Bool_Exp>
}

export type Subscription_RootGptHeroReportIssuesArgs = {
  distinct_on?: InputMaybe<Array<GptHeroReportIssues_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroReportIssues_Order_By>>
  where?: InputMaybe<GptHeroReportIssues_Bool_Exp>
}

export type Subscription_RootGptHeroReportIssues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GptHeroReportIssues_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroReportIssues_Order_By>>
  where?: InputMaybe<GptHeroReportIssues_Bool_Exp>
}

export type Subscription_RootGptHeroReportIssues_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootGptHeroReportIssues_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<GptHeroReportIssues_Stream_Cursor_Input>>
  where?: InputMaybe<GptHeroReportIssues_Bool_Exp>
}

export type Subscription_RootGptHeroTopicsArgs = {
  distinct_on?: InputMaybe<Array<GptHeroTopics_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroTopics_Order_By>>
  where?: InputMaybe<GptHeroTopics_Bool_Exp>
}

export type Subscription_RootGptHeroTopics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<GptHeroTopics_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<GptHeroTopics_Order_By>>
  where?: InputMaybe<GptHeroTopics_Bool_Exp>
}

export type Subscription_RootGptHeroTopics_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootGptHeroTopics_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<GptHeroTopics_Stream_Cursor_Input>>
  where?: InputMaybe<GptHeroTopics_Bool_Exp>
}

export type Subscription_RootMidjourneyImagesArgs = {
  distinct_on?: InputMaybe<Array<MidjourneyImages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<MidjourneyImages_Order_By>>
  where?: InputMaybe<MidjourneyImages_Bool_Exp>
}

export type Subscription_RootMidjourneyImages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<MidjourneyImages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<MidjourneyImages_Order_By>>
  where?: InputMaybe<MidjourneyImages_Bool_Exp>
}

export type Subscription_RootMidjourneyImages_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootMidjourneyImages_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<MidjourneyImages_Stream_Cursor_Input>>
  where?: InputMaybe<MidjourneyImages_Bool_Exp>
}

export type Subscription_RootNewebpayEpgNotificationsArgs = {
  distinct_on?: InputMaybe<Array<NewebpayEpgNotifications_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<NewebpayEpgNotifications_Order_By>>
  where?: InputMaybe<NewebpayEpgNotifications_Bool_Exp>
}

export type Subscription_RootNewebpayEpgNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<NewebpayEpgNotifications_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<NewebpayEpgNotifications_Order_By>>
  where?: InputMaybe<NewebpayEpgNotifications_Bool_Exp>
}

export type Subscription_RootNewebpayEpgNotifications_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootNewebpayEpgNotifications_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<NewebpayEpgNotifications_Stream_Cursor_Input>>
  where?: InputMaybe<NewebpayEpgNotifications_Bool_Exp>
}

export type Subscription_RootPromptUsagesArgs = {
  distinct_on?: InputMaybe<Array<PromptUsages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<PromptUsages_Order_By>>
  where?: InputMaybe<PromptUsages_Bool_Exp>
}

export type Subscription_RootPromptUsages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PromptUsages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<PromptUsages_Order_By>>
  where?: InputMaybe<PromptUsages_Bool_Exp>
}

export type Subscription_RootPromptUsages_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootPromptUsages_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<PromptUsages_Stream_Cursor_Input>>
  where?: InputMaybe<PromptUsages_Bool_Exp>
}

export type Subscription_RootStripeWebhookLogsArgs = {
  distinct_on?: InputMaybe<Array<StripeWebhookLogs_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<StripeWebhookLogs_Order_By>>
  where?: InputMaybe<StripeWebhookLogs_Bool_Exp>
}

export type Subscription_RootStripeWebhookLogs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<StripeWebhookLogs_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<StripeWebhookLogs_Order_By>>
  where?: InputMaybe<StripeWebhookLogs_Bool_Exp>
}

export type Subscription_RootStripeWebhookLogs_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootStripeWebhookLogs_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<StripeWebhookLogs_Stream_Cursor_Input>>
  where?: InputMaybe<StripeWebhookLogs_Bool_Exp>
}

export type Subscription_RootSubscriptionItemsArgs = {
  distinct_on?: InputMaybe<Array<SubscriptionItems_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<SubscriptionItems_Order_By>>
  where?: InputMaybe<SubscriptionItems_Bool_Exp>
}

export type Subscription_RootSubscriptionItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SubscriptionItems_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<SubscriptionItems_Order_By>>
  where?: InputMaybe<SubscriptionItems_Bool_Exp>
}

export type Subscription_RootSubscriptionItems_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootSubscriptionItems_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<SubscriptionItems_Stream_Cursor_Input>>
  where?: InputMaybe<SubscriptionItems_Bool_Exp>
}

export type Subscription_RootSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>
  where?: InputMaybe<Subscriptions_Bool_Exp>
}

export type Subscription_RootSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>
  where?: InputMaybe<Subscriptions_Bool_Exp>
}

export type Subscription_RootSubscriptions_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootSubscriptions_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Subscriptions_Stream_Cursor_Input>>
  where?: InputMaybe<Subscriptions_Bool_Exp>
}

export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Users_Order_By>>
  where?: InputMaybe<Users_Bool_Exp>
}

export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Users_Order_By>>
  where?: InputMaybe<Users_Bool_Exp>
}

export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>
  where?: InputMaybe<Users_Bool_Exp>
}

export type Subscription_RootWritersoftUsageReportsArgs = {
  distinct_on?: InputMaybe<Array<WritersoftUsageReports_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<WritersoftUsageReports_Order_By>>
  where?: InputMaybe<WritersoftUsageReports_Bool_Exp>
}

export type Subscription_RootWritersoftUsageReports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WritersoftUsageReports_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<WritersoftUsageReports_Order_By>>
  where?: InputMaybe<WritersoftUsageReports_Bool_Exp>
}

export type Subscription_RootWritersoftUsageReports_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootWritersoftUsageReports_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<WritersoftUsageReports_Stream_Cursor_Input>>
  where?: InputMaybe<WritersoftUsageReports_Bool_Exp>
}

/** columns and relationships of "subscriptions" */
export type Subscriptions = {
  __typename?: 'subscriptions'
  /** An object relationship */
  app: Apps
  appId: Scalars['String']
  createdAt: Scalars['timestamptz']
  id: Scalars['uuid']
  stripeCheckoutSessionId?: Maybe<Scalars['String']>
  stripeStatus?: Maybe<Scalars['String']>
  stripeSubscriptionId?: Maybe<Scalars['String']>
  /** An array relationship */
  subscriptionItems: Array<SubscriptionItems>
  /** An aggregate relationship */
  subscriptionItems_aggregate: SubscriptionItems_Aggregate
  updatedAt: Scalars['timestamptz']
  /** An object relationship */
  user: Users
  userId: Scalars['uuid']
}

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscriptionItemsArgs = {
  distinct_on?: InputMaybe<Array<SubscriptionItems_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<SubscriptionItems_Order_By>>
  where?: InputMaybe<SubscriptionItems_Bool_Exp>
}

/** columns and relationships of "subscriptions" */
export type SubscriptionsSubscriptionItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<SubscriptionItems_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<SubscriptionItems_Order_By>>
  where?: InputMaybe<SubscriptionItems_Bool_Exp>
}

/** aggregated selection of "subscriptions" */
export type Subscriptions_Aggregate = {
  __typename?: 'subscriptions_aggregate'
  aggregate?: Maybe<Subscriptions_Aggregate_Fields>
  nodes: Array<Subscriptions>
}

export type Subscriptions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Subscriptions_Aggregate_Bool_Exp_Count>
}

export type Subscriptions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Subscriptions_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Subscriptions_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "subscriptions" */
export type Subscriptions_Aggregate_Fields = {
  __typename?: 'subscriptions_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Subscriptions_Max_Fields>
  min?: Maybe<Subscriptions_Min_Fields>
}

/** aggregate fields of "subscriptions" */
export type Subscriptions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscriptions_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "subscriptions" */
export type Subscriptions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Subscriptions_Max_Order_By>
  min?: InputMaybe<Subscriptions_Min_Order_By>
}

/** input type for inserting array relation for remote table "subscriptions" */
export type Subscriptions_Arr_Rel_Insert_Input = {
  data: Array<Subscriptions_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Subscriptions_On_Conflict>
}

/** Boolean expression to filter rows from the table "subscriptions". All fields are combined with a logical 'AND'. */
export type Subscriptions_Bool_Exp = {
  _and?: InputMaybe<Array<Subscriptions_Bool_Exp>>
  _not?: InputMaybe<Subscriptions_Bool_Exp>
  _or?: InputMaybe<Array<Subscriptions_Bool_Exp>>
  app?: InputMaybe<Apps_Bool_Exp>
  appId?: InputMaybe<String_Comparison_Exp>
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  stripeCheckoutSessionId?: InputMaybe<String_Comparison_Exp>
  stripeStatus?: InputMaybe<String_Comparison_Exp>
  stripeSubscriptionId?: InputMaybe<String_Comparison_Exp>
  subscriptionItems?: InputMaybe<SubscriptionItems_Bool_Exp>
  subscriptionItems_aggregate?: InputMaybe<SubscriptionItems_Aggregate_Bool_Exp>
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>
  user?: InputMaybe<Users_Bool_Exp>
  userId?: InputMaybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "subscriptions" */
export enum Subscriptions_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubscriptionsPkey = 'subscriptions_pkey',
}

/** input type for inserting data into table "subscriptions" */
export type Subscriptions_Insert_Input = {
  app?: InputMaybe<Apps_Obj_Rel_Insert_Input>
  appId?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  stripeCheckoutSessionId?: InputMaybe<Scalars['String']>
  stripeStatus?: InputMaybe<Scalars['String']>
  stripeSubscriptionId?: InputMaybe<Scalars['String']>
  subscriptionItems?: InputMaybe<SubscriptionItems_Arr_Rel_Insert_Input>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>
  userId?: InputMaybe<Scalars['uuid']>
}

/** aggregate max on columns */
export type Subscriptions_Max_Fields = {
  __typename?: 'subscriptions_max_fields'
  appId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  stripeCheckoutSessionId?: Maybe<Scalars['String']>
  stripeStatus?: Maybe<Scalars['String']>
  stripeSubscriptionId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
  userId?: Maybe<Scalars['uuid']>
}

/** order by max() on columns of table "subscriptions" */
export type Subscriptions_Max_Order_By = {
  appId?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  stripeCheckoutSessionId?: InputMaybe<Order_By>
  stripeStatus?: InputMaybe<Order_By>
  stripeSubscriptionId?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
  userId?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Subscriptions_Min_Fields = {
  __typename?: 'subscriptions_min_fields'
  appId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  stripeCheckoutSessionId?: Maybe<Scalars['String']>
  stripeStatus?: Maybe<Scalars['String']>
  stripeSubscriptionId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
  userId?: Maybe<Scalars['uuid']>
}

/** order by min() on columns of table "subscriptions" */
export type Subscriptions_Min_Order_By = {
  appId?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  stripeCheckoutSessionId?: InputMaybe<Order_By>
  stripeStatus?: InputMaybe<Order_By>
  stripeSubscriptionId?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
  userId?: InputMaybe<Order_By>
}

/** response of any mutation on the table "subscriptions" */
export type Subscriptions_Mutation_Response = {
  __typename?: 'subscriptions_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Subscriptions>
}

/** input type for inserting object relation for remote table "subscriptions" */
export type Subscriptions_Obj_Rel_Insert_Input = {
  data: Subscriptions_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Subscriptions_On_Conflict>
}

/** on_conflict condition type for table "subscriptions" */
export type Subscriptions_On_Conflict = {
  constraint: Subscriptions_Constraint
  update_columns?: Array<Subscriptions_Update_Column>
  where?: InputMaybe<Subscriptions_Bool_Exp>
}

/** Ordering options when selecting data from "subscriptions". */
export type Subscriptions_Order_By = {
  app?: InputMaybe<Apps_Order_By>
  appId?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  stripeCheckoutSessionId?: InputMaybe<Order_By>
  stripeStatus?: InputMaybe<Order_By>
  stripeSubscriptionId?: InputMaybe<Order_By>
  subscriptionItems_aggregate?: InputMaybe<SubscriptionItems_Aggregate_Order_By>
  updatedAt?: InputMaybe<Order_By>
  user?: InputMaybe<Users_Order_By>
  userId?: InputMaybe<Order_By>
}

/** primary key columns input for table: subscriptions */
export type Subscriptions_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "subscriptions" */
export enum Subscriptions_Select_Column {
  /** column name */
  AppId = 'appId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  StripeCheckoutSessionId = 'stripeCheckoutSessionId',
  /** column name */
  StripeStatus = 'stripeStatus',
  /** column name */
  StripeSubscriptionId = 'stripeSubscriptionId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "subscriptions" */
export type Subscriptions_Set_Input = {
  appId?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  stripeCheckoutSessionId?: InputMaybe<Scalars['String']>
  stripeStatus?: InputMaybe<Scalars['String']>
  stripeSubscriptionId?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  userId?: InputMaybe<Scalars['uuid']>
}

/** Streaming cursor of the table "subscriptions" */
export type Subscriptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscriptions_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Subscriptions_Stream_Cursor_Value_Input = {
  appId?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  stripeCheckoutSessionId?: InputMaybe<Scalars['String']>
  stripeStatus?: InputMaybe<Scalars['String']>
  stripeSubscriptionId?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  userId?: InputMaybe<Scalars['uuid']>
}

/** update columns of table "subscriptions" */
export enum Subscriptions_Update_Column {
  /** column name */
  AppId = 'appId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  StripeCheckoutSessionId = 'stripeCheckoutSessionId',
  /** column name */
  StripeStatus = 'stripeStatus',
  /** column name */
  StripeSubscriptionId = 'stripeSubscriptionId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

export type Subscriptions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscriptions_Set_Input>
  /** filter the rows which have to be updated */
  where: Subscriptions_Bool_Exp
}

export type TextEditingExpandInput = {
  format: Format
  language: LanguageSlug
  text: Scalars['String']
  tone: ToneSlug
}

export type TextEditingImproveInput = {
  format: Format
  language: LanguageSlug
  text: Scalars['String']
  tone: ToneSlug
}

export type TextEditingRewordInput = {
  format: Format
  language: LanguageSlug
  text: Scalars['String']
  tone: ToneSlug
}

export type TextEditingShortenInput = {
  format: Format
  language: LanguageSlug
  text: Scalars['String']
  tone: ToneSlug
}

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>
  _gt?: InputMaybe<Scalars['timestamp']>
  _gte?: InputMaybe<Scalars['timestamp']>
  _in?: InputMaybe<Array<Scalars['timestamp']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['timestamp']>
  _lte?: InputMaybe<Scalars['timestamp']>
  _neq?: InputMaybe<Scalars['timestamp']>
  _nin?: InputMaybe<Array<Scalars['timestamp']>>
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>
  _gt?: InputMaybe<Scalars['timestamptz']>
  _gte?: InputMaybe<Scalars['timestamptz']>
  _in?: InputMaybe<Array<Scalars['timestamptz']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['timestamptz']>
  _lte?: InputMaybe<Scalars['timestamptz']>
  _neq?: InputMaybe<Scalars['timestamptz']>
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>
}

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users'
  /** An object relationship */
  app: Apps
  appId: Scalars['String']
  auth0userId: Scalars['String']
  createdAt: Scalars['timestamptz']
  email?: Maybe<Scalars['String']>
  /** An array relationship */
  forms: Array<Forms>
  /** An aggregate relationship */
  forms_aggregate: Forms_Aggregate
  id: Scalars['uuid']
  name: Scalars['String']
  nickname?: Maybe<Scalars['String']>
  profileUrl?: Maybe<Scalars['String']>
  referralCode?: Maybe<Scalars['String']>
  /** An object relationship */
  referredByUser?: Maybe<Users>
  referredByUserId?: Maybe<Scalars['uuid']>
  /** An array relationship */
  referredUsers: Array<Users>
  /** An aggregate relationship */
  referredUsers_aggregate: Users_Aggregate
  stripeCustomerId?: Maybe<Scalars['String']>
  /** An array relationship */
  subscriptions: Array<Subscriptions>
  /** An aggregate relationship */
  subscriptions_aggregate: Subscriptions_Aggregate
  updatedAt: Scalars['timestamptz']
  /** An array relationship */
  writersoftUsageReports: Array<WritersoftUsageReports>
  /** An aggregate relationship */
  writersoftUsageReports_aggregate: WritersoftUsageReports_Aggregate
}

/** columns and relationships of "users" */
export type UsersFormsArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Forms_Order_By>>
  where?: InputMaybe<Forms_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersForms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Forms_Order_By>>
  where?: InputMaybe<Forms_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersReferredUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Users_Order_By>>
  where?: InputMaybe<Users_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersReferredUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Users_Order_By>>
  where?: InputMaybe<Users_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>
  where?: InputMaybe<Subscriptions_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscriptions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Subscriptions_Order_By>>
  where?: InputMaybe<Subscriptions_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersWritersoftUsageReportsArgs = {
  distinct_on?: InputMaybe<Array<WritersoftUsageReports_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<WritersoftUsageReports_Order_By>>
  where?: InputMaybe<WritersoftUsageReports_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersWritersoftUsageReports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<WritersoftUsageReports_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<WritersoftUsageReports_Order_By>>
  where?: InputMaybe<WritersoftUsageReports_Bool_Exp>
}

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate'
  aggregate?: Maybe<Users_Aggregate_Fields>
  nodes: Array<Users>
}

export type Users_Aggregate_Bool_Exp = {
  count?: InputMaybe<Users_Aggregate_Bool_Exp_Count>
}

export type Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Users_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Users_Max_Fields>
  min?: Maybe<Users_Min_Fields>
}

/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Users_Max_Order_By>
  min?: InputMaybe<Users_Min_Order_By>
}

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>
}

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>
  _not?: InputMaybe<Users_Bool_Exp>
  _or?: InputMaybe<Array<Users_Bool_Exp>>
  app?: InputMaybe<Apps_Bool_Exp>
  appId?: InputMaybe<String_Comparison_Exp>
  auth0userId?: InputMaybe<String_Comparison_Exp>
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>
  email?: InputMaybe<String_Comparison_Exp>
  forms?: InputMaybe<Forms_Bool_Exp>
  forms_aggregate?: InputMaybe<Forms_Aggregate_Bool_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  nickname?: InputMaybe<String_Comparison_Exp>
  profileUrl?: InputMaybe<String_Comparison_Exp>
  referralCode?: InputMaybe<String_Comparison_Exp>
  referredByUser?: InputMaybe<Users_Bool_Exp>
  referredByUserId?: InputMaybe<Uuid_Comparison_Exp>
  referredUsers?: InputMaybe<Users_Bool_Exp>
  referredUsers_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>
  stripeCustomerId?: InputMaybe<String_Comparison_Exp>
  subscriptions?: InputMaybe<Subscriptions_Bool_Exp>
  subscriptions_aggregate?: InputMaybe<Subscriptions_Aggregate_Bool_Exp>
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>
  writersoftUsageReports?: InputMaybe<WritersoftUsageReports_Bool_Exp>
  writersoftUsageReports_aggregate?: InputMaybe<WritersoftUsageReports_Aggregate_Bool_Exp>
}

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "appId", "auth0userId" */
  UsersAppIdAuth0userIdKey = 'users_appId_auth0userId_key',
  /** unique or primary key constraint on columns "appId", "referralCode" */
  UsersAppIdReferralCodeKey = 'users_appId_referralCode_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  app?: InputMaybe<Apps_Obj_Rel_Insert_Input>
  appId?: InputMaybe<Scalars['String']>
  auth0userId?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  forms?: InputMaybe<Forms_Arr_Rel_Insert_Input>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  nickname?: InputMaybe<Scalars['String']>
  profileUrl?: InputMaybe<Scalars['String']>
  referralCode?: InputMaybe<Scalars['String']>
  referredByUser?: InputMaybe<Users_Obj_Rel_Insert_Input>
  referredByUserId?: InputMaybe<Scalars['uuid']>
  referredUsers?: InputMaybe<Users_Arr_Rel_Insert_Input>
  stripeCustomerId?: InputMaybe<Scalars['String']>
  subscriptions?: InputMaybe<Subscriptions_Arr_Rel_Insert_Input>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  writersoftUsageReports?: InputMaybe<WritersoftUsageReports_Arr_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields'
  appId?: Maybe<Scalars['String']>
  auth0userId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  nickname?: Maybe<Scalars['String']>
  profileUrl?: Maybe<Scalars['String']>
  referralCode?: Maybe<Scalars['String']>
  referredByUserId?: Maybe<Scalars['uuid']>
  stripeCustomerId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  appId?: InputMaybe<Order_By>
  auth0userId?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  nickname?: InputMaybe<Order_By>
  profileUrl?: InputMaybe<Order_By>
  referralCode?: InputMaybe<Order_By>
  referredByUserId?: InputMaybe<Order_By>
  stripeCustomerId?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields'
  appId?: Maybe<Scalars['String']>
  auth0userId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['timestamptz']>
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  nickname?: Maybe<Scalars['String']>
  profileUrl?: Maybe<Scalars['String']>
  referralCode?: Maybe<Scalars['String']>
  referredByUserId?: Maybe<Scalars['uuid']>
  stripeCustomerId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  appId?: InputMaybe<Order_By>
  auth0userId?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  nickname?: InputMaybe<Order_By>
  profileUrl?: InputMaybe<Order_By>
  referralCode?: InputMaybe<Order_By>
  referredByUserId?: InputMaybe<Order_By>
  stripeCustomerId?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
}

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Users>
}

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>
}

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint
  update_columns?: Array<Users_Update_Column>
  where?: InputMaybe<Users_Bool_Exp>
}

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  app?: InputMaybe<Apps_Order_By>
  appId?: InputMaybe<Order_By>
  auth0userId?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  forms_aggregate?: InputMaybe<Forms_Aggregate_Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  nickname?: InputMaybe<Order_By>
  profileUrl?: InputMaybe<Order_By>
  referralCode?: InputMaybe<Order_By>
  referredByUser?: InputMaybe<Users_Order_By>
  referredByUserId?: InputMaybe<Order_By>
  referredUsers_aggregate?: InputMaybe<Users_Aggregate_Order_By>
  stripeCustomerId?: InputMaybe<Order_By>
  subscriptions_aggregate?: InputMaybe<Subscriptions_Aggregate_Order_By>
  updatedAt?: InputMaybe<Order_By>
  writersoftUsageReports_aggregate?: InputMaybe<WritersoftUsageReports_Aggregate_Order_By>
}

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  AppId = 'appId',
  /** column name */
  Auth0userId = 'auth0userId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  ProfileUrl = 'profileUrl',
  /** column name */
  ReferralCode = 'referralCode',
  /** column name */
  ReferredByUserId = 'referredByUserId',
  /** column name */
  StripeCustomerId = 'stripeCustomerId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  appId?: InputMaybe<Scalars['String']>
  auth0userId?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  nickname?: InputMaybe<Scalars['String']>
  profileUrl?: InputMaybe<Scalars['String']>
  referralCode?: InputMaybe<Scalars['String']>
  referredByUserId?: InputMaybe<Scalars['uuid']>
  stripeCustomerId?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  appId?: InputMaybe<Scalars['String']>
  auth0userId?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  email?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  name?: InputMaybe<Scalars['String']>
  nickname?: InputMaybe<Scalars['String']>
  profileUrl?: InputMaybe<Scalars['String']>
  referralCode?: InputMaybe<Scalars['String']>
  referredByUserId?: InputMaybe<Scalars['uuid']>
  stripeCustomerId?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  AppId = 'appId',
  /** column name */
  Auth0userId = 'auth0userId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  ProfileUrl = 'profileUrl',
  /** column name */
  ReferralCode = 'referralCode',
  /** column name */
  ReferredByUserId = 'referredByUserId',
  /** column name */
  StripeCustomerId = 'stripeCustomerId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>
  _gt?: InputMaybe<Scalars['uuid']>
  _gte?: InputMaybe<Scalars['uuid']>
  _in?: InputMaybe<Array<Scalars['uuid']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['uuid']>
  _lte?: InputMaybe<Scalars['uuid']>
  _neq?: InputMaybe<Scalars['uuid']>
  _nin?: InputMaybe<Array<Scalars['uuid']>>
}

/** columns and relationships of "writersoftUsageReports" */
export type WritersoftUsageReports = {
  __typename?: 'writersoftUsageReports'
  /** An object relationship */
  app: Apps
  appId: Scalars['String']
  characters: Scalars['Int']
  createdAt: Scalars['timestamptz']
  format: Scalars['String']
  id: Scalars['uuid']
  inputContexts: Scalars['jsonb']
  languageId: Scalars['String']
  response?: Maybe<Scalars['jsonb']>
  sentAt?: Maybe<Scalars['timestamp']>
  stripeResponse?: Maybe<Scalars['jsonb']>
  stripeSubscriptionItemId?: Maybe<Scalars['String']>
  toneId: Scalars['String']
  updatedAt: Scalars['timestamptz']
  useCaseId: Scalars['String']
  /** An object relationship */
  user?: Maybe<Users>
  userId?: Maybe<Scalars['uuid']>
  variations: Scalars['Int']
}

/** columns and relationships of "writersoftUsageReports" */
export type WritersoftUsageReportsInputContextsArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "writersoftUsageReports" */
export type WritersoftUsageReportsResponseArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "writersoftUsageReports" */
export type WritersoftUsageReportsStripeResponseArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "writersoftUsageReports" */
export type WritersoftUsageReports_Aggregate = {
  __typename?: 'writersoftUsageReports_aggregate'
  aggregate?: Maybe<WritersoftUsageReports_Aggregate_Fields>
  nodes: Array<WritersoftUsageReports>
}

export type WritersoftUsageReports_Aggregate_Bool_Exp = {
  count?: InputMaybe<WritersoftUsageReports_Aggregate_Bool_Exp_Count>
}

export type WritersoftUsageReports_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<WritersoftUsageReports_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<WritersoftUsageReports_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "writersoftUsageReports" */
export type WritersoftUsageReports_Aggregate_Fields = {
  __typename?: 'writersoftUsageReports_aggregate_fields'
  avg?: Maybe<WritersoftUsageReports_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<WritersoftUsageReports_Max_Fields>
  min?: Maybe<WritersoftUsageReports_Min_Fields>
  stddev?: Maybe<WritersoftUsageReports_Stddev_Fields>
  stddev_pop?: Maybe<WritersoftUsageReports_Stddev_Pop_Fields>
  stddev_samp?: Maybe<WritersoftUsageReports_Stddev_Samp_Fields>
  sum?: Maybe<WritersoftUsageReports_Sum_Fields>
  var_pop?: Maybe<WritersoftUsageReports_Var_Pop_Fields>
  var_samp?: Maybe<WritersoftUsageReports_Var_Samp_Fields>
  variance?: Maybe<WritersoftUsageReports_Variance_Fields>
}

/** aggregate fields of "writersoftUsageReports" */
export type WritersoftUsageReports_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<WritersoftUsageReports_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "writersoftUsageReports" */
export type WritersoftUsageReports_Aggregate_Order_By = {
  avg?: InputMaybe<WritersoftUsageReports_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<WritersoftUsageReports_Max_Order_By>
  min?: InputMaybe<WritersoftUsageReports_Min_Order_By>
  stddev?: InputMaybe<WritersoftUsageReports_Stddev_Order_By>
  stddev_pop?: InputMaybe<WritersoftUsageReports_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<WritersoftUsageReports_Stddev_Samp_Order_By>
  sum?: InputMaybe<WritersoftUsageReports_Sum_Order_By>
  var_pop?: InputMaybe<WritersoftUsageReports_Var_Pop_Order_By>
  var_samp?: InputMaybe<WritersoftUsageReports_Var_Samp_Order_By>
  variance?: InputMaybe<WritersoftUsageReports_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type WritersoftUsageReports_Append_Input = {
  inputContexts?: InputMaybe<Scalars['jsonb']>
  response?: InputMaybe<Scalars['jsonb']>
  stripeResponse?: InputMaybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "writersoftUsageReports" */
export type WritersoftUsageReports_Arr_Rel_Insert_Input = {
  data: Array<WritersoftUsageReports_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<WritersoftUsageReports_On_Conflict>
}

/** aggregate avg on columns */
export type WritersoftUsageReports_Avg_Fields = {
  __typename?: 'writersoftUsageReports_avg_fields'
  characters?: Maybe<Scalars['Float']>
  variations?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "writersoftUsageReports" */
export type WritersoftUsageReports_Avg_Order_By = {
  characters?: InputMaybe<Order_By>
  variations?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "writersoftUsageReports". All fields are combined with a logical 'AND'. */
export type WritersoftUsageReports_Bool_Exp = {
  _and?: InputMaybe<Array<WritersoftUsageReports_Bool_Exp>>
  _not?: InputMaybe<WritersoftUsageReports_Bool_Exp>
  _or?: InputMaybe<Array<WritersoftUsageReports_Bool_Exp>>
  app?: InputMaybe<Apps_Bool_Exp>
  appId?: InputMaybe<String_Comparison_Exp>
  characters?: InputMaybe<Int_Comparison_Exp>
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>
  format?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  inputContexts?: InputMaybe<Jsonb_Comparison_Exp>
  languageId?: InputMaybe<String_Comparison_Exp>
  response?: InputMaybe<Jsonb_Comparison_Exp>
  sentAt?: InputMaybe<Timestamp_Comparison_Exp>
  stripeResponse?: InputMaybe<Jsonb_Comparison_Exp>
  stripeSubscriptionItemId?: InputMaybe<String_Comparison_Exp>
  toneId?: InputMaybe<String_Comparison_Exp>
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>
  useCaseId?: InputMaybe<String_Comparison_Exp>
  user?: InputMaybe<Users_Bool_Exp>
  userId?: InputMaybe<Uuid_Comparison_Exp>
  variations?: InputMaybe<Int_Comparison_Exp>
}

/** unique or primary key constraints on table "writersoftUsageReports" */
export enum WritersoftUsageReports_Constraint {
  /** unique or primary key constraint on columns "id" */
  WritersoftUsageReportsPkey = 'writersoftUsageReports_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type WritersoftUsageReports_Delete_At_Path_Input = {
  inputContexts?: InputMaybe<Array<Scalars['String']>>
  response?: InputMaybe<Array<Scalars['String']>>
  stripeResponse?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type WritersoftUsageReports_Delete_Elem_Input = {
  inputContexts?: InputMaybe<Scalars['Int']>
  response?: InputMaybe<Scalars['Int']>
  stripeResponse?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type WritersoftUsageReports_Delete_Key_Input = {
  inputContexts?: InputMaybe<Scalars['String']>
  response?: InputMaybe<Scalars['String']>
  stripeResponse?: InputMaybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "writersoftUsageReports" */
export type WritersoftUsageReports_Inc_Input = {
  characters?: InputMaybe<Scalars['Int']>
  variations?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "writersoftUsageReports" */
export type WritersoftUsageReports_Insert_Input = {
  app?: InputMaybe<Apps_Obj_Rel_Insert_Input>
  appId?: InputMaybe<Scalars['String']>
  characters?: InputMaybe<Scalars['Int']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  format?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  inputContexts?: InputMaybe<Scalars['jsonb']>
  languageId?: InputMaybe<Scalars['String']>
  response?: InputMaybe<Scalars['jsonb']>
  sentAt?: InputMaybe<Scalars['timestamp']>
  stripeResponse?: InputMaybe<Scalars['jsonb']>
  stripeSubscriptionItemId?: InputMaybe<Scalars['String']>
  toneId?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  useCaseId?: InputMaybe<Scalars['String']>
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>
  userId?: InputMaybe<Scalars['uuid']>
  variations?: InputMaybe<Scalars['Int']>
}

/** aggregate max on columns */
export type WritersoftUsageReports_Max_Fields = {
  __typename?: 'writersoftUsageReports_max_fields'
  appId?: Maybe<Scalars['String']>
  characters?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['timestamptz']>
  format?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  languageId?: Maybe<Scalars['String']>
  sentAt?: Maybe<Scalars['timestamp']>
  stripeSubscriptionItemId?: Maybe<Scalars['String']>
  toneId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
  useCaseId?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['uuid']>
  variations?: Maybe<Scalars['Int']>
}

/** order by max() on columns of table "writersoftUsageReports" */
export type WritersoftUsageReports_Max_Order_By = {
  appId?: InputMaybe<Order_By>
  characters?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  format?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  languageId?: InputMaybe<Order_By>
  sentAt?: InputMaybe<Order_By>
  stripeSubscriptionItemId?: InputMaybe<Order_By>
  toneId?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
  useCaseId?: InputMaybe<Order_By>
  userId?: InputMaybe<Order_By>
  variations?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type WritersoftUsageReports_Min_Fields = {
  __typename?: 'writersoftUsageReports_min_fields'
  appId?: Maybe<Scalars['String']>
  characters?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['timestamptz']>
  format?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  languageId?: Maybe<Scalars['String']>
  sentAt?: Maybe<Scalars['timestamp']>
  stripeSubscriptionItemId?: Maybe<Scalars['String']>
  toneId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['timestamptz']>
  useCaseId?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['uuid']>
  variations?: Maybe<Scalars['Int']>
}

/** order by min() on columns of table "writersoftUsageReports" */
export type WritersoftUsageReports_Min_Order_By = {
  appId?: InputMaybe<Order_By>
  characters?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  format?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  languageId?: InputMaybe<Order_By>
  sentAt?: InputMaybe<Order_By>
  stripeSubscriptionItemId?: InputMaybe<Order_By>
  toneId?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
  useCaseId?: InputMaybe<Order_By>
  userId?: InputMaybe<Order_By>
  variations?: InputMaybe<Order_By>
}

/** response of any mutation on the table "writersoftUsageReports" */
export type WritersoftUsageReports_Mutation_Response = {
  __typename?: 'writersoftUsageReports_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<WritersoftUsageReports>
}

/** on_conflict condition type for table "writersoftUsageReports" */
export type WritersoftUsageReports_On_Conflict = {
  constraint: WritersoftUsageReports_Constraint
  update_columns?: Array<WritersoftUsageReports_Update_Column>
  where?: InputMaybe<WritersoftUsageReports_Bool_Exp>
}

/** Ordering options when selecting data from "writersoftUsageReports". */
export type WritersoftUsageReports_Order_By = {
  app?: InputMaybe<Apps_Order_By>
  appId?: InputMaybe<Order_By>
  characters?: InputMaybe<Order_By>
  createdAt?: InputMaybe<Order_By>
  format?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  inputContexts?: InputMaybe<Order_By>
  languageId?: InputMaybe<Order_By>
  response?: InputMaybe<Order_By>
  sentAt?: InputMaybe<Order_By>
  stripeResponse?: InputMaybe<Order_By>
  stripeSubscriptionItemId?: InputMaybe<Order_By>
  toneId?: InputMaybe<Order_By>
  updatedAt?: InputMaybe<Order_By>
  useCaseId?: InputMaybe<Order_By>
  user?: InputMaybe<Users_Order_By>
  userId?: InputMaybe<Order_By>
  variations?: InputMaybe<Order_By>
}

/** primary key columns input for table: writersoftUsageReports */
export type WritersoftUsageReports_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type WritersoftUsageReports_Prepend_Input = {
  inputContexts?: InputMaybe<Scalars['jsonb']>
  response?: InputMaybe<Scalars['jsonb']>
  stripeResponse?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "writersoftUsageReports" */
export enum WritersoftUsageReports_Select_Column {
  /** column name */
  AppId = 'appId',
  /** column name */
  Characters = 'characters',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Format = 'format',
  /** column name */
  Id = 'id',
  /** column name */
  InputContexts = 'inputContexts',
  /** column name */
  LanguageId = 'languageId',
  /** column name */
  Response = 'response',
  /** column name */
  SentAt = 'sentAt',
  /** column name */
  StripeResponse = 'stripeResponse',
  /** column name */
  StripeSubscriptionItemId = 'stripeSubscriptionItemId',
  /** column name */
  ToneId = 'toneId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UseCaseId = 'useCaseId',
  /** column name */
  UserId = 'userId',
  /** column name */
  Variations = 'variations',
}

/** input type for updating data in table "writersoftUsageReports" */
export type WritersoftUsageReports_Set_Input = {
  appId?: InputMaybe<Scalars['String']>
  characters?: InputMaybe<Scalars['Int']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  format?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  inputContexts?: InputMaybe<Scalars['jsonb']>
  languageId?: InputMaybe<Scalars['String']>
  response?: InputMaybe<Scalars['jsonb']>
  sentAt?: InputMaybe<Scalars['timestamp']>
  stripeResponse?: InputMaybe<Scalars['jsonb']>
  stripeSubscriptionItemId?: InputMaybe<Scalars['String']>
  toneId?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  useCaseId?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['uuid']>
  variations?: InputMaybe<Scalars['Int']>
}

/** aggregate stddev on columns */
export type WritersoftUsageReports_Stddev_Fields = {
  __typename?: 'writersoftUsageReports_stddev_fields'
  characters?: Maybe<Scalars['Float']>
  variations?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "writersoftUsageReports" */
export type WritersoftUsageReports_Stddev_Order_By = {
  characters?: InputMaybe<Order_By>
  variations?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type WritersoftUsageReports_Stddev_Pop_Fields = {
  __typename?: 'writersoftUsageReports_stddev_pop_fields'
  characters?: Maybe<Scalars['Float']>
  variations?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "writersoftUsageReports" */
export type WritersoftUsageReports_Stddev_Pop_Order_By = {
  characters?: InputMaybe<Order_By>
  variations?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type WritersoftUsageReports_Stddev_Samp_Fields = {
  __typename?: 'writersoftUsageReports_stddev_samp_fields'
  characters?: Maybe<Scalars['Float']>
  variations?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "writersoftUsageReports" */
export type WritersoftUsageReports_Stddev_Samp_Order_By = {
  characters?: InputMaybe<Order_By>
  variations?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "writersoftUsageReports" */
export type WritersoftUsageReports_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: WritersoftUsageReports_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type WritersoftUsageReports_Stream_Cursor_Value_Input = {
  appId?: InputMaybe<Scalars['String']>
  characters?: InputMaybe<Scalars['Int']>
  createdAt?: InputMaybe<Scalars['timestamptz']>
  format?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  inputContexts?: InputMaybe<Scalars['jsonb']>
  languageId?: InputMaybe<Scalars['String']>
  response?: InputMaybe<Scalars['jsonb']>
  sentAt?: InputMaybe<Scalars['timestamp']>
  stripeResponse?: InputMaybe<Scalars['jsonb']>
  stripeSubscriptionItemId?: InputMaybe<Scalars['String']>
  toneId?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['timestamptz']>
  useCaseId?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['uuid']>
  variations?: InputMaybe<Scalars['Int']>
}

/** aggregate sum on columns */
export type WritersoftUsageReports_Sum_Fields = {
  __typename?: 'writersoftUsageReports_sum_fields'
  characters?: Maybe<Scalars['Int']>
  variations?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "writersoftUsageReports" */
export type WritersoftUsageReports_Sum_Order_By = {
  characters?: InputMaybe<Order_By>
  variations?: InputMaybe<Order_By>
}

/** update columns of table "writersoftUsageReports" */
export enum WritersoftUsageReports_Update_Column {
  /** column name */
  AppId = 'appId',
  /** column name */
  Characters = 'characters',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Format = 'format',
  /** column name */
  Id = 'id',
  /** column name */
  InputContexts = 'inputContexts',
  /** column name */
  LanguageId = 'languageId',
  /** column name */
  Response = 'response',
  /** column name */
  SentAt = 'sentAt',
  /** column name */
  StripeResponse = 'stripeResponse',
  /** column name */
  StripeSubscriptionItemId = 'stripeSubscriptionItemId',
  /** column name */
  ToneId = 'toneId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UseCaseId = 'useCaseId',
  /** column name */
  UserId = 'userId',
  /** column name */
  Variations = 'variations',
}

export type WritersoftUsageReports_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<WritersoftUsageReports_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<WritersoftUsageReports_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<WritersoftUsageReports_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<WritersoftUsageReports_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<WritersoftUsageReports_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<WritersoftUsageReports_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WritersoftUsageReports_Set_Input>
  /** filter the rows which have to be updated */
  where: WritersoftUsageReports_Bool_Exp
}

/** aggregate var_pop on columns */
export type WritersoftUsageReports_Var_Pop_Fields = {
  __typename?: 'writersoftUsageReports_var_pop_fields'
  characters?: Maybe<Scalars['Float']>
  variations?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "writersoftUsageReports" */
export type WritersoftUsageReports_Var_Pop_Order_By = {
  characters?: InputMaybe<Order_By>
  variations?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type WritersoftUsageReports_Var_Samp_Fields = {
  __typename?: 'writersoftUsageReports_var_samp_fields'
  characters?: Maybe<Scalars['Float']>
  variations?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "writersoftUsageReports" */
export type WritersoftUsageReports_Var_Samp_Order_By = {
  characters?: InputMaybe<Order_By>
  variations?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type WritersoftUsageReports_Variance_Fields = {
  __typename?: 'writersoftUsageReports_variance_fields'
  characters?: Maybe<Scalars['Float']>
  variations?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "writersoftUsageReports" */
export type WritersoftUsageReports_Variance_Order_By = {
  characters?: InputMaybe<Order_By>
  variations?: InputMaybe<Order_By>
}

export type AICompletionRecordsFragment = {
  __typename?: 'aICompletionRecords'
  id: any
  clientName?: string | null
  meta?: any | null
  createdAt: any
  content?: string | null
  completedAt?: any | null
  updatedAt: any
}

export type AdminInsertNewebpayEpgNotificationMutationVariables = Exact<{
  object: NewebpayEpgNotifications_Insert_Input
}>

export type AdminInsertNewebpayEpgNotificationMutation = {
  __typename?: 'mutation_root'
  insert_newebpayEpgNotifications_one?: {
    __typename?: 'newebpayEpgNotifications'
    amt: number
    checkCode: string
    consigneeName?: string | null
    createdAt: any
    data: any
    paymentType: string
    merchantId: string
    merchantOrderNo: string
    payerAddr?: string | null
    payerEmail?: string | null
    payerTel?: string | null
    payerName?: string | null
    quantity: number
    status: string
    tradeNo: string
    unitPrice: number
    updatedAt: any
    id: any
    isValid?: boolean | null
  } | null
}

export type AdminUpsertUsersOneForLoginMutationVariables = Exact<{
  name: Scalars['String']
  nickname: Scalars['String']
  profileUrl: Scalars['String']
  auth0userId: Scalars['String']
  appId: Scalars['String']
  email?: InputMaybe<Scalars['String']>
}>

export type AdminUpsertUsersOneForLoginMutation = {
  __typename?: 'mutation_root'
  insert_users_one?: {
    __typename?: 'users'
    updatedAt: any
    profileUrl?: string | null
    nickname?: string | null
    name: string
    id: any
    email?: string | null
    createdAt: any
    auth0userId: string
    appId: string
    stripeCustomerId?: string | null
    app: {
      __typename?: 'apps'
      id: string
      stripeWritersoftLookupKey?: string | null
      stripeWritersoftConfigurationId?: string | null
    }
    subscriptions: Array<{
      __typename?: 'subscriptions'
      stripeStatus?: string | null
      subscriptionItems: Array<{
        __typename?: 'subscriptionItems'
        stripeSubscriptionItemId: string
        id: any
      }>
    }>
  } | null
}

export type AdminInsertWritersoftUsageReportOneMutationVariables = Exact<{
  appId: Scalars['String']
  characters: Scalars['Int']
  format: Scalars['String']
  inputContexts: Scalars['jsonb']
  languageId: Scalars['String']
  response?: InputMaybe<Scalars['jsonb']>
  toneId: Scalars['String']
  userId?: InputMaybe<Scalars['uuid']>
  variations: Scalars['Int']
  useCaseId: Scalars['String']
  stripeSubscriptionItemId?: InputMaybe<Scalars['String']>
}>

export type AdminInsertWritersoftUsageReportOneMutation = {
  __typename?: 'mutation_root'
  insert_writersoftUsageReports_one?: {
    __typename?: 'writersoftUsageReports'
    appId: string
    characters: number
    createdAt: any
    format: string
    id: any
    inputContexts: any
    languageId: string
    response?: any | null
    toneId: string
    updatedAt: any
    useCaseId: string
    userId?: any | null
    variations: number
    stripeSubscriptionItemId?: string | null
  } | null
}

export type UserUpdateUserByPkMutationVariables = Exact<{
  id: Scalars['uuid']
  user: Users_Set_Input
}>

export type UserUpdateUserByPkMutation = {
  __typename?: 'mutation_root'
  update_users_by_pk?: {
    __typename?: 'users'
    appId: string
    auth0userId: string
    email?: string | null
    id: any
    name: string
    nickname?: string | null
    profileUrl?: string | null
    stripeCustomerId?: string | null
  } | null
}

export type AdminInsertStripeWebhookOneMutationVariables = Exact<{
  data: Scalars['jsonb']
  eventType: Scalars['String']
}>

export type AdminInsertStripeWebhookOneMutation = {
  __typename?: 'mutation_root'
  insert_stripeWebhookLogs_one?: {
    __typename?: 'stripeWebhookLogs'
    id: any
    eventType?: string | null
    data?: any | null
    updatedAt: any
    createdAt: any
  } | null
}

export type AdminUpdateSubscriptionStripeDataByStripeSubscriptionIdMutationVariables = Exact<{
  stripeSubscriptionId: Scalars['String']
  setStripeStatus: Scalars['String']
}>

export type AdminUpdateSubscriptionStripeDataByStripeSubscriptionIdMutation = {
  __typename?: 'mutation_root'
  update_subscriptions?: {
    __typename?: 'subscriptions_mutation_response'
    affected_rows: number
  } | null
}

export type AdminUpdateSubscriptionStripeDataMutationVariables = Exact<{
  setStripeSubscriptionId: Scalars['String']
  stripeCheckoutSessionId: Scalars['String']
  setStripeStatus: Scalars['String']
  subscriptionItems: Array<SubscriptionItems_Insert_Input> | SubscriptionItems_Insert_Input
}>

export type AdminUpdateSubscriptionStripeDataMutation = {
  __typename?: 'mutation_root'
  update_subscriptions?: {
    __typename?: 'subscriptions_mutation_response'
    affected_rows: number
  } | null
  insert_subscriptionItems?: {
    __typename?: 'subscriptionItems_mutation_response'
    affected_rows: number
    returning: Array<{
      __typename?: 'subscriptionItems'
      appId: string
      createdAt: any
      id: any
      stripeSubscriptionItemId: string
      subscriptionId: any
      updatedAt: any
    }>
  } | null
}

export type AdminUpdateUnsentStripeRecordMutationVariables = Exact<{
  id: Scalars['uuid']
  sentAt: Scalars['timestamp']
  stripeResponse: Scalars['jsonb']
}>

export type AdminUpdateUnsentStripeRecordMutation = {
  __typename?: 'mutation_root'
  update_writersoftUsageReports_by_pk?: {
    __typename?: 'writersoftUsageReports'
    id: any
    sentAt?: any | null
    stripeResponse?: any | null
  } | null
}

export type AdminInitSubscriptionOneMutationVariables = Exact<{
  object: Subscriptions_Insert_Input
}>

export type AdminInitSubscriptionOneMutation = {
  __typename?: 'mutation_root'
  insert_subscriptions_one?: {
    __typename?: 'subscriptions'
    appId: string
    createdAt: any
    id: any
    stripeCheckoutSessionId?: string | null
    stripeStatus?: string | null
    stripeSubscriptionId?: string | null
    userId: any
    updatedAt: any
    subscriptionItems: Array<{
      __typename?: 'subscriptionItems'
      id: any
      stripeSubscriptionItemId: string
      appId: string
      createdAt: any
      updatedAt: any
    }>
  } | null
}

export type AdminFillInTheSentAtColumnMutationVariables = Exact<{
  sentAt: Scalars['timestamp']
}>

export type AdminFillInTheSentAtColumnMutation = {
  __typename?: 'mutation_root'
  update_writersoftUsageReports?: {
    __typename?: 'writersoftUsageReports_mutation_response'
    affected_rows: number
  } | null
}

export type UserCreateStripeCheckoutSessionsMutationVariables = Exact<{ [key: string]: never }>

export type UserCreateStripeCheckoutSessionsMutation = {
  __typename?: 'mutation_root'
  createStripeCheckoutSessions?: {
    __typename?: 'CreateStripeCheckoutSession'
    redirectUrl: string
  } | null
}

export type UserCreatePortalSessionsMutationVariables = Exact<{ [key: string]: never }>

export type UserCreatePortalSessionsMutation = {
  __typename?: 'mutation_root'
  createPortalSessions?: { __typename?: 'CreatePortalSession'; redirectUrl: string } | null
}

export type AdminInsertPromptUsagesOneMutationVariables = Exact<{
  object: PromptUsages_Insert_Input
}>

export type AdminInsertPromptUsagesOneMutation = {
  __typename?: 'mutation_root'
  insert_promptUsages_one?: {
    __typename?: 'promptUsages'
    appId?: string | null
    createdAt: any
    discordUserId?: string | null
    id: any
    language: string
    model: string
    object: string
    promptId: string
    response: any
    updatedAt: any
    usageCompletionTokens: number
    usagePromptTokens: number
    usageTotalTokens: number
    userPrompt: string
  } | null
}

export type AdminTruncateDiscordMessagesMutationVariables = Exact<{
  cratedAtLte: Scalars['timestamptz']
}>

export type AdminTruncateDiscordMessagesMutation = {
  __typename?: 'mutation_root'
  delete_discordMessages?: {
    __typename?: 'discordMessages_mutation_response'
    affected_rows: number
  } | null
}

export type AdminInsertAiCompletionRecordsOneMutationVariables = Exact<{
  object: AICompletionRecords_Insert_Input
}>

export type AdminInsertAiCompletionRecordsOneMutation = {
  __typename?: 'mutation_root'
  insert_aICompletionRecords_one?: {
    __typename?: 'aICompletionRecords'
    id: any
    clientName?: string | null
    meta?: any | null
    createdAt: any
    content?: string | null
    completedAt?: any | null
    updatedAt: any
  } | null
}

export type AdminFetchNewebpayEpgNotificationsCountQueryVariables = Exact<{ [key: string]: never }>

export type AdminFetchNewebpayEpgNotificationsCountQuery = {
  __typename?: 'query_root'
  newebpayEpgNotifications_aggregate: {
    __typename?: 'newebpayEpgNotifications_aggregate'
    aggregate?: { __typename?: 'newebpayEpgNotifications_aggregate_fields'; count: number } | null
  }
}

export type AdminFetchSystemUsageByUserQueryVariables = Exact<{ [key: string]: never }>

export type AdminFetchSystemUsageByUserQuery = {
  __typename?: 'query_root'
  users: Array<{
    __typename?: 'users'
    appId: string
    email?: string | null
    name: string
    writersoftUsageReports_aggregate: {
      __typename?: 'writersoftUsageReports_aggregate'
      aggregate?: {
        __typename?: 'writersoftUsageReports_aggregate_fields'
        count: number
        sum?: {
          __typename?: 'writersoftUsageReports_sum_fields'
          characters?: number | null
        } | null
      } | null
    }
  }>
  apps: Array<{
    __typename?: 'apps'
    id: string
    writersoftUsageReports_aggregate: {
      __typename?: 'writersoftUsageReports_aggregate'
      aggregate?: {
        __typename?: 'writersoftUsageReports_aggregate_fields'
        count: number
        sum?: {
          __typename?: 'writersoftUsageReports_sum_fields'
          characters?: number | null
        } | null
      } | null
    }
  }>
}

export type AdminFetchFormDataByContentfulFormIdQueryVariables = Exact<{
  condition: Forms_Bool_Exp
}>

export type AdminFetchFormDataByContentfulFormIdQuery = {
  __typename?: 'query_root'
  forms: Array<{
    __typename?: 'forms'
    id: any
    createdAt: any
    updatedAt: any
    formAnswers: Array<{
      __typename?: 'formAnswers'
      id: any
      type: string
      value: string
      contentfulFormComponentId: string
      createdAt: any
      updatedAt: any
    }>
  }>
}

export type AdminFetchFormByPkQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type AdminFetchFormByPkQuery = {
  __typename?: 'query_root'
  forms_by_pk?: {
    __typename?: 'forms'
    id: any
    createdAt: any
    updatedAt: any
    contentfulFormId: string
    formAnswers: Array<{
      __typename?: 'formAnswers'
      id: any
      type: string
      value: string
      contentfulFormComponentId: string
      createdAt: any
      updatedAt: any
    }>
  } | null
}

export type AdminFetchSectionFormComponentsByFormIdQueryVariables = Exact<{
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type AdminFetchSectionFormComponentsByFormIdQuery = {
  __typename?: 'query_root'
  sectionsForm?: {
    __typename?: 'SectionsForm'
    componentsCollection?: {
      __typename?: 'SectionsFormComponentsCollection'
      items: Array<{
        __typename?: 'SectionsFormComponent'
        type?: string | null
        label?: string | null
        sys: { __typename?: 'Sys'; id: string }
      } | null>
    } | null
  } | null
}

export type AdminSearchErc721TokenMetadataAttributeTraitTypeCollectionQueryVariables = Exact<{
  title?: InputMaybe<Scalars['String']>
}>

export type AdminSearchErc721TokenMetadataAttributeTraitTypeCollectionQuery = {
  __typename?: 'query_root'
  erc721TokenMetadataAttributeTraitTypeCollection?: {
    __typename?: 'Erc721TokenMetadataAttributeTraitTypeCollection'
    total: number
    items: Array<{
      __typename?: 'Erc721TokenMetadataAttributeTraitType'
      sys: { __typename?: 'Sys'; id: string }
    } | null>
  } | null
}

export type AdminFetchSmartContractErc721CollectionQueryVariables = Exact<{ [key: string]: never }>

export type AdminFetchSmartContractErc721CollectionQuery = {
  __typename?: 'query_root'
  smartContractErc721Collection?: {
    __typename?: 'SmartContractErc721Collection'
    total: number
    items: Array<{
      __typename?: 'SmartContractErc721'
      smartContractAddress?: string | null
      sys: { __typename?: 'Sys'; id: string }
    } | null>
  } | null
}

export type UserFetchFormDataByContentfulFormIdQueryVariables = Exact<{
  contentfulFormId: Scalars['String']
}>

export type UserFetchFormDataByContentfulFormIdQuery = {
  __typename?: 'query_root'
  forms: Array<{
    __typename?: 'forms'
    id: any
    contentfulFormId: string
    createdAt: any
    updatedAt: any
    formAnswers: Array<{
      __typename?: 'formAnswers'
      id: any
      type: string
      value: string
      contentfulFormComponentId: string
      createdAt: any
      updatedAt: any
    }>
  }>
}

export type AdminFetchActiveSubscriptionCountQueryVariables = Exact<{
  userId: Scalars['uuid']
  appId: Scalars['String']
}>

export type AdminFetchActiveSubscriptionCountQuery = {
  __typename?: 'query_root'
  subscriptions_aggregate: {
    __typename?: 'subscriptions_aggregate'
    aggregate?: { __typename?: 'subscriptions_aggregate_fields'; count: number } | null
    nodes: Array<{
      __typename?: 'subscriptions'
      id: any
      stripeStatus?: string | null
      stripeCheckoutSessionId?: string | null
      stripeSubscriptionId?: string | null
      appId: string
    }>
  }
}

export type UserFetchActiveSubscriptionByAuth0IdQueryVariables = Exact<{
  auth0Id: Scalars['String']
}>

export type UserFetchActiveSubscriptionByAuth0IdQuery = {
  __typename?: 'query_root'
  subscriptions: Array<{
    __typename?: 'subscriptions'
    id: any
    stripeStatus?: string | null
    createdAt: any
    updatedAt: any
  }>
}

export type AdminFetchSubscriptionByStripeCheckoutSessionIdQueryVariables = Exact<{
  stripeCheckoutSessionId: Scalars['String']
}>

export type AdminFetchSubscriptionByStripeCheckoutSessionIdQuery = {
  __typename?: 'query_root'
  subscriptions: Array<{
    __typename?: 'subscriptions'
    appId: string
    createdAt: any
    id: any
    stripeCheckoutSessionId?: string | null
    stripeStatus?: string | null
    stripeSubscriptionId?: string | null
    updatedAt: any
    userId: any
  }>
}

export type AdminFetchUnsentUsageReportQueryVariables = Exact<{ [key: string]: never }>

export type AdminFetchUnsentUsageReportQuery = {
  __typename?: 'query_root'
  writersoftUsageReports: Array<{
    __typename?: 'writersoftUsageReports'
    id: any
    createdAt: any
    characters: number
    appId: string
    sentAt?: any | null
    userId?: any | null
    stripeSubscriptionItemId?: string | null
  }>
}

export type AdminSearchErc721TokenMetadataCollectionQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
}>

export type AdminSearchErc721TokenMetadataCollectionQuery = {
  __typename?: 'query_root'
  erc721TokenMetadataCollection?: {
    __typename?: 'Erc721TokenMetadataCollection'
    total: number
    items: Array<{
      __typename?: 'Erc721TokenMetadata'
      tokenId?: string | null
      sys: { __typename?: 'Sys'; id: string }
    } | null>
  } | null
}

export type AdminFetchTruncatedDiscordMessaggeQueryVariables = Exact<{
  offset: Scalars['Int']
}>

export type AdminFetchTruncatedDiscordMessaggeQuery = {
  __typename?: 'query_root'
  discordMessages: Array<{ __typename?: 'discordMessages'; id: any; createdAt: any }>
}

export type AdminFetchAiCompletionRecordsByPkQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type AdminFetchAiCompletionRecordsByPkQuery = {
  __typename?: 'query_root'
  aICompletionRecords_by_pk?: {
    __typename?: 'aICompletionRecords'
    id: any
    clientName?: string | null
    meta?: any | null
    createdAt: any
    content?: string | null
    completedAt?: any | null
    updatedAt: any
  } | null
}

export type SeoMetadataFragmentFragment = {
  __typename?: 'SeoMetadata'
  name?: string | null
  content?: string | null
}

export type TagsForAllProductsFragment = {
  __typename?: 'Tags'
  name?: string | null
  groupName?: string | null
  sys: { __typename?: 'Sys'; id: string }
}

export type SocialMediaLinkFragment = {
  __typename?: 'SocialMediaLink'
  title?: string | null
  link?: string | null
  type?: string | null
  isExternal?: boolean | null
  overwriteDisplayIconWithIconifyIcon?: string | null
}

export type PromptEngineeringAdminFragment = {
  __typename?: 'PromptEngineering'
  prompt?: string | null
  purpose?: string | null
  sys: { __typename?: 'Sys'; id: string }
}

export type RootSettingsFragment = {
  __typename?: 'RootSettings'
  sectionColors?: Array<string | null> | null
  scope?: string | null
  companyColor?: string | null
  theme?: string | null
  renamePathDisplayTextBlog?: string | null
  renamePathDisplayTextHome?: string | null
  renamePathDisplayTextProduct?: string | null
  renameCallToActionLinksDisplayText?: string | null
  companyLogo?: { __typename?: 'Asset'; url?: string | null } | null
  companyFavicon?: { __typename?: 'Asset'; url?: string | null } | null
  callToActionLink?: {
    __typename?: 'CallToActionLink'
    title?: string | null
    link?: string | null
  } | null
  seoMetadataItemsCollection?: {
    __typename?: 'RootSettingsSeoMetadataItemsCollection'
    items: Array<{
      __typename?: 'SeoMetadata'
      name?: string | null
      content?: string | null
    } | null>
  } | null
  socialMediaLinksCollection?: {
    __typename?: 'RootSettingsSocialMediaLinksCollection'
    items: Array<{
      __typename?: 'SocialMediaLink'
      title?: string | null
      link?: string | null
      type?: string | null
      isExternal?: boolean | null
      overwriteDisplayIconWithIconifyIcon?: string | null
    } | null>
  } | null
  headerDisplayPagesCollection?: {
    __typename?: 'RootSettingsHeaderDisplayPagesCollection'
    items: Array<{
      __typename?: 'Pages'
      slug?: string | null
      displayName?: string | null
      iconifyIconNameForMobileMenu?: string | null
    } | null>
  } | null
  mobileDrawerDisplayPagesCollection?: {
    __typename?: 'RootSettingsMobileDrawerDisplayPagesCollection'
    items: Array<{
      __typename?: 'Pages'
      slug?: string | null
      displayName?: string | null
      iconifyIconNameForMobileMenu?: string | null
    } | null>
  } | null
  promptEngineeringsCollection?: {
    __typename?: 'RootSettingsPromptEngineeringsCollection'
    items: Array<{
      __typename?: 'PromptEngineering'
      title?: string | null
      promptHint?: string | null
      teaser?: string | null
      purpose?: string | null
      sys: { __typename?: 'Sys'; id: string }
    } | null>
  } | null
}

export type DisplayPagesFragment = {
  __typename?: 'Pages'
  slug?: string | null
  displayName?: string | null
  iconifyIconNameForMobileMenu?: string | null
}

export type BlogsContentAssetsFragment = {
  __typename?: 'BlogsContentAssets'
  block: Array<{
    __typename?: 'Asset'
    url?: string | null
    width?: number | null
    height?: number | null
    description?: string | null
    sys: { __typename?: 'Sys'; id: string }
  } | null>
}

export type TagsContentAssetsFragment = {
  __typename?: 'TagsContentAssets'
  block: Array<{
    __typename?: 'Asset'
    url?: string | null
    width?: number | null
    height?: number | null
    description?: string | null
    sys: { __typename?: 'Sys'; id: string }
  } | null>
}

export type SectionsMinimalsVideoFeatureDescriptionAssetsFragment = {
  __typename?: 'SectionsMinimalsVideoFeatureDescriptionAssets'
  block: Array<{
    __typename?: 'Asset'
    url?: string | null
    width?: number | null
    height?: number | null
    description?: string | null
    sys: { __typename?: 'Sys'; id: string }
  } | null>
}

export type AssetFragment = {
  __typename?: 'Asset'
  url?: string | null
  width?: number | null
  height?: number | null
  description?: string | null
  sys: { __typename?: 'Sys'; id: string }
}

export type ContextInputFragmentFragment = {
  __typename?: 'ContextInput'
  _id: string
  inputMaximumCharacters: number
  inputType: string
  isRequired?: boolean | null
  keyLabel: string
  label: string
  placeholder: string
}

export type SupportingLanguageFragmentFragment = {
  __typename?: 'SupportingLanguage'
  _id: string
  name: string
  slug: string
}

export type SupportingToneFragmentFragment = {
  __typename?: 'SupportingTone'
  _id: string
  name: string
  slug: string
}

export type SupportingUseCaseFragmentFragment = {
  __typename?: 'SupportingUseCase'
  _id: string
  caption: string
  createdAt?: string | null
  description: string
  image: string
  info: string
  key: string
  name: string
  slug: string
  variants: number
  contextInputs: Array<{
    __typename?: 'ContextInput'
    _id: string
    inputMaximumCharacters: number
    inputType: string
    isRequired?: boolean | null
    keyLabel: string
    label: string
    placeholder: string
  }>
}

export type Erc721TokenMetadataFragmentFragment = {
  __typename?: 'Erc721TokenMetadata'
  tokenId?: string | null
  name?: string | null
  description?: string | null
  externalUrl?: string | null
  backgroundColor?: string | null
  youtubeUrl?: string | null
  sys: { __typename?: 'Sys'; id: string }
  image?: { __typename?: 'Asset'; url?: string | null } | null
  animationUrl?: { __typename?: 'Asset'; url?: string | null } | null
  attributesCollection?: {
    __typename?: 'Erc721TokenMetadataAttributesCollection'
    items: Array<{
      __typename?: 'Erc721TokenMetadataAttributesText'
      displayType?: string | null
      value?: string | null
      traitType?: {
        __typename?: 'Erc721TokenMetadataAttributeTraitType'
        title?: string | null
      } | null
    } | null>
  } | null
}

export type SectionsMintListFragmentFragment = {
  __typename?: 'SectionsMintList'
  title?: string | null
  description?: string | null
  key: 'SectionsMintList'
  mintCardsCollection?: {
    __typename?: 'SectionsMintListMintCardsCollection'
    items: Array<{
      __typename?: 'SectionsMintListComponent'
      title?: string | null
      description?: string | null
      price?: number | null
      style?: string | null
      smartContractCallToActionName?: string | null
      isDisplay?: boolean | null
      smartContractErc721?: {
        __typename?: 'SmartContractErc721'
        smartContractAddress?: string | null
        network?: string | null
        abi?: any | null
        isSingleTokenMetadataMode?: boolean | null
        logo?: { __typename?: 'Asset'; url?: string | null } | null
        bannerImage?: { __typename?: 'Asset'; url?: string | null } | null
        featureImage?: { __typename?: 'Asset'; url?: string | null } | null
        displayImage?: { __typename?: 'Asset'; url?: string | null } | null
        singleToken?: {
          __typename?: 'Erc721TokenMetadata'
          sys: { __typename?: 'Sys'; id: string }
        } | null
        whitelistAddressesCollection?: {
          __typename?: 'SmartContractErc721WhitelistAddressesCollection'
          items: Array<{
            __typename?: 'SmartContractErc721WhitelistAddress'
            name?: string | null
            ethAddress?: string | null
            alreadyMinted?: boolean | null
          } | null>
        } | null
      } | null
      dependencySmartContractErc721?: {
        __typename?: 'SmartContractErc721'
        smartContractAddress?: string | null
        network?: string | null
        abi?: any | null
        isSingleTokenMetadataMode?: boolean | null
        logo?: { __typename?: 'Asset'; url?: string | null } | null
        bannerImage?: { __typename?: 'Asset'; url?: string | null } | null
        featureImage?: { __typename?: 'Asset'; url?: string | null } | null
        displayImage?: { __typename?: 'Asset'; url?: string | null } | null
        singleToken?: {
          __typename?: 'Erc721TokenMetadata'
          sys: { __typename?: 'Sys'; id: string }
        } | null
        whitelistAddressesCollection?: {
          __typename?: 'SmartContractErc721WhitelistAddressesCollection'
          items: Array<{
            __typename?: 'SmartContractErc721WhitelistAddress'
            name?: string | null
            ethAddress?: string | null
            alreadyMinted?: boolean | null
          } | null>
        } | null
      } | null
    } | null>
  } | null
}

export type SectionsMintListComponentFragmentFragment = {
  __typename?: 'SectionsMintListComponent'
  title?: string | null
  description?: string | null
  price?: number | null
  style?: string | null
  smartContractCallToActionName?: string | null
  isDisplay?: boolean | null
  smartContractErc721?: {
    __typename?: 'SmartContractErc721'
    smartContractAddress?: string | null
    network?: string | null
    abi?: any | null
    isSingleTokenMetadataMode?: boolean | null
    logo?: { __typename?: 'Asset'; url?: string | null } | null
    bannerImage?: { __typename?: 'Asset'; url?: string | null } | null
    featureImage?: { __typename?: 'Asset'; url?: string | null } | null
    displayImage?: { __typename?: 'Asset'; url?: string | null } | null
    singleToken?: {
      __typename?: 'Erc721TokenMetadata'
      sys: { __typename?: 'Sys'; id: string }
    } | null
    whitelistAddressesCollection?: {
      __typename?: 'SmartContractErc721WhitelistAddressesCollection'
      items: Array<{
        __typename?: 'SmartContractErc721WhitelistAddress'
        name?: string | null
        ethAddress?: string | null
        alreadyMinted?: boolean | null
      } | null>
    } | null
  } | null
  dependencySmartContractErc721?: {
    __typename?: 'SmartContractErc721'
    smartContractAddress?: string | null
    network?: string | null
    abi?: any | null
    isSingleTokenMetadataMode?: boolean | null
    logo?: { __typename?: 'Asset'; url?: string | null } | null
    bannerImage?: { __typename?: 'Asset'; url?: string | null } | null
    featureImage?: { __typename?: 'Asset'; url?: string | null } | null
    displayImage?: { __typename?: 'Asset'; url?: string | null } | null
    singleToken?: {
      __typename?: 'Erc721TokenMetadata'
      sys: { __typename?: 'Sys'; id: string }
    } | null
    whitelistAddressesCollection?: {
      __typename?: 'SmartContractErc721WhitelistAddressesCollection'
      items: Array<{
        __typename?: 'SmartContractErc721WhitelistAddress'
        name?: string | null
        ethAddress?: string | null
        alreadyMinted?: boolean | null
      } | null>
    } | null
  } | null
}

export type SmartContractErc721FragmentFragment = {
  __typename?: 'SmartContractErc721'
  smartContractAddress?: string | null
  network?: string | null
  abi?: any | null
  isSingleTokenMetadataMode?: boolean | null
  logo?: { __typename?: 'Asset'; url?: string | null } | null
  bannerImage?: { __typename?: 'Asset'; url?: string | null } | null
  featureImage?: { __typename?: 'Asset'; url?: string | null } | null
  displayImage?: { __typename?: 'Asset'; url?: string | null } | null
  singleToken?: {
    __typename?: 'Erc721TokenMetadata'
    sys: { __typename?: 'Sys'; id: string }
  } | null
  whitelistAddressesCollection?: {
    __typename?: 'SmartContractErc721WhitelistAddressesCollection'
    items: Array<{
      __typename?: 'SmartContractErc721WhitelistAddress'
      name?: string | null
      ethAddress?: string | null
      alreadyMinted?: boolean | null
    } | null>
  } | null
}

export type SectionsFeaturesListFragmentFragment = {
  __typename?: 'SectionsFeaturesList'
  title?: string | null
  description?: string | null
  key: 'SectionsFeaturesList'
  componentsCollection?: {
    __typename?: 'SectionsFeaturesListComponentsCollection'
    items: Array<{
      __typename?: 'SectionsFeaturesListComponent'
      title?: string | null
      description?: string | null
      iconifyIconName?: string | null
      icon?: { __typename?: 'Asset'; url?: string | null } | null
    } | null>
  } | null
}

export type SectionsMinimalsContactListFragmentFragment = {
  __typename?: 'SectionsMinimalsContactList'
  title?: string | null
  description?: string | null
  key: 'SectionsMinimalsContactList'
  linksCollection?: {
    __typename?: 'SectionsMinimalsContactListLinksCollection'
    items: Array<{
      __typename?: 'SectionsMinimalsContactComponent'
      title?: string | null
      href?: string | null
    } | null>
  } | null
}

export type SectionsTimerFragmentFragment = {
  __typename?: 'SectionsTimer'
  title?: string | null
  description?: string | null
  startTime?: any | null
  appearance?: string | null
  key: 'SectionsTimer'
  backgroundImage?: { __typename?: 'Asset'; url?: string | null } | null
}

export type SectionsMinimalsHistoryListFragmentFragment = {
  __typename?: 'SectionsMinimalsHistoryList'
  title?: string | null
  description?: string | null
  componentDateFormat?: string | null
  key: 'SectionsMinimalsHistoryList'
  componentsCollection?: {
    __typename?: 'SectionsMinimalsHistoryListComponentsCollection'
    items: Array<{
      __typename?: 'SectionsMinimalsHistoryComponent'
      title?: string | null
      description?: string | null
      displayTime?: any | null
      featureImage?: { __typename?: 'Asset'; url?: string | null } | null
    } | null>
  } | null
}

export type SectionsMinimalsGalleryFragmentFragment = {
  __typename?: 'SectionsMinimalsGallery'
  title?: string | null
  description?: string | null
  key: 'SectionsMinimalsGallery'
  displayItemsCollection?: {
    __typename?: 'SectionsMinimalsGalleryDisplayItemsCollection'
    items: Array<{
      __typename?: 'SectionsMinimalsImagesList'
      title?: string | null
      description?: string | null
      sys: { __typename?: 'Sys'; id: string }
      imagesCollection?: {
        __typename?: 'AssetCollection'
        items: Array<{
          __typename?: 'Asset'
          url?: string | null
          title?: string | null
          description?: string | null
          sys: { __typename?: 'Sys'; id: string }
        } | null>
      } | null
    } | null>
  } | null
}

export type GalleryAssetFragment = {
  __typename?: 'Asset'
  url?: string | null
  title?: string | null
  description?: string | null
  sys: { __typename?: 'Sys'; id: string }
}

export type SectionsMinimalsImagesListForGalleryFragment = {
  __typename?: 'SectionsMinimalsImagesList'
  title?: string | null
  description?: string | null
  displayMode?: string | null
  gridModeColumnsCountDesktop?: number | null
  gridModeColumnsCountMobile?: number | null
  key: 'SectionsMinimalsImagesList'
  imagesCollection?: {
    __typename?: 'AssetCollection'
    items: Array<{
      __typename?: 'Asset'
      url?: string | null
      title?: string | null
      description?: string | null
      sys: { __typename?: 'Sys'; id: string }
    } | null>
  } | null
}

export type SectionsFaqListFragmentFragment = {
  __typename?: 'SectionsFaqList'
  title?: string | null
  description?: string | null
  key: 'SectionsFaqList'
  componentsCollection?: {
    __typename?: 'SectionsFaqListComponentsCollection'
    items: Array<{
      __typename?: 'SectionsFaqComponent'
      title?: string | null
      content?: { __typename?: 'SectionsFaqComponentContent'; json: any } | null
    } | null>
  } | null
}

export type SectionsDetailPersonListFragmentFragment = {
  __typename?: 'SectionsDetailPersonList'
  title?: string | null
  description?: string | null
  key: 'SectionsDetailPersonList'
  componentsCollection?: {
    __typename?: 'SectionsDetailPersonListComponentsCollection'
    items: Array<{
      __typename?: 'SectionsDetailPersonComponent'
      title?: string | null
      titleHref?: string | null
      description?: string | null
      descriptionHref?: string | null
      detail?: { __typename?: 'SectionsDetailPersonComponentDetail'; json: any } | null
      avatar?: { __typename?: 'Asset'; url?: string | null } | null
    } | null>
  } | null
}

export type SectionsMinimalsHumanListFragmentFragment = {
  __typename?: 'SectionsMinimalsHumanList'
  title?: string | null
  description?: string | null
  key: 'SectionsMinimalsHumanList'
  componentsCollection?: {
    __typename?: 'SectionsMinimalsHumanListComponentsCollection'
    items: Array<{
      __typename?: 'SectionsMinimalsHumanComponent'
      title?: string | null
      description?: string | null
      descriptionHref?: string | null
      featureImage?: { __typename?: 'Asset'; url?: string | null } | null
    } | null>
  } | null
}

export type SectionsMinimalsImagesListFragmentFragment = {
  __typename?: 'SectionsMinimalsImagesList'
  title?: string | null
  description?: string | null
  displayMode?: string | null
  gridModeColumnsCountDesktop?: number | null
  gridModeColumnsCountMobile?: number | null
  gridModeGapDesktop?: number | null
  gridModeGapMobile?: number | null
  key: 'SectionsMinimalsImagesList'
  imagesCollection?: {
    __typename?: 'AssetCollection'
    items: Array<{ __typename?: 'Asset'; url?: string | null } | null>
  } | null
  imagesMobileCollection?: {
    __typename?: 'AssetCollection'
    items: Array<{ __typename?: 'Asset'; url?: string | null } | null>
  } | null
  imagesWithHrefMobileCollection?: {
    __typename?: 'SectionsMinimalsImagesListImagesWithHrefMobileCollection'
    items: Array<{
      __typename?: 'SectionsImage'
      callToActionHref?: string | null
      key: 'SectionsImage'
      sys: { __typename?: 'Sys'; id: string }
      image?: { __typename?: 'Asset'; title?: string | null; url?: string | null } | null
      mobileImage?: { __typename?: 'Asset'; title?: string | null; url?: string | null } | null
    } | null>
  } | null
  imagesWithHrefDesktopCollection?: {
    __typename?: 'SectionsMinimalsImagesListImagesWithHrefDesktopCollection'
    items: Array<{
      __typename?: 'SectionsImage'
      callToActionHref?: string | null
      key: 'SectionsImage'
      sys: { __typename?: 'Sys'; id: string }
      image?: { __typename?: 'Asset'; title?: string | null; url?: string | null } | null
      mobileImage?: { __typename?: 'Asset'; title?: string | null; url?: string | null } | null
    } | null>
  } | null
}

export type SectionsMinimalsServicesFragmentFragment = {
  __typename?: 'SectionsMinimalsServices'
  title?: string | null
  description?: string | null
  key: 'SectionsMinimalsServices'
  componentsCollection?: {
    __typename?: 'SectionsMinimalsServicesComponentsCollection'
    items: Array<{
      __typename?: 'SectionsMinimalsService'
      title?: string | null
      services?: Array<string | null> | null
      callToActionText?: string | null
      callToActionHref?: string | null
    } | null>
  } | null
}

export type SectionsHeroFragmentFragment = {
  __typename?: 'SectionsHero'
  companyName?: string | null
  featureText?: string | null
  descriptionText?: string | null
  callToActionText?: string | null
  callToActionHref?: string | null
  enableAiTrail?: boolean | null
  enableAiTrailDisplayText?: string | null
  enableAiRecommendation?: boolean | null
  enableAiRecommendationDisplayText?: string | null
  key: 'SectionsHero'
  sys: { __typename?: 'Sys'; id: string }
  backgroundImage?: { __typename?: 'Asset'; url?: string | null } | null
  fullWidthBackgroundImageMobile?: { __typename?: 'Asset'; url?: string | null } | null
  fullWidthBackgroundImageDesktop?: { __typename?: 'Asset'; url?: string | null } | null
}

export type SectionsHeroFragmentAdminFragment = {
  __typename?: 'SectionsHero'
  enableAiRecommendationPrompt?: string | null
  enableAiRecommendationTagsPrompt?: string | null
  companyName?: string | null
  featureText?: string | null
  descriptionText?: string | null
  callToActionText?: string | null
  callToActionHref?: string | null
  enableAiTrail?: boolean | null
  enableAiTrailDisplayText?: string | null
  enableAiRecommendation?: boolean | null
  enableAiRecommendationDisplayText?: string | null
  key: 'SectionsHero'
  sys: { __typename?: 'Sys'; id: string }
  backgroundImage?: { __typename?: 'Asset'; url?: string | null } | null
  fullWidthBackgroundImageMobile?: { __typename?: 'Asset'; url?: string | null } | null
  fullWidthBackgroundImageDesktop?: { __typename?: 'Asset'; url?: string | null } | null
}

export type SectionsMinimalsVideoFragmentFragment = {
  __typename?: 'SectionsMinimalsVideo'
  title?: string | null
  description?: string | null
  mode?: string | null
  videoRatio?: string | null
  youtubeEmbedLink?: string | null
  key: 'SectionsMinimalsVideo'
  callToAction?: {
    __typename?: 'CallToActionLink'
    title?: string | null
    link?: string | null
  } | null
  video?: { __typename?: 'Asset'; url?: string | null } | null
  image?: { __typename?: 'Asset'; url?: string | null } | null
  featureDescription?: {
    __typename?: 'SectionsMinimalsVideoFeatureDescription'
    json: any
    links: {
      __typename?: 'SectionsMinimalsVideoFeatureDescriptionLinks'
      assets: {
        __typename?: 'SectionsMinimalsVideoFeatureDescriptionAssets'
        block: Array<{
          __typename?: 'Asset'
          url?: string | null
          width?: number | null
          height?: number | null
          description?: string | null
          sys: { __typename?: 'Sys'; id: string }
        } | null>
      }
    }
  } | null
}

export type SectionsFormFragmentFragment = {
  __typename?: 'SectionsForm'
  name?: string | null
  title?: string | null
  description?: string | null
  callToActionText?: string | null
  callToActionHref?: string | null
  key: 'SectionsForm'
  sys: { __typename?: 'Sys'; id: string }
  componentsCollection?: {
    __typename?: 'SectionsFormComponentsCollection'
    items: Array<{
      __typename?: 'SectionsFormComponent'
      type?: string | null
      label?: string | null
      placeholder?: string | null
      helperText?: string | null
      defaultValue?: string | null
      size?: string | null
      maxLength?: number | null
      minLength?: number | null
      max?: string | null
      min?: string | null
      required?: boolean | null
      disabled?: boolean | null
      readOnly?: boolean | null
      defaultChecked?: boolean | null
      fullWidth?: boolean | null
      pattern?: string | null
      multiple?: boolean | null
      options?: Array<string | null> | null
      step?: number | null
      key: 'SectionsFormComponent'
      sys: { __typename?: 'Sys'; id: string }
    } | null>
  } | null
}

export type SectionsFormComponentFragmentFragment = {
  __typename?: 'SectionsFormComponent'
  type?: string | null
  label?: string | null
  placeholder?: string | null
  helperText?: string | null
  defaultValue?: string | null
  size?: string | null
  maxLength?: number | null
  minLength?: number | null
  max?: string | null
  min?: string | null
  required?: boolean | null
  disabled?: boolean | null
  readOnly?: boolean | null
  defaultChecked?: boolean | null
  fullWidth?: boolean | null
  pattern?: string | null
  multiple?: boolean | null
  options?: Array<string | null> | null
  step?: number | null
  key: 'SectionsFormComponent'
  sys: { __typename?: 'Sys'; id: string }
}

export type SectionsImageFragmentFragment = {
  __typename?: 'SectionsImage'
  callToActionHref?: string | null
  key: 'SectionsImage'
  sys: { __typename?: 'Sys'; id: string }
  image?: { __typename?: 'Asset'; title?: string | null; url?: string | null } | null
  mobileImage?: { __typename?: 'Asset'; title?: string | null; url?: string | null } | null
}

export type SectionsImageFragment = {
  __typename?: 'SectionsImage'
  callToActionHref?: string | null
  key: 'SectionsImage'
  sys: { __typename?: 'Sys'; id: string }
  image?: { __typename?: 'Asset'; title?: string | null; url?: string | null } | null
  mobileImage?: { __typename?: 'Asset'; title?: string | null; url?: string | null } | null
}

export type BlogFragmentFragment = {
  __typename?: 'Blogs'
  title?: string | null
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
  coverImage?: { __typename?: 'Asset'; url?: string | null } | null
  previewImage?: { __typename?: 'Asset'; url?: string | null } | null
  tagsCollection?: {
    __typename?: 'BlogsTagsCollection'
    items: Array<{ __typename?: 'Tags'; name?: string | null } | null>
  } | null
  author?: {
    __typename?: 'Authors'
    name?: string | null
    profileImage?: { __typename?: 'Asset'; url?: string | null } | null
  } | null
}

export type TagFragmentFragment = {
  __typename?: 'Tags'
  slug?: string | null
  name?: string | null
  seoMetadataItemsCollection?: {
    __typename?: 'TagsSeoMetadataItemsCollection'
    items: Array<
      | { __typename?: 'Authors' }
      | { __typename?: 'Blogs' }
      | { __typename?: 'CallToActionLink' }
      | { __typename?: 'Categories' }
      | { __typename?: 'Embeddings' }
      | { __typename?: 'Erc721TokenMetadata' }
      | { __typename?: 'Erc721TokenMetadataAttributeTraitType' }
      | { __typename?: 'Erc721TokenMetadataAttributesText' }
      | { __typename?: 'Pages' }
      | { __typename?: 'Products' }
      | { __typename?: 'PromptEngineering' }
      | { __typename?: 'RootSettings' }
      | { __typename?: 'SectionsDetailPersonComponent' }
      | { __typename?: 'SectionsDetailPersonList' }
      | { __typename?: 'SectionsFaqComponent' }
      | { __typename?: 'SectionsFaqList' }
      | { __typename?: 'SectionsFeaturesList' }
      | { __typename?: 'SectionsFeaturesListComponent' }
      | { __typename?: 'SectionsForm' }
      | { __typename?: 'SectionsFormComponent' }
      | { __typename?: 'SectionsHero' }
      | { __typename?: 'SectionsImage' }
      | { __typename?: 'SectionsMinimalsContactComponent' }
      | { __typename?: 'SectionsMinimalsContactList' }
      | { __typename?: 'SectionsMinimalsGallery' }
      | { __typename?: 'SectionsMinimalsHistoryComponent' }
      | { __typename?: 'SectionsMinimalsHistoryList' }
      | { __typename?: 'SectionsMinimalsHumanComponent' }
      | { __typename?: 'SectionsMinimalsHumanList' }
      | { __typename?: 'SectionsMinimalsImagesList' }
      | { __typename?: 'SectionsMinimalsService' }
      | { __typename?: 'SectionsMinimalsServices' }
      | { __typename?: 'SectionsMinimalsVideo' }
      | { __typename?: 'SectionsMintList' }
      | { __typename?: 'SectionsMintListComponent' }
      | { __typename?: 'SectionsTimer' }
      | { __typename?: 'SeoMetadata'; name?: string | null; content?: string | null }
      | { __typename?: 'SmartContractErc721' }
      | { __typename?: 'SmartContractErc721Token' }
      | { __typename?: 'SmartContractErc721TokenAttribute' }
      | { __typename?: 'SmartContractErc721WhitelistAddress' }
      | { __typename?: 'SocialMediaLink' }
      | { __typename?: 'Tags' }
      | null
    >
  } | null
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

export type TagContentFragmentFragment = {
  __typename?: 'Tags'
  sys: { __typename?: 'Sys'; id: string }
  content?: {
    __typename?: 'TagsContent'
    json: any
    links: {
      __typename?: 'TagsContentLinks'
      assets: {
        __typename?: 'TagsContentAssets'
        block: Array<{
          __typename?: 'Asset'
          url?: string | null
          width?: number | null
          height?: number | null
          description?: string | null
          sys: { __typename?: 'Sys'; id: string }
        } | null>
      }
    }
  } | null
}

export type BlogContentFragmentFragment = {
  __typename?: 'Blogs'
  content?: {
    __typename?: 'BlogsContent'
    json: any
    links: {
      __typename?: 'BlogsContentLinks'
      assets: {
        __typename?: 'BlogsContentAssets'
        block: Array<{
          __typename?: 'Asset'
          url?: string | null
          width?: number | null
          height?: number | null
          description?: string | null
          sys: { __typename?: 'Sys'; id: string }
        } | null>
      }
    }
  } | null
}

export type BlogLinkingFragmentFragment = {
  __typename?: 'BlogsLinkingCollections'
  categoriesCollection?: {
    __typename?: 'CategoriesCollection'
    items: Array<{ __typename?: 'Categories'; title?: string | null } | null>
  } | null
}

export type ProductPreviewFragmentFragment = {
  __typename?: 'Products'
  slug?: string | null
  title?: string | null
  abstract?: string | null
  price?: number | null
  sys: { __typename?: 'Sys'; id: string; publishedAt?: any | null }
  callToActionLink?: {
    __typename?: 'CallToActionLink'
    title?: string | null
    link?: string | null
  } | null
  previewImage?: { __typename?: 'Asset'; url?: string | null } | null
  imagesCollection?: {
    __typename?: 'AssetCollection'
    items: Array<{ __typename?: 'Asset'; url?: string | null } | null>
  } | null
  tagsCollection?: {
    __typename?: 'ProductsTagsCollection'
    items: Array<{
      __typename?: 'Tags'
      name?: string | null
      sys: { __typename?: 'Sys'; id: string }
    } | null>
  } | null
}

export type ProductContentFragmentFragment = {
  __typename?: 'Products'
  content?: {
    __typename?: 'ProductsContent'
    json: any
    links: {
      __typename?: 'ProductsContentLinks'
      assets: {
        __typename?: 'ProductsContentAssets'
        block: Array<{
          __typename?: 'Asset'
          url?: string | null
          width?: number | null
          height?: number | null
          description?: string | null
          sys: { __typename?: 'Sys'; id: string }
        } | null>
      }
    }
  } | null
}

export type ProductsContentAssetsFragment = {
  __typename?: 'ProductsContentAssets'
  block: Array<{
    __typename?: 'Asset'
    url?: string | null
    width?: number | null
    height?: number | null
    description?: string | null
    sys: { __typename?: 'Sys'; id: string }
  } | null>
}

export type PromptResponseFragment = {
  __typename?: 'PromptResponse'
  id: string
  language: PromptSupportingLanguageMap
  prompt: string
  createResponse?: {
    __typename?: 'PromptCreateResponse'
    id: string
    choices: Array<{
      __typename?: 'PromptChoice'
      finish_reason: string
      index: number
      text: string
    }>
  } | null
}

export type VisitorPromptUsagesFragment = {
  __typename?: 'promptUsages'
  id: any
  createdAt: any
  language: string
  promptId: string
  response: any
  userPrompt: string
}

export type MidjourneyImagesFragment = {
  __typename?: 'midjourneyImages'
  id: any
  name: string
  height: number
  ephemeral: boolean
  discordUserId: string
  discordMessageId: string
  discordAttachmentId: string
  description?: string | null
  createdAt: any
  contentType: string
  cloudinaryPublicId?: string | null
  attachment: string
  prompt: string
  proxyURL: string
  recordedAt: any
  size: number
  updatedAt: any
  url: string
  width: number
}

export type UserInsertFormMutationVariables = Exact<{
  contentfulFormId: Scalars['String']
  formAnswers: FormAnswers_Arr_Rel_Insert_Input
}>

export type UserInsertFormMutation = {
  __typename?: 'mutation_root'
  insert_forms?: { __typename?: 'forms_mutation_response'; affected_rows: number } | null
}

export type FetchBasicSetupConfigQueryVariables = Exact<{ [key: string]: never }>

export type FetchBasicSetupConfigQuery = {
  __typename?: 'query_root'
  supportingUseCases: Array<{
    __typename?: 'SupportingUseCase'
    _id: string
    caption: string
    createdAt?: string | null
    description: string
    image: string
    info: string
    key: string
    name: string
    slug: string
    variants: number
    contextInputs: Array<{
      __typename?: 'ContextInput'
      _id: string
      inputMaximumCharacters: number
      inputType: string
      isRequired?: boolean | null
      keyLabel: string
      label: string
      placeholder: string
    }>
  }>
  supportingTones: Array<{ __typename?: 'SupportingTone'; _id: string; name: string; slug: string }>
  supportingLanguages: Array<{
    __typename?: 'SupportingLanguage'
    _id: string
    name: string
    slug: string
  }>
}

export type FetchWriterGenericsQueryVariables = Exact<{
  inputs: Array<GenericInput> | GenericInput
}>

export type FetchWriterGenericsQuery = {
  __typename?: 'query_root'
  generics: Array<{
    __typename?: 'GenericResponse'
    id?: string | null
    text?: string | null
    tone: { __typename?: 'SupportingTone'; _id: string; name: string; slug: string }
    language: { __typename?: 'SupportingLanguage'; _id: string; name: string; slug: string }
    useCase: {
      __typename?: 'SupportingUseCase'
      _id: string
      caption: string
      createdAt?: string | null
      description: string
      image: string
      info: string
      key: string
      name: string
      slug: string
      variants: number
      contextInputs: Array<{
        __typename?: 'ContextInput'
        _id: string
        inputMaximumCharacters: number
        inputType: string
        isRequired?: boolean | null
        keyLabel: string
        label: string
        placeholder: string
      }>
    }
    textVariations?: Array<{ __typename?: 'TextVariation'; text?: string | null } | null> | null
  } | null>
}

export type FetchAllPostsWithTitleQueryVariables = Exact<{
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>
  locale?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<Array<InputMaybe<BlogsOrder>> | InputMaybe<BlogsOrder>>
  titleSearch?: InputMaybe<Scalars['String']>
}>

export type FetchAllPostsWithTitleQuery = {
  __typename?: 'query_root'
  blogsCollection?: {
    __typename?: 'BlogsCollection'
    items: Array<{
      __typename?: 'Blogs'
      title?: string | null
      linkedFrom?: {
        __typename?: 'BlogsLinkingCollections'
        categoriesCollection?: {
          __typename?: 'CategoriesCollection'
          items: Array<{ __typename?: 'Categories'; title?: string | null } | null>
        } | null
      } | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      coverImage?: { __typename?: 'Asset'; url?: string | null } | null
      previewImage?: { __typename?: 'Asset'; url?: string | null } | null
      tagsCollection?: {
        __typename?: 'BlogsTagsCollection'
        items: Array<{ __typename?: 'Tags'; name?: string | null } | null>
      } | null
      author?: {
        __typename?: 'Authors'
        name?: string | null
        profileImage?: { __typename?: 'Asset'; url?: string | null } | null
      } | null
    } | null>
  } | null
}

export type FetchAllTagsWithNameQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<Array<InputMaybe<TagsOrder>> | InputMaybe<TagsOrder>>
  nameSearch?: InputMaybe<Scalars['String']>
}>

export type FetchAllTagsWithNameQuery = {
  __typename?: 'query_root'
  tagsCollection?: {
    __typename?: 'TagsCollection'
    items: Array<{
      __typename?: 'Tags'
      slug?: string | null
      name?: string | null
      content?: { __typename?: 'TagsContent'; json: any } | null
      seoMetadataItemsCollection?: {
        __typename?: 'TagsSeoMetadataItemsCollection'
        items: Array<
          | { __typename?: 'Authors' }
          | { __typename?: 'Blogs' }
          | { __typename?: 'CallToActionLink' }
          | { __typename?: 'Categories' }
          | { __typename?: 'Embeddings' }
          | { __typename?: 'Erc721TokenMetadata' }
          | { __typename?: 'Erc721TokenMetadataAttributeTraitType' }
          | { __typename?: 'Erc721TokenMetadataAttributesText' }
          | { __typename?: 'Pages' }
          | { __typename?: 'Products' }
          | { __typename?: 'PromptEngineering' }
          | { __typename?: 'RootSettings' }
          | { __typename?: 'SectionsDetailPersonComponent' }
          | { __typename?: 'SectionsDetailPersonList' }
          | { __typename?: 'SectionsFaqComponent' }
          | { __typename?: 'SectionsFaqList' }
          | { __typename?: 'SectionsFeaturesList' }
          | { __typename?: 'SectionsFeaturesListComponent' }
          | { __typename?: 'SectionsForm' }
          | { __typename?: 'SectionsFormComponent' }
          | { __typename?: 'SectionsHero' }
          | { __typename?: 'SectionsImage' }
          | { __typename?: 'SectionsMinimalsContactComponent' }
          | { __typename?: 'SectionsMinimalsContactList' }
          | { __typename?: 'SectionsMinimalsGallery' }
          | { __typename?: 'SectionsMinimalsHistoryComponent' }
          | { __typename?: 'SectionsMinimalsHistoryList' }
          | { __typename?: 'SectionsMinimalsHumanComponent' }
          | { __typename?: 'SectionsMinimalsHumanList' }
          | { __typename?: 'SectionsMinimalsImagesList' }
          | { __typename?: 'SectionsMinimalsService' }
          | { __typename?: 'SectionsMinimalsServices' }
          | { __typename?: 'SectionsMinimalsVideo' }
          | { __typename?: 'SectionsMintList' }
          | { __typename?: 'SectionsMintListComponent' }
          | { __typename?: 'SectionsTimer' }
          | { __typename?: 'SeoMetadata'; name?: string | null; content?: string | null }
          | { __typename?: 'SmartContractErc721' }
          | { __typename?: 'SmartContractErc721Token' }
          | { __typename?: 'SmartContractErc721TokenAttribute' }
          | { __typename?: 'SmartContractErc721WhitelistAddress' }
          | { __typename?: 'SocialMediaLink' }
          | { __typename?: 'Tags' }
          | null
        >
      } | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
    } | null>
  } | null
}

export type FetchAllPostsTotalQueryVariables = Exact<{
  filters?: InputMaybe<BlogsFilter>
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchAllPostsTotalQuery = {
  __typename?: 'query_root'
  blogsCollection?: { __typename?: 'BlogsCollection'; total: number } | null
}

export type FetchPostsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String']
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchPostsByIdsQuery = {
  __typename?: 'query_root'
  blogsCollection?: {
    __typename?: 'BlogsCollection'
    items: Array<{
      __typename?: 'Blogs'
      title?: string | null
      linkedFrom?: {
        __typename?: 'BlogsLinkingCollections'
        categoriesCollection?: {
          __typename?: 'CategoriesCollection'
          items: Array<{ __typename?: 'Categories'; title?: string | null } | null>
        } | null
      } | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      coverImage?: { __typename?: 'Asset'; url?: string | null } | null
      previewImage?: { __typename?: 'Asset'; url?: string | null } | null
      tagsCollection?: {
        __typename?: 'BlogsTagsCollection'
        items: Array<{ __typename?: 'Tags'; name?: string | null } | null>
      } | null
      author?: {
        __typename?: 'Authors'
        name?: string | null
        profileImage?: { __typename?: 'Asset'; url?: string | null } | null
      } | null
    } | null>
  } | null
}

export type FetchPostByTitleQueryVariables = Exact<{
  title: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchPostByTitleQuery = {
  __typename?: 'query_root'
  blogsCollection?: {
    __typename?: 'BlogsCollection'
    items: Array<{
      __typename?: 'Blogs'
      title?: string | null
      linkedFrom?: {
        __typename?: 'BlogsLinkingCollections'
        categoriesCollection?: {
          __typename?: 'CategoriesCollection'
          items: Array<{ __typename?: 'Categories'; title?: string | null } | null>
        } | null
      } | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      coverImage?: { __typename?: 'Asset'; url?: string | null } | null
      previewImage?: { __typename?: 'Asset'; url?: string | null } | null
      tagsCollection?: {
        __typename?: 'BlogsTagsCollection'
        items: Array<{ __typename?: 'Tags'; name?: string | null } | null>
      } | null
      author?: {
        __typename?: 'Authors'
        name?: string | null
        profileImage?: { __typename?: 'Asset'; url?: string | null } | null
      } | null
      content?: {
        __typename?: 'BlogsContent'
        json: any
        links: {
          __typename?: 'BlogsContentLinks'
          assets: {
            __typename?: 'BlogsContentAssets'
            block: Array<{
              __typename?: 'Asset'
              url?: string | null
              width?: number | null
              height?: number | null
              description?: string | null
              sys: { __typename?: 'Sys'; id: string }
            } | null>
          }
        }
      } | null
    } | null>
  } | null
}

export type FetchTagByIdQueryVariables = Exact<{
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchTagByIdQuery = {
  __typename?: 'query_root'
  tags?: {
    __typename?: 'Tags'
    slug?: string | null
    name?: string | null
    seoMetadataItemsCollection?: {
      __typename?: 'TagsSeoMetadataItemsCollection'
      items: Array<
        | { __typename?: 'Authors' }
        | { __typename?: 'Blogs' }
        | { __typename?: 'CallToActionLink' }
        | { __typename?: 'Categories' }
        | { __typename?: 'Embeddings' }
        | { __typename?: 'Erc721TokenMetadata' }
        | { __typename?: 'Erc721TokenMetadataAttributeTraitType' }
        | { __typename?: 'Erc721TokenMetadataAttributesText' }
        | { __typename?: 'Pages' }
        | { __typename?: 'Products' }
        | { __typename?: 'PromptEngineering' }
        | { __typename?: 'RootSettings' }
        | { __typename?: 'SectionsDetailPersonComponent' }
        | { __typename?: 'SectionsDetailPersonList' }
        | { __typename?: 'SectionsFaqComponent' }
        | { __typename?: 'SectionsFaqList' }
        | { __typename?: 'SectionsFeaturesList' }
        | { __typename?: 'SectionsFeaturesListComponent' }
        | { __typename?: 'SectionsForm' }
        | { __typename?: 'SectionsFormComponent' }
        | { __typename?: 'SectionsHero' }
        | { __typename?: 'SectionsImage' }
        | { __typename?: 'SectionsMinimalsContactComponent' }
        | { __typename?: 'SectionsMinimalsContactList' }
        | { __typename?: 'SectionsMinimalsGallery' }
        | { __typename?: 'SectionsMinimalsHistoryComponent' }
        | { __typename?: 'SectionsMinimalsHistoryList' }
        | { __typename?: 'SectionsMinimalsHumanComponent' }
        | { __typename?: 'SectionsMinimalsHumanList' }
        | { __typename?: 'SectionsMinimalsImagesList' }
        | { __typename?: 'SectionsMinimalsService' }
        | { __typename?: 'SectionsMinimalsServices' }
        | { __typename?: 'SectionsMinimalsVideo' }
        | { __typename?: 'SectionsMintList' }
        | { __typename?: 'SectionsMintListComponent' }
        | { __typename?: 'SectionsTimer' }
        | { __typename?: 'SeoMetadata'; name?: string | null; content?: string | null }
        | { __typename?: 'SmartContractErc721' }
        | { __typename?: 'SmartContractErc721Token' }
        | { __typename?: 'SmartContractErc721TokenAttribute' }
        | { __typename?: 'SmartContractErc721WhitelistAddress' }
        | { __typename?: 'SocialMediaLink' }
        | { __typename?: 'Tags' }
        | null
      >
    } | null
    sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
    content?: {
      __typename?: 'TagsContent'
      json: any
      links: {
        __typename?: 'TagsContentLinks'
        assets: {
          __typename?: 'TagsContentAssets'
          block: Array<{
            __typename?: 'Asset'
            url?: string | null
            width?: number | null
            height?: number | null
            description?: string | null
            sys: { __typename?: 'Sys'; id: string }
          } | null>
        }
      }
    } | null
  } | null
}

export type FetchTagIdByTagSlugQueryVariables = Exact<{
  slug: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchTagIdByTagSlugQuery = {
  __typename?: 'query_root'
  tagsCollection?: {
    __typename?: 'TagsCollection'
    items: Array<{
      __typename?: 'Tags'
      slug?: string | null
      sys: { __typename?: 'Sys'; id: string }
    } | null>
  } | null
}

export type FetchTagByNameQueryVariables = Exact<{
  name: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchTagByNameQuery = {
  __typename?: 'query_root'
  tagsCollection?: {
    __typename?: 'TagsCollection'
    items: Array<{
      __typename?: 'Tags'
      slug?: string | null
      name?: string | null
      seoMetadataItemsCollection?: {
        __typename?: 'TagsSeoMetadataItemsCollection'
        items: Array<
          | { __typename?: 'Authors' }
          | { __typename?: 'Blogs' }
          | { __typename?: 'CallToActionLink' }
          | { __typename?: 'Categories' }
          | { __typename?: 'Embeddings' }
          | { __typename?: 'Erc721TokenMetadata' }
          | { __typename?: 'Erc721TokenMetadataAttributeTraitType' }
          | { __typename?: 'Erc721TokenMetadataAttributesText' }
          | { __typename?: 'Pages' }
          | { __typename?: 'Products' }
          | { __typename?: 'PromptEngineering' }
          | { __typename?: 'RootSettings' }
          | { __typename?: 'SectionsDetailPersonComponent' }
          | { __typename?: 'SectionsDetailPersonList' }
          | { __typename?: 'SectionsFaqComponent' }
          | { __typename?: 'SectionsFaqList' }
          | { __typename?: 'SectionsFeaturesList' }
          | { __typename?: 'SectionsFeaturesListComponent' }
          | { __typename?: 'SectionsForm' }
          | { __typename?: 'SectionsFormComponent' }
          | { __typename?: 'SectionsHero' }
          | { __typename?: 'SectionsImage' }
          | { __typename?: 'SectionsMinimalsContactComponent' }
          | { __typename?: 'SectionsMinimalsContactList' }
          | { __typename?: 'SectionsMinimalsGallery' }
          | { __typename?: 'SectionsMinimalsHistoryComponent' }
          | { __typename?: 'SectionsMinimalsHistoryList' }
          | { __typename?: 'SectionsMinimalsHumanComponent' }
          | { __typename?: 'SectionsMinimalsHumanList' }
          | { __typename?: 'SectionsMinimalsImagesList' }
          | { __typename?: 'SectionsMinimalsService' }
          | { __typename?: 'SectionsMinimalsServices' }
          | { __typename?: 'SectionsMinimalsVideo' }
          | { __typename?: 'SectionsMintList' }
          | { __typename?: 'SectionsMintListComponent' }
          | { __typename?: 'SectionsTimer' }
          | { __typename?: 'SeoMetadata'; name?: string | null; content?: string | null }
          | { __typename?: 'SmartContractErc721' }
          | { __typename?: 'SmartContractErc721Token' }
          | { __typename?: 'SmartContractErc721TokenAttribute' }
          | { __typename?: 'SmartContractErc721WhitelistAddress' }
          | { __typename?: 'SocialMediaLink' }
          | { __typename?: 'Tags' }
          | null
        >
      } | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      content?: {
        __typename?: 'TagsContent'
        json: any
        links: {
          __typename?: 'TagsContentLinks'
          assets: {
            __typename?: 'TagsContentAssets'
            block: Array<{
              __typename?: 'Asset'
              url?: string | null
              width?: number | null
              height?: number | null
              description?: string | null
              sys: { __typename?: 'Sys'; id: string }
            } | null>
          }
        }
      } | null
    } | null>
  } | null
}

export type FetchPostAndMorePostsByIdQueryVariables = Exact<{
  id: Scalars['String']
  morePostsLimit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchPostAndMorePostsByIdQuery = {
  __typename?: 'query_root'
  blogs?: {
    __typename?: 'Blogs'
    title?: string | null
    seoMetadataItemsCollection?: {
      __typename?: 'BlogsSeoMetadataItemsCollection'
      items: Array<{
        __typename?: 'SeoMetadata'
        name?: string | null
        content?: string | null
      } | null>
    } | null
    callToActionLink?: {
      __typename?: 'CallToActionLink'
      title?: string | null
      link?: string | null
    } | null
    linkedFrom?: {
      __typename?: 'BlogsLinkingCollections'
      categoriesCollection?: {
        __typename?: 'CategoriesCollection'
        items: Array<{
          __typename?: 'Categories'
          title?: string | null
          blogsCollection?: {
            __typename?: 'CategoriesBlogsCollection'
            items: Array<{
              __typename?: 'Blogs'
              title?: string | null
              sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
            } | null>
          } | null
        } | null>
      } | null
    } | null
    sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
    coverImage?: { __typename?: 'Asset'; url?: string | null } | null
    previewImage?: { __typename?: 'Asset'; url?: string | null } | null
    tagsCollection?: {
      __typename?: 'BlogsTagsCollection'
      items: Array<{ __typename?: 'Tags'; name?: string | null } | null>
    } | null
    author?: {
      __typename?: 'Authors'
      name?: string | null
      profileImage?: { __typename?: 'Asset'; url?: string | null } | null
    } | null
    content?: {
      __typename?: 'BlogsContent'
      json: any
      links: {
        __typename?: 'BlogsContentLinks'
        assets: {
          __typename?: 'BlogsContentAssets'
          block: Array<{
            __typename?: 'Asset'
            url?: string | null
            width?: number | null
            height?: number | null
            description?: string | null
            sys: { __typename?: 'Sys'; id: string }
          } | null>
        }
      }
    } | null
  } | null
}

export type FetchAllPostsIdByCategoryIdQueryVariables = Exact<{
  id: Scalars['String']
}>

export type FetchAllPostsIdByCategoryIdQuery = {
  __typename?: 'query_root'
  categories?: {
    __typename?: 'Categories'
    title?: string | null
    blogsCollection?: {
      __typename?: 'CategoriesBlogsCollection'
      items: Array<{ __typename?: 'Blogs'; sys: { __typename?: 'Sys'; id: string } } | null>
    } | null
  } | null
}

export type FetchCategoryIdByCategoryQueryVariables = Exact<{
  category: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchCategoryIdByCategoryQuery = {
  __typename?: 'query_root'
  categoriesCollection?: {
    __typename?: 'CategoriesCollection'
    items: Array<{ __typename?: 'Categories'; sys: { __typename?: 'Sys'; id: string } } | null>
  } | null
}

export type FetchCategoryByCategoryIdQueryVariables = Exact<{
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchCategoryByCategoryIdQuery = {
  __typename?: 'query_root'
  categories?: {
    __typename?: 'Categories'
    title?: string | null
    sys: { __typename?: 'Sys'; id: string }
  } | null
}

export type FetchPostIdByPostTitleQueryVariables = Exact<{
  title: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchPostIdByPostTitleQuery = {
  __typename?: 'query_root'
  blogsCollection?: {
    __typename?: 'BlogsCollection'
    items: Array<{
      __typename?: 'Blogs'
      title?: string | null
      sys: { __typename?: 'Sys'; id: string }
    } | null>
  } | null
}

export type FetchAllPostsTitleQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchAllPostsTitleQuery = {
  __typename?: 'query_root'
  blogsCollection?: {
    __typename?: 'BlogsCollection'
    items: Array<{ __typename?: 'Blogs'; title?: string | null } | null>
  } | null
}

export type FetchAllCategoriesQueryVariables = Exact<{
  filter?: InputMaybe<CategoriesFilter>
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchAllCategoriesQuery = {
  __typename?: 'query_root'
  categoriesCollection?: {
    __typename?: 'CategoriesCollection'
    items: Array<{ __typename?: 'Categories'; title?: string | null } | null>
  } | null
}

export type FetchAllProductsQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<Array<InputMaybe<ProductsOrder>> | InputMaybe<ProductsOrder>>
  textSearch?: InputMaybe<Scalars['String']>
}>

export type FetchAllProductsQuery = {
  __typename?: 'query_root'
  productsCollection?: {
    __typename?: 'ProductsCollection'
    items: Array<{
      __typename?: 'Products'
      slug?: string | null
      title?: string | null
      abstract?: string | null
      price?: number | null
      sys: { __typename?: 'Sys'; id: string; publishedAt?: any | null }
      callToActionLink?: {
        __typename?: 'CallToActionLink'
        title?: string | null
        link?: string | null
      } | null
      previewImage?: { __typename?: 'Asset'; url?: string | null } | null
      imagesCollection?: {
        __typename?: 'AssetCollection'
        items: Array<{ __typename?: 'Asset'; url?: string | null } | null>
      } | null
      tagsCollection?: {
        __typename?: 'ProductsTagsCollection'
        items: Array<{
          __typename?: 'Tags'
          name?: string | null
          sys: { __typename?: 'Sys'; id: string }
        } | null>
      } | null
    } | null>
  } | null
}

export type FetchAllProductsTotalQueryVariables = Exact<{
  filters?: InputMaybe<ProductsFilter>
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchAllProductsTotalQuery = {
  __typename?: 'query_root'
  productsCollection?: { __typename?: 'ProductsCollection'; total: number } | null
}

export type FetchAllTagsTotalQueryVariables = Exact<{
  filters?: InputMaybe<TagsFilter>
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchAllTagsTotalQuery = {
  __typename?: 'query_root'
  tagsCollection?: { __typename?: 'TagsCollection'; total: number } | null
}

export type FetchAllProductSearchTagsQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<Array<InputMaybe<TagsOrder>> | InputMaybe<TagsOrder>>
}>

export type FetchAllProductSearchTagsQuery = {
  __typename?: 'query_root'
  tagsCollection?: {
    __typename?: 'TagsCollection'
    items: Array<{
      __typename?: 'Tags'
      name?: string | null
      groupName?: string | null
      sys: { __typename?: 'Sys'; id: string }
    } | null>
  } | null
}

export type FetchAllProductsSlugQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<Array<InputMaybe<ProductsOrder>> | InputMaybe<ProductsOrder>>
}>

export type FetchAllProductsSlugQuery = {
  __typename?: 'query_root'
  productsCollection?: {
    __typename?: 'ProductsCollection'
    items: Array<{
      __typename?: 'Products'
      slug?: string | null
      sys: { __typename?: 'Sys'; id: string }
    } | null>
  } | null
}

export type FetchAllTagsSlugQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
}>

export type FetchAllTagsSlugQuery = {
  __typename?: 'query_root'
  tagsCollection?: {
    __typename?: 'TagsCollection'
    items: Array<{
      __typename?: 'Tags'
      slug?: string | null
      sys: { __typename?: 'Sys'; id: string }
    } | null>
  } | null
}

export type FetchProductIdByProductSlugQueryVariables = Exact<{
  slug: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchProductIdByProductSlugQuery = {
  __typename?: 'query_root'
  productsCollection?: {
    __typename?: 'ProductsCollection'
    items: Array<{
      __typename?: 'Products'
      slug?: string | null
      sys: { __typename?: 'Sys'; id: string }
    } | null>
  } | null
}

export type FetchProductByIdQueryVariables = Exact<{
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchProductByIdQuery = {
  __typename?: 'query_root'
  products?: {
    __typename?: 'Products'
    slug?: string | null
    title?: string | null
    description?: string | null
    price?: number | null
    sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
    previewImage?: { __typename?: 'Asset'; url?: string | null } | null
    imagesCollection?: {
      __typename?: 'AssetCollection'
      items: Array<{ __typename?: 'Asset'; url?: string | null } | null>
    } | null
    tagsCollection?: {
      __typename?: 'ProductsTagsCollection'
      items: Array<{
        __typename?: 'Tags'
        name?: string | null
        sys: { __typename?: 'Sys'; id: string }
      } | null>
    } | null
    seoMetadataItemsCollection?: {
      __typename?: 'ProductsSeoMetadataItemsCollection'
      items: Array<{
        __typename?: 'SeoMetadata'
        name?: string | null
        content?: string | null
      } | null>
    } | null
    callToActionLink?: {
      __typename?: 'CallToActionLink'
      title?: string | null
      link?: string | null
    } | null
    content?: {
      __typename?: 'ProductsContent'
      json: any
      links: {
        __typename?: 'ProductsContentLinks'
        assets: {
          __typename?: 'ProductsContentAssets'
          block: Array<{
            __typename?: 'Asset'
            url?: string | null
            width?: number | null
            height?: number | null
            description?: string | null
            sys: { __typename?: 'Sys'; id: string }
          } | null>
        }
      }
    } | null
  } | null
}

export type FetchProductsIdByTagIdQueryVariables = Exact<{
  id: Scalars['String']
  limit?: InputMaybe<Scalars['Int']>
}>

export type FetchProductsIdByTagIdQuery = {
  __typename?: 'query_root'
  tags?: {
    __typename?: 'Tags'
    linkedFrom?: {
      __typename?: 'TagsLinkingCollections'
      productsCollection?: {
        __typename?: 'ProductsCollection'
        items: Array<{ __typename?: 'Products'; sys: { __typename?: 'Sys'; id: string } } | null>
      } | null
    } | null
  } | null
}

export type FetchProductsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String']
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<Array<InputMaybe<ProductsOrder>> | InputMaybe<ProductsOrder>>
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchProductsByIdsQuery = {
  __typename?: 'query_root'
  productsCollection?: {
    __typename?: 'ProductsCollection'
    items: Array<{
      __typename?: 'Products'
      slug?: string | null
      title?: string | null
      abstract?: string | null
      price?: number | null
      sys: { __typename?: 'Sys'; id: string; publishedAt?: any | null }
      callToActionLink?: {
        __typename?: 'CallToActionLink'
        title?: string | null
        link?: string | null
      } | null
      previewImage?: { __typename?: 'Asset'; url?: string | null } | null
      imagesCollection?: {
        __typename?: 'AssetCollection'
        items: Array<{ __typename?: 'Asset'; url?: string | null } | null>
      } | null
      tagsCollection?: {
        __typename?: 'ProductsTagsCollection'
        items: Array<{
          __typename?: 'Tags'
          name?: string | null
          sys: { __typename?: 'Sys'; id: string }
        } | null>
      } | null
    } | null>
  } | null
}

export type FetchRootSettingsCollectionQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchRootSettingsCollectionQuery = {
  __typename?: 'query_root'
  rootSettingsCollection?: {
    __typename?: 'RootSettingsCollection'
    items: Array<{
      __typename?: 'RootSettings'
      sectionColors?: Array<string | null> | null
      scope?: string | null
      companyColor?: string | null
      theme?: string | null
      renamePathDisplayTextBlog?: string | null
      renamePathDisplayTextHome?: string | null
      renamePathDisplayTextProduct?: string | null
      renameCallToActionLinksDisplayText?: string | null
      companyLogo?: { __typename?: 'Asset'; url?: string | null } | null
      companyFavicon?: { __typename?: 'Asset'; url?: string | null } | null
      callToActionLink?: {
        __typename?: 'CallToActionLink'
        title?: string | null
        link?: string | null
      } | null
      seoMetadataItemsCollection?: {
        __typename?: 'RootSettingsSeoMetadataItemsCollection'
        items: Array<{
          __typename?: 'SeoMetadata'
          name?: string | null
          content?: string | null
        } | null>
      } | null
      socialMediaLinksCollection?: {
        __typename?: 'RootSettingsSocialMediaLinksCollection'
        items: Array<{
          __typename?: 'SocialMediaLink'
          title?: string | null
          link?: string | null
          type?: string | null
          isExternal?: boolean | null
          overwriteDisplayIconWithIconifyIcon?: string | null
        } | null>
      } | null
      headerDisplayPagesCollection?: {
        __typename?: 'RootSettingsHeaderDisplayPagesCollection'
        items: Array<{
          __typename?: 'Pages'
          slug?: string | null
          displayName?: string | null
          iconifyIconNameForMobileMenu?: string | null
        } | null>
      } | null
      mobileDrawerDisplayPagesCollection?: {
        __typename?: 'RootSettingsMobileDrawerDisplayPagesCollection'
        items: Array<{
          __typename?: 'Pages'
          slug?: string | null
          displayName?: string | null
          iconifyIconNameForMobileMenu?: string | null
        } | null>
      } | null
      promptEngineeringsCollection?: {
        __typename?: 'RootSettingsPromptEngineeringsCollection'
        items: Array<{
          __typename?: 'PromptEngineering'
          title?: string | null
          promptHint?: string | null
          teaser?: string | null
          purpose?: string | null
          sys: { __typename?: 'Sys'; id: string }
        } | null>
      } | null
    } | null>
  } | null
}

export type FetchRootSettingsCollectionForWriterPageQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchRootSettingsCollectionForWriterPageQuery = {
  __typename?: 'query_root'
  rootSettingsCollection?: {
    __typename?: 'RootSettingsCollection'
    items: Array<{
      __typename?: 'RootSettings'
      writersoftModules?: Array<string | null> | null
      sectionColors?: Array<string | null> | null
      scope?: string | null
      companyColor?: string | null
      theme?: string | null
      renamePathDisplayTextBlog?: string | null
      renamePathDisplayTextHome?: string | null
      renamePathDisplayTextProduct?: string | null
      renameCallToActionLinksDisplayText?: string | null
      companyLogo?: { __typename?: 'Asset'; url?: string | null } | null
      companyFavicon?: { __typename?: 'Asset'; url?: string | null } | null
      callToActionLink?: {
        __typename?: 'CallToActionLink'
        title?: string | null
        link?: string | null
      } | null
      seoMetadataItemsCollection?: {
        __typename?: 'RootSettingsSeoMetadataItemsCollection'
        items: Array<{
          __typename?: 'SeoMetadata'
          name?: string | null
          content?: string | null
        } | null>
      } | null
      socialMediaLinksCollection?: {
        __typename?: 'RootSettingsSocialMediaLinksCollection'
        items: Array<{
          __typename?: 'SocialMediaLink'
          title?: string | null
          link?: string | null
          type?: string | null
          isExternal?: boolean | null
          overwriteDisplayIconWithIconifyIcon?: string | null
        } | null>
      } | null
      headerDisplayPagesCollection?: {
        __typename?: 'RootSettingsHeaderDisplayPagesCollection'
        items: Array<{
          __typename?: 'Pages'
          slug?: string | null
          displayName?: string | null
          iconifyIconNameForMobileMenu?: string | null
        } | null>
      } | null
      mobileDrawerDisplayPagesCollection?: {
        __typename?: 'RootSettingsMobileDrawerDisplayPagesCollection'
        items: Array<{
          __typename?: 'Pages'
          slug?: string | null
          displayName?: string | null
          iconifyIconNameForMobileMenu?: string | null
        } | null>
      } | null
      promptEngineeringsCollection?: {
        __typename?: 'RootSettingsPromptEngineeringsCollection'
        items: Array<{
          __typename?: 'PromptEngineering'
          title?: string | null
          promptHint?: string | null
          teaser?: string | null
          purpose?: string | null
          sys: { __typename?: 'Sys'; id: string }
        } | null>
      } | null
    } | null>
  } | null
}

export type FetchAllPagesSlugQueryVariables = Exact<{ [key: string]: never }>

export type FetchAllPagesSlugQuery = {
  __typename?: 'query_root'
  pagesCollection?: {
    __typename?: 'PagesCollection'
    items: Array<{ __typename?: 'Pages'; slug?: string | null } | null>
  } | null
}

export type FetchPageBySlugQueryVariables = Exact<{
  slug: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchPageBySlugQuery = {
  __typename?: 'query_root'
  pagesCollection?: {
    __typename?: 'PagesCollection'
    items: Array<{
      __typename?: 'Pages'
      sectionsCollection?: {
        __typename?: 'PagesSectionsCollection'
        items: Array<
          | {
              __typename?: 'SectionsDetailPersonList'
              key: 'SectionsDetailPersonList'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename?: 'SectionsFaqList'
              key: 'SectionsFaqList'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename?: 'SectionsFeaturesList'
              key: 'SectionsFeaturesList'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename?: 'SectionsForm'
              key: 'SectionsForm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename?: 'SectionsHero'
              key: 'SectionsHero'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename?: 'SectionsImage'
              key: 'SectionsImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename?: 'SectionsMinimalsContactList'
              key: 'SectionsMinimalsContactList'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename?: 'SectionsMinimalsGallery'
              key: 'SectionsMinimalsGallery'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename?: 'SectionsMinimalsHistoryList'
              key: 'SectionsMinimalsHistoryList'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename?: 'SectionsMinimalsHumanList'
              key: 'SectionsMinimalsHumanList'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename?: 'SectionsMinimalsImagesList'
              key: 'SectionsMinimalsImagesList'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename?: 'SectionsMinimalsServices'
              key: 'SectionsMinimalsServices'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename?: 'SectionsMinimalsVideo'
              key: 'SectionsMinimalsVideo'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename?: 'SectionsMintList'
              key: 'SectionsMintList'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename?: 'SectionsTimer'
              key: 'SectionsTimer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | null
        >
      } | null
      sys: { __typename?: 'Sys'; id: string }
      seoMetadataItemsCollection?: {
        __typename?: 'PagesSeoMetadataItemsCollection'
        items: Array<{
          __typename?: 'SeoMetadata'
          name?: string | null
          content?: string | null
        } | null>
      } | null
    } | null>
  } | null
}

export type FetchSectionMintListByIdQueryVariables = Exact<{
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchSectionMintListByIdQuery = {
  __typename?: 'query_root'
  sectionsMintList?: {
    __typename?: 'SectionsMintList'
    title?: string | null
    description?: string | null
    key: 'SectionsMintList'
    mintCardsCollection?: {
      __typename?: 'SectionsMintListMintCardsCollection'
      items: Array<{
        __typename?: 'SectionsMintListComponent'
        title?: string | null
        description?: string | null
        price?: number | null
        style?: string | null
        smartContractCallToActionName?: string | null
        isDisplay?: boolean | null
        smartContractErc721?: {
          __typename?: 'SmartContractErc721'
          smartContractAddress?: string | null
          network?: string | null
          abi?: any | null
          isSingleTokenMetadataMode?: boolean | null
          logo?: { __typename?: 'Asset'; url?: string | null } | null
          bannerImage?: { __typename?: 'Asset'; url?: string | null } | null
          featureImage?: { __typename?: 'Asset'; url?: string | null } | null
          displayImage?: { __typename?: 'Asset'; url?: string | null } | null
          singleToken?: {
            __typename?: 'Erc721TokenMetadata'
            sys: { __typename?: 'Sys'; id: string }
          } | null
          whitelistAddressesCollection?: {
            __typename?: 'SmartContractErc721WhitelistAddressesCollection'
            items: Array<{
              __typename?: 'SmartContractErc721WhitelistAddress'
              name?: string | null
              ethAddress?: string | null
              alreadyMinted?: boolean | null
            } | null>
          } | null
        } | null
        dependencySmartContractErc721?: {
          __typename?: 'SmartContractErc721'
          smartContractAddress?: string | null
          network?: string | null
          abi?: any | null
          isSingleTokenMetadataMode?: boolean | null
          logo?: { __typename?: 'Asset'; url?: string | null } | null
          bannerImage?: { __typename?: 'Asset'; url?: string | null } | null
          featureImage?: { __typename?: 'Asset'; url?: string | null } | null
          displayImage?: { __typename?: 'Asset'; url?: string | null } | null
          singleToken?: {
            __typename?: 'Erc721TokenMetadata'
            sys: { __typename?: 'Sys'; id: string }
          } | null
          whitelistAddressesCollection?: {
            __typename?: 'SmartContractErc721WhitelistAddressesCollection'
            items: Array<{
              __typename?: 'SmartContractErc721WhitelistAddress'
              name?: string | null
              ethAddress?: string | null
              alreadyMinted?: boolean | null
            } | null>
          } | null
        } | null
      } | null>
    } | null
  } | null
}

export type FetchSmartContractErc721CollectionByContractAddressQueryVariables = Exact<{
  smartContractAddress: Scalars['String']
}>

export type FetchSmartContractErc721CollectionByContractAddressQuery = {
  __typename?: 'query_root'
  smartContractErc721Collection?: {
    __typename?: 'SmartContractErc721Collection'
    items: Array<{
      __typename?: 'SmartContractErc721'
      smartContractAddress?: string | null
      network?: string | null
      abi?: any | null
      isSingleTokenMetadataMode?: boolean | null
      logo?: { __typename?: 'Asset'; url?: string | null } | null
      bannerImage?: { __typename?: 'Asset'; url?: string | null } | null
      featureImage?: { __typename?: 'Asset'; url?: string | null } | null
      displayImage?: { __typename?: 'Asset'; url?: string | null } | null
      singleToken?: {
        __typename?: 'Erc721TokenMetadata'
        sys: { __typename?: 'Sys'; id: string }
      } | null
      whitelistAddressesCollection?: {
        __typename?: 'SmartContractErc721WhitelistAddressesCollection'
        items: Array<{
          __typename?: 'SmartContractErc721WhitelistAddress'
          name?: string | null
          ethAddress?: string | null
          alreadyMinted?: boolean | null
        } | null>
      } | null
    } | null>
  } | null
}

export type FetchSectionFeaturesListByIdQueryVariables = Exact<{
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchSectionFeaturesListByIdQuery = {
  __typename?: 'query_root'
  sectionsFeaturesList?: {
    __typename?: 'SectionsFeaturesList'
    title?: string | null
    description?: string | null
    key: 'SectionsFeaturesList'
    componentsCollection?: {
      __typename?: 'SectionsFeaturesListComponentsCollection'
      items: Array<{
        __typename?: 'SectionsFeaturesListComponent'
        title?: string | null
        description?: string | null
        iconifyIconName?: string | null
        icon?: { __typename?: 'Asset'; url?: string | null } | null
      } | null>
    } | null
  } | null
}

export type FetchErc721TokenMetadataByIdQueryVariables = Exact<{
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchErc721TokenMetadataByIdQuery = {
  __typename?: 'query_root'
  erc721TokenMetadata?: {
    __typename?: 'Erc721TokenMetadata'
    tokenId?: string | null
    name?: string | null
    description?: string | null
    externalUrl?: string | null
    backgroundColor?: string | null
    youtubeUrl?: string | null
    sys: { __typename?: 'Sys'; id: string }
    image?: { __typename?: 'Asset'; url?: string | null } | null
    animationUrl?: { __typename?: 'Asset'; url?: string | null } | null
    attributesCollection?: {
      __typename?: 'Erc721TokenMetadataAttributesCollection'
      items: Array<{
        __typename?: 'Erc721TokenMetadataAttributesText'
        displayType?: string | null
        value?: string | null
        traitType?: {
          __typename?: 'Erc721TokenMetadataAttributeTraitType'
          title?: string | null
        } | null
      } | null>
    } | null
  } | null
}

export type FetchErc721TokenMetadataTokenIdQueryVariables = Exact<{
  tokenId: Scalars['String']
}>

export type FetchErc721TokenMetadataTokenIdQuery = {
  __typename?: 'query_root'
  erc721TokenMetadataCollection?: {
    __typename?: 'Erc721TokenMetadataCollection'
    items: Array<{
      __typename?: 'Erc721TokenMetadata'
      tokenId?: string | null
      sys: { __typename?: 'Sys'; id: string }
    } | null>
  } | null
}

export type FetchSectionHeroByIdQueryVariables = Exact<{
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchSectionHeroByIdQuery = {
  __typename?: 'query_root'
  sectionsHero?: {
    __typename?: 'SectionsHero'
    companyName?: string | null
    featureText?: string | null
    descriptionText?: string | null
    callToActionText?: string | null
    callToActionHref?: string | null
    enableAiTrail?: boolean | null
    enableAiTrailDisplayText?: string | null
    enableAiRecommendation?: boolean | null
    enableAiRecommendationDisplayText?: string | null
    key: 'SectionsHero'
    sys: { __typename?: 'Sys'; id: string }
    backgroundImage?: { __typename?: 'Asset'; url?: string | null } | null
    fullWidthBackgroundImageMobile?: { __typename?: 'Asset'; url?: string | null } | null
    fullWidthBackgroundImageDesktop?: { __typename?: 'Asset'; url?: string | null } | null
  } | null
}

export type AdminFetchSectionHeroByIdQueryVariables = Exact<{
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type AdminFetchSectionHeroByIdQuery = {
  __typename?: 'query_root'
  sectionsHero?: {
    __typename?: 'SectionsHero'
    enableAiRecommendationPrompt?: string | null
    enableAiRecommendationTagsPrompt?: string | null
    companyName?: string | null
    featureText?: string | null
    descriptionText?: string | null
    callToActionText?: string | null
    callToActionHref?: string | null
    enableAiTrail?: boolean | null
    enableAiTrailDisplayText?: string | null
    enableAiRecommendation?: boolean | null
    enableAiRecommendationDisplayText?: string | null
    key: 'SectionsHero'
    sys: { __typename?: 'Sys'; id: string }
    backgroundImage?: { __typename?: 'Asset'; url?: string | null } | null
    fullWidthBackgroundImageMobile?: { __typename?: 'Asset'; url?: string | null } | null
    fullWidthBackgroundImageDesktop?: { __typename?: 'Asset'; url?: string | null } | null
  } | null
}

export type AdminFetchPromptEngineerByIdQueryVariables = Exact<{
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type AdminFetchPromptEngineerByIdQuery = {
  __typename?: 'query_root'
  promptEngineering?: {
    __typename?: 'PromptEngineering'
    prompt?: string | null
    purpose?: string | null
    sys: { __typename?: 'Sys'; id: string }
  } | null
}

export type FetchSectionVideoByIdQueryVariables = Exact<{
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchSectionVideoByIdQuery = {
  __typename?: 'query_root'
  sectionsMinimalsVideo?: {
    __typename?: 'SectionsMinimalsVideo'
    title?: string | null
    description?: string | null
    mode?: string | null
    videoRatio?: string | null
    youtubeEmbedLink?: string | null
    key: 'SectionsMinimalsVideo'
    callToAction?: {
      __typename?: 'CallToActionLink'
      title?: string | null
      link?: string | null
    } | null
    video?: { __typename?: 'Asset'; url?: string | null } | null
    image?: { __typename?: 'Asset'; url?: string | null } | null
    featureDescription?: {
      __typename?: 'SectionsMinimalsVideoFeatureDescription'
      json: any
      links: {
        __typename?: 'SectionsMinimalsVideoFeatureDescriptionLinks'
        assets: {
          __typename?: 'SectionsMinimalsVideoFeatureDescriptionAssets'
          block: Array<{
            __typename?: 'Asset'
            url?: string | null
            width?: number | null
            height?: number | null
            description?: string | null
            sys: { __typename?: 'Sys'; id: string }
          } | null>
        }
      }
    } | null
  } | null
}

export type FetchSectionServicesByIdQueryVariables = Exact<{
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchSectionServicesByIdQuery = {
  __typename?: 'query_root'
  sectionsMinimalsServices?: {
    __typename?: 'SectionsMinimalsServices'
    title?: string | null
    description?: string | null
    key: 'SectionsMinimalsServices'
    componentsCollection?: {
      __typename?: 'SectionsMinimalsServicesComponentsCollection'
      items: Array<{
        __typename?: 'SectionsMinimalsService'
        title?: string | null
        services?: Array<string | null> | null
        callToActionText?: string | null
        callToActionHref?: string | null
      } | null>
    } | null
  } | null
}

export type FetchSectionImagesByIdQueryVariables = Exact<{
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchSectionImagesByIdQuery = {
  __typename?: 'query_root'
  sectionsMinimalsImagesList?: {
    __typename?: 'SectionsMinimalsImagesList'
    title?: string | null
    description?: string | null
    displayMode?: string | null
    gridModeColumnsCountDesktop?: number | null
    gridModeColumnsCountMobile?: number | null
    gridModeGapDesktop?: number | null
    gridModeGapMobile?: number | null
    key: 'SectionsMinimalsImagesList'
    imagesCollection?: {
      __typename?: 'AssetCollection'
      items: Array<{ __typename?: 'Asset'; url?: string | null } | null>
    } | null
    imagesMobileCollection?: {
      __typename?: 'AssetCollection'
      items: Array<{ __typename?: 'Asset'; url?: string | null } | null>
    } | null
    imagesWithHrefMobileCollection?: {
      __typename?: 'SectionsMinimalsImagesListImagesWithHrefMobileCollection'
      items: Array<{
        __typename?: 'SectionsImage'
        callToActionHref?: string | null
        key: 'SectionsImage'
        sys: { __typename?: 'Sys'; id: string }
        image?: { __typename?: 'Asset'; title?: string | null; url?: string | null } | null
        mobileImage?: { __typename?: 'Asset'; title?: string | null; url?: string | null } | null
      } | null>
    } | null
    imagesWithHrefDesktopCollection?: {
      __typename?: 'SectionsMinimalsImagesListImagesWithHrefDesktopCollection'
      items: Array<{
        __typename?: 'SectionsImage'
        callToActionHref?: string | null
        key: 'SectionsImage'
        sys: { __typename?: 'Sys'; id: string }
        image?: { __typename?: 'Asset'; title?: string | null; url?: string | null } | null
        mobileImage?: { __typename?: 'Asset'; title?: string | null; url?: string | null } | null
      } | null>
    } | null
  } | null
}

export type FetchSectionHumanListByIdQueryVariables = Exact<{
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchSectionHumanListByIdQuery = {
  __typename?: 'query_root'
  sectionsMinimalsHumanList?: {
    __typename?: 'SectionsMinimalsHumanList'
    title?: string | null
    description?: string | null
    key: 'SectionsMinimalsHumanList'
    componentsCollection?: {
      __typename?: 'SectionsMinimalsHumanListComponentsCollection'
      items: Array<{
        __typename?: 'SectionsMinimalsHumanComponent'
        title?: string | null
        description?: string | null
        descriptionHref?: string | null
        featureImage?: { __typename?: 'Asset'; url?: string | null } | null
      } | null>
    } | null
  } | null
}

export type FetchSectionDetailPersonListByIdQueryVariables = Exact<{
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchSectionDetailPersonListByIdQuery = {
  __typename?: 'query_root'
  sectionsDetailPersonList?: {
    __typename?: 'SectionsDetailPersonList'
    title?: string | null
    description?: string | null
    key: 'SectionsDetailPersonList'
    componentsCollection?: {
      __typename?: 'SectionsDetailPersonListComponentsCollection'
      items: Array<{
        __typename?: 'SectionsDetailPersonComponent'
        title?: string | null
        titleHref?: string | null
        description?: string | null
        descriptionHref?: string | null
        detail?: { __typename?: 'SectionsDetailPersonComponentDetail'; json: any } | null
        avatar?: { __typename?: 'Asset'; url?: string | null } | null
      } | null>
    } | null
  } | null
}

export type FetchSectionFaqListByIdQueryVariables = Exact<{
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchSectionFaqListByIdQuery = {
  __typename?: 'query_root'
  sectionsFaqList?: {
    __typename?: 'SectionsFaqList'
    title?: string | null
    description?: string | null
    key: 'SectionsFaqList'
    componentsCollection?: {
      __typename?: 'SectionsFaqListComponentsCollection'
      items: Array<{
        __typename?: 'SectionsFaqComponent'
        title?: string | null
        content?: { __typename?: 'SectionsFaqComponentContent'; json: any } | null
      } | null>
    } | null
  } | null
}

export type FetchSectionHistoryListByIdQueryVariables = Exact<{
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchSectionHistoryListByIdQuery = {
  __typename?: 'query_root'
  sectionsMinimalsHistoryList?: {
    __typename?: 'SectionsMinimalsHistoryList'
    title?: string | null
    description?: string | null
    componentDateFormat?: string | null
    key: 'SectionsMinimalsHistoryList'
    componentsCollection?: {
      __typename?: 'SectionsMinimalsHistoryListComponentsCollection'
      items: Array<{
        __typename?: 'SectionsMinimalsHistoryComponent'
        title?: string | null
        description?: string | null
        displayTime?: any | null
        featureImage?: { __typename?: 'Asset'; url?: string | null } | null
      } | null>
    } | null
  } | null
}

export type FetchSectionContactListByIdQueryVariables = Exact<{
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchSectionContactListByIdQuery = {
  __typename?: 'query_root'
  sectionsMinimalsContactList?: {
    __typename?: 'SectionsMinimalsContactList'
    title?: string | null
    description?: string | null
    key: 'SectionsMinimalsContactList'
    linksCollection?: {
      __typename?: 'SectionsMinimalsContactListLinksCollection'
      items: Array<{
        __typename?: 'SectionsMinimalsContactComponent'
        title?: string | null
        href?: string | null
      } | null>
    } | null
  } | null
}

export type FetchSectionTimerByIdQueryVariables = Exact<{
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchSectionTimerByIdQuery = {
  __typename?: 'query_root'
  sectionsTimer?: {
    __typename?: 'SectionsTimer'
    title?: string | null
    description?: string | null
    startTime?: any | null
    appearance?: string | null
    key: 'SectionsTimer'
    backgroundImage?: { __typename?: 'Asset'; url?: string | null } | null
  } | null
}

export type FetchSectionFormByIdQueryVariables = Exact<{
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchSectionFormByIdQuery = {
  __typename?: 'query_root'
  sectionsForm?: {
    __typename?: 'SectionsForm'
    name?: string | null
    title?: string | null
    description?: string | null
    callToActionText?: string | null
    callToActionHref?: string | null
    key: 'SectionsForm'
    sys: { __typename?: 'Sys'; id: string }
    componentsCollection?: {
      __typename?: 'SectionsFormComponentsCollection'
      items: Array<{
        __typename?: 'SectionsFormComponent'
        type?: string | null
        label?: string | null
        placeholder?: string | null
        helperText?: string | null
        defaultValue?: string | null
        size?: string | null
        maxLength?: number | null
        minLength?: number | null
        max?: string | null
        min?: string | null
        required?: boolean | null
        disabled?: boolean | null
        readOnly?: boolean | null
        defaultChecked?: boolean | null
        fullWidth?: boolean | null
        pattern?: string | null
        multiple?: boolean | null
        options?: Array<string | null> | null
        step?: number | null
        key: 'SectionsFormComponent'
        sys: { __typename?: 'Sys'; id: string }
      } | null>
    } | null
  } | null
}

export type FetchSectionGalleryByIdQueryVariables = Exact<{
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchSectionGalleryByIdQuery = {
  __typename?: 'query_root'
  sectionsMinimalsGallery?: {
    __typename?: 'SectionsMinimalsGallery'
    title?: string | null
    description?: string | null
    key: 'SectionsMinimalsGallery'
    displayItemsCollection?: {
      __typename?: 'SectionsMinimalsGalleryDisplayItemsCollection'
      items: Array<{
        __typename?: 'SectionsMinimalsImagesList'
        title?: string | null
        description?: string | null
        sys: { __typename?: 'Sys'; id: string }
        imagesCollection?: {
          __typename?: 'AssetCollection'
          items: Array<{
            __typename?: 'Asset'
            url?: string | null
            title?: string | null
            description?: string | null
            sys: { __typename?: 'Sys'; id: string }
          } | null>
        } | null
      } | null>
    } | null
  } | null
}

export type FetchSectionImageByIdQueryVariables = Exact<{
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchSectionImageByIdQuery = {
  __typename?: 'query_root'
  sectionsImage?: {
    __typename?: 'SectionsImage'
    callToActionHref?: string | null
    key: 'SectionsImage'
    sys: { __typename?: 'Sys'; id: string }
    image?: { __typename?: 'Asset'; title?: string | null; url?: string | null } | null
    mobileImage?: { __typename?: 'Asset'; title?: string | null; url?: string | null } | null
  } | null
}

export type FetchSectionImagesByIdForGalleryQueryVariables = Exact<{
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchSectionImagesByIdForGalleryQuery = {
  __typename?: 'query_root'
  sectionsMinimalsImagesList?: {
    __typename?: 'SectionsMinimalsImagesList'
    title?: string | null
    description?: string | null
    displayMode?: string | null
    gridModeColumnsCountDesktop?: number | null
    gridModeColumnsCountMobile?: number | null
    key: 'SectionsMinimalsImagesList'
    imagesCollection?: {
      __typename?: 'AssetCollection'
      items: Array<{
        __typename?: 'Asset'
        url?: string | null
        title?: string | null
        description?: string | null
        sys: { __typename?: 'Sys'; id: string }
      } | null>
    } | null
  } | null
}

export type FetchAllSectionFormQueryVariables = Exact<{ [key: string]: never }>

export type FetchAllSectionFormQuery = {
  __typename?: 'query_root'
  sectionsFormCollection?: {
    __typename?: 'SectionsFormCollection'
    items: Array<{
      __typename?: 'SectionsForm'
      name?: string | null
      sys: { __typename?: 'Sys'; id: string }
    } | null>
  } | null
}

export type FetchSectionFormComponentsByFormIdQueryVariables = Exact<{
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
}>

export type FetchSectionFormComponentsByFormIdQuery = {
  __typename?: 'query_root'
  sectionsForm?: {
    __typename?: 'SectionsForm'
    componentsCollection?: {
      __typename?: 'SectionsFormComponentsCollection'
      items: Array<{
        __typename?: 'SectionsFormComponent'
        type?: string | null
        label?: string | null
        sys: { __typename?: 'Sys'; id: string }
      } | null>
    } | null
  } | null
}

export type FetchPromptsQueryVariables = Exact<{ [key: string]: never }>

export type FetchPromptsQuery = {
  __typename?: 'query_root'
  prompts: Array<{
    __typename?: 'PromptInterface'
    id: string
    promptHint: string
    promptTeaser: string
  }>
}

export type VisitorGeneratePromptQueryVariables = Exact<{
  language: PromptSupportingLanguageMap
  prompt: Scalars['String']
  promptId: Scalars['String']
}>

export type VisitorGeneratePromptQuery = {
  __typename?: 'query_root'
  generatePrompt: {
    __typename?: 'PromptResponse'
    id: string
    language: PromptSupportingLanguageMap
    prompt: string
    createResponse?: {
      __typename?: 'PromptCreateResponse'
      id: string
      choices: Array<{
        __typename?: 'PromptChoice'
        finish_reason: string
        index: number
        text: string
      }>
    } | null
  }
}

export type VisitorGeneratePromptWithTagsQueryVariables = Exact<{
  language: PromptSupportingLanguageMap
  prompt: Scalars['String']
  promptId: Scalars['String']
  tags: Array<Scalars['String']> | Scalars['String']
}>

export type VisitorGeneratePromptWithTagsQuery = {
  __typename?: 'query_root'
  generatePromptWithTags: {
    __typename?: 'PromptResponse'
    id: string
    language: PromptSupportingLanguageMap
    prompt: string
    createResponse?: {
      __typename?: 'PromptCreateResponse'
      id: string
      choices: Array<{
        __typename?: 'PromptChoice'
        finish_reason: string
        index: number
        text: string
      }>
    } | null
  }
}

export type VisitorFetchMidjourneyImagesQueryVariables = Exact<{
  limit: Scalars['Int']
  offset: Scalars['Int']
  where?: InputMaybe<MidjourneyImages_Bool_Exp>
  order_by?: InputMaybe<Array<MidjourneyImages_Order_By> | MidjourneyImages_Order_By>
}>

export type VisitorFetchMidjourneyImagesQuery = {
  __typename?: 'query_root'
  midjourneyImages: Array<{
    __typename?: 'midjourneyImages'
    id: any
    name: string
    prompt: string
    url: string
    width: number
    size: number
    height: number
    contentType: string
    cloudinaryPublicId?: string | null
    attachment: string
  }>
  midjourneyImages_aggregate: {
    __typename?: 'midjourneyImages_aggregate'
    aggregate?: { __typename?: 'midjourneyImages_aggregate_fields'; count: number } | null
  }
}

export type VisitorFetchMidjourneyImagesByPkQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type VisitorFetchMidjourneyImagesByPkQuery = {
  __typename?: 'query_root'
  midjourneyImages_by_pk?: {
    __typename?: 'midjourneyImages'
    id: any
    name: string
    height: number
    ephemeral: boolean
    discordUserId: string
    discordMessageId: string
    discordAttachmentId: string
    description?: string | null
    createdAt: any
    contentType: string
    cloudinaryPublicId?: string | null
    attachment: string
    prompt: string
    proxyURL: string
    recordedAt: any
    size: number
    updatedAt: any
    url: string
    width: number
  } | null
}

export type VisitorFetchPromptUsagesQueryVariables = Exact<{
  where?: InputMaybe<PromptUsages_Bool_Exp>
  order_by?: InputMaybe<Array<PromptUsages_Order_By> | PromptUsages_Order_By>
  limit?: InputMaybe<Scalars['Int']>
}>

export type VisitorFetchPromptUsagesQuery = {
  __typename?: 'query_root'
  promptUsages: Array<{
    __typename?: 'promptUsages'
    id: any
    createdAt: any
    language: string
    promptId: string
    response: any
    userPrompt: string
  }>
}

export type VisitorFetchAllImagesIdQueryVariables = Exact<{ [key: string]: never }>

export type VisitorFetchAllImagesIdQuery = {
  __typename?: 'query_root'
  midjourneyImages: Array<{ __typename?: 'midjourneyImages'; id: any }>
}

export type VisitorFetchImagesByIdsQueryVariables = Exact<{
  imagesId: Array<Scalars['uuid']> | Scalars['uuid']
}>

export type VisitorFetchImagesByIdsQuery = {
  __typename?: 'query_root'
  midjourneyImages: Array<{
    __typename?: 'midjourneyImages'
    id: any
    cloudinaryPublicId?: string | null
  }>
}

export const AICompletionRecordsFragmentDoc = gql`
  fragment aICompletionRecords on aICompletionRecords {
    id
    clientName
    meta
    createdAt
    content
    completedAt
    updatedAt
    meta
  }
`
export const TagsForAllProductsFragmentDoc = gql`
  fragment TagsForAllProducts on Tags {
    sys {
      id
    }
    name
    groupName
  }
`
export const PromptEngineeringAdminFragmentDoc = gql`
  fragment PromptEngineeringAdmin on PromptEngineering {
    prompt
    purpose
    sys {
      id
    }
  }
`
export const SeoMetadataFragmentFragmentDoc = gql`
  fragment seoMetadataFragment on SeoMetadata {
    name
    content
  }
`
export const SocialMediaLinkFragmentDoc = gql`
  fragment SocialMediaLink on SocialMediaLink {
    title
    link
    type
    isExternal
    overwriteDisplayIconWithIconifyIcon
  }
`
export const DisplayPagesFragmentDoc = gql`
  fragment DisplayPages on Pages {
    slug
    displayName(locale: $locale)
    iconifyIconNameForMobileMenu
  }
`
export const RootSettingsFragmentDoc = gql`
  fragment RootSettings on RootSettings {
    sectionColors
    scope
    companyColor
    theme
    companyLogo {
      url
    }
    companyFavicon {
      url
    }
    callToActionLink(locale: $locale) {
      title
      link
    }
    seoMetadataItemsCollection(locale: $locale) {
      items {
        ...seoMetadataFragment
      }
    }
    socialMediaLinksCollection(locale: $locale) {
      items {
        ...SocialMediaLink
      }
    }
    headerDisplayPagesCollection(locale: $locale) {
      items {
        ...DisplayPages
      }
    }
    mobileDrawerDisplayPagesCollection(locale: $locale) {
      items {
        ...DisplayPages
      }
    }
    promptEngineeringsCollection(locale: $locale) {
      items {
        title
        promptHint
        teaser
        purpose
        sys {
          id
        }
      }
    }
    renamePathDisplayTextBlog(locale: $locale)
    renamePathDisplayTextHome(locale: $locale)
    renamePathDisplayTextProduct(locale: $locale)
    renameCallToActionLinksDisplayText(locale: $locale)
  }
  ${SeoMetadataFragmentFragmentDoc}
  ${SocialMediaLinkFragmentDoc}
  ${DisplayPagesFragmentDoc}
`
export const SupportingLanguageFragmentFragmentDoc = gql`
  fragment supportingLanguageFragment on SupportingLanguage {
    _id
    name
    slug
  }
`
export const SupportingToneFragmentFragmentDoc = gql`
  fragment supportingToneFragment on SupportingTone {
    _id
    name
    slug
  }
`
export const ContextInputFragmentFragmentDoc = gql`
  fragment contextInputFragment on ContextInput {
    _id
    inputMaximumCharacters
    inputType
    isRequired
    keyLabel
    label
    placeholder
  }
`
export const SupportingUseCaseFragmentFragmentDoc = gql`
  fragment supportingUseCaseFragment on SupportingUseCase {
    _id
    caption
    createdAt
    description
    image
    info
    key
    name
    slug
    variants
    contextInputs {
      ...contextInputFragment
    }
  }
  ${ContextInputFragmentFragmentDoc}
`
export const Erc721TokenMetadataFragmentFragmentDoc = gql`
  fragment erc721TokenMetadataFragment on Erc721TokenMetadata {
    sys {
      id
    }
    tokenId
    name
    description
    image {
      url
    }
    animationUrl {
      url
    }
    externalUrl
    backgroundColor
    youtubeUrl
    attributesCollection {
      items {
        traitType {
          title
        }
        displayType
        value
      }
    }
  }
`
export const SmartContractErc721FragmentFragmentDoc = gql`
  fragment smartContractErc721Fragment on SmartContractErc721 {
    logo {
      url
    }
    bannerImage {
      url
    }
    featureImage {
      url
    }
    displayImage {
      url
    }
    smartContractAddress
    network
    abi
    isSingleTokenMetadataMode
    singleToken {
      sys {
        id
      }
    }
    whitelistAddressesCollection {
      items {
        name
        ethAddress
        alreadyMinted
      }
    }
  }
`
export const SectionsMintListComponentFragmentFragmentDoc = gql`
  fragment sectionsMintListComponentFragment on SectionsMintListComponent {
    title
    description
    price
    style
    smartContractCallToActionName
    isDisplay
    smartContractErc721 {
      ...smartContractErc721Fragment
    }
    dependencySmartContractErc721 {
      ...smartContractErc721Fragment
    }
  }
  ${SmartContractErc721FragmentFragmentDoc}
`
export const SectionsMintListFragmentFragmentDoc = gql`
  fragment sectionsMintListFragment on SectionsMintList {
    key: __typename
    title
    description
    mintCardsCollection(locale: $locale, limit: 50) {
      items {
        ...sectionsMintListComponentFragment
      }
    }
  }
  ${SectionsMintListComponentFragmentFragmentDoc}
`
export const SectionsFeaturesListFragmentFragmentDoc = gql`
  fragment sectionsFeaturesListFragment on SectionsFeaturesList {
    key: __typename
    title
    description
    componentsCollection(locale: $locale) {
      items {
        title
        description
        icon {
          url
        }
        iconifyIconName
      }
    }
  }
`
export const SectionsMinimalsContactListFragmentFragmentDoc = gql`
  fragment sectionsMinimalsContactListFragment on SectionsMinimalsContactList {
    key: __typename
    title
    description
    linksCollection(locale: $locale) {
      items {
        title
        href
      }
    }
  }
`
export const SectionsTimerFragmentFragmentDoc = gql`
  fragment sectionsTimerFragment on SectionsTimer {
    key: __typename
    title
    description
    startTime
    appearance
    backgroundImage {
      url
    }
  }
`
export const SectionsMinimalsHistoryListFragmentFragmentDoc = gql`
  fragment sectionsMinimalsHistoryListFragment on SectionsMinimalsHistoryList {
    key: __typename
    title
    description
    componentDateFormat
    componentsCollection(locale: $locale) {
      items {
        title
        description
        displayTime
        featureImage {
          url
        }
      }
    }
  }
`
export const GalleryAssetFragmentDoc = gql`
  fragment GalleryAsset on Asset {
    sys {
      id
    }
    url
    title
    description
  }
`
export const SectionsMinimalsGalleryFragmentFragmentDoc = gql`
  fragment sectionsMinimalsGalleryFragment on SectionsMinimalsGallery {
    key: __typename
    title
    description
    displayItemsCollection(locale: $locale) {
      items {
        sys {
          id
        }
        title
        description
        imagesCollection(limit: 1) {
          items {
            ...GalleryAsset
          }
        }
      }
    }
  }
  ${GalleryAssetFragmentDoc}
`
export const SectionsMinimalsImagesListForGalleryFragmentDoc = gql`
  fragment sectionsMinimalsImagesListForGallery on SectionsMinimalsImagesList {
    key: __typename
    title
    description
    displayMode
    gridModeColumnsCountDesktop
    gridModeColumnsCountMobile
    imagesCollection(locale: $locale) {
      items {
        ...GalleryAsset
      }
    }
  }
  ${GalleryAssetFragmentDoc}
`
export const SectionsFaqListFragmentFragmentDoc = gql`
  fragment sectionsFaqListFragment on SectionsFaqList {
    key: __typename
    title
    description
    componentsCollection(locale: $locale) {
      items {
        title
        content {
          json
        }
      }
    }
  }
`
export const SectionsDetailPersonListFragmentFragmentDoc = gql`
  fragment sectionsDetailPersonListFragment on SectionsDetailPersonList {
    key: __typename
    title
    description
    componentsCollection(locale: $locale) {
      items {
        title
        titleHref
        description
        descriptionHref
        detail {
          json
        }
        avatar {
          url
        }
      }
    }
  }
`
export const SectionsMinimalsHumanListFragmentFragmentDoc = gql`
  fragment sectionsMinimalsHumanListFragment on SectionsMinimalsHumanList {
    key: __typename
    title
    description
    componentsCollection(locale: $locale) {
      items {
        title
        description
        descriptionHref
        featureImage {
          url
        }
      }
    }
  }
`
export const SectionsImageFragmentDoc = gql`
  fragment SectionsImage on SectionsImage {
    key: __typename
    sys {
      id
    }
    image {
      title
      url
    }
    mobileImage {
      title
      url
    }
    callToActionHref
  }
`
export const SectionsMinimalsImagesListFragmentFragmentDoc = gql`
  fragment sectionsMinimalsImagesListFragment on SectionsMinimalsImagesList {
    key: __typename
    title
    description
    displayMode
    gridModeColumnsCountDesktop
    gridModeColumnsCountMobile
    gridModeGapDesktop
    gridModeGapMobile
    imagesCollection(locale: $locale) {
      items {
        url
      }
    }
    imagesMobileCollection(locale: $locale) {
      items {
        url
      }
    }
    imagesWithHrefMobileCollection(limit: 10, locale: $locale) {
      items {
        ...SectionsImage
      }
    }
    imagesWithHrefDesktopCollection(limit: 10, locale: $locale) {
      items {
        ...SectionsImage
      }
    }
  }
  ${SectionsImageFragmentDoc}
`
export const SectionsMinimalsServicesFragmentFragmentDoc = gql`
  fragment sectionsMinimalsServicesFragment on SectionsMinimalsServices {
    key: __typename
    title
    description
    componentsCollection(locale: $locale) {
      items {
        title
        services
        callToActionText
        callToActionHref
      }
    }
  }
`
export const SectionsHeroFragmentFragmentDoc = gql`
  fragment sectionsHeroFragment on SectionsHero {
    sys {
      id
    }
    key: __typename
    companyName
    featureText
    descriptionText
    callToActionText
    callToActionHref
    enableAiTrail
    enableAiTrailDisplayText
    enableAiRecommendation
    enableAiRecommendationDisplayText
    backgroundImage {
      url
    }
    fullWidthBackgroundImageMobile {
      url
    }
    fullWidthBackgroundImageDesktop {
      url
    }
  }
`
export const SectionsHeroFragmentAdminFragmentDoc = gql`
  fragment sectionsHeroFragmentAdmin on SectionsHero {
    ...sectionsHeroFragment
    enableAiRecommendationPrompt
    enableAiRecommendationTagsPrompt
  }
  ${SectionsHeroFragmentFragmentDoc}
`
export const AssetFragmentDoc = gql`
  fragment Asset on Asset {
    sys {
      id
    }
    url
    width
    height
    description
  }
`
export const SectionsMinimalsVideoFeatureDescriptionAssetsFragmentDoc = gql`
  fragment SectionsMinimalsVideoFeatureDescriptionAssets on SectionsMinimalsVideoFeatureDescriptionAssets {
    block {
      ...Asset
    }
  }
  ${AssetFragmentDoc}
`
export const SectionsMinimalsVideoFragmentFragmentDoc = gql`
  fragment sectionsMinimalsVideoFragment on SectionsMinimalsVideo {
    key: __typename
    title
    description
    mode
    videoRatio
    callToAction(locale: $locale) {
      title
      link
    }
    video {
      url
    }
    image {
      url
    }
    youtubeEmbedLink
    featureDescription {
      json
      links {
        assets {
          ...SectionsMinimalsVideoFeatureDescriptionAssets
        }
      }
    }
  }
  ${SectionsMinimalsVideoFeatureDescriptionAssetsFragmentDoc}
`
export const SectionsFormComponentFragmentFragmentDoc = gql`
  fragment sectionsFormComponentFragment on SectionsFormComponent {
    key: __typename
    sys {
      id
    }
    type
    label
    placeholder
    helperText
    defaultValue
    size
    maxLength
    minLength
    max
    min
    required
    disabled
    readOnly
    defaultChecked
    fullWidth
    pattern
    multiple
    options
    step
  }
`
export const SectionsFormFragmentFragmentDoc = gql`
  fragment sectionsFormFragment on SectionsForm {
    key: __typename
    sys {
      id
    }
    name
    title
    description
    componentsCollection {
      items {
        ...sectionsFormComponentFragment
      }
    }
    callToActionText
    callToActionHref
  }
  ${SectionsFormComponentFragmentFragmentDoc}
`
export const SectionsImageFragmentFragmentDoc = gql`
  fragment sectionsImageFragment on SectionsImage {
    key: __typename
    sys {
      id
    }
    image {
      title
      url
    }
    mobileImage {
      title
      url
    }
    callToActionHref
  }
`
export const BlogFragmentFragmentDoc = gql`
  fragment blogFragment on Blogs {
    title(locale: $locale)
    sys {
      id
      firstPublishedAt
    }
    coverImage {
      url
    }
    previewImage {
      url
    }
    tagsCollection {
      items {
        name(locale: $locale)
      }
    }
    author {
      name
      profileImage {
        url
      }
    }
  }
`
export const TagFragmentFragmentDoc = gql`
  fragment tagFragment on Tags {
    slug
    name(locale: $locale)
    seoMetadataItemsCollection(locale: $locale) {
      items {
        ...seoMetadataFragment
      }
    }
    sys {
      id
      firstPublishedAt
    }
  }
  ${SeoMetadataFragmentFragmentDoc}
`
export const TagsContentAssetsFragmentDoc = gql`
  fragment TagsContentAssets on TagsContentAssets {
    block {
      ...Asset
    }
  }
  ${AssetFragmentDoc}
`
export const TagContentFragmentFragmentDoc = gql`
  fragment tagContentFragment on Tags {
    sys {
      id
    }
    content(locale: $locale) {
      json
      links {
        assets {
          ...TagsContentAssets
        }
      }
    }
  }
  ${TagsContentAssetsFragmentDoc}
`
export const BlogsContentAssetsFragmentDoc = gql`
  fragment BlogsContentAssets on BlogsContentAssets {
    block {
      ...Asset
    }
  }
  ${AssetFragmentDoc}
`
export const BlogContentFragmentFragmentDoc = gql`
  fragment blogContentFragment on Blogs {
    content(locale: $locale) {
      json
      links {
        assets {
          ...BlogsContentAssets
        }
      }
    }
  }
  ${BlogsContentAssetsFragmentDoc}
`
export const BlogLinkingFragmentFragmentDoc = gql`
  fragment blogLinkingFragment on BlogsLinkingCollections {
    categoriesCollection(limit: 2) {
      items {
        title(locale: $locale)
      }
    }
  }
`
export const ProductPreviewFragmentFragmentDoc = gql`
  fragment productPreviewFragment on Products {
    sys {
      id
      publishedAt
    }
    slug
    title
    abstract
    price
    callToActionLink {
      title
      link
    }
    previewImage {
      url
    }
    imagesCollection(limit: 1) {
      items {
        url
      }
    }
    tagsCollection {
      items {
        sys {
          id
        }
        name
      }
    }
  }
`
export const ProductsContentAssetsFragmentDoc = gql`
  fragment productsContentAssets on ProductsContentAssets {
    block {
      ...Asset
    }
  }
  ${AssetFragmentDoc}
`
export const ProductContentFragmentFragmentDoc = gql`
  fragment productContentFragment on Products {
    content(locale: $locale) {
      json
      links {
        assets {
          ...productsContentAssets
        }
      }
    }
  }
  ${ProductsContentAssetsFragmentDoc}
`
export const PromptResponseFragmentDoc = gql`
  fragment PromptResponse on PromptResponse {
    id
    language
    prompt
    createResponse {
      id
      choices {
        finish_reason
        index
        text
      }
    }
  }
`
export const VisitorPromptUsagesFragmentDoc = gql`
  fragment visitorPromptUsages on promptUsages {
    id
    createdAt
    language
    promptId
    response
    userPrompt
  }
`
export const MidjourneyImagesFragmentDoc = gql`
  fragment midjourneyImages on midjourneyImages {
    id
    name
    height
    ephemeral
    discordUserId
    discordMessageId
    discordAttachmentId
    description
    createdAt
    contentType
    cloudinaryPublicId
    attachment
    prompt
    proxyURL
    recordedAt
    size
    updatedAt
    url
    width
  }
`
export const AdminInsertNewebpayEpgNotificationDocument = gql`
  mutation adminInsertNewebpayEpgNotification($object: newebpayEpgNotifications_insert_input!) {
    insert_newebpayEpgNotifications_one(object: $object) {
      amt
      checkCode
      consigneeName
      createdAt
      data
      paymentType
      merchantId
      merchantOrderNo
      payerAddr
      payerEmail
      payerTel
      payerName
      quantity
      status
      tradeNo
      unitPrice
      updatedAt
      id
      isValid
    }
  }
`
export type AdminInsertNewebpayEpgNotificationMutationFn = Apollo.MutationFunction<
  AdminInsertNewebpayEpgNotificationMutation,
  AdminInsertNewebpayEpgNotificationMutationVariables
>

/**
 * __useAdminInsertNewebpayEpgNotificationMutation__
 *
 * To run a mutation, you first call `useAdminInsertNewebpayEpgNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminInsertNewebpayEpgNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminInsertNewebpayEpgNotificationMutation, { data, loading, error }] = useAdminInsertNewebpayEpgNotificationMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useAdminInsertNewebpayEpgNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminInsertNewebpayEpgNotificationMutation,
    AdminInsertNewebpayEpgNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminInsertNewebpayEpgNotificationMutation,
    AdminInsertNewebpayEpgNotificationMutationVariables
  >(AdminInsertNewebpayEpgNotificationDocument, options)
}
export type AdminInsertNewebpayEpgNotificationMutationHookResult = ReturnType<
  typeof useAdminInsertNewebpayEpgNotificationMutation
>
export type AdminInsertNewebpayEpgNotificationMutationResult =
  Apollo.MutationResult<AdminInsertNewebpayEpgNotificationMutation>
export type AdminInsertNewebpayEpgNotificationMutationOptions = Apollo.BaseMutationOptions<
  AdminInsertNewebpayEpgNotificationMutation,
  AdminInsertNewebpayEpgNotificationMutationVariables
>
export const AdminUpsertUsersOneForLoginDocument = gql`
  mutation adminUpsertUsersOneForLogin(
    $name: String!
    $nickname: String!
    $profileUrl: String!
    $auth0userId: String!
    $appId: String!
    $email: String
  ) {
    insert_users_one(
      object: {
        name: $name
        nickname: $nickname
        profileUrl: $profileUrl
        auth0userId: $auth0userId
        appId: $appId
        email: $email
      }
      on_conflict: {
        update_columns: [name, nickname, profileUrl, email]
        constraint: users_appId_auth0userId_key
      }
    ) {
      updatedAt
      profileUrl
      nickname
      name
      id
      email
      createdAt
      auth0userId
      appId
      stripeCustomerId
      app {
        id
        stripeWritersoftLookupKey
        stripeWritersoftConfigurationId
      }
      subscriptions(where: { stripeStatus: { _eq: "active" } }) {
        stripeStatus
        subscriptionItems {
          stripeSubscriptionItemId
          id
        }
      }
    }
  }
`
export type AdminUpsertUsersOneForLoginMutationFn = Apollo.MutationFunction<
  AdminUpsertUsersOneForLoginMutation,
  AdminUpsertUsersOneForLoginMutationVariables
>

/**
 * __useAdminUpsertUsersOneForLoginMutation__
 *
 * To run a mutation, you first call `useAdminUpsertUsersOneForLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpsertUsersOneForLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpsertUsersOneForLoginMutation, { data, loading, error }] = useAdminUpsertUsersOneForLoginMutation({
 *   variables: {
 *      name: // value for 'name'
 *      nickname: // value for 'nickname'
 *      profileUrl: // value for 'profileUrl'
 *      auth0userId: // value for 'auth0userId'
 *      appId: // value for 'appId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAdminUpsertUsersOneForLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpsertUsersOneForLoginMutation,
    AdminUpsertUsersOneForLoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminUpsertUsersOneForLoginMutation,
    AdminUpsertUsersOneForLoginMutationVariables
  >(AdminUpsertUsersOneForLoginDocument, options)
}
export type AdminUpsertUsersOneForLoginMutationHookResult = ReturnType<
  typeof useAdminUpsertUsersOneForLoginMutation
>
export type AdminUpsertUsersOneForLoginMutationResult =
  Apollo.MutationResult<AdminUpsertUsersOneForLoginMutation>
export type AdminUpsertUsersOneForLoginMutationOptions = Apollo.BaseMutationOptions<
  AdminUpsertUsersOneForLoginMutation,
  AdminUpsertUsersOneForLoginMutationVariables
>
export const AdminInsertWritersoftUsageReportOneDocument = gql`
  mutation adminInsertWritersoftUsageReportOne(
    $appId: String!
    $characters: Int!
    $format: String!
    $inputContexts: jsonb!
    $languageId: String!
    $response: jsonb
    $toneId: String!
    $userId: uuid
    $variations: Int!
    $useCaseId: String!
    $stripeSubscriptionItemId: String
  ) {
    insert_writersoftUsageReports_one(
      object: {
        appId: $appId
        characters: $characters
        format: $format
        inputContexts: $inputContexts
        languageId: $languageId
        response: $response
        toneId: $toneId
        userId: $userId
        variations: $variations
        useCaseId: $useCaseId
        stripeSubscriptionItemId: $stripeSubscriptionItemId
      }
    ) {
      appId
      characters
      createdAt
      format
      id
      inputContexts
      languageId
      response
      toneId
      updatedAt
      useCaseId
      userId
      variations
      stripeSubscriptionItemId
    }
  }
`
export type AdminInsertWritersoftUsageReportOneMutationFn = Apollo.MutationFunction<
  AdminInsertWritersoftUsageReportOneMutation,
  AdminInsertWritersoftUsageReportOneMutationVariables
>

/**
 * __useAdminInsertWritersoftUsageReportOneMutation__
 *
 * To run a mutation, you first call `useAdminInsertWritersoftUsageReportOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminInsertWritersoftUsageReportOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminInsertWritersoftUsageReportOneMutation, { data, loading, error }] = useAdminInsertWritersoftUsageReportOneMutation({
 *   variables: {
 *      appId: // value for 'appId'
 *      characters: // value for 'characters'
 *      format: // value for 'format'
 *      inputContexts: // value for 'inputContexts'
 *      languageId: // value for 'languageId'
 *      response: // value for 'response'
 *      toneId: // value for 'toneId'
 *      userId: // value for 'userId'
 *      variations: // value for 'variations'
 *      useCaseId: // value for 'useCaseId'
 *      stripeSubscriptionItemId: // value for 'stripeSubscriptionItemId'
 *   },
 * });
 */
export function useAdminInsertWritersoftUsageReportOneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminInsertWritersoftUsageReportOneMutation,
    AdminInsertWritersoftUsageReportOneMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminInsertWritersoftUsageReportOneMutation,
    AdminInsertWritersoftUsageReportOneMutationVariables
  >(AdminInsertWritersoftUsageReportOneDocument, options)
}
export type AdminInsertWritersoftUsageReportOneMutationHookResult = ReturnType<
  typeof useAdminInsertWritersoftUsageReportOneMutation
>
export type AdminInsertWritersoftUsageReportOneMutationResult =
  Apollo.MutationResult<AdminInsertWritersoftUsageReportOneMutation>
export type AdminInsertWritersoftUsageReportOneMutationOptions = Apollo.BaseMutationOptions<
  AdminInsertWritersoftUsageReportOneMutation,
  AdminInsertWritersoftUsageReportOneMutationVariables
>
export const UserUpdateUserByPkDocument = gql`
  mutation userUpdateUserByPk($id: uuid!, $user: users_set_input!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: $user) {
      appId
      auth0userId
      email
      id
      name
      nickname
      profileUrl
      stripeCustomerId
    }
  }
`
export type UserUpdateUserByPkMutationFn = Apollo.MutationFunction<
  UserUpdateUserByPkMutation,
  UserUpdateUserByPkMutationVariables
>

/**
 * __useUserUpdateUserByPkMutation__
 *
 * To run a mutation, you first call `useUserUpdateUserByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateUserByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateUserByPkMutation, { data, loading, error }] = useUserUpdateUserByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUserUpdateUserByPkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserUpdateUserByPkMutation,
    UserUpdateUserByPkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UserUpdateUserByPkMutation, UserUpdateUserByPkMutationVariables>(
    UserUpdateUserByPkDocument,
    options
  )
}
export type UserUpdateUserByPkMutationHookResult = ReturnType<typeof useUserUpdateUserByPkMutation>
export type UserUpdateUserByPkMutationResult = Apollo.MutationResult<UserUpdateUserByPkMutation>
export type UserUpdateUserByPkMutationOptions = Apollo.BaseMutationOptions<
  UserUpdateUserByPkMutation,
  UserUpdateUserByPkMutationVariables
>
export const AdminInsertStripeWebhookOneDocument = gql`
  mutation adminInsertStripeWebhookOne($data: jsonb!, $eventType: String!) {
    insert_stripeWebhookLogs_one(object: { data: $data, eventType: $eventType }) {
      id
      eventType
      data
      updatedAt
      createdAt
    }
  }
`
export type AdminInsertStripeWebhookOneMutationFn = Apollo.MutationFunction<
  AdminInsertStripeWebhookOneMutation,
  AdminInsertStripeWebhookOneMutationVariables
>

/**
 * __useAdminInsertStripeWebhookOneMutation__
 *
 * To run a mutation, you first call `useAdminInsertStripeWebhookOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminInsertStripeWebhookOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminInsertStripeWebhookOneMutation, { data, loading, error }] = useAdminInsertStripeWebhookOneMutation({
 *   variables: {
 *      data: // value for 'data'
 *      eventType: // value for 'eventType'
 *   },
 * });
 */
export function useAdminInsertStripeWebhookOneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminInsertStripeWebhookOneMutation,
    AdminInsertStripeWebhookOneMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminInsertStripeWebhookOneMutation,
    AdminInsertStripeWebhookOneMutationVariables
  >(AdminInsertStripeWebhookOneDocument, options)
}
export type AdminInsertStripeWebhookOneMutationHookResult = ReturnType<
  typeof useAdminInsertStripeWebhookOneMutation
>
export type AdminInsertStripeWebhookOneMutationResult =
  Apollo.MutationResult<AdminInsertStripeWebhookOneMutation>
export type AdminInsertStripeWebhookOneMutationOptions = Apollo.BaseMutationOptions<
  AdminInsertStripeWebhookOneMutation,
  AdminInsertStripeWebhookOneMutationVariables
>
export const AdminUpdateSubscriptionStripeDataByStripeSubscriptionIdDocument = gql`
  mutation adminUpdateSubscriptionStripeDataByStripeSubscriptionId(
    $stripeSubscriptionId: String!
    $setStripeStatus: String!
  ) {
    update_subscriptions(
      where: { stripeSubscriptionId: { _eq: $stripeSubscriptionId } }
      _set: { stripeStatus: $setStripeStatus }
    ) {
      affected_rows
    }
  }
`
export type AdminUpdateSubscriptionStripeDataByStripeSubscriptionIdMutationFn =
  Apollo.MutationFunction<
    AdminUpdateSubscriptionStripeDataByStripeSubscriptionIdMutation,
    AdminUpdateSubscriptionStripeDataByStripeSubscriptionIdMutationVariables
  >

/**
 * __useAdminUpdateSubscriptionStripeDataByStripeSubscriptionIdMutation__
 *
 * To run a mutation, you first call `useAdminUpdateSubscriptionStripeDataByStripeSubscriptionIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateSubscriptionStripeDataByStripeSubscriptionIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateSubscriptionStripeDataByStripeSubscriptionIdMutation, { data, loading, error }] = useAdminUpdateSubscriptionStripeDataByStripeSubscriptionIdMutation({
 *   variables: {
 *      stripeSubscriptionId: // value for 'stripeSubscriptionId'
 *      setStripeStatus: // value for 'setStripeStatus'
 *   },
 * });
 */
export function useAdminUpdateSubscriptionStripeDataByStripeSubscriptionIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateSubscriptionStripeDataByStripeSubscriptionIdMutation,
    AdminUpdateSubscriptionStripeDataByStripeSubscriptionIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminUpdateSubscriptionStripeDataByStripeSubscriptionIdMutation,
    AdminUpdateSubscriptionStripeDataByStripeSubscriptionIdMutationVariables
  >(AdminUpdateSubscriptionStripeDataByStripeSubscriptionIdDocument, options)
}
export type AdminUpdateSubscriptionStripeDataByStripeSubscriptionIdMutationHookResult = ReturnType<
  typeof useAdminUpdateSubscriptionStripeDataByStripeSubscriptionIdMutation
>
export type AdminUpdateSubscriptionStripeDataByStripeSubscriptionIdMutationResult =
  Apollo.MutationResult<AdminUpdateSubscriptionStripeDataByStripeSubscriptionIdMutation>
export type AdminUpdateSubscriptionStripeDataByStripeSubscriptionIdMutationOptions =
  Apollo.BaseMutationOptions<
    AdminUpdateSubscriptionStripeDataByStripeSubscriptionIdMutation,
    AdminUpdateSubscriptionStripeDataByStripeSubscriptionIdMutationVariables
  >
export const AdminUpdateSubscriptionStripeDataDocument = gql`
  mutation adminUpdateSubscriptionStripeData(
    $setStripeSubscriptionId: String!
    $stripeCheckoutSessionId: String!
    $setStripeStatus: String!
    $subscriptionItems: [subscriptionItems_insert_input!]!
  ) {
    update_subscriptions(
      where: { stripeCheckoutSessionId: { _eq: $stripeCheckoutSessionId } }
      _set: { stripeSubscriptionId: $setStripeSubscriptionId, stripeStatus: $setStripeStatus }
    ) {
      affected_rows
    }
    insert_subscriptionItems(objects: $subscriptionItems) {
      affected_rows
      returning {
        appId
        createdAt
        id
        stripeSubscriptionItemId
        subscriptionId
        updatedAt
      }
    }
  }
`
export type AdminUpdateSubscriptionStripeDataMutationFn = Apollo.MutationFunction<
  AdminUpdateSubscriptionStripeDataMutation,
  AdminUpdateSubscriptionStripeDataMutationVariables
>

/**
 * __useAdminUpdateSubscriptionStripeDataMutation__
 *
 * To run a mutation, you first call `useAdminUpdateSubscriptionStripeDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateSubscriptionStripeDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateSubscriptionStripeDataMutation, { data, loading, error }] = useAdminUpdateSubscriptionStripeDataMutation({
 *   variables: {
 *      setStripeSubscriptionId: // value for 'setStripeSubscriptionId'
 *      stripeCheckoutSessionId: // value for 'stripeCheckoutSessionId'
 *      setStripeStatus: // value for 'setStripeStatus'
 *      subscriptionItems: // value for 'subscriptionItems'
 *   },
 * });
 */
export function useAdminUpdateSubscriptionStripeDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateSubscriptionStripeDataMutation,
    AdminUpdateSubscriptionStripeDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminUpdateSubscriptionStripeDataMutation,
    AdminUpdateSubscriptionStripeDataMutationVariables
  >(AdminUpdateSubscriptionStripeDataDocument, options)
}
export type AdminUpdateSubscriptionStripeDataMutationHookResult = ReturnType<
  typeof useAdminUpdateSubscriptionStripeDataMutation
>
export type AdminUpdateSubscriptionStripeDataMutationResult =
  Apollo.MutationResult<AdminUpdateSubscriptionStripeDataMutation>
export type AdminUpdateSubscriptionStripeDataMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateSubscriptionStripeDataMutation,
  AdminUpdateSubscriptionStripeDataMutationVariables
>
export const AdminUpdateUnsentStripeRecordDocument = gql`
  mutation adminUpdateUnsentStripeRecord($id: uuid!, $sentAt: timestamp!, $stripeResponse: jsonb!) {
    update_writersoftUsageReports_by_pk(
      pk_columns: { id: $id }
      _set: { sentAt: $sentAt, stripeResponse: $stripeResponse }
    ) {
      id
      sentAt
      stripeResponse
    }
  }
`
export type AdminUpdateUnsentStripeRecordMutationFn = Apollo.MutationFunction<
  AdminUpdateUnsentStripeRecordMutation,
  AdminUpdateUnsentStripeRecordMutationVariables
>

/**
 * __useAdminUpdateUnsentStripeRecordMutation__
 *
 * To run a mutation, you first call `useAdminUpdateUnsentStripeRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateUnsentStripeRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateUnsentStripeRecordMutation, { data, loading, error }] = useAdminUpdateUnsentStripeRecordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sentAt: // value for 'sentAt'
 *      stripeResponse: // value for 'stripeResponse'
 *   },
 * });
 */
export function useAdminUpdateUnsentStripeRecordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateUnsentStripeRecordMutation,
    AdminUpdateUnsentStripeRecordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminUpdateUnsentStripeRecordMutation,
    AdminUpdateUnsentStripeRecordMutationVariables
  >(AdminUpdateUnsentStripeRecordDocument, options)
}
export type AdminUpdateUnsentStripeRecordMutationHookResult = ReturnType<
  typeof useAdminUpdateUnsentStripeRecordMutation
>
export type AdminUpdateUnsentStripeRecordMutationResult =
  Apollo.MutationResult<AdminUpdateUnsentStripeRecordMutation>
export type AdminUpdateUnsentStripeRecordMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateUnsentStripeRecordMutation,
  AdminUpdateUnsentStripeRecordMutationVariables
>
export const AdminInitSubscriptionOneDocument = gql`
  mutation adminInitSubscriptionOne($object: subscriptions_insert_input!) {
    insert_subscriptions_one(object: $object) {
      appId
      createdAt
      id
      stripeCheckoutSessionId
      stripeStatus
      stripeSubscriptionId
      userId
      updatedAt
      subscriptionItems {
        id
        stripeSubscriptionItemId
        appId
        createdAt
        updatedAt
      }
    }
  }
`
export type AdminInitSubscriptionOneMutationFn = Apollo.MutationFunction<
  AdminInitSubscriptionOneMutation,
  AdminInitSubscriptionOneMutationVariables
>

/**
 * __useAdminInitSubscriptionOneMutation__
 *
 * To run a mutation, you first call `useAdminInitSubscriptionOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminInitSubscriptionOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminInitSubscriptionOneMutation, { data, loading, error }] = useAdminInitSubscriptionOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useAdminInitSubscriptionOneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminInitSubscriptionOneMutation,
    AdminInitSubscriptionOneMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminInitSubscriptionOneMutation,
    AdminInitSubscriptionOneMutationVariables
  >(AdminInitSubscriptionOneDocument, options)
}
export type AdminInitSubscriptionOneMutationHookResult = ReturnType<
  typeof useAdminInitSubscriptionOneMutation
>
export type AdminInitSubscriptionOneMutationResult =
  Apollo.MutationResult<AdminInitSubscriptionOneMutation>
export type AdminInitSubscriptionOneMutationOptions = Apollo.BaseMutationOptions<
  AdminInitSubscriptionOneMutation,
  AdminInitSubscriptionOneMutationVariables
>
export const AdminFillInTheSentAtColumnDocument = gql`
  mutation adminFillInTheSentAtColumn($sentAt: timestamp!) {
    update_writersoftUsageReports(
      where: { sentAt: { _is_null: true } }
      _set: { sentAt: $sentAt }
    ) {
      affected_rows
    }
  }
`
export type AdminFillInTheSentAtColumnMutationFn = Apollo.MutationFunction<
  AdminFillInTheSentAtColumnMutation,
  AdminFillInTheSentAtColumnMutationVariables
>

/**
 * __useAdminFillInTheSentAtColumnMutation__
 *
 * To run a mutation, you first call `useAdminFillInTheSentAtColumnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminFillInTheSentAtColumnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminFillInTheSentAtColumnMutation, { data, loading, error }] = useAdminFillInTheSentAtColumnMutation({
 *   variables: {
 *      sentAt: // value for 'sentAt'
 *   },
 * });
 */
export function useAdminFillInTheSentAtColumnMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminFillInTheSentAtColumnMutation,
    AdminFillInTheSentAtColumnMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminFillInTheSentAtColumnMutation,
    AdminFillInTheSentAtColumnMutationVariables
  >(AdminFillInTheSentAtColumnDocument, options)
}
export type AdminFillInTheSentAtColumnMutationHookResult = ReturnType<
  typeof useAdminFillInTheSentAtColumnMutation
>
export type AdminFillInTheSentAtColumnMutationResult =
  Apollo.MutationResult<AdminFillInTheSentAtColumnMutation>
export type AdminFillInTheSentAtColumnMutationOptions = Apollo.BaseMutationOptions<
  AdminFillInTheSentAtColumnMutation,
  AdminFillInTheSentAtColumnMutationVariables
>
export const UserCreateStripeCheckoutSessionsDocument = gql`
  mutation userCreateStripeCheckoutSessions {
    createStripeCheckoutSessions {
      redirectUrl
    }
  }
`
export type UserCreateStripeCheckoutSessionsMutationFn = Apollo.MutationFunction<
  UserCreateStripeCheckoutSessionsMutation,
  UserCreateStripeCheckoutSessionsMutationVariables
>

/**
 * __useUserCreateStripeCheckoutSessionsMutation__
 *
 * To run a mutation, you first call `useUserCreateStripeCheckoutSessionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCreateStripeCheckoutSessionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCreateStripeCheckoutSessionsMutation, { data, loading, error }] = useUserCreateStripeCheckoutSessionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useUserCreateStripeCheckoutSessionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserCreateStripeCheckoutSessionsMutation,
    UserCreateStripeCheckoutSessionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UserCreateStripeCheckoutSessionsMutation,
    UserCreateStripeCheckoutSessionsMutationVariables
  >(UserCreateStripeCheckoutSessionsDocument, options)
}
export type UserCreateStripeCheckoutSessionsMutationHookResult = ReturnType<
  typeof useUserCreateStripeCheckoutSessionsMutation
>
export type UserCreateStripeCheckoutSessionsMutationResult =
  Apollo.MutationResult<UserCreateStripeCheckoutSessionsMutation>
export type UserCreateStripeCheckoutSessionsMutationOptions = Apollo.BaseMutationOptions<
  UserCreateStripeCheckoutSessionsMutation,
  UserCreateStripeCheckoutSessionsMutationVariables
>
export const UserCreatePortalSessionsDocument = gql`
  mutation userCreatePortalSessions {
    createPortalSessions {
      redirectUrl
    }
  }
`
export type UserCreatePortalSessionsMutationFn = Apollo.MutationFunction<
  UserCreatePortalSessionsMutation,
  UserCreatePortalSessionsMutationVariables
>

/**
 * __useUserCreatePortalSessionsMutation__
 *
 * To run a mutation, you first call `useUserCreatePortalSessionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCreatePortalSessionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCreatePortalSessionsMutation, { data, loading, error }] = useUserCreatePortalSessionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useUserCreatePortalSessionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserCreatePortalSessionsMutation,
    UserCreatePortalSessionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UserCreatePortalSessionsMutation,
    UserCreatePortalSessionsMutationVariables
  >(UserCreatePortalSessionsDocument, options)
}
export type UserCreatePortalSessionsMutationHookResult = ReturnType<
  typeof useUserCreatePortalSessionsMutation
>
export type UserCreatePortalSessionsMutationResult =
  Apollo.MutationResult<UserCreatePortalSessionsMutation>
export type UserCreatePortalSessionsMutationOptions = Apollo.BaseMutationOptions<
  UserCreatePortalSessionsMutation,
  UserCreatePortalSessionsMutationVariables
>
export const AdminInsertPromptUsagesOneDocument = gql`
  mutation adminInsertPromptUsagesOne($object: promptUsages_insert_input!) {
    insert_promptUsages_one(object: $object) {
      appId
      createdAt
      discordUserId
      id
      language
      model
      object
      promptId
      response
      updatedAt
      usageCompletionTokens
      usagePromptTokens
      usageTotalTokens
      userPrompt
    }
  }
`
export type AdminInsertPromptUsagesOneMutationFn = Apollo.MutationFunction<
  AdminInsertPromptUsagesOneMutation,
  AdminInsertPromptUsagesOneMutationVariables
>

/**
 * __useAdminInsertPromptUsagesOneMutation__
 *
 * To run a mutation, you first call `useAdminInsertPromptUsagesOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminInsertPromptUsagesOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminInsertPromptUsagesOneMutation, { data, loading, error }] = useAdminInsertPromptUsagesOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useAdminInsertPromptUsagesOneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminInsertPromptUsagesOneMutation,
    AdminInsertPromptUsagesOneMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminInsertPromptUsagesOneMutation,
    AdminInsertPromptUsagesOneMutationVariables
  >(AdminInsertPromptUsagesOneDocument, options)
}
export type AdminInsertPromptUsagesOneMutationHookResult = ReturnType<
  typeof useAdminInsertPromptUsagesOneMutation
>
export type AdminInsertPromptUsagesOneMutationResult =
  Apollo.MutationResult<AdminInsertPromptUsagesOneMutation>
export type AdminInsertPromptUsagesOneMutationOptions = Apollo.BaseMutationOptions<
  AdminInsertPromptUsagesOneMutation,
  AdminInsertPromptUsagesOneMutationVariables
>
export const AdminTruncateDiscordMessagesDocument = gql`
  mutation adminTruncateDiscordMessages($cratedAtLte: timestamptz!) {
    delete_discordMessages(where: { createdAt: { _lte: $cratedAtLte } }) {
      affected_rows
    }
  }
`
export type AdminTruncateDiscordMessagesMutationFn = Apollo.MutationFunction<
  AdminTruncateDiscordMessagesMutation,
  AdminTruncateDiscordMessagesMutationVariables
>

/**
 * __useAdminTruncateDiscordMessagesMutation__
 *
 * To run a mutation, you first call `useAdminTruncateDiscordMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminTruncateDiscordMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminTruncateDiscordMessagesMutation, { data, loading, error }] = useAdminTruncateDiscordMessagesMutation({
 *   variables: {
 *      cratedAtLte: // value for 'cratedAtLte'
 *   },
 * });
 */
export function useAdminTruncateDiscordMessagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminTruncateDiscordMessagesMutation,
    AdminTruncateDiscordMessagesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminTruncateDiscordMessagesMutation,
    AdminTruncateDiscordMessagesMutationVariables
  >(AdminTruncateDiscordMessagesDocument, options)
}
export type AdminTruncateDiscordMessagesMutationHookResult = ReturnType<
  typeof useAdminTruncateDiscordMessagesMutation
>
export type AdminTruncateDiscordMessagesMutationResult =
  Apollo.MutationResult<AdminTruncateDiscordMessagesMutation>
export type AdminTruncateDiscordMessagesMutationOptions = Apollo.BaseMutationOptions<
  AdminTruncateDiscordMessagesMutation,
  AdminTruncateDiscordMessagesMutationVariables
>
export const AdminInsertAiCompletionRecordsOneDocument = gql`
  mutation adminInsertAICompletionRecordsOne($object: aICompletionRecords_insert_input!) {
    insert_aICompletionRecords_one(object: $object) {
      ...aICompletionRecords
    }
  }
  ${AICompletionRecordsFragmentDoc}
`
export type AdminInsertAiCompletionRecordsOneMutationFn = Apollo.MutationFunction<
  AdminInsertAiCompletionRecordsOneMutation,
  AdminInsertAiCompletionRecordsOneMutationVariables
>

/**
 * __useAdminInsertAiCompletionRecordsOneMutation__
 *
 * To run a mutation, you first call `useAdminInsertAiCompletionRecordsOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminInsertAiCompletionRecordsOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminInsertAiCompletionRecordsOneMutation, { data, loading, error }] = useAdminInsertAiCompletionRecordsOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useAdminInsertAiCompletionRecordsOneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminInsertAiCompletionRecordsOneMutation,
    AdminInsertAiCompletionRecordsOneMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AdminInsertAiCompletionRecordsOneMutation,
    AdminInsertAiCompletionRecordsOneMutationVariables
  >(AdminInsertAiCompletionRecordsOneDocument, options)
}
export type AdminInsertAiCompletionRecordsOneMutationHookResult = ReturnType<
  typeof useAdminInsertAiCompletionRecordsOneMutation
>
export type AdminInsertAiCompletionRecordsOneMutationResult =
  Apollo.MutationResult<AdminInsertAiCompletionRecordsOneMutation>
export type AdminInsertAiCompletionRecordsOneMutationOptions = Apollo.BaseMutationOptions<
  AdminInsertAiCompletionRecordsOneMutation,
  AdminInsertAiCompletionRecordsOneMutationVariables
>
export const AdminFetchNewebpayEpgNotificationsCountDocument = gql`
  query adminFetchNewebpayEpgNotificationsCount {
    newebpayEpgNotifications_aggregate {
      aggregate {
        count
      }
    }
  }
`

/**
 * __useAdminFetchNewebpayEpgNotificationsCountQuery__
 *
 * To run a query within a React component, call `useAdminFetchNewebpayEpgNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFetchNewebpayEpgNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFetchNewebpayEpgNotificationsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminFetchNewebpayEpgNotificationsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminFetchNewebpayEpgNotificationsCountQuery,
    AdminFetchNewebpayEpgNotificationsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminFetchNewebpayEpgNotificationsCountQuery,
    AdminFetchNewebpayEpgNotificationsCountQueryVariables
  >(AdminFetchNewebpayEpgNotificationsCountDocument, options)
}
export function useAdminFetchNewebpayEpgNotificationsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminFetchNewebpayEpgNotificationsCountQuery,
    AdminFetchNewebpayEpgNotificationsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminFetchNewebpayEpgNotificationsCountQuery,
    AdminFetchNewebpayEpgNotificationsCountQueryVariables
  >(AdminFetchNewebpayEpgNotificationsCountDocument, options)
}
export type AdminFetchNewebpayEpgNotificationsCountQueryHookResult = ReturnType<
  typeof useAdminFetchNewebpayEpgNotificationsCountQuery
>
export type AdminFetchNewebpayEpgNotificationsCountLazyQueryHookResult = ReturnType<
  typeof useAdminFetchNewebpayEpgNotificationsCountLazyQuery
>
export type AdminFetchNewebpayEpgNotificationsCountQueryResult = Apollo.QueryResult<
  AdminFetchNewebpayEpgNotificationsCountQuery,
  AdminFetchNewebpayEpgNotificationsCountQueryVariables
>
export function refetchAdminFetchNewebpayEpgNotificationsCountQuery(
  variables?: AdminFetchNewebpayEpgNotificationsCountQueryVariables
) {
  return { query: AdminFetchNewebpayEpgNotificationsCountDocument, variables: variables }
}
export const AdminFetchSystemUsageByUserDocument = gql`
  query adminFetchSystemUsageByUser {
    users {
      appId
      email
      name
      writersoftUsageReports_aggregate {
        aggregate {
          count
          sum {
            characters
          }
        }
      }
    }
    apps {
      id
      writersoftUsageReports_aggregate {
        aggregate {
          count
          sum {
            characters
          }
        }
      }
    }
  }
`

/**
 * __useAdminFetchSystemUsageByUserQuery__
 *
 * To run a query within a React component, call `useAdminFetchSystemUsageByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFetchSystemUsageByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFetchSystemUsageByUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminFetchSystemUsageByUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminFetchSystemUsageByUserQuery,
    AdminFetchSystemUsageByUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminFetchSystemUsageByUserQuery,
    AdminFetchSystemUsageByUserQueryVariables
  >(AdminFetchSystemUsageByUserDocument, options)
}
export function useAdminFetchSystemUsageByUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminFetchSystemUsageByUserQuery,
    AdminFetchSystemUsageByUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminFetchSystemUsageByUserQuery,
    AdminFetchSystemUsageByUserQueryVariables
  >(AdminFetchSystemUsageByUserDocument, options)
}
export type AdminFetchSystemUsageByUserQueryHookResult = ReturnType<
  typeof useAdminFetchSystemUsageByUserQuery
>
export type AdminFetchSystemUsageByUserLazyQueryHookResult = ReturnType<
  typeof useAdminFetchSystemUsageByUserLazyQuery
>
export type AdminFetchSystemUsageByUserQueryResult = Apollo.QueryResult<
  AdminFetchSystemUsageByUserQuery,
  AdminFetchSystemUsageByUserQueryVariables
>
export function refetchAdminFetchSystemUsageByUserQuery(
  variables?: AdminFetchSystemUsageByUserQueryVariables
) {
  return { query: AdminFetchSystemUsageByUserDocument, variables: variables }
}
export const AdminFetchFormDataByContentfulFormIdDocument = gql`
  query adminFetchFormDataByContentfulFormId($condition: forms_bool_exp!) {
    forms(where: $condition, order_by: [{ updatedAt: desc }]) {
      id
      createdAt
      updatedAt
      formAnswers {
        id
        type
        value
        contentfulFormComponentId
        createdAt
        updatedAt
      }
    }
  }
`

/**
 * __useAdminFetchFormDataByContentfulFormIdQuery__
 *
 * To run a query within a React component, call `useAdminFetchFormDataByContentfulFormIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFetchFormDataByContentfulFormIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFetchFormDataByContentfulFormIdQuery({
 *   variables: {
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useAdminFetchFormDataByContentfulFormIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminFetchFormDataByContentfulFormIdQuery,
    AdminFetchFormDataByContentfulFormIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminFetchFormDataByContentfulFormIdQuery,
    AdminFetchFormDataByContentfulFormIdQueryVariables
  >(AdminFetchFormDataByContentfulFormIdDocument, options)
}
export function useAdminFetchFormDataByContentfulFormIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminFetchFormDataByContentfulFormIdQuery,
    AdminFetchFormDataByContentfulFormIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminFetchFormDataByContentfulFormIdQuery,
    AdminFetchFormDataByContentfulFormIdQueryVariables
  >(AdminFetchFormDataByContentfulFormIdDocument, options)
}
export type AdminFetchFormDataByContentfulFormIdQueryHookResult = ReturnType<
  typeof useAdminFetchFormDataByContentfulFormIdQuery
>
export type AdminFetchFormDataByContentfulFormIdLazyQueryHookResult = ReturnType<
  typeof useAdminFetchFormDataByContentfulFormIdLazyQuery
>
export type AdminFetchFormDataByContentfulFormIdQueryResult = Apollo.QueryResult<
  AdminFetchFormDataByContentfulFormIdQuery,
  AdminFetchFormDataByContentfulFormIdQueryVariables
>
export function refetchAdminFetchFormDataByContentfulFormIdQuery(
  variables: AdminFetchFormDataByContentfulFormIdQueryVariables
) {
  return { query: AdminFetchFormDataByContentfulFormIdDocument, variables: variables }
}
export const AdminFetchFormByPkDocument = gql`
  query adminFetchFormByPk($id: uuid!) {
    forms_by_pk(id: $id) {
      id
      createdAt
      updatedAt
      contentfulFormId
      formAnswers {
        id
        type
        value
        contentfulFormComponentId
        createdAt
        updatedAt
      }
    }
  }
`

/**
 * __useAdminFetchFormByPkQuery__
 *
 * To run a query within a React component, call `useAdminFetchFormByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFetchFormByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFetchFormByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminFetchFormByPkQuery(
  baseOptions: Apollo.QueryHookOptions<AdminFetchFormByPkQuery, AdminFetchFormByPkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdminFetchFormByPkQuery, AdminFetchFormByPkQueryVariables>(
    AdminFetchFormByPkDocument,
    options
  )
}
export function useAdminFetchFormByPkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminFetchFormByPkQuery,
    AdminFetchFormByPkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AdminFetchFormByPkQuery, AdminFetchFormByPkQueryVariables>(
    AdminFetchFormByPkDocument,
    options
  )
}
export type AdminFetchFormByPkQueryHookResult = ReturnType<typeof useAdminFetchFormByPkQuery>
export type AdminFetchFormByPkLazyQueryHookResult = ReturnType<
  typeof useAdminFetchFormByPkLazyQuery
>
export type AdminFetchFormByPkQueryResult = Apollo.QueryResult<
  AdminFetchFormByPkQuery,
  AdminFetchFormByPkQueryVariables
>
export function refetchAdminFetchFormByPkQuery(variables: AdminFetchFormByPkQueryVariables) {
  return { query: AdminFetchFormByPkDocument, variables: variables }
}
export const AdminFetchSectionFormComponentsByFormIdDocument = gql`
  query adminFetchSectionFormComponentsByFormId($id: String!, $locale: String) {
    sectionsForm(id: $id, locale: $locale) {
      componentsCollection {
        items {
          sys {
            id
          }
          type
          label
        }
      }
    }
  }
`

/**
 * __useAdminFetchSectionFormComponentsByFormIdQuery__
 *
 * To run a query within a React component, call `useAdminFetchSectionFormComponentsByFormIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFetchSectionFormComponentsByFormIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFetchSectionFormComponentsByFormIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useAdminFetchSectionFormComponentsByFormIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminFetchSectionFormComponentsByFormIdQuery,
    AdminFetchSectionFormComponentsByFormIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminFetchSectionFormComponentsByFormIdQuery,
    AdminFetchSectionFormComponentsByFormIdQueryVariables
  >(AdminFetchSectionFormComponentsByFormIdDocument, options)
}
export function useAdminFetchSectionFormComponentsByFormIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminFetchSectionFormComponentsByFormIdQuery,
    AdminFetchSectionFormComponentsByFormIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminFetchSectionFormComponentsByFormIdQuery,
    AdminFetchSectionFormComponentsByFormIdQueryVariables
  >(AdminFetchSectionFormComponentsByFormIdDocument, options)
}
export type AdminFetchSectionFormComponentsByFormIdQueryHookResult = ReturnType<
  typeof useAdminFetchSectionFormComponentsByFormIdQuery
>
export type AdminFetchSectionFormComponentsByFormIdLazyQueryHookResult = ReturnType<
  typeof useAdminFetchSectionFormComponentsByFormIdLazyQuery
>
export type AdminFetchSectionFormComponentsByFormIdQueryResult = Apollo.QueryResult<
  AdminFetchSectionFormComponentsByFormIdQuery,
  AdminFetchSectionFormComponentsByFormIdQueryVariables
>
export function refetchAdminFetchSectionFormComponentsByFormIdQuery(
  variables: AdminFetchSectionFormComponentsByFormIdQueryVariables
) {
  return { query: AdminFetchSectionFormComponentsByFormIdDocument, variables: variables }
}
export const AdminSearchErc721TokenMetadataAttributeTraitTypeCollectionDocument = gql`
  query adminSearchErc721TokenMetadataAttributeTraitTypeCollection($title: String) {
    erc721TokenMetadataAttributeTraitTypeCollection(where: { title: $title }) {
      total
      items {
        sys {
          id
        }
      }
    }
  }
`

/**
 * __useAdminSearchErc721TokenMetadataAttributeTraitTypeCollectionQuery__
 *
 * To run a query within a React component, call `useAdminSearchErc721TokenMetadataAttributeTraitTypeCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSearchErc721TokenMetadataAttributeTraitTypeCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSearchErc721TokenMetadataAttributeTraitTypeCollectionQuery({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useAdminSearchErc721TokenMetadataAttributeTraitTypeCollectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminSearchErc721TokenMetadataAttributeTraitTypeCollectionQuery,
    AdminSearchErc721TokenMetadataAttributeTraitTypeCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminSearchErc721TokenMetadataAttributeTraitTypeCollectionQuery,
    AdminSearchErc721TokenMetadataAttributeTraitTypeCollectionQueryVariables
  >(AdminSearchErc721TokenMetadataAttributeTraitTypeCollectionDocument, options)
}
export function useAdminSearchErc721TokenMetadataAttributeTraitTypeCollectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminSearchErc721TokenMetadataAttributeTraitTypeCollectionQuery,
    AdminSearchErc721TokenMetadataAttributeTraitTypeCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminSearchErc721TokenMetadataAttributeTraitTypeCollectionQuery,
    AdminSearchErc721TokenMetadataAttributeTraitTypeCollectionQueryVariables
  >(AdminSearchErc721TokenMetadataAttributeTraitTypeCollectionDocument, options)
}
export type AdminSearchErc721TokenMetadataAttributeTraitTypeCollectionQueryHookResult = ReturnType<
  typeof useAdminSearchErc721TokenMetadataAttributeTraitTypeCollectionQuery
>
export type AdminSearchErc721TokenMetadataAttributeTraitTypeCollectionLazyQueryHookResult =
  ReturnType<typeof useAdminSearchErc721TokenMetadataAttributeTraitTypeCollectionLazyQuery>
export type AdminSearchErc721TokenMetadataAttributeTraitTypeCollectionQueryResult =
  Apollo.QueryResult<
    AdminSearchErc721TokenMetadataAttributeTraitTypeCollectionQuery,
    AdminSearchErc721TokenMetadataAttributeTraitTypeCollectionQueryVariables
  >
export function refetchAdminSearchErc721TokenMetadataAttributeTraitTypeCollectionQuery(
  variables?: AdminSearchErc721TokenMetadataAttributeTraitTypeCollectionQueryVariables
) {
  return {
    query: AdminSearchErc721TokenMetadataAttributeTraitTypeCollectionDocument,
    variables: variables,
  }
}
export const AdminFetchSmartContractErc721CollectionDocument = gql`
  query adminFetchSmartContractErc721Collection {
    smartContractErc721Collection {
      total
      items {
        sys {
          id
        }
        smartContractAddress
      }
    }
  }
`

/**
 * __useAdminFetchSmartContractErc721CollectionQuery__
 *
 * To run a query within a React component, call `useAdminFetchSmartContractErc721CollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFetchSmartContractErc721CollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFetchSmartContractErc721CollectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminFetchSmartContractErc721CollectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminFetchSmartContractErc721CollectionQuery,
    AdminFetchSmartContractErc721CollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminFetchSmartContractErc721CollectionQuery,
    AdminFetchSmartContractErc721CollectionQueryVariables
  >(AdminFetchSmartContractErc721CollectionDocument, options)
}
export function useAdminFetchSmartContractErc721CollectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminFetchSmartContractErc721CollectionQuery,
    AdminFetchSmartContractErc721CollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminFetchSmartContractErc721CollectionQuery,
    AdminFetchSmartContractErc721CollectionQueryVariables
  >(AdminFetchSmartContractErc721CollectionDocument, options)
}
export type AdminFetchSmartContractErc721CollectionQueryHookResult = ReturnType<
  typeof useAdminFetchSmartContractErc721CollectionQuery
>
export type AdminFetchSmartContractErc721CollectionLazyQueryHookResult = ReturnType<
  typeof useAdminFetchSmartContractErc721CollectionLazyQuery
>
export type AdminFetchSmartContractErc721CollectionQueryResult = Apollo.QueryResult<
  AdminFetchSmartContractErc721CollectionQuery,
  AdminFetchSmartContractErc721CollectionQueryVariables
>
export function refetchAdminFetchSmartContractErc721CollectionQuery(
  variables?: AdminFetchSmartContractErc721CollectionQueryVariables
) {
  return { query: AdminFetchSmartContractErc721CollectionDocument, variables: variables }
}
export const UserFetchFormDataByContentfulFormIdDocument = gql`
  query userFetchFormDataByContentfulFormId($contentfulFormId: String!) {
    forms(
      where: { contentfulFormId: { _eq: $contentfulFormId } }
      order_by: [{ updatedAt: desc }]
    ) {
      id
      contentfulFormId
      createdAt
      updatedAt
      formAnswers {
        id
        type
        value
        contentfulFormComponentId
        createdAt
        updatedAt
      }
    }
  }
`

/**
 * __useUserFetchFormDataByContentfulFormIdQuery__
 *
 * To run a query within a React component, call `useUserFetchFormDataByContentfulFormIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFetchFormDataByContentfulFormIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFetchFormDataByContentfulFormIdQuery({
 *   variables: {
 *      contentfulFormId: // value for 'contentfulFormId'
 *   },
 * });
 */
export function useUserFetchFormDataByContentfulFormIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserFetchFormDataByContentfulFormIdQuery,
    UserFetchFormDataByContentfulFormIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UserFetchFormDataByContentfulFormIdQuery,
    UserFetchFormDataByContentfulFormIdQueryVariables
  >(UserFetchFormDataByContentfulFormIdDocument, options)
}
export function useUserFetchFormDataByContentfulFormIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserFetchFormDataByContentfulFormIdQuery,
    UserFetchFormDataByContentfulFormIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UserFetchFormDataByContentfulFormIdQuery,
    UserFetchFormDataByContentfulFormIdQueryVariables
  >(UserFetchFormDataByContentfulFormIdDocument, options)
}
export type UserFetchFormDataByContentfulFormIdQueryHookResult = ReturnType<
  typeof useUserFetchFormDataByContentfulFormIdQuery
>
export type UserFetchFormDataByContentfulFormIdLazyQueryHookResult = ReturnType<
  typeof useUserFetchFormDataByContentfulFormIdLazyQuery
>
export type UserFetchFormDataByContentfulFormIdQueryResult = Apollo.QueryResult<
  UserFetchFormDataByContentfulFormIdQuery,
  UserFetchFormDataByContentfulFormIdQueryVariables
>
export function refetchUserFetchFormDataByContentfulFormIdQuery(
  variables: UserFetchFormDataByContentfulFormIdQueryVariables
) {
  return { query: UserFetchFormDataByContentfulFormIdDocument, variables: variables }
}
export const AdminFetchActiveSubscriptionCountDocument = gql`
  query adminFetchActiveSubscriptionCount($userId: uuid!, $appId: String!) {
    subscriptions_aggregate(
      where: { userId: { _eq: $userId }, stripeStatus: { _eq: "active" }, appId: { _eq: $appId } }
    ) {
      aggregate {
        count
      }
      nodes {
        id
        stripeStatus
        stripeCheckoutSessionId
        stripeSubscriptionId
        appId
      }
    }
  }
`

/**
 * __useAdminFetchActiveSubscriptionCountQuery__
 *
 * To run a query within a React component, call `useAdminFetchActiveSubscriptionCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFetchActiveSubscriptionCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFetchActiveSubscriptionCountQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      appId: // value for 'appId'
 *   },
 * });
 */
export function useAdminFetchActiveSubscriptionCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminFetchActiveSubscriptionCountQuery,
    AdminFetchActiveSubscriptionCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminFetchActiveSubscriptionCountQuery,
    AdminFetchActiveSubscriptionCountQueryVariables
  >(AdminFetchActiveSubscriptionCountDocument, options)
}
export function useAdminFetchActiveSubscriptionCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminFetchActiveSubscriptionCountQuery,
    AdminFetchActiveSubscriptionCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminFetchActiveSubscriptionCountQuery,
    AdminFetchActiveSubscriptionCountQueryVariables
  >(AdminFetchActiveSubscriptionCountDocument, options)
}
export type AdminFetchActiveSubscriptionCountQueryHookResult = ReturnType<
  typeof useAdminFetchActiveSubscriptionCountQuery
>
export type AdminFetchActiveSubscriptionCountLazyQueryHookResult = ReturnType<
  typeof useAdminFetchActiveSubscriptionCountLazyQuery
>
export type AdminFetchActiveSubscriptionCountQueryResult = Apollo.QueryResult<
  AdminFetchActiveSubscriptionCountQuery,
  AdminFetchActiveSubscriptionCountQueryVariables
>
export function refetchAdminFetchActiveSubscriptionCountQuery(
  variables: AdminFetchActiveSubscriptionCountQueryVariables
) {
  return { query: AdminFetchActiveSubscriptionCountDocument, variables: variables }
}
export const UserFetchActiveSubscriptionByAuth0IdDocument = gql`
  query userFetchActiveSubscriptionByAuth0Id($auth0Id: String!) {
    subscriptions(
      where: { stripeStatus: { _eq: "active" }, user: { auth0userId: { _eq: $auth0Id } } }
    ) {
      id
      stripeStatus
      createdAt
      updatedAt
    }
  }
`

/**
 * __useUserFetchActiveSubscriptionByAuth0IdQuery__
 *
 * To run a query within a React component, call `useUserFetchActiveSubscriptionByAuth0IdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFetchActiveSubscriptionByAuth0IdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFetchActiveSubscriptionByAuth0IdQuery({
 *   variables: {
 *      auth0Id: // value for 'auth0Id'
 *   },
 * });
 */
export function useUserFetchActiveSubscriptionByAuth0IdQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserFetchActiveSubscriptionByAuth0IdQuery,
    UserFetchActiveSubscriptionByAuth0IdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UserFetchActiveSubscriptionByAuth0IdQuery,
    UserFetchActiveSubscriptionByAuth0IdQueryVariables
  >(UserFetchActiveSubscriptionByAuth0IdDocument, options)
}
export function useUserFetchActiveSubscriptionByAuth0IdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserFetchActiveSubscriptionByAuth0IdQuery,
    UserFetchActiveSubscriptionByAuth0IdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UserFetchActiveSubscriptionByAuth0IdQuery,
    UserFetchActiveSubscriptionByAuth0IdQueryVariables
  >(UserFetchActiveSubscriptionByAuth0IdDocument, options)
}
export type UserFetchActiveSubscriptionByAuth0IdQueryHookResult = ReturnType<
  typeof useUserFetchActiveSubscriptionByAuth0IdQuery
>
export type UserFetchActiveSubscriptionByAuth0IdLazyQueryHookResult = ReturnType<
  typeof useUserFetchActiveSubscriptionByAuth0IdLazyQuery
>
export type UserFetchActiveSubscriptionByAuth0IdQueryResult = Apollo.QueryResult<
  UserFetchActiveSubscriptionByAuth0IdQuery,
  UserFetchActiveSubscriptionByAuth0IdQueryVariables
>
export function refetchUserFetchActiveSubscriptionByAuth0IdQuery(
  variables: UserFetchActiveSubscriptionByAuth0IdQueryVariables
) {
  return { query: UserFetchActiveSubscriptionByAuth0IdDocument, variables: variables }
}
export const AdminFetchSubscriptionByStripeCheckoutSessionIdDocument = gql`
  query adminFetchSubscriptionByStripeCheckoutSessionId($stripeCheckoutSessionId: String!) {
    subscriptions(where: { stripeCheckoutSessionId: { _eq: $stripeCheckoutSessionId } }) {
      appId
      createdAt
      id
      stripeCheckoutSessionId
      stripeStatus
      stripeSubscriptionId
      updatedAt
      userId
    }
  }
`

/**
 * __useAdminFetchSubscriptionByStripeCheckoutSessionIdQuery__
 *
 * To run a query within a React component, call `useAdminFetchSubscriptionByStripeCheckoutSessionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFetchSubscriptionByStripeCheckoutSessionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFetchSubscriptionByStripeCheckoutSessionIdQuery({
 *   variables: {
 *      stripeCheckoutSessionId: // value for 'stripeCheckoutSessionId'
 *   },
 * });
 */
export function useAdminFetchSubscriptionByStripeCheckoutSessionIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminFetchSubscriptionByStripeCheckoutSessionIdQuery,
    AdminFetchSubscriptionByStripeCheckoutSessionIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminFetchSubscriptionByStripeCheckoutSessionIdQuery,
    AdminFetchSubscriptionByStripeCheckoutSessionIdQueryVariables
  >(AdminFetchSubscriptionByStripeCheckoutSessionIdDocument, options)
}
export function useAdminFetchSubscriptionByStripeCheckoutSessionIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminFetchSubscriptionByStripeCheckoutSessionIdQuery,
    AdminFetchSubscriptionByStripeCheckoutSessionIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminFetchSubscriptionByStripeCheckoutSessionIdQuery,
    AdminFetchSubscriptionByStripeCheckoutSessionIdQueryVariables
  >(AdminFetchSubscriptionByStripeCheckoutSessionIdDocument, options)
}
export type AdminFetchSubscriptionByStripeCheckoutSessionIdQueryHookResult = ReturnType<
  typeof useAdminFetchSubscriptionByStripeCheckoutSessionIdQuery
>
export type AdminFetchSubscriptionByStripeCheckoutSessionIdLazyQueryHookResult = ReturnType<
  typeof useAdminFetchSubscriptionByStripeCheckoutSessionIdLazyQuery
>
export type AdminFetchSubscriptionByStripeCheckoutSessionIdQueryResult = Apollo.QueryResult<
  AdminFetchSubscriptionByStripeCheckoutSessionIdQuery,
  AdminFetchSubscriptionByStripeCheckoutSessionIdQueryVariables
>
export function refetchAdminFetchSubscriptionByStripeCheckoutSessionIdQuery(
  variables: AdminFetchSubscriptionByStripeCheckoutSessionIdQueryVariables
) {
  return { query: AdminFetchSubscriptionByStripeCheckoutSessionIdDocument, variables: variables }
}
export const AdminFetchUnsentUsageReportDocument = gql`
  query adminFetchUnsentUsageReport {
    writersoftUsageReports(
      where: {
        sentAt: { _is_null: true }
        stripeSubscriptionItemId: { _is_null: false }
        userId: { _is_null: false }
      }
      limit: 50
    ) {
      id
      createdAt
      characters
      appId
      sentAt
      userId
      stripeSubscriptionItemId
    }
  }
`

/**
 * __useAdminFetchUnsentUsageReportQuery__
 *
 * To run a query within a React component, call `useAdminFetchUnsentUsageReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFetchUnsentUsageReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFetchUnsentUsageReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminFetchUnsentUsageReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminFetchUnsentUsageReportQuery,
    AdminFetchUnsentUsageReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminFetchUnsentUsageReportQuery,
    AdminFetchUnsentUsageReportQueryVariables
  >(AdminFetchUnsentUsageReportDocument, options)
}
export function useAdminFetchUnsentUsageReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminFetchUnsentUsageReportQuery,
    AdminFetchUnsentUsageReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminFetchUnsentUsageReportQuery,
    AdminFetchUnsentUsageReportQueryVariables
  >(AdminFetchUnsentUsageReportDocument, options)
}
export type AdminFetchUnsentUsageReportQueryHookResult = ReturnType<
  typeof useAdminFetchUnsentUsageReportQuery
>
export type AdminFetchUnsentUsageReportLazyQueryHookResult = ReturnType<
  typeof useAdminFetchUnsentUsageReportLazyQuery
>
export type AdminFetchUnsentUsageReportQueryResult = Apollo.QueryResult<
  AdminFetchUnsentUsageReportQuery,
  AdminFetchUnsentUsageReportQueryVariables
>
export function refetchAdminFetchUnsentUsageReportQuery(
  variables?: AdminFetchUnsentUsageReportQueryVariables
) {
  return { query: AdminFetchUnsentUsageReportDocument, variables: variables }
}
export const AdminSearchErc721TokenMetadataCollectionDocument = gql`
  query adminSearchErc721TokenMetadataCollection($limit: Int, $skip: Int) {
    erc721TokenMetadataCollection(limit: $limit, skip: $skip) {
      total
      items {
        tokenId
        sys {
          id
        }
      }
    }
  }
`

/**
 * __useAdminSearchErc721TokenMetadataCollectionQuery__
 *
 * To run a query within a React component, call `useAdminSearchErc721TokenMetadataCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSearchErc721TokenMetadataCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSearchErc721TokenMetadataCollectionQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAdminSearchErc721TokenMetadataCollectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminSearchErc721TokenMetadataCollectionQuery,
    AdminSearchErc721TokenMetadataCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminSearchErc721TokenMetadataCollectionQuery,
    AdminSearchErc721TokenMetadataCollectionQueryVariables
  >(AdminSearchErc721TokenMetadataCollectionDocument, options)
}
export function useAdminSearchErc721TokenMetadataCollectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminSearchErc721TokenMetadataCollectionQuery,
    AdminSearchErc721TokenMetadataCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminSearchErc721TokenMetadataCollectionQuery,
    AdminSearchErc721TokenMetadataCollectionQueryVariables
  >(AdminSearchErc721TokenMetadataCollectionDocument, options)
}
export type AdminSearchErc721TokenMetadataCollectionQueryHookResult = ReturnType<
  typeof useAdminSearchErc721TokenMetadataCollectionQuery
>
export type AdminSearchErc721TokenMetadataCollectionLazyQueryHookResult = ReturnType<
  typeof useAdminSearchErc721TokenMetadataCollectionLazyQuery
>
export type AdminSearchErc721TokenMetadataCollectionQueryResult = Apollo.QueryResult<
  AdminSearchErc721TokenMetadataCollectionQuery,
  AdminSearchErc721TokenMetadataCollectionQueryVariables
>
export function refetchAdminSearchErc721TokenMetadataCollectionQuery(
  variables?: AdminSearchErc721TokenMetadataCollectionQueryVariables
) {
  return { query: AdminSearchErc721TokenMetadataCollectionDocument, variables: variables }
}
export const AdminFetchTruncatedDiscordMessaggeDocument = gql`
  query adminFetchTruncatedDiscordMessagge($offset: Int!) {
    discordMessages(limit: 1, offset: $offset, order_by: { createdAt: desc }) {
      id
      createdAt
    }
  }
`

/**
 * __useAdminFetchTruncatedDiscordMessaggeQuery__
 *
 * To run a query within a React component, call `useAdminFetchTruncatedDiscordMessaggeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFetchTruncatedDiscordMessaggeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFetchTruncatedDiscordMessaggeQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAdminFetchTruncatedDiscordMessaggeQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminFetchTruncatedDiscordMessaggeQuery,
    AdminFetchTruncatedDiscordMessaggeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminFetchTruncatedDiscordMessaggeQuery,
    AdminFetchTruncatedDiscordMessaggeQueryVariables
  >(AdminFetchTruncatedDiscordMessaggeDocument, options)
}
export function useAdminFetchTruncatedDiscordMessaggeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminFetchTruncatedDiscordMessaggeQuery,
    AdminFetchTruncatedDiscordMessaggeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminFetchTruncatedDiscordMessaggeQuery,
    AdminFetchTruncatedDiscordMessaggeQueryVariables
  >(AdminFetchTruncatedDiscordMessaggeDocument, options)
}
export type AdminFetchTruncatedDiscordMessaggeQueryHookResult = ReturnType<
  typeof useAdminFetchTruncatedDiscordMessaggeQuery
>
export type AdminFetchTruncatedDiscordMessaggeLazyQueryHookResult = ReturnType<
  typeof useAdminFetchTruncatedDiscordMessaggeLazyQuery
>
export type AdminFetchTruncatedDiscordMessaggeQueryResult = Apollo.QueryResult<
  AdminFetchTruncatedDiscordMessaggeQuery,
  AdminFetchTruncatedDiscordMessaggeQueryVariables
>
export function refetchAdminFetchTruncatedDiscordMessaggeQuery(
  variables: AdminFetchTruncatedDiscordMessaggeQueryVariables
) {
  return { query: AdminFetchTruncatedDiscordMessaggeDocument, variables: variables }
}
export const AdminFetchAiCompletionRecordsByPkDocument = gql`
  query adminFetchAICompletionRecordsByPk($id: uuid!) {
    aICompletionRecords_by_pk(id: $id) {
      ...aICompletionRecords
    }
  }
  ${AICompletionRecordsFragmentDoc}
`

/**
 * __useAdminFetchAiCompletionRecordsByPkQuery__
 *
 * To run a query within a React component, call `useAdminFetchAiCompletionRecordsByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFetchAiCompletionRecordsByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFetchAiCompletionRecordsByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminFetchAiCompletionRecordsByPkQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminFetchAiCompletionRecordsByPkQuery,
    AdminFetchAiCompletionRecordsByPkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminFetchAiCompletionRecordsByPkQuery,
    AdminFetchAiCompletionRecordsByPkQueryVariables
  >(AdminFetchAiCompletionRecordsByPkDocument, options)
}
export function useAdminFetchAiCompletionRecordsByPkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminFetchAiCompletionRecordsByPkQuery,
    AdminFetchAiCompletionRecordsByPkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminFetchAiCompletionRecordsByPkQuery,
    AdminFetchAiCompletionRecordsByPkQueryVariables
  >(AdminFetchAiCompletionRecordsByPkDocument, options)
}
export type AdminFetchAiCompletionRecordsByPkQueryHookResult = ReturnType<
  typeof useAdminFetchAiCompletionRecordsByPkQuery
>
export type AdminFetchAiCompletionRecordsByPkLazyQueryHookResult = ReturnType<
  typeof useAdminFetchAiCompletionRecordsByPkLazyQuery
>
export type AdminFetchAiCompletionRecordsByPkQueryResult = Apollo.QueryResult<
  AdminFetchAiCompletionRecordsByPkQuery,
  AdminFetchAiCompletionRecordsByPkQueryVariables
>
export function refetchAdminFetchAiCompletionRecordsByPkQuery(
  variables: AdminFetchAiCompletionRecordsByPkQueryVariables
) {
  return { query: AdminFetchAiCompletionRecordsByPkDocument, variables: variables }
}
export const UserInsertFormDocument = gql`
  mutation userInsertForm(
    $contentfulFormId: String!
    $formAnswers: formAnswers_arr_rel_insert_input!
  ) {
    insert_forms(objects: { contentfulFormId: $contentfulFormId, formAnswers: $formAnswers }) {
      affected_rows
    }
  }
`
export type UserInsertFormMutationFn = Apollo.MutationFunction<
  UserInsertFormMutation,
  UserInsertFormMutationVariables
>

/**
 * __useUserInsertFormMutation__
 *
 * To run a mutation, you first call `useUserInsertFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserInsertFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userInsertFormMutation, { data, loading, error }] = useUserInsertFormMutation({
 *   variables: {
 *      contentfulFormId: // value for 'contentfulFormId'
 *      formAnswers: // value for 'formAnswers'
 *   },
 * });
 */
export function useUserInsertFormMutation(
  baseOptions?: Apollo.MutationHookOptions<UserInsertFormMutation, UserInsertFormMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UserInsertFormMutation, UserInsertFormMutationVariables>(
    UserInsertFormDocument,
    options
  )
}
export type UserInsertFormMutationHookResult = ReturnType<typeof useUserInsertFormMutation>
export type UserInsertFormMutationResult = Apollo.MutationResult<UserInsertFormMutation>
export type UserInsertFormMutationOptions = Apollo.BaseMutationOptions<
  UserInsertFormMutation,
  UserInsertFormMutationVariables
>
export const FetchBasicSetupConfigDocument = gql`
  query fetchBasicSetupConfig {
    supportingUseCases {
      ...supportingUseCaseFragment
    }
    supportingTones {
      ...supportingToneFragment
    }
    supportingLanguages {
      ...supportingLanguageFragment
    }
  }
  ${SupportingUseCaseFragmentFragmentDoc}
  ${SupportingToneFragmentFragmentDoc}
  ${SupportingLanguageFragmentFragmentDoc}
`

/**
 * __useFetchBasicSetupConfigQuery__
 *
 * To run a query within a React component, call `useFetchBasicSetupConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchBasicSetupConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchBasicSetupConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchBasicSetupConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchBasicSetupConfigQuery,
    FetchBasicSetupConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchBasicSetupConfigQuery, FetchBasicSetupConfigQueryVariables>(
    FetchBasicSetupConfigDocument,
    options
  )
}
export function useFetchBasicSetupConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchBasicSetupConfigQuery,
    FetchBasicSetupConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchBasicSetupConfigQuery, FetchBasicSetupConfigQueryVariables>(
    FetchBasicSetupConfigDocument,
    options
  )
}
export type FetchBasicSetupConfigQueryHookResult = ReturnType<typeof useFetchBasicSetupConfigQuery>
export type FetchBasicSetupConfigLazyQueryHookResult = ReturnType<
  typeof useFetchBasicSetupConfigLazyQuery
>
export type FetchBasicSetupConfigQueryResult = Apollo.QueryResult<
  FetchBasicSetupConfigQuery,
  FetchBasicSetupConfigQueryVariables
>
export function refetchFetchBasicSetupConfigQuery(variables?: FetchBasicSetupConfigQueryVariables) {
  return { query: FetchBasicSetupConfigDocument, variables: variables }
}
export const FetchWriterGenericsDocument = gql`
  query fetchWriterGenerics($inputs: [GenericInput!]!) {
    generics(inputs: $inputs) {
      id
      tone {
        ...supportingToneFragment
      }
      language {
        ...supportingLanguageFragment
      }
      useCase {
        ...supportingUseCaseFragment
      }
      text
      textVariations {
        text
      }
    }
  }
  ${SupportingToneFragmentFragmentDoc}
  ${SupportingLanguageFragmentFragmentDoc}
  ${SupportingUseCaseFragmentFragmentDoc}
`

/**
 * __useFetchWriterGenericsQuery__
 *
 * To run a query within a React component, call `useFetchWriterGenericsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchWriterGenericsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchWriterGenericsQuery({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useFetchWriterGenericsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchWriterGenericsQuery, FetchWriterGenericsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchWriterGenericsQuery, FetchWriterGenericsQueryVariables>(
    FetchWriterGenericsDocument,
    options
  )
}
export function useFetchWriterGenericsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchWriterGenericsQuery,
    FetchWriterGenericsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchWriterGenericsQuery, FetchWriterGenericsQueryVariables>(
    FetchWriterGenericsDocument,
    options
  )
}
export type FetchWriterGenericsQueryHookResult = ReturnType<typeof useFetchWriterGenericsQuery>
export type FetchWriterGenericsLazyQueryHookResult = ReturnType<
  typeof useFetchWriterGenericsLazyQuery
>
export type FetchWriterGenericsQueryResult = Apollo.QueryResult<
  FetchWriterGenericsQuery,
  FetchWriterGenericsQueryVariables
>
export function refetchFetchWriterGenericsQuery(variables: FetchWriterGenericsQueryVariables) {
  return { query: FetchWriterGenericsDocument, variables: variables }
}
export const FetchAllPostsWithTitleDocument = gql`
  query fetchAllPostsWithTitle(
    $allowedLocales: [String]
    $locale: String
    $limit: Int
    $skip: Int
    $order: [BlogsOrder]
    $titleSearch: String
  ) {
    blogsCollection(
      limit: $limit
      skip: $skip
      order: $order
      locale: $locale
      where: { title_contains: $titleSearch }
    ) {
      items {
        ...blogFragment
        linkedFrom(allowedLocales: $allowedLocales) {
          ...blogLinkingFragment
        }
      }
    }
  }
  ${BlogFragmentFragmentDoc}
  ${BlogLinkingFragmentFragmentDoc}
`

/**
 * __useFetchAllPostsWithTitleQuery__
 *
 * To run a query within a React component, call `useFetchAllPostsWithTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllPostsWithTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllPostsWithTitleQuery({
 *   variables: {
 *      allowedLocales: // value for 'allowedLocales'
 *      locale: // value for 'locale'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *      titleSearch: // value for 'titleSearch'
 *   },
 * });
 */
export function useFetchAllPostsWithTitleQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchAllPostsWithTitleQuery,
    FetchAllPostsWithTitleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchAllPostsWithTitleQuery, FetchAllPostsWithTitleQueryVariables>(
    FetchAllPostsWithTitleDocument,
    options
  )
}
export function useFetchAllPostsWithTitleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAllPostsWithTitleQuery,
    FetchAllPostsWithTitleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchAllPostsWithTitleQuery, FetchAllPostsWithTitleQueryVariables>(
    FetchAllPostsWithTitleDocument,
    options
  )
}
export type FetchAllPostsWithTitleQueryHookResult = ReturnType<
  typeof useFetchAllPostsWithTitleQuery
>
export type FetchAllPostsWithTitleLazyQueryHookResult = ReturnType<
  typeof useFetchAllPostsWithTitleLazyQuery
>
export type FetchAllPostsWithTitleQueryResult = Apollo.QueryResult<
  FetchAllPostsWithTitleQuery,
  FetchAllPostsWithTitleQueryVariables
>
export function refetchFetchAllPostsWithTitleQuery(
  variables?: FetchAllPostsWithTitleQueryVariables
) {
  return { query: FetchAllPostsWithTitleDocument, variables: variables }
}
export const FetchAllTagsWithNameDocument = gql`
  query fetchAllTagsWithName(
    $locale: String
    $limit: Int
    $skip: Int
    $order: [TagsOrder]
    $nameSearch: String
  ) {
    tagsCollection(
      limit: $limit
      skip: $skip
      order: $order
      locale: $locale
      where: { name_contains: $nameSearch }
    ) {
      items {
        ...tagFragment
        content(locale: $locale) {
          json
        }
      }
    }
  }
  ${TagFragmentFragmentDoc}
`

/**
 * __useFetchAllTagsWithNameQuery__
 *
 * To run a query within a React component, call `useFetchAllTagsWithNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllTagsWithNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllTagsWithNameQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *      nameSearch: // value for 'nameSearch'
 *   },
 * });
 */
export function useFetchAllTagsWithNameQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchAllTagsWithNameQuery,
    FetchAllTagsWithNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchAllTagsWithNameQuery, FetchAllTagsWithNameQueryVariables>(
    FetchAllTagsWithNameDocument,
    options
  )
}
export function useFetchAllTagsWithNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAllTagsWithNameQuery,
    FetchAllTagsWithNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchAllTagsWithNameQuery, FetchAllTagsWithNameQueryVariables>(
    FetchAllTagsWithNameDocument,
    options
  )
}
export type FetchAllTagsWithNameQueryHookResult = ReturnType<typeof useFetchAllTagsWithNameQuery>
export type FetchAllTagsWithNameLazyQueryHookResult = ReturnType<
  typeof useFetchAllTagsWithNameLazyQuery
>
export type FetchAllTagsWithNameQueryResult = Apollo.QueryResult<
  FetchAllTagsWithNameQuery,
  FetchAllTagsWithNameQueryVariables
>
export function refetchFetchAllTagsWithNameQuery(variables?: FetchAllTagsWithNameQueryVariables) {
  return { query: FetchAllTagsWithNameDocument, variables: variables }
}
export const FetchAllPostsTotalDocument = gql`
  query fetchAllPostsTotal($filters: BlogsFilter, $locale: String) {
    blogsCollection(where: $filters, order: sys_publishedAt_DESC, locale: $locale) {
      total
    }
  }
`

/**
 * __useFetchAllPostsTotalQuery__
 *
 * To run a query within a React component, call `useFetchAllPostsTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllPostsTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllPostsTotalQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchAllPostsTotalQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchAllPostsTotalQuery, FetchAllPostsTotalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchAllPostsTotalQuery, FetchAllPostsTotalQueryVariables>(
    FetchAllPostsTotalDocument,
    options
  )
}
export function useFetchAllPostsTotalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAllPostsTotalQuery,
    FetchAllPostsTotalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchAllPostsTotalQuery, FetchAllPostsTotalQueryVariables>(
    FetchAllPostsTotalDocument,
    options
  )
}
export type FetchAllPostsTotalQueryHookResult = ReturnType<typeof useFetchAllPostsTotalQuery>
export type FetchAllPostsTotalLazyQueryHookResult = ReturnType<
  typeof useFetchAllPostsTotalLazyQuery
>
export type FetchAllPostsTotalQueryResult = Apollo.QueryResult<
  FetchAllPostsTotalQuery,
  FetchAllPostsTotalQueryVariables
>
export function refetchFetchAllPostsTotalQuery(variables?: FetchAllPostsTotalQueryVariables) {
  return { query: FetchAllPostsTotalDocument, variables: variables }
}
export const FetchPostsByIdsDocument = gql`
  query fetchPostsByIds($ids: [String!]!, $limit: Int, $skip: Int, $locale: String) {
    blogsCollection(
      where: { sys: { id_in: $ids } }
      order: sys_publishedAt_DESC
      limit: $limit
      skip: $skip
    ) {
      items {
        ...blogFragment
        linkedFrom {
          ...blogLinkingFragment
        }
      }
    }
  }
  ${BlogFragmentFragmentDoc}
  ${BlogLinkingFragmentFragmentDoc}
`

/**
 * __useFetchPostsByIdsQuery__
 *
 * To run a query within a React component, call `useFetchPostsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPostsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPostsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchPostsByIdsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchPostsByIdsQuery, FetchPostsByIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchPostsByIdsQuery, FetchPostsByIdsQueryVariables>(
    FetchPostsByIdsDocument,
    options
  )
}
export function useFetchPostsByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchPostsByIdsQuery, FetchPostsByIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchPostsByIdsQuery, FetchPostsByIdsQueryVariables>(
    FetchPostsByIdsDocument,
    options
  )
}
export type FetchPostsByIdsQueryHookResult = ReturnType<typeof useFetchPostsByIdsQuery>
export type FetchPostsByIdsLazyQueryHookResult = ReturnType<typeof useFetchPostsByIdsLazyQuery>
export type FetchPostsByIdsQueryResult = Apollo.QueryResult<
  FetchPostsByIdsQuery,
  FetchPostsByIdsQueryVariables
>
export function refetchFetchPostsByIdsQuery(variables: FetchPostsByIdsQueryVariables) {
  return { query: FetchPostsByIdsDocument, variables: variables }
}
export const FetchPostByTitleDocument = gql`
  query fetchPostByTitle($title: String!, $locale: String) {
    blogsCollection(where: { title: $title }, preview: true, limit: 1, locale: $locale) {
      items {
        ...blogFragment
        ...blogContentFragment
        linkedFrom {
          ...blogLinkingFragment
        }
      }
    }
  }
  ${BlogFragmentFragmentDoc}
  ${BlogContentFragmentFragmentDoc}
  ${BlogLinkingFragmentFragmentDoc}
`

/**
 * __useFetchPostByTitleQuery__
 *
 * To run a query within a React component, call `useFetchPostByTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPostByTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPostByTitleQuery({
 *   variables: {
 *      title: // value for 'title'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchPostByTitleQuery(
  baseOptions: Apollo.QueryHookOptions<FetchPostByTitleQuery, FetchPostByTitleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchPostByTitleQuery, FetchPostByTitleQueryVariables>(
    FetchPostByTitleDocument,
    options
  )
}
export function useFetchPostByTitleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchPostByTitleQuery, FetchPostByTitleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchPostByTitleQuery, FetchPostByTitleQueryVariables>(
    FetchPostByTitleDocument,
    options
  )
}
export type FetchPostByTitleQueryHookResult = ReturnType<typeof useFetchPostByTitleQuery>
export type FetchPostByTitleLazyQueryHookResult = ReturnType<typeof useFetchPostByTitleLazyQuery>
export type FetchPostByTitleQueryResult = Apollo.QueryResult<
  FetchPostByTitleQuery,
  FetchPostByTitleQueryVariables
>
export function refetchFetchPostByTitleQuery(variables: FetchPostByTitleQueryVariables) {
  return { query: FetchPostByTitleDocument, variables: variables }
}
export const FetchTagByIdDocument = gql`
  query fetchTagById($id: String!, $locale: String) {
    tags(id: $id) {
      ...tagFragment
      ...tagContentFragment
    }
  }
  ${TagFragmentFragmentDoc}
  ${TagContentFragmentFragmentDoc}
`

/**
 * __useFetchTagByIdQuery__
 *
 * To run a query within a React component, call `useFetchTagByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTagByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTagByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchTagByIdQuery(
  baseOptions: Apollo.QueryHookOptions<FetchTagByIdQuery, FetchTagByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchTagByIdQuery, FetchTagByIdQueryVariables>(
    FetchTagByIdDocument,
    options
  )
}
export function useFetchTagByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchTagByIdQuery, FetchTagByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchTagByIdQuery, FetchTagByIdQueryVariables>(
    FetchTagByIdDocument,
    options
  )
}
export type FetchTagByIdQueryHookResult = ReturnType<typeof useFetchTagByIdQuery>
export type FetchTagByIdLazyQueryHookResult = ReturnType<typeof useFetchTagByIdLazyQuery>
export type FetchTagByIdQueryResult = Apollo.QueryResult<
  FetchTagByIdQuery,
  FetchTagByIdQueryVariables
>
export function refetchFetchTagByIdQuery(variables: FetchTagByIdQueryVariables) {
  return { query: FetchTagByIdDocument, variables: variables }
}
export const FetchTagIdByTagSlugDocument = gql`
  query fetchTagIdByTagSlug($slug: String!, $locale: String) {
    tagsCollection(where: { slug: $slug }, limit: 1, locale: $locale) {
      items {
        slug
        sys {
          id
        }
      }
    }
  }
`

/**
 * __useFetchTagIdByTagSlugQuery__
 *
 * To run a query within a React component, call `useFetchTagIdByTagSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTagIdByTagSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTagIdByTagSlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchTagIdByTagSlugQuery(
  baseOptions: Apollo.QueryHookOptions<FetchTagIdByTagSlugQuery, FetchTagIdByTagSlugQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchTagIdByTagSlugQuery, FetchTagIdByTagSlugQueryVariables>(
    FetchTagIdByTagSlugDocument,
    options
  )
}
export function useFetchTagIdByTagSlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchTagIdByTagSlugQuery,
    FetchTagIdByTagSlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchTagIdByTagSlugQuery, FetchTagIdByTagSlugQueryVariables>(
    FetchTagIdByTagSlugDocument,
    options
  )
}
export type FetchTagIdByTagSlugQueryHookResult = ReturnType<typeof useFetchTagIdByTagSlugQuery>
export type FetchTagIdByTagSlugLazyQueryHookResult = ReturnType<
  typeof useFetchTagIdByTagSlugLazyQuery
>
export type FetchTagIdByTagSlugQueryResult = Apollo.QueryResult<
  FetchTagIdByTagSlugQuery,
  FetchTagIdByTagSlugQueryVariables
>
export function refetchFetchTagIdByTagSlugQuery(variables: FetchTagIdByTagSlugQueryVariables) {
  return { query: FetchTagIdByTagSlugDocument, variables: variables }
}
export const FetchTagByNameDocument = gql`
  query fetchTagByName($name: String!, $locale: String) {
    tagsCollection(where: { name: $name }, preview: true, limit: 1, locale: $locale) {
      items {
        ...tagFragment
        ...tagContentFragment
      }
    }
  }
  ${TagFragmentFragmentDoc}
  ${TagContentFragmentFragmentDoc}
`

/**
 * __useFetchTagByNameQuery__
 *
 * To run a query within a React component, call `useFetchTagByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTagByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTagByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchTagByNameQuery(
  baseOptions: Apollo.QueryHookOptions<FetchTagByNameQuery, FetchTagByNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchTagByNameQuery, FetchTagByNameQueryVariables>(
    FetchTagByNameDocument,
    options
  )
}
export function useFetchTagByNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchTagByNameQuery, FetchTagByNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchTagByNameQuery, FetchTagByNameQueryVariables>(
    FetchTagByNameDocument,
    options
  )
}
export type FetchTagByNameQueryHookResult = ReturnType<typeof useFetchTagByNameQuery>
export type FetchTagByNameLazyQueryHookResult = ReturnType<typeof useFetchTagByNameLazyQuery>
export type FetchTagByNameQueryResult = Apollo.QueryResult<
  FetchTagByNameQuery,
  FetchTagByNameQueryVariables
>
export function refetchFetchTagByNameQuery(variables: FetchTagByNameQueryVariables) {
  return { query: FetchTagByNameDocument, variables: variables }
}
export const FetchPostAndMorePostsByIdDocument = gql`
  query fetchPostAndMorePostsById($id: String!, $morePostsLimit: Int, $locale: String) {
    blogs(id: $id) {
      ...blogFragment
      ...blogContentFragment
      seoMetadataItemsCollection(locale: $locale) {
        items {
          name
          content
        }
      }
      callToActionLink(locale: $locale) {
        title
        link
      }
      linkedFrom {
        categoriesCollection {
          items {
            title(locale: $locale)
            blogsCollection(limit: $morePostsLimit, locale: $locale) {
              items {
                title
                sys {
                  id
                  firstPublishedAt
                }
              }
            }
          }
        }
      }
    }
  }
  ${BlogFragmentFragmentDoc}
  ${BlogContentFragmentFragmentDoc}
`

/**
 * __useFetchPostAndMorePostsByIdQuery__
 *
 * To run a query within a React component, call `useFetchPostAndMorePostsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPostAndMorePostsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPostAndMorePostsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      morePostsLimit: // value for 'morePostsLimit'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchPostAndMorePostsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchPostAndMorePostsByIdQuery,
    FetchPostAndMorePostsByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchPostAndMorePostsByIdQuery, FetchPostAndMorePostsByIdQueryVariables>(
    FetchPostAndMorePostsByIdDocument,
    options
  )
}
export function useFetchPostAndMorePostsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchPostAndMorePostsByIdQuery,
    FetchPostAndMorePostsByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchPostAndMorePostsByIdQuery,
    FetchPostAndMorePostsByIdQueryVariables
  >(FetchPostAndMorePostsByIdDocument, options)
}
export type FetchPostAndMorePostsByIdQueryHookResult = ReturnType<
  typeof useFetchPostAndMorePostsByIdQuery
>
export type FetchPostAndMorePostsByIdLazyQueryHookResult = ReturnType<
  typeof useFetchPostAndMorePostsByIdLazyQuery
>
export type FetchPostAndMorePostsByIdQueryResult = Apollo.QueryResult<
  FetchPostAndMorePostsByIdQuery,
  FetchPostAndMorePostsByIdQueryVariables
>
export function refetchFetchPostAndMorePostsByIdQuery(
  variables: FetchPostAndMorePostsByIdQueryVariables
) {
  return { query: FetchPostAndMorePostsByIdDocument, variables: variables }
}
export const FetchAllPostsIdByCategoryIdDocument = gql`
  query fetchAllPostsIdByCategoryId($id: String!) {
    categories(id: $id) {
      title
      blogsCollection {
        items {
          sys {
            id
          }
        }
      }
    }
  }
`

/**
 * __useFetchAllPostsIdByCategoryIdQuery__
 *
 * To run a query within a React component, call `useFetchAllPostsIdByCategoryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllPostsIdByCategoryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllPostsIdByCategoryIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchAllPostsIdByCategoryIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchAllPostsIdByCategoryIdQuery,
    FetchAllPostsIdByCategoryIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchAllPostsIdByCategoryIdQuery,
    FetchAllPostsIdByCategoryIdQueryVariables
  >(FetchAllPostsIdByCategoryIdDocument, options)
}
export function useFetchAllPostsIdByCategoryIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAllPostsIdByCategoryIdQuery,
    FetchAllPostsIdByCategoryIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchAllPostsIdByCategoryIdQuery,
    FetchAllPostsIdByCategoryIdQueryVariables
  >(FetchAllPostsIdByCategoryIdDocument, options)
}
export type FetchAllPostsIdByCategoryIdQueryHookResult = ReturnType<
  typeof useFetchAllPostsIdByCategoryIdQuery
>
export type FetchAllPostsIdByCategoryIdLazyQueryHookResult = ReturnType<
  typeof useFetchAllPostsIdByCategoryIdLazyQuery
>
export type FetchAllPostsIdByCategoryIdQueryResult = Apollo.QueryResult<
  FetchAllPostsIdByCategoryIdQuery,
  FetchAllPostsIdByCategoryIdQueryVariables
>
export function refetchFetchAllPostsIdByCategoryIdQuery(
  variables: FetchAllPostsIdByCategoryIdQueryVariables
) {
  return { query: FetchAllPostsIdByCategoryIdDocument, variables: variables }
}
export const FetchCategoryIdByCategoryDocument = gql`
  query fetchCategoryIdByCategory($category: String!, $locale: String) {
    categoriesCollection(where: { title: $category }, locale: $locale, limit: 1) {
      items {
        sys {
          id
        }
      }
    }
  }
`

/**
 * __useFetchCategoryIdByCategoryQuery__
 *
 * To run a query within a React component, call `useFetchCategoryIdByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCategoryIdByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCategoryIdByCategoryQuery({
 *   variables: {
 *      category: // value for 'category'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchCategoryIdByCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchCategoryIdByCategoryQuery,
    FetchCategoryIdByCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchCategoryIdByCategoryQuery, FetchCategoryIdByCategoryQueryVariables>(
    FetchCategoryIdByCategoryDocument,
    options
  )
}
export function useFetchCategoryIdByCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchCategoryIdByCategoryQuery,
    FetchCategoryIdByCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchCategoryIdByCategoryQuery,
    FetchCategoryIdByCategoryQueryVariables
  >(FetchCategoryIdByCategoryDocument, options)
}
export type FetchCategoryIdByCategoryQueryHookResult = ReturnType<
  typeof useFetchCategoryIdByCategoryQuery
>
export type FetchCategoryIdByCategoryLazyQueryHookResult = ReturnType<
  typeof useFetchCategoryIdByCategoryLazyQuery
>
export type FetchCategoryIdByCategoryQueryResult = Apollo.QueryResult<
  FetchCategoryIdByCategoryQuery,
  FetchCategoryIdByCategoryQueryVariables
>
export function refetchFetchCategoryIdByCategoryQuery(
  variables: FetchCategoryIdByCategoryQueryVariables
) {
  return { query: FetchCategoryIdByCategoryDocument, variables: variables }
}
export const FetchCategoryByCategoryIdDocument = gql`
  query fetchCategoryByCategoryId($id: String!, $locale: String) {
    categories(id: $id, locale: $locale) {
      sys {
        id
      }
      title
    }
  }
`

/**
 * __useFetchCategoryByCategoryIdQuery__
 *
 * To run a query within a React component, call `useFetchCategoryByCategoryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCategoryByCategoryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCategoryByCategoryIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchCategoryByCategoryIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchCategoryByCategoryIdQuery,
    FetchCategoryByCategoryIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchCategoryByCategoryIdQuery, FetchCategoryByCategoryIdQueryVariables>(
    FetchCategoryByCategoryIdDocument,
    options
  )
}
export function useFetchCategoryByCategoryIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchCategoryByCategoryIdQuery,
    FetchCategoryByCategoryIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchCategoryByCategoryIdQuery,
    FetchCategoryByCategoryIdQueryVariables
  >(FetchCategoryByCategoryIdDocument, options)
}
export type FetchCategoryByCategoryIdQueryHookResult = ReturnType<
  typeof useFetchCategoryByCategoryIdQuery
>
export type FetchCategoryByCategoryIdLazyQueryHookResult = ReturnType<
  typeof useFetchCategoryByCategoryIdLazyQuery
>
export type FetchCategoryByCategoryIdQueryResult = Apollo.QueryResult<
  FetchCategoryByCategoryIdQuery,
  FetchCategoryByCategoryIdQueryVariables
>
export function refetchFetchCategoryByCategoryIdQuery(
  variables: FetchCategoryByCategoryIdQueryVariables
) {
  return { query: FetchCategoryByCategoryIdDocument, variables: variables }
}
export const FetchPostIdByPostTitleDocument = gql`
  query fetchPostIdByPostTitle($title: String!, $locale: String) {
    blogsCollection(where: { title: $title }, limit: 1, locale: $locale) {
      items {
        title
        sys {
          id
        }
      }
    }
  }
`

/**
 * __useFetchPostIdByPostTitleQuery__
 *
 * To run a query within a React component, call `useFetchPostIdByPostTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPostIdByPostTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPostIdByPostTitleQuery({
 *   variables: {
 *      title: // value for 'title'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchPostIdByPostTitleQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchPostIdByPostTitleQuery,
    FetchPostIdByPostTitleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchPostIdByPostTitleQuery, FetchPostIdByPostTitleQueryVariables>(
    FetchPostIdByPostTitleDocument,
    options
  )
}
export function useFetchPostIdByPostTitleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchPostIdByPostTitleQuery,
    FetchPostIdByPostTitleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchPostIdByPostTitleQuery, FetchPostIdByPostTitleQueryVariables>(
    FetchPostIdByPostTitleDocument,
    options
  )
}
export type FetchPostIdByPostTitleQueryHookResult = ReturnType<
  typeof useFetchPostIdByPostTitleQuery
>
export type FetchPostIdByPostTitleLazyQueryHookResult = ReturnType<
  typeof useFetchPostIdByPostTitleLazyQuery
>
export type FetchPostIdByPostTitleQueryResult = Apollo.QueryResult<
  FetchPostIdByPostTitleQuery,
  FetchPostIdByPostTitleQueryVariables
>
export function refetchFetchPostIdByPostTitleQuery(
  variables: FetchPostIdByPostTitleQueryVariables
) {
  return { query: FetchPostIdByPostTitleDocument, variables: variables }
}
export const FetchAllPostsTitleDocument = gql`
  query fetchAllPostsTitle($locale: String) {
    blogsCollection(order: sys_publishedAt_DESC, locale: $locale) {
      items {
        title
      }
    }
  }
`

/**
 * __useFetchAllPostsTitleQuery__
 *
 * To run a query within a React component, call `useFetchAllPostsTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllPostsTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllPostsTitleQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchAllPostsTitleQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchAllPostsTitleQuery, FetchAllPostsTitleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchAllPostsTitleQuery, FetchAllPostsTitleQueryVariables>(
    FetchAllPostsTitleDocument,
    options
  )
}
export function useFetchAllPostsTitleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAllPostsTitleQuery,
    FetchAllPostsTitleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchAllPostsTitleQuery, FetchAllPostsTitleQueryVariables>(
    FetchAllPostsTitleDocument,
    options
  )
}
export type FetchAllPostsTitleQueryHookResult = ReturnType<typeof useFetchAllPostsTitleQuery>
export type FetchAllPostsTitleLazyQueryHookResult = ReturnType<
  typeof useFetchAllPostsTitleLazyQuery
>
export type FetchAllPostsTitleQueryResult = Apollo.QueryResult<
  FetchAllPostsTitleQuery,
  FetchAllPostsTitleQueryVariables
>
export function refetchFetchAllPostsTitleQuery(variables?: FetchAllPostsTitleQueryVariables) {
  return { query: FetchAllPostsTitleDocument, variables: variables }
}
export const FetchAllCategoriesDocument = gql`
  query fetchAllCategories($filter: CategoriesFilter, $locale: String) {
    categoriesCollection(where: $filter) {
      items {
        title(locale: $locale)
      }
    }
  }
`

/**
 * __useFetchAllCategoriesQuery__
 *
 * To run a query within a React component, call `useFetchAllCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllCategoriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchAllCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchAllCategoriesQuery, FetchAllCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchAllCategoriesQuery, FetchAllCategoriesQueryVariables>(
    FetchAllCategoriesDocument,
    options
  )
}
export function useFetchAllCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAllCategoriesQuery,
    FetchAllCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchAllCategoriesQuery, FetchAllCategoriesQueryVariables>(
    FetchAllCategoriesDocument,
    options
  )
}
export type FetchAllCategoriesQueryHookResult = ReturnType<typeof useFetchAllCategoriesQuery>
export type FetchAllCategoriesLazyQueryHookResult = ReturnType<
  typeof useFetchAllCategoriesLazyQuery
>
export type FetchAllCategoriesQueryResult = Apollo.QueryResult<
  FetchAllCategoriesQuery,
  FetchAllCategoriesQueryVariables
>
export function refetchFetchAllCategoriesQuery(variables?: FetchAllCategoriesQueryVariables) {
  return { query: FetchAllCategoriesDocument, variables: variables }
}
export const FetchAllProductsDocument = gql`
  query fetchAllProducts(
    $locale: String
    $limit: Int
    $skip: Int
    $order: [ProductsOrder]
    $textSearch: String
  ) {
    productsCollection(
      limit: $limit
      skip: $skip
      order: $order
      locale: $locale
      where: {
        OR: [
          { title_contains: $textSearch }
          { abstract_contains: $textSearch }
          { description_contains: $textSearch }
        ]
      }
    ) {
      items {
        ...productPreviewFragment
      }
    }
  }
  ${ProductPreviewFragmentFragmentDoc}
`

/**
 * __useFetchAllProductsQuery__
 *
 * To run a query within a React component, call `useFetchAllProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllProductsQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *      textSearch: // value for 'textSearch'
 *   },
 * });
 */
export function useFetchAllProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchAllProductsQuery, FetchAllProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchAllProductsQuery, FetchAllProductsQueryVariables>(
    FetchAllProductsDocument,
    options
  )
}
export function useFetchAllProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchAllProductsQuery, FetchAllProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchAllProductsQuery, FetchAllProductsQueryVariables>(
    FetchAllProductsDocument,
    options
  )
}
export type FetchAllProductsQueryHookResult = ReturnType<typeof useFetchAllProductsQuery>
export type FetchAllProductsLazyQueryHookResult = ReturnType<typeof useFetchAllProductsLazyQuery>
export type FetchAllProductsQueryResult = Apollo.QueryResult<
  FetchAllProductsQuery,
  FetchAllProductsQueryVariables
>
export function refetchFetchAllProductsQuery(variables?: FetchAllProductsQueryVariables) {
  return { query: FetchAllProductsDocument, variables: variables }
}
export const FetchAllProductsTotalDocument = gql`
  query fetchAllProductsTotal($filters: ProductsFilter, $locale: String) {
    productsCollection(where: $filters, order: sys_publishedAt_DESC, locale: $locale) {
      total
    }
  }
`

/**
 * __useFetchAllProductsTotalQuery__
 *
 * To run a query within a React component, call `useFetchAllProductsTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllProductsTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllProductsTotalQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchAllProductsTotalQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchAllProductsTotalQuery,
    FetchAllProductsTotalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchAllProductsTotalQuery, FetchAllProductsTotalQueryVariables>(
    FetchAllProductsTotalDocument,
    options
  )
}
export function useFetchAllProductsTotalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAllProductsTotalQuery,
    FetchAllProductsTotalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchAllProductsTotalQuery, FetchAllProductsTotalQueryVariables>(
    FetchAllProductsTotalDocument,
    options
  )
}
export type FetchAllProductsTotalQueryHookResult = ReturnType<typeof useFetchAllProductsTotalQuery>
export type FetchAllProductsTotalLazyQueryHookResult = ReturnType<
  typeof useFetchAllProductsTotalLazyQuery
>
export type FetchAllProductsTotalQueryResult = Apollo.QueryResult<
  FetchAllProductsTotalQuery,
  FetchAllProductsTotalQueryVariables
>
export function refetchFetchAllProductsTotalQuery(variables?: FetchAllProductsTotalQueryVariables) {
  return { query: FetchAllProductsTotalDocument, variables: variables }
}
export const FetchAllTagsTotalDocument = gql`
  query fetchAllTagsTotal($filters: TagsFilter, $locale: String) {
    tagsCollection(where: $filters, order: sys_publishedAt_DESC, locale: $locale) {
      total
    }
  }
`

/**
 * __useFetchAllTagsTotalQuery__
 *
 * To run a query within a React component, call `useFetchAllTagsTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllTagsTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllTagsTotalQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchAllTagsTotalQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchAllTagsTotalQuery, FetchAllTagsTotalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchAllTagsTotalQuery, FetchAllTagsTotalQueryVariables>(
    FetchAllTagsTotalDocument,
    options
  )
}
export function useFetchAllTagsTotalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchAllTagsTotalQuery, FetchAllTagsTotalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchAllTagsTotalQuery, FetchAllTagsTotalQueryVariables>(
    FetchAllTagsTotalDocument,
    options
  )
}
export type FetchAllTagsTotalQueryHookResult = ReturnType<typeof useFetchAllTagsTotalQuery>
export type FetchAllTagsTotalLazyQueryHookResult = ReturnType<typeof useFetchAllTagsTotalLazyQuery>
export type FetchAllTagsTotalQueryResult = Apollo.QueryResult<
  FetchAllTagsTotalQuery,
  FetchAllTagsTotalQueryVariables
>
export function refetchFetchAllTagsTotalQuery(variables?: FetchAllTagsTotalQueryVariables) {
  return { query: FetchAllTagsTotalDocument, variables: variables }
}
export const FetchAllProductSearchTagsDocument = gql`
  query fetchAllProductSearchTags($locale: String, $limit: Int, $skip: Int, $order: [TagsOrder]) {
    tagsCollection(
      limit: $limit
      skip: $skip
      order: $order
      locale: $locale
      where: { typeForSearch_contains_some: ["all", "product"] }
    ) {
      items {
        ...TagsForAllProducts
      }
    }
  }
  ${TagsForAllProductsFragmentDoc}
`

/**
 * __useFetchAllProductSearchTagsQuery__
 *
 * To run a query within a React component, call `useFetchAllProductSearchTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllProductSearchTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllProductSearchTagsQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useFetchAllProductSearchTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchAllProductSearchTagsQuery,
    FetchAllProductSearchTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchAllProductSearchTagsQuery, FetchAllProductSearchTagsQueryVariables>(
    FetchAllProductSearchTagsDocument,
    options
  )
}
export function useFetchAllProductSearchTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAllProductSearchTagsQuery,
    FetchAllProductSearchTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchAllProductSearchTagsQuery,
    FetchAllProductSearchTagsQueryVariables
  >(FetchAllProductSearchTagsDocument, options)
}
export type FetchAllProductSearchTagsQueryHookResult = ReturnType<
  typeof useFetchAllProductSearchTagsQuery
>
export type FetchAllProductSearchTagsLazyQueryHookResult = ReturnType<
  typeof useFetchAllProductSearchTagsLazyQuery
>
export type FetchAllProductSearchTagsQueryResult = Apollo.QueryResult<
  FetchAllProductSearchTagsQuery,
  FetchAllProductSearchTagsQueryVariables
>
export function refetchFetchAllProductSearchTagsQuery(
  variables?: FetchAllProductSearchTagsQueryVariables
) {
  return { query: FetchAllProductSearchTagsDocument, variables: variables }
}
export const FetchAllProductsSlugDocument = gql`
  query fetchAllProductsSlug($locale: String, $limit: Int, $skip: Int, $order: [ProductsOrder]) {
    productsCollection(limit: $limit, skip: $skip, order: $order, locale: $locale) {
      items {
        sys {
          id
        }
        slug
      }
    }
  }
`

/**
 * __useFetchAllProductsSlugQuery__
 *
 * To run a query within a React component, call `useFetchAllProductsSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllProductsSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllProductsSlugQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useFetchAllProductsSlugQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchAllProductsSlugQuery,
    FetchAllProductsSlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchAllProductsSlugQuery, FetchAllProductsSlugQueryVariables>(
    FetchAllProductsSlugDocument,
    options
  )
}
export function useFetchAllProductsSlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAllProductsSlugQuery,
    FetchAllProductsSlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchAllProductsSlugQuery, FetchAllProductsSlugQueryVariables>(
    FetchAllProductsSlugDocument,
    options
  )
}
export type FetchAllProductsSlugQueryHookResult = ReturnType<typeof useFetchAllProductsSlugQuery>
export type FetchAllProductsSlugLazyQueryHookResult = ReturnType<
  typeof useFetchAllProductsSlugLazyQuery
>
export type FetchAllProductsSlugQueryResult = Apollo.QueryResult<
  FetchAllProductsSlugQuery,
  FetchAllProductsSlugQueryVariables
>
export function refetchFetchAllProductsSlugQuery(variables?: FetchAllProductsSlugQueryVariables) {
  return { query: FetchAllProductsSlugDocument, variables: variables }
}
export const FetchAllTagsSlugDocument = gql`
  query fetchAllTagsSlug($locale: String, $limit: Int, $skip: Int) {
    tagsCollection(limit: $limit, skip: $skip, locale: $locale) {
      items {
        sys {
          id
        }
        slug
      }
    }
  }
`

/**
 * __useFetchAllTagsSlugQuery__
 *
 * To run a query within a React component, call `useFetchAllTagsSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllTagsSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllTagsSlugQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useFetchAllTagsSlugQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchAllTagsSlugQuery, FetchAllTagsSlugQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchAllTagsSlugQuery, FetchAllTagsSlugQueryVariables>(
    FetchAllTagsSlugDocument,
    options
  )
}
export function useFetchAllTagsSlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchAllTagsSlugQuery, FetchAllTagsSlugQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchAllTagsSlugQuery, FetchAllTagsSlugQueryVariables>(
    FetchAllTagsSlugDocument,
    options
  )
}
export type FetchAllTagsSlugQueryHookResult = ReturnType<typeof useFetchAllTagsSlugQuery>
export type FetchAllTagsSlugLazyQueryHookResult = ReturnType<typeof useFetchAllTagsSlugLazyQuery>
export type FetchAllTagsSlugQueryResult = Apollo.QueryResult<
  FetchAllTagsSlugQuery,
  FetchAllTagsSlugQueryVariables
>
export function refetchFetchAllTagsSlugQuery(variables?: FetchAllTagsSlugQueryVariables) {
  return { query: FetchAllTagsSlugDocument, variables: variables }
}
export const FetchProductIdByProductSlugDocument = gql`
  query fetchProductIdByProductSlug($slug: String!, $locale: String) {
    productsCollection(where: { slug: $slug }, limit: 1, locale: $locale) {
      items {
        slug
        sys {
          id
        }
      }
    }
  }
`

/**
 * __useFetchProductIdByProductSlugQuery__
 *
 * To run a query within a React component, call `useFetchProductIdByProductSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProductIdByProductSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProductIdByProductSlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchProductIdByProductSlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchProductIdByProductSlugQuery,
    FetchProductIdByProductSlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchProductIdByProductSlugQuery,
    FetchProductIdByProductSlugQueryVariables
  >(FetchProductIdByProductSlugDocument, options)
}
export function useFetchProductIdByProductSlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchProductIdByProductSlugQuery,
    FetchProductIdByProductSlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchProductIdByProductSlugQuery,
    FetchProductIdByProductSlugQueryVariables
  >(FetchProductIdByProductSlugDocument, options)
}
export type FetchProductIdByProductSlugQueryHookResult = ReturnType<
  typeof useFetchProductIdByProductSlugQuery
>
export type FetchProductIdByProductSlugLazyQueryHookResult = ReturnType<
  typeof useFetchProductIdByProductSlugLazyQuery
>
export type FetchProductIdByProductSlugQueryResult = Apollo.QueryResult<
  FetchProductIdByProductSlugQuery,
  FetchProductIdByProductSlugQueryVariables
>
export function refetchFetchProductIdByProductSlugQuery(
  variables: FetchProductIdByProductSlugQueryVariables
) {
  return { query: FetchProductIdByProductSlugDocument, variables: variables }
}
export const FetchProductByIdDocument = gql`
  query fetchProductById($id: String!, $locale: String) {
    products(id: $id, locale: $locale) {
      sys {
        id
        firstPublishedAt
      }
      slug
      title
      description
      price
      ...productContentFragment
      previewImage {
        url
      }
      imagesCollection {
        items {
          url
        }
      }
      tagsCollection {
        items {
          sys {
            id
          }
          name
        }
      }
      seoMetadataItemsCollection(locale: $locale) {
        items {
          ...seoMetadataFragment
        }
      }
      callToActionLink(locale: $locale) {
        title
        link
      }
    }
  }
  ${ProductContentFragmentFragmentDoc}
  ${SeoMetadataFragmentFragmentDoc}
`

/**
 * __useFetchProductByIdQuery__
 *
 * To run a query within a React component, call `useFetchProductByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProductByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProductByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchProductByIdQuery(
  baseOptions: Apollo.QueryHookOptions<FetchProductByIdQuery, FetchProductByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchProductByIdQuery, FetchProductByIdQueryVariables>(
    FetchProductByIdDocument,
    options
  )
}
export function useFetchProductByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchProductByIdQuery, FetchProductByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchProductByIdQuery, FetchProductByIdQueryVariables>(
    FetchProductByIdDocument,
    options
  )
}
export type FetchProductByIdQueryHookResult = ReturnType<typeof useFetchProductByIdQuery>
export type FetchProductByIdLazyQueryHookResult = ReturnType<typeof useFetchProductByIdLazyQuery>
export type FetchProductByIdQueryResult = Apollo.QueryResult<
  FetchProductByIdQuery,
  FetchProductByIdQueryVariables
>
export function refetchFetchProductByIdQuery(variables: FetchProductByIdQueryVariables) {
  return { query: FetchProductByIdDocument, variables: variables }
}
export const FetchProductsIdByTagIdDocument = gql`
  query fetchProductsIdByTagId($id: String!, $limit: Int) {
    tags(id: $id) {
      linkedFrom {
        productsCollection(limit: $limit) {
          items {
            sys {
              id
            }
          }
        }
      }
    }
  }
`

/**
 * __useFetchProductsIdByTagIdQuery__
 *
 * To run a query within a React component, call `useFetchProductsIdByTagIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProductsIdByTagIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProductsIdByTagIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFetchProductsIdByTagIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchProductsIdByTagIdQuery,
    FetchProductsIdByTagIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchProductsIdByTagIdQuery, FetchProductsIdByTagIdQueryVariables>(
    FetchProductsIdByTagIdDocument,
    options
  )
}
export function useFetchProductsIdByTagIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchProductsIdByTagIdQuery,
    FetchProductsIdByTagIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchProductsIdByTagIdQuery, FetchProductsIdByTagIdQueryVariables>(
    FetchProductsIdByTagIdDocument,
    options
  )
}
export type FetchProductsIdByTagIdQueryHookResult = ReturnType<
  typeof useFetchProductsIdByTagIdQuery
>
export type FetchProductsIdByTagIdLazyQueryHookResult = ReturnType<
  typeof useFetchProductsIdByTagIdLazyQuery
>
export type FetchProductsIdByTagIdQueryResult = Apollo.QueryResult<
  FetchProductsIdByTagIdQuery,
  FetchProductsIdByTagIdQueryVariables
>
export function refetchFetchProductsIdByTagIdQuery(
  variables: FetchProductsIdByTagIdQueryVariables
) {
  return { query: FetchProductsIdByTagIdDocument, variables: variables }
}
export const FetchProductsByIdsDocument = gql`
  query fetchProductsByIds(
    $ids: [String!]!
    $limit: Int
    $skip: Int
    $order: [ProductsOrder]
    $locale: String
  ) {
    productsCollection(
      where: { sys: { id_in: $ids } }
      limit: $limit
      skip: $skip
      order: $order
      locale: $locale
    ) {
      items {
        ...productPreviewFragment
      }
    }
  }
  ${ProductPreviewFragmentFragmentDoc}
`

/**
 * __useFetchProductsByIdsQuery__
 *
 * To run a query within a React component, call `useFetchProductsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProductsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProductsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchProductsByIdsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchProductsByIdsQuery, FetchProductsByIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchProductsByIdsQuery, FetchProductsByIdsQueryVariables>(
    FetchProductsByIdsDocument,
    options
  )
}
export function useFetchProductsByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchProductsByIdsQuery,
    FetchProductsByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchProductsByIdsQuery, FetchProductsByIdsQueryVariables>(
    FetchProductsByIdsDocument,
    options
  )
}
export type FetchProductsByIdsQueryHookResult = ReturnType<typeof useFetchProductsByIdsQuery>
export type FetchProductsByIdsLazyQueryHookResult = ReturnType<
  typeof useFetchProductsByIdsLazyQuery
>
export type FetchProductsByIdsQueryResult = Apollo.QueryResult<
  FetchProductsByIdsQuery,
  FetchProductsByIdsQueryVariables
>
export function refetchFetchProductsByIdsQuery(variables: FetchProductsByIdsQueryVariables) {
  return { query: FetchProductsByIdsDocument, variables: variables }
}
export const FetchRootSettingsCollectionDocument = gql`
  query fetchRootSettingsCollection($locale: String) {
    rootSettingsCollection(limit: 1) {
      items {
        ...RootSettings
      }
    }
  }
  ${RootSettingsFragmentDoc}
`

/**
 * __useFetchRootSettingsCollectionQuery__
 *
 * To run a query within a React component, call `useFetchRootSettingsCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRootSettingsCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRootSettingsCollectionQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchRootSettingsCollectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchRootSettingsCollectionQuery,
    FetchRootSettingsCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchRootSettingsCollectionQuery,
    FetchRootSettingsCollectionQueryVariables
  >(FetchRootSettingsCollectionDocument, options)
}
export function useFetchRootSettingsCollectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchRootSettingsCollectionQuery,
    FetchRootSettingsCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchRootSettingsCollectionQuery,
    FetchRootSettingsCollectionQueryVariables
  >(FetchRootSettingsCollectionDocument, options)
}
export type FetchRootSettingsCollectionQueryHookResult = ReturnType<
  typeof useFetchRootSettingsCollectionQuery
>
export type FetchRootSettingsCollectionLazyQueryHookResult = ReturnType<
  typeof useFetchRootSettingsCollectionLazyQuery
>
export type FetchRootSettingsCollectionQueryResult = Apollo.QueryResult<
  FetchRootSettingsCollectionQuery,
  FetchRootSettingsCollectionQueryVariables
>
export function refetchFetchRootSettingsCollectionQuery(
  variables?: FetchRootSettingsCollectionQueryVariables
) {
  return { query: FetchRootSettingsCollectionDocument, variables: variables }
}
export const FetchRootSettingsCollectionForWriterPageDocument = gql`
  query fetchRootSettingsCollectionForWriterPage($locale: String) {
    rootSettingsCollection(limit: 1) {
      items {
        ...RootSettings
        writersoftModules
      }
    }
  }
  ${RootSettingsFragmentDoc}
`

/**
 * __useFetchRootSettingsCollectionForWriterPageQuery__
 *
 * To run a query within a React component, call `useFetchRootSettingsCollectionForWriterPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRootSettingsCollectionForWriterPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRootSettingsCollectionForWriterPageQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchRootSettingsCollectionForWriterPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchRootSettingsCollectionForWriterPageQuery,
    FetchRootSettingsCollectionForWriterPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchRootSettingsCollectionForWriterPageQuery,
    FetchRootSettingsCollectionForWriterPageQueryVariables
  >(FetchRootSettingsCollectionForWriterPageDocument, options)
}
export function useFetchRootSettingsCollectionForWriterPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchRootSettingsCollectionForWriterPageQuery,
    FetchRootSettingsCollectionForWriterPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchRootSettingsCollectionForWriterPageQuery,
    FetchRootSettingsCollectionForWriterPageQueryVariables
  >(FetchRootSettingsCollectionForWriterPageDocument, options)
}
export type FetchRootSettingsCollectionForWriterPageQueryHookResult = ReturnType<
  typeof useFetchRootSettingsCollectionForWriterPageQuery
>
export type FetchRootSettingsCollectionForWriterPageLazyQueryHookResult = ReturnType<
  typeof useFetchRootSettingsCollectionForWriterPageLazyQuery
>
export type FetchRootSettingsCollectionForWriterPageQueryResult = Apollo.QueryResult<
  FetchRootSettingsCollectionForWriterPageQuery,
  FetchRootSettingsCollectionForWriterPageQueryVariables
>
export function refetchFetchRootSettingsCollectionForWriterPageQuery(
  variables?: FetchRootSettingsCollectionForWriterPageQueryVariables
) {
  return { query: FetchRootSettingsCollectionForWriterPageDocument, variables: variables }
}
export const FetchAllPagesSlugDocument = gql`
  query fetchAllPagesSlug {
    pagesCollection {
      items {
        slug
      }
    }
  }
`

/**
 * __useFetchAllPagesSlugQuery__
 *
 * To run a query within a React component, call `useFetchAllPagesSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllPagesSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllPagesSlugQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAllPagesSlugQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchAllPagesSlugQuery, FetchAllPagesSlugQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchAllPagesSlugQuery, FetchAllPagesSlugQueryVariables>(
    FetchAllPagesSlugDocument,
    options
  )
}
export function useFetchAllPagesSlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchAllPagesSlugQuery, FetchAllPagesSlugQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchAllPagesSlugQuery, FetchAllPagesSlugQueryVariables>(
    FetchAllPagesSlugDocument,
    options
  )
}
export type FetchAllPagesSlugQueryHookResult = ReturnType<typeof useFetchAllPagesSlugQuery>
export type FetchAllPagesSlugLazyQueryHookResult = ReturnType<typeof useFetchAllPagesSlugLazyQuery>
export type FetchAllPagesSlugQueryResult = Apollo.QueryResult<
  FetchAllPagesSlugQuery,
  FetchAllPagesSlugQueryVariables
>
export function refetchFetchAllPagesSlugQuery(variables?: FetchAllPagesSlugQueryVariables) {
  return { query: FetchAllPagesSlugDocument, variables: variables }
}
export const FetchPageBySlugDocument = gql`
  query fetchPageBySlug($slug: String!, $locale: String) {
    pagesCollection(where: { slug: $slug }, limit: 1, locale: $locale) {
      items {
        sys {
          id
        }
        seoMetadataItemsCollection(locale: $locale) {
          items {
            name
            content
          }
        }
        ... on Pages {
          sectionsCollection {
            items {
              ... on SectionsHero {
                key: __typename
                sys {
                  id
                }
              }
              ... on SectionsMinimalsImagesList {
                key: __typename
                sys {
                  id
                }
              }
              ... on SectionsMinimalsServices {
                key: __typename
                sys {
                  id
                }
              }
              ... on SectionsMinimalsContactList {
                key: __typename
                sys {
                  id
                }
              }
              ... on SectionsMinimalsHistoryList {
                key: __typename
                sys {
                  id
                }
              }
              ... on SectionsMinimalsHumanList {
                key: __typename
                sys {
                  id
                }
              }
              ... on SectionsDetailPersonList {
                key: __typename
                sys {
                  id
                }
              }
              ... on SectionsFaqList {
                key: __typename
                sys {
                  id
                }
              }
              ... on SectionsMinimalsVideo {
                key: __typename
                sys {
                  id
                }
              }
              ... on SectionsFeaturesList {
                key: __typename
                sys {
                  id
                }
              }
              ... on SectionsMintList {
                key: __typename
                sys {
                  id
                }
              }
              ... on SectionsTimer {
                key: __typename
                sys {
                  id
                }
              }
              ... on SectionsMinimalsGallery {
                key: __typename
                sys {
                  id
                }
              }
              ... on SectionsForm {
                key: __typename
                sys {
                  id
                }
              }
              ... on SectionsImage {
                key: __typename
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useFetchPageBySlugQuery__
 *
 * To run a query within a React component, call `useFetchPageBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPageBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPageBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchPageBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<FetchPageBySlugQuery, FetchPageBySlugQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchPageBySlugQuery, FetchPageBySlugQueryVariables>(
    FetchPageBySlugDocument,
    options
  )
}
export function useFetchPageBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchPageBySlugQuery, FetchPageBySlugQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchPageBySlugQuery, FetchPageBySlugQueryVariables>(
    FetchPageBySlugDocument,
    options
  )
}
export type FetchPageBySlugQueryHookResult = ReturnType<typeof useFetchPageBySlugQuery>
export type FetchPageBySlugLazyQueryHookResult = ReturnType<typeof useFetchPageBySlugLazyQuery>
export type FetchPageBySlugQueryResult = Apollo.QueryResult<
  FetchPageBySlugQuery,
  FetchPageBySlugQueryVariables
>
export function refetchFetchPageBySlugQuery(variables: FetchPageBySlugQueryVariables) {
  return { query: FetchPageBySlugDocument, variables: variables }
}
export const FetchSectionMintListByIdDocument = gql`
  query fetchSectionMintListById($id: String!, $locale: String) {
    sectionsMintList(id: $id, locale: $locale) {
      ...sectionsMintListFragment
    }
  }
  ${SectionsMintListFragmentFragmentDoc}
`

/**
 * __useFetchSectionMintListByIdQuery__
 *
 * To run a query within a React component, call `useFetchSectionMintListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSectionMintListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSectionMintListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchSectionMintListByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSectionMintListByIdQuery,
    FetchSectionMintListByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchSectionMintListByIdQuery, FetchSectionMintListByIdQueryVariables>(
    FetchSectionMintListByIdDocument,
    options
  )
}
export function useFetchSectionMintListByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSectionMintListByIdQuery,
    FetchSectionMintListByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchSectionMintListByIdQuery, FetchSectionMintListByIdQueryVariables>(
    FetchSectionMintListByIdDocument,
    options
  )
}
export type FetchSectionMintListByIdQueryHookResult = ReturnType<
  typeof useFetchSectionMintListByIdQuery
>
export type FetchSectionMintListByIdLazyQueryHookResult = ReturnType<
  typeof useFetchSectionMintListByIdLazyQuery
>
export type FetchSectionMintListByIdQueryResult = Apollo.QueryResult<
  FetchSectionMintListByIdQuery,
  FetchSectionMintListByIdQueryVariables
>
export function refetchFetchSectionMintListByIdQuery(
  variables: FetchSectionMintListByIdQueryVariables
) {
  return { query: FetchSectionMintListByIdDocument, variables: variables }
}
export const FetchSmartContractErc721CollectionByContractAddressDocument = gql`
  query fetchSmartContractErc721CollectionByContractAddress($smartContractAddress: String!) {
    smartContractErc721Collection(where: { smartContractAddress: $smartContractAddress }) {
      items {
        ...smartContractErc721Fragment
      }
    }
  }
  ${SmartContractErc721FragmentFragmentDoc}
`

/**
 * __useFetchSmartContractErc721CollectionByContractAddressQuery__
 *
 * To run a query within a React component, call `useFetchSmartContractErc721CollectionByContractAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSmartContractErc721CollectionByContractAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSmartContractErc721CollectionByContractAddressQuery({
 *   variables: {
 *      smartContractAddress: // value for 'smartContractAddress'
 *   },
 * });
 */
export function useFetchSmartContractErc721CollectionByContractAddressQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSmartContractErc721CollectionByContractAddressQuery,
    FetchSmartContractErc721CollectionByContractAddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchSmartContractErc721CollectionByContractAddressQuery,
    FetchSmartContractErc721CollectionByContractAddressQueryVariables
  >(FetchSmartContractErc721CollectionByContractAddressDocument, options)
}
export function useFetchSmartContractErc721CollectionByContractAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSmartContractErc721CollectionByContractAddressQuery,
    FetchSmartContractErc721CollectionByContractAddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchSmartContractErc721CollectionByContractAddressQuery,
    FetchSmartContractErc721CollectionByContractAddressQueryVariables
  >(FetchSmartContractErc721CollectionByContractAddressDocument, options)
}
export type FetchSmartContractErc721CollectionByContractAddressQueryHookResult = ReturnType<
  typeof useFetchSmartContractErc721CollectionByContractAddressQuery
>
export type FetchSmartContractErc721CollectionByContractAddressLazyQueryHookResult = ReturnType<
  typeof useFetchSmartContractErc721CollectionByContractAddressLazyQuery
>
export type FetchSmartContractErc721CollectionByContractAddressQueryResult = Apollo.QueryResult<
  FetchSmartContractErc721CollectionByContractAddressQuery,
  FetchSmartContractErc721CollectionByContractAddressQueryVariables
>
export function refetchFetchSmartContractErc721CollectionByContractAddressQuery(
  variables: FetchSmartContractErc721CollectionByContractAddressQueryVariables
) {
  return {
    query: FetchSmartContractErc721CollectionByContractAddressDocument,
    variables: variables,
  }
}
export const FetchSectionFeaturesListByIdDocument = gql`
  query fetchSectionFeaturesListById($id: String!, $locale: String) {
    sectionsFeaturesList(id: $id, locale: $locale) {
      ...sectionsFeaturesListFragment
    }
  }
  ${SectionsFeaturesListFragmentFragmentDoc}
`

/**
 * __useFetchSectionFeaturesListByIdQuery__
 *
 * To run a query within a React component, call `useFetchSectionFeaturesListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSectionFeaturesListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSectionFeaturesListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchSectionFeaturesListByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSectionFeaturesListByIdQuery,
    FetchSectionFeaturesListByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchSectionFeaturesListByIdQuery,
    FetchSectionFeaturesListByIdQueryVariables
  >(FetchSectionFeaturesListByIdDocument, options)
}
export function useFetchSectionFeaturesListByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSectionFeaturesListByIdQuery,
    FetchSectionFeaturesListByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchSectionFeaturesListByIdQuery,
    FetchSectionFeaturesListByIdQueryVariables
  >(FetchSectionFeaturesListByIdDocument, options)
}
export type FetchSectionFeaturesListByIdQueryHookResult = ReturnType<
  typeof useFetchSectionFeaturesListByIdQuery
>
export type FetchSectionFeaturesListByIdLazyQueryHookResult = ReturnType<
  typeof useFetchSectionFeaturesListByIdLazyQuery
>
export type FetchSectionFeaturesListByIdQueryResult = Apollo.QueryResult<
  FetchSectionFeaturesListByIdQuery,
  FetchSectionFeaturesListByIdQueryVariables
>
export function refetchFetchSectionFeaturesListByIdQuery(
  variables: FetchSectionFeaturesListByIdQueryVariables
) {
  return { query: FetchSectionFeaturesListByIdDocument, variables: variables }
}
export const FetchErc721TokenMetadataByIdDocument = gql`
  query fetchErc721TokenMetadataById($id: String!, $locale: String) {
    erc721TokenMetadata(id: $id, locale: $locale) {
      ...erc721TokenMetadataFragment
    }
  }
  ${Erc721TokenMetadataFragmentFragmentDoc}
`

/**
 * __useFetchErc721TokenMetadataByIdQuery__
 *
 * To run a query within a React component, call `useFetchErc721TokenMetadataByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchErc721TokenMetadataByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchErc721TokenMetadataByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchErc721TokenMetadataByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchErc721TokenMetadataByIdQuery,
    FetchErc721TokenMetadataByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchErc721TokenMetadataByIdQuery,
    FetchErc721TokenMetadataByIdQueryVariables
  >(FetchErc721TokenMetadataByIdDocument, options)
}
export function useFetchErc721TokenMetadataByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchErc721TokenMetadataByIdQuery,
    FetchErc721TokenMetadataByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchErc721TokenMetadataByIdQuery,
    FetchErc721TokenMetadataByIdQueryVariables
  >(FetchErc721TokenMetadataByIdDocument, options)
}
export type FetchErc721TokenMetadataByIdQueryHookResult = ReturnType<
  typeof useFetchErc721TokenMetadataByIdQuery
>
export type FetchErc721TokenMetadataByIdLazyQueryHookResult = ReturnType<
  typeof useFetchErc721TokenMetadataByIdLazyQuery
>
export type FetchErc721TokenMetadataByIdQueryResult = Apollo.QueryResult<
  FetchErc721TokenMetadataByIdQuery,
  FetchErc721TokenMetadataByIdQueryVariables
>
export function refetchFetchErc721TokenMetadataByIdQuery(
  variables: FetchErc721TokenMetadataByIdQueryVariables
) {
  return { query: FetchErc721TokenMetadataByIdDocument, variables: variables }
}
export const FetchErc721TokenMetadataTokenIdDocument = gql`
  query fetchErc721TokenMetadataTokenId($tokenId: String!) {
    erc721TokenMetadataCollection(where: { tokenId: $tokenId }) {
      items {
        tokenId
        sys {
          id
        }
      }
    }
  }
`

/**
 * __useFetchErc721TokenMetadataTokenIdQuery__
 *
 * To run a query within a React component, call `useFetchErc721TokenMetadataTokenIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchErc721TokenMetadataTokenIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchErc721TokenMetadataTokenIdQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useFetchErc721TokenMetadataTokenIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchErc721TokenMetadataTokenIdQuery,
    FetchErc721TokenMetadataTokenIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchErc721TokenMetadataTokenIdQuery,
    FetchErc721TokenMetadataTokenIdQueryVariables
  >(FetchErc721TokenMetadataTokenIdDocument, options)
}
export function useFetchErc721TokenMetadataTokenIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchErc721TokenMetadataTokenIdQuery,
    FetchErc721TokenMetadataTokenIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchErc721TokenMetadataTokenIdQuery,
    FetchErc721TokenMetadataTokenIdQueryVariables
  >(FetchErc721TokenMetadataTokenIdDocument, options)
}
export type FetchErc721TokenMetadataTokenIdQueryHookResult = ReturnType<
  typeof useFetchErc721TokenMetadataTokenIdQuery
>
export type FetchErc721TokenMetadataTokenIdLazyQueryHookResult = ReturnType<
  typeof useFetchErc721TokenMetadataTokenIdLazyQuery
>
export type FetchErc721TokenMetadataTokenIdQueryResult = Apollo.QueryResult<
  FetchErc721TokenMetadataTokenIdQuery,
  FetchErc721TokenMetadataTokenIdQueryVariables
>
export function refetchFetchErc721TokenMetadataTokenIdQuery(
  variables: FetchErc721TokenMetadataTokenIdQueryVariables
) {
  return { query: FetchErc721TokenMetadataTokenIdDocument, variables: variables }
}
export const FetchSectionHeroByIdDocument = gql`
  query fetchSectionHeroById($id: String!, $locale: String) {
    sectionsHero(id: $id, locale: $locale) {
      ...sectionsHeroFragment
    }
  }
  ${SectionsHeroFragmentFragmentDoc}
`

/**
 * __useFetchSectionHeroByIdQuery__
 *
 * To run a query within a React component, call `useFetchSectionHeroByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSectionHeroByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSectionHeroByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchSectionHeroByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSectionHeroByIdQuery,
    FetchSectionHeroByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchSectionHeroByIdQuery, FetchSectionHeroByIdQueryVariables>(
    FetchSectionHeroByIdDocument,
    options
  )
}
export function useFetchSectionHeroByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSectionHeroByIdQuery,
    FetchSectionHeroByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchSectionHeroByIdQuery, FetchSectionHeroByIdQueryVariables>(
    FetchSectionHeroByIdDocument,
    options
  )
}
export type FetchSectionHeroByIdQueryHookResult = ReturnType<typeof useFetchSectionHeroByIdQuery>
export type FetchSectionHeroByIdLazyQueryHookResult = ReturnType<
  typeof useFetchSectionHeroByIdLazyQuery
>
export type FetchSectionHeroByIdQueryResult = Apollo.QueryResult<
  FetchSectionHeroByIdQuery,
  FetchSectionHeroByIdQueryVariables
>
export function refetchFetchSectionHeroByIdQuery(variables: FetchSectionHeroByIdQueryVariables) {
  return { query: FetchSectionHeroByIdDocument, variables: variables }
}
export const AdminFetchSectionHeroByIdDocument = gql`
  query adminFetchSectionHeroById($id: String!, $locale: String) {
    sectionsHero(id: $id, locale: $locale) {
      ...sectionsHeroFragmentAdmin
    }
  }
  ${SectionsHeroFragmentAdminFragmentDoc}
`

/**
 * __useAdminFetchSectionHeroByIdQuery__
 *
 * To run a query within a React component, call `useAdminFetchSectionHeroByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFetchSectionHeroByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFetchSectionHeroByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useAdminFetchSectionHeroByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminFetchSectionHeroByIdQuery,
    AdminFetchSectionHeroByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdminFetchSectionHeroByIdQuery, AdminFetchSectionHeroByIdQueryVariables>(
    AdminFetchSectionHeroByIdDocument,
    options
  )
}
export function useAdminFetchSectionHeroByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminFetchSectionHeroByIdQuery,
    AdminFetchSectionHeroByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminFetchSectionHeroByIdQuery,
    AdminFetchSectionHeroByIdQueryVariables
  >(AdminFetchSectionHeroByIdDocument, options)
}
export type AdminFetchSectionHeroByIdQueryHookResult = ReturnType<
  typeof useAdminFetchSectionHeroByIdQuery
>
export type AdminFetchSectionHeroByIdLazyQueryHookResult = ReturnType<
  typeof useAdminFetchSectionHeroByIdLazyQuery
>
export type AdminFetchSectionHeroByIdQueryResult = Apollo.QueryResult<
  AdminFetchSectionHeroByIdQuery,
  AdminFetchSectionHeroByIdQueryVariables
>
export function refetchAdminFetchSectionHeroByIdQuery(
  variables: AdminFetchSectionHeroByIdQueryVariables
) {
  return { query: AdminFetchSectionHeroByIdDocument, variables: variables }
}
export const AdminFetchPromptEngineerByIdDocument = gql`
  query adminFetchPromptEngineerById($id: String!, $locale: String) {
    promptEngineering(id: $id, locale: $locale) {
      ...PromptEngineeringAdmin
    }
  }
  ${PromptEngineeringAdminFragmentDoc}
`

/**
 * __useAdminFetchPromptEngineerByIdQuery__
 *
 * To run a query within a React component, call `useAdminFetchPromptEngineerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFetchPromptEngineerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFetchPromptEngineerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useAdminFetchPromptEngineerByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminFetchPromptEngineerByIdQuery,
    AdminFetchPromptEngineerByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdminFetchPromptEngineerByIdQuery,
    AdminFetchPromptEngineerByIdQueryVariables
  >(AdminFetchPromptEngineerByIdDocument, options)
}
export function useAdminFetchPromptEngineerByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminFetchPromptEngineerByIdQuery,
    AdminFetchPromptEngineerByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdminFetchPromptEngineerByIdQuery,
    AdminFetchPromptEngineerByIdQueryVariables
  >(AdminFetchPromptEngineerByIdDocument, options)
}
export type AdminFetchPromptEngineerByIdQueryHookResult = ReturnType<
  typeof useAdminFetchPromptEngineerByIdQuery
>
export type AdminFetchPromptEngineerByIdLazyQueryHookResult = ReturnType<
  typeof useAdminFetchPromptEngineerByIdLazyQuery
>
export type AdminFetchPromptEngineerByIdQueryResult = Apollo.QueryResult<
  AdminFetchPromptEngineerByIdQuery,
  AdminFetchPromptEngineerByIdQueryVariables
>
export function refetchAdminFetchPromptEngineerByIdQuery(
  variables: AdminFetchPromptEngineerByIdQueryVariables
) {
  return { query: AdminFetchPromptEngineerByIdDocument, variables: variables }
}
export const FetchSectionVideoByIdDocument = gql`
  query fetchSectionVideoById($id: String!, $locale: String) {
    sectionsMinimalsVideo(id: $id, locale: $locale) {
      ...sectionsMinimalsVideoFragment
    }
  }
  ${SectionsMinimalsVideoFragmentFragmentDoc}
`

/**
 * __useFetchSectionVideoByIdQuery__
 *
 * To run a query within a React component, call `useFetchSectionVideoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSectionVideoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSectionVideoByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchSectionVideoByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSectionVideoByIdQuery,
    FetchSectionVideoByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchSectionVideoByIdQuery, FetchSectionVideoByIdQueryVariables>(
    FetchSectionVideoByIdDocument,
    options
  )
}
export function useFetchSectionVideoByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSectionVideoByIdQuery,
    FetchSectionVideoByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchSectionVideoByIdQuery, FetchSectionVideoByIdQueryVariables>(
    FetchSectionVideoByIdDocument,
    options
  )
}
export type FetchSectionVideoByIdQueryHookResult = ReturnType<typeof useFetchSectionVideoByIdQuery>
export type FetchSectionVideoByIdLazyQueryHookResult = ReturnType<
  typeof useFetchSectionVideoByIdLazyQuery
>
export type FetchSectionVideoByIdQueryResult = Apollo.QueryResult<
  FetchSectionVideoByIdQuery,
  FetchSectionVideoByIdQueryVariables
>
export function refetchFetchSectionVideoByIdQuery(variables: FetchSectionVideoByIdQueryVariables) {
  return { query: FetchSectionVideoByIdDocument, variables: variables }
}
export const FetchSectionServicesByIdDocument = gql`
  query fetchSectionServicesById($id: String!, $locale: String) {
    sectionsMinimalsServices(id: $id, locale: $locale) {
      ...sectionsMinimalsServicesFragment
    }
  }
  ${SectionsMinimalsServicesFragmentFragmentDoc}
`

/**
 * __useFetchSectionServicesByIdQuery__
 *
 * To run a query within a React component, call `useFetchSectionServicesByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSectionServicesByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSectionServicesByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchSectionServicesByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSectionServicesByIdQuery,
    FetchSectionServicesByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchSectionServicesByIdQuery, FetchSectionServicesByIdQueryVariables>(
    FetchSectionServicesByIdDocument,
    options
  )
}
export function useFetchSectionServicesByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSectionServicesByIdQuery,
    FetchSectionServicesByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchSectionServicesByIdQuery, FetchSectionServicesByIdQueryVariables>(
    FetchSectionServicesByIdDocument,
    options
  )
}
export type FetchSectionServicesByIdQueryHookResult = ReturnType<
  typeof useFetchSectionServicesByIdQuery
>
export type FetchSectionServicesByIdLazyQueryHookResult = ReturnType<
  typeof useFetchSectionServicesByIdLazyQuery
>
export type FetchSectionServicesByIdQueryResult = Apollo.QueryResult<
  FetchSectionServicesByIdQuery,
  FetchSectionServicesByIdQueryVariables
>
export function refetchFetchSectionServicesByIdQuery(
  variables: FetchSectionServicesByIdQueryVariables
) {
  return { query: FetchSectionServicesByIdDocument, variables: variables }
}
export const FetchSectionImagesByIdDocument = gql`
  query fetchSectionImagesById($id: String!, $locale: String) {
    sectionsMinimalsImagesList(id: $id, locale: $locale) {
      ...sectionsMinimalsImagesListFragment
    }
  }
  ${SectionsMinimalsImagesListFragmentFragmentDoc}
`

/**
 * __useFetchSectionImagesByIdQuery__
 *
 * To run a query within a React component, call `useFetchSectionImagesByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSectionImagesByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSectionImagesByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchSectionImagesByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSectionImagesByIdQuery,
    FetchSectionImagesByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchSectionImagesByIdQuery, FetchSectionImagesByIdQueryVariables>(
    FetchSectionImagesByIdDocument,
    options
  )
}
export function useFetchSectionImagesByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSectionImagesByIdQuery,
    FetchSectionImagesByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchSectionImagesByIdQuery, FetchSectionImagesByIdQueryVariables>(
    FetchSectionImagesByIdDocument,
    options
  )
}
export type FetchSectionImagesByIdQueryHookResult = ReturnType<
  typeof useFetchSectionImagesByIdQuery
>
export type FetchSectionImagesByIdLazyQueryHookResult = ReturnType<
  typeof useFetchSectionImagesByIdLazyQuery
>
export type FetchSectionImagesByIdQueryResult = Apollo.QueryResult<
  FetchSectionImagesByIdQuery,
  FetchSectionImagesByIdQueryVariables
>
export function refetchFetchSectionImagesByIdQuery(
  variables: FetchSectionImagesByIdQueryVariables
) {
  return { query: FetchSectionImagesByIdDocument, variables: variables }
}
export const FetchSectionHumanListByIdDocument = gql`
  query fetchSectionHumanListById($id: String!, $locale: String) {
    sectionsMinimalsHumanList(id: $id, locale: $locale) {
      ...sectionsMinimalsHumanListFragment
    }
  }
  ${SectionsMinimalsHumanListFragmentFragmentDoc}
`

/**
 * __useFetchSectionHumanListByIdQuery__
 *
 * To run a query within a React component, call `useFetchSectionHumanListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSectionHumanListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSectionHumanListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchSectionHumanListByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSectionHumanListByIdQuery,
    FetchSectionHumanListByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchSectionHumanListByIdQuery, FetchSectionHumanListByIdQueryVariables>(
    FetchSectionHumanListByIdDocument,
    options
  )
}
export function useFetchSectionHumanListByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSectionHumanListByIdQuery,
    FetchSectionHumanListByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchSectionHumanListByIdQuery,
    FetchSectionHumanListByIdQueryVariables
  >(FetchSectionHumanListByIdDocument, options)
}
export type FetchSectionHumanListByIdQueryHookResult = ReturnType<
  typeof useFetchSectionHumanListByIdQuery
>
export type FetchSectionHumanListByIdLazyQueryHookResult = ReturnType<
  typeof useFetchSectionHumanListByIdLazyQuery
>
export type FetchSectionHumanListByIdQueryResult = Apollo.QueryResult<
  FetchSectionHumanListByIdQuery,
  FetchSectionHumanListByIdQueryVariables
>
export function refetchFetchSectionHumanListByIdQuery(
  variables: FetchSectionHumanListByIdQueryVariables
) {
  return { query: FetchSectionHumanListByIdDocument, variables: variables }
}
export const FetchSectionDetailPersonListByIdDocument = gql`
  query fetchSectionDetailPersonListById($id: String!, $locale: String) {
    sectionsDetailPersonList(id: $id, locale: $locale) {
      ...sectionsDetailPersonListFragment
    }
  }
  ${SectionsDetailPersonListFragmentFragmentDoc}
`

/**
 * __useFetchSectionDetailPersonListByIdQuery__
 *
 * To run a query within a React component, call `useFetchSectionDetailPersonListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSectionDetailPersonListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSectionDetailPersonListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchSectionDetailPersonListByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSectionDetailPersonListByIdQuery,
    FetchSectionDetailPersonListByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchSectionDetailPersonListByIdQuery,
    FetchSectionDetailPersonListByIdQueryVariables
  >(FetchSectionDetailPersonListByIdDocument, options)
}
export function useFetchSectionDetailPersonListByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSectionDetailPersonListByIdQuery,
    FetchSectionDetailPersonListByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchSectionDetailPersonListByIdQuery,
    FetchSectionDetailPersonListByIdQueryVariables
  >(FetchSectionDetailPersonListByIdDocument, options)
}
export type FetchSectionDetailPersonListByIdQueryHookResult = ReturnType<
  typeof useFetchSectionDetailPersonListByIdQuery
>
export type FetchSectionDetailPersonListByIdLazyQueryHookResult = ReturnType<
  typeof useFetchSectionDetailPersonListByIdLazyQuery
>
export type FetchSectionDetailPersonListByIdQueryResult = Apollo.QueryResult<
  FetchSectionDetailPersonListByIdQuery,
  FetchSectionDetailPersonListByIdQueryVariables
>
export function refetchFetchSectionDetailPersonListByIdQuery(
  variables: FetchSectionDetailPersonListByIdQueryVariables
) {
  return { query: FetchSectionDetailPersonListByIdDocument, variables: variables }
}
export const FetchSectionFaqListByIdDocument = gql`
  query fetchSectionFaqListById($id: String!, $locale: String) {
    sectionsFaqList(id: $id, locale: $locale) {
      ...sectionsFaqListFragment
    }
  }
  ${SectionsFaqListFragmentFragmentDoc}
`

/**
 * __useFetchSectionFaqListByIdQuery__
 *
 * To run a query within a React component, call `useFetchSectionFaqListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSectionFaqListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSectionFaqListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchSectionFaqListByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSectionFaqListByIdQuery,
    FetchSectionFaqListByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchSectionFaqListByIdQuery, FetchSectionFaqListByIdQueryVariables>(
    FetchSectionFaqListByIdDocument,
    options
  )
}
export function useFetchSectionFaqListByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSectionFaqListByIdQuery,
    FetchSectionFaqListByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchSectionFaqListByIdQuery, FetchSectionFaqListByIdQueryVariables>(
    FetchSectionFaqListByIdDocument,
    options
  )
}
export type FetchSectionFaqListByIdQueryHookResult = ReturnType<
  typeof useFetchSectionFaqListByIdQuery
>
export type FetchSectionFaqListByIdLazyQueryHookResult = ReturnType<
  typeof useFetchSectionFaqListByIdLazyQuery
>
export type FetchSectionFaqListByIdQueryResult = Apollo.QueryResult<
  FetchSectionFaqListByIdQuery,
  FetchSectionFaqListByIdQueryVariables
>
export function refetchFetchSectionFaqListByIdQuery(
  variables: FetchSectionFaqListByIdQueryVariables
) {
  return { query: FetchSectionFaqListByIdDocument, variables: variables }
}
export const FetchSectionHistoryListByIdDocument = gql`
  query fetchSectionHistoryListById($id: String!, $locale: String) {
    sectionsMinimalsHistoryList(id: $id, locale: $locale) {
      ...sectionsMinimalsHistoryListFragment
    }
  }
  ${SectionsMinimalsHistoryListFragmentFragmentDoc}
`

/**
 * __useFetchSectionHistoryListByIdQuery__
 *
 * To run a query within a React component, call `useFetchSectionHistoryListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSectionHistoryListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSectionHistoryListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchSectionHistoryListByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSectionHistoryListByIdQuery,
    FetchSectionHistoryListByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchSectionHistoryListByIdQuery,
    FetchSectionHistoryListByIdQueryVariables
  >(FetchSectionHistoryListByIdDocument, options)
}
export function useFetchSectionHistoryListByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSectionHistoryListByIdQuery,
    FetchSectionHistoryListByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchSectionHistoryListByIdQuery,
    FetchSectionHistoryListByIdQueryVariables
  >(FetchSectionHistoryListByIdDocument, options)
}
export type FetchSectionHistoryListByIdQueryHookResult = ReturnType<
  typeof useFetchSectionHistoryListByIdQuery
>
export type FetchSectionHistoryListByIdLazyQueryHookResult = ReturnType<
  typeof useFetchSectionHistoryListByIdLazyQuery
>
export type FetchSectionHistoryListByIdQueryResult = Apollo.QueryResult<
  FetchSectionHistoryListByIdQuery,
  FetchSectionHistoryListByIdQueryVariables
>
export function refetchFetchSectionHistoryListByIdQuery(
  variables: FetchSectionHistoryListByIdQueryVariables
) {
  return { query: FetchSectionHistoryListByIdDocument, variables: variables }
}
export const FetchSectionContactListByIdDocument = gql`
  query fetchSectionContactListById($id: String!, $locale: String) {
    sectionsMinimalsContactList(id: $id, locale: $locale) {
      ...sectionsMinimalsContactListFragment
    }
  }
  ${SectionsMinimalsContactListFragmentFragmentDoc}
`

/**
 * __useFetchSectionContactListByIdQuery__
 *
 * To run a query within a React component, call `useFetchSectionContactListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSectionContactListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSectionContactListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchSectionContactListByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSectionContactListByIdQuery,
    FetchSectionContactListByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchSectionContactListByIdQuery,
    FetchSectionContactListByIdQueryVariables
  >(FetchSectionContactListByIdDocument, options)
}
export function useFetchSectionContactListByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSectionContactListByIdQuery,
    FetchSectionContactListByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchSectionContactListByIdQuery,
    FetchSectionContactListByIdQueryVariables
  >(FetchSectionContactListByIdDocument, options)
}
export type FetchSectionContactListByIdQueryHookResult = ReturnType<
  typeof useFetchSectionContactListByIdQuery
>
export type FetchSectionContactListByIdLazyQueryHookResult = ReturnType<
  typeof useFetchSectionContactListByIdLazyQuery
>
export type FetchSectionContactListByIdQueryResult = Apollo.QueryResult<
  FetchSectionContactListByIdQuery,
  FetchSectionContactListByIdQueryVariables
>
export function refetchFetchSectionContactListByIdQuery(
  variables: FetchSectionContactListByIdQueryVariables
) {
  return { query: FetchSectionContactListByIdDocument, variables: variables }
}
export const FetchSectionTimerByIdDocument = gql`
  query fetchSectionTimerById($id: String!, $locale: String) {
    sectionsTimer(id: $id, locale: $locale) {
      ...sectionsTimerFragment
    }
  }
  ${SectionsTimerFragmentFragmentDoc}
`

/**
 * __useFetchSectionTimerByIdQuery__
 *
 * To run a query within a React component, call `useFetchSectionTimerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSectionTimerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSectionTimerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchSectionTimerByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSectionTimerByIdQuery,
    FetchSectionTimerByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchSectionTimerByIdQuery, FetchSectionTimerByIdQueryVariables>(
    FetchSectionTimerByIdDocument,
    options
  )
}
export function useFetchSectionTimerByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSectionTimerByIdQuery,
    FetchSectionTimerByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchSectionTimerByIdQuery, FetchSectionTimerByIdQueryVariables>(
    FetchSectionTimerByIdDocument,
    options
  )
}
export type FetchSectionTimerByIdQueryHookResult = ReturnType<typeof useFetchSectionTimerByIdQuery>
export type FetchSectionTimerByIdLazyQueryHookResult = ReturnType<
  typeof useFetchSectionTimerByIdLazyQuery
>
export type FetchSectionTimerByIdQueryResult = Apollo.QueryResult<
  FetchSectionTimerByIdQuery,
  FetchSectionTimerByIdQueryVariables
>
export function refetchFetchSectionTimerByIdQuery(variables: FetchSectionTimerByIdQueryVariables) {
  return { query: FetchSectionTimerByIdDocument, variables: variables }
}
export const FetchSectionFormByIdDocument = gql`
  query fetchSectionFormById($id: String!, $locale: String) {
    sectionsForm(id: $id, locale: $locale) {
      ...sectionsFormFragment
    }
  }
  ${SectionsFormFragmentFragmentDoc}
`

/**
 * __useFetchSectionFormByIdQuery__
 *
 * To run a query within a React component, call `useFetchSectionFormByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSectionFormByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSectionFormByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchSectionFormByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSectionFormByIdQuery,
    FetchSectionFormByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchSectionFormByIdQuery, FetchSectionFormByIdQueryVariables>(
    FetchSectionFormByIdDocument,
    options
  )
}
export function useFetchSectionFormByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSectionFormByIdQuery,
    FetchSectionFormByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchSectionFormByIdQuery, FetchSectionFormByIdQueryVariables>(
    FetchSectionFormByIdDocument,
    options
  )
}
export type FetchSectionFormByIdQueryHookResult = ReturnType<typeof useFetchSectionFormByIdQuery>
export type FetchSectionFormByIdLazyQueryHookResult = ReturnType<
  typeof useFetchSectionFormByIdLazyQuery
>
export type FetchSectionFormByIdQueryResult = Apollo.QueryResult<
  FetchSectionFormByIdQuery,
  FetchSectionFormByIdQueryVariables
>
export function refetchFetchSectionFormByIdQuery(variables: FetchSectionFormByIdQueryVariables) {
  return { query: FetchSectionFormByIdDocument, variables: variables }
}
export const FetchSectionGalleryByIdDocument = gql`
  query fetchSectionGalleryById($id: String!, $locale: String) {
    sectionsMinimalsGallery(id: $id, locale: $locale) {
      ...sectionsMinimalsGalleryFragment
    }
  }
  ${SectionsMinimalsGalleryFragmentFragmentDoc}
`

/**
 * __useFetchSectionGalleryByIdQuery__
 *
 * To run a query within a React component, call `useFetchSectionGalleryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSectionGalleryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSectionGalleryByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchSectionGalleryByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSectionGalleryByIdQuery,
    FetchSectionGalleryByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchSectionGalleryByIdQuery, FetchSectionGalleryByIdQueryVariables>(
    FetchSectionGalleryByIdDocument,
    options
  )
}
export function useFetchSectionGalleryByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSectionGalleryByIdQuery,
    FetchSectionGalleryByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchSectionGalleryByIdQuery, FetchSectionGalleryByIdQueryVariables>(
    FetchSectionGalleryByIdDocument,
    options
  )
}
export type FetchSectionGalleryByIdQueryHookResult = ReturnType<
  typeof useFetchSectionGalleryByIdQuery
>
export type FetchSectionGalleryByIdLazyQueryHookResult = ReturnType<
  typeof useFetchSectionGalleryByIdLazyQuery
>
export type FetchSectionGalleryByIdQueryResult = Apollo.QueryResult<
  FetchSectionGalleryByIdQuery,
  FetchSectionGalleryByIdQueryVariables
>
export function refetchFetchSectionGalleryByIdQuery(
  variables: FetchSectionGalleryByIdQueryVariables
) {
  return { query: FetchSectionGalleryByIdDocument, variables: variables }
}
export const FetchSectionImageByIdDocument = gql`
  query fetchSectionImageById($id: String!, $locale: String) {
    sectionsImage(id: $id, locale: $locale) {
      ...sectionsImageFragment
    }
  }
  ${SectionsImageFragmentFragmentDoc}
`

/**
 * __useFetchSectionImageByIdQuery__
 *
 * To run a query within a React component, call `useFetchSectionImageByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSectionImageByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSectionImageByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchSectionImageByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSectionImageByIdQuery,
    FetchSectionImageByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchSectionImageByIdQuery, FetchSectionImageByIdQueryVariables>(
    FetchSectionImageByIdDocument,
    options
  )
}
export function useFetchSectionImageByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSectionImageByIdQuery,
    FetchSectionImageByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchSectionImageByIdQuery, FetchSectionImageByIdQueryVariables>(
    FetchSectionImageByIdDocument,
    options
  )
}
export type FetchSectionImageByIdQueryHookResult = ReturnType<typeof useFetchSectionImageByIdQuery>
export type FetchSectionImageByIdLazyQueryHookResult = ReturnType<
  typeof useFetchSectionImageByIdLazyQuery
>
export type FetchSectionImageByIdQueryResult = Apollo.QueryResult<
  FetchSectionImageByIdQuery,
  FetchSectionImageByIdQueryVariables
>
export function refetchFetchSectionImageByIdQuery(variables: FetchSectionImageByIdQueryVariables) {
  return { query: FetchSectionImageByIdDocument, variables: variables }
}
export const FetchSectionImagesByIdForGalleryDocument = gql`
  query fetchSectionImagesByIdForGallery($id: String!, $locale: String) {
    sectionsMinimalsImagesList(id: $id, locale: $locale) {
      ...sectionsMinimalsImagesListForGallery
    }
  }
  ${SectionsMinimalsImagesListForGalleryFragmentDoc}
`

/**
 * __useFetchSectionImagesByIdForGalleryQuery__
 *
 * To run a query within a React component, call `useFetchSectionImagesByIdForGalleryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSectionImagesByIdForGalleryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSectionImagesByIdForGalleryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchSectionImagesByIdForGalleryQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSectionImagesByIdForGalleryQuery,
    FetchSectionImagesByIdForGalleryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchSectionImagesByIdForGalleryQuery,
    FetchSectionImagesByIdForGalleryQueryVariables
  >(FetchSectionImagesByIdForGalleryDocument, options)
}
export function useFetchSectionImagesByIdForGalleryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSectionImagesByIdForGalleryQuery,
    FetchSectionImagesByIdForGalleryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchSectionImagesByIdForGalleryQuery,
    FetchSectionImagesByIdForGalleryQueryVariables
  >(FetchSectionImagesByIdForGalleryDocument, options)
}
export type FetchSectionImagesByIdForGalleryQueryHookResult = ReturnType<
  typeof useFetchSectionImagesByIdForGalleryQuery
>
export type FetchSectionImagesByIdForGalleryLazyQueryHookResult = ReturnType<
  typeof useFetchSectionImagesByIdForGalleryLazyQuery
>
export type FetchSectionImagesByIdForGalleryQueryResult = Apollo.QueryResult<
  FetchSectionImagesByIdForGalleryQuery,
  FetchSectionImagesByIdForGalleryQueryVariables
>
export function refetchFetchSectionImagesByIdForGalleryQuery(
  variables: FetchSectionImagesByIdForGalleryQueryVariables
) {
  return { query: FetchSectionImagesByIdForGalleryDocument, variables: variables }
}
export const FetchAllSectionFormDocument = gql`
  query fetchAllSectionForm {
    sectionsFormCollection {
      items {
        sys {
          id
        }
        name
      }
    }
  }
`

/**
 * __useFetchAllSectionFormQuery__
 *
 * To run a query within a React component, call `useFetchAllSectionFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllSectionFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllSectionFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAllSectionFormQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchAllSectionFormQuery, FetchAllSectionFormQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchAllSectionFormQuery, FetchAllSectionFormQueryVariables>(
    FetchAllSectionFormDocument,
    options
  )
}
export function useFetchAllSectionFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAllSectionFormQuery,
    FetchAllSectionFormQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchAllSectionFormQuery, FetchAllSectionFormQueryVariables>(
    FetchAllSectionFormDocument,
    options
  )
}
export type FetchAllSectionFormQueryHookResult = ReturnType<typeof useFetchAllSectionFormQuery>
export type FetchAllSectionFormLazyQueryHookResult = ReturnType<
  typeof useFetchAllSectionFormLazyQuery
>
export type FetchAllSectionFormQueryResult = Apollo.QueryResult<
  FetchAllSectionFormQuery,
  FetchAllSectionFormQueryVariables
>
export function refetchFetchAllSectionFormQuery(variables?: FetchAllSectionFormQueryVariables) {
  return { query: FetchAllSectionFormDocument, variables: variables }
}
export const FetchSectionFormComponentsByFormIdDocument = gql`
  query fetchSectionFormComponentsByFormId($id: String!, $locale: String) {
    sectionsForm(id: $id, locale: $locale) {
      componentsCollection {
        items {
          sys {
            id
          }
          type
          label
        }
      }
    }
  }
`

/**
 * __useFetchSectionFormComponentsByFormIdQuery__
 *
 * To run a query within a React component, call `useFetchSectionFormComponentsByFormIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSectionFormComponentsByFormIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSectionFormComponentsByFormIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useFetchSectionFormComponentsByFormIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchSectionFormComponentsByFormIdQuery,
    FetchSectionFormComponentsByFormIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FetchSectionFormComponentsByFormIdQuery,
    FetchSectionFormComponentsByFormIdQueryVariables
  >(FetchSectionFormComponentsByFormIdDocument, options)
}
export function useFetchSectionFormComponentsByFormIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchSectionFormComponentsByFormIdQuery,
    FetchSectionFormComponentsByFormIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FetchSectionFormComponentsByFormIdQuery,
    FetchSectionFormComponentsByFormIdQueryVariables
  >(FetchSectionFormComponentsByFormIdDocument, options)
}
export type FetchSectionFormComponentsByFormIdQueryHookResult = ReturnType<
  typeof useFetchSectionFormComponentsByFormIdQuery
>
export type FetchSectionFormComponentsByFormIdLazyQueryHookResult = ReturnType<
  typeof useFetchSectionFormComponentsByFormIdLazyQuery
>
export type FetchSectionFormComponentsByFormIdQueryResult = Apollo.QueryResult<
  FetchSectionFormComponentsByFormIdQuery,
  FetchSectionFormComponentsByFormIdQueryVariables
>
export function refetchFetchSectionFormComponentsByFormIdQuery(
  variables: FetchSectionFormComponentsByFormIdQueryVariables
) {
  return { query: FetchSectionFormComponentsByFormIdDocument, variables: variables }
}
export const FetchPromptsDocument = gql`
  query fetchPrompts {
    prompts {
      id
      promptHint
      promptTeaser
    }
  }
`

/**
 * __useFetchPromptsQuery__
 *
 * To run a query within a React component, call `useFetchPromptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPromptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPromptsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchPromptsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchPromptsQuery, FetchPromptsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchPromptsQuery, FetchPromptsQueryVariables>(
    FetchPromptsDocument,
    options
  )
}
export function useFetchPromptsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchPromptsQuery, FetchPromptsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchPromptsQuery, FetchPromptsQueryVariables>(
    FetchPromptsDocument,
    options
  )
}
export type FetchPromptsQueryHookResult = ReturnType<typeof useFetchPromptsQuery>
export type FetchPromptsLazyQueryHookResult = ReturnType<typeof useFetchPromptsLazyQuery>
export type FetchPromptsQueryResult = Apollo.QueryResult<
  FetchPromptsQuery,
  FetchPromptsQueryVariables
>
export function refetchFetchPromptsQuery(variables?: FetchPromptsQueryVariables) {
  return { query: FetchPromptsDocument, variables: variables }
}
export const VisitorGeneratePromptDocument = gql`
  query visitorGeneratePrompt(
    $language: PromptSupportingLanguageMap!
    $prompt: String!
    $promptId: String!
  ) {
    generatePrompt(prompt: $prompt, promptId: $promptId, language: $language) {
      ...PromptResponse
    }
  }
  ${PromptResponseFragmentDoc}
`

/**
 * __useVisitorGeneratePromptQuery__
 *
 * To run a query within a React component, call `useVisitorGeneratePromptQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitorGeneratePromptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitorGeneratePromptQuery({
 *   variables: {
 *      language: // value for 'language'
 *      prompt: // value for 'prompt'
 *      promptId: // value for 'promptId'
 *   },
 * });
 */
export function useVisitorGeneratePromptQuery(
  baseOptions: Apollo.QueryHookOptions<
    VisitorGeneratePromptQuery,
    VisitorGeneratePromptQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VisitorGeneratePromptQuery, VisitorGeneratePromptQueryVariables>(
    VisitorGeneratePromptDocument,
    options
  )
}
export function useVisitorGeneratePromptLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VisitorGeneratePromptQuery,
    VisitorGeneratePromptQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<VisitorGeneratePromptQuery, VisitorGeneratePromptQueryVariables>(
    VisitorGeneratePromptDocument,
    options
  )
}
export type VisitorGeneratePromptQueryHookResult = ReturnType<typeof useVisitorGeneratePromptQuery>
export type VisitorGeneratePromptLazyQueryHookResult = ReturnType<
  typeof useVisitorGeneratePromptLazyQuery
>
export type VisitorGeneratePromptQueryResult = Apollo.QueryResult<
  VisitorGeneratePromptQuery,
  VisitorGeneratePromptQueryVariables
>
export function refetchVisitorGeneratePromptQuery(variables: VisitorGeneratePromptQueryVariables) {
  return { query: VisitorGeneratePromptDocument, variables: variables }
}
export const VisitorGeneratePromptWithTagsDocument = gql`
  query visitorGeneratePromptWithTags(
    $language: PromptSupportingLanguageMap!
    $prompt: String!
    $promptId: String!
    $tags: [String!]!
  ) {
    generatePromptWithTags(prompt: $prompt, promptId: $promptId, language: $language, tags: $tags) {
      ...PromptResponse
    }
  }
  ${PromptResponseFragmentDoc}
`

/**
 * __useVisitorGeneratePromptWithTagsQuery__
 *
 * To run a query within a React component, call `useVisitorGeneratePromptWithTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitorGeneratePromptWithTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitorGeneratePromptWithTagsQuery({
 *   variables: {
 *      language: // value for 'language'
 *      prompt: // value for 'prompt'
 *      promptId: // value for 'promptId'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useVisitorGeneratePromptWithTagsQuery(
  baseOptions: Apollo.QueryHookOptions<
    VisitorGeneratePromptWithTagsQuery,
    VisitorGeneratePromptWithTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    VisitorGeneratePromptWithTagsQuery,
    VisitorGeneratePromptWithTagsQueryVariables
  >(VisitorGeneratePromptWithTagsDocument, options)
}
export function useVisitorGeneratePromptWithTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VisitorGeneratePromptWithTagsQuery,
    VisitorGeneratePromptWithTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    VisitorGeneratePromptWithTagsQuery,
    VisitorGeneratePromptWithTagsQueryVariables
  >(VisitorGeneratePromptWithTagsDocument, options)
}
export type VisitorGeneratePromptWithTagsQueryHookResult = ReturnType<
  typeof useVisitorGeneratePromptWithTagsQuery
>
export type VisitorGeneratePromptWithTagsLazyQueryHookResult = ReturnType<
  typeof useVisitorGeneratePromptWithTagsLazyQuery
>
export type VisitorGeneratePromptWithTagsQueryResult = Apollo.QueryResult<
  VisitorGeneratePromptWithTagsQuery,
  VisitorGeneratePromptWithTagsQueryVariables
>
export function refetchVisitorGeneratePromptWithTagsQuery(
  variables: VisitorGeneratePromptWithTagsQueryVariables
) {
  return { query: VisitorGeneratePromptWithTagsDocument, variables: variables }
}
export const VisitorFetchMidjourneyImagesDocument = gql`
  query visitorFetchMidjourneyImages(
    $limit: Int!
    $offset: Int!
    $where: midjourneyImages_bool_exp
    $order_by: [midjourneyImages_order_by!] = { createdAt: desc }
  ) {
    midjourneyImages(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
      id
      name
      prompt
      url
      width
      size
      height
      contentType
      cloudinaryPublicId
      attachment
    }
    midjourneyImages_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

/**
 * __useVisitorFetchMidjourneyImagesQuery__
 *
 * To run a query within a React component, call `useVisitorFetchMidjourneyImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitorFetchMidjourneyImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitorFetchMidjourneyImagesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useVisitorFetchMidjourneyImagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    VisitorFetchMidjourneyImagesQuery,
    VisitorFetchMidjourneyImagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    VisitorFetchMidjourneyImagesQuery,
    VisitorFetchMidjourneyImagesQueryVariables
  >(VisitorFetchMidjourneyImagesDocument, options)
}
export function useVisitorFetchMidjourneyImagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VisitorFetchMidjourneyImagesQuery,
    VisitorFetchMidjourneyImagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    VisitorFetchMidjourneyImagesQuery,
    VisitorFetchMidjourneyImagesQueryVariables
  >(VisitorFetchMidjourneyImagesDocument, options)
}
export type VisitorFetchMidjourneyImagesQueryHookResult = ReturnType<
  typeof useVisitorFetchMidjourneyImagesQuery
>
export type VisitorFetchMidjourneyImagesLazyQueryHookResult = ReturnType<
  typeof useVisitorFetchMidjourneyImagesLazyQuery
>
export type VisitorFetchMidjourneyImagesQueryResult = Apollo.QueryResult<
  VisitorFetchMidjourneyImagesQuery,
  VisitorFetchMidjourneyImagesQueryVariables
>
export function refetchVisitorFetchMidjourneyImagesQuery(
  variables: VisitorFetchMidjourneyImagesQueryVariables
) {
  return { query: VisitorFetchMidjourneyImagesDocument, variables: variables }
}
export const VisitorFetchMidjourneyImagesByPkDocument = gql`
  query visitorFetchMidjourneyImagesByPk($id: uuid!) {
    midjourneyImages_by_pk(id: $id) {
      ...midjourneyImages
    }
  }
  ${MidjourneyImagesFragmentDoc}
`

/**
 * __useVisitorFetchMidjourneyImagesByPkQuery__
 *
 * To run a query within a React component, call `useVisitorFetchMidjourneyImagesByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitorFetchMidjourneyImagesByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitorFetchMidjourneyImagesByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVisitorFetchMidjourneyImagesByPkQuery(
  baseOptions: Apollo.QueryHookOptions<
    VisitorFetchMidjourneyImagesByPkQuery,
    VisitorFetchMidjourneyImagesByPkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    VisitorFetchMidjourneyImagesByPkQuery,
    VisitorFetchMidjourneyImagesByPkQueryVariables
  >(VisitorFetchMidjourneyImagesByPkDocument, options)
}
export function useVisitorFetchMidjourneyImagesByPkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VisitorFetchMidjourneyImagesByPkQuery,
    VisitorFetchMidjourneyImagesByPkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    VisitorFetchMidjourneyImagesByPkQuery,
    VisitorFetchMidjourneyImagesByPkQueryVariables
  >(VisitorFetchMidjourneyImagesByPkDocument, options)
}
export type VisitorFetchMidjourneyImagesByPkQueryHookResult = ReturnType<
  typeof useVisitorFetchMidjourneyImagesByPkQuery
>
export type VisitorFetchMidjourneyImagesByPkLazyQueryHookResult = ReturnType<
  typeof useVisitorFetchMidjourneyImagesByPkLazyQuery
>
export type VisitorFetchMidjourneyImagesByPkQueryResult = Apollo.QueryResult<
  VisitorFetchMidjourneyImagesByPkQuery,
  VisitorFetchMidjourneyImagesByPkQueryVariables
>
export function refetchVisitorFetchMidjourneyImagesByPkQuery(
  variables: VisitorFetchMidjourneyImagesByPkQueryVariables
) {
  return { query: VisitorFetchMidjourneyImagesByPkDocument, variables: variables }
}
export const VisitorFetchPromptUsagesDocument = gql`
  query visitorFetchPromptUsages(
    $where: promptUsages_bool_exp = {}
    $order_by: [promptUsages_order_by!] = { createdAt: desc }
    $limit: Int
  ) {
    promptUsages(where: $where, order_by: $order_by, limit: $limit) {
      ...visitorPromptUsages
    }
  }
  ${VisitorPromptUsagesFragmentDoc}
`

/**
 * __useVisitorFetchPromptUsagesQuery__
 *
 * To run a query within a React component, call `useVisitorFetchPromptUsagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitorFetchPromptUsagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitorFetchPromptUsagesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useVisitorFetchPromptUsagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    VisitorFetchPromptUsagesQuery,
    VisitorFetchPromptUsagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VisitorFetchPromptUsagesQuery, VisitorFetchPromptUsagesQueryVariables>(
    VisitorFetchPromptUsagesDocument,
    options
  )
}
export function useVisitorFetchPromptUsagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VisitorFetchPromptUsagesQuery,
    VisitorFetchPromptUsagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<VisitorFetchPromptUsagesQuery, VisitorFetchPromptUsagesQueryVariables>(
    VisitorFetchPromptUsagesDocument,
    options
  )
}
export type VisitorFetchPromptUsagesQueryHookResult = ReturnType<
  typeof useVisitorFetchPromptUsagesQuery
>
export type VisitorFetchPromptUsagesLazyQueryHookResult = ReturnType<
  typeof useVisitorFetchPromptUsagesLazyQuery
>
export type VisitorFetchPromptUsagesQueryResult = Apollo.QueryResult<
  VisitorFetchPromptUsagesQuery,
  VisitorFetchPromptUsagesQueryVariables
>
export function refetchVisitorFetchPromptUsagesQuery(
  variables?: VisitorFetchPromptUsagesQueryVariables
) {
  return { query: VisitorFetchPromptUsagesDocument, variables: variables }
}
export const VisitorFetchAllImagesIdDocument = gql`
  query visitorFetchAllImagesId {
    midjourneyImages {
      id
    }
  }
`

/**
 * __useVisitorFetchAllImagesIdQuery__
 *
 * To run a query within a React component, call `useVisitorFetchAllImagesIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitorFetchAllImagesIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitorFetchAllImagesIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useVisitorFetchAllImagesIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    VisitorFetchAllImagesIdQuery,
    VisitorFetchAllImagesIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VisitorFetchAllImagesIdQuery, VisitorFetchAllImagesIdQueryVariables>(
    VisitorFetchAllImagesIdDocument,
    options
  )
}
export function useVisitorFetchAllImagesIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VisitorFetchAllImagesIdQuery,
    VisitorFetchAllImagesIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<VisitorFetchAllImagesIdQuery, VisitorFetchAllImagesIdQueryVariables>(
    VisitorFetchAllImagesIdDocument,
    options
  )
}
export type VisitorFetchAllImagesIdQueryHookResult = ReturnType<
  typeof useVisitorFetchAllImagesIdQuery
>
export type VisitorFetchAllImagesIdLazyQueryHookResult = ReturnType<
  typeof useVisitorFetchAllImagesIdLazyQuery
>
export type VisitorFetchAllImagesIdQueryResult = Apollo.QueryResult<
  VisitorFetchAllImagesIdQuery,
  VisitorFetchAllImagesIdQueryVariables
>
export function refetchVisitorFetchAllImagesIdQuery(
  variables?: VisitorFetchAllImagesIdQueryVariables
) {
  return { query: VisitorFetchAllImagesIdDocument, variables: variables }
}
export const VisitorFetchImagesByIdsDocument = gql`
  query visitorFetchImagesByIds($imagesId: [uuid!]!) {
    midjourneyImages(where: { id: { _in: $imagesId } }) {
      id
      cloudinaryPublicId
    }
  }
`

/**
 * __useVisitorFetchImagesByIdsQuery__
 *
 * To run a query within a React component, call `useVisitorFetchImagesByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitorFetchImagesByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitorFetchImagesByIdsQuery({
 *   variables: {
 *      imagesId: // value for 'imagesId'
 *   },
 * });
 */
export function useVisitorFetchImagesByIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    VisitorFetchImagesByIdsQuery,
    VisitorFetchImagesByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VisitorFetchImagesByIdsQuery, VisitorFetchImagesByIdsQueryVariables>(
    VisitorFetchImagesByIdsDocument,
    options
  )
}
export function useVisitorFetchImagesByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VisitorFetchImagesByIdsQuery,
    VisitorFetchImagesByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<VisitorFetchImagesByIdsQuery, VisitorFetchImagesByIdsQueryVariables>(
    VisitorFetchImagesByIdsDocument,
    options
  )
}
export type VisitorFetchImagesByIdsQueryHookResult = ReturnType<
  typeof useVisitorFetchImagesByIdsQuery
>
export type VisitorFetchImagesByIdsLazyQueryHookResult = ReturnType<
  typeof useVisitorFetchImagesByIdsLazyQuery
>
export type VisitorFetchImagesByIdsQueryResult = Apollo.QueryResult<
  VisitorFetchImagesByIdsQuery,
  VisitorFetchImagesByIdsQueryVariables
>
export function refetchVisitorFetchImagesByIdsQuery(
  variables: VisitorFetchImagesByIdsQueryVariables
) {
  return { query: VisitorFetchImagesByIdsDocument, variables: variables }
}
export type AssetKeySpecifier = (
  | 'contentType'
  | 'contentfulMetadata'
  | 'description'
  | 'fileName'
  | 'height'
  | 'linkedFrom'
  | 'size'
  | 'sys'
  | 'title'
  | 'url'
  | 'width'
  | AssetKeySpecifier
)[]
export type AssetFieldPolicy = {
  contentType?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  fileName?: FieldPolicy<any> | FieldReadFunction<any>
  height?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  size?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
  url?: FieldPolicy<any> | FieldReadFunction<any>
  width?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AssetCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | AssetCollectionKeySpecifier
)[]
export type AssetCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AssetLinkingCollectionsKeySpecifier = (
  | 'authorsCollection'
  | 'blogsCollection'
  | 'entryCollection'
  | 'erc721TokenMetadataCollection'
  | 'productsCollection'
  | 'rootSettingsCollection'
  | 'sectionsDetailPersonComponentCollection'
  | 'sectionsFeaturesListComponentCollection'
  | 'sectionsHeroCollection'
  | 'sectionsImageCollection'
  | 'sectionsMinimalsHistoryComponentCollection'
  | 'sectionsMinimalsHumanComponentCollection'
  | 'sectionsMinimalsImagesListCollection'
  | 'sectionsMinimalsVideoCollection'
  | 'sectionsTimerCollection'
  | 'smartContractErc721Collection'
  | 'smartContractErc721TokenCollection'
  | AssetLinkingCollectionsKeySpecifier
)[]
export type AssetLinkingCollectionsFieldPolicy = {
  authorsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  blogsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  erc721TokenMetadataCollection?: FieldPolicy<any> | FieldReadFunction<any>
  productsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  rootSettingsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsDetailPersonComponentCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsFeaturesListComponentCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsHeroCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsImageCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsHistoryComponentCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsHumanComponentCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsImagesListCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsVideoCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsTimerCollection?: FieldPolicy<any> | FieldReadFunction<any>
  smartContractErc721Collection?: FieldPolicy<any> | FieldReadFunction<any>
  smartContractErc721TokenCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AuthorsKeySpecifier = (
  | 'blogIdeas'
  | 'blogIdeasRelatedCategoriesCollection'
  | 'blogIdeasRelatedImagesCollection'
  | 'blogIdeasRelatedTagsCollection'
  | 'contentfulMetadata'
  | 'generatedBlogState'
  | 'linkedFrom'
  | 'name'
  | 'profileImage'
  | 'sys'
  | 'useBlogAutoGenerator'
  | AuthorsKeySpecifier
)[]
export type AuthorsFieldPolicy = {
  blogIdeas?: FieldPolicy<any> | FieldReadFunction<any>
  blogIdeasRelatedCategoriesCollection?: FieldPolicy<any> | FieldReadFunction<any>
  blogIdeasRelatedImagesCollection?: FieldPolicy<any> | FieldReadFunction<any>
  blogIdeasRelatedTagsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  generatedBlogState?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  profileImage?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  useBlogAutoGenerator?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AuthorsBlogIdeasRelatedCategoriesCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | AuthorsBlogIdeasRelatedCategoriesCollectionKeySpecifier
)[]
export type AuthorsBlogIdeasRelatedCategoriesCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AuthorsBlogIdeasRelatedTagsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | AuthorsBlogIdeasRelatedTagsCollectionKeySpecifier
)[]
export type AuthorsBlogIdeasRelatedTagsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AuthorsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | AuthorsCollectionKeySpecifier
)[]
export type AuthorsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AuthorsLinkingCollectionsKeySpecifier = (
  | 'blogsCollection'
  | 'entryCollection'
  | AuthorsLinkingCollectionsKeySpecifier
)[]
export type AuthorsLinkingCollectionsFieldPolicy = {
  blogsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AutoContentKeySpecifier = (
  | 'htmlTag'
  | 'originalText'
  | 'purpose'
  | 'text'
  | 'useCase'
  | 'useCaseId'
  | 'useCaseSlug'
  | AutoContentKeySpecifier
)[]
export type AutoContentFieldPolicy = {
  htmlTag?: FieldPolicy<any> | FieldReadFunction<any>
  originalText?: FieldPolicy<any> | FieldReadFunction<any>
  purpose?: FieldPolicy<any> | FieldReadFunction<any>
  text?: FieldPolicy<any> | FieldReadFunction<any>
  useCase?: FieldPolicy<any> | FieldReadFunction<any>
  useCaseId?: FieldPolicy<any> | FieldReadFunction<any>
  useCaseSlug?: FieldPolicy<any> | FieldReadFunction<any>
}
export type AutoGenArticleResponseKeySpecifier = (
  | 'contents'
  | 'contentsWithoutHtml'
  | 'keywords'
  | 'seoInfo'
  | AutoGenArticleResponseKeySpecifier
)[]
export type AutoGenArticleResponseFieldPolicy = {
  contents?: FieldPolicy<any> | FieldReadFunction<any>
  contentsWithoutHtml?: FieldPolicy<any> | FieldReadFunction<any>
  keywords?: FieldPolicy<any> | FieldReadFunction<any>
  seoInfo?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BlogsKeySpecifier = (
  | 'author'
  | 'callToActionLink'
  | 'content'
  | 'contentfulMetadata'
  | 'coverImage'
  | 'linkedFrom'
  | 'previewImage'
  | 'seoMetadataItemsCollection'
  | 'sys'
  | 'tagsCollection'
  | 'title'
  | BlogsKeySpecifier
)[]
export type BlogsFieldPolicy = {
  author?: FieldPolicy<any> | FieldReadFunction<any>
  callToActionLink?: FieldPolicy<any> | FieldReadFunction<any>
  content?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  coverImage?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  previewImage?: FieldPolicy<any> | FieldReadFunction<any>
  seoMetadataItemsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  tagsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BlogsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | BlogsCollectionKeySpecifier
)[]
export type BlogsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BlogsContentKeySpecifier = ('json' | 'links' | BlogsContentKeySpecifier)[]
export type BlogsContentFieldPolicy = {
  json?: FieldPolicy<any> | FieldReadFunction<any>
  links?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BlogsContentAssetsKeySpecifier = (
  | 'block'
  | 'hyperlink'
  | BlogsContentAssetsKeySpecifier
)[]
export type BlogsContentAssetsFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
  hyperlink?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BlogsContentEntriesKeySpecifier = (
  | 'block'
  | 'hyperlink'
  | 'inline'
  | BlogsContentEntriesKeySpecifier
)[]
export type BlogsContentEntriesFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
  hyperlink?: FieldPolicy<any> | FieldReadFunction<any>
  inline?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BlogsContentLinksKeySpecifier = (
  | 'assets'
  | 'entries'
  | 'resources'
  | BlogsContentLinksKeySpecifier
)[]
export type BlogsContentLinksFieldPolicy = {
  assets?: FieldPolicy<any> | FieldReadFunction<any>
  entries?: FieldPolicy<any> | FieldReadFunction<any>
  resources?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BlogsContentResourcesKeySpecifier = ('block' | BlogsContentResourcesKeySpecifier)[]
export type BlogsContentResourcesFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BlogsLinkingCollectionsKeySpecifier = (
  | 'categoriesCollection'
  | 'entryCollection'
  | BlogsLinkingCollectionsKeySpecifier
)[]
export type BlogsLinkingCollectionsFieldPolicy = {
  categoriesCollection?: FieldPolicy<any> | FieldReadFunction<any>
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BlogsSeoMetadataItemsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | BlogsSeoMetadataItemsCollectionKeySpecifier
)[]
export type BlogsSeoMetadataItemsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type BlogsTagsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | BlogsTagsCollectionKeySpecifier
)[]
export type BlogsTagsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CallToActionLinkKeySpecifier = (
  | 'contentfulMetadata'
  | 'link'
  | 'linkedFrom'
  | 'sys'
  | 'title'
  | CallToActionLinkKeySpecifier
)[]
export type CallToActionLinkFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  link?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CallToActionLinkCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | CallToActionLinkCollectionKeySpecifier
)[]
export type CallToActionLinkCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CallToActionLinkLinkingCollectionsKeySpecifier = (
  | 'blogsCollection'
  | 'entryCollection'
  | 'productsCollection'
  | 'rootSettingsCollection'
  | 'sectionsMinimalsVideoCollection'
  | CallToActionLinkLinkingCollectionsKeySpecifier
)[]
export type CallToActionLinkLinkingCollectionsFieldPolicy = {
  blogsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  productsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  rootSettingsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsVideoCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CategoriesKeySpecifier = (
  | 'blogsCollection'
  | 'contentfulMetadata'
  | 'linkedFrom'
  | 'sys'
  | 'title'
  | CategoriesKeySpecifier
)[]
export type CategoriesFieldPolicy = {
  blogsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CategoriesBlogsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | CategoriesBlogsCollectionKeySpecifier
)[]
export type CategoriesBlogsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CategoriesCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | CategoriesCollectionKeySpecifier
)[]
export type CategoriesCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CategoriesLinkingCollectionsKeySpecifier = (
  | 'authorsCollection'
  | 'entryCollection'
  | CategoriesLinkingCollectionsKeySpecifier
)[]
export type CategoriesLinkingCollectionsFieldPolicy = {
  authorsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ChromeExtensionPromptResponseKeySpecifier = (
  | 'category'
  | 'createdAt'
  | 'id'
  | 'prompt'
  | 'promptHint'
  | 'promptTeaser'
  | 'statistics'
  | 'title'
  | 'updatedAt'
  | ChromeExtensionPromptResponseKeySpecifier
)[]
export type ChromeExtensionPromptResponseFieldPolicy = {
  category?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  prompt?: FieldPolicy<any> | FieldReadFunction<any>
  promptHint?: FieldPolicy<any> | FieldReadFunction<any>
  promptTeaser?: FieldPolicy<any> | FieldReadFunction<any>
  statistics?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ContentfulMetadataKeySpecifier = ('tags' | ContentfulMetadataKeySpecifier)[]
export type ContentfulMetadataFieldPolicy = {
  tags?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ContentfulTagKeySpecifier = ('id' | 'name' | ContentfulTagKeySpecifier)[]
export type ContentfulTagFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ContextInputKeySpecifier = (
  | '_id'
  | 'inputMaximumCharacters'
  | 'inputType'
  | 'isRequired'
  | 'keyLabel'
  | 'label'
  | 'placeholder'
  | ContextInputKeySpecifier
)[]
export type ContextInputFieldPolicy = {
  _id?: FieldPolicy<any> | FieldReadFunction<any>
  inputMaximumCharacters?: FieldPolicy<any> | FieldReadFunction<any>
  inputType?: FieldPolicy<any> | FieldReadFunction<any>
  isRequired?: FieldPolicy<any> | FieldReadFunction<any>
  keyLabel?: FieldPolicy<any> | FieldReadFunction<any>
  label?: FieldPolicy<any> | FieldReadFunction<any>
  placeholder?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CreatePortalSessionKeySpecifier = ('redirectUrl' | CreatePortalSessionKeySpecifier)[]
export type CreatePortalSessionFieldPolicy = {
  redirectUrl?: FieldPolicy<any> | FieldReadFunction<any>
}
export type CreateStripeCheckoutSessionKeySpecifier = (
  | 'redirectUrl'
  | CreateStripeCheckoutSessionKeySpecifier
)[]
export type CreateStripeCheckoutSessionFieldPolicy = {
  redirectUrl?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DeletePromptResponseKeySpecifier = (
  | 'affected_rows'
  | DeletePromptResponseKeySpecifier
)[]
export type DeletePromptResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>
}
export type DislikePromptResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | DislikePromptResponseKeySpecifier
)[]
export type DislikePromptResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>
  returning?: FieldPolicy<any> | FieldReadFunction<any>
}
export type EmbeddingsKeySpecifier = (
  | 'contentfulMetadata'
  | 'embeddingLink'
  | 'linkedFrom'
  | 'sys'
  | EmbeddingsKeySpecifier
)[]
export type EmbeddingsFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  embeddingLink?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
}
export type EmbeddingsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | EmbeddingsCollectionKeySpecifier
)[]
export type EmbeddingsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type EmbeddingsLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | EmbeddingsLinkingCollectionsKeySpecifier
)[]
export type EmbeddingsLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type EntryKeySpecifier = ('contentfulMetadata' | 'sys' | EntryKeySpecifier)[]
export type EntryFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
}
export type EntryCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | EntryCollectionKeySpecifier
)[]
export type EntryCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Erc721TokenMetadataKeySpecifier = (
  | 'animationUrl'
  | 'attributesCollection'
  | 'backgroundColor'
  | 'contentfulMetadata'
  | 'description'
  | 'externalUrl'
  | 'image'
  | 'linkedFrom'
  | 'name'
  | 'sys'
  | 'tokenId'
  | 'youtubeUrl'
  | Erc721TokenMetadataKeySpecifier
)[]
export type Erc721TokenMetadataFieldPolicy = {
  animationUrl?: FieldPolicy<any> | FieldReadFunction<any>
  attributesCollection?: FieldPolicy<any> | FieldReadFunction<any>
  backgroundColor?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  externalUrl?: FieldPolicy<any> | FieldReadFunction<any>
  image?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  tokenId?: FieldPolicy<any> | FieldReadFunction<any>
  youtubeUrl?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Erc721TokenMetadataAttributeTraitTypeKeySpecifier = (
  | 'contentfulMetadata'
  | 'linkedFrom'
  | 'sys'
  | 'title'
  | Erc721TokenMetadataAttributeTraitTypeKeySpecifier
)[]
export type Erc721TokenMetadataAttributeTraitTypeFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Erc721TokenMetadataAttributeTraitTypeCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | Erc721TokenMetadataAttributeTraitTypeCollectionKeySpecifier
)[]
export type Erc721TokenMetadataAttributeTraitTypeCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Erc721TokenMetadataAttributeTraitTypeLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'erc721TokenMetadataAttributesTextCollection'
  | Erc721TokenMetadataAttributeTraitTypeLinkingCollectionsKeySpecifier
)[]
export type Erc721TokenMetadataAttributeTraitTypeLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  erc721TokenMetadataAttributesTextCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Erc721TokenMetadataAttributesCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | Erc721TokenMetadataAttributesCollectionKeySpecifier
)[]
export type Erc721TokenMetadataAttributesCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Erc721TokenMetadataAttributesTextKeySpecifier = (
  | 'contentfulMetadata'
  | 'displayType'
  | 'linkedFrom'
  | 'sys'
  | 'traitType'
  | 'value'
  | Erc721TokenMetadataAttributesTextKeySpecifier
)[]
export type Erc721TokenMetadataAttributesTextFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  displayType?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  traitType?: FieldPolicy<any> | FieldReadFunction<any>
  value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Erc721TokenMetadataAttributesTextCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | Erc721TokenMetadataAttributesTextCollectionKeySpecifier
)[]
export type Erc721TokenMetadataAttributesTextCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Erc721TokenMetadataAttributesTextLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'erc721TokenMetadataCollection'
  | Erc721TokenMetadataAttributesTextLinkingCollectionsKeySpecifier
)[]
export type Erc721TokenMetadataAttributesTextLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  erc721TokenMetadataCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Erc721TokenMetadataCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | Erc721TokenMetadataCollectionKeySpecifier
)[]
export type Erc721TokenMetadataCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type Erc721TokenMetadataLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'smartContractErc721Collection'
  | Erc721TokenMetadataLinkingCollectionsKeySpecifier
)[]
export type Erc721TokenMetadataLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  smartContractErc721Collection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GenerateImageResponseKeySpecifier = (
  | 'formattedPrompt'
  | 'imageCreateV1'
  | 'imageCreateV2'
  | 'prompt'
  | GenerateImageResponseKeySpecifier
)[]
export type GenerateImageResponseFieldPolicy = {
  formattedPrompt?: FieldPolicy<any> | FieldReadFunction<any>
  imageCreateV1?: FieldPolicy<any> | FieldReadFunction<any>
  imageCreateV2?: FieldPolicy<any> | FieldReadFunction<any>
  prompt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GenericResponseKeySpecifier = (
  | 'id'
  | 'language'
  | 'text'
  | 'textVariations'
  | 'tone'
  | 'useCase'
  | GenericResponseKeySpecifier
)[]
export type GenericResponseFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>
  language?: FieldPolicy<any> | FieldReadFunction<any>
  text?: FieldPolicy<any> | FieldReadFunction<any>
  textVariations?: FieldPolicy<any> | FieldReadFunction<any>
  tone?: FieldPolicy<any> | FieldReadFunction<any>
  useCase?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GptHeroDislikePromptKeySpecifier = ('likes' | GptHeroDislikePromptKeySpecifier)[]
export type GptHeroDislikePromptFieldPolicy = {
  likes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GptHeroLikePromptKeySpecifier = ('likes' | GptHeroLikePromptKeySpecifier)[]
export type GptHeroLikePromptFieldPolicy = {
  likes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type GptHeroSeenPromptKeySpecifier = ('seen' | GptHeroSeenPromptKeySpecifier)[]
export type GptHeroSeenPromptFieldPolicy = {
  seen?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ImageCreateResponseKeySpecifier = (
  | 'created'
  | 'data'
  | 'id'
  | 'promptInput'
  | ImageCreateResponseKeySpecifier
)[]
export type ImageCreateResponseFieldPolicy = {
  created?: FieldPolicy<any> | FieldReadFunction<any>
  data?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  promptInput?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ImageCreateResponseDataKeySpecifier = (
  | 'b64_json'
  | 'url'
  | ImageCreateResponseDataKeySpecifier
)[]
export type ImageCreateResponseDataFieldPolicy = {
  b64_json?: FieldPolicy<any> | FieldReadFunction<any>
  url?: FieldPolicy<any> | FieldReadFunction<any>
}
export type LikePromptResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | LikePromptResponseKeySpecifier
)[]
export type LikePromptResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>
  returning?: FieldPolicy<any> | FieldReadFunction<any>
}
export type MetaItemKeySpecifier = ('content' | 'property' | MetaItemKeySpecifier)[]
export type MetaItemFieldPolicy = {
  content?: FieldPolicy<any> | FieldReadFunction<any>
  property?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PagesKeySpecifier = (
  | 'contentfulMetadata'
  | 'displayName'
  | 'iconifyIconNameForMobileMenu'
  | 'linkedFrom'
  | 'sectionsCollection'
  | 'seoMetadataItemsCollection'
  | 'slug'
  | 'sys'
  | PagesKeySpecifier
)[]
export type PagesFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  displayName?: FieldPolicy<any> | FieldReadFunction<any>
  iconifyIconNameForMobileMenu?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  seoMetadataItemsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  slug?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PagesCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | PagesCollectionKeySpecifier
)[]
export type PagesCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PagesLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'rootSettingsCollection'
  | PagesLinkingCollectionsKeySpecifier
)[]
export type PagesLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  rootSettingsCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PagesSectionsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | PagesSectionsCollectionKeySpecifier
)[]
export type PagesSectionsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PagesSeoMetadataItemsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | PagesSeoMetadataItemsCollectionKeySpecifier
)[]
export type PagesSeoMetadataItemsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductsKeySpecifier = (
  | 'abstract'
  | 'callToActionLink'
  | 'content'
  | 'contentfulMetadata'
  | 'description'
  | 'imagesCollection'
  | 'linkedFrom'
  | 'previewImage'
  | 'price'
  | 'seoMetadataItemsCollection'
  | 'slug'
  | 'sys'
  | 'tagsCollection'
  | 'title'
  | ProductsKeySpecifier
)[]
export type ProductsFieldPolicy = {
  abstract?: FieldPolicy<any> | FieldReadFunction<any>
  callToActionLink?: FieldPolicy<any> | FieldReadFunction<any>
  content?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  imagesCollection?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  previewImage?: FieldPolicy<any> | FieldReadFunction<any>
  price?: FieldPolicy<any> | FieldReadFunction<any>
  seoMetadataItemsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  slug?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  tagsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | ProductsCollectionKeySpecifier
)[]
export type ProductsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductsContentKeySpecifier = ('json' | 'links' | ProductsContentKeySpecifier)[]
export type ProductsContentFieldPolicy = {
  json?: FieldPolicy<any> | FieldReadFunction<any>
  links?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductsContentAssetsKeySpecifier = (
  | 'block'
  | 'hyperlink'
  | ProductsContentAssetsKeySpecifier
)[]
export type ProductsContentAssetsFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
  hyperlink?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductsContentEntriesKeySpecifier = (
  | 'block'
  | 'hyperlink'
  | 'inline'
  | ProductsContentEntriesKeySpecifier
)[]
export type ProductsContentEntriesFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
  hyperlink?: FieldPolicy<any> | FieldReadFunction<any>
  inline?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductsContentLinksKeySpecifier = (
  | 'assets'
  | 'entries'
  | 'resources'
  | ProductsContentLinksKeySpecifier
)[]
export type ProductsContentLinksFieldPolicy = {
  assets?: FieldPolicy<any> | FieldReadFunction<any>
  entries?: FieldPolicy<any> | FieldReadFunction<any>
  resources?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductsContentResourcesKeySpecifier = (
  | 'block'
  | ProductsContentResourcesKeySpecifier
)[]
export type ProductsContentResourcesFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductsLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | ProductsLinkingCollectionsKeySpecifier
)[]
export type ProductsLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductsSeoMetadataItemsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | ProductsSeoMetadataItemsCollectionKeySpecifier
)[]
export type ProductsSeoMetadataItemsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ProductsTagsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | ProductsTagsCollectionKeySpecifier
)[]
export type ProductsTagsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PromptChoiceKeySpecifier = (
  | 'finish_reason'
  | 'index'
  | 'text'
  | PromptChoiceKeySpecifier
)[]
export type PromptChoiceFieldPolicy = {
  finish_reason?: FieldPolicy<any> | FieldReadFunction<any>
  index?: FieldPolicy<any> | FieldReadFunction<any>
  text?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PromptCreateResponseKeySpecifier = (
  | 'choices'
  | 'created'
  | 'id'
  | 'model'
  | 'object'
  | 'usage'
  | PromptCreateResponseKeySpecifier
)[]
export type PromptCreateResponseFieldPolicy = {
  choices?: FieldPolicy<any> | FieldReadFunction<any>
  created?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  model?: FieldPolicy<any> | FieldReadFunction<any>
  object?: FieldPolicy<any> | FieldReadFunction<any>
  usage?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PromptEngineeringKeySpecifier = (
  | 'contentfulMetadata'
  | 'linkedFrom'
  | 'prompt'
  | 'promptHint'
  | 'purpose'
  | 'sys'
  | 'teaser'
  | 'title'
  | PromptEngineeringKeySpecifier
)[]
export type PromptEngineeringFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  prompt?: FieldPolicy<any> | FieldReadFunction<any>
  promptHint?: FieldPolicy<any> | FieldReadFunction<any>
  purpose?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  teaser?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PromptEngineeringCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | PromptEngineeringCollectionKeySpecifier
)[]
export type PromptEngineeringCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PromptEngineeringLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'rootSettingsCollection'
  | PromptEngineeringLinkingCollectionsKeySpecifier
)[]
export type PromptEngineeringLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  rootSettingsCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PromptInterfaceKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'prompt'
  | 'promptHint'
  | 'promptTeaser'
  | 'statistics'
  | 'updatedAt'
  | PromptInterfaceKeySpecifier
)[]
export type PromptInterfaceFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  prompt?: FieldPolicy<any> | FieldReadFunction<any>
  promptHint?: FieldPolicy<any> | FieldReadFunction<any>
  promptTeaser?: FieldPolicy<any> | FieldReadFunction<any>
  statistics?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PromptResponseKeySpecifier = (
  | 'createResponse'
  | 'id'
  | 'language'
  | 'prompt'
  | 'promptInterface'
  | PromptResponseKeySpecifier
)[]
export type PromptResponseFieldPolicy = {
  createResponse?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  language?: FieldPolicy<any> | FieldReadFunction<any>
  prompt?: FieldPolicy<any> | FieldReadFunction<any>
  promptInterface?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PromptStatisticsKeySpecifier = (
  | 'usages'
  | 'views'
  | 'votes'
  | PromptStatisticsKeySpecifier
)[]
export type PromptStatisticsFieldPolicy = {
  usages?: FieldPolicy<any> | FieldReadFunction<any>
  views?: FieldPolicy<any> | FieldReadFunction<any>
  votes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type PromptUsageKeySpecifier = (
  | 'completion_tokens'
  | 'prompt_tokens'
  | 'total_tokens'
  | PromptUsageKeySpecifier
)[]
export type PromptUsageFieldPolicy = {
  completion_tokens?: FieldPolicy<any> | FieldReadFunction<any>
  prompt_tokens?: FieldPolicy<any> | FieldReadFunction<any>
  total_tokens?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ResourceLinkKeySpecifier = ('sys' | ResourceLinkKeySpecifier)[]
export type ResourceLinkFieldPolicy = {
  sys?: FieldPolicy<any> | FieldReadFunction<any>
}
export type ResourceSysKeySpecifier = ('linkType' | 'type' | 'urn' | ResourceSysKeySpecifier)[]
export type ResourceSysFieldPolicy = {
  linkType?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  urn?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RootSettingsKeySpecifier = (
  | 'callToActionLink'
  | 'companyColor'
  | 'companyFavicon'
  | 'companyLogo'
  | 'contentfulMetadata'
  | 'headerDisplayPagesCollection'
  | 'linkedFrom'
  | 'mobileDrawerDisplayPagesCollection'
  | 'promptEngineeringsCollection'
  | 'renameCallToActionLinksDisplayText'
  | 'renamePathDisplayTextBlog'
  | 'renamePathDisplayTextHome'
  | 'renamePathDisplayTextProduct'
  | 'scope'
  | 'sectionColors'
  | 'seoMetadataItemsCollection'
  | 'socialMediaLinksCollection'
  | 'sys'
  | 'theme'
  | 'writersoftModules'
  | RootSettingsKeySpecifier
)[]
export type RootSettingsFieldPolicy = {
  callToActionLink?: FieldPolicy<any> | FieldReadFunction<any>
  companyColor?: FieldPolicy<any> | FieldReadFunction<any>
  companyFavicon?: FieldPolicy<any> | FieldReadFunction<any>
  companyLogo?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  headerDisplayPagesCollection?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  mobileDrawerDisplayPagesCollection?: FieldPolicy<any> | FieldReadFunction<any>
  promptEngineeringsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  renameCallToActionLinksDisplayText?: FieldPolicy<any> | FieldReadFunction<any>
  renamePathDisplayTextBlog?: FieldPolicy<any> | FieldReadFunction<any>
  renamePathDisplayTextHome?: FieldPolicy<any> | FieldReadFunction<any>
  renamePathDisplayTextProduct?: FieldPolicy<any> | FieldReadFunction<any>
  scope?: FieldPolicy<any> | FieldReadFunction<any>
  sectionColors?: FieldPolicy<any> | FieldReadFunction<any>
  seoMetadataItemsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  socialMediaLinksCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  theme?: FieldPolicy<any> | FieldReadFunction<any>
  writersoftModules?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RootSettingsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | RootSettingsCollectionKeySpecifier
)[]
export type RootSettingsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RootSettingsHeaderDisplayPagesCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | RootSettingsHeaderDisplayPagesCollectionKeySpecifier
)[]
export type RootSettingsHeaderDisplayPagesCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RootSettingsLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | RootSettingsLinkingCollectionsKeySpecifier
)[]
export type RootSettingsLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RootSettingsMobileDrawerDisplayPagesCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | RootSettingsMobileDrawerDisplayPagesCollectionKeySpecifier
)[]
export type RootSettingsMobileDrawerDisplayPagesCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RootSettingsPromptEngineeringsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | RootSettingsPromptEngineeringsCollectionKeySpecifier
)[]
export type RootSettingsPromptEngineeringsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RootSettingsSeoMetadataItemsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | RootSettingsSeoMetadataItemsCollectionKeySpecifier
)[]
export type RootSettingsSeoMetadataItemsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type RootSettingsSocialMediaLinksCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | RootSettingsSocialMediaLinksCollectionKeySpecifier
)[]
export type RootSettingsSocialMediaLinksCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsDetailPersonComponentKeySpecifier = (
  | 'avatar'
  | 'contentfulMetadata'
  | 'description'
  | 'descriptionHref'
  | 'detail'
  | 'linkedFrom'
  | 'sys'
  | 'title'
  | 'titleHref'
  | SectionsDetailPersonComponentKeySpecifier
)[]
export type SectionsDetailPersonComponentFieldPolicy = {
  avatar?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  descriptionHref?: FieldPolicy<any> | FieldReadFunction<any>
  detail?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
  titleHref?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsDetailPersonComponentCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsDetailPersonComponentCollectionKeySpecifier
)[]
export type SectionsDetailPersonComponentCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsDetailPersonComponentDetailKeySpecifier = (
  | 'json'
  | 'links'
  | SectionsDetailPersonComponentDetailKeySpecifier
)[]
export type SectionsDetailPersonComponentDetailFieldPolicy = {
  json?: FieldPolicy<any> | FieldReadFunction<any>
  links?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsDetailPersonComponentDetailAssetsKeySpecifier = (
  | 'block'
  | 'hyperlink'
  | SectionsDetailPersonComponentDetailAssetsKeySpecifier
)[]
export type SectionsDetailPersonComponentDetailAssetsFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
  hyperlink?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsDetailPersonComponentDetailEntriesKeySpecifier = (
  | 'block'
  | 'hyperlink'
  | 'inline'
  | SectionsDetailPersonComponentDetailEntriesKeySpecifier
)[]
export type SectionsDetailPersonComponentDetailEntriesFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
  hyperlink?: FieldPolicy<any> | FieldReadFunction<any>
  inline?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsDetailPersonComponentDetailLinksKeySpecifier = (
  | 'assets'
  | 'entries'
  | 'resources'
  | SectionsDetailPersonComponentDetailLinksKeySpecifier
)[]
export type SectionsDetailPersonComponentDetailLinksFieldPolicy = {
  assets?: FieldPolicy<any> | FieldReadFunction<any>
  entries?: FieldPolicy<any> | FieldReadFunction<any>
  resources?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsDetailPersonComponentDetailResourcesKeySpecifier = (
  | 'block'
  | SectionsDetailPersonComponentDetailResourcesKeySpecifier
)[]
export type SectionsDetailPersonComponentDetailResourcesFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsDetailPersonComponentLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'sectionsDetailPersonListCollection'
  | SectionsDetailPersonComponentLinkingCollectionsKeySpecifier
)[]
export type SectionsDetailPersonComponentLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsDetailPersonListCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsDetailPersonListKeySpecifier = (
  | 'componentsCollection'
  | 'contentfulMetadata'
  | 'description'
  | 'linkedFrom'
  | 'sys'
  | 'title'
  | SectionsDetailPersonListKeySpecifier
)[]
export type SectionsDetailPersonListFieldPolicy = {
  componentsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsDetailPersonListCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsDetailPersonListCollectionKeySpecifier
)[]
export type SectionsDetailPersonListCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsDetailPersonListComponentsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsDetailPersonListComponentsCollectionKeySpecifier
)[]
export type SectionsDetailPersonListComponentsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsDetailPersonListLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'pagesCollection'
  | SectionsDetailPersonListLinkingCollectionsKeySpecifier
)[]
export type SectionsDetailPersonListLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  pagesCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFaqComponentKeySpecifier = (
  | 'content'
  | 'contentfulMetadata'
  | 'linkedFrom'
  | 'sys'
  | 'title'
  | SectionsFaqComponentKeySpecifier
)[]
export type SectionsFaqComponentFieldPolicy = {
  content?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFaqComponentCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsFaqComponentCollectionKeySpecifier
)[]
export type SectionsFaqComponentCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFaqComponentContentKeySpecifier = (
  | 'json'
  | 'links'
  | SectionsFaqComponentContentKeySpecifier
)[]
export type SectionsFaqComponentContentFieldPolicy = {
  json?: FieldPolicy<any> | FieldReadFunction<any>
  links?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFaqComponentContentAssetsKeySpecifier = (
  | 'block'
  | 'hyperlink'
  | SectionsFaqComponentContentAssetsKeySpecifier
)[]
export type SectionsFaqComponentContentAssetsFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
  hyperlink?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFaqComponentContentEntriesKeySpecifier = (
  | 'block'
  | 'hyperlink'
  | 'inline'
  | SectionsFaqComponentContentEntriesKeySpecifier
)[]
export type SectionsFaqComponentContentEntriesFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
  hyperlink?: FieldPolicy<any> | FieldReadFunction<any>
  inline?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFaqComponentContentLinksKeySpecifier = (
  | 'assets'
  | 'entries'
  | 'resources'
  | SectionsFaqComponentContentLinksKeySpecifier
)[]
export type SectionsFaqComponentContentLinksFieldPolicy = {
  assets?: FieldPolicy<any> | FieldReadFunction<any>
  entries?: FieldPolicy<any> | FieldReadFunction<any>
  resources?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFaqComponentContentResourcesKeySpecifier = (
  | 'block'
  | SectionsFaqComponentContentResourcesKeySpecifier
)[]
export type SectionsFaqComponentContentResourcesFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFaqComponentLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'sectionsFaqListCollection'
  | SectionsFaqComponentLinkingCollectionsKeySpecifier
)[]
export type SectionsFaqComponentLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsFaqListCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFaqListKeySpecifier = (
  | 'componentsCollection'
  | 'contentfulMetadata'
  | 'description'
  | 'linkedFrom'
  | 'sys'
  | 'title'
  | SectionsFaqListKeySpecifier
)[]
export type SectionsFaqListFieldPolicy = {
  componentsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFaqListCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsFaqListCollectionKeySpecifier
)[]
export type SectionsFaqListCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFaqListComponentsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsFaqListComponentsCollectionKeySpecifier
)[]
export type SectionsFaqListComponentsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFaqListLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'pagesCollection'
  | SectionsFaqListLinkingCollectionsKeySpecifier
)[]
export type SectionsFaqListLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  pagesCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFeaturesListKeySpecifier = (
  | 'componentsCollection'
  | 'contentfulMetadata'
  | 'description'
  | 'linkedFrom'
  | 'sys'
  | 'title'
  | SectionsFeaturesListKeySpecifier
)[]
export type SectionsFeaturesListFieldPolicy = {
  componentsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFeaturesListCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsFeaturesListCollectionKeySpecifier
)[]
export type SectionsFeaturesListCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFeaturesListComponentKeySpecifier = (
  | 'contentfulMetadata'
  | 'description'
  | 'icon'
  | 'iconifyIconName'
  | 'linkedFrom'
  | 'sys'
  | 'title'
  | SectionsFeaturesListComponentKeySpecifier
)[]
export type SectionsFeaturesListComponentFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  icon?: FieldPolicy<any> | FieldReadFunction<any>
  iconifyIconName?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFeaturesListComponentCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsFeaturesListComponentCollectionKeySpecifier
)[]
export type SectionsFeaturesListComponentCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFeaturesListComponentLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'sectionsFeaturesListCollection'
  | SectionsFeaturesListComponentLinkingCollectionsKeySpecifier
)[]
export type SectionsFeaturesListComponentLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsFeaturesListCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFeaturesListComponentsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsFeaturesListComponentsCollectionKeySpecifier
)[]
export type SectionsFeaturesListComponentsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFeaturesListLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'pagesCollection'
  | SectionsFeaturesListLinkingCollectionsKeySpecifier
)[]
export type SectionsFeaturesListLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  pagesCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFormKeySpecifier = (
  | 'callToActionHref'
  | 'callToActionText'
  | 'componentsCollection'
  | 'contentfulMetadata'
  | 'description'
  | 'linkedFrom'
  | 'name'
  | 'sys'
  | 'title'
  | SectionsFormKeySpecifier
)[]
export type SectionsFormFieldPolicy = {
  callToActionHref?: FieldPolicy<any> | FieldReadFunction<any>
  callToActionText?: FieldPolicy<any> | FieldReadFunction<any>
  componentsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFormCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsFormCollectionKeySpecifier
)[]
export type SectionsFormCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFormComponentKeySpecifier = (
  | 'contentfulMetadata'
  | 'defaultChecked'
  | 'defaultValue'
  | 'disabled'
  | 'fullWidth'
  | 'helperText'
  | 'label'
  | 'linkedFrom'
  | 'max'
  | 'maxLength'
  | 'min'
  | 'minLength'
  | 'multiple'
  | 'options'
  | 'pattern'
  | 'placeholder'
  | 'readOnly'
  | 'required'
  | 'size'
  | 'step'
  | 'sys'
  | 'type'
  | SectionsFormComponentKeySpecifier
)[]
export type SectionsFormComponentFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  defaultChecked?: FieldPolicy<any> | FieldReadFunction<any>
  defaultValue?: FieldPolicy<any> | FieldReadFunction<any>
  disabled?: FieldPolicy<any> | FieldReadFunction<any>
  fullWidth?: FieldPolicy<any> | FieldReadFunction<any>
  helperText?: FieldPolicy<any> | FieldReadFunction<any>
  label?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  max?: FieldPolicy<any> | FieldReadFunction<any>
  maxLength?: FieldPolicy<any> | FieldReadFunction<any>
  min?: FieldPolicy<any> | FieldReadFunction<any>
  minLength?: FieldPolicy<any> | FieldReadFunction<any>
  multiple?: FieldPolicy<any> | FieldReadFunction<any>
  options?: FieldPolicy<any> | FieldReadFunction<any>
  pattern?: FieldPolicy<any> | FieldReadFunction<any>
  placeholder?: FieldPolicy<any> | FieldReadFunction<any>
  readOnly?: FieldPolicy<any> | FieldReadFunction<any>
  required?: FieldPolicy<any> | FieldReadFunction<any>
  size?: FieldPolicy<any> | FieldReadFunction<any>
  step?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFormComponentCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsFormComponentCollectionKeySpecifier
)[]
export type SectionsFormComponentCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFormComponentLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'sectionsFormCollection'
  | SectionsFormComponentLinkingCollectionsKeySpecifier
)[]
export type SectionsFormComponentLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsFormCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFormComponentsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsFormComponentsCollectionKeySpecifier
)[]
export type SectionsFormComponentsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsFormLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'pagesCollection'
  | SectionsFormLinkingCollectionsKeySpecifier
)[]
export type SectionsFormLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  pagesCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsHeroKeySpecifier = (
  | 'backgroundImage'
  | 'callToActionHref'
  | 'callToActionText'
  | 'companyName'
  | 'contentfulMetadata'
  | 'descriptionText'
  | 'enableAiRecommendation'
  | 'enableAiRecommendationDisplayText'
  | 'enableAiRecommendationPrompt'
  | 'enableAiRecommendationTagsPrompt'
  | 'enableAiTrail'
  | 'enableAiTrailDisplayText'
  | 'featureText'
  | 'fullWidthBackgroundImageDesktop'
  | 'fullWidthBackgroundImageMobile'
  | 'linkedFrom'
  | 'sys'
  | SectionsHeroKeySpecifier
)[]
export type SectionsHeroFieldPolicy = {
  backgroundImage?: FieldPolicy<any> | FieldReadFunction<any>
  callToActionHref?: FieldPolicy<any> | FieldReadFunction<any>
  callToActionText?: FieldPolicy<any> | FieldReadFunction<any>
  companyName?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  descriptionText?: FieldPolicy<any> | FieldReadFunction<any>
  enableAiRecommendation?: FieldPolicy<any> | FieldReadFunction<any>
  enableAiRecommendationDisplayText?: FieldPolicy<any> | FieldReadFunction<any>
  enableAiRecommendationPrompt?: FieldPolicy<any> | FieldReadFunction<any>
  enableAiRecommendationTagsPrompt?: FieldPolicy<any> | FieldReadFunction<any>
  enableAiTrail?: FieldPolicy<any> | FieldReadFunction<any>
  enableAiTrailDisplayText?: FieldPolicy<any> | FieldReadFunction<any>
  featureText?: FieldPolicy<any> | FieldReadFunction<any>
  fullWidthBackgroundImageDesktop?: FieldPolicy<any> | FieldReadFunction<any>
  fullWidthBackgroundImageMobile?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsHeroCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsHeroCollectionKeySpecifier
)[]
export type SectionsHeroCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsHeroLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'pagesCollection'
  | SectionsHeroLinkingCollectionsKeySpecifier
)[]
export type SectionsHeroLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  pagesCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsImageKeySpecifier = (
  | 'callToActionHref'
  | 'contentfulMetadata'
  | 'image'
  | 'linkedFrom'
  | 'mobileImage'
  | 'sys'
  | SectionsImageKeySpecifier
)[]
export type SectionsImageFieldPolicy = {
  callToActionHref?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  image?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  mobileImage?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsImageCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsImageCollectionKeySpecifier
)[]
export type SectionsImageCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsImageLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'pagesCollection'
  | 'sectionsMinimalsImagesListCollection'
  | SectionsImageLinkingCollectionsKeySpecifier
)[]
export type SectionsImageLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  pagesCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsImagesListCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsContactComponentKeySpecifier = (
  | 'contentfulMetadata'
  | 'href'
  | 'linkedFrom'
  | 'sys'
  | 'title'
  | SectionsMinimalsContactComponentKeySpecifier
)[]
export type SectionsMinimalsContactComponentFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  href?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsContactComponentCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsMinimalsContactComponentCollectionKeySpecifier
)[]
export type SectionsMinimalsContactComponentCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsContactComponentLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'sectionsMinimalsContactListCollection'
  | SectionsMinimalsContactComponentLinkingCollectionsKeySpecifier
)[]
export type SectionsMinimalsContactComponentLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsContactListCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsContactListKeySpecifier = (
  | 'contentfulMetadata'
  | 'description'
  | 'linkedFrom'
  | 'linksCollection'
  | 'sys'
  | 'title'
  | SectionsMinimalsContactListKeySpecifier
)[]
export type SectionsMinimalsContactListFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  linksCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsContactListCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsMinimalsContactListCollectionKeySpecifier
)[]
export type SectionsMinimalsContactListCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsContactListLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'pagesCollection'
  | SectionsMinimalsContactListLinkingCollectionsKeySpecifier
)[]
export type SectionsMinimalsContactListLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  pagesCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsContactListLinksCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsMinimalsContactListLinksCollectionKeySpecifier
)[]
export type SectionsMinimalsContactListLinksCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsGalleryKeySpecifier = (
  | 'contentfulMetadata'
  | 'description'
  | 'displayItemsCollection'
  | 'linkedFrom'
  | 'sys'
  | 'title'
  | SectionsMinimalsGalleryKeySpecifier
)[]
export type SectionsMinimalsGalleryFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  displayItemsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsGalleryCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsMinimalsGalleryCollectionKeySpecifier
)[]
export type SectionsMinimalsGalleryCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsGalleryDisplayItemsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsMinimalsGalleryDisplayItemsCollectionKeySpecifier
)[]
export type SectionsMinimalsGalleryDisplayItemsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsGalleryLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'pagesCollection'
  | SectionsMinimalsGalleryLinkingCollectionsKeySpecifier
)[]
export type SectionsMinimalsGalleryLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  pagesCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsHistoryComponentKeySpecifier = (
  | 'contentfulMetadata'
  | 'description'
  | 'displayTime'
  | 'featureImage'
  | 'linkedFrom'
  | 'sys'
  | 'title'
  | SectionsMinimalsHistoryComponentKeySpecifier
)[]
export type SectionsMinimalsHistoryComponentFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  displayTime?: FieldPolicy<any> | FieldReadFunction<any>
  featureImage?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsHistoryComponentCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsMinimalsHistoryComponentCollectionKeySpecifier
)[]
export type SectionsMinimalsHistoryComponentCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsHistoryComponentLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'sectionsMinimalsHistoryListCollection'
  | SectionsMinimalsHistoryComponentLinkingCollectionsKeySpecifier
)[]
export type SectionsMinimalsHistoryComponentLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsHistoryListCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsHistoryListKeySpecifier = (
  | 'componentDateFormat'
  | 'componentsCollection'
  | 'contentfulMetadata'
  | 'description'
  | 'linkedFrom'
  | 'sys'
  | 'title'
  | SectionsMinimalsHistoryListKeySpecifier
)[]
export type SectionsMinimalsHistoryListFieldPolicy = {
  componentDateFormat?: FieldPolicy<any> | FieldReadFunction<any>
  componentsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsHistoryListCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsMinimalsHistoryListCollectionKeySpecifier
)[]
export type SectionsMinimalsHistoryListCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsHistoryListComponentsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsMinimalsHistoryListComponentsCollectionKeySpecifier
)[]
export type SectionsMinimalsHistoryListComponentsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsHistoryListLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'pagesCollection'
  | SectionsMinimalsHistoryListLinkingCollectionsKeySpecifier
)[]
export type SectionsMinimalsHistoryListLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  pagesCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsHumanComponentKeySpecifier = (
  | 'contentfulMetadata'
  | 'description'
  | 'descriptionHref'
  | 'featureImage'
  | 'linkedFrom'
  | 'sys'
  | 'title'
  | SectionsMinimalsHumanComponentKeySpecifier
)[]
export type SectionsMinimalsHumanComponentFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  descriptionHref?: FieldPolicy<any> | FieldReadFunction<any>
  featureImage?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsHumanComponentCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsMinimalsHumanComponentCollectionKeySpecifier
)[]
export type SectionsMinimalsHumanComponentCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsHumanComponentLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'sectionsMinimalsHumanListCollection'
  | SectionsMinimalsHumanComponentLinkingCollectionsKeySpecifier
)[]
export type SectionsMinimalsHumanComponentLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsHumanListCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsHumanListKeySpecifier = (
  | 'componentsCollection'
  | 'contentfulMetadata'
  | 'description'
  | 'linkedFrom'
  | 'sys'
  | 'title'
  | SectionsMinimalsHumanListKeySpecifier
)[]
export type SectionsMinimalsHumanListFieldPolicy = {
  componentsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsHumanListCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsMinimalsHumanListCollectionKeySpecifier
)[]
export type SectionsMinimalsHumanListCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsHumanListComponentsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsMinimalsHumanListComponentsCollectionKeySpecifier
)[]
export type SectionsMinimalsHumanListComponentsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsHumanListLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'pagesCollection'
  | SectionsMinimalsHumanListLinkingCollectionsKeySpecifier
)[]
export type SectionsMinimalsHumanListLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  pagesCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsImagesListKeySpecifier = (
  | 'contentfulMetadata'
  | 'description'
  | 'displayMode'
  | 'gridModeColumnsCountDesktop'
  | 'gridModeColumnsCountMobile'
  | 'gridModeGapDesktop'
  | 'gridModeGapMobile'
  | 'imagesCollection'
  | 'imagesMobileCollection'
  | 'imagesWithHrefDesktopCollection'
  | 'imagesWithHrefMobileCollection'
  | 'linkedFrom'
  | 'sys'
  | 'title'
  | SectionsMinimalsImagesListKeySpecifier
)[]
export type SectionsMinimalsImagesListFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  displayMode?: FieldPolicy<any> | FieldReadFunction<any>
  gridModeColumnsCountDesktop?: FieldPolicy<any> | FieldReadFunction<any>
  gridModeColumnsCountMobile?: FieldPolicy<any> | FieldReadFunction<any>
  gridModeGapDesktop?: FieldPolicy<any> | FieldReadFunction<any>
  gridModeGapMobile?: FieldPolicy<any> | FieldReadFunction<any>
  imagesCollection?: FieldPolicy<any> | FieldReadFunction<any>
  imagesMobileCollection?: FieldPolicy<any> | FieldReadFunction<any>
  imagesWithHrefDesktopCollection?: FieldPolicy<any> | FieldReadFunction<any>
  imagesWithHrefMobileCollection?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsImagesListCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsMinimalsImagesListCollectionKeySpecifier
)[]
export type SectionsMinimalsImagesListCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsImagesListImagesWithHrefDesktopCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsMinimalsImagesListImagesWithHrefDesktopCollectionKeySpecifier
)[]
export type SectionsMinimalsImagesListImagesWithHrefDesktopCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsImagesListImagesWithHrefMobileCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsMinimalsImagesListImagesWithHrefMobileCollectionKeySpecifier
)[]
export type SectionsMinimalsImagesListImagesWithHrefMobileCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsImagesListLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'pagesCollection'
  | 'sectionsMinimalsGalleryCollection'
  | SectionsMinimalsImagesListLinkingCollectionsKeySpecifier
)[]
export type SectionsMinimalsImagesListLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  pagesCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsGalleryCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsServiceKeySpecifier = (
  | 'callToActionHref'
  | 'callToActionText'
  | 'contentfulMetadata'
  | 'linkedFrom'
  | 'services'
  | 'sys'
  | 'title'
  | SectionsMinimalsServiceKeySpecifier
)[]
export type SectionsMinimalsServiceFieldPolicy = {
  callToActionHref?: FieldPolicy<any> | FieldReadFunction<any>
  callToActionText?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  services?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsServiceCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsMinimalsServiceCollectionKeySpecifier
)[]
export type SectionsMinimalsServiceCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsServiceLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'sectionsMinimalsServicesCollection'
  | SectionsMinimalsServiceLinkingCollectionsKeySpecifier
)[]
export type SectionsMinimalsServiceLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsServicesCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsServicesKeySpecifier = (
  | 'componentsCollection'
  | 'contentfulMetadata'
  | 'description'
  | 'linkedFrom'
  | 'sys'
  | 'title'
  | SectionsMinimalsServicesKeySpecifier
)[]
export type SectionsMinimalsServicesFieldPolicy = {
  componentsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsServicesCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsMinimalsServicesCollectionKeySpecifier
)[]
export type SectionsMinimalsServicesCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsServicesComponentsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsMinimalsServicesComponentsCollectionKeySpecifier
)[]
export type SectionsMinimalsServicesComponentsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsServicesLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'pagesCollection'
  | SectionsMinimalsServicesLinkingCollectionsKeySpecifier
)[]
export type SectionsMinimalsServicesLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  pagesCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsVideoKeySpecifier = (
  | 'callToAction'
  | 'contentfulMetadata'
  | 'description'
  | 'featureDescription'
  | 'image'
  | 'linkedFrom'
  | 'mode'
  | 'sys'
  | 'title'
  | 'video'
  | 'videoRatio'
  | 'youtubeEmbedLink'
  | SectionsMinimalsVideoKeySpecifier
)[]
export type SectionsMinimalsVideoFieldPolicy = {
  callToAction?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  featureDescription?: FieldPolicy<any> | FieldReadFunction<any>
  image?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  mode?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
  video?: FieldPolicy<any> | FieldReadFunction<any>
  videoRatio?: FieldPolicy<any> | FieldReadFunction<any>
  youtubeEmbedLink?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsVideoCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsMinimalsVideoCollectionKeySpecifier
)[]
export type SectionsMinimalsVideoCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsVideoFeatureDescriptionKeySpecifier = (
  | 'json'
  | 'links'
  | SectionsMinimalsVideoFeatureDescriptionKeySpecifier
)[]
export type SectionsMinimalsVideoFeatureDescriptionFieldPolicy = {
  json?: FieldPolicy<any> | FieldReadFunction<any>
  links?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsVideoFeatureDescriptionAssetsKeySpecifier = (
  | 'block'
  | 'hyperlink'
  | SectionsMinimalsVideoFeatureDescriptionAssetsKeySpecifier
)[]
export type SectionsMinimalsVideoFeatureDescriptionAssetsFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
  hyperlink?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsVideoFeatureDescriptionEntriesKeySpecifier = (
  | 'block'
  | 'hyperlink'
  | 'inline'
  | SectionsMinimalsVideoFeatureDescriptionEntriesKeySpecifier
)[]
export type SectionsMinimalsVideoFeatureDescriptionEntriesFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
  hyperlink?: FieldPolicy<any> | FieldReadFunction<any>
  inline?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsVideoFeatureDescriptionLinksKeySpecifier = (
  | 'assets'
  | 'entries'
  | 'resources'
  | SectionsMinimalsVideoFeatureDescriptionLinksKeySpecifier
)[]
export type SectionsMinimalsVideoFeatureDescriptionLinksFieldPolicy = {
  assets?: FieldPolicy<any> | FieldReadFunction<any>
  entries?: FieldPolicy<any> | FieldReadFunction<any>
  resources?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsVideoFeatureDescriptionResourcesKeySpecifier = (
  | 'block'
  | SectionsMinimalsVideoFeatureDescriptionResourcesKeySpecifier
)[]
export type SectionsMinimalsVideoFeatureDescriptionResourcesFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMinimalsVideoLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'pagesCollection'
  | SectionsMinimalsVideoLinkingCollectionsKeySpecifier
)[]
export type SectionsMinimalsVideoLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  pagesCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMintListKeySpecifier = (
  | 'contentfulMetadata'
  | 'description'
  | 'linkedFrom'
  | 'mintCardsCollection'
  | 'sys'
  | 'title'
  | SectionsMintListKeySpecifier
)[]
export type SectionsMintListFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  mintCardsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMintListCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsMintListCollectionKeySpecifier
)[]
export type SectionsMintListCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMintListComponentKeySpecifier = (
  | 'contentfulMetadata'
  | 'dependencySmartContractErc721'
  | 'description'
  | 'isDisplay'
  | 'linkedFrom'
  | 'price'
  | 'smartContractCallToActionName'
  | 'smartContractErc721'
  | 'style'
  | 'sys'
  | 'title'
  | SectionsMintListComponentKeySpecifier
)[]
export type SectionsMintListComponentFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  dependencySmartContractErc721?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  isDisplay?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  price?: FieldPolicy<any> | FieldReadFunction<any>
  smartContractCallToActionName?: FieldPolicy<any> | FieldReadFunction<any>
  smartContractErc721?: FieldPolicy<any> | FieldReadFunction<any>
  style?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMintListComponentCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsMintListComponentCollectionKeySpecifier
)[]
export type SectionsMintListComponentCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMintListComponentLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'sectionsMintListCollection'
  | SectionsMintListComponentLinkingCollectionsKeySpecifier
)[]
export type SectionsMintListComponentLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMintListCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMintListLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'pagesCollection'
  | SectionsMintListLinkingCollectionsKeySpecifier
)[]
export type SectionsMintListLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  pagesCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsMintListMintCardsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsMintListMintCardsCollectionKeySpecifier
)[]
export type SectionsMintListMintCardsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsTimerKeySpecifier = (
  | 'appearance'
  | 'backgroundImage'
  | 'contentfulMetadata'
  | 'description'
  | 'linkedFrom'
  | 'startTime'
  | 'sys'
  | 'title'
  | SectionsTimerKeySpecifier
)[]
export type SectionsTimerFieldPolicy = {
  appearance?: FieldPolicy<any> | FieldReadFunction<any>
  backgroundImage?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  startTime?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsTimerCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SectionsTimerCollectionKeySpecifier
)[]
export type SectionsTimerCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SectionsTimerLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'pagesCollection'
  | SectionsTimerLinkingCollectionsKeySpecifier
)[]
export type SectionsTimerLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  pagesCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SeenPromptResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | SeenPromptResponseKeySpecifier
)[]
export type SeenPromptResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>
  returning?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SeoInfoKeySpecifier = ('description' | 'metaItems' | 'title' | SeoInfoKeySpecifier)[]
export type SeoInfoFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>
  metaItems?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SeoMetadataKeySpecifier = (
  | 'content'
  | 'contentfulMetadata'
  | 'linkedFrom'
  | 'name'
  | 'sys'
  | SeoMetadataKeySpecifier
)[]
export type SeoMetadataFieldPolicy = {
  content?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SeoMetadataCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SeoMetadataCollectionKeySpecifier
)[]
export type SeoMetadataCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SeoMetadataLinkingCollectionsKeySpecifier = (
  | 'blogsCollection'
  | 'entryCollection'
  | 'pagesCollection'
  | 'productsCollection'
  | 'rootSettingsCollection'
  | SeoMetadataLinkingCollectionsKeySpecifier
)[]
export type SeoMetadataLinkingCollectionsFieldPolicy = {
  blogsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  pagesCollection?: FieldPolicy<any> | FieldReadFunction<any>
  productsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  rootSettingsCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SmartContractErc721KeySpecifier = (
  | 'abi'
  | 'bannerImage'
  | 'contentfulMetadata'
  | 'displayImage'
  | 'featureImage'
  | 'isSingleTokenMetadataMode'
  | 'linkedFrom'
  | 'logo'
  | 'network'
  | 'singleToken'
  | 'smartContractAddress'
  | 'smartContractGist'
  | 'sys'
  | 'tokensCollection'
  | 'whitelistAddressesCollection'
  | SmartContractErc721KeySpecifier
)[]
export type SmartContractErc721FieldPolicy = {
  abi?: FieldPolicy<any> | FieldReadFunction<any>
  bannerImage?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  displayImage?: FieldPolicy<any> | FieldReadFunction<any>
  featureImage?: FieldPolicy<any> | FieldReadFunction<any>
  isSingleTokenMetadataMode?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  logo?: FieldPolicy<any> | FieldReadFunction<any>
  network?: FieldPolicy<any> | FieldReadFunction<any>
  singleToken?: FieldPolicy<any> | FieldReadFunction<any>
  smartContractAddress?: FieldPolicy<any> | FieldReadFunction<any>
  smartContractGist?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  tokensCollection?: FieldPolicy<any> | FieldReadFunction<any>
  whitelistAddressesCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SmartContractErc721CollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SmartContractErc721CollectionKeySpecifier
)[]
export type SmartContractErc721CollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SmartContractErc721LinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'sectionsMintListComponentCollection'
  | SmartContractErc721LinkingCollectionsKeySpecifier
)[]
export type SmartContractErc721LinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMintListComponentCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SmartContractErc721TokenKeySpecifier = (
  | 'animationUrl'
  | 'attributesCollection'
  | 'backgroundColor'
  | 'contentfulMetadata'
  | 'description'
  | 'externalUrl'
  | 'image'
  | 'linkedFrom'
  | 'name'
  | 'sys'
  | 'tokenId'
  | 'youtubeUrl'
  | SmartContractErc721TokenKeySpecifier
)[]
export type SmartContractErc721TokenFieldPolicy = {
  animationUrl?: FieldPolicy<any> | FieldReadFunction<any>
  attributesCollection?: FieldPolicy<any> | FieldReadFunction<any>
  backgroundColor?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  externalUrl?: FieldPolicy<any> | FieldReadFunction<any>
  image?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  tokenId?: FieldPolicy<any> | FieldReadFunction<any>
  youtubeUrl?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SmartContractErc721TokenAttributeKeySpecifier = (
  | 'contentfulMetadata'
  | 'displayType'
  | 'linkedFrom'
  | 'sys'
  | 'traitType'
  | 'value'
  | SmartContractErc721TokenAttributeKeySpecifier
)[]
export type SmartContractErc721TokenAttributeFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  displayType?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  traitType?: FieldPolicy<any> | FieldReadFunction<any>
  value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SmartContractErc721TokenAttributeCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SmartContractErc721TokenAttributeCollectionKeySpecifier
)[]
export type SmartContractErc721TokenAttributeCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SmartContractErc721TokenAttributeLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'smartContractErc721TokenCollection'
  | SmartContractErc721TokenAttributeLinkingCollectionsKeySpecifier
)[]
export type SmartContractErc721TokenAttributeLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  smartContractErc721TokenCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SmartContractErc721TokenAttributesCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SmartContractErc721TokenAttributesCollectionKeySpecifier
)[]
export type SmartContractErc721TokenAttributesCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SmartContractErc721TokenCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SmartContractErc721TokenCollectionKeySpecifier
)[]
export type SmartContractErc721TokenCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SmartContractErc721TokenLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | SmartContractErc721TokenLinkingCollectionsKeySpecifier
)[]
export type SmartContractErc721TokenLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SmartContractErc721TokensCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SmartContractErc721TokensCollectionKeySpecifier
)[]
export type SmartContractErc721TokensCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SmartContractErc721WhitelistAddressKeySpecifier = (
  | 'alreadyMinted'
  | 'contentfulMetadata'
  | 'ethAddress'
  | 'linkedFrom'
  | 'name'
  | 'sys'
  | SmartContractErc721WhitelistAddressKeySpecifier
)[]
export type SmartContractErc721WhitelistAddressFieldPolicy = {
  alreadyMinted?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  ethAddress?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SmartContractErc721WhitelistAddressCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SmartContractErc721WhitelistAddressCollectionKeySpecifier
)[]
export type SmartContractErc721WhitelistAddressCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SmartContractErc721WhitelistAddressLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'smartContractErc721Collection'
  | SmartContractErc721WhitelistAddressLinkingCollectionsKeySpecifier
)[]
export type SmartContractErc721WhitelistAddressLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  smartContractErc721Collection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SmartContractErc721WhitelistAddressesCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SmartContractErc721WhitelistAddressesCollectionKeySpecifier
)[]
export type SmartContractErc721WhitelistAddressesCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SocialMediaLinkKeySpecifier = (
  | 'contentfulMetadata'
  | 'isExternal'
  | 'link'
  | 'linkedFrom'
  | 'overwriteDisplayIconWithIconifyIcon'
  | 'sys'
  | 'title'
  | 'type'
  | SocialMediaLinkKeySpecifier
)[]
export type SocialMediaLinkFieldPolicy = {
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  isExternal?: FieldPolicy<any> | FieldReadFunction<any>
  link?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  overwriteDisplayIconWithIconifyIcon?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SocialMediaLinkCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | SocialMediaLinkCollectionKeySpecifier
)[]
export type SocialMediaLinkCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SocialMediaLinkLinkingCollectionsKeySpecifier = (
  | 'entryCollection'
  | 'rootSettingsCollection'
  | SocialMediaLinkLinkingCollectionsKeySpecifier
)[]
export type SocialMediaLinkLinkingCollectionsFieldPolicy = {
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  rootSettingsCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SupportingLanguageKeySpecifier = (
  | '_id'
  | 'createdAt'
  | 'isDefault'
  | 'isNew'
  | 'name'
  | 'slug'
  | SupportingLanguageKeySpecifier
)[]
export type SupportingLanguageFieldPolicy = {
  _id?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  isDefault?: FieldPolicy<any> | FieldReadFunction<any>
  isNew?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  slug?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SupportingToneKeySpecifier = (
  | '_id'
  | 'createdAt'
  | 'isNew'
  | 'name'
  | 'slug'
  | SupportingToneKeySpecifier
)[]
export type SupportingToneFieldPolicy = {
  _id?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  isNew?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  slug?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SupportingUseCaseKeySpecifier = (
  | '_id'
  | 'caption'
  | 'contextInputs'
  | 'createdAt'
  | 'description'
  | 'image'
  | 'info'
  | 'isCustom'
  | 'isNew'
  | 'key'
  | 'name'
  | 'slug'
  | 'variants'
  | SupportingUseCaseKeySpecifier
)[]
export type SupportingUseCaseFieldPolicy = {
  _id?: FieldPolicy<any> | FieldReadFunction<any>
  caption?: FieldPolicy<any> | FieldReadFunction<any>
  contextInputs?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  image?: FieldPolicy<any> | FieldReadFunction<any>
  info?: FieldPolicy<any> | FieldReadFunction<any>
  isCustom?: FieldPolicy<any> | FieldReadFunction<any>
  isNew?: FieldPolicy<any> | FieldReadFunction<any>
  key?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  slug?: FieldPolicy<any> | FieldReadFunction<any>
  variants?: FieldPolicy<any> | FieldReadFunction<any>
}
export type SysKeySpecifier = (
  | 'environmentId'
  | 'firstPublishedAt'
  | 'id'
  | 'publishedAt'
  | 'publishedVersion'
  | 'spaceId'
  | SysKeySpecifier
)[]
export type SysFieldPolicy = {
  environmentId?: FieldPolicy<any> | FieldReadFunction<any>
  firstPublishedAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  publishedAt?: FieldPolicy<any> | FieldReadFunction<any>
  publishedVersion?: FieldPolicy<any> | FieldReadFunction<any>
  spaceId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TagsKeySpecifier = (
  | 'content'
  | 'contentfulMetadata'
  | 'groupName'
  | 'linkedFrom'
  | 'name'
  | 'seoMetadataItemsCollection'
  | 'slug'
  | 'sys'
  | 'typeForSearch'
  | TagsKeySpecifier
)[]
export type TagsFieldPolicy = {
  content?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  groupName?: FieldPolicy<any> | FieldReadFunction<any>
  linkedFrom?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  seoMetadataItemsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  slug?: FieldPolicy<any> | FieldReadFunction<any>
  sys?: FieldPolicy<any> | FieldReadFunction<any>
  typeForSearch?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TagsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | TagsCollectionKeySpecifier
)[]
export type TagsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TagsContentKeySpecifier = ('json' | 'links' | TagsContentKeySpecifier)[]
export type TagsContentFieldPolicy = {
  json?: FieldPolicy<any> | FieldReadFunction<any>
  links?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TagsContentAssetsKeySpecifier = (
  | 'block'
  | 'hyperlink'
  | TagsContentAssetsKeySpecifier
)[]
export type TagsContentAssetsFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
  hyperlink?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TagsContentEntriesKeySpecifier = (
  | 'block'
  | 'hyperlink'
  | 'inline'
  | TagsContentEntriesKeySpecifier
)[]
export type TagsContentEntriesFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
  hyperlink?: FieldPolicy<any> | FieldReadFunction<any>
  inline?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TagsContentLinksKeySpecifier = (
  | 'assets'
  | 'entries'
  | 'resources'
  | TagsContentLinksKeySpecifier
)[]
export type TagsContentLinksFieldPolicy = {
  assets?: FieldPolicy<any> | FieldReadFunction<any>
  entries?: FieldPolicy<any> | FieldReadFunction<any>
  resources?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TagsContentResourcesKeySpecifier = ('block' | TagsContentResourcesKeySpecifier)[]
export type TagsContentResourcesFieldPolicy = {
  block?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TagsLinkingCollectionsKeySpecifier = (
  | 'authorsCollection'
  | 'blogsCollection'
  | 'entryCollection'
  | 'productsCollection'
  | TagsLinkingCollectionsKeySpecifier
)[]
export type TagsLinkingCollectionsFieldPolicy = {
  authorsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  blogsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  productsCollection?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TagsSeoMetadataItemsCollectionKeySpecifier = (
  | 'items'
  | 'limit'
  | 'skip'
  | 'total'
  | TagsSeoMetadataItemsCollectionKeySpecifier
)[]
export type TagsSeoMetadataItemsCollectionFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>
  limit?: FieldPolicy<any> | FieldReadFunction<any>
  skip?: FieldPolicy<any> | FieldReadFunction<any>
  total?: FieldPolicy<any> | FieldReadFunction<any>
}
export type TextVariationKeySpecifier = ('text' | TextVariationKeySpecifier)[]
export type TextVariationFieldPolicy = {
  text?: FieldPolicy<any> | FieldReadFunction<any>
}
export type aICompletionRecordsKeySpecifier = (
  | 'actorId'
  | 'clientName'
  | 'completedAt'
  | 'content'
  | 'createdAt'
  | 'id'
  | 'meta'
  | 'updatedAt'
  | aICompletionRecordsKeySpecifier
)[]
export type aICompletionRecordsFieldPolicy = {
  actorId?: FieldPolicy<any> | FieldReadFunction<any>
  clientName?: FieldPolicy<any> | FieldReadFunction<any>
  completedAt?: FieldPolicy<any> | FieldReadFunction<any>
  content?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  meta?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type aICompletionRecords_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | aICompletionRecords_aggregateKeySpecifier
)[]
export type aICompletionRecords_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type aICompletionRecords_aggregate_fieldsKeySpecifier = (
  | 'count'
  | 'max'
  | 'min'
  | aICompletionRecords_aggregate_fieldsKeySpecifier
)[]
export type aICompletionRecords_aggregate_fieldsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>
  max?: FieldPolicy<any> | FieldReadFunction<any>
  min?: FieldPolicy<any> | FieldReadFunction<any>
}
export type aICompletionRecords_max_fieldsKeySpecifier = (
  | 'actorId'
  | 'clientName'
  | 'completedAt'
  | 'content'
  | 'createdAt'
  | 'id'
  | 'updatedAt'
  | aICompletionRecords_max_fieldsKeySpecifier
)[]
export type aICompletionRecords_max_fieldsFieldPolicy = {
  actorId?: FieldPolicy<any> | FieldReadFunction<any>
  clientName?: FieldPolicy<any> | FieldReadFunction<any>
  completedAt?: FieldPolicy<any> | FieldReadFunction<any>
  content?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type aICompletionRecords_min_fieldsKeySpecifier = (
  | 'actorId'
  | 'clientName'
  | 'completedAt'
  | 'content'
  | 'createdAt'
  | 'id'
  | 'updatedAt'
  | aICompletionRecords_min_fieldsKeySpecifier
)[]
export type aICompletionRecords_min_fieldsFieldPolicy = {
  actorId?: FieldPolicy<any> | FieldReadFunction<any>
  clientName?: FieldPolicy<any> | FieldReadFunction<any>
  completedAt?: FieldPolicy<any> | FieldReadFunction<any>
  content?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type aICompletionRecords_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | aICompletionRecords_mutation_responseKeySpecifier
)[]
export type aICompletionRecords_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>
  returning?: FieldPolicy<any> | FieldReadFunction<any>
}
export type appsKeySpecifier = (
  | 'createdAt'
  | 'formAnswers'
  | 'formAnswers_aggregate'
  | 'forms'
  | 'forms_aggregate'
  | 'host'
  | 'id'
  | 'stripeWritersoftConfigurationId'
  | 'stripeWritersoftLookupKey'
  | 'subscriptionItems'
  | 'subscriptionItems_aggregate'
  | 'subscriptions'
  | 'subscriptions_aggregate'
  | 'updatedAt'
  | 'users'
  | 'users_aggregate'
  | 'writersoftUsageReports'
  | 'writersoftUsageReports_aggregate'
  | appsKeySpecifier
)[]
export type appsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  formAnswers?: FieldPolicy<any> | FieldReadFunction<any>
  formAnswers_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  forms?: FieldPolicy<any> | FieldReadFunction<any>
  forms_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  host?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  stripeWritersoftConfigurationId?: FieldPolicy<any> | FieldReadFunction<any>
  stripeWritersoftLookupKey?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptionItems?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptionItems_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptions?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptions_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  users?: FieldPolicy<any> | FieldReadFunction<any>
  users_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  writersoftUsageReports?: FieldPolicy<any> | FieldReadFunction<any>
  writersoftUsageReports_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
}
export type apps_aggregateKeySpecifier = ('aggregate' | 'nodes' | apps_aggregateKeySpecifier)[]
export type apps_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type apps_aggregate_fieldsKeySpecifier = (
  | 'count'
  | 'max'
  | 'min'
  | apps_aggregate_fieldsKeySpecifier
)[]
export type apps_aggregate_fieldsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>
  max?: FieldPolicy<any> | FieldReadFunction<any>
  min?: FieldPolicy<any> | FieldReadFunction<any>
}
export type apps_max_fieldsKeySpecifier = (
  | 'createdAt'
  | 'host'
  | 'id'
  | 'stripeWritersoftConfigurationId'
  | 'stripeWritersoftLookupKey'
  | 'updatedAt'
  | apps_max_fieldsKeySpecifier
)[]
export type apps_max_fieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  host?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  stripeWritersoftConfigurationId?: FieldPolicy<any> | FieldReadFunction<any>
  stripeWritersoftLookupKey?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type apps_min_fieldsKeySpecifier = (
  | 'createdAt'
  | 'host'
  | 'id'
  | 'stripeWritersoftConfigurationId'
  | 'stripeWritersoftLookupKey'
  | 'updatedAt'
  | apps_min_fieldsKeySpecifier
)[]
export type apps_min_fieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  host?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  stripeWritersoftConfigurationId?: FieldPolicy<any> | FieldReadFunction<any>
  stripeWritersoftLookupKey?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type apps_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | apps_mutation_responseKeySpecifier
)[]
export type apps_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>
  returning?: FieldPolicy<any> | FieldReadFunction<any>
}
export type discordMessagesKeySpecifier = (
  | 'attachments'
  | 'createdAt'
  | 'event'
  | 'id'
  | 'message'
  | 'updatedAt'
  | discordMessagesKeySpecifier
)[]
export type discordMessagesFieldPolicy = {
  attachments?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  event?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  message?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type discordMessages_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | discordMessages_aggregateKeySpecifier
)[]
export type discordMessages_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type discordMessages_aggregate_fieldsKeySpecifier = (
  | 'count'
  | 'max'
  | 'min'
  | discordMessages_aggregate_fieldsKeySpecifier
)[]
export type discordMessages_aggregate_fieldsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>
  max?: FieldPolicy<any> | FieldReadFunction<any>
  min?: FieldPolicy<any> | FieldReadFunction<any>
}
export type discordMessages_max_fieldsKeySpecifier = (
  | 'createdAt'
  | 'event'
  | 'id'
  | 'updatedAt'
  | discordMessages_max_fieldsKeySpecifier
)[]
export type discordMessages_max_fieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  event?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type discordMessages_min_fieldsKeySpecifier = (
  | 'createdAt'
  | 'event'
  | 'id'
  | 'updatedAt'
  | discordMessages_min_fieldsKeySpecifier
)[]
export type discordMessages_min_fieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  event?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type discordMessages_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | discordMessages_mutation_responseKeySpecifier
)[]
export type discordMessages_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>
  returning?: FieldPolicy<any> | FieldReadFunction<any>
}
export type formAnswersKeySpecifier = (
  | 'app'
  | 'appId'
  | 'contentfulFormComponentId'
  | 'createdAt'
  | 'form'
  | 'formId'
  | 'id'
  | 'type'
  | 'updatedAt'
  | 'value'
  | formAnswersKeySpecifier
)[]
export type formAnswersFieldPolicy = {
  app?: FieldPolicy<any> | FieldReadFunction<any>
  appId?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulFormComponentId?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  form?: FieldPolicy<any> | FieldReadFunction<any>
  formId?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type formAnswers_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | formAnswers_aggregateKeySpecifier
)[]
export type formAnswers_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type formAnswers_aggregate_fieldsKeySpecifier = (
  | 'count'
  | 'max'
  | 'min'
  | formAnswers_aggregate_fieldsKeySpecifier
)[]
export type formAnswers_aggregate_fieldsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>
  max?: FieldPolicy<any> | FieldReadFunction<any>
  min?: FieldPolicy<any> | FieldReadFunction<any>
}
export type formAnswers_max_fieldsKeySpecifier = (
  | 'appId'
  | 'contentfulFormComponentId'
  | 'createdAt'
  | 'formId'
  | 'id'
  | 'type'
  | 'updatedAt'
  | 'value'
  | formAnswers_max_fieldsKeySpecifier
)[]
export type formAnswers_max_fieldsFieldPolicy = {
  appId?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulFormComponentId?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  formId?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type formAnswers_min_fieldsKeySpecifier = (
  | 'appId'
  | 'contentfulFormComponentId'
  | 'createdAt'
  | 'formId'
  | 'id'
  | 'type'
  | 'updatedAt'
  | 'value'
  | formAnswers_min_fieldsKeySpecifier
)[]
export type formAnswers_min_fieldsFieldPolicy = {
  appId?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulFormComponentId?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  formId?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  type?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  value?: FieldPolicy<any> | FieldReadFunction<any>
}
export type formAnswers_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | formAnswers_mutation_responseKeySpecifier
)[]
export type formAnswers_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>
  returning?: FieldPolicy<any> | FieldReadFunction<any>
}
export type formsKeySpecifier = (
  | 'app'
  | 'appId'
  | 'contentfulFormId'
  | 'createdAt'
  | 'formAnswers'
  | 'formAnswers_aggregate'
  | 'id'
  | 'updatedAt'
  | 'user'
  | 'userId'
  | formsKeySpecifier
)[]
export type formsFieldPolicy = {
  app?: FieldPolicy<any> | FieldReadFunction<any>
  appId?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulFormId?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  formAnswers?: FieldPolicy<any> | FieldReadFunction<any>
  formAnswers_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  user?: FieldPolicy<any> | FieldReadFunction<any>
  userId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type forms_aggregateKeySpecifier = ('aggregate' | 'nodes' | forms_aggregateKeySpecifier)[]
export type forms_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type forms_aggregate_fieldsKeySpecifier = (
  | 'count'
  | 'max'
  | 'min'
  | forms_aggregate_fieldsKeySpecifier
)[]
export type forms_aggregate_fieldsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>
  max?: FieldPolicy<any> | FieldReadFunction<any>
  min?: FieldPolicy<any> | FieldReadFunction<any>
}
export type forms_max_fieldsKeySpecifier = (
  | 'appId'
  | 'contentfulFormId'
  | 'createdAt'
  | 'id'
  | 'updatedAt'
  | 'userId'
  | forms_max_fieldsKeySpecifier
)[]
export type forms_max_fieldsFieldPolicy = {
  appId?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulFormId?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type forms_min_fieldsKeySpecifier = (
  | 'appId'
  | 'contentfulFormId'
  | 'createdAt'
  | 'id'
  | 'updatedAt'
  | 'userId'
  | forms_min_fieldsKeySpecifier
)[]
export type forms_min_fieldsFieldPolicy = {
  appId?: FieldPolicy<any> | FieldReadFunction<any>
  contentfulFormId?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type forms_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | forms_mutation_responseKeySpecifier
)[]
export type forms_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>
  returning?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroAccountsKeySpecifier = (
  | 'createdAt'
  | 'email'
  | 'gptHeroPromptUsages'
  | 'gptHeroPromptUsages_aggregate'
  | 'gptHeroPrompts'
  | 'gptHeroPrompts_aggregate'
  | 'id'
  | 'identity'
  | 'name'
  | 'updatedAt'
  | 'website'
  | gptHeroAccountsKeySpecifier
)[]
export type gptHeroAccountsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  email?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroPromptUsages?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroPromptUsages_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroPrompts?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroPrompts_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  identity?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  website?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroAccounts_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | gptHeroAccounts_aggregateKeySpecifier
)[]
export type gptHeroAccounts_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroAccounts_aggregate_fieldsKeySpecifier = (
  | 'count'
  | 'max'
  | 'min'
  | gptHeroAccounts_aggregate_fieldsKeySpecifier
)[]
export type gptHeroAccounts_aggregate_fieldsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>
  max?: FieldPolicy<any> | FieldReadFunction<any>
  min?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroAccounts_max_fieldsKeySpecifier = (
  | 'createdAt'
  | 'email'
  | 'id'
  | 'identity'
  | 'name'
  | 'updatedAt'
  | 'website'
  | gptHeroAccounts_max_fieldsKeySpecifier
)[]
export type gptHeroAccounts_max_fieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  email?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  identity?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  website?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroAccounts_min_fieldsKeySpecifier = (
  | 'createdAt'
  | 'email'
  | 'id'
  | 'identity'
  | 'name'
  | 'updatedAt'
  | 'website'
  | gptHeroAccounts_min_fieldsKeySpecifier
)[]
export type gptHeroAccounts_min_fieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  email?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  identity?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  website?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroAccounts_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | gptHeroAccounts_mutation_responseKeySpecifier
)[]
export type gptHeroAccounts_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>
  returning?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroPromptUsagesKeySpecifier = (
  | 'content'
  | 'createdAt'
  | 'gptHeroAccount'
  | 'gptHeroPrompt'
  | 'id'
  | 'identity'
  | 'lang'
  | 'promptId'
  | gptHeroPromptUsagesKeySpecifier
)[]
export type gptHeroPromptUsagesFieldPolicy = {
  content?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroAccount?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroPrompt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  identity?: FieldPolicy<any> | FieldReadFunction<any>
  lang?: FieldPolicy<any> | FieldReadFunction<any>
  promptId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroPromptUsages_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | gptHeroPromptUsages_aggregateKeySpecifier
)[]
export type gptHeroPromptUsages_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroPromptUsages_aggregate_fieldsKeySpecifier = (
  | 'count'
  | 'max'
  | 'min'
  | gptHeroPromptUsages_aggregate_fieldsKeySpecifier
)[]
export type gptHeroPromptUsages_aggregate_fieldsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>
  max?: FieldPolicy<any> | FieldReadFunction<any>
  min?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroPromptUsages_max_fieldsKeySpecifier = (
  | 'content'
  | 'createdAt'
  | 'id'
  | 'identity'
  | 'lang'
  | 'promptId'
  | gptHeroPromptUsages_max_fieldsKeySpecifier
)[]
export type gptHeroPromptUsages_max_fieldsFieldPolicy = {
  content?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  identity?: FieldPolicy<any> | FieldReadFunction<any>
  lang?: FieldPolicy<any> | FieldReadFunction<any>
  promptId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroPromptUsages_min_fieldsKeySpecifier = (
  | 'content'
  | 'createdAt'
  | 'id'
  | 'identity'
  | 'lang'
  | 'promptId'
  | gptHeroPromptUsages_min_fieldsKeySpecifier
)[]
export type gptHeroPromptUsages_min_fieldsFieldPolicy = {
  content?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  identity?: FieldPolicy<any> | FieldReadFunction<any>
  lang?: FieldPolicy<any> | FieldReadFunction<any>
  promptId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroPromptUsages_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | gptHeroPromptUsages_mutation_responseKeySpecifier
)[]
export type gptHeroPromptUsages_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>
  returning?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroPromptsKeySpecifier = (
  | 'accountId'
  | 'createdAt'
  | 'deletedAt'
  | 'gptHeroAccount'
  | 'gptHeroPromptUsages'
  | 'gptHeroPromptUsages_aggregate'
  | 'gptHeroReportIssues'
  | 'gptHeroReportIssues_aggregate'
  | 'gptHeroTopic'
  | 'hint'
  | 'id'
  | 'isPublic'
  | 'likes'
  | 'originId'
  | 'seen'
  | 'teaser'
  | 'template'
  | 'title'
  | 'topicId'
  | 'updatedAt'
  | 'usage'
  | gptHeroPromptsKeySpecifier
)[]
export type gptHeroPromptsFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  deletedAt?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroAccount?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroPromptUsages?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroPromptUsages_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroReportIssues?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroReportIssues_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroTopic?: FieldPolicy<any> | FieldReadFunction<any>
  hint?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isPublic?: FieldPolicy<any> | FieldReadFunction<any>
  likes?: FieldPolicy<any> | FieldReadFunction<any>
  originId?: FieldPolicy<any> | FieldReadFunction<any>
  seen?: FieldPolicy<any> | FieldReadFunction<any>
  teaser?: FieldPolicy<any> | FieldReadFunction<any>
  template?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
  topicId?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  usage?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroPrompts_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | gptHeroPrompts_aggregateKeySpecifier
)[]
export type gptHeroPrompts_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroPrompts_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | gptHeroPrompts_aggregate_fieldsKeySpecifier
)[]
export type gptHeroPrompts_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>
  count?: FieldPolicy<any> | FieldReadFunction<any>
  max?: FieldPolicy<any> | FieldReadFunction<any>
  min?: FieldPolicy<any> | FieldReadFunction<any>
  stddev?: FieldPolicy<any> | FieldReadFunction<any>
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>
  sum?: FieldPolicy<any> | FieldReadFunction<any>
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>
  variance?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroPrompts_avg_fieldsKeySpecifier = (
  | 'likes'
  | 'seen'
  | 'usage'
  | gptHeroPrompts_avg_fieldsKeySpecifier
)[]
export type gptHeroPrompts_avg_fieldsFieldPolicy = {
  likes?: FieldPolicy<any> | FieldReadFunction<any>
  seen?: FieldPolicy<any> | FieldReadFunction<any>
  usage?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroPrompts_max_fieldsKeySpecifier = (
  | 'accountId'
  | 'createdAt'
  | 'deletedAt'
  | 'hint'
  | 'id'
  | 'likes'
  | 'originId'
  | 'seen'
  | 'teaser'
  | 'template'
  | 'title'
  | 'topicId'
  | 'updatedAt'
  | 'usage'
  | gptHeroPrompts_max_fieldsKeySpecifier
)[]
export type gptHeroPrompts_max_fieldsFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  deletedAt?: FieldPolicy<any> | FieldReadFunction<any>
  hint?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  likes?: FieldPolicy<any> | FieldReadFunction<any>
  originId?: FieldPolicy<any> | FieldReadFunction<any>
  seen?: FieldPolicy<any> | FieldReadFunction<any>
  teaser?: FieldPolicy<any> | FieldReadFunction<any>
  template?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
  topicId?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  usage?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroPrompts_min_fieldsKeySpecifier = (
  | 'accountId'
  | 'createdAt'
  | 'deletedAt'
  | 'hint'
  | 'id'
  | 'likes'
  | 'originId'
  | 'seen'
  | 'teaser'
  | 'template'
  | 'title'
  | 'topicId'
  | 'updatedAt'
  | 'usage'
  | gptHeroPrompts_min_fieldsKeySpecifier
)[]
export type gptHeroPrompts_min_fieldsFieldPolicy = {
  accountId?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  deletedAt?: FieldPolicy<any> | FieldReadFunction<any>
  hint?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  likes?: FieldPolicy<any> | FieldReadFunction<any>
  originId?: FieldPolicy<any> | FieldReadFunction<any>
  seen?: FieldPolicy<any> | FieldReadFunction<any>
  teaser?: FieldPolicy<any> | FieldReadFunction<any>
  template?: FieldPolicy<any> | FieldReadFunction<any>
  title?: FieldPolicy<any> | FieldReadFunction<any>
  topicId?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  usage?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroPrompts_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | gptHeroPrompts_mutation_responseKeySpecifier
)[]
export type gptHeroPrompts_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>
  returning?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroPrompts_stddev_fieldsKeySpecifier = (
  | 'likes'
  | 'seen'
  | 'usage'
  | gptHeroPrompts_stddev_fieldsKeySpecifier
)[]
export type gptHeroPrompts_stddev_fieldsFieldPolicy = {
  likes?: FieldPolicy<any> | FieldReadFunction<any>
  seen?: FieldPolicy<any> | FieldReadFunction<any>
  usage?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroPrompts_stddev_pop_fieldsKeySpecifier = (
  | 'likes'
  | 'seen'
  | 'usage'
  | gptHeroPrompts_stddev_pop_fieldsKeySpecifier
)[]
export type gptHeroPrompts_stddev_pop_fieldsFieldPolicy = {
  likes?: FieldPolicy<any> | FieldReadFunction<any>
  seen?: FieldPolicy<any> | FieldReadFunction<any>
  usage?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroPrompts_stddev_samp_fieldsKeySpecifier = (
  | 'likes'
  | 'seen'
  | 'usage'
  | gptHeroPrompts_stddev_samp_fieldsKeySpecifier
)[]
export type gptHeroPrompts_stddev_samp_fieldsFieldPolicy = {
  likes?: FieldPolicy<any> | FieldReadFunction<any>
  seen?: FieldPolicy<any> | FieldReadFunction<any>
  usage?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroPrompts_sum_fieldsKeySpecifier = (
  | 'likes'
  | 'seen'
  | 'usage'
  | gptHeroPrompts_sum_fieldsKeySpecifier
)[]
export type gptHeroPrompts_sum_fieldsFieldPolicy = {
  likes?: FieldPolicy<any> | FieldReadFunction<any>
  seen?: FieldPolicy<any> | FieldReadFunction<any>
  usage?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroPrompts_var_pop_fieldsKeySpecifier = (
  | 'likes'
  | 'seen'
  | 'usage'
  | gptHeroPrompts_var_pop_fieldsKeySpecifier
)[]
export type gptHeroPrompts_var_pop_fieldsFieldPolicy = {
  likes?: FieldPolicy<any> | FieldReadFunction<any>
  seen?: FieldPolicy<any> | FieldReadFunction<any>
  usage?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroPrompts_var_samp_fieldsKeySpecifier = (
  | 'likes'
  | 'seen'
  | 'usage'
  | gptHeroPrompts_var_samp_fieldsKeySpecifier
)[]
export type gptHeroPrompts_var_samp_fieldsFieldPolicy = {
  likes?: FieldPolicy<any> | FieldReadFunction<any>
  seen?: FieldPolicy<any> | FieldReadFunction<any>
  usage?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroPrompts_variance_fieldsKeySpecifier = (
  | 'likes'
  | 'seen'
  | 'usage'
  | gptHeroPrompts_variance_fieldsKeySpecifier
)[]
export type gptHeroPrompts_variance_fieldsFieldPolicy = {
  likes?: FieldPolicy<any> | FieldReadFunction<any>
  seen?: FieldPolicy<any> | FieldReadFunction<any>
  usage?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroReportIssuesKeySpecifier = (
  | 'comment'
  | 'content'
  | 'createdAt'
  | 'email'
  | 'gptHeroPrompt'
  | 'id'
  | 'isChecked'
  | 'promptId'
  | 'reason'
  | 'updatedAt'
  | gptHeroReportIssuesKeySpecifier
)[]
export type gptHeroReportIssuesFieldPolicy = {
  comment?: FieldPolicy<any> | FieldReadFunction<any>
  content?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  email?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroPrompt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isChecked?: FieldPolicy<any> | FieldReadFunction<any>
  promptId?: FieldPolicy<any> | FieldReadFunction<any>
  reason?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroReportIssues_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | gptHeroReportIssues_aggregateKeySpecifier
)[]
export type gptHeroReportIssues_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroReportIssues_aggregate_fieldsKeySpecifier = (
  | 'count'
  | 'max'
  | 'min'
  | gptHeroReportIssues_aggregate_fieldsKeySpecifier
)[]
export type gptHeroReportIssues_aggregate_fieldsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>
  max?: FieldPolicy<any> | FieldReadFunction<any>
  min?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroReportIssues_max_fieldsKeySpecifier = (
  | 'comment'
  | 'content'
  | 'createdAt'
  | 'email'
  | 'id'
  | 'promptId'
  | 'reason'
  | 'updatedAt'
  | gptHeroReportIssues_max_fieldsKeySpecifier
)[]
export type gptHeroReportIssues_max_fieldsFieldPolicy = {
  comment?: FieldPolicy<any> | FieldReadFunction<any>
  content?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  email?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  promptId?: FieldPolicy<any> | FieldReadFunction<any>
  reason?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroReportIssues_min_fieldsKeySpecifier = (
  | 'comment'
  | 'content'
  | 'createdAt'
  | 'email'
  | 'id'
  | 'promptId'
  | 'reason'
  | 'updatedAt'
  | gptHeroReportIssues_min_fieldsKeySpecifier
)[]
export type gptHeroReportIssues_min_fieldsFieldPolicy = {
  comment?: FieldPolicy<any> | FieldReadFunction<any>
  content?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  email?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  promptId?: FieldPolicy<any> | FieldReadFunction<any>
  reason?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroReportIssues_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | gptHeroReportIssues_mutation_responseKeySpecifier
)[]
export type gptHeroReportIssues_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>
  returning?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroTopicsKeySpecifier = (
  | 'createdAt'
  | 'gptHeroPrompts'
  | 'gptHeroPrompts_aggregate'
  | 'id'
  | 'name'
  | 'updatedAt'
  | gptHeroTopicsKeySpecifier
)[]
export type gptHeroTopicsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroPrompts?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroPrompts_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroTopics_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | gptHeroTopics_aggregateKeySpecifier
)[]
export type gptHeroTopics_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroTopics_aggregate_fieldsKeySpecifier = (
  | 'count'
  | 'max'
  | 'min'
  | gptHeroTopics_aggregate_fieldsKeySpecifier
)[]
export type gptHeroTopics_aggregate_fieldsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>
  max?: FieldPolicy<any> | FieldReadFunction<any>
  min?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroTopics_max_fieldsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'name'
  | 'updatedAt'
  | gptHeroTopics_max_fieldsKeySpecifier
)[]
export type gptHeroTopics_max_fieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroTopics_min_fieldsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'name'
  | 'updatedAt'
  | gptHeroTopics_min_fieldsKeySpecifier
)[]
export type gptHeroTopics_min_fieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type gptHeroTopics_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | gptHeroTopics_mutation_responseKeySpecifier
)[]
export type gptHeroTopics_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>
  returning?: FieldPolicy<any> | FieldReadFunction<any>
}
export type midjourneyImagesKeySpecifier = (
  | 'attachment'
  | 'cloudinaryPublicId'
  | 'contentType'
  | 'createdAt'
  | 'deletedAt'
  | 'description'
  | 'discordAttachmentId'
  | 'discordMessageId'
  | 'discordUserId'
  | 'ephemeral'
  | 'height'
  | 'id'
  | 'name'
  | 'prompt'
  | 'proxyURL'
  | 'recordedAt'
  | 'size'
  | 'updatedAt'
  | 'url'
  | 'width'
  | midjourneyImagesKeySpecifier
)[]
export type midjourneyImagesFieldPolicy = {
  attachment?: FieldPolicy<any> | FieldReadFunction<any>
  cloudinaryPublicId?: FieldPolicy<any> | FieldReadFunction<any>
  contentType?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  deletedAt?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  discordAttachmentId?: FieldPolicy<any> | FieldReadFunction<any>
  discordMessageId?: FieldPolicy<any> | FieldReadFunction<any>
  discordUserId?: FieldPolicy<any> | FieldReadFunction<any>
  ephemeral?: FieldPolicy<any> | FieldReadFunction<any>
  height?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  prompt?: FieldPolicy<any> | FieldReadFunction<any>
  proxyURL?: FieldPolicy<any> | FieldReadFunction<any>
  recordedAt?: FieldPolicy<any> | FieldReadFunction<any>
  size?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  url?: FieldPolicy<any> | FieldReadFunction<any>
  width?: FieldPolicy<any> | FieldReadFunction<any>
}
export type midjourneyImages_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | midjourneyImages_aggregateKeySpecifier
)[]
export type midjourneyImages_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type midjourneyImages_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | midjourneyImages_aggregate_fieldsKeySpecifier
)[]
export type midjourneyImages_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>
  count?: FieldPolicy<any> | FieldReadFunction<any>
  max?: FieldPolicy<any> | FieldReadFunction<any>
  min?: FieldPolicy<any> | FieldReadFunction<any>
  stddev?: FieldPolicy<any> | FieldReadFunction<any>
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>
  sum?: FieldPolicy<any> | FieldReadFunction<any>
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>
  variance?: FieldPolicy<any> | FieldReadFunction<any>
}
export type midjourneyImages_avg_fieldsKeySpecifier = (
  | 'height'
  | 'size'
  | 'width'
  | midjourneyImages_avg_fieldsKeySpecifier
)[]
export type midjourneyImages_avg_fieldsFieldPolicy = {
  height?: FieldPolicy<any> | FieldReadFunction<any>
  size?: FieldPolicy<any> | FieldReadFunction<any>
  width?: FieldPolicy<any> | FieldReadFunction<any>
}
export type midjourneyImages_max_fieldsKeySpecifier = (
  | 'attachment'
  | 'cloudinaryPublicId'
  | 'contentType'
  | 'createdAt'
  | 'deletedAt'
  | 'description'
  | 'discordAttachmentId'
  | 'discordMessageId'
  | 'discordUserId'
  | 'height'
  | 'id'
  | 'name'
  | 'prompt'
  | 'proxyURL'
  | 'recordedAt'
  | 'size'
  | 'updatedAt'
  | 'url'
  | 'width'
  | midjourneyImages_max_fieldsKeySpecifier
)[]
export type midjourneyImages_max_fieldsFieldPolicy = {
  attachment?: FieldPolicy<any> | FieldReadFunction<any>
  cloudinaryPublicId?: FieldPolicy<any> | FieldReadFunction<any>
  contentType?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  deletedAt?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  discordAttachmentId?: FieldPolicy<any> | FieldReadFunction<any>
  discordMessageId?: FieldPolicy<any> | FieldReadFunction<any>
  discordUserId?: FieldPolicy<any> | FieldReadFunction<any>
  height?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  prompt?: FieldPolicy<any> | FieldReadFunction<any>
  proxyURL?: FieldPolicy<any> | FieldReadFunction<any>
  recordedAt?: FieldPolicy<any> | FieldReadFunction<any>
  size?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  url?: FieldPolicy<any> | FieldReadFunction<any>
  width?: FieldPolicy<any> | FieldReadFunction<any>
}
export type midjourneyImages_min_fieldsKeySpecifier = (
  | 'attachment'
  | 'cloudinaryPublicId'
  | 'contentType'
  | 'createdAt'
  | 'deletedAt'
  | 'description'
  | 'discordAttachmentId'
  | 'discordMessageId'
  | 'discordUserId'
  | 'height'
  | 'id'
  | 'name'
  | 'prompt'
  | 'proxyURL'
  | 'recordedAt'
  | 'size'
  | 'updatedAt'
  | 'url'
  | 'width'
  | midjourneyImages_min_fieldsKeySpecifier
)[]
export type midjourneyImages_min_fieldsFieldPolicy = {
  attachment?: FieldPolicy<any> | FieldReadFunction<any>
  cloudinaryPublicId?: FieldPolicy<any> | FieldReadFunction<any>
  contentType?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  deletedAt?: FieldPolicy<any> | FieldReadFunction<any>
  description?: FieldPolicy<any> | FieldReadFunction<any>
  discordAttachmentId?: FieldPolicy<any> | FieldReadFunction<any>
  discordMessageId?: FieldPolicy<any> | FieldReadFunction<any>
  discordUserId?: FieldPolicy<any> | FieldReadFunction<any>
  height?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  prompt?: FieldPolicy<any> | FieldReadFunction<any>
  proxyURL?: FieldPolicy<any> | FieldReadFunction<any>
  recordedAt?: FieldPolicy<any> | FieldReadFunction<any>
  size?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  url?: FieldPolicy<any> | FieldReadFunction<any>
  width?: FieldPolicy<any> | FieldReadFunction<any>
}
export type midjourneyImages_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | midjourneyImages_mutation_responseKeySpecifier
)[]
export type midjourneyImages_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>
  returning?: FieldPolicy<any> | FieldReadFunction<any>
}
export type midjourneyImages_stddev_fieldsKeySpecifier = (
  | 'height'
  | 'size'
  | 'width'
  | midjourneyImages_stddev_fieldsKeySpecifier
)[]
export type midjourneyImages_stddev_fieldsFieldPolicy = {
  height?: FieldPolicy<any> | FieldReadFunction<any>
  size?: FieldPolicy<any> | FieldReadFunction<any>
  width?: FieldPolicy<any> | FieldReadFunction<any>
}
export type midjourneyImages_stddev_pop_fieldsKeySpecifier = (
  | 'height'
  | 'size'
  | 'width'
  | midjourneyImages_stddev_pop_fieldsKeySpecifier
)[]
export type midjourneyImages_stddev_pop_fieldsFieldPolicy = {
  height?: FieldPolicy<any> | FieldReadFunction<any>
  size?: FieldPolicy<any> | FieldReadFunction<any>
  width?: FieldPolicy<any> | FieldReadFunction<any>
}
export type midjourneyImages_stddev_samp_fieldsKeySpecifier = (
  | 'height'
  | 'size'
  | 'width'
  | midjourneyImages_stddev_samp_fieldsKeySpecifier
)[]
export type midjourneyImages_stddev_samp_fieldsFieldPolicy = {
  height?: FieldPolicy<any> | FieldReadFunction<any>
  size?: FieldPolicy<any> | FieldReadFunction<any>
  width?: FieldPolicy<any> | FieldReadFunction<any>
}
export type midjourneyImages_sum_fieldsKeySpecifier = (
  | 'height'
  | 'size'
  | 'width'
  | midjourneyImages_sum_fieldsKeySpecifier
)[]
export type midjourneyImages_sum_fieldsFieldPolicy = {
  height?: FieldPolicy<any> | FieldReadFunction<any>
  size?: FieldPolicy<any> | FieldReadFunction<any>
  width?: FieldPolicy<any> | FieldReadFunction<any>
}
export type midjourneyImages_var_pop_fieldsKeySpecifier = (
  | 'height'
  | 'size'
  | 'width'
  | midjourneyImages_var_pop_fieldsKeySpecifier
)[]
export type midjourneyImages_var_pop_fieldsFieldPolicy = {
  height?: FieldPolicy<any> | FieldReadFunction<any>
  size?: FieldPolicy<any> | FieldReadFunction<any>
  width?: FieldPolicy<any> | FieldReadFunction<any>
}
export type midjourneyImages_var_samp_fieldsKeySpecifier = (
  | 'height'
  | 'size'
  | 'width'
  | midjourneyImages_var_samp_fieldsKeySpecifier
)[]
export type midjourneyImages_var_samp_fieldsFieldPolicy = {
  height?: FieldPolicy<any> | FieldReadFunction<any>
  size?: FieldPolicy<any> | FieldReadFunction<any>
  width?: FieldPolicy<any> | FieldReadFunction<any>
}
export type midjourneyImages_variance_fieldsKeySpecifier = (
  | 'height'
  | 'size'
  | 'width'
  | midjourneyImages_variance_fieldsKeySpecifier
)[]
export type midjourneyImages_variance_fieldsFieldPolicy = {
  height?: FieldPolicy<any> | FieldReadFunction<any>
  size?: FieldPolicy<any> | FieldReadFunction<any>
  width?: FieldPolicy<any> | FieldReadFunction<any>
}
export type mutation_rootKeySpecifier = (
  | 'createPortalSessions'
  | 'createStripeCheckoutSessions'
  | 'delete_aICompletionRecords'
  | 'delete_aICompletionRecords_by_pk'
  | 'delete_apps'
  | 'delete_apps_by_pk'
  | 'delete_discordMessages'
  | 'delete_discordMessages_by_pk'
  | 'delete_formAnswers'
  | 'delete_formAnswers_by_pk'
  | 'delete_forms'
  | 'delete_forms_by_pk'
  | 'delete_gptHeroAccounts'
  | 'delete_gptHeroAccounts_by_pk'
  | 'delete_gptHeroPromptUsages'
  | 'delete_gptHeroPromptUsages_by_pk'
  | 'delete_gptHeroPrompts'
  | 'delete_gptHeroPrompts_by_pk'
  | 'delete_gptHeroReportIssues'
  | 'delete_gptHeroReportIssues_by_pk'
  | 'delete_gptHeroTopics'
  | 'delete_gptHeroTopics_by_pk'
  | 'delete_midjourneyImages'
  | 'delete_midjourneyImages_by_pk'
  | 'delete_newebpayEpgNotifications'
  | 'delete_newebpayEpgNotifications_by_pk'
  | 'delete_promptUsages'
  | 'delete_promptUsages_by_pk'
  | 'delete_stripeWebhookLogs'
  | 'delete_stripeWebhookLogs_by_pk'
  | 'delete_subscriptionItems'
  | 'delete_subscriptionItems_by_pk'
  | 'delete_subscriptions'
  | 'delete_subscriptions_by_pk'
  | 'delete_users'
  | 'delete_users_by_pk'
  | 'delete_writersoftUsageReports'
  | 'delete_writersoftUsageReports_by_pk'
  | 'gptHeroDeletePrompt'
  | 'gptHeroDislikePrompt'
  | 'gptHeroLikePrompt'
  | 'gptHeroSeenPrompt'
  | 'insert_aICompletionRecords'
  | 'insert_aICompletionRecords_one'
  | 'insert_apps'
  | 'insert_apps_one'
  | 'insert_discordMessages'
  | 'insert_discordMessages_one'
  | 'insert_formAnswers'
  | 'insert_formAnswers_one'
  | 'insert_forms'
  | 'insert_forms_one'
  | 'insert_gptHeroAccounts'
  | 'insert_gptHeroAccounts_one'
  | 'insert_gptHeroPromptUsages'
  | 'insert_gptHeroPromptUsages_one'
  | 'insert_gptHeroPrompts'
  | 'insert_gptHeroPrompts_one'
  | 'insert_gptHeroReportIssues'
  | 'insert_gptHeroReportIssues_one'
  | 'insert_gptHeroTopics'
  | 'insert_gptHeroTopics_one'
  | 'insert_midjourneyImages'
  | 'insert_midjourneyImages_one'
  | 'insert_newebpayEpgNotifications'
  | 'insert_newebpayEpgNotifications_one'
  | 'insert_promptUsages'
  | 'insert_promptUsages_one'
  | 'insert_stripeWebhookLogs'
  | 'insert_stripeWebhookLogs_one'
  | 'insert_subscriptionItems'
  | 'insert_subscriptionItems_one'
  | 'insert_subscriptions'
  | 'insert_subscriptions_one'
  | 'insert_users'
  | 'insert_users_one'
  | 'insert_writersoftUsageReports'
  | 'insert_writersoftUsageReports_one'
  | 'updateList'
  | 'updatePromptForChromeExtensionPrompt'
  | 'update_aICompletionRecords'
  | 'update_aICompletionRecords_by_pk'
  | 'update_aICompletionRecords_many'
  | 'update_apps'
  | 'update_apps_by_pk'
  | 'update_apps_many'
  | 'update_discordMessages'
  | 'update_discordMessages_by_pk'
  | 'update_discordMessages_many'
  | 'update_formAnswers'
  | 'update_formAnswers_by_pk'
  | 'update_formAnswers_many'
  | 'update_forms'
  | 'update_forms_by_pk'
  | 'update_forms_many'
  | 'update_gptHeroAccounts'
  | 'update_gptHeroAccounts_by_pk'
  | 'update_gptHeroAccounts_many'
  | 'update_gptHeroPromptUsages'
  | 'update_gptHeroPromptUsages_by_pk'
  | 'update_gptHeroPromptUsages_many'
  | 'update_gptHeroPrompts'
  | 'update_gptHeroPrompts_by_pk'
  | 'update_gptHeroPrompts_many'
  | 'update_gptHeroReportIssues'
  | 'update_gptHeroReportIssues_by_pk'
  | 'update_gptHeroReportIssues_many'
  | 'update_gptHeroTopics'
  | 'update_gptHeroTopics_by_pk'
  | 'update_gptHeroTopics_many'
  | 'update_midjourneyImages'
  | 'update_midjourneyImages_by_pk'
  | 'update_midjourneyImages_many'
  | 'update_newebpayEpgNotifications'
  | 'update_newebpayEpgNotifications_by_pk'
  | 'update_newebpayEpgNotifications_many'
  | 'update_promptUsages'
  | 'update_promptUsages_by_pk'
  | 'update_promptUsages_many'
  | 'update_stripeWebhookLogs'
  | 'update_stripeWebhookLogs_by_pk'
  | 'update_stripeWebhookLogs_many'
  | 'update_subscriptionItems'
  | 'update_subscriptionItems_by_pk'
  | 'update_subscriptionItems_many'
  | 'update_subscriptions'
  | 'update_subscriptions_by_pk'
  | 'update_subscriptions_many'
  | 'update_users'
  | 'update_users_by_pk'
  | 'update_users_many'
  | 'update_writersoftUsageReports'
  | 'update_writersoftUsageReports_by_pk'
  | 'update_writersoftUsageReports_many'
  | mutation_rootKeySpecifier
)[]
export type mutation_rootFieldPolicy = {
  createPortalSessions?: FieldPolicy<any> | FieldReadFunction<any>
  createStripeCheckoutSessions?: FieldPolicy<any> | FieldReadFunction<any>
  delete_aICompletionRecords?: FieldPolicy<any> | FieldReadFunction<any>
  delete_aICompletionRecords_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  delete_apps?: FieldPolicy<any> | FieldReadFunction<any>
  delete_apps_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  delete_discordMessages?: FieldPolicy<any> | FieldReadFunction<any>
  delete_discordMessages_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  delete_formAnswers?: FieldPolicy<any> | FieldReadFunction<any>
  delete_formAnswers_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  delete_forms?: FieldPolicy<any> | FieldReadFunction<any>
  delete_forms_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  delete_gptHeroAccounts?: FieldPolicy<any> | FieldReadFunction<any>
  delete_gptHeroAccounts_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  delete_gptHeroPromptUsages?: FieldPolicy<any> | FieldReadFunction<any>
  delete_gptHeroPromptUsages_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  delete_gptHeroPrompts?: FieldPolicy<any> | FieldReadFunction<any>
  delete_gptHeroPrompts_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  delete_gptHeroReportIssues?: FieldPolicy<any> | FieldReadFunction<any>
  delete_gptHeroReportIssues_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  delete_gptHeroTopics?: FieldPolicy<any> | FieldReadFunction<any>
  delete_gptHeroTopics_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  delete_midjourneyImages?: FieldPolicy<any> | FieldReadFunction<any>
  delete_midjourneyImages_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  delete_newebpayEpgNotifications?: FieldPolicy<any> | FieldReadFunction<any>
  delete_newebpayEpgNotifications_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  delete_promptUsages?: FieldPolicy<any> | FieldReadFunction<any>
  delete_promptUsages_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  delete_stripeWebhookLogs?: FieldPolicy<any> | FieldReadFunction<any>
  delete_stripeWebhookLogs_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  delete_subscriptionItems?: FieldPolicy<any> | FieldReadFunction<any>
  delete_subscriptionItems_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  delete_subscriptions?: FieldPolicy<any> | FieldReadFunction<any>
  delete_subscriptions_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  delete_users?: FieldPolicy<any> | FieldReadFunction<any>
  delete_users_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  delete_writersoftUsageReports?: FieldPolicy<any> | FieldReadFunction<any>
  delete_writersoftUsageReports_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroDeletePrompt?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroDislikePrompt?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroLikePrompt?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroSeenPrompt?: FieldPolicy<any> | FieldReadFunction<any>
  insert_aICompletionRecords?: FieldPolicy<any> | FieldReadFunction<any>
  insert_aICompletionRecords_one?: FieldPolicy<any> | FieldReadFunction<any>
  insert_apps?: FieldPolicy<any> | FieldReadFunction<any>
  insert_apps_one?: FieldPolicy<any> | FieldReadFunction<any>
  insert_discordMessages?: FieldPolicy<any> | FieldReadFunction<any>
  insert_discordMessages_one?: FieldPolicy<any> | FieldReadFunction<any>
  insert_formAnswers?: FieldPolicy<any> | FieldReadFunction<any>
  insert_formAnswers_one?: FieldPolicy<any> | FieldReadFunction<any>
  insert_forms?: FieldPolicy<any> | FieldReadFunction<any>
  insert_forms_one?: FieldPolicy<any> | FieldReadFunction<any>
  insert_gptHeroAccounts?: FieldPolicy<any> | FieldReadFunction<any>
  insert_gptHeroAccounts_one?: FieldPolicy<any> | FieldReadFunction<any>
  insert_gptHeroPromptUsages?: FieldPolicy<any> | FieldReadFunction<any>
  insert_gptHeroPromptUsages_one?: FieldPolicy<any> | FieldReadFunction<any>
  insert_gptHeroPrompts?: FieldPolicy<any> | FieldReadFunction<any>
  insert_gptHeroPrompts_one?: FieldPolicy<any> | FieldReadFunction<any>
  insert_gptHeroReportIssues?: FieldPolicy<any> | FieldReadFunction<any>
  insert_gptHeroReportIssues_one?: FieldPolicy<any> | FieldReadFunction<any>
  insert_gptHeroTopics?: FieldPolicy<any> | FieldReadFunction<any>
  insert_gptHeroTopics_one?: FieldPolicy<any> | FieldReadFunction<any>
  insert_midjourneyImages?: FieldPolicy<any> | FieldReadFunction<any>
  insert_midjourneyImages_one?: FieldPolicy<any> | FieldReadFunction<any>
  insert_newebpayEpgNotifications?: FieldPolicy<any> | FieldReadFunction<any>
  insert_newebpayEpgNotifications_one?: FieldPolicy<any> | FieldReadFunction<any>
  insert_promptUsages?: FieldPolicy<any> | FieldReadFunction<any>
  insert_promptUsages_one?: FieldPolicy<any> | FieldReadFunction<any>
  insert_stripeWebhookLogs?: FieldPolicy<any> | FieldReadFunction<any>
  insert_stripeWebhookLogs_one?: FieldPolicy<any> | FieldReadFunction<any>
  insert_subscriptionItems?: FieldPolicy<any> | FieldReadFunction<any>
  insert_subscriptionItems_one?: FieldPolicy<any> | FieldReadFunction<any>
  insert_subscriptions?: FieldPolicy<any> | FieldReadFunction<any>
  insert_subscriptions_one?: FieldPolicy<any> | FieldReadFunction<any>
  insert_users?: FieldPolicy<any> | FieldReadFunction<any>
  insert_users_one?: FieldPolicy<any> | FieldReadFunction<any>
  insert_writersoftUsageReports?: FieldPolicy<any> | FieldReadFunction<any>
  insert_writersoftUsageReports_one?: FieldPolicy<any> | FieldReadFunction<any>
  updateList?: FieldPolicy<any> | FieldReadFunction<any>
  updatePromptForChromeExtensionPrompt?: FieldPolicy<any> | FieldReadFunction<any>
  update_aICompletionRecords?: FieldPolicy<any> | FieldReadFunction<any>
  update_aICompletionRecords_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  update_aICompletionRecords_many?: FieldPolicy<any> | FieldReadFunction<any>
  update_apps?: FieldPolicy<any> | FieldReadFunction<any>
  update_apps_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  update_apps_many?: FieldPolicy<any> | FieldReadFunction<any>
  update_discordMessages?: FieldPolicy<any> | FieldReadFunction<any>
  update_discordMessages_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  update_discordMessages_many?: FieldPolicy<any> | FieldReadFunction<any>
  update_formAnswers?: FieldPolicy<any> | FieldReadFunction<any>
  update_formAnswers_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  update_formAnswers_many?: FieldPolicy<any> | FieldReadFunction<any>
  update_forms?: FieldPolicy<any> | FieldReadFunction<any>
  update_forms_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  update_forms_many?: FieldPolicy<any> | FieldReadFunction<any>
  update_gptHeroAccounts?: FieldPolicy<any> | FieldReadFunction<any>
  update_gptHeroAccounts_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  update_gptHeroAccounts_many?: FieldPolicy<any> | FieldReadFunction<any>
  update_gptHeroPromptUsages?: FieldPolicy<any> | FieldReadFunction<any>
  update_gptHeroPromptUsages_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  update_gptHeroPromptUsages_many?: FieldPolicy<any> | FieldReadFunction<any>
  update_gptHeroPrompts?: FieldPolicy<any> | FieldReadFunction<any>
  update_gptHeroPrompts_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  update_gptHeroPrompts_many?: FieldPolicy<any> | FieldReadFunction<any>
  update_gptHeroReportIssues?: FieldPolicy<any> | FieldReadFunction<any>
  update_gptHeroReportIssues_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  update_gptHeroReportIssues_many?: FieldPolicy<any> | FieldReadFunction<any>
  update_gptHeroTopics?: FieldPolicy<any> | FieldReadFunction<any>
  update_gptHeroTopics_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  update_gptHeroTopics_many?: FieldPolicy<any> | FieldReadFunction<any>
  update_midjourneyImages?: FieldPolicy<any> | FieldReadFunction<any>
  update_midjourneyImages_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  update_midjourneyImages_many?: FieldPolicy<any> | FieldReadFunction<any>
  update_newebpayEpgNotifications?: FieldPolicy<any> | FieldReadFunction<any>
  update_newebpayEpgNotifications_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  update_newebpayEpgNotifications_many?: FieldPolicy<any> | FieldReadFunction<any>
  update_promptUsages?: FieldPolicy<any> | FieldReadFunction<any>
  update_promptUsages_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  update_promptUsages_many?: FieldPolicy<any> | FieldReadFunction<any>
  update_stripeWebhookLogs?: FieldPolicy<any> | FieldReadFunction<any>
  update_stripeWebhookLogs_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  update_stripeWebhookLogs_many?: FieldPolicy<any> | FieldReadFunction<any>
  update_subscriptionItems?: FieldPolicy<any> | FieldReadFunction<any>
  update_subscriptionItems_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  update_subscriptionItems_many?: FieldPolicy<any> | FieldReadFunction<any>
  update_subscriptions?: FieldPolicy<any> | FieldReadFunction<any>
  update_subscriptions_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  update_subscriptions_many?: FieldPolicy<any> | FieldReadFunction<any>
  update_users?: FieldPolicy<any> | FieldReadFunction<any>
  update_users_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  update_users_many?: FieldPolicy<any> | FieldReadFunction<any>
  update_writersoftUsageReports?: FieldPolicy<any> | FieldReadFunction<any>
  update_writersoftUsageReports_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  update_writersoftUsageReports_many?: FieldPolicy<any> | FieldReadFunction<any>
}
export type newebpayEpgNotificationsKeySpecifier = (
  | 'amt'
  | 'checkCode'
  | 'consigneeName'
  | 'createdAt'
  | 'data'
  | 'id'
  | 'isValid'
  | 'merchantId'
  | 'merchantOrderNo'
  | 'payerAddr'
  | 'payerEmail'
  | 'payerName'
  | 'payerTel'
  | 'paymentType'
  | 'quantity'
  | 'status'
  | 'tradeNo'
  | 'unitPrice'
  | 'updatedAt'
  | newebpayEpgNotificationsKeySpecifier
)[]
export type newebpayEpgNotificationsFieldPolicy = {
  amt?: FieldPolicy<any> | FieldReadFunction<any>
  checkCode?: FieldPolicy<any> | FieldReadFunction<any>
  consigneeName?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  data?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  isValid?: FieldPolicy<any> | FieldReadFunction<any>
  merchantId?: FieldPolicy<any> | FieldReadFunction<any>
  merchantOrderNo?: FieldPolicy<any> | FieldReadFunction<any>
  payerAddr?: FieldPolicy<any> | FieldReadFunction<any>
  payerEmail?: FieldPolicy<any> | FieldReadFunction<any>
  payerName?: FieldPolicy<any> | FieldReadFunction<any>
  payerTel?: FieldPolicy<any> | FieldReadFunction<any>
  paymentType?: FieldPolicy<any> | FieldReadFunction<any>
  quantity?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  tradeNo?: FieldPolicy<any> | FieldReadFunction<any>
  unitPrice?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type newebpayEpgNotifications_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | newebpayEpgNotifications_aggregateKeySpecifier
)[]
export type newebpayEpgNotifications_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type newebpayEpgNotifications_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | newebpayEpgNotifications_aggregate_fieldsKeySpecifier
)[]
export type newebpayEpgNotifications_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>
  count?: FieldPolicy<any> | FieldReadFunction<any>
  max?: FieldPolicy<any> | FieldReadFunction<any>
  min?: FieldPolicy<any> | FieldReadFunction<any>
  stddev?: FieldPolicy<any> | FieldReadFunction<any>
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>
  sum?: FieldPolicy<any> | FieldReadFunction<any>
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>
  variance?: FieldPolicy<any> | FieldReadFunction<any>
}
export type newebpayEpgNotifications_avg_fieldsKeySpecifier = (
  | 'amt'
  | 'quantity'
  | 'unitPrice'
  | newebpayEpgNotifications_avg_fieldsKeySpecifier
)[]
export type newebpayEpgNotifications_avg_fieldsFieldPolicy = {
  amt?: FieldPolicy<any> | FieldReadFunction<any>
  quantity?: FieldPolicy<any> | FieldReadFunction<any>
  unitPrice?: FieldPolicy<any> | FieldReadFunction<any>
}
export type newebpayEpgNotifications_max_fieldsKeySpecifier = (
  | 'amt'
  | 'checkCode'
  | 'consigneeName'
  | 'createdAt'
  | 'id'
  | 'merchantId'
  | 'merchantOrderNo'
  | 'payerAddr'
  | 'payerEmail'
  | 'payerName'
  | 'payerTel'
  | 'paymentType'
  | 'quantity'
  | 'status'
  | 'tradeNo'
  | 'unitPrice'
  | 'updatedAt'
  | newebpayEpgNotifications_max_fieldsKeySpecifier
)[]
export type newebpayEpgNotifications_max_fieldsFieldPolicy = {
  amt?: FieldPolicy<any> | FieldReadFunction<any>
  checkCode?: FieldPolicy<any> | FieldReadFunction<any>
  consigneeName?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  merchantId?: FieldPolicy<any> | FieldReadFunction<any>
  merchantOrderNo?: FieldPolicy<any> | FieldReadFunction<any>
  payerAddr?: FieldPolicy<any> | FieldReadFunction<any>
  payerEmail?: FieldPolicy<any> | FieldReadFunction<any>
  payerName?: FieldPolicy<any> | FieldReadFunction<any>
  payerTel?: FieldPolicy<any> | FieldReadFunction<any>
  paymentType?: FieldPolicy<any> | FieldReadFunction<any>
  quantity?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  tradeNo?: FieldPolicy<any> | FieldReadFunction<any>
  unitPrice?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type newebpayEpgNotifications_min_fieldsKeySpecifier = (
  | 'amt'
  | 'checkCode'
  | 'consigneeName'
  | 'createdAt'
  | 'id'
  | 'merchantId'
  | 'merchantOrderNo'
  | 'payerAddr'
  | 'payerEmail'
  | 'payerName'
  | 'payerTel'
  | 'paymentType'
  | 'quantity'
  | 'status'
  | 'tradeNo'
  | 'unitPrice'
  | 'updatedAt'
  | newebpayEpgNotifications_min_fieldsKeySpecifier
)[]
export type newebpayEpgNotifications_min_fieldsFieldPolicy = {
  amt?: FieldPolicy<any> | FieldReadFunction<any>
  checkCode?: FieldPolicy<any> | FieldReadFunction<any>
  consigneeName?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  merchantId?: FieldPolicy<any> | FieldReadFunction<any>
  merchantOrderNo?: FieldPolicy<any> | FieldReadFunction<any>
  payerAddr?: FieldPolicy<any> | FieldReadFunction<any>
  payerEmail?: FieldPolicy<any> | FieldReadFunction<any>
  payerName?: FieldPolicy<any> | FieldReadFunction<any>
  payerTel?: FieldPolicy<any> | FieldReadFunction<any>
  paymentType?: FieldPolicy<any> | FieldReadFunction<any>
  quantity?: FieldPolicy<any> | FieldReadFunction<any>
  status?: FieldPolicy<any> | FieldReadFunction<any>
  tradeNo?: FieldPolicy<any> | FieldReadFunction<any>
  unitPrice?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type newebpayEpgNotifications_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | newebpayEpgNotifications_mutation_responseKeySpecifier
)[]
export type newebpayEpgNotifications_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>
  returning?: FieldPolicy<any> | FieldReadFunction<any>
}
export type newebpayEpgNotifications_stddev_fieldsKeySpecifier = (
  | 'amt'
  | 'quantity'
  | 'unitPrice'
  | newebpayEpgNotifications_stddev_fieldsKeySpecifier
)[]
export type newebpayEpgNotifications_stddev_fieldsFieldPolicy = {
  amt?: FieldPolicy<any> | FieldReadFunction<any>
  quantity?: FieldPolicy<any> | FieldReadFunction<any>
  unitPrice?: FieldPolicy<any> | FieldReadFunction<any>
}
export type newebpayEpgNotifications_stddev_pop_fieldsKeySpecifier = (
  | 'amt'
  | 'quantity'
  | 'unitPrice'
  | newebpayEpgNotifications_stddev_pop_fieldsKeySpecifier
)[]
export type newebpayEpgNotifications_stddev_pop_fieldsFieldPolicy = {
  amt?: FieldPolicy<any> | FieldReadFunction<any>
  quantity?: FieldPolicy<any> | FieldReadFunction<any>
  unitPrice?: FieldPolicy<any> | FieldReadFunction<any>
}
export type newebpayEpgNotifications_stddev_samp_fieldsKeySpecifier = (
  | 'amt'
  | 'quantity'
  | 'unitPrice'
  | newebpayEpgNotifications_stddev_samp_fieldsKeySpecifier
)[]
export type newebpayEpgNotifications_stddev_samp_fieldsFieldPolicy = {
  amt?: FieldPolicy<any> | FieldReadFunction<any>
  quantity?: FieldPolicy<any> | FieldReadFunction<any>
  unitPrice?: FieldPolicy<any> | FieldReadFunction<any>
}
export type newebpayEpgNotifications_sum_fieldsKeySpecifier = (
  | 'amt'
  | 'quantity'
  | 'unitPrice'
  | newebpayEpgNotifications_sum_fieldsKeySpecifier
)[]
export type newebpayEpgNotifications_sum_fieldsFieldPolicy = {
  amt?: FieldPolicy<any> | FieldReadFunction<any>
  quantity?: FieldPolicy<any> | FieldReadFunction<any>
  unitPrice?: FieldPolicy<any> | FieldReadFunction<any>
}
export type newebpayEpgNotifications_var_pop_fieldsKeySpecifier = (
  | 'amt'
  | 'quantity'
  | 'unitPrice'
  | newebpayEpgNotifications_var_pop_fieldsKeySpecifier
)[]
export type newebpayEpgNotifications_var_pop_fieldsFieldPolicy = {
  amt?: FieldPolicy<any> | FieldReadFunction<any>
  quantity?: FieldPolicy<any> | FieldReadFunction<any>
  unitPrice?: FieldPolicy<any> | FieldReadFunction<any>
}
export type newebpayEpgNotifications_var_samp_fieldsKeySpecifier = (
  | 'amt'
  | 'quantity'
  | 'unitPrice'
  | newebpayEpgNotifications_var_samp_fieldsKeySpecifier
)[]
export type newebpayEpgNotifications_var_samp_fieldsFieldPolicy = {
  amt?: FieldPolicy<any> | FieldReadFunction<any>
  quantity?: FieldPolicy<any> | FieldReadFunction<any>
  unitPrice?: FieldPolicy<any> | FieldReadFunction<any>
}
export type newebpayEpgNotifications_variance_fieldsKeySpecifier = (
  | 'amt'
  | 'quantity'
  | 'unitPrice'
  | newebpayEpgNotifications_variance_fieldsKeySpecifier
)[]
export type newebpayEpgNotifications_variance_fieldsFieldPolicy = {
  amt?: FieldPolicy<any> | FieldReadFunction<any>
  quantity?: FieldPolicy<any> | FieldReadFunction<any>
  unitPrice?: FieldPolicy<any> | FieldReadFunction<any>
}
export type promptUsagesKeySpecifier = (
  | 'appId'
  | 'createdAt'
  | 'discordUserId'
  | 'id'
  | 'language'
  | 'model'
  | 'object'
  | 'promptId'
  | 'response'
  | 'updatedAt'
  | 'usageCompletionTokens'
  | 'usagePromptTokens'
  | 'usageTotalTokens'
  | 'userPrompt'
  | promptUsagesKeySpecifier
)[]
export type promptUsagesFieldPolicy = {
  appId?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  discordUserId?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  language?: FieldPolicy<any> | FieldReadFunction<any>
  model?: FieldPolicy<any> | FieldReadFunction<any>
  object?: FieldPolicy<any> | FieldReadFunction<any>
  promptId?: FieldPolicy<any> | FieldReadFunction<any>
  response?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  usageCompletionTokens?: FieldPolicy<any> | FieldReadFunction<any>
  usagePromptTokens?: FieldPolicy<any> | FieldReadFunction<any>
  usageTotalTokens?: FieldPolicy<any> | FieldReadFunction<any>
  userPrompt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type promptUsages_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | promptUsages_aggregateKeySpecifier
)[]
export type promptUsages_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type promptUsages_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | promptUsages_aggregate_fieldsKeySpecifier
)[]
export type promptUsages_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>
  count?: FieldPolicy<any> | FieldReadFunction<any>
  max?: FieldPolicy<any> | FieldReadFunction<any>
  min?: FieldPolicy<any> | FieldReadFunction<any>
  stddev?: FieldPolicy<any> | FieldReadFunction<any>
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>
  sum?: FieldPolicy<any> | FieldReadFunction<any>
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>
  variance?: FieldPolicy<any> | FieldReadFunction<any>
}
export type promptUsages_avg_fieldsKeySpecifier = (
  | 'usageCompletionTokens'
  | 'usagePromptTokens'
  | 'usageTotalTokens'
  | promptUsages_avg_fieldsKeySpecifier
)[]
export type promptUsages_avg_fieldsFieldPolicy = {
  usageCompletionTokens?: FieldPolicy<any> | FieldReadFunction<any>
  usagePromptTokens?: FieldPolicy<any> | FieldReadFunction<any>
  usageTotalTokens?: FieldPolicy<any> | FieldReadFunction<any>
}
export type promptUsages_max_fieldsKeySpecifier = (
  | 'appId'
  | 'createdAt'
  | 'discordUserId'
  | 'id'
  | 'language'
  | 'model'
  | 'object'
  | 'promptId'
  | 'updatedAt'
  | 'usageCompletionTokens'
  | 'usagePromptTokens'
  | 'usageTotalTokens'
  | 'userPrompt'
  | promptUsages_max_fieldsKeySpecifier
)[]
export type promptUsages_max_fieldsFieldPolicy = {
  appId?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  discordUserId?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  language?: FieldPolicy<any> | FieldReadFunction<any>
  model?: FieldPolicy<any> | FieldReadFunction<any>
  object?: FieldPolicy<any> | FieldReadFunction<any>
  promptId?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  usageCompletionTokens?: FieldPolicy<any> | FieldReadFunction<any>
  usagePromptTokens?: FieldPolicy<any> | FieldReadFunction<any>
  usageTotalTokens?: FieldPolicy<any> | FieldReadFunction<any>
  userPrompt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type promptUsages_min_fieldsKeySpecifier = (
  | 'appId'
  | 'createdAt'
  | 'discordUserId'
  | 'id'
  | 'language'
  | 'model'
  | 'object'
  | 'promptId'
  | 'updatedAt'
  | 'usageCompletionTokens'
  | 'usagePromptTokens'
  | 'usageTotalTokens'
  | 'userPrompt'
  | promptUsages_min_fieldsKeySpecifier
)[]
export type promptUsages_min_fieldsFieldPolicy = {
  appId?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  discordUserId?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  language?: FieldPolicy<any> | FieldReadFunction<any>
  model?: FieldPolicy<any> | FieldReadFunction<any>
  object?: FieldPolicy<any> | FieldReadFunction<any>
  promptId?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  usageCompletionTokens?: FieldPolicy<any> | FieldReadFunction<any>
  usagePromptTokens?: FieldPolicy<any> | FieldReadFunction<any>
  usageTotalTokens?: FieldPolicy<any> | FieldReadFunction<any>
  userPrompt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type promptUsages_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | promptUsages_mutation_responseKeySpecifier
)[]
export type promptUsages_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>
  returning?: FieldPolicy<any> | FieldReadFunction<any>
}
export type promptUsages_stddev_fieldsKeySpecifier = (
  | 'usageCompletionTokens'
  | 'usagePromptTokens'
  | 'usageTotalTokens'
  | promptUsages_stddev_fieldsKeySpecifier
)[]
export type promptUsages_stddev_fieldsFieldPolicy = {
  usageCompletionTokens?: FieldPolicy<any> | FieldReadFunction<any>
  usagePromptTokens?: FieldPolicy<any> | FieldReadFunction<any>
  usageTotalTokens?: FieldPolicy<any> | FieldReadFunction<any>
}
export type promptUsages_stddev_pop_fieldsKeySpecifier = (
  | 'usageCompletionTokens'
  | 'usagePromptTokens'
  | 'usageTotalTokens'
  | promptUsages_stddev_pop_fieldsKeySpecifier
)[]
export type promptUsages_stddev_pop_fieldsFieldPolicy = {
  usageCompletionTokens?: FieldPolicy<any> | FieldReadFunction<any>
  usagePromptTokens?: FieldPolicy<any> | FieldReadFunction<any>
  usageTotalTokens?: FieldPolicy<any> | FieldReadFunction<any>
}
export type promptUsages_stddev_samp_fieldsKeySpecifier = (
  | 'usageCompletionTokens'
  | 'usagePromptTokens'
  | 'usageTotalTokens'
  | promptUsages_stddev_samp_fieldsKeySpecifier
)[]
export type promptUsages_stddev_samp_fieldsFieldPolicy = {
  usageCompletionTokens?: FieldPolicy<any> | FieldReadFunction<any>
  usagePromptTokens?: FieldPolicy<any> | FieldReadFunction<any>
  usageTotalTokens?: FieldPolicy<any> | FieldReadFunction<any>
}
export type promptUsages_sum_fieldsKeySpecifier = (
  | 'usageCompletionTokens'
  | 'usagePromptTokens'
  | 'usageTotalTokens'
  | promptUsages_sum_fieldsKeySpecifier
)[]
export type promptUsages_sum_fieldsFieldPolicy = {
  usageCompletionTokens?: FieldPolicy<any> | FieldReadFunction<any>
  usagePromptTokens?: FieldPolicy<any> | FieldReadFunction<any>
  usageTotalTokens?: FieldPolicy<any> | FieldReadFunction<any>
}
export type promptUsages_var_pop_fieldsKeySpecifier = (
  | 'usageCompletionTokens'
  | 'usagePromptTokens'
  | 'usageTotalTokens'
  | promptUsages_var_pop_fieldsKeySpecifier
)[]
export type promptUsages_var_pop_fieldsFieldPolicy = {
  usageCompletionTokens?: FieldPolicy<any> | FieldReadFunction<any>
  usagePromptTokens?: FieldPolicy<any> | FieldReadFunction<any>
  usageTotalTokens?: FieldPolicy<any> | FieldReadFunction<any>
}
export type promptUsages_var_samp_fieldsKeySpecifier = (
  | 'usageCompletionTokens'
  | 'usagePromptTokens'
  | 'usageTotalTokens'
  | promptUsages_var_samp_fieldsKeySpecifier
)[]
export type promptUsages_var_samp_fieldsFieldPolicy = {
  usageCompletionTokens?: FieldPolicy<any> | FieldReadFunction<any>
  usagePromptTokens?: FieldPolicy<any> | FieldReadFunction<any>
  usageTotalTokens?: FieldPolicy<any> | FieldReadFunction<any>
}
export type promptUsages_variance_fieldsKeySpecifier = (
  | 'usageCompletionTokens'
  | 'usagePromptTokens'
  | 'usageTotalTokens'
  | promptUsages_variance_fieldsKeySpecifier
)[]
export type promptUsages_variance_fieldsFieldPolicy = {
  usageCompletionTokens?: FieldPolicy<any> | FieldReadFunction<any>
  usagePromptTokens?: FieldPolicy<any> | FieldReadFunction<any>
  usageTotalTokens?: FieldPolicy<any> | FieldReadFunction<any>
}
export type query_rootKeySpecifier = (
  | 'aICompletionRecords'
  | 'aICompletionRecords_aggregate'
  | 'aICompletionRecords_by_pk'
  | 'apps'
  | 'apps_aggregate'
  | 'apps_by_pk'
  | 'asset'
  | 'assetCollection'
  | 'authors'
  | 'authorsCollection'
  | 'autoGenArticle'
  | 'autoGenArticleV2'
  | 'autoGenArticleV3ByKeywords'
  | 'autoGenArticleV4ByKeywords'
  | 'blogs'
  | 'blogsCollection'
  | 'callToActionLink'
  | 'callToActionLinkCollection'
  | 'categories'
  | 'categoriesCollection'
  | 'discordMessages'
  | 'discordMessages_aggregate'
  | 'discordMessages_by_pk'
  | 'embeddings'
  | 'embeddingsCollection'
  | 'entryCollection'
  | 'erc721TokenMetadata'
  | 'erc721TokenMetadataAttributeTraitType'
  | 'erc721TokenMetadataAttributeTraitTypeCollection'
  | 'erc721TokenMetadataAttributesText'
  | 'erc721TokenMetadataAttributesTextCollection'
  | 'erc721TokenMetadataCollection'
  | 'formAnswers'
  | 'formAnswers_aggregate'
  | 'formAnswers_by_pk'
  | 'forms'
  | 'forms_aggregate'
  | 'forms_by_pk'
  | 'generateImage'
  | 'generatePrompt'
  | 'generatePromptForChromeExtensionPrompt'
  | 'generatePromptWithTags'
  | 'generics'
  | 'getBlogSectionWriting'
  | 'getTextEditingExpand'
  | 'getTextEditingImprove'
  | 'getTextEditingReword'
  | 'getTextEditingShorten'
  | 'gptHeroAccounts'
  | 'gptHeroAccounts_aggregate'
  | 'gptHeroAccounts_by_pk'
  | 'gptHeroPromptUsages'
  | 'gptHeroPromptUsages_aggregate'
  | 'gptHeroPromptUsages_by_pk'
  | 'gptHeroPrompts'
  | 'gptHeroPrompts_aggregate'
  | 'gptHeroPrompts_by_pk'
  | 'gptHeroReportIssues'
  | 'gptHeroReportIssues_aggregate'
  | 'gptHeroReportIssues_by_pk'
  | 'gptHeroTopics'
  | 'gptHeroTopics_aggregate'
  | 'gptHeroTopics_by_pk'
  | 'midjourneyImages'
  | 'midjourneyImages_aggregate'
  | 'midjourneyImages_by_pk'
  | 'newebpayEpgNotifications'
  | 'newebpayEpgNotifications_aggregate'
  | 'newebpayEpgNotifications_by_pk'
  | 'pages'
  | 'pagesCollection'
  | 'products'
  | 'productsCollection'
  | 'promptEngineering'
  | 'promptEngineeringCollection'
  | 'promptUsages'
  | 'promptUsages_aggregate'
  | 'promptUsages_by_pk'
  | 'prompts'
  | 'promptsForChromeExtension'
  | 'rootSettings'
  | 'rootSettingsCollection'
  | 'sectionsDetailPersonComponent'
  | 'sectionsDetailPersonComponentCollection'
  | 'sectionsDetailPersonList'
  | 'sectionsDetailPersonListCollection'
  | 'sectionsFaqComponent'
  | 'sectionsFaqComponentCollection'
  | 'sectionsFaqList'
  | 'sectionsFaqListCollection'
  | 'sectionsFeaturesList'
  | 'sectionsFeaturesListCollection'
  | 'sectionsFeaturesListComponent'
  | 'sectionsFeaturesListComponentCollection'
  | 'sectionsForm'
  | 'sectionsFormCollection'
  | 'sectionsFormComponent'
  | 'sectionsFormComponentCollection'
  | 'sectionsHero'
  | 'sectionsHeroCollection'
  | 'sectionsImage'
  | 'sectionsImageCollection'
  | 'sectionsMinimalsContactComponent'
  | 'sectionsMinimalsContactComponentCollection'
  | 'sectionsMinimalsContactList'
  | 'sectionsMinimalsContactListCollection'
  | 'sectionsMinimalsGallery'
  | 'sectionsMinimalsGalleryCollection'
  | 'sectionsMinimalsHistoryComponent'
  | 'sectionsMinimalsHistoryComponentCollection'
  | 'sectionsMinimalsHistoryList'
  | 'sectionsMinimalsHistoryListCollection'
  | 'sectionsMinimalsHumanComponent'
  | 'sectionsMinimalsHumanComponentCollection'
  | 'sectionsMinimalsHumanList'
  | 'sectionsMinimalsHumanListCollection'
  | 'sectionsMinimalsImagesList'
  | 'sectionsMinimalsImagesListCollection'
  | 'sectionsMinimalsService'
  | 'sectionsMinimalsServiceCollection'
  | 'sectionsMinimalsServices'
  | 'sectionsMinimalsServicesCollection'
  | 'sectionsMinimalsVideo'
  | 'sectionsMinimalsVideoCollection'
  | 'sectionsMintList'
  | 'sectionsMintListCollection'
  | 'sectionsMintListComponent'
  | 'sectionsMintListComponentCollection'
  | 'sectionsTimer'
  | 'sectionsTimerCollection'
  | 'seoMetadata'
  | 'seoMetadataCollection'
  | 'smartContractErc721'
  | 'smartContractErc721Collection'
  | 'smartContractErc721Token'
  | 'smartContractErc721TokenAttribute'
  | 'smartContractErc721TokenAttributeCollection'
  | 'smartContractErc721TokenCollection'
  | 'smartContractErc721WhitelistAddress'
  | 'smartContractErc721WhitelistAddressCollection'
  | 'socialMediaLink'
  | 'socialMediaLinkCollection'
  | 'stripeWebhookLogs'
  | 'stripeWebhookLogs_aggregate'
  | 'stripeWebhookLogs_by_pk'
  | 'subscriptionItems'
  | 'subscriptionItems_aggregate'
  | 'subscriptionItems_by_pk'
  | 'subscriptions'
  | 'subscriptions_aggregate'
  | 'subscriptions_by_pk'
  | 'supportingLanguages'
  | 'supportingTones'
  | 'supportingUseCases'
  | 'tags'
  | 'tagsCollection'
  | 'users'
  | 'users_aggregate'
  | 'users_by_pk'
  | 'writersoftUsageReports'
  | 'writersoftUsageReports_aggregate'
  | 'writersoftUsageReports_by_pk'
  | query_rootKeySpecifier
)[]
export type query_rootFieldPolicy = {
  aICompletionRecords?: FieldPolicy<any> | FieldReadFunction<any>
  aICompletionRecords_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  aICompletionRecords_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  apps?: FieldPolicy<any> | FieldReadFunction<any>
  apps_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  apps_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  asset?: FieldPolicy<any> | FieldReadFunction<any>
  assetCollection?: FieldPolicy<any> | FieldReadFunction<any>
  authors?: FieldPolicy<any> | FieldReadFunction<any>
  authorsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  autoGenArticle?: FieldPolicy<any> | FieldReadFunction<any>
  autoGenArticleV2?: FieldPolicy<any> | FieldReadFunction<any>
  autoGenArticleV3ByKeywords?: FieldPolicy<any> | FieldReadFunction<any>
  autoGenArticleV4ByKeywords?: FieldPolicy<any> | FieldReadFunction<any>
  blogs?: FieldPolicy<any> | FieldReadFunction<any>
  blogsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  callToActionLink?: FieldPolicy<any> | FieldReadFunction<any>
  callToActionLinkCollection?: FieldPolicy<any> | FieldReadFunction<any>
  categories?: FieldPolicy<any> | FieldReadFunction<any>
  categoriesCollection?: FieldPolicy<any> | FieldReadFunction<any>
  discordMessages?: FieldPolicy<any> | FieldReadFunction<any>
  discordMessages_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  discordMessages_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  embeddings?: FieldPolicy<any> | FieldReadFunction<any>
  embeddingsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  entryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  erc721TokenMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  erc721TokenMetadataAttributeTraitType?: FieldPolicy<any> | FieldReadFunction<any>
  erc721TokenMetadataAttributeTraitTypeCollection?: FieldPolicy<any> | FieldReadFunction<any>
  erc721TokenMetadataAttributesText?: FieldPolicy<any> | FieldReadFunction<any>
  erc721TokenMetadataAttributesTextCollection?: FieldPolicy<any> | FieldReadFunction<any>
  erc721TokenMetadataCollection?: FieldPolicy<any> | FieldReadFunction<any>
  formAnswers?: FieldPolicy<any> | FieldReadFunction<any>
  formAnswers_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  formAnswers_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  forms?: FieldPolicy<any> | FieldReadFunction<any>
  forms_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  forms_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  generateImage?: FieldPolicy<any> | FieldReadFunction<any>
  generatePrompt?: FieldPolicy<any> | FieldReadFunction<any>
  generatePromptForChromeExtensionPrompt?: FieldPolicy<any> | FieldReadFunction<any>
  generatePromptWithTags?: FieldPolicy<any> | FieldReadFunction<any>
  generics?: FieldPolicy<any> | FieldReadFunction<any>
  getBlogSectionWriting?: FieldPolicy<any> | FieldReadFunction<any>
  getTextEditingExpand?: FieldPolicy<any> | FieldReadFunction<any>
  getTextEditingImprove?: FieldPolicy<any> | FieldReadFunction<any>
  getTextEditingReword?: FieldPolicy<any> | FieldReadFunction<any>
  getTextEditingShorten?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroAccounts?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroAccounts_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroAccounts_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroPromptUsages?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroPromptUsages_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroPromptUsages_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroPrompts?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroPrompts_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroPrompts_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroReportIssues?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroReportIssues_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroReportIssues_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroTopics?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroTopics_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroTopics_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  midjourneyImages?: FieldPolicy<any> | FieldReadFunction<any>
  midjourneyImages_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  midjourneyImages_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  newebpayEpgNotifications?: FieldPolicy<any> | FieldReadFunction<any>
  newebpayEpgNotifications_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  newebpayEpgNotifications_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  pages?: FieldPolicy<any> | FieldReadFunction<any>
  pagesCollection?: FieldPolicy<any> | FieldReadFunction<any>
  products?: FieldPolicy<any> | FieldReadFunction<any>
  productsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  promptEngineering?: FieldPolicy<any> | FieldReadFunction<any>
  promptEngineeringCollection?: FieldPolicy<any> | FieldReadFunction<any>
  promptUsages?: FieldPolicy<any> | FieldReadFunction<any>
  promptUsages_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  promptUsages_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  prompts?: FieldPolicy<any> | FieldReadFunction<any>
  promptsForChromeExtension?: FieldPolicy<any> | FieldReadFunction<any>
  rootSettings?: FieldPolicy<any> | FieldReadFunction<any>
  rootSettingsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsDetailPersonComponent?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsDetailPersonComponentCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsDetailPersonList?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsDetailPersonListCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsFaqComponent?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsFaqComponentCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsFaqList?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsFaqListCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsFeaturesList?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsFeaturesListCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsFeaturesListComponent?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsFeaturesListComponentCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsForm?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsFormCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsFormComponent?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsFormComponentCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsHero?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsHeroCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsImage?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsImageCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsContactComponent?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsContactComponentCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsContactList?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsContactListCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsGallery?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsGalleryCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsHistoryComponent?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsHistoryComponentCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsHistoryList?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsHistoryListCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsHumanComponent?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsHumanComponentCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsHumanList?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsHumanListCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsImagesList?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsImagesListCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsService?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsServiceCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsServices?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsServicesCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsVideo?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMinimalsVideoCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMintList?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMintListCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMintListComponent?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsMintListComponentCollection?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsTimer?: FieldPolicy<any> | FieldReadFunction<any>
  sectionsTimerCollection?: FieldPolicy<any> | FieldReadFunction<any>
  seoMetadata?: FieldPolicy<any> | FieldReadFunction<any>
  seoMetadataCollection?: FieldPolicy<any> | FieldReadFunction<any>
  smartContractErc721?: FieldPolicy<any> | FieldReadFunction<any>
  smartContractErc721Collection?: FieldPolicy<any> | FieldReadFunction<any>
  smartContractErc721Token?: FieldPolicy<any> | FieldReadFunction<any>
  smartContractErc721TokenAttribute?: FieldPolicy<any> | FieldReadFunction<any>
  smartContractErc721TokenAttributeCollection?: FieldPolicy<any> | FieldReadFunction<any>
  smartContractErc721TokenCollection?: FieldPolicy<any> | FieldReadFunction<any>
  smartContractErc721WhitelistAddress?: FieldPolicy<any> | FieldReadFunction<any>
  smartContractErc721WhitelistAddressCollection?: FieldPolicy<any> | FieldReadFunction<any>
  socialMediaLink?: FieldPolicy<any> | FieldReadFunction<any>
  socialMediaLinkCollection?: FieldPolicy<any> | FieldReadFunction<any>
  stripeWebhookLogs?: FieldPolicy<any> | FieldReadFunction<any>
  stripeWebhookLogs_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  stripeWebhookLogs_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptionItems?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptionItems_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptionItems_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptions?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptions_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptions_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  supportingLanguages?: FieldPolicy<any> | FieldReadFunction<any>
  supportingTones?: FieldPolicy<any> | FieldReadFunction<any>
  supportingUseCases?: FieldPolicy<any> | FieldReadFunction<any>
  tags?: FieldPolicy<any> | FieldReadFunction<any>
  tagsCollection?: FieldPolicy<any> | FieldReadFunction<any>
  users?: FieldPolicy<any> | FieldReadFunction<any>
  users_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  users_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  writersoftUsageReports?: FieldPolicy<any> | FieldReadFunction<any>
  writersoftUsageReports_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  writersoftUsageReports_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
}
export type stripeWebhookLogsKeySpecifier = (
  | 'createdAt'
  | 'data'
  | 'eventType'
  | 'id'
  | 'updatedAt'
  | stripeWebhookLogsKeySpecifier
)[]
export type stripeWebhookLogsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  data?: FieldPolicy<any> | FieldReadFunction<any>
  eventType?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type stripeWebhookLogs_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | stripeWebhookLogs_aggregateKeySpecifier
)[]
export type stripeWebhookLogs_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type stripeWebhookLogs_aggregate_fieldsKeySpecifier = (
  | 'count'
  | 'max'
  | 'min'
  | stripeWebhookLogs_aggregate_fieldsKeySpecifier
)[]
export type stripeWebhookLogs_aggregate_fieldsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>
  max?: FieldPolicy<any> | FieldReadFunction<any>
  min?: FieldPolicy<any> | FieldReadFunction<any>
}
export type stripeWebhookLogs_max_fieldsKeySpecifier = (
  | 'createdAt'
  | 'eventType'
  | 'id'
  | 'updatedAt'
  | stripeWebhookLogs_max_fieldsKeySpecifier
)[]
export type stripeWebhookLogs_max_fieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  eventType?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type stripeWebhookLogs_min_fieldsKeySpecifier = (
  | 'createdAt'
  | 'eventType'
  | 'id'
  | 'updatedAt'
  | stripeWebhookLogs_min_fieldsKeySpecifier
)[]
export type stripeWebhookLogs_min_fieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  eventType?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type stripeWebhookLogs_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | stripeWebhookLogs_mutation_responseKeySpecifier
)[]
export type stripeWebhookLogs_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>
  returning?: FieldPolicy<any> | FieldReadFunction<any>
}
export type subscriptionItemsKeySpecifier = (
  | 'app'
  | 'appId'
  | 'createdAt'
  | 'id'
  | 'stripeSubscriptionItemId'
  | 'subscription'
  | 'subscriptionId'
  | 'updatedAt'
  | subscriptionItemsKeySpecifier
)[]
export type subscriptionItemsFieldPolicy = {
  app?: FieldPolicy<any> | FieldReadFunction<any>
  appId?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  stripeSubscriptionItemId?: FieldPolicy<any> | FieldReadFunction<any>
  subscription?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptionId?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type subscriptionItems_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | subscriptionItems_aggregateKeySpecifier
)[]
export type subscriptionItems_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type subscriptionItems_aggregate_fieldsKeySpecifier = (
  | 'count'
  | 'max'
  | 'min'
  | subscriptionItems_aggregate_fieldsKeySpecifier
)[]
export type subscriptionItems_aggregate_fieldsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>
  max?: FieldPolicy<any> | FieldReadFunction<any>
  min?: FieldPolicy<any> | FieldReadFunction<any>
}
export type subscriptionItems_max_fieldsKeySpecifier = (
  | 'appId'
  | 'createdAt'
  | 'id'
  | 'stripeSubscriptionItemId'
  | 'subscriptionId'
  | 'updatedAt'
  | subscriptionItems_max_fieldsKeySpecifier
)[]
export type subscriptionItems_max_fieldsFieldPolicy = {
  appId?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  stripeSubscriptionItemId?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptionId?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type subscriptionItems_min_fieldsKeySpecifier = (
  | 'appId'
  | 'createdAt'
  | 'id'
  | 'stripeSubscriptionItemId'
  | 'subscriptionId'
  | 'updatedAt'
  | subscriptionItems_min_fieldsKeySpecifier
)[]
export type subscriptionItems_min_fieldsFieldPolicy = {
  appId?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  stripeSubscriptionItemId?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptionId?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type subscriptionItems_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | subscriptionItems_mutation_responseKeySpecifier
)[]
export type subscriptionItems_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>
  returning?: FieldPolicy<any> | FieldReadFunction<any>
}
export type subscription_rootKeySpecifier = (
  | 'aICompletionRecords'
  | 'aICompletionRecords_aggregate'
  | 'aICompletionRecords_by_pk'
  | 'aICompletionRecords_stream'
  | 'apps'
  | 'apps_aggregate'
  | 'apps_by_pk'
  | 'apps_stream'
  | 'discordMessages'
  | 'discordMessages_aggregate'
  | 'discordMessages_by_pk'
  | 'discordMessages_stream'
  | 'formAnswers'
  | 'formAnswers_aggregate'
  | 'formAnswers_by_pk'
  | 'formAnswers_stream'
  | 'forms'
  | 'forms_aggregate'
  | 'forms_by_pk'
  | 'forms_stream'
  | 'gptHeroAccounts'
  | 'gptHeroAccounts_aggregate'
  | 'gptHeroAccounts_by_pk'
  | 'gptHeroAccounts_stream'
  | 'gptHeroPromptUsages'
  | 'gptHeroPromptUsages_aggregate'
  | 'gptHeroPromptUsages_by_pk'
  | 'gptHeroPromptUsages_stream'
  | 'gptHeroPrompts'
  | 'gptHeroPrompts_aggregate'
  | 'gptHeroPrompts_by_pk'
  | 'gptHeroPrompts_stream'
  | 'gptHeroReportIssues'
  | 'gptHeroReportIssues_aggregate'
  | 'gptHeroReportIssues_by_pk'
  | 'gptHeroReportIssues_stream'
  | 'gptHeroTopics'
  | 'gptHeroTopics_aggregate'
  | 'gptHeroTopics_by_pk'
  | 'gptHeroTopics_stream'
  | 'midjourneyImages'
  | 'midjourneyImages_aggregate'
  | 'midjourneyImages_by_pk'
  | 'midjourneyImages_stream'
  | 'newebpayEpgNotifications'
  | 'newebpayEpgNotifications_aggregate'
  | 'newebpayEpgNotifications_by_pk'
  | 'newebpayEpgNotifications_stream'
  | 'promptUsages'
  | 'promptUsages_aggregate'
  | 'promptUsages_by_pk'
  | 'promptUsages_stream'
  | 'stripeWebhookLogs'
  | 'stripeWebhookLogs_aggregate'
  | 'stripeWebhookLogs_by_pk'
  | 'stripeWebhookLogs_stream'
  | 'subscriptionItems'
  | 'subscriptionItems_aggregate'
  | 'subscriptionItems_by_pk'
  | 'subscriptionItems_stream'
  | 'subscriptions'
  | 'subscriptions_aggregate'
  | 'subscriptions_by_pk'
  | 'subscriptions_stream'
  | 'users'
  | 'users_aggregate'
  | 'users_by_pk'
  | 'users_stream'
  | 'writersoftUsageReports'
  | 'writersoftUsageReports_aggregate'
  | 'writersoftUsageReports_by_pk'
  | 'writersoftUsageReports_stream'
  | subscription_rootKeySpecifier
)[]
export type subscription_rootFieldPolicy = {
  aICompletionRecords?: FieldPolicy<any> | FieldReadFunction<any>
  aICompletionRecords_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  aICompletionRecords_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  aICompletionRecords_stream?: FieldPolicy<any> | FieldReadFunction<any>
  apps?: FieldPolicy<any> | FieldReadFunction<any>
  apps_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  apps_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  apps_stream?: FieldPolicy<any> | FieldReadFunction<any>
  discordMessages?: FieldPolicy<any> | FieldReadFunction<any>
  discordMessages_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  discordMessages_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  discordMessages_stream?: FieldPolicy<any> | FieldReadFunction<any>
  formAnswers?: FieldPolicy<any> | FieldReadFunction<any>
  formAnswers_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  formAnswers_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  formAnswers_stream?: FieldPolicy<any> | FieldReadFunction<any>
  forms?: FieldPolicy<any> | FieldReadFunction<any>
  forms_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  forms_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  forms_stream?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroAccounts?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroAccounts_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroAccounts_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroAccounts_stream?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroPromptUsages?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroPromptUsages_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroPromptUsages_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroPromptUsages_stream?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroPrompts?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroPrompts_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroPrompts_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroPrompts_stream?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroReportIssues?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroReportIssues_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroReportIssues_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroReportIssues_stream?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroTopics?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroTopics_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroTopics_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  gptHeroTopics_stream?: FieldPolicy<any> | FieldReadFunction<any>
  midjourneyImages?: FieldPolicy<any> | FieldReadFunction<any>
  midjourneyImages_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  midjourneyImages_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  midjourneyImages_stream?: FieldPolicy<any> | FieldReadFunction<any>
  newebpayEpgNotifications?: FieldPolicy<any> | FieldReadFunction<any>
  newebpayEpgNotifications_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  newebpayEpgNotifications_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  newebpayEpgNotifications_stream?: FieldPolicy<any> | FieldReadFunction<any>
  promptUsages?: FieldPolicy<any> | FieldReadFunction<any>
  promptUsages_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  promptUsages_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  promptUsages_stream?: FieldPolicy<any> | FieldReadFunction<any>
  stripeWebhookLogs?: FieldPolicy<any> | FieldReadFunction<any>
  stripeWebhookLogs_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  stripeWebhookLogs_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  stripeWebhookLogs_stream?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptionItems?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptionItems_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptionItems_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptionItems_stream?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptions?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptions_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptions_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptions_stream?: FieldPolicy<any> | FieldReadFunction<any>
  users?: FieldPolicy<any> | FieldReadFunction<any>
  users_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  users_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  users_stream?: FieldPolicy<any> | FieldReadFunction<any>
  writersoftUsageReports?: FieldPolicy<any> | FieldReadFunction<any>
  writersoftUsageReports_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  writersoftUsageReports_by_pk?: FieldPolicy<any> | FieldReadFunction<any>
  writersoftUsageReports_stream?: FieldPolicy<any> | FieldReadFunction<any>
}
export type subscriptionsKeySpecifier = (
  | 'app'
  | 'appId'
  | 'createdAt'
  | 'id'
  | 'stripeCheckoutSessionId'
  | 'stripeStatus'
  | 'stripeSubscriptionId'
  | 'subscriptionItems'
  | 'subscriptionItems_aggregate'
  | 'updatedAt'
  | 'user'
  | 'userId'
  | subscriptionsKeySpecifier
)[]
export type subscriptionsFieldPolicy = {
  app?: FieldPolicy<any> | FieldReadFunction<any>
  appId?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  stripeCheckoutSessionId?: FieldPolicy<any> | FieldReadFunction<any>
  stripeStatus?: FieldPolicy<any> | FieldReadFunction<any>
  stripeSubscriptionId?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptionItems?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptionItems_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  user?: FieldPolicy<any> | FieldReadFunction<any>
  userId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type subscriptions_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | subscriptions_aggregateKeySpecifier
)[]
export type subscriptions_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type subscriptions_aggregate_fieldsKeySpecifier = (
  | 'count'
  | 'max'
  | 'min'
  | subscriptions_aggregate_fieldsKeySpecifier
)[]
export type subscriptions_aggregate_fieldsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>
  max?: FieldPolicy<any> | FieldReadFunction<any>
  min?: FieldPolicy<any> | FieldReadFunction<any>
}
export type subscriptions_max_fieldsKeySpecifier = (
  | 'appId'
  | 'createdAt'
  | 'id'
  | 'stripeCheckoutSessionId'
  | 'stripeStatus'
  | 'stripeSubscriptionId'
  | 'updatedAt'
  | 'userId'
  | subscriptions_max_fieldsKeySpecifier
)[]
export type subscriptions_max_fieldsFieldPolicy = {
  appId?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  stripeCheckoutSessionId?: FieldPolicy<any> | FieldReadFunction<any>
  stripeStatus?: FieldPolicy<any> | FieldReadFunction<any>
  stripeSubscriptionId?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type subscriptions_min_fieldsKeySpecifier = (
  | 'appId'
  | 'createdAt'
  | 'id'
  | 'stripeCheckoutSessionId'
  | 'stripeStatus'
  | 'stripeSubscriptionId'
  | 'updatedAt'
  | 'userId'
  | subscriptions_min_fieldsKeySpecifier
)[]
export type subscriptions_min_fieldsFieldPolicy = {
  appId?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  stripeCheckoutSessionId?: FieldPolicy<any> | FieldReadFunction<any>
  stripeStatus?: FieldPolicy<any> | FieldReadFunction<any>
  stripeSubscriptionId?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  userId?: FieldPolicy<any> | FieldReadFunction<any>
}
export type subscriptions_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | subscriptions_mutation_responseKeySpecifier
)[]
export type subscriptions_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>
  returning?: FieldPolicy<any> | FieldReadFunction<any>
}
export type usersKeySpecifier = (
  | 'app'
  | 'appId'
  | 'auth0userId'
  | 'createdAt'
  | 'email'
  | 'forms'
  | 'forms_aggregate'
  | 'id'
  | 'name'
  | 'nickname'
  | 'profileUrl'
  | 'referralCode'
  | 'referredByUser'
  | 'referredByUserId'
  | 'referredUsers'
  | 'referredUsers_aggregate'
  | 'stripeCustomerId'
  | 'subscriptions'
  | 'subscriptions_aggregate'
  | 'updatedAt'
  | 'writersoftUsageReports'
  | 'writersoftUsageReports_aggregate'
  | usersKeySpecifier
)[]
export type usersFieldPolicy = {
  app?: FieldPolicy<any> | FieldReadFunction<any>
  appId?: FieldPolicy<any> | FieldReadFunction<any>
  auth0userId?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  email?: FieldPolicy<any> | FieldReadFunction<any>
  forms?: FieldPolicy<any> | FieldReadFunction<any>
  forms_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  nickname?: FieldPolicy<any> | FieldReadFunction<any>
  profileUrl?: FieldPolicy<any> | FieldReadFunction<any>
  referralCode?: FieldPolicy<any> | FieldReadFunction<any>
  referredByUser?: FieldPolicy<any> | FieldReadFunction<any>
  referredByUserId?: FieldPolicy<any> | FieldReadFunction<any>
  referredUsers?: FieldPolicy<any> | FieldReadFunction<any>
  referredUsers_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  stripeCustomerId?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptions?: FieldPolicy<any> | FieldReadFunction<any>
  subscriptions_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  writersoftUsageReports?: FieldPolicy<any> | FieldReadFunction<any>
  writersoftUsageReports_aggregate?: FieldPolicy<any> | FieldReadFunction<any>
}
export type users_aggregateKeySpecifier = ('aggregate' | 'nodes' | users_aggregateKeySpecifier)[]
export type users_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type users_aggregate_fieldsKeySpecifier = (
  | 'count'
  | 'max'
  | 'min'
  | users_aggregate_fieldsKeySpecifier
)[]
export type users_aggregate_fieldsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>
  max?: FieldPolicy<any> | FieldReadFunction<any>
  min?: FieldPolicy<any> | FieldReadFunction<any>
}
export type users_max_fieldsKeySpecifier = (
  | 'appId'
  | 'auth0userId'
  | 'createdAt'
  | 'email'
  | 'id'
  | 'name'
  | 'nickname'
  | 'profileUrl'
  | 'referralCode'
  | 'referredByUserId'
  | 'stripeCustomerId'
  | 'updatedAt'
  | users_max_fieldsKeySpecifier
)[]
export type users_max_fieldsFieldPolicy = {
  appId?: FieldPolicy<any> | FieldReadFunction<any>
  auth0userId?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  email?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  nickname?: FieldPolicy<any> | FieldReadFunction<any>
  profileUrl?: FieldPolicy<any> | FieldReadFunction<any>
  referralCode?: FieldPolicy<any> | FieldReadFunction<any>
  referredByUserId?: FieldPolicy<any> | FieldReadFunction<any>
  stripeCustomerId?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type users_min_fieldsKeySpecifier = (
  | 'appId'
  | 'auth0userId'
  | 'createdAt'
  | 'email'
  | 'id'
  | 'name'
  | 'nickname'
  | 'profileUrl'
  | 'referralCode'
  | 'referredByUserId'
  | 'stripeCustomerId'
  | 'updatedAt'
  | users_min_fieldsKeySpecifier
)[]
export type users_min_fieldsFieldPolicy = {
  appId?: FieldPolicy<any> | FieldReadFunction<any>
  auth0userId?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  email?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  name?: FieldPolicy<any> | FieldReadFunction<any>
  nickname?: FieldPolicy<any> | FieldReadFunction<any>
  profileUrl?: FieldPolicy<any> | FieldReadFunction<any>
  referralCode?: FieldPolicy<any> | FieldReadFunction<any>
  referredByUserId?: FieldPolicy<any> | FieldReadFunction<any>
  stripeCustomerId?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
}
export type users_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | users_mutation_responseKeySpecifier
)[]
export type users_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>
  returning?: FieldPolicy<any> | FieldReadFunction<any>
}
export type writersoftUsageReportsKeySpecifier = (
  | 'app'
  | 'appId'
  | 'characters'
  | 'createdAt'
  | 'format'
  | 'id'
  | 'inputContexts'
  | 'languageId'
  | 'response'
  | 'sentAt'
  | 'stripeResponse'
  | 'stripeSubscriptionItemId'
  | 'toneId'
  | 'updatedAt'
  | 'useCaseId'
  | 'user'
  | 'userId'
  | 'variations'
  | writersoftUsageReportsKeySpecifier
)[]
export type writersoftUsageReportsFieldPolicy = {
  app?: FieldPolicy<any> | FieldReadFunction<any>
  appId?: FieldPolicy<any> | FieldReadFunction<any>
  characters?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  format?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  inputContexts?: FieldPolicy<any> | FieldReadFunction<any>
  languageId?: FieldPolicy<any> | FieldReadFunction<any>
  response?: FieldPolicy<any> | FieldReadFunction<any>
  sentAt?: FieldPolicy<any> | FieldReadFunction<any>
  stripeResponse?: FieldPolicy<any> | FieldReadFunction<any>
  stripeSubscriptionItemId?: FieldPolicy<any> | FieldReadFunction<any>
  toneId?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  useCaseId?: FieldPolicy<any> | FieldReadFunction<any>
  user?: FieldPolicy<any> | FieldReadFunction<any>
  userId?: FieldPolicy<any> | FieldReadFunction<any>
  variations?: FieldPolicy<any> | FieldReadFunction<any>
}
export type writersoftUsageReports_aggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | writersoftUsageReports_aggregateKeySpecifier
)[]
export type writersoftUsageReports_aggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>
  nodes?: FieldPolicy<any> | FieldReadFunction<any>
}
export type writersoftUsageReports_aggregate_fieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddev_pop'
  | 'stddev_samp'
  | 'sum'
  | 'var_pop'
  | 'var_samp'
  | 'variance'
  | writersoftUsageReports_aggregate_fieldsKeySpecifier
)[]
export type writersoftUsageReports_aggregate_fieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>
  count?: FieldPolicy<any> | FieldReadFunction<any>
  max?: FieldPolicy<any> | FieldReadFunction<any>
  min?: FieldPolicy<any> | FieldReadFunction<any>
  stddev?: FieldPolicy<any> | FieldReadFunction<any>
  stddev_pop?: FieldPolicy<any> | FieldReadFunction<any>
  stddev_samp?: FieldPolicy<any> | FieldReadFunction<any>
  sum?: FieldPolicy<any> | FieldReadFunction<any>
  var_pop?: FieldPolicy<any> | FieldReadFunction<any>
  var_samp?: FieldPolicy<any> | FieldReadFunction<any>
  variance?: FieldPolicy<any> | FieldReadFunction<any>
}
export type writersoftUsageReports_avg_fieldsKeySpecifier = (
  | 'characters'
  | 'variations'
  | writersoftUsageReports_avg_fieldsKeySpecifier
)[]
export type writersoftUsageReports_avg_fieldsFieldPolicy = {
  characters?: FieldPolicy<any> | FieldReadFunction<any>
  variations?: FieldPolicy<any> | FieldReadFunction<any>
}
export type writersoftUsageReports_max_fieldsKeySpecifier = (
  | 'appId'
  | 'characters'
  | 'createdAt'
  | 'format'
  | 'id'
  | 'languageId'
  | 'sentAt'
  | 'stripeSubscriptionItemId'
  | 'toneId'
  | 'updatedAt'
  | 'useCaseId'
  | 'userId'
  | 'variations'
  | writersoftUsageReports_max_fieldsKeySpecifier
)[]
export type writersoftUsageReports_max_fieldsFieldPolicy = {
  appId?: FieldPolicy<any> | FieldReadFunction<any>
  characters?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  format?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  languageId?: FieldPolicy<any> | FieldReadFunction<any>
  sentAt?: FieldPolicy<any> | FieldReadFunction<any>
  stripeSubscriptionItemId?: FieldPolicy<any> | FieldReadFunction<any>
  toneId?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  useCaseId?: FieldPolicy<any> | FieldReadFunction<any>
  userId?: FieldPolicy<any> | FieldReadFunction<any>
  variations?: FieldPolicy<any> | FieldReadFunction<any>
}
export type writersoftUsageReports_min_fieldsKeySpecifier = (
  | 'appId'
  | 'characters'
  | 'createdAt'
  | 'format'
  | 'id'
  | 'languageId'
  | 'sentAt'
  | 'stripeSubscriptionItemId'
  | 'toneId'
  | 'updatedAt'
  | 'useCaseId'
  | 'userId'
  | 'variations'
  | writersoftUsageReports_min_fieldsKeySpecifier
)[]
export type writersoftUsageReports_min_fieldsFieldPolicy = {
  appId?: FieldPolicy<any> | FieldReadFunction<any>
  characters?: FieldPolicy<any> | FieldReadFunction<any>
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>
  format?: FieldPolicy<any> | FieldReadFunction<any>
  id?: FieldPolicy<any> | FieldReadFunction<any>
  languageId?: FieldPolicy<any> | FieldReadFunction<any>
  sentAt?: FieldPolicy<any> | FieldReadFunction<any>
  stripeSubscriptionItemId?: FieldPolicy<any> | FieldReadFunction<any>
  toneId?: FieldPolicy<any> | FieldReadFunction<any>
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>
  useCaseId?: FieldPolicy<any> | FieldReadFunction<any>
  userId?: FieldPolicy<any> | FieldReadFunction<any>
  variations?: FieldPolicy<any> | FieldReadFunction<any>
}
export type writersoftUsageReports_mutation_responseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | writersoftUsageReports_mutation_responseKeySpecifier
)[]
export type writersoftUsageReports_mutation_responseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>
  returning?: FieldPolicy<any> | FieldReadFunction<any>
}
export type writersoftUsageReports_stddev_fieldsKeySpecifier = (
  | 'characters'
  | 'variations'
  | writersoftUsageReports_stddev_fieldsKeySpecifier
)[]
export type writersoftUsageReports_stddev_fieldsFieldPolicy = {
  characters?: FieldPolicy<any> | FieldReadFunction<any>
  variations?: FieldPolicy<any> | FieldReadFunction<any>
}
export type writersoftUsageReports_stddev_pop_fieldsKeySpecifier = (
  | 'characters'
  | 'variations'
  | writersoftUsageReports_stddev_pop_fieldsKeySpecifier
)[]
export type writersoftUsageReports_stddev_pop_fieldsFieldPolicy = {
  characters?: FieldPolicy<any> | FieldReadFunction<any>
  variations?: FieldPolicy<any> | FieldReadFunction<any>
}
export type writersoftUsageReports_stddev_samp_fieldsKeySpecifier = (
  | 'characters'
  | 'variations'
  | writersoftUsageReports_stddev_samp_fieldsKeySpecifier
)[]
export type writersoftUsageReports_stddev_samp_fieldsFieldPolicy = {
  characters?: FieldPolicy<any> | FieldReadFunction<any>
  variations?: FieldPolicy<any> | FieldReadFunction<any>
}
export type writersoftUsageReports_sum_fieldsKeySpecifier = (
  | 'characters'
  | 'variations'
  | writersoftUsageReports_sum_fieldsKeySpecifier
)[]
export type writersoftUsageReports_sum_fieldsFieldPolicy = {
  characters?: FieldPolicy<any> | FieldReadFunction<any>
  variations?: FieldPolicy<any> | FieldReadFunction<any>
}
export type writersoftUsageReports_var_pop_fieldsKeySpecifier = (
  | 'characters'
  | 'variations'
  | writersoftUsageReports_var_pop_fieldsKeySpecifier
)[]
export type writersoftUsageReports_var_pop_fieldsFieldPolicy = {
  characters?: FieldPolicy<any> | FieldReadFunction<any>
  variations?: FieldPolicy<any> | FieldReadFunction<any>
}
export type writersoftUsageReports_var_samp_fieldsKeySpecifier = (
  | 'characters'
  | 'variations'
  | writersoftUsageReports_var_samp_fieldsKeySpecifier
)[]
export type writersoftUsageReports_var_samp_fieldsFieldPolicy = {
  characters?: FieldPolicy<any> | FieldReadFunction<any>
  variations?: FieldPolicy<any> | FieldReadFunction<any>
}
export type writersoftUsageReports_variance_fieldsKeySpecifier = (
  | 'characters'
  | 'variations'
  | writersoftUsageReports_variance_fieldsKeySpecifier
)[]
export type writersoftUsageReports_variance_fieldsFieldPolicy = {
  characters?: FieldPolicy<any> | FieldReadFunction<any>
  variations?: FieldPolicy<any> | FieldReadFunction<any>
}
export type StrictTypedTypePolicies = {
  Asset?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AssetKeySpecifier | (() => undefined | AssetKeySpecifier)
    fields?: AssetFieldPolicy
  }
  AssetCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AssetCollectionKeySpecifier
      | (() => undefined | AssetCollectionKeySpecifier)
    fields?: AssetCollectionFieldPolicy
  }
  AssetLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AssetLinkingCollectionsKeySpecifier
      | (() => undefined | AssetLinkingCollectionsKeySpecifier)
    fields?: AssetLinkingCollectionsFieldPolicy
  }
  Authors?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AuthorsKeySpecifier | (() => undefined | AuthorsKeySpecifier)
    fields?: AuthorsFieldPolicy
  }
  AuthorsBlogIdeasRelatedCategoriesCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AuthorsBlogIdeasRelatedCategoriesCollectionKeySpecifier
      | (() => undefined | AuthorsBlogIdeasRelatedCategoriesCollectionKeySpecifier)
    fields?: AuthorsBlogIdeasRelatedCategoriesCollectionFieldPolicy
  }
  AuthorsBlogIdeasRelatedTagsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AuthorsBlogIdeasRelatedTagsCollectionKeySpecifier
      | (() => undefined | AuthorsBlogIdeasRelatedTagsCollectionKeySpecifier)
    fields?: AuthorsBlogIdeasRelatedTagsCollectionFieldPolicy
  }
  AuthorsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AuthorsCollectionKeySpecifier
      | (() => undefined | AuthorsCollectionKeySpecifier)
    fields?: AuthorsCollectionFieldPolicy
  }
  AuthorsLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AuthorsLinkingCollectionsKeySpecifier
      | (() => undefined | AuthorsLinkingCollectionsKeySpecifier)
    fields?: AuthorsLinkingCollectionsFieldPolicy
  }
  AutoContent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AutoContentKeySpecifier | (() => undefined | AutoContentKeySpecifier)
    fields?: AutoContentFieldPolicy
  }
  AutoGenArticleResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AutoGenArticleResponseKeySpecifier
      | (() => undefined | AutoGenArticleResponseKeySpecifier)
    fields?: AutoGenArticleResponseFieldPolicy
  }
  Blogs?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BlogsKeySpecifier | (() => undefined | BlogsKeySpecifier)
    fields?: BlogsFieldPolicy
  }
  BlogsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlogsCollectionKeySpecifier
      | (() => undefined | BlogsCollectionKeySpecifier)
    fields?: BlogsCollectionFieldPolicy
  }
  BlogsContent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BlogsContentKeySpecifier | (() => undefined | BlogsContentKeySpecifier)
    fields?: BlogsContentFieldPolicy
  }
  BlogsContentAssets?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlogsContentAssetsKeySpecifier
      | (() => undefined | BlogsContentAssetsKeySpecifier)
    fields?: BlogsContentAssetsFieldPolicy
  }
  BlogsContentEntries?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlogsContentEntriesKeySpecifier
      | (() => undefined | BlogsContentEntriesKeySpecifier)
    fields?: BlogsContentEntriesFieldPolicy
  }
  BlogsContentLinks?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlogsContentLinksKeySpecifier
      | (() => undefined | BlogsContentLinksKeySpecifier)
    fields?: BlogsContentLinksFieldPolicy
  }
  BlogsContentResources?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlogsContentResourcesKeySpecifier
      | (() => undefined | BlogsContentResourcesKeySpecifier)
    fields?: BlogsContentResourcesFieldPolicy
  }
  BlogsLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlogsLinkingCollectionsKeySpecifier
      | (() => undefined | BlogsLinkingCollectionsKeySpecifier)
    fields?: BlogsLinkingCollectionsFieldPolicy
  }
  BlogsSeoMetadataItemsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlogsSeoMetadataItemsCollectionKeySpecifier
      | (() => undefined | BlogsSeoMetadataItemsCollectionKeySpecifier)
    fields?: BlogsSeoMetadataItemsCollectionFieldPolicy
  }
  BlogsTagsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlogsTagsCollectionKeySpecifier
      | (() => undefined | BlogsTagsCollectionKeySpecifier)
    fields?: BlogsTagsCollectionFieldPolicy
  }
  CallToActionLink?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CallToActionLinkKeySpecifier
      | (() => undefined | CallToActionLinkKeySpecifier)
    fields?: CallToActionLinkFieldPolicy
  }
  CallToActionLinkCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CallToActionLinkCollectionKeySpecifier
      | (() => undefined | CallToActionLinkCollectionKeySpecifier)
    fields?: CallToActionLinkCollectionFieldPolicy
  }
  CallToActionLinkLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CallToActionLinkLinkingCollectionsKeySpecifier
      | (() => undefined | CallToActionLinkLinkingCollectionsKeySpecifier)
    fields?: CallToActionLinkLinkingCollectionsFieldPolicy
  }
  Categories?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CategoriesKeySpecifier | (() => undefined | CategoriesKeySpecifier)
    fields?: CategoriesFieldPolicy
  }
  CategoriesBlogsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CategoriesBlogsCollectionKeySpecifier
      | (() => undefined | CategoriesBlogsCollectionKeySpecifier)
    fields?: CategoriesBlogsCollectionFieldPolicy
  }
  CategoriesCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CategoriesCollectionKeySpecifier
      | (() => undefined | CategoriesCollectionKeySpecifier)
    fields?: CategoriesCollectionFieldPolicy
  }
  CategoriesLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CategoriesLinkingCollectionsKeySpecifier
      | (() => undefined | CategoriesLinkingCollectionsKeySpecifier)
    fields?: CategoriesLinkingCollectionsFieldPolicy
  }
  ChromeExtensionPromptResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ChromeExtensionPromptResponseKeySpecifier
      | (() => undefined | ChromeExtensionPromptResponseKeySpecifier)
    fields?: ChromeExtensionPromptResponseFieldPolicy
  }
  ContentfulMetadata?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ContentfulMetadataKeySpecifier
      | (() => undefined | ContentfulMetadataKeySpecifier)
    fields?: ContentfulMetadataFieldPolicy
  }
  ContentfulTag?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ContentfulTagKeySpecifier | (() => undefined | ContentfulTagKeySpecifier)
    fields?: ContentfulTagFieldPolicy
  }
  ContextInput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ContextInputKeySpecifier | (() => undefined | ContextInputKeySpecifier)
    fields?: ContextInputFieldPolicy
  }
  CreatePortalSession?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CreatePortalSessionKeySpecifier
      | (() => undefined | CreatePortalSessionKeySpecifier)
    fields?: CreatePortalSessionFieldPolicy
  }
  CreateStripeCheckoutSession?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CreateStripeCheckoutSessionKeySpecifier
      | (() => undefined | CreateStripeCheckoutSessionKeySpecifier)
    fields?: CreateStripeCheckoutSessionFieldPolicy
  }
  DeletePromptResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DeletePromptResponseKeySpecifier
      | (() => undefined | DeletePromptResponseKeySpecifier)
    fields?: DeletePromptResponseFieldPolicy
  }
  DislikePromptResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DislikePromptResponseKeySpecifier
      | (() => undefined | DislikePromptResponseKeySpecifier)
    fields?: DislikePromptResponseFieldPolicy
  }
  Embeddings?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | EmbeddingsKeySpecifier | (() => undefined | EmbeddingsKeySpecifier)
    fields?: EmbeddingsFieldPolicy
  }
  EmbeddingsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EmbeddingsCollectionKeySpecifier
      | (() => undefined | EmbeddingsCollectionKeySpecifier)
    fields?: EmbeddingsCollectionFieldPolicy
  }
  EmbeddingsLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EmbeddingsLinkingCollectionsKeySpecifier
      | (() => undefined | EmbeddingsLinkingCollectionsKeySpecifier)
    fields?: EmbeddingsLinkingCollectionsFieldPolicy
  }
  Entry?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | EntryKeySpecifier | (() => undefined | EntryKeySpecifier)
    fields?: EntryFieldPolicy
  }
  EntryCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EntryCollectionKeySpecifier
      | (() => undefined | EntryCollectionKeySpecifier)
    fields?: EntryCollectionFieldPolicy
  }
  Erc721TokenMetadata?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Erc721TokenMetadataKeySpecifier
      | (() => undefined | Erc721TokenMetadataKeySpecifier)
    fields?: Erc721TokenMetadataFieldPolicy
  }
  Erc721TokenMetadataAttributeTraitType?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Erc721TokenMetadataAttributeTraitTypeKeySpecifier
      | (() => undefined | Erc721TokenMetadataAttributeTraitTypeKeySpecifier)
    fields?: Erc721TokenMetadataAttributeTraitTypeFieldPolicy
  }
  Erc721TokenMetadataAttributeTraitTypeCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Erc721TokenMetadataAttributeTraitTypeCollectionKeySpecifier
      | (() => undefined | Erc721TokenMetadataAttributeTraitTypeCollectionKeySpecifier)
    fields?: Erc721TokenMetadataAttributeTraitTypeCollectionFieldPolicy
  }
  Erc721TokenMetadataAttributeTraitTypeLinkingCollections?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | Erc721TokenMetadataAttributeTraitTypeLinkingCollectionsKeySpecifier
      | (() => undefined | Erc721TokenMetadataAttributeTraitTypeLinkingCollectionsKeySpecifier)
    fields?: Erc721TokenMetadataAttributeTraitTypeLinkingCollectionsFieldPolicy
  }
  Erc721TokenMetadataAttributesCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Erc721TokenMetadataAttributesCollectionKeySpecifier
      | (() => undefined | Erc721TokenMetadataAttributesCollectionKeySpecifier)
    fields?: Erc721TokenMetadataAttributesCollectionFieldPolicy
  }
  Erc721TokenMetadataAttributesText?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Erc721TokenMetadataAttributesTextKeySpecifier
      | (() => undefined | Erc721TokenMetadataAttributesTextKeySpecifier)
    fields?: Erc721TokenMetadataAttributesTextFieldPolicy
  }
  Erc721TokenMetadataAttributesTextCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Erc721TokenMetadataAttributesTextCollectionKeySpecifier
      | (() => undefined | Erc721TokenMetadataAttributesTextCollectionKeySpecifier)
    fields?: Erc721TokenMetadataAttributesTextCollectionFieldPolicy
  }
  Erc721TokenMetadataAttributesTextLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Erc721TokenMetadataAttributesTextLinkingCollectionsKeySpecifier
      | (() => undefined | Erc721TokenMetadataAttributesTextLinkingCollectionsKeySpecifier)
    fields?: Erc721TokenMetadataAttributesTextLinkingCollectionsFieldPolicy
  }
  Erc721TokenMetadataCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Erc721TokenMetadataCollectionKeySpecifier
      | (() => undefined | Erc721TokenMetadataCollectionKeySpecifier)
    fields?: Erc721TokenMetadataCollectionFieldPolicy
  }
  Erc721TokenMetadataLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | Erc721TokenMetadataLinkingCollectionsKeySpecifier
      | (() => undefined | Erc721TokenMetadataLinkingCollectionsKeySpecifier)
    fields?: Erc721TokenMetadataLinkingCollectionsFieldPolicy
  }
  GenerateImageResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GenerateImageResponseKeySpecifier
      | (() => undefined | GenerateImageResponseKeySpecifier)
    fields?: GenerateImageResponseFieldPolicy
  }
  GenericResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GenericResponseKeySpecifier
      | (() => undefined | GenericResponseKeySpecifier)
    fields?: GenericResponseFieldPolicy
  }
  GptHeroDislikePrompt?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GptHeroDislikePromptKeySpecifier
      | (() => undefined | GptHeroDislikePromptKeySpecifier)
    fields?: GptHeroDislikePromptFieldPolicy
  }
  GptHeroLikePrompt?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GptHeroLikePromptKeySpecifier
      | (() => undefined | GptHeroLikePromptKeySpecifier)
    fields?: GptHeroLikePromptFieldPolicy
  }
  GptHeroSeenPrompt?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GptHeroSeenPromptKeySpecifier
      | (() => undefined | GptHeroSeenPromptKeySpecifier)
    fields?: GptHeroSeenPromptFieldPolicy
  }
  ImageCreateResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ImageCreateResponseKeySpecifier
      | (() => undefined | ImageCreateResponseKeySpecifier)
    fields?: ImageCreateResponseFieldPolicy
  }
  ImageCreateResponseData?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ImageCreateResponseDataKeySpecifier
      | (() => undefined | ImageCreateResponseDataKeySpecifier)
    fields?: ImageCreateResponseDataFieldPolicy
  }
  LikePromptResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LikePromptResponseKeySpecifier
      | (() => undefined | LikePromptResponseKeySpecifier)
    fields?: LikePromptResponseFieldPolicy
  }
  MetaItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MetaItemKeySpecifier | (() => undefined | MetaItemKeySpecifier)
    fields?: MetaItemFieldPolicy
  }
  Pages?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PagesKeySpecifier | (() => undefined | PagesKeySpecifier)
    fields?: PagesFieldPolicy
  }
  PagesCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PagesCollectionKeySpecifier
      | (() => undefined | PagesCollectionKeySpecifier)
    fields?: PagesCollectionFieldPolicy
  }
  PagesLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PagesLinkingCollectionsKeySpecifier
      | (() => undefined | PagesLinkingCollectionsKeySpecifier)
    fields?: PagesLinkingCollectionsFieldPolicy
  }
  PagesSectionsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PagesSectionsCollectionKeySpecifier
      | (() => undefined | PagesSectionsCollectionKeySpecifier)
    fields?: PagesSectionsCollectionFieldPolicy
  }
  PagesSeoMetadataItemsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PagesSeoMetadataItemsCollectionKeySpecifier
      | (() => undefined | PagesSeoMetadataItemsCollectionKeySpecifier)
    fields?: PagesSeoMetadataItemsCollectionFieldPolicy
  }
  Products?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ProductsKeySpecifier | (() => undefined | ProductsKeySpecifier)
    fields?: ProductsFieldPolicy
  }
  ProductsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProductsCollectionKeySpecifier
      | (() => undefined | ProductsCollectionKeySpecifier)
    fields?: ProductsCollectionFieldPolicy
  }
  ProductsContent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProductsContentKeySpecifier
      | (() => undefined | ProductsContentKeySpecifier)
    fields?: ProductsContentFieldPolicy
  }
  ProductsContentAssets?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProductsContentAssetsKeySpecifier
      | (() => undefined | ProductsContentAssetsKeySpecifier)
    fields?: ProductsContentAssetsFieldPolicy
  }
  ProductsContentEntries?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProductsContentEntriesKeySpecifier
      | (() => undefined | ProductsContentEntriesKeySpecifier)
    fields?: ProductsContentEntriesFieldPolicy
  }
  ProductsContentLinks?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProductsContentLinksKeySpecifier
      | (() => undefined | ProductsContentLinksKeySpecifier)
    fields?: ProductsContentLinksFieldPolicy
  }
  ProductsContentResources?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProductsContentResourcesKeySpecifier
      | (() => undefined | ProductsContentResourcesKeySpecifier)
    fields?: ProductsContentResourcesFieldPolicy
  }
  ProductsLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProductsLinkingCollectionsKeySpecifier
      | (() => undefined | ProductsLinkingCollectionsKeySpecifier)
    fields?: ProductsLinkingCollectionsFieldPolicy
  }
  ProductsSeoMetadataItemsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProductsSeoMetadataItemsCollectionKeySpecifier
      | (() => undefined | ProductsSeoMetadataItemsCollectionKeySpecifier)
    fields?: ProductsSeoMetadataItemsCollectionFieldPolicy
  }
  ProductsTagsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProductsTagsCollectionKeySpecifier
      | (() => undefined | ProductsTagsCollectionKeySpecifier)
    fields?: ProductsTagsCollectionFieldPolicy
  }
  PromptChoice?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PromptChoiceKeySpecifier | (() => undefined | PromptChoiceKeySpecifier)
    fields?: PromptChoiceFieldPolicy
  }
  PromptCreateResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PromptCreateResponseKeySpecifier
      | (() => undefined | PromptCreateResponseKeySpecifier)
    fields?: PromptCreateResponseFieldPolicy
  }
  PromptEngineering?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PromptEngineeringKeySpecifier
      | (() => undefined | PromptEngineeringKeySpecifier)
    fields?: PromptEngineeringFieldPolicy
  }
  PromptEngineeringCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PromptEngineeringCollectionKeySpecifier
      | (() => undefined | PromptEngineeringCollectionKeySpecifier)
    fields?: PromptEngineeringCollectionFieldPolicy
  }
  PromptEngineeringLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PromptEngineeringLinkingCollectionsKeySpecifier
      | (() => undefined | PromptEngineeringLinkingCollectionsKeySpecifier)
    fields?: PromptEngineeringLinkingCollectionsFieldPolicy
  }
  PromptInterface?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PromptInterfaceKeySpecifier
      | (() => undefined | PromptInterfaceKeySpecifier)
    fields?: PromptInterfaceFieldPolicy
  }
  PromptResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PromptResponseKeySpecifier | (() => undefined | PromptResponseKeySpecifier)
    fields?: PromptResponseFieldPolicy
  }
  PromptStatistics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PromptStatisticsKeySpecifier
      | (() => undefined | PromptStatisticsKeySpecifier)
    fields?: PromptStatisticsFieldPolicy
  }
  PromptUsage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PromptUsageKeySpecifier | (() => undefined | PromptUsageKeySpecifier)
    fields?: PromptUsageFieldPolicy
  }
  ResourceLink?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ResourceLinkKeySpecifier | (() => undefined | ResourceLinkKeySpecifier)
    fields?: ResourceLinkFieldPolicy
  }
  ResourceSys?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ResourceSysKeySpecifier | (() => undefined | ResourceSysKeySpecifier)
    fields?: ResourceSysFieldPolicy
  }
  RootSettings?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RootSettingsKeySpecifier | (() => undefined | RootSettingsKeySpecifier)
    fields?: RootSettingsFieldPolicy
  }
  RootSettingsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RootSettingsCollectionKeySpecifier
      | (() => undefined | RootSettingsCollectionKeySpecifier)
    fields?: RootSettingsCollectionFieldPolicy
  }
  RootSettingsHeaderDisplayPagesCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RootSettingsHeaderDisplayPagesCollectionKeySpecifier
      | (() => undefined | RootSettingsHeaderDisplayPagesCollectionKeySpecifier)
    fields?: RootSettingsHeaderDisplayPagesCollectionFieldPolicy
  }
  RootSettingsLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RootSettingsLinkingCollectionsKeySpecifier
      | (() => undefined | RootSettingsLinkingCollectionsKeySpecifier)
    fields?: RootSettingsLinkingCollectionsFieldPolicy
  }
  RootSettingsMobileDrawerDisplayPagesCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RootSettingsMobileDrawerDisplayPagesCollectionKeySpecifier
      | (() => undefined | RootSettingsMobileDrawerDisplayPagesCollectionKeySpecifier)
    fields?: RootSettingsMobileDrawerDisplayPagesCollectionFieldPolicy
  }
  RootSettingsPromptEngineeringsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RootSettingsPromptEngineeringsCollectionKeySpecifier
      | (() => undefined | RootSettingsPromptEngineeringsCollectionKeySpecifier)
    fields?: RootSettingsPromptEngineeringsCollectionFieldPolicy
  }
  RootSettingsSeoMetadataItemsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RootSettingsSeoMetadataItemsCollectionKeySpecifier
      | (() => undefined | RootSettingsSeoMetadataItemsCollectionKeySpecifier)
    fields?: RootSettingsSeoMetadataItemsCollectionFieldPolicy
  }
  RootSettingsSocialMediaLinksCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RootSettingsSocialMediaLinksCollectionKeySpecifier
      | (() => undefined | RootSettingsSocialMediaLinksCollectionKeySpecifier)
    fields?: RootSettingsSocialMediaLinksCollectionFieldPolicy
  }
  SectionsDetailPersonComponent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsDetailPersonComponentKeySpecifier
      | (() => undefined | SectionsDetailPersonComponentKeySpecifier)
    fields?: SectionsDetailPersonComponentFieldPolicy
  }
  SectionsDetailPersonComponentCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsDetailPersonComponentCollectionKeySpecifier
      | (() => undefined | SectionsDetailPersonComponentCollectionKeySpecifier)
    fields?: SectionsDetailPersonComponentCollectionFieldPolicy
  }
  SectionsDetailPersonComponentDetail?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsDetailPersonComponentDetailKeySpecifier
      | (() => undefined | SectionsDetailPersonComponentDetailKeySpecifier)
    fields?: SectionsDetailPersonComponentDetailFieldPolicy
  }
  SectionsDetailPersonComponentDetailAssets?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsDetailPersonComponentDetailAssetsKeySpecifier
      | (() => undefined | SectionsDetailPersonComponentDetailAssetsKeySpecifier)
    fields?: SectionsDetailPersonComponentDetailAssetsFieldPolicy
  }
  SectionsDetailPersonComponentDetailEntries?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsDetailPersonComponentDetailEntriesKeySpecifier
      | (() => undefined | SectionsDetailPersonComponentDetailEntriesKeySpecifier)
    fields?: SectionsDetailPersonComponentDetailEntriesFieldPolicy
  }
  SectionsDetailPersonComponentDetailLinks?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsDetailPersonComponentDetailLinksKeySpecifier
      | (() => undefined | SectionsDetailPersonComponentDetailLinksKeySpecifier)
    fields?: SectionsDetailPersonComponentDetailLinksFieldPolicy
  }
  SectionsDetailPersonComponentDetailResources?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsDetailPersonComponentDetailResourcesKeySpecifier
      | (() => undefined | SectionsDetailPersonComponentDetailResourcesKeySpecifier)
    fields?: SectionsDetailPersonComponentDetailResourcesFieldPolicy
  }
  SectionsDetailPersonComponentLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsDetailPersonComponentLinkingCollectionsKeySpecifier
      | (() => undefined | SectionsDetailPersonComponentLinkingCollectionsKeySpecifier)
    fields?: SectionsDetailPersonComponentLinkingCollectionsFieldPolicy
  }
  SectionsDetailPersonList?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsDetailPersonListKeySpecifier
      | (() => undefined | SectionsDetailPersonListKeySpecifier)
    fields?: SectionsDetailPersonListFieldPolicy
  }
  SectionsDetailPersonListCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsDetailPersonListCollectionKeySpecifier
      | (() => undefined | SectionsDetailPersonListCollectionKeySpecifier)
    fields?: SectionsDetailPersonListCollectionFieldPolicy
  }
  SectionsDetailPersonListComponentsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsDetailPersonListComponentsCollectionKeySpecifier
      | (() => undefined | SectionsDetailPersonListComponentsCollectionKeySpecifier)
    fields?: SectionsDetailPersonListComponentsCollectionFieldPolicy
  }
  SectionsDetailPersonListLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsDetailPersonListLinkingCollectionsKeySpecifier
      | (() => undefined | SectionsDetailPersonListLinkingCollectionsKeySpecifier)
    fields?: SectionsDetailPersonListLinkingCollectionsFieldPolicy
  }
  SectionsFaqComponent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFaqComponentKeySpecifier
      | (() => undefined | SectionsFaqComponentKeySpecifier)
    fields?: SectionsFaqComponentFieldPolicy
  }
  SectionsFaqComponentCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFaqComponentCollectionKeySpecifier
      | (() => undefined | SectionsFaqComponentCollectionKeySpecifier)
    fields?: SectionsFaqComponentCollectionFieldPolicy
  }
  SectionsFaqComponentContent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFaqComponentContentKeySpecifier
      | (() => undefined | SectionsFaqComponentContentKeySpecifier)
    fields?: SectionsFaqComponentContentFieldPolicy
  }
  SectionsFaqComponentContentAssets?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFaqComponentContentAssetsKeySpecifier
      | (() => undefined | SectionsFaqComponentContentAssetsKeySpecifier)
    fields?: SectionsFaqComponentContentAssetsFieldPolicy
  }
  SectionsFaqComponentContentEntries?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFaqComponentContentEntriesKeySpecifier
      | (() => undefined | SectionsFaqComponentContentEntriesKeySpecifier)
    fields?: SectionsFaqComponentContentEntriesFieldPolicy
  }
  SectionsFaqComponentContentLinks?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFaqComponentContentLinksKeySpecifier
      | (() => undefined | SectionsFaqComponentContentLinksKeySpecifier)
    fields?: SectionsFaqComponentContentLinksFieldPolicy
  }
  SectionsFaqComponentContentResources?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFaqComponentContentResourcesKeySpecifier
      | (() => undefined | SectionsFaqComponentContentResourcesKeySpecifier)
    fields?: SectionsFaqComponentContentResourcesFieldPolicy
  }
  SectionsFaqComponentLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFaqComponentLinkingCollectionsKeySpecifier
      | (() => undefined | SectionsFaqComponentLinkingCollectionsKeySpecifier)
    fields?: SectionsFaqComponentLinkingCollectionsFieldPolicy
  }
  SectionsFaqList?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFaqListKeySpecifier
      | (() => undefined | SectionsFaqListKeySpecifier)
    fields?: SectionsFaqListFieldPolicy
  }
  SectionsFaqListCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFaqListCollectionKeySpecifier
      | (() => undefined | SectionsFaqListCollectionKeySpecifier)
    fields?: SectionsFaqListCollectionFieldPolicy
  }
  SectionsFaqListComponentsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFaqListComponentsCollectionKeySpecifier
      | (() => undefined | SectionsFaqListComponentsCollectionKeySpecifier)
    fields?: SectionsFaqListComponentsCollectionFieldPolicy
  }
  SectionsFaqListLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFaqListLinkingCollectionsKeySpecifier
      | (() => undefined | SectionsFaqListLinkingCollectionsKeySpecifier)
    fields?: SectionsFaqListLinkingCollectionsFieldPolicy
  }
  SectionsFeaturesList?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFeaturesListKeySpecifier
      | (() => undefined | SectionsFeaturesListKeySpecifier)
    fields?: SectionsFeaturesListFieldPolicy
  }
  SectionsFeaturesListCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFeaturesListCollectionKeySpecifier
      | (() => undefined | SectionsFeaturesListCollectionKeySpecifier)
    fields?: SectionsFeaturesListCollectionFieldPolicy
  }
  SectionsFeaturesListComponent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFeaturesListComponentKeySpecifier
      | (() => undefined | SectionsFeaturesListComponentKeySpecifier)
    fields?: SectionsFeaturesListComponentFieldPolicy
  }
  SectionsFeaturesListComponentCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFeaturesListComponentCollectionKeySpecifier
      | (() => undefined | SectionsFeaturesListComponentCollectionKeySpecifier)
    fields?: SectionsFeaturesListComponentCollectionFieldPolicy
  }
  SectionsFeaturesListComponentLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFeaturesListComponentLinkingCollectionsKeySpecifier
      | (() => undefined | SectionsFeaturesListComponentLinkingCollectionsKeySpecifier)
    fields?: SectionsFeaturesListComponentLinkingCollectionsFieldPolicy
  }
  SectionsFeaturesListComponentsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFeaturesListComponentsCollectionKeySpecifier
      | (() => undefined | SectionsFeaturesListComponentsCollectionKeySpecifier)
    fields?: SectionsFeaturesListComponentsCollectionFieldPolicy
  }
  SectionsFeaturesListLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFeaturesListLinkingCollectionsKeySpecifier
      | (() => undefined | SectionsFeaturesListLinkingCollectionsKeySpecifier)
    fields?: SectionsFeaturesListLinkingCollectionsFieldPolicy
  }
  SectionsForm?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SectionsFormKeySpecifier | (() => undefined | SectionsFormKeySpecifier)
    fields?: SectionsFormFieldPolicy
  }
  SectionsFormCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFormCollectionKeySpecifier
      | (() => undefined | SectionsFormCollectionKeySpecifier)
    fields?: SectionsFormCollectionFieldPolicy
  }
  SectionsFormComponent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFormComponentKeySpecifier
      | (() => undefined | SectionsFormComponentKeySpecifier)
    fields?: SectionsFormComponentFieldPolicy
  }
  SectionsFormComponentCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFormComponentCollectionKeySpecifier
      | (() => undefined | SectionsFormComponentCollectionKeySpecifier)
    fields?: SectionsFormComponentCollectionFieldPolicy
  }
  SectionsFormComponentLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFormComponentLinkingCollectionsKeySpecifier
      | (() => undefined | SectionsFormComponentLinkingCollectionsKeySpecifier)
    fields?: SectionsFormComponentLinkingCollectionsFieldPolicy
  }
  SectionsFormComponentsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFormComponentsCollectionKeySpecifier
      | (() => undefined | SectionsFormComponentsCollectionKeySpecifier)
    fields?: SectionsFormComponentsCollectionFieldPolicy
  }
  SectionsFormLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsFormLinkingCollectionsKeySpecifier
      | (() => undefined | SectionsFormLinkingCollectionsKeySpecifier)
    fields?: SectionsFormLinkingCollectionsFieldPolicy
  }
  SectionsHero?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SectionsHeroKeySpecifier | (() => undefined | SectionsHeroKeySpecifier)
    fields?: SectionsHeroFieldPolicy
  }
  SectionsHeroCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsHeroCollectionKeySpecifier
      | (() => undefined | SectionsHeroCollectionKeySpecifier)
    fields?: SectionsHeroCollectionFieldPolicy
  }
  SectionsHeroLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsHeroLinkingCollectionsKeySpecifier
      | (() => undefined | SectionsHeroLinkingCollectionsKeySpecifier)
    fields?: SectionsHeroLinkingCollectionsFieldPolicy
  }
  SectionsImage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SectionsImageKeySpecifier | (() => undefined | SectionsImageKeySpecifier)
    fields?: SectionsImageFieldPolicy
  }
  SectionsImageCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsImageCollectionKeySpecifier
      | (() => undefined | SectionsImageCollectionKeySpecifier)
    fields?: SectionsImageCollectionFieldPolicy
  }
  SectionsImageLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsImageLinkingCollectionsKeySpecifier
      | (() => undefined | SectionsImageLinkingCollectionsKeySpecifier)
    fields?: SectionsImageLinkingCollectionsFieldPolicy
  }
  SectionsMinimalsContactComponent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsContactComponentKeySpecifier
      | (() => undefined | SectionsMinimalsContactComponentKeySpecifier)
    fields?: SectionsMinimalsContactComponentFieldPolicy
  }
  SectionsMinimalsContactComponentCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsContactComponentCollectionKeySpecifier
      | (() => undefined | SectionsMinimalsContactComponentCollectionKeySpecifier)
    fields?: SectionsMinimalsContactComponentCollectionFieldPolicy
  }
  SectionsMinimalsContactComponentLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsContactComponentLinkingCollectionsKeySpecifier
      | (() => undefined | SectionsMinimalsContactComponentLinkingCollectionsKeySpecifier)
    fields?: SectionsMinimalsContactComponentLinkingCollectionsFieldPolicy
  }
  SectionsMinimalsContactList?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsContactListKeySpecifier
      | (() => undefined | SectionsMinimalsContactListKeySpecifier)
    fields?: SectionsMinimalsContactListFieldPolicy
  }
  SectionsMinimalsContactListCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsContactListCollectionKeySpecifier
      | (() => undefined | SectionsMinimalsContactListCollectionKeySpecifier)
    fields?: SectionsMinimalsContactListCollectionFieldPolicy
  }
  SectionsMinimalsContactListLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsContactListLinkingCollectionsKeySpecifier
      | (() => undefined | SectionsMinimalsContactListLinkingCollectionsKeySpecifier)
    fields?: SectionsMinimalsContactListLinkingCollectionsFieldPolicy
  }
  SectionsMinimalsContactListLinksCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsContactListLinksCollectionKeySpecifier
      | (() => undefined | SectionsMinimalsContactListLinksCollectionKeySpecifier)
    fields?: SectionsMinimalsContactListLinksCollectionFieldPolicy
  }
  SectionsMinimalsGallery?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsGalleryKeySpecifier
      | (() => undefined | SectionsMinimalsGalleryKeySpecifier)
    fields?: SectionsMinimalsGalleryFieldPolicy
  }
  SectionsMinimalsGalleryCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsGalleryCollectionKeySpecifier
      | (() => undefined | SectionsMinimalsGalleryCollectionKeySpecifier)
    fields?: SectionsMinimalsGalleryCollectionFieldPolicy
  }
  SectionsMinimalsGalleryDisplayItemsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsGalleryDisplayItemsCollectionKeySpecifier
      | (() => undefined | SectionsMinimalsGalleryDisplayItemsCollectionKeySpecifier)
    fields?: SectionsMinimalsGalleryDisplayItemsCollectionFieldPolicy
  }
  SectionsMinimalsGalleryLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsGalleryLinkingCollectionsKeySpecifier
      | (() => undefined | SectionsMinimalsGalleryLinkingCollectionsKeySpecifier)
    fields?: SectionsMinimalsGalleryLinkingCollectionsFieldPolicy
  }
  SectionsMinimalsHistoryComponent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsHistoryComponentKeySpecifier
      | (() => undefined | SectionsMinimalsHistoryComponentKeySpecifier)
    fields?: SectionsMinimalsHistoryComponentFieldPolicy
  }
  SectionsMinimalsHistoryComponentCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsHistoryComponentCollectionKeySpecifier
      | (() => undefined | SectionsMinimalsHistoryComponentCollectionKeySpecifier)
    fields?: SectionsMinimalsHistoryComponentCollectionFieldPolicy
  }
  SectionsMinimalsHistoryComponentLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsHistoryComponentLinkingCollectionsKeySpecifier
      | (() => undefined | SectionsMinimalsHistoryComponentLinkingCollectionsKeySpecifier)
    fields?: SectionsMinimalsHistoryComponentLinkingCollectionsFieldPolicy
  }
  SectionsMinimalsHistoryList?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsHistoryListKeySpecifier
      | (() => undefined | SectionsMinimalsHistoryListKeySpecifier)
    fields?: SectionsMinimalsHistoryListFieldPolicy
  }
  SectionsMinimalsHistoryListCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsHistoryListCollectionKeySpecifier
      | (() => undefined | SectionsMinimalsHistoryListCollectionKeySpecifier)
    fields?: SectionsMinimalsHistoryListCollectionFieldPolicy
  }
  SectionsMinimalsHistoryListComponentsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsHistoryListComponentsCollectionKeySpecifier
      | (() => undefined | SectionsMinimalsHistoryListComponentsCollectionKeySpecifier)
    fields?: SectionsMinimalsHistoryListComponentsCollectionFieldPolicy
  }
  SectionsMinimalsHistoryListLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsHistoryListLinkingCollectionsKeySpecifier
      | (() => undefined | SectionsMinimalsHistoryListLinkingCollectionsKeySpecifier)
    fields?: SectionsMinimalsHistoryListLinkingCollectionsFieldPolicy
  }
  SectionsMinimalsHumanComponent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsHumanComponentKeySpecifier
      | (() => undefined | SectionsMinimalsHumanComponentKeySpecifier)
    fields?: SectionsMinimalsHumanComponentFieldPolicy
  }
  SectionsMinimalsHumanComponentCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsHumanComponentCollectionKeySpecifier
      | (() => undefined | SectionsMinimalsHumanComponentCollectionKeySpecifier)
    fields?: SectionsMinimalsHumanComponentCollectionFieldPolicy
  }
  SectionsMinimalsHumanComponentLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsHumanComponentLinkingCollectionsKeySpecifier
      | (() => undefined | SectionsMinimalsHumanComponentLinkingCollectionsKeySpecifier)
    fields?: SectionsMinimalsHumanComponentLinkingCollectionsFieldPolicy
  }
  SectionsMinimalsHumanList?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsHumanListKeySpecifier
      | (() => undefined | SectionsMinimalsHumanListKeySpecifier)
    fields?: SectionsMinimalsHumanListFieldPolicy
  }
  SectionsMinimalsHumanListCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsHumanListCollectionKeySpecifier
      | (() => undefined | SectionsMinimalsHumanListCollectionKeySpecifier)
    fields?: SectionsMinimalsHumanListCollectionFieldPolicy
  }
  SectionsMinimalsHumanListComponentsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsHumanListComponentsCollectionKeySpecifier
      | (() => undefined | SectionsMinimalsHumanListComponentsCollectionKeySpecifier)
    fields?: SectionsMinimalsHumanListComponentsCollectionFieldPolicy
  }
  SectionsMinimalsHumanListLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsHumanListLinkingCollectionsKeySpecifier
      | (() => undefined | SectionsMinimalsHumanListLinkingCollectionsKeySpecifier)
    fields?: SectionsMinimalsHumanListLinkingCollectionsFieldPolicy
  }
  SectionsMinimalsImagesList?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsImagesListKeySpecifier
      | (() => undefined | SectionsMinimalsImagesListKeySpecifier)
    fields?: SectionsMinimalsImagesListFieldPolicy
  }
  SectionsMinimalsImagesListCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsImagesListCollectionKeySpecifier
      | (() => undefined | SectionsMinimalsImagesListCollectionKeySpecifier)
    fields?: SectionsMinimalsImagesListCollectionFieldPolicy
  }
  SectionsMinimalsImagesListImagesWithHrefDesktopCollection?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | SectionsMinimalsImagesListImagesWithHrefDesktopCollectionKeySpecifier
      | (() => undefined | SectionsMinimalsImagesListImagesWithHrefDesktopCollectionKeySpecifier)
    fields?: SectionsMinimalsImagesListImagesWithHrefDesktopCollectionFieldPolicy
  }
  SectionsMinimalsImagesListImagesWithHrefMobileCollection?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | SectionsMinimalsImagesListImagesWithHrefMobileCollectionKeySpecifier
      | (() => undefined | SectionsMinimalsImagesListImagesWithHrefMobileCollectionKeySpecifier)
    fields?: SectionsMinimalsImagesListImagesWithHrefMobileCollectionFieldPolicy
  }
  SectionsMinimalsImagesListLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsImagesListLinkingCollectionsKeySpecifier
      | (() => undefined | SectionsMinimalsImagesListLinkingCollectionsKeySpecifier)
    fields?: SectionsMinimalsImagesListLinkingCollectionsFieldPolicy
  }
  SectionsMinimalsService?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsServiceKeySpecifier
      | (() => undefined | SectionsMinimalsServiceKeySpecifier)
    fields?: SectionsMinimalsServiceFieldPolicy
  }
  SectionsMinimalsServiceCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsServiceCollectionKeySpecifier
      | (() => undefined | SectionsMinimalsServiceCollectionKeySpecifier)
    fields?: SectionsMinimalsServiceCollectionFieldPolicy
  }
  SectionsMinimalsServiceLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsServiceLinkingCollectionsKeySpecifier
      | (() => undefined | SectionsMinimalsServiceLinkingCollectionsKeySpecifier)
    fields?: SectionsMinimalsServiceLinkingCollectionsFieldPolicy
  }
  SectionsMinimalsServices?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsServicesKeySpecifier
      | (() => undefined | SectionsMinimalsServicesKeySpecifier)
    fields?: SectionsMinimalsServicesFieldPolicy
  }
  SectionsMinimalsServicesCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsServicesCollectionKeySpecifier
      | (() => undefined | SectionsMinimalsServicesCollectionKeySpecifier)
    fields?: SectionsMinimalsServicesCollectionFieldPolicy
  }
  SectionsMinimalsServicesComponentsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsServicesComponentsCollectionKeySpecifier
      | (() => undefined | SectionsMinimalsServicesComponentsCollectionKeySpecifier)
    fields?: SectionsMinimalsServicesComponentsCollectionFieldPolicy
  }
  SectionsMinimalsServicesLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsServicesLinkingCollectionsKeySpecifier
      | (() => undefined | SectionsMinimalsServicesLinkingCollectionsKeySpecifier)
    fields?: SectionsMinimalsServicesLinkingCollectionsFieldPolicy
  }
  SectionsMinimalsVideo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsVideoKeySpecifier
      | (() => undefined | SectionsMinimalsVideoKeySpecifier)
    fields?: SectionsMinimalsVideoFieldPolicy
  }
  SectionsMinimalsVideoCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsVideoCollectionKeySpecifier
      | (() => undefined | SectionsMinimalsVideoCollectionKeySpecifier)
    fields?: SectionsMinimalsVideoCollectionFieldPolicy
  }
  SectionsMinimalsVideoFeatureDescription?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsVideoFeatureDescriptionKeySpecifier
      | (() => undefined | SectionsMinimalsVideoFeatureDescriptionKeySpecifier)
    fields?: SectionsMinimalsVideoFeatureDescriptionFieldPolicy
  }
  SectionsMinimalsVideoFeatureDescriptionAssets?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsVideoFeatureDescriptionAssetsKeySpecifier
      | (() => undefined | SectionsMinimalsVideoFeatureDescriptionAssetsKeySpecifier)
    fields?: SectionsMinimalsVideoFeatureDescriptionAssetsFieldPolicy
  }
  SectionsMinimalsVideoFeatureDescriptionEntries?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsVideoFeatureDescriptionEntriesKeySpecifier
      | (() => undefined | SectionsMinimalsVideoFeatureDescriptionEntriesKeySpecifier)
    fields?: SectionsMinimalsVideoFeatureDescriptionEntriesFieldPolicy
  }
  SectionsMinimalsVideoFeatureDescriptionLinks?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsVideoFeatureDescriptionLinksKeySpecifier
      | (() => undefined | SectionsMinimalsVideoFeatureDescriptionLinksKeySpecifier)
    fields?: SectionsMinimalsVideoFeatureDescriptionLinksFieldPolicy
  }
  SectionsMinimalsVideoFeatureDescriptionResources?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsVideoFeatureDescriptionResourcesKeySpecifier
      | (() => undefined | SectionsMinimalsVideoFeatureDescriptionResourcesKeySpecifier)
    fields?: SectionsMinimalsVideoFeatureDescriptionResourcesFieldPolicy
  }
  SectionsMinimalsVideoLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMinimalsVideoLinkingCollectionsKeySpecifier
      | (() => undefined | SectionsMinimalsVideoLinkingCollectionsKeySpecifier)
    fields?: SectionsMinimalsVideoLinkingCollectionsFieldPolicy
  }
  SectionsMintList?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMintListKeySpecifier
      | (() => undefined | SectionsMintListKeySpecifier)
    fields?: SectionsMintListFieldPolicy
  }
  SectionsMintListCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMintListCollectionKeySpecifier
      | (() => undefined | SectionsMintListCollectionKeySpecifier)
    fields?: SectionsMintListCollectionFieldPolicy
  }
  SectionsMintListComponent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMintListComponentKeySpecifier
      | (() => undefined | SectionsMintListComponentKeySpecifier)
    fields?: SectionsMintListComponentFieldPolicy
  }
  SectionsMintListComponentCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMintListComponentCollectionKeySpecifier
      | (() => undefined | SectionsMintListComponentCollectionKeySpecifier)
    fields?: SectionsMintListComponentCollectionFieldPolicy
  }
  SectionsMintListComponentLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMintListComponentLinkingCollectionsKeySpecifier
      | (() => undefined | SectionsMintListComponentLinkingCollectionsKeySpecifier)
    fields?: SectionsMintListComponentLinkingCollectionsFieldPolicy
  }
  SectionsMintListLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMintListLinkingCollectionsKeySpecifier
      | (() => undefined | SectionsMintListLinkingCollectionsKeySpecifier)
    fields?: SectionsMintListLinkingCollectionsFieldPolicy
  }
  SectionsMintListMintCardsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsMintListMintCardsCollectionKeySpecifier
      | (() => undefined | SectionsMintListMintCardsCollectionKeySpecifier)
    fields?: SectionsMintListMintCardsCollectionFieldPolicy
  }
  SectionsTimer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SectionsTimerKeySpecifier | (() => undefined | SectionsTimerKeySpecifier)
    fields?: SectionsTimerFieldPolicy
  }
  SectionsTimerCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsTimerCollectionKeySpecifier
      | (() => undefined | SectionsTimerCollectionKeySpecifier)
    fields?: SectionsTimerCollectionFieldPolicy
  }
  SectionsTimerLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SectionsTimerLinkingCollectionsKeySpecifier
      | (() => undefined | SectionsTimerLinkingCollectionsKeySpecifier)
    fields?: SectionsTimerLinkingCollectionsFieldPolicy
  }
  SeenPromptResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SeenPromptResponseKeySpecifier
      | (() => undefined | SeenPromptResponseKeySpecifier)
    fields?: SeenPromptResponseFieldPolicy
  }
  SeoInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SeoInfoKeySpecifier | (() => undefined | SeoInfoKeySpecifier)
    fields?: SeoInfoFieldPolicy
  }
  SeoMetadata?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SeoMetadataKeySpecifier | (() => undefined | SeoMetadataKeySpecifier)
    fields?: SeoMetadataFieldPolicy
  }
  SeoMetadataCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SeoMetadataCollectionKeySpecifier
      | (() => undefined | SeoMetadataCollectionKeySpecifier)
    fields?: SeoMetadataCollectionFieldPolicy
  }
  SeoMetadataLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SeoMetadataLinkingCollectionsKeySpecifier
      | (() => undefined | SeoMetadataLinkingCollectionsKeySpecifier)
    fields?: SeoMetadataLinkingCollectionsFieldPolicy
  }
  SmartContractErc721?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SmartContractErc721KeySpecifier
      | (() => undefined | SmartContractErc721KeySpecifier)
    fields?: SmartContractErc721FieldPolicy
  }
  SmartContractErc721Collection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SmartContractErc721CollectionKeySpecifier
      | (() => undefined | SmartContractErc721CollectionKeySpecifier)
    fields?: SmartContractErc721CollectionFieldPolicy
  }
  SmartContractErc721LinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SmartContractErc721LinkingCollectionsKeySpecifier
      | (() => undefined | SmartContractErc721LinkingCollectionsKeySpecifier)
    fields?: SmartContractErc721LinkingCollectionsFieldPolicy
  }
  SmartContractErc721Token?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SmartContractErc721TokenKeySpecifier
      | (() => undefined | SmartContractErc721TokenKeySpecifier)
    fields?: SmartContractErc721TokenFieldPolicy
  }
  SmartContractErc721TokenAttribute?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SmartContractErc721TokenAttributeKeySpecifier
      | (() => undefined | SmartContractErc721TokenAttributeKeySpecifier)
    fields?: SmartContractErc721TokenAttributeFieldPolicy
  }
  SmartContractErc721TokenAttributeCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SmartContractErc721TokenAttributeCollectionKeySpecifier
      | (() => undefined | SmartContractErc721TokenAttributeCollectionKeySpecifier)
    fields?: SmartContractErc721TokenAttributeCollectionFieldPolicy
  }
  SmartContractErc721TokenAttributeLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SmartContractErc721TokenAttributeLinkingCollectionsKeySpecifier
      | (() => undefined | SmartContractErc721TokenAttributeLinkingCollectionsKeySpecifier)
    fields?: SmartContractErc721TokenAttributeLinkingCollectionsFieldPolicy
  }
  SmartContractErc721TokenAttributesCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SmartContractErc721TokenAttributesCollectionKeySpecifier
      | (() => undefined | SmartContractErc721TokenAttributesCollectionKeySpecifier)
    fields?: SmartContractErc721TokenAttributesCollectionFieldPolicy
  }
  SmartContractErc721TokenCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SmartContractErc721TokenCollectionKeySpecifier
      | (() => undefined | SmartContractErc721TokenCollectionKeySpecifier)
    fields?: SmartContractErc721TokenCollectionFieldPolicy
  }
  SmartContractErc721TokenLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SmartContractErc721TokenLinkingCollectionsKeySpecifier
      | (() => undefined | SmartContractErc721TokenLinkingCollectionsKeySpecifier)
    fields?: SmartContractErc721TokenLinkingCollectionsFieldPolicy
  }
  SmartContractErc721TokensCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SmartContractErc721TokensCollectionKeySpecifier
      | (() => undefined | SmartContractErc721TokensCollectionKeySpecifier)
    fields?: SmartContractErc721TokensCollectionFieldPolicy
  }
  SmartContractErc721WhitelistAddress?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SmartContractErc721WhitelistAddressKeySpecifier
      | (() => undefined | SmartContractErc721WhitelistAddressKeySpecifier)
    fields?: SmartContractErc721WhitelistAddressFieldPolicy
  }
  SmartContractErc721WhitelistAddressCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SmartContractErc721WhitelistAddressCollectionKeySpecifier
      | (() => undefined | SmartContractErc721WhitelistAddressCollectionKeySpecifier)
    fields?: SmartContractErc721WhitelistAddressCollectionFieldPolicy
  }
  SmartContractErc721WhitelistAddressLinkingCollections?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | SmartContractErc721WhitelistAddressLinkingCollectionsKeySpecifier
      | (() => undefined | SmartContractErc721WhitelistAddressLinkingCollectionsKeySpecifier)
    fields?: SmartContractErc721WhitelistAddressLinkingCollectionsFieldPolicy
  }
  SmartContractErc721WhitelistAddressesCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SmartContractErc721WhitelistAddressesCollectionKeySpecifier
      | (() => undefined | SmartContractErc721WhitelistAddressesCollectionKeySpecifier)
    fields?: SmartContractErc721WhitelistAddressesCollectionFieldPolicy
  }
  SocialMediaLink?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SocialMediaLinkKeySpecifier
      | (() => undefined | SocialMediaLinkKeySpecifier)
    fields?: SocialMediaLinkFieldPolicy
  }
  SocialMediaLinkCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SocialMediaLinkCollectionKeySpecifier
      | (() => undefined | SocialMediaLinkCollectionKeySpecifier)
    fields?: SocialMediaLinkCollectionFieldPolicy
  }
  SocialMediaLinkLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SocialMediaLinkLinkingCollectionsKeySpecifier
      | (() => undefined | SocialMediaLinkLinkingCollectionsKeySpecifier)
    fields?: SocialMediaLinkLinkingCollectionsFieldPolicy
  }
  SupportingLanguage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SupportingLanguageKeySpecifier
      | (() => undefined | SupportingLanguageKeySpecifier)
    fields?: SupportingLanguageFieldPolicy
  }
  SupportingTone?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SupportingToneKeySpecifier | (() => undefined | SupportingToneKeySpecifier)
    fields?: SupportingToneFieldPolicy
  }
  SupportingUseCase?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SupportingUseCaseKeySpecifier
      | (() => undefined | SupportingUseCaseKeySpecifier)
    fields?: SupportingUseCaseFieldPolicy
  }
  Sys?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SysKeySpecifier | (() => undefined | SysKeySpecifier)
    fields?: SysFieldPolicy
  }
  Tags?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TagsKeySpecifier | (() => undefined | TagsKeySpecifier)
    fields?: TagsFieldPolicy
  }
  TagsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TagsCollectionKeySpecifier | (() => undefined | TagsCollectionKeySpecifier)
    fields?: TagsCollectionFieldPolicy
  }
  TagsContent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TagsContentKeySpecifier | (() => undefined | TagsContentKeySpecifier)
    fields?: TagsContentFieldPolicy
  }
  TagsContentAssets?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TagsContentAssetsKeySpecifier
      | (() => undefined | TagsContentAssetsKeySpecifier)
    fields?: TagsContentAssetsFieldPolicy
  }
  TagsContentEntries?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TagsContentEntriesKeySpecifier
      | (() => undefined | TagsContentEntriesKeySpecifier)
    fields?: TagsContentEntriesFieldPolicy
  }
  TagsContentLinks?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TagsContentLinksKeySpecifier
      | (() => undefined | TagsContentLinksKeySpecifier)
    fields?: TagsContentLinksFieldPolicy
  }
  TagsContentResources?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TagsContentResourcesKeySpecifier
      | (() => undefined | TagsContentResourcesKeySpecifier)
    fields?: TagsContentResourcesFieldPolicy
  }
  TagsLinkingCollections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TagsLinkingCollectionsKeySpecifier
      | (() => undefined | TagsLinkingCollectionsKeySpecifier)
    fields?: TagsLinkingCollectionsFieldPolicy
  }
  TagsSeoMetadataItemsCollection?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TagsSeoMetadataItemsCollectionKeySpecifier
      | (() => undefined | TagsSeoMetadataItemsCollectionKeySpecifier)
    fields?: TagsSeoMetadataItemsCollectionFieldPolicy
  }
  TextVariation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TextVariationKeySpecifier | (() => undefined | TextVariationKeySpecifier)
    fields?: TextVariationFieldPolicy
  }
  aICompletionRecords?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | aICompletionRecordsKeySpecifier
      | (() => undefined | aICompletionRecordsKeySpecifier)
    fields?: aICompletionRecordsFieldPolicy
  }
  aICompletionRecords_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | aICompletionRecords_aggregateKeySpecifier
      | (() => undefined | aICompletionRecords_aggregateKeySpecifier)
    fields?: aICompletionRecords_aggregateFieldPolicy
  }
  aICompletionRecords_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | aICompletionRecords_aggregate_fieldsKeySpecifier
      | (() => undefined | aICompletionRecords_aggregate_fieldsKeySpecifier)
    fields?: aICompletionRecords_aggregate_fieldsFieldPolicy
  }
  aICompletionRecords_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | aICompletionRecords_max_fieldsKeySpecifier
      | (() => undefined | aICompletionRecords_max_fieldsKeySpecifier)
    fields?: aICompletionRecords_max_fieldsFieldPolicy
  }
  aICompletionRecords_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | aICompletionRecords_min_fieldsKeySpecifier
      | (() => undefined | aICompletionRecords_min_fieldsKeySpecifier)
    fields?: aICompletionRecords_min_fieldsFieldPolicy
  }
  aICompletionRecords_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | aICompletionRecords_mutation_responseKeySpecifier
      | (() => undefined | aICompletionRecords_mutation_responseKeySpecifier)
    fields?: aICompletionRecords_mutation_responseFieldPolicy
  }
  apps?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | appsKeySpecifier | (() => undefined | appsKeySpecifier)
    fields?: appsFieldPolicy
  }
  apps_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | apps_aggregateKeySpecifier | (() => undefined | apps_aggregateKeySpecifier)
    fields?: apps_aggregateFieldPolicy
  }
  apps_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | apps_aggregate_fieldsKeySpecifier
      | (() => undefined | apps_aggregate_fieldsKeySpecifier)
    fields?: apps_aggregate_fieldsFieldPolicy
  }
  apps_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | apps_max_fieldsKeySpecifier
      | (() => undefined | apps_max_fieldsKeySpecifier)
    fields?: apps_max_fieldsFieldPolicy
  }
  apps_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | apps_min_fieldsKeySpecifier
      | (() => undefined | apps_min_fieldsKeySpecifier)
    fields?: apps_min_fieldsFieldPolicy
  }
  apps_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | apps_mutation_responseKeySpecifier
      | (() => undefined | apps_mutation_responseKeySpecifier)
    fields?: apps_mutation_responseFieldPolicy
  }
  discordMessages?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | discordMessagesKeySpecifier
      | (() => undefined | discordMessagesKeySpecifier)
    fields?: discordMessagesFieldPolicy
  }
  discordMessages_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | discordMessages_aggregateKeySpecifier
      | (() => undefined | discordMessages_aggregateKeySpecifier)
    fields?: discordMessages_aggregateFieldPolicy
  }
  discordMessages_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | discordMessages_aggregate_fieldsKeySpecifier
      | (() => undefined | discordMessages_aggregate_fieldsKeySpecifier)
    fields?: discordMessages_aggregate_fieldsFieldPolicy
  }
  discordMessages_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | discordMessages_max_fieldsKeySpecifier
      | (() => undefined | discordMessages_max_fieldsKeySpecifier)
    fields?: discordMessages_max_fieldsFieldPolicy
  }
  discordMessages_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | discordMessages_min_fieldsKeySpecifier
      | (() => undefined | discordMessages_min_fieldsKeySpecifier)
    fields?: discordMessages_min_fieldsFieldPolicy
  }
  discordMessages_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | discordMessages_mutation_responseKeySpecifier
      | (() => undefined | discordMessages_mutation_responseKeySpecifier)
    fields?: discordMessages_mutation_responseFieldPolicy
  }
  formAnswers?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | formAnswersKeySpecifier | (() => undefined | formAnswersKeySpecifier)
    fields?: formAnswersFieldPolicy
  }
  formAnswers_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | formAnswers_aggregateKeySpecifier
      | (() => undefined | formAnswers_aggregateKeySpecifier)
    fields?: formAnswers_aggregateFieldPolicy
  }
  formAnswers_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | formAnswers_aggregate_fieldsKeySpecifier
      | (() => undefined | formAnswers_aggregate_fieldsKeySpecifier)
    fields?: formAnswers_aggregate_fieldsFieldPolicy
  }
  formAnswers_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | formAnswers_max_fieldsKeySpecifier
      | (() => undefined | formAnswers_max_fieldsKeySpecifier)
    fields?: formAnswers_max_fieldsFieldPolicy
  }
  formAnswers_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | formAnswers_min_fieldsKeySpecifier
      | (() => undefined | formAnswers_min_fieldsKeySpecifier)
    fields?: formAnswers_min_fieldsFieldPolicy
  }
  formAnswers_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | formAnswers_mutation_responseKeySpecifier
      | (() => undefined | formAnswers_mutation_responseKeySpecifier)
    fields?: formAnswers_mutation_responseFieldPolicy
  }
  forms?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | formsKeySpecifier | (() => undefined | formsKeySpecifier)
    fields?: formsFieldPolicy
  }
  forms_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | forms_aggregateKeySpecifier
      | (() => undefined | forms_aggregateKeySpecifier)
    fields?: forms_aggregateFieldPolicy
  }
  forms_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | forms_aggregate_fieldsKeySpecifier
      | (() => undefined | forms_aggregate_fieldsKeySpecifier)
    fields?: forms_aggregate_fieldsFieldPolicy
  }
  forms_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | forms_max_fieldsKeySpecifier
      | (() => undefined | forms_max_fieldsKeySpecifier)
    fields?: forms_max_fieldsFieldPolicy
  }
  forms_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | forms_min_fieldsKeySpecifier
      | (() => undefined | forms_min_fieldsKeySpecifier)
    fields?: forms_min_fieldsFieldPolicy
  }
  forms_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | forms_mutation_responseKeySpecifier
      | (() => undefined | forms_mutation_responseKeySpecifier)
    fields?: forms_mutation_responseFieldPolicy
  }
  gptHeroAccounts?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroAccountsKeySpecifier
      | (() => undefined | gptHeroAccountsKeySpecifier)
    fields?: gptHeroAccountsFieldPolicy
  }
  gptHeroAccounts_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroAccounts_aggregateKeySpecifier
      | (() => undefined | gptHeroAccounts_aggregateKeySpecifier)
    fields?: gptHeroAccounts_aggregateFieldPolicy
  }
  gptHeroAccounts_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroAccounts_aggregate_fieldsKeySpecifier
      | (() => undefined | gptHeroAccounts_aggregate_fieldsKeySpecifier)
    fields?: gptHeroAccounts_aggregate_fieldsFieldPolicy
  }
  gptHeroAccounts_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroAccounts_max_fieldsKeySpecifier
      | (() => undefined | gptHeroAccounts_max_fieldsKeySpecifier)
    fields?: gptHeroAccounts_max_fieldsFieldPolicy
  }
  gptHeroAccounts_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroAccounts_min_fieldsKeySpecifier
      | (() => undefined | gptHeroAccounts_min_fieldsKeySpecifier)
    fields?: gptHeroAccounts_min_fieldsFieldPolicy
  }
  gptHeroAccounts_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroAccounts_mutation_responseKeySpecifier
      | (() => undefined | gptHeroAccounts_mutation_responseKeySpecifier)
    fields?: gptHeroAccounts_mutation_responseFieldPolicy
  }
  gptHeroPromptUsages?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroPromptUsagesKeySpecifier
      | (() => undefined | gptHeroPromptUsagesKeySpecifier)
    fields?: gptHeroPromptUsagesFieldPolicy
  }
  gptHeroPromptUsages_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroPromptUsages_aggregateKeySpecifier
      | (() => undefined | gptHeroPromptUsages_aggregateKeySpecifier)
    fields?: gptHeroPromptUsages_aggregateFieldPolicy
  }
  gptHeroPromptUsages_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroPromptUsages_aggregate_fieldsKeySpecifier
      | (() => undefined | gptHeroPromptUsages_aggregate_fieldsKeySpecifier)
    fields?: gptHeroPromptUsages_aggregate_fieldsFieldPolicy
  }
  gptHeroPromptUsages_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroPromptUsages_max_fieldsKeySpecifier
      | (() => undefined | gptHeroPromptUsages_max_fieldsKeySpecifier)
    fields?: gptHeroPromptUsages_max_fieldsFieldPolicy
  }
  gptHeroPromptUsages_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroPromptUsages_min_fieldsKeySpecifier
      | (() => undefined | gptHeroPromptUsages_min_fieldsKeySpecifier)
    fields?: gptHeroPromptUsages_min_fieldsFieldPolicy
  }
  gptHeroPromptUsages_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroPromptUsages_mutation_responseKeySpecifier
      | (() => undefined | gptHeroPromptUsages_mutation_responseKeySpecifier)
    fields?: gptHeroPromptUsages_mutation_responseFieldPolicy
  }
  gptHeroPrompts?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | gptHeroPromptsKeySpecifier | (() => undefined | gptHeroPromptsKeySpecifier)
    fields?: gptHeroPromptsFieldPolicy
  }
  gptHeroPrompts_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroPrompts_aggregateKeySpecifier
      | (() => undefined | gptHeroPrompts_aggregateKeySpecifier)
    fields?: gptHeroPrompts_aggregateFieldPolicy
  }
  gptHeroPrompts_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroPrompts_aggregate_fieldsKeySpecifier
      | (() => undefined | gptHeroPrompts_aggregate_fieldsKeySpecifier)
    fields?: gptHeroPrompts_aggregate_fieldsFieldPolicy
  }
  gptHeroPrompts_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroPrompts_avg_fieldsKeySpecifier
      | (() => undefined | gptHeroPrompts_avg_fieldsKeySpecifier)
    fields?: gptHeroPrompts_avg_fieldsFieldPolicy
  }
  gptHeroPrompts_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroPrompts_max_fieldsKeySpecifier
      | (() => undefined | gptHeroPrompts_max_fieldsKeySpecifier)
    fields?: gptHeroPrompts_max_fieldsFieldPolicy
  }
  gptHeroPrompts_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroPrompts_min_fieldsKeySpecifier
      | (() => undefined | gptHeroPrompts_min_fieldsKeySpecifier)
    fields?: gptHeroPrompts_min_fieldsFieldPolicy
  }
  gptHeroPrompts_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroPrompts_mutation_responseKeySpecifier
      | (() => undefined | gptHeroPrompts_mutation_responseKeySpecifier)
    fields?: gptHeroPrompts_mutation_responseFieldPolicy
  }
  gptHeroPrompts_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroPrompts_stddev_fieldsKeySpecifier
      | (() => undefined | gptHeroPrompts_stddev_fieldsKeySpecifier)
    fields?: gptHeroPrompts_stddev_fieldsFieldPolicy
  }
  gptHeroPrompts_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroPrompts_stddev_pop_fieldsKeySpecifier
      | (() => undefined | gptHeroPrompts_stddev_pop_fieldsKeySpecifier)
    fields?: gptHeroPrompts_stddev_pop_fieldsFieldPolicy
  }
  gptHeroPrompts_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroPrompts_stddev_samp_fieldsKeySpecifier
      | (() => undefined | gptHeroPrompts_stddev_samp_fieldsKeySpecifier)
    fields?: gptHeroPrompts_stddev_samp_fieldsFieldPolicy
  }
  gptHeroPrompts_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroPrompts_sum_fieldsKeySpecifier
      | (() => undefined | gptHeroPrompts_sum_fieldsKeySpecifier)
    fields?: gptHeroPrompts_sum_fieldsFieldPolicy
  }
  gptHeroPrompts_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroPrompts_var_pop_fieldsKeySpecifier
      | (() => undefined | gptHeroPrompts_var_pop_fieldsKeySpecifier)
    fields?: gptHeroPrompts_var_pop_fieldsFieldPolicy
  }
  gptHeroPrompts_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroPrompts_var_samp_fieldsKeySpecifier
      | (() => undefined | gptHeroPrompts_var_samp_fieldsKeySpecifier)
    fields?: gptHeroPrompts_var_samp_fieldsFieldPolicy
  }
  gptHeroPrompts_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroPrompts_variance_fieldsKeySpecifier
      | (() => undefined | gptHeroPrompts_variance_fieldsKeySpecifier)
    fields?: gptHeroPrompts_variance_fieldsFieldPolicy
  }
  gptHeroReportIssues?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroReportIssuesKeySpecifier
      | (() => undefined | gptHeroReportIssuesKeySpecifier)
    fields?: gptHeroReportIssuesFieldPolicy
  }
  gptHeroReportIssues_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroReportIssues_aggregateKeySpecifier
      | (() => undefined | gptHeroReportIssues_aggregateKeySpecifier)
    fields?: gptHeroReportIssues_aggregateFieldPolicy
  }
  gptHeroReportIssues_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroReportIssues_aggregate_fieldsKeySpecifier
      | (() => undefined | gptHeroReportIssues_aggregate_fieldsKeySpecifier)
    fields?: gptHeroReportIssues_aggregate_fieldsFieldPolicy
  }
  gptHeroReportIssues_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroReportIssues_max_fieldsKeySpecifier
      | (() => undefined | gptHeroReportIssues_max_fieldsKeySpecifier)
    fields?: gptHeroReportIssues_max_fieldsFieldPolicy
  }
  gptHeroReportIssues_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroReportIssues_min_fieldsKeySpecifier
      | (() => undefined | gptHeroReportIssues_min_fieldsKeySpecifier)
    fields?: gptHeroReportIssues_min_fieldsFieldPolicy
  }
  gptHeroReportIssues_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroReportIssues_mutation_responseKeySpecifier
      | (() => undefined | gptHeroReportIssues_mutation_responseKeySpecifier)
    fields?: gptHeroReportIssues_mutation_responseFieldPolicy
  }
  gptHeroTopics?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | gptHeroTopicsKeySpecifier | (() => undefined | gptHeroTopicsKeySpecifier)
    fields?: gptHeroTopicsFieldPolicy
  }
  gptHeroTopics_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroTopics_aggregateKeySpecifier
      | (() => undefined | gptHeroTopics_aggregateKeySpecifier)
    fields?: gptHeroTopics_aggregateFieldPolicy
  }
  gptHeroTopics_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroTopics_aggregate_fieldsKeySpecifier
      | (() => undefined | gptHeroTopics_aggregate_fieldsKeySpecifier)
    fields?: gptHeroTopics_aggregate_fieldsFieldPolicy
  }
  gptHeroTopics_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroTopics_max_fieldsKeySpecifier
      | (() => undefined | gptHeroTopics_max_fieldsKeySpecifier)
    fields?: gptHeroTopics_max_fieldsFieldPolicy
  }
  gptHeroTopics_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroTopics_min_fieldsKeySpecifier
      | (() => undefined | gptHeroTopics_min_fieldsKeySpecifier)
    fields?: gptHeroTopics_min_fieldsFieldPolicy
  }
  gptHeroTopics_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | gptHeroTopics_mutation_responseKeySpecifier
      | (() => undefined | gptHeroTopics_mutation_responseKeySpecifier)
    fields?: gptHeroTopics_mutation_responseFieldPolicy
  }
  midjourneyImages?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | midjourneyImagesKeySpecifier
      | (() => undefined | midjourneyImagesKeySpecifier)
    fields?: midjourneyImagesFieldPolicy
  }
  midjourneyImages_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | midjourneyImages_aggregateKeySpecifier
      | (() => undefined | midjourneyImages_aggregateKeySpecifier)
    fields?: midjourneyImages_aggregateFieldPolicy
  }
  midjourneyImages_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | midjourneyImages_aggregate_fieldsKeySpecifier
      | (() => undefined | midjourneyImages_aggregate_fieldsKeySpecifier)
    fields?: midjourneyImages_aggregate_fieldsFieldPolicy
  }
  midjourneyImages_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | midjourneyImages_avg_fieldsKeySpecifier
      | (() => undefined | midjourneyImages_avg_fieldsKeySpecifier)
    fields?: midjourneyImages_avg_fieldsFieldPolicy
  }
  midjourneyImages_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | midjourneyImages_max_fieldsKeySpecifier
      | (() => undefined | midjourneyImages_max_fieldsKeySpecifier)
    fields?: midjourneyImages_max_fieldsFieldPolicy
  }
  midjourneyImages_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | midjourneyImages_min_fieldsKeySpecifier
      | (() => undefined | midjourneyImages_min_fieldsKeySpecifier)
    fields?: midjourneyImages_min_fieldsFieldPolicy
  }
  midjourneyImages_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | midjourneyImages_mutation_responseKeySpecifier
      | (() => undefined | midjourneyImages_mutation_responseKeySpecifier)
    fields?: midjourneyImages_mutation_responseFieldPolicy
  }
  midjourneyImages_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | midjourneyImages_stddev_fieldsKeySpecifier
      | (() => undefined | midjourneyImages_stddev_fieldsKeySpecifier)
    fields?: midjourneyImages_stddev_fieldsFieldPolicy
  }
  midjourneyImages_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | midjourneyImages_stddev_pop_fieldsKeySpecifier
      | (() => undefined | midjourneyImages_stddev_pop_fieldsKeySpecifier)
    fields?: midjourneyImages_stddev_pop_fieldsFieldPolicy
  }
  midjourneyImages_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | midjourneyImages_stddev_samp_fieldsKeySpecifier
      | (() => undefined | midjourneyImages_stddev_samp_fieldsKeySpecifier)
    fields?: midjourneyImages_stddev_samp_fieldsFieldPolicy
  }
  midjourneyImages_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | midjourneyImages_sum_fieldsKeySpecifier
      | (() => undefined | midjourneyImages_sum_fieldsKeySpecifier)
    fields?: midjourneyImages_sum_fieldsFieldPolicy
  }
  midjourneyImages_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | midjourneyImages_var_pop_fieldsKeySpecifier
      | (() => undefined | midjourneyImages_var_pop_fieldsKeySpecifier)
    fields?: midjourneyImages_var_pop_fieldsFieldPolicy
  }
  midjourneyImages_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | midjourneyImages_var_samp_fieldsKeySpecifier
      | (() => undefined | midjourneyImages_var_samp_fieldsKeySpecifier)
    fields?: midjourneyImages_var_samp_fieldsFieldPolicy
  }
  midjourneyImages_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | midjourneyImages_variance_fieldsKeySpecifier
      | (() => undefined | midjourneyImages_variance_fieldsKeySpecifier)
    fields?: midjourneyImages_variance_fieldsFieldPolicy
  }
  mutation_root?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | mutation_rootKeySpecifier | (() => undefined | mutation_rootKeySpecifier)
    fields?: mutation_rootFieldPolicy
  }
  newebpayEpgNotifications?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | newebpayEpgNotificationsKeySpecifier
      | (() => undefined | newebpayEpgNotificationsKeySpecifier)
    fields?: newebpayEpgNotificationsFieldPolicy
  }
  newebpayEpgNotifications_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | newebpayEpgNotifications_aggregateKeySpecifier
      | (() => undefined | newebpayEpgNotifications_aggregateKeySpecifier)
    fields?: newebpayEpgNotifications_aggregateFieldPolicy
  }
  newebpayEpgNotifications_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | newebpayEpgNotifications_aggregate_fieldsKeySpecifier
      | (() => undefined | newebpayEpgNotifications_aggregate_fieldsKeySpecifier)
    fields?: newebpayEpgNotifications_aggregate_fieldsFieldPolicy
  }
  newebpayEpgNotifications_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | newebpayEpgNotifications_avg_fieldsKeySpecifier
      | (() => undefined | newebpayEpgNotifications_avg_fieldsKeySpecifier)
    fields?: newebpayEpgNotifications_avg_fieldsFieldPolicy
  }
  newebpayEpgNotifications_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | newebpayEpgNotifications_max_fieldsKeySpecifier
      | (() => undefined | newebpayEpgNotifications_max_fieldsKeySpecifier)
    fields?: newebpayEpgNotifications_max_fieldsFieldPolicy
  }
  newebpayEpgNotifications_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | newebpayEpgNotifications_min_fieldsKeySpecifier
      | (() => undefined | newebpayEpgNotifications_min_fieldsKeySpecifier)
    fields?: newebpayEpgNotifications_min_fieldsFieldPolicy
  }
  newebpayEpgNotifications_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | newebpayEpgNotifications_mutation_responseKeySpecifier
      | (() => undefined | newebpayEpgNotifications_mutation_responseKeySpecifier)
    fields?: newebpayEpgNotifications_mutation_responseFieldPolicy
  }
  newebpayEpgNotifications_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | newebpayEpgNotifications_stddev_fieldsKeySpecifier
      | (() => undefined | newebpayEpgNotifications_stddev_fieldsKeySpecifier)
    fields?: newebpayEpgNotifications_stddev_fieldsFieldPolicy
  }
  newebpayEpgNotifications_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | newebpayEpgNotifications_stddev_pop_fieldsKeySpecifier
      | (() => undefined | newebpayEpgNotifications_stddev_pop_fieldsKeySpecifier)
    fields?: newebpayEpgNotifications_stddev_pop_fieldsFieldPolicy
  }
  newebpayEpgNotifications_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | newebpayEpgNotifications_stddev_samp_fieldsKeySpecifier
      | (() => undefined | newebpayEpgNotifications_stddev_samp_fieldsKeySpecifier)
    fields?: newebpayEpgNotifications_stddev_samp_fieldsFieldPolicy
  }
  newebpayEpgNotifications_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | newebpayEpgNotifications_sum_fieldsKeySpecifier
      | (() => undefined | newebpayEpgNotifications_sum_fieldsKeySpecifier)
    fields?: newebpayEpgNotifications_sum_fieldsFieldPolicy
  }
  newebpayEpgNotifications_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | newebpayEpgNotifications_var_pop_fieldsKeySpecifier
      | (() => undefined | newebpayEpgNotifications_var_pop_fieldsKeySpecifier)
    fields?: newebpayEpgNotifications_var_pop_fieldsFieldPolicy
  }
  newebpayEpgNotifications_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | newebpayEpgNotifications_var_samp_fieldsKeySpecifier
      | (() => undefined | newebpayEpgNotifications_var_samp_fieldsKeySpecifier)
    fields?: newebpayEpgNotifications_var_samp_fieldsFieldPolicy
  }
  newebpayEpgNotifications_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | newebpayEpgNotifications_variance_fieldsKeySpecifier
      | (() => undefined | newebpayEpgNotifications_variance_fieldsKeySpecifier)
    fields?: newebpayEpgNotifications_variance_fieldsFieldPolicy
  }
  promptUsages?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | promptUsagesKeySpecifier | (() => undefined | promptUsagesKeySpecifier)
    fields?: promptUsagesFieldPolicy
  }
  promptUsages_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | promptUsages_aggregateKeySpecifier
      | (() => undefined | promptUsages_aggregateKeySpecifier)
    fields?: promptUsages_aggregateFieldPolicy
  }
  promptUsages_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | promptUsages_aggregate_fieldsKeySpecifier
      | (() => undefined | promptUsages_aggregate_fieldsKeySpecifier)
    fields?: promptUsages_aggregate_fieldsFieldPolicy
  }
  promptUsages_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | promptUsages_avg_fieldsKeySpecifier
      | (() => undefined | promptUsages_avg_fieldsKeySpecifier)
    fields?: promptUsages_avg_fieldsFieldPolicy
  }
  promptUsages_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | promptUsages_max_fieldsKeySpecifier
      | (() => undefined | promptUsages_max_fieldsKeySpecifier)
    fields?: promptUsages_max_fieldsFieldPolicy
  }
  promptUsages_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | promptUsages_min_fieldsKeySpecifier
      | (() => undefined | promptUsages_min_fieldsKeySpecifier)
    fields?: promptUsages_min_fieldsFieldPolicy
  }
  promptUsages_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | promptUsages_mutation_responseKeySpecifier
      | (() => undefined | promptUsages_mutation_responseKeySpecifier)
    fields?: promptUsages_mutation_responseFieldPolicy
  }
  promptUsages_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | promptUsages_stddev_fieldsKeySpecifier
      | (() => undefined | promptUsages_stddev_fieldsKeySpecifier)
    fields?: promptUsages_stddev_fieldsFieldPolicy
  }
  promptUsages_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | promptUsages_stddev_pop_fieldsKeySpecifier
      | (() => undefined | promptUsages_stddev_pop_fieldsKeySpecifier)
    fields?: promptUsages_stddev_pop_fieldsFieldPolicy
  }
  promptUsages_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | promptUsages_stddev_samp_fieldsKeySpecifier
      | (() => undefined | promptUsages_stddev_samp_fieldsKeySpecifier)
    fields?: promptUsages_stddev_samp_fieldsFieldPolicy
  }
  promptUsages_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | promptUsages_sum_fieldsKeySpecifier
      | (() => undefined | promptUsages_sum_fieldsKeySpecifier)
    fields?: promptUsages_sum_fieldsFieldPolicy
  }
  promptUsages_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | promptUsages_var_pop_fieldsKeySpecifier
      | (() => undefined | promptUsages_var_pop_fieldsKeySpecifier)
    fields?: promptUsages_var_pop_fieldsFieldPolicy
  }
  promptUsages_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | promptUsages_var_samp_fieldsKeySpecifier
      | (() => undefined | promptUsages_var_samp_fieldsKeySpecifier)
    fields?: promptUsages_var_samp_fieldsFieldPolicy
  }
  promptUsages_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | promptUsages_variance_fieldsKeySpecifier
      | (() => undefined | promptUsages_variance_fieldsKeySpecifier)
    fields?: promptUsages_variance_fieldsFieldPolicy
  }
  query_root?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | query_rootKeySpecifier | (() => undefined | query_rootKeySpecifier)
    fields?: query_rootFieldPolicy
  }
  stripeWebhookLogs?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | stripeWebhookLogsKeySpecifier
      | (() => undefined | stripeWebhookLogsKeySpecifier)
    fields?: stripeWebhookLogsFieldPolicy
  }
  stripeWebhookLogs_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | stripeWebhookLogs_aggregateKeySpecifier
      | (() => undefined | stripeWebhookLogs_aggregateKeySpecifier)
    fields?: stripeWebhookLogs_aggregateFieldPolicy
  }
  stripeWebhookLogs_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | stripeWebhookLogs_aggregate_fieldsKeySpecifier
      | (() => undefined | stripeWebhookLogs_aggregate_fieldsKeySpecifier)
    fields?: stripeWebhookLogs_aggregate_fieldsFieldPolicy
  }
  stripeWebhookLogs_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | stripeWebhookLogs_max_fieldsKeySpecifier
      | (() => undefined | stripeWebhookLogs_max_fieldsKeySpecifier)
    fields?: stripeWebhookLogs_max_fieldsFieldPolicy
  }
  stripeWebhookLogs_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | stripeWebhookLogs_min_fieldsKeySpecifier
      | (() => undefined | stripeWebhookLogs_min_fieldsKeySpecifier)
    fields?: stripeWebhookLogs_min_fieldsFieldPolicy
  }
  stripeWebhookLogs_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | stripeWebhookLogs_mutation_responseKeySpecifier
      | (() => undefined | stripeWebhookLogs_mutation_responseKeySpecifier)
    fields?: stripeWebhookLogs_mutation_responseFieldPolicy
  }
  subscriptionItems?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | subscriptionItemsKeySpecifier
      | (() => undefined | subscriptionItemsKeySpecifier)
    fields?: subscriptionItemsFieldPolicy
  }
  subscriptionItems_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | subscriptionItems_aggregateKeySpecifier
      | (() => undefined | subscriptionItems_aggregateKeySpecifier)
    fields?: subscriptionItems_aggregateFieldPolicy
  }
  subscriptionItems_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | subscriptionItems_aggregate_fieldsKeySpecifier
      | (() => undefined | subscriptionItems_aggregate_fieldsKeySpecifier)
    fields?: subscriptionItems_aggregate_fieldsFieldPolicy
  }
  subscriptionItems_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | subscriptionItems_max_fieldsKeySpecifier
      | (() => undefined | subscriptionItems_max_fieldsKeySpecifier)
    fields?: subscriptionItems_max_fieldsFieldPolicy
  }
  subscriptionItems_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | subscriptionItems_min_fieldsKeySpecifier
      | (() => undefined | subscriptionItems_min_fieldsKeySpecifier)
    fields?: subscriptionItems_min_fieldsFieldPolicy
  }
  subscriptionItems_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | subscriptionItems_mutation_responseKeySpecifier
      | (() => undefined | subscriptionItems_mutation_responseKeySpecifier)
    fields?: subscriptionItems_mutation_responseFieldPolicy
  }
  subscription_root?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | subscription_rootKeySpecifier
      | (() => undefined | subscription_rootKeySpecifier)
    fields?: subscription_rootFieldPolicy
  }
  subscriptions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | subscriptionsKeySpecifier | (() => undefined | subscriptionsKeySpecifier)
    fields?: subscriptionsFieldPolicy
  }
  subscriptions_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | subscriptions_aggregateKeySpecifier
      | (() => undefined | subscriptions_aggregateKeySpecifier)
    fields?: subscriptions_aggregateFieldPolicy
  }
  subscriptions_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | subscriptions_aggregate_fieldsKeySpecifier
      | (() => undefined | subscriptions_aggregate_fieldsKeySpecifier)
    fields?: subscriptions_aggregate_fieldsFieldPolicy
  }
  subscriptions_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | subscriptions_max_fieldsKeySpecifier
      | (() => undefined | subscriptions_max_fieldsKeySpecifier)
    fields?: subscriptions_max_fieldsFieldPolicy
  }
  subscriptions_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | subscriptions_min_fieldsKeySpecifier
      | (() => undefined | subscriptions_min_fieldsKeySpecifier)
    fields?: subscriptions_min_fieldsFieldPolicy
  }
  subscriptions_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | subscriptions_mutation_responseKeySpecifier
      | (() => undefined | subscriptions_mutation_responseKeySpecifier)
    fields?: subscriptions_mutation_responseFieldPolicy
  }
  users?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | usersKeySpecifier | (() => undefined | usersKeySpecifier)
    fields?: usersFieldPolicy
  }
  users_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_aggregateKeySpecifier
      | (() => undefined | users_aggregateKeySpecifier)
    fields?: users_aggregateFieldPolicy
  }
  users_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_aggregate_fieldsKeySpecifier
      | (() => undefined | users_aggregate_fieldsKeySpecifier)
    fields?: users_aggregate_fieldsFieldPolicy
  }
  users_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_max_fieldsKeySpecifier
      | (() => undefined | users_max_fieldsKeySpecifier)
    fields?: users_max_fieldsFieldPolicy
  }
  users_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_min_fieldsKeySpecifier
      | (() => undefined | users_min_fieldsKeySpecifier)
    fields?: users_min_fieldsFieldPolicy
  }
  users_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | users_mutation_responseKeySpecifier
      | (() => undefined | users_mutation_responseKeySpecifier)
    fields?: users_mutation_responseFieldPolicy
  }
  writersoftUsageReports?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | writersoftUsageReportsKeySpecifier
      | (() => undefined | writersoftUsageReportsKeySpecifier)
    fields?: writersoftUsageReportsFieldPolicy
  }
  writersoftUsageReports_aggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | writersoftUsageReports_aggregateKeySpecifier
      | (() => undefined | writersoftUsageReports_aggregateKeySpecifier)
    fields?: writersoftUsageReports_aggregateFieldPolicy
  }
  writersoftUsageReports_aggregate_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | writersoftUsageReports_aggregate_fieldsKeySpecifier
      | (() => undefined | writersoftUsageReports_aggregate_fieldsKeySpecifier)
    fields?: writersoftUsageReports_aggregate_fieldsFieldPolicy
  }
  writersoftUsageReports_avg_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | writersoftUsageReports_avg_fieldsKeySpecifier
      | (() => undefined | writersoftUsageReports_avg_fieldsKeySpecifier)
    fields?: writersoftUsageReports_avg_fieldsFieldPolicy
  }
  writersoftUsageReports_max_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | writersoftUsageReports_max_fieldsKeySpecifier
      | (() => undefined | writersoftUsageReports_max_fieldsKeySpecifier)
    fields?: writersoftUsageReports_max_fieldsFieldPolicy
  }
  writersoftUsageReports_min_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | writersoftUsageReports_min_fieldsKeySpecifier
      | (() => undefined | writersoftUsageReports_min_fieldsKeySpecifier)
    fields?: writersoftUsageReports_min_fieldsFieldPolicy
  }
  writersoftUsageReports_mutation_response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | writersoftUsageReports_mutation_responseKeySpecifier
      | (() => undefined | writersoftUsageReports_mutation_responseKeySpecifier)
    fields?: writersoftUsageReports_mutation_responseFieldPolicy
  }
  writersoftUsageReports_stddev_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | writersoftUsageReports_stddev_fieldsKeySpecifier
      | (() => undefined | writersoftUsageReports_stddev_fieldsKeySpecifier)
    fields?: writersoftUsageReports_stddev_fieldsFieldPolicy
  }
  writersoftUsageReports_stddev_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | writersoftUsageReports_stddev_pop_fieldsKeySpecifier
      | (() => undefined | writersoftUsageReports_stddev_pop_fieldsKeySpecifier)
    fields?: writersoftUsageReports_stddev_pop_fieldsFieldPolicy
  }
  writersoftUsageReports_stddev_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | writersoftUsageReports_stddev_samp_fieldsKeySpecifier
      | (() => undefined | writersoftUsageReports_stddev_samp_fieldsKeySpecifier)
    fields?: writersoftUsageReports_stddev_samp_fieldsFieldPolicy
  }
  writersoftUsageReports_sum_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | writersoftUsageReports_sum_fieldsKeySpecifier
      | (() => undefined | writersoftUsageReports_sum_fieldsKeySpecifier)
    fields?: writersoftUsageReports_sum_fieldsFieldPolicy
  }
  writersoftUsageReports_var_pop_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | writersoftUsageReports_var_pop_fieldsKeySpecifier
      | (() => undefined | writersoftUsageReports_var_pop_fieldsKeySpecifier)
    fields?: writersoftUsageReports_var_pop_fieldsFieldPolicy
  }
  writersoftUsageReports_var_samp_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | writersoftUsageReports_var_samp_fieldsKeySpecifier
      | (() => undefined | writersoftUsageReports_var_samp_fieldsKeySpecifier)
    fields?: writersoftUsageReports_var_samp_fieldsFieldPolicy
  }
  writersoftUsageReports_variance_fields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | writersoftUsageReports_variance_fieldsKeySpecifier
      | (() => undefined | writersoftUsageReports_variance_fieldsKeySpecifier)
    fields?: writersoftUsageReports_variance_fieldsFieldPolicy
  }
}
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies
