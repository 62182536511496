import { ReactNode } from 'react'
// @mui
import { ThemeProvider, createTheme, useTheme, Theme } from '@mui/material/styles'
// hooks
import useLocales from '@/hooks/useLocales'
import useApp from '@/hooks/useApp'
import componentsOverride from '@/theme/overrides'
import merge from 'lodash/merge'
import { GlobalSetting } from '@/services/page'
import palette from '@/theme/palette'
// ----------------------------------------------------------------------
type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>
}

type Props = {
  children: ReactNode
  globalSetting?: GlobalSetting
}

export default function ThemeLocalization({ children, globalSetting }: Props) {
  const defaultTheme = useTheme()
  const { currentLang } = useLocales()
  const { companyColor } = useApp()
  const customTheme: RecursivePartial<Theme> = {
    palette: {
      primary: { main: globalSetting?.companyColor ?? companyColor ?? palette.light.primary.main },
    },
  }
  const theme = createTheme(merge(defaultTheme, customTheme), currentLang.systemValue)

  theme.components = componentsOverride(theme)

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
