import { defaultAppConfig } from './appConfig'
import { SettingsValueProps } from './components/settings/type'
// routes
import { PATH_DASHBOARD } from './routes/paths'
import layout from './theme/layout'

// API
// ----------------------------------------------------------------------

export const FIREBASE_API = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APPID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
}

export const COGNITO_API = {
  userPoolId: process.env.AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.AWS_COGNITO_CLIENT_ID,
}

export const AUTH0_API = {
  clientId: process.env.AUTH0_CLIENT_ID,
  domain: process.env.AUTH0_DOMAIN,
  certificate: process.env.AUTH0_CERTIFICATE || '',
}

export const MAPBOX_API = process.env.MAPBOX

export const HASURA = {
  graphqlAdminSecret: process.env.HASURA_GRAPHQL_ADMIN_SECRET || '',
  graphqlUrl: process.env.HASURA_GRAPHQL_ENDPOINT + '/v1/graphql',
}

export const CONTENTFUL = {
  spaceUrl: `https://app.contentful.com/spaces/{{SPACE_ID}}`,
  contentfulGraphqlUrl:
    'https://graphql.contentful.com/content/v1/spaces/{{SPACE_ID}}/environments/master',
  contentfulEndpoint: 'https://graphql.contentful.com',
  apiManagementAccessToken: process.env.CONTENTFUL_API_MANAGEMENT_ACCESS_TOKEN,
  defaultSpaceId: process.env.CONTENTFUL_SPACE_ID ?? defaultAppConfig.contentfulConfig.spaceId,
}

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: layout.appBar.mobile,
  MAIN_DESKTOP_HEIGHT: layout.appBar.desktop,
  MAIN_DESKTOP_OFFSET_HEIGHT: layout.appBar.desktop - 16,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
}

export const NAVBAR = {
  BASE_WIDTH: 260,
  BASE_COLLAPSE_WIDTH: 88,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
}

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
}

// SETTINGS
// ----------------------------------------------------------------------

export const cookiesExpires = 3

export const cookiesKey = {
  themeMode: 'themeMode',
  themeDirection: 'themeDirection',
  themeColorPresets: 'themeColorPresets',
  themeLayout: 'themeLayout',
  themeStretch: 'themeStretch',
}

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: false,
}

export const NEWEBPAY_EPG = {
  merchantId: process.env.NEWEBPAY_EPG_MERCHANT_ID || '',
  hashKey: process.env.NEWEBPAY_EPG_HASH_KEY || '',
  hashIv: process.env.NEWEBPAY_EPG_HASH_IV || '',
}
export const WRITERSOFT = {
  RYTR_API_KEY: process.env.RYTR_API_KEY,
  WRITERSOFT_GRAPHQL_ENDPOINT: process.env.WRITERSOFT_GRAPHQL_ENDPOINT,
}

export const STRIPE = {
  apiKey: process.env.STRIPE_API_KEY || '',
  endpointSecret: process.env.STRIPE_ENDPOINT_SECRET || '',
  writersoftSubscriptionEnabled: process.env.STRIPE_WRITERSOFT_SUBSCRIPTION_ENABLED === 'true',
}

// reference: https://vercel.com/docs/concepts/projects/environment-variables#system-environment-variables
export const VERCEL = {
  isRunningOnVercel: (process.env.VERCEL || '') === '1',
  env: process.env.VERCEL_ENV || '',
}

export const OPEN_AI = {
  // TODO: will remove token
  dalleBearerToken:
    process.env.DALLE_BEARER_TOKEN ?? 'sess-qB8IsJFBZjtCzFV1ZPNTc1Fb04cKHpJNphox5Ryn',
  OPEN_AI_APY_KEY: process.env.OPEN_AI_APY_KEY ?? '',
}
export const REPLICATE = {
  APY_KEY: process.env.REPLICATE_API_KEY ?? '',
}

export const ADMIN_API_TOKEN = process.env.ADMIN_API_TOKEN || ''

export const DISCORD = {
  linkToApp: (serverId: string, channelId: string) =>
    `discord://discordapp.com/channels/${serverId}/${channelId}`,
  server: {
    mataverseDigital: {
      id: '934428500310491156',
      channelId: {
        midjourneyBot: '1061109975692361840',
      },
    },
  },
}

export const PINECONE = {
  ENVIRONMENT: process.env.PINECONE_ENVIRONMENT ?? '',
  API_KEY: process.env.PINECONE_API_KEY ?? '',
  INDEX_NAME: process.env.PINECONE_INDEX_NAME ?? '',
}

export const APIFY = {
  API_TOKEN: process.env.APIFY_API_TOKEN || '',
}

export const SENTRY = {
  SENTRY_DSN: process.env.SENTRY_DSN || '',
}
