// i18n
import '../locales/i18n'

// highlight
import '../utils/highlight'

// scroll bar
import 'simplebar/src/simplebar.css'

// lightbox
import 'react-image-lightbox/style.css'

// map
import 'mapbox-gl/dist/mapbox-gl.css'

// editor
import 'react-quill/dist/quill.snow.css'

// slick-carousel
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'

import cookie from 'cookie'
import { ReactElement, ReactNode } from 'react'
// next
import { NextPage } from 'next'
import Head from 'next/head'
import App, { AppProps, AppContext } from 'next/app'
import Script from 'next/script'
import { useRouter } from 'next/router'
//
import { PersistGate } from 'redux-persist/lib/integration/react'
// @mui
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
// redux
import { persistor } from '../redux/store'
// utils
import { getSettings } from '../utils/settings'
import { SettingsValueProps } from '../components/settings/type'
// contexts
import { SettingsProvider } from '../contexts/SettingsContext'
import { CollapseDrawerProvider } from '../contexts/CollapseDrawerContext'
import { AppProvider } from '../contexts/AppContext'
import { AuthProvider } from '../contexts/Auth0Context'
// theme
import ThemeProvider from '../theme'
// components
import { ChartStyle } from '../components/chart'
import RtlLayout from '../components/RtlLayout'
import ProgressBar from '../components/ProgressBar'
import ThemeColorPresets from '../components/ThemeColorPresets'
import NotistackProvider from '@/components/NotistackProvider'
import ThemeLocalization from '../components/ThemeLocalization'
import MotionLazyContainer from '../components/animate/MotionLazyContainer'
import { SnackbarUtilsConfigurator } from '@/components/SnackbarUtils'

import { Web3ReactProvider } from '@web3-react/core'
import Web3 from 'web3'
import { provider } from 'web3-core'
import { ApolloProvider } from '@apollo/client'
import { useApollo } from '@/apollo/client'
import {
  APP_TRACKS,
  DEFAULT_TRACKS,
  AppIdEnum,
  TrackTypeEnum,
  getAppHostConfigByAppId,
  getAppLocaleConfig,
} from '@/appConfig'
import { GlobalSetting } from '@/services/page'
import { UserProvider as Auth0UserProvider } from '@auth0/nextjs-auth0'
import '@fontsource/space-grotesk'

type NextPageWithLayout = NextPage<PageProps> & {
  getLayout?: (page: ReactElement) => ReactNode
}

function getLibrary(provider: provider) {
  return new Web3(provider)
}

type PageProps = { globalSetting?: GlobalSetting } & AppProps['pageProps']

interface MyAppProps extends AppProps {
  appId: AppIdEnum
  settings: SettingsValueProps
  Component: NextPageWithLayout
  initialApolloState: any
  pageProps: PageProps
}

export default function MyApp(props: MyAppProps) {
  const router = useRouter()
  const { Component, pageProps, settings, initialApolloState, appId } = props
  const apolloClient = useApollo(initialApolloState)

  const getLayout = Component.getLayout ?? ((page) => page)

  // TODO tracking service refactor
  const gtmId = DEFAULT_TRACKS.find((track) => track.type === TrackTypeEnum.gtm)?.value
  const customGtmId = APP_TRACKS.find(
    (track) => track.appId === appId && track.type === TrackTypeEnum.gtm
  )?.value

  const globalSetting = pageProps.globalSetting as GlobalSetting | undefined

  const { host } = getAppHostConfigByAppId(appId)
  const { locales } = getAppLocaleConfig(appId)

  return (
    <>
      {customGtmId && (
        /* Google Tag Manager - Global base code */
        <>
          <Script
            id="gtag-base-custom"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${customGtmId}');
          `,
            }}
          />
          <noscript>
            <iframe
              src={`https://www.googletagmanager.com/ns.html?id=${customGtmId}`}
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            />
          </noscript>
        </>
      )}
      {gtmId && (
        /* Google Tag Manager - Global base code */
        <>
          <Script
            id="gtag-base-root"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${gtmId}');
          `,
            }}
          />
          <noscript>
            <iframe
              src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            />
          </noscript>
        </>
      )}

      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        {locales.map((locale) => (
          <link
            key={locale}
            type="text/html"
            rel="alternate"
            hrefLang={locale}
            href={`https://${host}/${locale}${router.asPath}`}
          />
        ))}
      </Head>
      <PersistGate loading={null} persistor={persistor}>
        {() => (
          <Auth0UserProvider profileUrl="/api/auth/profile">
            <ApolloProvider client={apolloClient}>
              <AppProvider appId={appId} globalSetting={globalSetting}>
                <AuthProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <CollapseDrawerProvider>
                      <SettingsProvider defaultSettings={settings}>
                        <ThemeProvider>
                          <NotistackProvider>
                            <SnackbarUtilsConfigurator />
                            <MotionLazyContainer>
                              <ThemeColorPresets>
                                <ThemeLocalization globalSetting={globalSetting}>
                                  <RtlLayout>
                                    <ChartStyle />
                                    <ProgressBar />
                                    {getLayout(
                                      <Web3ReactProvider getLibrary={getLibrary}>
                                        <Component {...pageProps} />
                                      </Web3ReactProvider>
                                    )}
                                  </RtlLayout>
                                </ThemeLocalization>
                              </ThemeColorPresets>
                            </MotionLazyContainer>
                          </NotistackProvider>
                        </ThemeProvider>
                      </SettingsProvider>
                    </CollapseDrawerProvider>
                  </LocalizationProvider>
                </AuthProvider>
              </AppProvider>
            </ApolloProvider>
          </Auth0UserProvider>
        )}
      </PersistGate>
    </>
  )
}

// ----------------------------------------------------------------------

MyApp.getInitialProps = async (context: AppContext) => {
  const appProps = await App.getInitialProps(context)

  const cookies = cookie.parse(
    context.ctx.req ? context.ctx.req.headers.cookie || '' : document.cookie
  )

  const settings = getSettings(cookies)
  const appId = context.router.query.appId || AppIdEnum.bePassive

  return {
    appId,
    ...appProps,
    settings,
  }
}
