import { IncomingMessage } from 'http'
import { NextApiRequest } from 'next'
import { NextRequest } from 'next/server'
import { $enum } from 'ts-enum-util'
import {
  devApplicationArt,
  devApplicationBecky,
  devApplicationBlockchain,
  devApplicationBYTETCM,
  devApplicationDigorlon,
  devApplicationEdgar,
  devApplicationJane,
  devApplicationJimmy,
  devApplicationWayne,
  devApplicationMetaverse,
  devApplicationSunny,
  devApplicationTrip,
  devApplicationXchange,
  devApplicationYuubuke,
  devFormSubmit,
  devWritersoftHook,
  DiscordCredential,
  devApplicationMidjourneyToday,
  devApplicationK003000,
  devApplicationElena,
  devApplicationJolicattt,
  devApplicationNerissa,
  devApplicationSmartTools,
  devApplicationFinancial,
} from './utils/discord/credientials'
import { VERCEL } from '@/config'

/*
  If new domain is added, 
  please go to https://manage.auth0.com/dashboard/us/freeman-axie/applications/hh3YEz6DkXIXrFi6anf0pjrYt300JZSe/settings 
  for updating the needed urls for auth0 to work properly

  Step1
  Visit: {{domain}}/api/auth/config

  Step2
  Go to https://manage.auth0.com/dashboard/us/freeman-axie/applications/hh3YEz6DkXIXrFi6anf0pjrYt300JZSe/settings
  
  Step3 
  Copy paste all the needed config to the auth0 dashboard

*/

export enum AppIdEnum {
  bePassive = 'be-passive',
  writersoft = 'writersoft',
  reneverse = 'reneverse',
  agafgoon = 'agafgoon',
  nftYl = 'nft-yl',
  quantBlocks = 'quant-blocks',
  huanTw = 'huan-tw',
  cooseii = 'cooseii',
  u99me = 'u99me',
  atctArt = 'atct-art',
  royalArtCollectClub = 'royalArtCollectClub',
  moralsArtCollectClub = 'moralsArtCollectClub',
  cssArtCollectClub = 'cssArtCollectClub',
  artCollectClub = 'artCollectClub',
  kscDesign = 'kscDesign',
  kscDesignDrYang = 'kscDesignDrYang',
  edgarhuangGolfClubTw = 'edgarhuangGolfClubTw',
  yaoleiLife = 'yaoleiLife',
  newsBytetcm = 'newsBytetcm',
  jaYaoleiLife = 'jaYaoleiLife',
  blogDearmentorbecky = 'blogDearmentorbecky',
  aiDigorlon = 'aiDigorlon',
  iambition = 'iambition',
  xChange = 'xChange',
  xtf = 'xtf',
  godbobiMe = 'godbobiMe',
  pattytripBePassive = 'pattytripBePassive',
  picksBePassive = 'picksBePassive',
  yuubuke = 'yuubuke',
  wayneBePassive = 'wayneBePassive',
  sunnyBePassive = 'sunnyBePassive',
  jimmyBePassive = 'jimmyBePassive',
  janeBePassive = 'janeBePassive',
  nerissaBePassive = 'nerissaBePassive',
  smartTools = 'smartTools',
  financialBePassive = 'financialBePassive',
  midjourneyToday = 'midjourneyToday',
  k003000 = 'k003000',
  elena = 'elena',
  jolicattt = 'jolicattt',
  ppcai = 'ppcai',
  aiprmStore = 'aiprmStore',
  aiToolsStore = 'aiToolsStore',
  aiSolutionsStore = 'aiSolutionsStore',
}

export enum ModuleEnum {
  Locale = 'locale',
  Blog = 'blog',
  Mint = 'mint',
  Auth = 'auth',
  Product = 'product',
  ImageCollection = 'imageCollection',
}

export enum AppServiceEnum {
  entrepreneur = 'entrepreneur',
  writer = 'writer',
  storybook = 'storybook',
  prompts = 'prompts',
  translate = 'translate',
  lineSticker = 'lineSticker',
  midjourneyImageCollection = 'midjourneyImageCollection',
  promptForChromeExtension = 'promptForChromeExtension',
}

export enum SupportLocaleEnum {
  en = 'en',
  zhTw = 'zh-TW',
  // Japanese
  ja = 'ja',
}

export enum ContentfulSupportingLocaleEnum {
  en = 'en',
  zhHantTW = 'zh-Hant-TW',
  ja = 'ja',
}

export enum ContentfulConfigEnum {
  // space name is meant for developers to identify the space, it is not used in code
  spaceName = 'spaceName',
  accessToken = 'accessToken',
  spaceId = 'spaceId',
}

export interface AppConfig {
  appIdEnum: AppIdEnum
  host: {
    production: string
    preview: string | null
  }
  enableModulesMap: { [key in ModuleEnum]: boolean }
  enableServicesMap: { [key in AppServiceEnum]?: boolean }
  supportLocales: SupportLocaleEnum[]
  defaultLocale?: SupportLocaleEnum
  contentfulConfig: {
    [key in ContentfulConfigEnum]: string
  }
  smartContract?: {
    [key: string]: {
      artEngineClientFilePath: string
      contractAddress: string
    }
  }
  postGenerationLoggers: DiscordCredential[] | []
  formSubmitLoggers: DiscordCredential[] | []
}

export const defaultAppConfig: AppConfig = {
  appIdEnum: AppIdEnum.bePassive,
  enableModulesMap: {
    auth: true,
    blog: true,
    locale: true,
    mint: true,
    product: true,
    imageCollection: true,
  },
  enableServicesMap: {
    writer: true,
    storybook: true,
    prompts: true,
    lineSticker: true,
    translate: true,
    midjourneyImageCollection: true,
    entrepreneur: true,
    promptForChromeExtension: true,
  },
  host: {
    production: 'www.be-passive.com',
    preview: 'preview.be-passive.com',
  },
  supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
  contentfulConfig: {
    accessToken: '40f-WAEqSFm-E5rAjRGcGoMi1nGt7huakSz3zT9myso',
    spaceId: '035nyqgapb7h',
    spaceName: 'all-one-one',
  },
  postGenerationLoggers: [],
  formSubmitLoggers: [],
}

export const appsConfigs: AppConfig[] = [
  defaultAppConfig,
  {
    appIdEnum: AppIdEnum.writersoft,
    enableModulesMap: {
      auth: true,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: true,
    },
    enableServicesMap: { writer: true },
    host: {
      production: 'writersoft.be-passive.com',
      preview: 'preview.writersoft.be-passive.com',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'f43xgrm3wzt1',
      accessToken: '5I8Xj4wP9lIJBaHOBCN1QOG5Yiw1rJwmSJKJ9XiG-xQ',
    },
    postGenerationLoggers: [],
    formSubmitLoggers: [],
  },
  {
    appIdEnum: AppIdEnum.reneverse,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: true,
      mint: true,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: true },
    host: {
      production: 'www.renecats.com',
      preview: 'preview-renecats.vercel.app',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    contentfulConfig: {
      spaceName: 'blog',
      spaceId: '6p1i6shut3on',
      accessToken: 'EHyXhnqo23JFc75IKe-wRX6hdbAJblFn3Y5IpbbRg6g',
    },
    postGenerationLoggers: [devApplicationYuubuke],
    formSubmitLoggers: [devApplicationYuubuke],
  },
  {
    appIdEnum: AppIdEnum.agafgoon,
    enableModulesMap: {
      auth: false,
      blog: false,
      locale: true,
      mint: true,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: true },
    host: {
      production: 'www.agafgoon.com',
      preview: 'preview.agafgoon.com',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    contentfulConfig: {
      spaceName: 'internet',
      spaceId: 'izw1okzrqvr9',
      accessToken: 'huFXAvNKD2KaF3uRNYCwkRzwt3tXy8zr1wpqC5wXiy4',
    },
    postGenerationLoggers: [],
    formSubmitLoggers: [],
  },
  {
    appIdEnum: AppIdEnum.nftYl,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: true },
    host: {
      production: 'www.nft-yl.xyz',
      preview: 'preview.nft-yl.xyz',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'dccw7jpyzais',
      accessToken: 'V8Ov86erEA1Td-DrBS37oFhA1qSPLRrhJMShxa70seY',
    },
    postGenerationLoggers: [],
    formSubmitLoggers: [],
  },
  {
    appIdEnum: AppIdEnum.quantBlocks,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: true },
    host: {
      production: 'www.quant-blocks.com',
      preview: 'preview.quant-blocks.com',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'qssy008pk94o',
      accessToken: 'OayqGBg3N8EdFJPVs6MAi-tM79x03gAUQs2zwYAI_Hw',
    },
    postGenerationLoggers: [devApplicationBlockchain],
    formSubmitLoggers: [devApplicationBlockchain],
  },
  {
    appIdEnum: AppIdEnum.cooseii,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: true,
      mint: true,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: true },
    host: {
      production: 'cooseii.be-passive.com',
      preview: 'preview.cooseii.be-passive.com',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    defaultLocale: SupportLocaleEnum.en,
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'gq2iz264b19p',
      accessToken: 'Qs0bTuSqAmpiiLPoJ8r2dXue8sJAgTy3VGIFQzW46Hw',
    },
    postGenerationLoggers: [],
    formSubmitLoggers: [],
  },
  {
    appIdEnum: AppIdEnum.u99me,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: true,
      mint: false,
      product: true,
      imageCollection: false,
    },
    enableServicesMap: { writer: false },
    host: {
      production: 'www.u99.me',
      preview: 'preview.u99.me',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    contentfulConfig: {
      spaceName: 'all in one',
      spaceId: 'ac0mku1rotx2',
      accessToken: 'QO9JvcElaoRefPHWWTq4Dv71hb2FVMC3r3xkdobd44M',
    },
    postGenerationLoggers: [devApplicationJane],
    formSubmitLoggers: [devApplicationJane],
  },
  {
    appIdEnum: AppIdEnum.huanTw,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: false },
    host: {
      production: 'www.huan.tw',
      preview: 'preview.huan.tw',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: '1wd78ya37meh',
      accessToken: 'tRMJLZ6Posb5CiIVK_1pa4rvlYEFJuvLDotYezfZKlw',
    },
    postGenerationLoggers: [],
    formSubmitLoggers: [],
  },
  {
    appIdEnum: AppIdEnum.atctArt,
    enableModulesMap: {
      auth: false,
      blog: false,
      locale: true,
      mint: true,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: false },
    host: {
      production: 'atct.vercel.app',
      preview: 'preview-atct.vercel.app',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'spx0vlb16b01',
      accessToken: 'E-KuByUBGnhHeHxhRGTnST-iAKVxfL3cUuBlKVay7Xw',
    },
    postGenerationLoggers: [],
    formSubmitLoggers: [],
  },
  {
    appIdEnum: AppIdEnum.royalArtCollectClub,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: false,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: false },
    host: {
      production: 'royal.art-collect.club',
      preview: 'preview.royal.art-collect.club',
    },
    supportLocales: [SupportLocaleEnum.zhTw],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: '906a1bswqysc',
      accessToken: 'picg7IskbPRmmB26cxgYDYPF1eviHvQLrP1I9O19luc',
    },
    postGenerationLoggers: [devApplicationArt],
    formSubmitLoggers: [devApplicationArt],
  },
  {
    appIdEnum: AppIdEnum.kscDesign,
    enableModulesMap: {
      auth: true,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: true },
    host: {
      production: 'www.kscdesign.com',
      preview: 'preview.kscdesign.com',
    },
    supportLocales: [SupportLocaleEnum.zhTw, SupportLocaleEnum.en],
    contentfulConfig: {
      spaceName: 'Blank',
      spaceId: 'o07e15qd5dus',
      accessToken: 'hjw80y8UrttBCH0ouBKJdMJtMFpEPwixzh06dUTIL4E',
    },
    postGenerationLoggers: [],
    formSubmitLoggers: [],
  },
  {
    appIdEnum: AppIdEnum.kscDesignDrYang,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: false },
    host: {
      production: 'dr.yang.kscdesign.com',
      preview: 'preview.dr.yang.kscdesign.com',
    },
    supportLocales: [SupportLocaleEnum.zhTw, SupportLocaleEnum.en],
    contentfulConfig: {
      spaceName: 'all in one',
      spaceId: 'e256899q0vwc',
      accessToken: 'oaFgDQ3Nt4tGU0viafSJYvHfqSb3-mSh6RrPkLwNnz0',
    },
    postGenerationLoggers: [],
    formSubmitLoggers: [],
  },
  {
    appIdEnum: AppIdEnum.edgarhuangGolfClubTw,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: false },
    host: {
      production: 'edgarhuang.golfclub.tw',
      preview: 'preview.edgarhuang.golfclub.tw',
    },
    supportLocales: [SupportLocaleEnum.zhTw, SupportLocaleEnum.en],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'v26jel2zttv8',
      accessToken: 'jD3N6cHpvWdqR8H-BMdk5dRyF2blKZDhV3r60UqM5cw',
    },
    postGenerationLoggers: [devApplicationEdgar],
    formSubmitLoggers: [devApplicationEdgar],
  },
  {
    appIdEnum: AppIdEnum.moralsArtCollectClub,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: false },
    host: {
      production: 'morals.art-collect.club',
      preview: 'preview.morals.art-collect.club',
    },
    supportLocales: [SupportLocaleEnum.zhTw, SupportLocaleEnum.en],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: '0hqcex9p2rqq',
      accessToken: 'yLXXVWT5ESdh4A2JpmXxgE104wdzt8yktZKwPgERVmY',
    },
    postGenerationLoggers: [devApplicationArt],
    formSubmitLoggers: [devApplicationArt],
  },
  {
    appIdEnum: AppIdEnum.cssArtCollectClub,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: false },
    host: {
      production: 'css.art-collect.club',
      preview: 'preview.css.art-collect.club',
    },
    supportLocales: [SupportLocaleEnum.zhTw, SupportLocaleEnum.en],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'irpd2x4gud7m',
      accessToken: 'XpTQse9HH6NMha3bCwQQhZr5egTZDYKpi35gdJ3kASw',
    },
    postGenerationLoggers: [devApplicationArt],
    formSubmitLoggers: [devApplicationArt],
  },
  {
    appIdEnum: AppIdEnum.artCollectClub,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: false },
    host: {
      production: 'art-collect.club',
      preview: 'preview.art-collect.club',
    },
    supportLocales: [SupportLocaleEnum.zhTw, SupportLocaleEnum.en],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'inlh8yyvbpz7',
      accessToken: 'vfNbYKmWPYZqGwFcBOEjB4tCub0uo4ROF_3gDi_Wo9s',
    },
    postGenerationLoggers: [devApplicationArt],
    formSubmitLoggers: [devApplicationArt],
  },
  {
    appIdEnum: AppIdEnum.yaoleiLife,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: false },
    host: {
      production: 'www.yaolei.life',
      preview: 'preview.yaolei.life',
    },
    supportLocales: [SupportLocaleEnum.zhTw, SupportLocaleEnum.en],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'kp8h55tqf5sw',
      accessToken: '2hfCFcB838EbNmILSAZWZVrzL_qiEjpUJfAZIM5t-xE',
    },
    postGenerationLoggers: [],
    formSubmitLoggers: [],
  },
  {
    appIdEnum: AppIdEnum.newsBytetcm,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: false },
    host: {
      production: 'news.bytetcm.com',
      preview: 'preview.news.bytetcm.com',
    },
    supportLocales: [SupportLocaleEnum.zhTw, SupportLocaleEnum.en],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'qadt7p87lhe2',
      accessToken: 'KWI_1-U7-d4UgPxZE7v71fbGo1boiUJSEclExmGZfBQ',
    },
    postGenerationLoggers: [devApplicationBYTETCM],
    formSubmitLoggers: [devApplicationBYTETCM],
  },
  {
    appIdEnum: AppIdEnum.jaYaoleiLife,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: false },
    host: {
      production: 'ja.yaolei.life',
      preview: 'preview.ja.yaolei.life',
    },
    supportLocales: [SupportLocaleEnum.ja, SupportLocaleEnum.en],
    defaultLocale: SupportLocaleEnum.ja,
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: '7ifcbuq1wsd9',
      accessToken: '7ysTcRJP_ZJqxXR5gsmITjpyxB2CtaYLPEf8a5FQSbs',
    },
    postGenerationLoggers: [],
    formSubmitLoggers: [],
  },
  {
    appIdEnum: AppIdEnum.blogDearmentorbecky,
    enableModulesMap: {
      auth: true,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: false },
    host: {
      production: 'blog.dearmentorbecky.com',
      preview: 'preview.blog.dearmentorbecky.com',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    defaultLocale: SupportLocaleEnum.zhTw,
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'pmh7vfa17rfa',
      accessToken: 'RBjUQHDkOOIJWzh-PDQ7mbqqIMSp3pe33T7sAgDQddQ',
    },
    postGenerationLoggers: [devApplicationBecky],
    formSubmitLoggers: [devApplicationBecky],
  },
  {
    appIdEnum: AppIdEnum.aiDigorlon,
    enableModulesMap: {
      auth: true,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: true },
    host: {
      production: 'ai.digorlon.com',
      preview: 'preview.ai.digorlon.com',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    defaultLocale: SupportLocaleEnum.zhTw,
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'xuz1olbp8lpx',
      accessToken: 'RQedIeHyzxtD61XaHUyOHBI7iEEKOWrKm0i7JP4ROYY',
    },
    postGenerationLoggers: [devApplicationDigorlon],
    formSubmitLoggers: [devApplicationDigorlon],
  },
  {
    appIdEnum: AppIdEnum.iambition,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: false },
    host: {
      production: 'iambition.iambition.co',
      preview: 'preview.iambition.be-passive.com',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    defaultLocale: SupportLocaleEnum.en,
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'fry7a5gpzyw7',
      accessToken: 'RzvO6Dsc91GMYHLsomdFWbarIR5DvkxM5PuakItxOC4',
    },
    postGenerationLoggers: [],
    formSubmitLoggers: [],
  },
  {
    appIdEnum: AppIdEnum.iambition,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: false },
    host: {
      production: 'www.iambition.co',
      preview: 'preview.iambition.co',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    defaultLocale: SupportLocaleEnum.en,
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'fry7a5gpzyw7',
      accessToken: 'RzvO6Dsc91GMYHLsomdFWbarIR5DvkxM5PuakItxOC4',
    },
    postGenerationLoggers: [],
    formSubmitLoggers: [],
  },
  // {
  //   appIdEnum: AppIdEnum.xChange,
  //   enableModulesMap: { auth: true, blog: true, locale: true, mint: false, product: false, imageCollection:false, imageCollection: false },
  //   enableServicesMap: { writer: true },
  //   host: {
  //     production: 'xchange.be-passive.com',
  //     preview: 'preview.xchange.be-passive.com',
  //   },
  //   supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
  //   defaultLocale: SupportLocaleEnum.en,
  //   contentfulConfig: {
  //     spaceName: 'all-in-one',
  //     spaceId: 'l3ubjs67qz86',
  //     accessToken: 'okYy3l9hyKf93UVXVzIoHMWVZTPDJ2V7CJehHK1PWcM',
  //   },
  //   postGenerationLoggers: [devApplicationXchange],
  //   formSubmitLoggers: [devApplicationXchange],
  // },
  {
    appIdEnum: AppIdEnum.xtf,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: false,
      mint: true,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: false },
    host: {
      production: 'www.xtf.wtf',
      preview: 'preview.xtf.wtf',
    },
    supportLocales: [SupportLocaleEnum.en],
    defaultLocale: SupportLocaleEnum.en,
    contentfulConfig: {
      spaceName: 'Blank',
      spaceId: 'bdrjtqqelcyz',
      accessToken: 'u3ybbonDiLK6F4cKy5Fnd2I4evtm7dbDmyW8ICMhwZU',
    },
    smartContract: {
      '0xC04acbD5BD1694Fd09d195Ab014875a21793f38D': {
        artEngineClientFilePath: 'xtf',
        contractAddress: '0xC04acbD5BD1694Fd09d195Ab014875a21793f38D',
      },
    },
    postGenerationLoggers: [],
    formSubmitLoggers: [],
  },
  {
    appIdEnum: AppIdEnum.godbobiMe,
    enableModulesMap: {
      auth: true,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: false },
    host: {
      production: 'www.godbobi.me',
      preview: 'preview.godbobi.me',
    },
    supportLocales: [SupportLocaleEnum.zhTw, SupportLocaleEnum.en],
    defaultLocale: SupportLocaleEnum.zhTw,
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'om8dzd77lpa0',
      accessToken: 'yDDYp85jlj2Ahg9A_7T6Ar1Jq2eesew689vYBnr8KIM',
    },
    postGenerationLoggers: [devApplicationJane],
    formSubmitLoggers: [devApplicationJane],
  },
  {
    appIdEnum: AppIdEnum.pattytripBePassive,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: true,
      mint: false,
      product: true,
      imageCollection: false,
    },
    enableServicesMap: { writer: true, entrepreneur: true, prompts: true, lineSticker: true },
    host: {
      production: 'pattytrip.be-passive.com',
      preview: 'preview.pattytrip.be-passive.com',
    },
    supportLocales: [SupportLocaleEnum.zhTw, SupportLocaleEnum.en],
    defaultLocale: SupportLocaleEnum.zhTw,
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'uvtxyp5m9ddj',
      accessToken: 'hDFnbwhf6zlspvAWzxDtf_PsxWx883LnNHMf4weuz6Q',
    },
    postGenerationLoggers: [devApplicationTrip],
    formSubmitLoggers: [devApplicationTrip],
  },
  {
    appIdEnum: AppIdEnum.picksBePassive,
    enableModulesMap: {
      auth: true,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: true },
    host: {
      production: 'picks.be-passive.com',
      preview: 'preview.picks.be-passive.com',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: '4snfefnzvzwk',
      accessToken: 'SCRXCQuChDpmQt1JuB8iYGqojsAv3l9XhWQE9MDhuG0',
    },
    postGenerationLoggers: [devApplicationYuubuke],
    formSubmitLoggers: [devApplicationYuubuke],
  },
  {
    appIdEnum: AppIdEnum.yuubuke,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: false },
    host: {
      production: 'www.yuubuke.com',
      preview: 'preview.yuubuke.com',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'ey35hcbmj676',
      accessToken: 'lpdCup1ZvcTBlCQNhYlhmYUh5fa4KObu3DXXVqu2WeU',
    },
    postGenerationLoggers: [devApplicationYuubuke],
    formSubmitLoggers: [devApplicationYuubuke],
  },
  {
    appIdEnum: AppIdEnum.jimmyBePassive,
    enableModulesMap: {
      auth: true,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: true },
    host: {
      production: 'jimmy.be-passive.com',
      preview: 'preview.jimmy.be-passive.com',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'p3pxb6txo5d6',
      accessToken: 'dzrmdEg9VanjftKO4eompV0PXwLC_Q67EbuAYiSrp0g',
    },
    postGenerationLoggers: [devApplicationMetaverse, devApplicationJimmy],
    formSubmitLoggers: [devApplicationMetaverse, devApplicationJimmy],
  },
  {
    appIdEnum: AppIdEnum.sunnyBePassive,
    enableModulesMap: {
      auth: true,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: true },
    host: {
      production: 'sunny.be-passive.com',
      preview: 'preview.sunny.be-passive.com',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: '6cu7v9qbvljc',
      accessToken: 'DLCrRM6zsCZwy5BhGfdqcRAQCKyRYczUGarF63B003c',
    },
    postGenerationLoggers: [devApplicationMetaverse, devApplicationSunny],
    formSubmitLoggers: [devApplicationMetaverse, devApplicationSunny],
  },
  {
    appIdEnum: AppIdEnum.wayneBePassive,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: true,
      mint: false,
      product: true,
      imageCollection: false,
    },
    enableServicesMap: { writer: true },
    host: {
      production: 'wayne.be-passive.com',
      preview: 'preview.wayne.be-passive.com',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'gu9zi3n72uty',
      accessToken: 'tMdpTU_kjGXNkdr1XtFsJttQHfEazLXdMRMpRbeEu_U',
    },
    postGenerationLoggers: [devApplicationMetaverse, devApplicationWayne],
    formSubmitLoggers: [devApplicationMetaverse, devApplicationWayne],
  },
  {
    appIdEnum: AppIdEnum.janeBePassive,
    enableModulesMap: {
      auth: true,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: true },
    host: {
      production: 'jane.be-passive.com',
      preview: 'preview.jane.be-passive.com',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'tdv8x1a1ld9m',
      accessToken: 'o_938iJCm186ZkznZCQ2oxXglC8gtMkMLCVWCpcc0tg',
    },
    postGenerationLoggers: [devApplicationJane],
    formSubmitLoggers: [devApplicationJane],
  },
  {
    appIdEnum: AppIdEnum.midjourneyToday,
    enableModulesMap: {
      auth: true,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: true },
    host: {
      production: 'www.midjourney.today',
      preview: 'preview.midjourney.today',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'q3dpufx6qtrz',
      accessToken: 'TT_gMiGKtYBh7QSmm5V20adJFnvjvgEQijTxpScfdxE',
    },
    postGenerationLoggers: [devApplicationMidjourneyToday],
    formSubmitLoggers: [devApplicationMidjourneyToday],
  },
  {
    appIdEnum: AppIdEnum.k003000,
    enableModulesMap: {
      auth: true,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: true },
    host: {
      production: 'k003000.be-passive.com',
      preview: 'preview.k003000.be-passive.com',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'df1utm39jy8w',
      accessToken: 'RuqZIwnKr7d51760NnLldAfWr8Vm8Xur5uZBqTj3o3I',
    },
    postGenerationLoggers: [devApplicationK003000],
    formSubmitLoggers: [devApplicationK003000],
  },
  {
    appIdEnum: AppIdEnum.elena,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: false },
    host: {
      production: 'elena.kscdesign.com',
      preview: 'preview.elena.kscdesign.com',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'xttz6maokyir',
      accessToken: '9FY5DFf8N1tEtvB79b3MPQDmZA0pmf24L-Jch5Lhdqo',
    },
    postGenerationLoggers: [devApplicationElena],
    formSubmitLoggers: [devApplicationElena],
  },
  {
    appIdEnum: AppIdEnum.jolicattt,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: false },
    host: {
      production: 'jolicattt.be-passive.com',
      preview: 'preview.jolicattt.be-passive.com',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: '34fnsmk0a01q',
      accessToken: 'ClJase7jlc88q5Nf0flJGXYE5egj7k2CezmDPa0tavY',
    },
    postGenerationLoggers: [devApplicationJolicattt],
    formSubmitLoggers: [devApplicationJolicattt],
  },
  {
    appIdEnum: AppIdEnum.nerissaBePassive,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: true,
      mint: false,
      product: true,
      imageCollection: false,
    },
    enableServicesMap: { writer: false },
    host: {
      production: 'nerissa.be-passive.com',
      preview: 'preview.nerissa.be-passive.com',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'ddtf39832nd9',
      accessToken: 'Fm0syluYSxiZJsIGZGsQvnNmV2Fdk0BHS2NTg0ZMQDE',
    },
    postGenerationLoggers: [devApplicationNerissa],
    formSubmitLoggers: [devApplicationNerissa],
  },
  {
    appIdEnum: AppIdEnum.smartTools,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: true,
      mint: false,
      product: true,
      imageCollection: true,
    },
    enableServicesMap: {
      writer: false,
      entrepreneur: true,
      prompts: true,
      promptForChromeExtension: true,
    },
    host: {
      production: 'www.smart-tools.ai',
      preview: 'preview.smart-tools.ai',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: '6off5bx15smh',
      accessToken: '_HjpJtACRf5OEpyukqW0eB4LJjmrj24c8GNwOgFMTlA',
    },
    postGenerationLoggers: [devApplicationSmartTools],
    formSubmitLoggers: [devApplicationSmartTools],
  },
  {
    appIdEnum: AppIdEnum.financialBePassive,
    enableModulesMap: {
      auth: false,
      blog: true,
      locale: false,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: false },
    host: {
      production: 'financial.be-passive.com',
      preview: 'preview.financial.be-passive.com',
    },
    supportLocales: [SupportLocaleEnum.zhTw],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: '8r7a10isorc5',
      accessToken: 'fylQc7IRBuIV-HNl_lT9W49RrGWZTbGhG59_fWBbMVY',
    },
    postGenerationLoggers: [devApplicationFinancial],
    formSubmitLoggers: [devApplicationFinancial],
  },
  {
    appIdEnum: AppIdEnum.ppcai,
    enableModulesMap: {
      auth: false,
      blog: false,
      locale: false,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: false, prompts: true },
    host: {
      production: 'www.ppcai.io',
      preview: 'preview.ppcai.io',
    },
    supportLocales: [SupportLocaleEnum.en],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'ozchk07xu6rf',
      accessToken: 'lT3yzNYSvgAa8kV2fZgY56DJGXdb13GNF_JEKbJPvP8',
    },
    postGenerationLoggers: [],
    formSubmitLoggers: [],
  },
  {
    appIdEnum: AppIdEnum.aiToolsStore,
    enableModulesMap: {
      auth: false,
      blog: false,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: false, entrepreneur: true },
    host: {
      production: 'www.ai-tools.store',
      preview: 'preview.ai-tools.store',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'rq3fnyeg8prm',
      accessToken: '7dz8EJEHzmWUmCSjrNVMHyWGbXYZNaZNTzCnqqi3qJ0',
    },
    postGenerationLoggers: [],
    formSubmitLoggers: [],
  },
  {
    appIdEnum: AppIdEnum.aiprmStore,
    enableModulesMap: {
      auth: false,
      blog: false,
      locale: true,
      mint: false,
      product: false,
      imageCollection: false,
    },
    enableServicesMap: { writer: false },
    host: {
      production: 'www.aiprm.store',
      preview: 'preview.aiprm.store',
    },
    supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
    contentfulConfig: {
      spaceName: 'all-in-one',
      spaceId: 'rq3fnyeg8prm',
      accessToken: '7dz8EJEHzmWUmCSjrNVMHyWGbXYZNaZNTzCnqqi3qJ0',
    },
    postGenerationLoggers: [],
    formSubmitLoggers: [],
  },
  // {
  //   appIdEnum: AppIdEnum.aiSolutionsStore,
  //   enableModulesMap: {
  //     auth: false,
  //     blog: true,
  //     locale: true,
  //     mint: false,
  //     product: true,
  //     imageCollection: false,
  //   },
  //   enableServicesMap: {
  //     writer: true,
  //     entrepreneur: true,
  //     prompts: true,
  //     lineSticker: true,
  //   },
  //   host: {
  //     production: 'www.ai-solutions.store',
  //     preview: 'preview.ai-solutions.store',
  //   },
  //   supportLocales: [SupportLocaleEnum.en, SupportLocaleEnum.zhTw],
  //   contentfulConfig: {
  //     spaceName: 'all-in-one',
  //     spaceId: 'rq3fnyeg8prm',
  //     accessToken: '7dz8EJEHzmWUmCSjrNVMHyWGbXYZNaZNTzCnqqi3qJ0',
  //   },
  //   postGenerationLoggers: [],
  //   formSubmitLoggers: [],
  // },
].map((config) => ({
  ...config,
  postGenerationLoggers: [...config.postGenerationLoggers, devWritersoftHook],
  formSubmitLoggers: [...config.formSubmitLoggers, devFormSubmit],
}))

interface AppHost {
  appId: AppIdEnum
  host: string
}

export enum TrackTypeEnum {
  gtm = 'gtm',
  // Facebook Pixel, Google Analytics, more...
}

export const APPS = appsConfigs.map((config) => ({
  id: config.appIdEnum,
  title: config.appIdEnum,
  description: `Welcome to ${config.appIdEnum}`,
}))

export const APP_PRODUCTION_HOSTS: AppHost[] = appsConfigs.map((config) => ({
  appId: config.appIdEnum,
  host: config.host.production,
}))

interface AppConfigWithPreviewUrl extends AppConfig {
  host: AppConfig['host'] & { preview: string }
}

export const APP_PREVIEW_HOSTS: AppHost[] = appsConfigs
  .filter((config): config is AppConfigWithPreviewUrl => config.host.preview !== null)
  .map((config) => ({
    appId: config.appIdEnum,
    host: config.host.preview,
  }))
export const APP_HOSTS: AppHost[] = [...APP_PRODUCTION_HOSTS, ...APP_PREVIEW_HOSTS]

export const DEFAULT_CONTENTFUL = {
  appId: AppIdEnum.bePassive,
  name: 'all-in-one',
  spaceId: process.env.CONTENTFUL_SPACE_ID ?? '',
  accessToken: process.env.CONTENTFUL_API_ACCESS_TOKEN ?? '',
}

export const APP_CONTENTFULS = appsConfigs.map((config) => ({
  appId: config.appIdEnum,
  name: config.contentfulConfig.spaceName,
  spaceId: config.contentfulConfig.spaceId,
  accessToken: config.contentfulConfig.accessToken,
}))

interface AppModuleConfig {
  appId: AppIdEnum
  module: ModuleEnum
}

export const APP_MODULES: AppModuleConfig[] = appsConfigs.reduce((modulesArray, config) => {
  const modules: AppModuleConfig[] = Object.keys(config.enableModulesMap)
    .filter((key) => {
      const v = $enum(ModuleEnum).asValueOrThrow(key)
      return config.enableModulesMap[v]
    })
    .map((key) => ({
      appId: config.appIdEnum,
      module: $enum(ModuleEnum).asValueOrThrow(key),
    }))
  return [...modulesArray, ...modules]
}, [] as AppModuleConfig[])

interface AppServiceConfig {
  appId: AppIdEnum
  service: AppServiceEnum
}

export const APP_SERVICES: AppServiceConfig[] = appsConfigs.reduce((servicesArray, config) => {
  const services: AppServiceConfig[] = Object.keys(config.enableServicesMap)
    .filter((key) => {
      const v = $enum(AppServiceEnum).asValueOrThrow(key)
      return config.enableServicesMap[v]
    })
    .map((key) => ({
      appId: config.appIdEnum,
      service: $enum(AppServiceEnum).asValueOrThrow(key),
    }))
  return [...servicesArray, ...services]
}, [] as AppServiceConfig[])

interface AppLocaleConfig {
  appId: AppIdEnum
  value: SupportLocaleEnum
}

export const APP_LOCALES: AppLocaleConfig[] = appsConfigs.reduce((localesArray, config) => {
  const locales: AppLocaleConfig[] = config.supportLocales.map((supportLocale) => ({
    appId: config.appIdEnum,
    value: supportLocale,
  }))
  return [...localesArray, ...locales]
}, [] as AppLocaleConfig[])

export const APP_NAVS: {
  appId: AppIdEnum
  title: { value: string; locale: SupportLocaleEnum }[]
  icon: string
  path: string
}[] = [
  // {
  //   appId: AppIdEnum.reneverse,
  //   title: [
  //     {
  //       value: '課程',
  //       locale: SupportLocaleEnum.zhTw,
  //     },
  //     {
  //       value: 'Courses',
  //       locale: SupportLocaleEnum.en,
  //     },
  //   ],
  //   icon: 'ic:round-class',
  //   path: '/courses',
  // },
]

interface GTMConfig {
  appId: AppIdEnum
  type: TrackTypeEnum
  value: string
}

export const DEFAULT_TRACKS: GTMConfig[] = [
  {
    appId: AppIdEnum.bePassive,
    type: TrackTypeEnum.gtm,
    value: 'GTM-TFJX8HK',
  },
]

export const APP_TRACKS: GTMConfig[] = [
  {
    appId: AppIdEnum.reneverse,
    type: TrackTypeEnum.gtm,
    value: 'GTM-PSNFRRS',
  },
]

// TODO: will be replaced by hasura user role
export const ownerEmails = ['chaoweichiu@gmail.com', 'mjunzh@gmail.com', 'sunnylochun@gmail.com']

export const getAppLocaleConfig = (appId: AppIdEnum) => {
  const app = appsConfigs.find((appsConfig) => appsConfig.appIdEnum === appId) ?? defaultAppConfig
  const defaultLocale = app.defaultLocale
    ? app.defaultLocale
    : app.supportLocales.includes(SupportLocaleEnum.zhTw)
    ? SupportLocaleEnum.zhTw
    : app.supportLocales[0] ?? SupportLocaleEnum.zhTw

  return {
    locales: app.supportLocales,
    defaultLocale,
  }
}

export const getTargetAppByNextApiRequest = (
  req: NextApiRequest | NextRequest | IncomingMessage
): AppIdEnum => {
  const fallbackAppUrl = 'www.be-passive.com'

  const hostname =
    req instanceof NextRequest
      ? req.headers.get('host') || fallbackAppUrl
      : req.headers.host || fallbackAppUrl

  const fallbackAppId = process.env.APP_ID
    ? ($enum(AppIdEnum)
        .getKeys()
        .find((key) => key === process.env.APP_ID) as AppIdEnum) ?? AppIdEnum.bePassive
    : AppIdEnum.bePassive

  return process.env.NODE_ENV === 'production'
    ? APP_HOSTS.find((appHost) => appHost.host.includes(hostname))?.appId || fallbackAppId
    : fallbackAppId
}

export const getAppHostConfigByAppId = (appId: AppIdEnum) => {
  const fallbackApp: AppHost = {
    host: 'www.be-passive.com',
    appId: AppIdEnum.bePassive,
  }
  const result =
    process.env.NODE_ENV === 'production'
      ? APP_HOSTS.find((appHost) => appHost.appId.includes(appId)) || fallbackApp
      : fallbackApp

  return process.env.NODE_ENV === 'production' ? result || fallbackApp : fallbackApp
}

export const guessAppHostFromVercelEnv = (appId: string) => {
  const local: AppHost = {
    host: 'localhost:8081',
    appId: AppIdEnum.bePassive,
  }

  const fallbackApp: AppHost = {
    host: 'www.be-passive.com',
    appId: AppIdEnum.bePassive,
  }

  let guessedAppHost: AppHost

  switch (VERCEL.env) {
    case 'production':
      guessedAppHost =
        APP_PRODUCTION_HOSTS.find((appHost) => appHost.appId.includes(appId)) || fallbackApp
      break
    case 'preview':
      guessedAppHost =
        APP_PREVIEW_HOSTS.find((appHost) => appHost.appId.includes(appId)) || fallbackApp
      break
    default:
      guessedAppHost = local
  }

  return guessedAppHost.host
}
