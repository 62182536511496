import { ReactNode, createContext } from 'react'
import {
  APPS,
  APP_HOSTS,
  APP_NAVS,
  APP_MODULES,
  APP_SERVICES,
  AppServiceEnum,
  ModuleEnum,
  AppIdEnum,
  getAppLocaleConfig,
  SupportLocaleEnum,
} from '@/appConfig'
import { useFetchRootSettingsCollectionQuery } from '@/generated/graphql'
import { GlobalSetting } from '@/services/page'
import { useRouter } from 'next/router'
import { formatLocale, globalSettingTransformFunc } from '@/utils/contentful'

// ----------------------------------------------------------------------
// TODO AppProps move to types file
interface AppProps extends Partial<GlobalSetting> {
  id: AppIdEnum
  host: string
  title: string | null
  logo: string | null
  description: string | null
  locales: SupportLocaleEnum[]
  defaultLocale: SupportLocaleEnum
  modules: ModuleEnum[]
  services: AppServiceEnum[]
  navs: {
    title: string
    icon: string
    path: string
  }[]
}
type AppContextProps = AppProps & {
  loading: boolean
  error?: Error
  refetch?: () => void
}

const initialState: AppContextProps = {
  id: AppIdEnum.bePassive,
  host: '',
  title: null,
  logo: null,
  locales: [],
  defaultLocale: SupportLocaleEnum.zhTw,
  description: null,
  modules: [],
  services: [],
  navs: [],
  loading: true,
}

const AppContext = createContext(initialState)

type AppProviderProps = {
  children: ReactNode
  appId: AppIdEnum
  globalSetting?: GlobalSetting
}

function AppProvider(props: AppProviderProps) {
  const { appId, children } = props
  const { locale, defaultLocale } = useRouter()
  const appLocaleConfig = getAppLocaleConfig(appId)
  const { data, loading } = useFetchRootSettingsCollectionQuery(
    props.globalSetting
      ? {
          skip: true,
        }
      : {
          variables: {
            locale: formatLocale(locale),
          },
        }
  )
  const globalSetting = props.globalSetting ?? globalSettingTransformFunc(data)
  const currentApp = APPS.find((app) => app.id === appId)
  const host = APP_HOSTS.find((appHost) => appHost.appId === appId)?.host
  const navs = APP_NAVS.filter((appNav) => appNav.appId === appId).map((appNav) => ({
    title:
      (
        appNav.title.find((title) => title.locale === locale) ??
        appNav.title.find((title) => title.locale === defaultLocale)
      )?.value || '',
    icon: appNav.icon,
    path: appNav.path,
  }))
  const modules = APP_MODULES.filter((appModule) => appModule.appId === appId).map(
    (appModule) => appModule.module
  )
  const services = APP_SERVICES.filter((appService) => appService.appId === appId).map(
    (appService) => appService.service
  )

  return (
    <AppContext.Provider
      value={{
        ...globalSetting,
        id: appId,
        host: host ?? '',
        title: globalSetting?.seoMetadataItemsMap.title ?? currentApp?.title ?? null,
        description:
          globalSetting?.seoMetadataItemsMap.description ?? currentApp?.description ?? null,
        logo: globalSetting?.companyLogo ?? null,
        locales: appLocaleConfig.locales,
        defaultLocale: appLocaleConfig.defaultLocale,
        modules,
        services,
        navs,
        loading,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export { AppProvider, AppContext }
