/* eslint-disable global-require */
import fetch from 'isomorphic-fetch'
import { IncomingMessage, ServerResponse } from 'http'
import { useMemo } from 'react'
import { RetryLink } from '@apollo/client/link/retry'
import { ApolloClient, from, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import SnackbarUtils from '@/components/SnackbarUtils'
import { typePolicies } from './localState'

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined

export type ResolverContext = {
  req?: IncomingMessage
  res?: ServerResponse
}

const httpLink = new HttpLink({
  uri: '/api/graphql',
  credentials: 'same-origin',
  fetch,
})

const cache = new InMemoryCache({
  typePolicies,
})
const errorLink = onError(({ graphQLErrors, networkError }) => {
  graphQLErrors?.forEach(({ message, locations, path, extensions }) => {
    console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    SnackbarUtils.enqueueSnackbar(message, {
      variant: 'error',
    })
  })
})

const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 50,
    retryIf: (error, _operation) => !!error,
  },
})
// https://medium.com/@joanvila/productionizing-apollo-links-4cdc11d278eb
const links = from([retryLink, errorLink, httpLink])

function createApolloClient(context?: ResolverContext) {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: links,
    cache,
  })
}

export function initializeApollo(
  initialState: any = null,
  // Pages with Next.js data fetching methods, like `getStaticProps`, can send
  // a custom context which will be used by `SchemaLink` to server render pages
  context?: ResolverContext
) {
  const _apolloClient = apolloClient ?? createApolloClient(context)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    _apolloClient.cache.restore(initialState)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function useApollo(initialState: any) {
  const store = useMemo(() => initializeApollo(initialState), [initialState])
  return store
}
