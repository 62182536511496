export enum SupportingGuild {
  freeman = 'freeman',
  writersoft = 'writersoft',
  trip = 'trip',
  yuubuke = 'yuubuke',
  blockchain = 'blockchain',
  jane = 'jane',
  digorlon = 'digorlon',
  bytetcm = 'bytetcm',
  becky = 'becky',
  edgar = 'edgar',
  art = 'art',
  xchange = 'xchange',
  metaverse = 'metaverse',
  jimmy = 'jimmy',
  sunny = 'sunny',
  wayne = 'wayne',
  midjourneyToday = 'midjourneyToday',
  k003000 = 'k003000',
  elena = 'elena',
  jolicattt = 'jolicattt',
  nerissa = 'nerissa',
  smartTools = 'smartTools',
  financial = 'financial',
}

export interface DiscordCredential {
  guild: SupportingGuild
  url: string
}

export const devErrorHook: DiscordCredential = {
  guild: SupportingGuild.freeman,
  url: 'https://discord.com/api/webhooks/1021240627926679563/i_ffU9t1fCOxpSYyq3atXMbFytQhFAfdQiYS0TWuK2KkkZTG1J4LEAwhqImL0Y7pYMFl',
}
export const devWritersoftHook: DiscordCredential = {
  guild: SupportingGuild.writersoft,
  url: 'https://discord.com/api/webhooks/1034727025879044146/i_OFhnR4_CGfztn8nKjff6nEKJKJh78x4obkekKm54LYsHutqlZgVrZoJD539q82sshg',
}
export const devFormSubmit: DiscordCredential = {
  guild: SupportingGuild.writersoft,
  url: 'https://discord.com/api/webhooks/1043327207482855475/adjm0b85GBkUsesccPqbLNPWPYyqNcxXK00uCCS-P8hmFLWYIl3D4aSKY1VNGVO0_88j',
}
export const devApplicationYuubuke: DiscordCredential = {
  guild: SupportingGuild.yuubuke,
  url: 'https://discord.com/api/webhooks/1054181079667462194/1gtjEhxx8u4-fwJUS7a31_FfGuFK6jJg802gYaN01bsBuEAOmi6TZ4WM9BSP9pjXnH_4',
}
export const devApplicationTrip: DiscordCredential = {
  guild: SupportingGuild.trip,
  url: 'https://discord.com/api/webhooks/1054180801392164985/C9VbQHzlx4sZxGPIXK3Jcz9OARbKcbPMK_do8W7VDugobgMTDkjWZVW7lQCuvY39zjYg',
}
export const devApplicationBlockchain: DiscordCredential = {
  guild: SupportingGuild.blockchain,
  url: 'https://discord.com/api/webhooks/1054180937958695052/eeg7iyrXiIWJQmDRl7ZwVmQmb7duDIE_xLi9JVJIQriKDIJ_F7Q50v6YSadlYACeHkso',
}
export const devApplicationJane: DiscordCredential = {
  guild: SupportingGuild.jane,
  url: 'https://discord.com/api/webhooks/1054181851566178345/5YUE2UtMMeiamBD-zxTQ325Uug-IL93OaWEhyeJVWjLF0e8ZehdOqm-BNubiKlSKXOHV',
}
export const devApplicationDigorlon: DiscordCredential = {
  guild: SupportingGuild.digorlon,
  url: 'https://discord.com/api/webhooks/1054182565516423248/opZwYgUroLj3vq25sOEFIJiVOSzSjsCWeCGrwMXnXwZLt4tuX28V2iILjHfih-DnBks1',
}
export const devApplicationBYTETCM: DiscordCredential = {
  guild: SupportingGuild.bytetcm,
  url: 'https://discord.com/api/webhooks/1054182893326450788/qrxBbnS7RiEPNfItIaFS0BUuJOcWF3jtmMuZDp8igW40nfSIK5qOlMeMrO13juBoOlwh',
}
export const devApplicationBecky: DiscordCredential = {
  guild: SupportingGuild.becky,
  url: 'https://discord.com/api/webhooks/1054183296306794527/kY5sj5105jb0ppBgvOI71Ys5FcWsZA59YpnlupxH5I7GrjZm8yNJPFN8Q_WRq955T191',
}
export const devApplicationEdgar: DiscordCredential = {
  guild: SupportingGuild.edgar,
  url: 'https://discord.com/api/webhooks/1054199665538646137/a0esuqEAhOZOIeXAB38sg3RZDGgchQqlI6XZ-8VzNXG-FCYxdaNOqFziqP-ePP0T3bAP',
}
export const devApplicationArt: DiscordCredential = {
  guild: SupportingGuild.art,
  url: 'https://discord.com/api/webhooks/1054200254754476112/tcz5SbXcS8cqaKKhR582Oh4LbGBIZyBMv3lYxPg_BaBcXGcxIo-xhhP6vU03YZokzjd3',
}
export const devApplicationXchange: DiscordCredential = {
  guild: SupportingGuild.xchange,
  url: 'https://discord.com/api/webhooks/1054201101991297204/KYFPbY3hBkPQ-U6Rwr4ahColGTNqxBhC7pGaNq68QMqwPfshEx2Av5gcremkIq20DBFh',
}
export const devApplicationMetaverse: DiscordCredential = {
  guild: SupportingGuild.metaverse,
  url: 'https://discord.com/api/webhooks/1056115720292933703/bygMoBOqIUhVqLHfP9EZuykyYJ4bUyDxkHOcysZSuVn81K6FY2-YWOHf19d_NzTpS3_c',
}
export const devApplicationJimmy: DiscordCredential = {
  guild: SupportingGuild.jimmy,
  url: 'https://discord.com/api/webhooks/1056721253869813770/bVBwAxaTxaTuO_KUOV6NtP9EEVUhoM82kkhtAOdpqrrAH9uEVEFcLo0R8IdF0GXmwwfp',
}
export const devApplicationSunny: DiscordCredential = {
  guild: SupportingGuild.sunny,
  url: 'https://discord.com/api/webhooks/1056726605856251914/98n9kmn9Vm2zKLgqgaUAaBrJzUagCUvxSIwPyyIMZ37uRpdn7X_vOzqNjQwtdmieXFk4',
}
export const devApplicationWayne: DiscordCredential = {
  guild: SupportingGuild.wayne,
  url: 'https://discord.com/api/webhooks/1056727085588164669/cv5qumkAa6vvcg9p8bGVGNRXWLJEvl0CKk4ACXGMaDLyGOze4MzGkawFCatZ1_BTFy4e',
}
export const devApplicationMidjourneyToday: DiscordCredential = {
  guild: SupportingGuild.midjourneyToday,
  url: 'https://discord.com/api/webhooks/1063439506885324921/OQqqrTk5oFgxuNotmwlsBlNDcPahDTvRiXAWmppW04s2p2yh5H7BUgeCWm5fwIQ3KoFH',
}
export const devApplicationK003000: DiscordCredential = {
  guild: SupportingGuild.k003000,
  url: 'https://discord.com/api/webhooks/1066150629770268774/XVXAnMrLUaR7_72qmX1J3P7_fI05i64ee7CQEGsB4aymmETEPlDZWU7Q2xWzSSLSfjER',
}
export const devApplicationElena: DiscordCredential = {
  guild: SupportingGuild.elena,
  url: 'https://discord.com/api/webhooks/1066152171793555576/YpdBkfbZTgAWR1zrUeNMk-4MLu59dG3b5YrTDYBx2ANbp0i51awmeuW8zG4ySBMNmbAk',
}
export const devApplicationJolicattt: DiscordCredential = {
  guild: SupportingGuild.jolicattt,
  url: 'https://discord.com/api/webhooks/1066164893327315085/ygzwcBwHa1prngXM1HAv6RhguKIIQeUlpjti_JffFXCmttG1sZesYYtIAhdgLZhdLagh',
}
export const devApplicationNerissa: DiscordCredential = {
  guild: SupportingGuild.nerissa,
  url: 'https://discord.com/api/webhooks/1072379812301246514/KtVFAOm09MF4EWq-5t2lVd3V59vYTjfktsxAiJhKP-nlXCPgjiJekUMAxtZjtqTrWGFP',
}
export const devApplicationSmartTools: DiscordCredential = {
  guild: SupportingGuild.smartTools,
  url: 'https://discord.com/api/webhooks/1072383095073951826/b9GLwVwOsJvLiXtDKRl3U9UGc-bwPItQv50Sm-gbTbPlsByRBk_8kb-NrXy5si_OQXA2',
}
export const devApplicationFinancial: DiscordCredential = {
  guild: SupportingGuild.financial,
  url: 'https://discord.com/api/webhooks/1072420975112622171/xN9LVDEiD73OY1LrZ8-QSiRuUsnsp1jtRJyl7koLyzXF6_h7jYujFT7nzfGK6nFSicC9',
}
