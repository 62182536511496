import { useMemo, ReactNode } from 'react'
// @mui
import { CssBaseline } from '@mui/material'
import { createTheme, ThemeOptions, ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
// hooks
import useSettings from '@/hooks/useSettings'
import useApp from '@/hooks/useApp'
//
import palette from './palette'
import typography from './typography'
import breakpoints from './breakpoints'
import componentsOverride from './overrides'
import shadows, { customShadows } from './shadows'

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode
}

export default function ThemeProvider({ children }: Props) {
  const { themeMode, themeDirection } = useSettings()
  const appContext = useApp()
  const finalThemeMode = appContext.theme ?? themeMode

  const isLight = finalThemeMode === 'light' ? true : finalThemeMode === 'dark' ? false : true

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight, themeDirection]
  )

  const theme = createTheme(themeOptions)
  theme.components = componentsOverride(theme)

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  )
}
