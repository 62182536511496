const layout = {
  elementGap: {
    mobile: 15,
    desktop: 30,
  },
  innerGap: {
    mobile: 10,
    desktop: 40,
  },
  appBar: {
    mobile: 64,
    desktop: 88,
  },
  gap: {
    mobile: 20,
    desktop: 45,
  },
  footerHeight: {
    mobile: 700,
    desktop: 290,
  },
  drawerWidth: {
    collapse: 90,
    mobile: 200,
    desktop: 200,
  },
  iconFontSize: {
    share: 20,
  },
  maxWidth: {
    desktop: 1200,
  },
}

export default layout
